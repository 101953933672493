import clsx from "clsx";
import {
  Base2,
  Base2Strong,
  Caption1,
  CenteredPage,
  Display3,
} from "components";
import { Button, Input } from "components/base";
import { VerifyEmail } from "components/pages";
import { FloatingLogo, GoogleLoginButton } from "components/partial";
import { GraphQLError } from "graphql";
import {
  useSignUpFromProjectInvitationMutation,
  useSignUpMutation,
} from "graphql/generated";
import { useProjectInvitationOrigin } from "hooks";
import { t } from "i18n-js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  GraphQLValidationErrorDetail,
  VALIDATION_FAILURE,
} from "utils/constants/graphql_errors";
import { TAKEN } from "utils/constants/validation_codes";
import { EMAIL_REGEX, PASSWORD_REGEX } from "utils/helpers/validations";

type SignUpFormData = {
  email: string;
  password: string;
};

type SignUpFormProps = {
  onSuccess: (data: SignUpFormData) => void;
};

function SignUpForm({ onSuccess }: SignUpFormProps) {
  const { mutateAsync } = useSignUpMutation();
  const navigate = useNavigate();
  const {
    hasProjectInvitationOrigin,
    locationState,
    projectInvitationId,
    projectName,
    inviteeEmail,
    token,
    googleAuthError,
  } = useProjectInvitationOrigin();
  const { mutateAsync: signUpFromProjectInvitationMutateAsync } =
    useSignUpFromProjectInvitationMutation();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<SignUpFormData>({
    mode: "onTouched",
    defaultValues: { email: inviteeEmail },
  });
  const [submitError, setSubmitError] = useState<string | undefined>();

  const onSubmit = async (data: SignUpFormData) => {
    const { email, password } = data;

    try {
      if (hasProjectInvitationOrigin) {
        const { signUpFromProjectInvitation } =
          await signUpFromProjectInvitationMutateAsync({
            input: {
              email: email?.toLowerCase(),
              password,
              passwordConfirmation: password,
            },
            projectInvitation: {
              id: projectInvitationId,
              token,
            },
          });
        if (signUpFromProjectInvitation) {
          await queryClient.refetchQueries(["CurrentUser"]);
          navigate("/onboarding/about_you", { state: locationState });
        }
      } else {
        const { signUp } = await mutateAsync({
          input: {
            email: email?.toLowerCase(),
            password,
            passwordConfirmation: password,
          },
        });
        if (signUp) {
          onSuccess(data);
        }
      }
    } catch (error) {
      const graphQLError = error as GraphQLError;
      const errorCode = graphQLError?.extensions?.code;

      if (
        graphQLError?.message?.includes("Email This mail domain been suspended")
      ) {
        setSubmitError("This mail domain been suspended");
      } else if (errorCode === VALIDATION_FAILURE) {
        const validationErrorDetails = graphQLError?.extensions
          ?.details as Array<GraphQLValidationErrorDetail>;
        if (
          validationErrorDetails &&
          validationErrorDetails?.some((detail) => detail.error === TAKEN)
        ) {
          setSubmitError(t("errors.emailInUse"));
        } else {
          setSubmitError(graphQLError?.message || t("errors.genericError"));
        }
      } else {
        setSubmitError(t("errors.genericError"));
      }
    }
  };

  const navigateToLogin = () => {
    if (hasProjectInvitationOrigin) {
      const encodedPath = window.btoa(
        `redirect_path=${"login"}&projectInvitationId=${projectInvitationId}&projectName=${projectName}&token=${token}&inviteeEmail=${inviteeEmail}`,
      );
      const navigateUrl = `/project-invitations/role?${encodedPath}`;
      navigate(navigateUrl);
    }
  };

  useEffect(() => {
    setSubmitError(
      googleAuthError ? t(`errors.auth.${googleAuthError}`) : undefined,
    );
  }, [googleAuthError]);

  return (
    <>
      <div className="flex w-108 flex-col">
        <Display3 className="mb-24 text-neutral-90">
          {t("pages.signUp.title")}
        </Display3>
        <GoogleLoginButton isSignUp />
        <Base2 className="my-4 text-neutral-90">or</Base2>
        <form
          className="flex flex-col text-left"
          onSubmit={handleSubmit(onSubmit) as () => void}
        >
          {submitError && (
            <Caption1
              className="text-center text-secondary-red-50"
              data-testId="signup-submit-error"
            >
              {submitError}
            </Caption1>
          )}
          <Input
            id="email"
            type="email"
            placeholder="name@example.com"
            label={t("shared.emailAddress")}
            error={errors.email?.message}
            className="!bg-neutral-5"
            {...register("email", {
              required: t("errors.presence"),
              pattern: {
                value: EMAIL_REGEX,
                message: t("errors.invalidEmail"),
              },
            })}
          />
          <Input
            id="password"
            type="password"
            placeholder="Password"
            label={t("shared.password")}
            error={errors.password?.message}
            className="!bg-neutral-5"
            contentClassName="mt-4"
            {...register("password", {
              required: t("errors.presence"),
              pattern: {
                value: PASSWORD_REGEX,
                message: t("errors.password"),
              },
            })}
          />
          <Button
            className="mt-4"
            type="submit"
            disabled={isSubmitting}
            value={t("shared.createAccount")}
          >
            {t("shared.createAccount")}
          </Button>
        </form>
      </div>

      <Base2 className="mt-12 text-neutral-90">
        {t("pages.signUp.alreadyHaveAccount")}{" "}
        <Button variant="link" onClick={navigateToLogin} className="!inline">
          <Base2Strong as="span">{t("shared.logIn")}</Base2Strong>
        </Button>
      </Base2>
      <Base2 className="mt-4 max-w-md text-neutral-90">
        {t("pages.signUp.acceptTerms")}
      </Base2>
    </>
  );
}

function SignUp() {
  const [email, setEmail] = useState<string>();

  const handleSuccess = (data: SignUpFormData) => {
    setEmail(data.email);
  };

  return (
    <CenteredPage className={clsx(email ? "bg-white" : "bg-neutral-10")}>
      <div className="flex flex-1 flex-col justify-center py-28">
        <FloatingLogo />
        {email ? (
          <VerifyEmail email={email} />
        ) : (
          <SignUpForm onSuccess={handleSuccess} />
        )}
      </div>
    </CenteredPage>
  );
}

export default SignUp;
