import {
  Button,
  DiSCProfile,
  DiSCProfileOption,
  Tooltip,
} from "components/base";
import { HStack } from "components/layout";
import ToggleButton from "components/partial/ToggleControl/ToggleButton";
import {
  Caption2,
  Display5,
  Overline,
  Paragraph1,
  Paragraph3,
} from "components/Typography";
import { useGetEnvsContext } from "contexts";
import { UpdateUserMetadatumInput } from "graphql/generated";
import { t } from "i18n-js";
import useUpdateUserMetadatum from "mutations/use-update-userMetadatum";
import { Info } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
type Props = {
  props: { imagename: string; discType: string; visibleToTeam: boolean };
};

function Quizgraph(props: Props) {
  let paragraph = "";
  // const { imagename, discType } = props?.props;
  const { mutateAsync: updateUserMetadatum } = useUpdateUserMetadatum();
  const imagename1: string = props?.props?.imagename || "i";
  const discType1: string = props?.props?.discType || "Influence";

  const { currentUser } = useGetEnvsContext();

  const [activeToggle, setActiveToggle] = useState<boolean>(
    props?.props?.visibleToTeam,
  );
  const sentence = {
    Dominance: "Bold and Task Focused",
    Conscientiousness: "Reserved and Task Focused.",
    Influence: "Bold and People Focused",
    Steadiness: "Reserved and People Focused",
  };

  if (discType1 == "Dominance") {
    paragraph = "Bold and Task Focused";
  }
  if (discType1 == "Conscientiousness") {
    paragraph = "Reserved and Task Focused.";
  }
  if (discType1 == "Influence") {
    paragraph = "Bold and People Focused";
  }
  if (discType1 == "Steadiness") {
    paragraph = "Reserved and People Focused";
  }
  const queryClient = useQueryClient();
  void queryClient.invalidateQueries(["getPersonalityProfile"]);
  const handleToggleMutation = async ({
    userId,
    visibleToTeam,
  }: UpdateUserMetadatumInput) => {
    try {
      await updateUserMetadatum({
        input: {
          userId: userId || "",
          visibleToTeam,
        },
      });
      void queryClient.refetchQueries(["getDiscDetailsView"]);
      void queryClient.invalidateQueries(["ProjectById"]);
      void queryClient.invalidateQueries(["ProjectAssignments"]);
      void queryClient.invalidateQueries(["getPersonalityProfile"]);
    } catch {
      console.error;
    }
  };

  const PersonalityProfileTooltip = () => {
    return (
      <div className="w-[280px]">
        <Caption2>
          {t(
            "component.userSettings.tabs.insights.sections.personalityProfile.tooltipMessage",
          )}
        </Caption2>
      </div>
    );
  };

  useEffect(() => {
    if (activeToggle != props?.props?.visibleToTeam) {
      void handleToggleMutation({
        userId: currentUser?.id,
        visibleToTeam: activeToggle,
      });
    }
  }, [activeToggle]);

  return (
    <>
      <div className="mr-14 mb-3 flex content-center justify-start">
        <HStack className="w-[190px]">
          <DiSCProfile
            profile={imagename1.toUpperCase() as DiSCProfileOption}
          />
        </HStack>
        <div>
          <Overline className="mt-[4px] text-neutral-800">
            {`${imagename1.toUpperCase()} Profile`}
          </Overline>
          <Display5 className="my-2 text-neutral-900">{discType1}</Display5>
          <Paragraph1 className="mb-3 w-[640px] text-neutral-800">
            {t(`team.personalityProfileMessages.${imagename1}`)}
          </Paragraph1>

          <div className="flex space-x-2">
            <div className="flex">
              <Tooltip
                content={PersonalityProfileTooltip}
                placement="bottom"
                offset={6}
              >
                <Button
                  style={{ marginTop: "4px" }}
                  variant="link"
                  className="inline-block"
                  RightIcon={() => (
                    <Info size={14} className="text-neutral-70 " />
                  )}
                />
              </Tooltip>
              <p
                style={{
                  marginLeft: "2px",
                  color: "#7F8489",
                  fontSize: "10px",
                  marginTop: "4px",
                  display: "inline-block",
                  textDecoration: "underline",
                }}
              >
                {t(
                  "component.userSettings.tabs.insights.sections.personalityProfile.howWeDetermine",
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <HStack
            className="mt-3 mb-3 h-[52px] rounded-lg !bg-[#F2F3F4] p-10 px-14"
            space={10}
            justify="between"
            align="center"
          >
            <p className="text-base">Share your DiSC with your team(s)</p>
            <HStack
              className="w-[300px] items-center"
              justify="between"
              align="center"
            >
              <Paragraph3 className="font-Steradian mr-2 !whitespace-nowrap text-sm text-neutral-700">
                Show on my profile and team insights
              </Paragraph3>
              <HStack>
                <ToggleButton
                  onToggle={(state) => setActiveToggle(state)}
                  startsOn={activeToggle}
                />
              </HStack>
            </HStack>
          </HStack>
        </div>
      </div>
    </>
  );
}
export default Quizgraph;
