import { useProjectInvitationOrigin } from "hooks";
import React from "react";

import SignUp from "../SignUp/SignUp";
import LogIn from "./LogIn";

const AuthPage = () => {
  const { hasProjectInvitationOrigin, redirect_path } =
    useProjectInvitationOrigin();
  if (
    hasProjectInvitationOrigin &&
    redirect_path &&
    redirect_path === "sign_up"
  )
    return <SignUp />;
  return <LogIn />;
};
export default AuthPage;
