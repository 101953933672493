export const formatMinutes = (value: number) => {
  const minutes = Math.floor(value / 60);
  const seconds = Math.ceil(value - minutes * 60);

  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const formatTimeAssigned = (value: number) => {
  const minutes = Math.floor(value);
  const seconds = Math.ceil((value % 1) * 60);

  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};
