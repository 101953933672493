import { Button, Input, Link } from "components/base";
import { SlackControl } from "components/CreateInteractions";
import {
  EmbeddedLinks,
  ErrorType,
} from "components/CreateInteractions/CreateAnnouncementForm";
import {
  ConfirmDialog,
  FormInputErrors,
  InteractionForm,
  InteractionModal,
} from "components/partial";
import { Base2, Caption1 } from "components/Typography";
import { useHighlightContext } from "contexts/HighlightContext";
import { Announcement } from "graphql/generated";
import { useFetchMetadata } from "hooks";
import { t } from "i18n-js";
import { pick } from "lodash";
import useDeleteAnnouncement from "mutations/use-delete-announcement";
import useUpdateAnnouncement from "mutations/use-update-announcement";
import { CircleNotch, Megaphone, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { DeepPartial, SubmitHandler, useForm } from "react-hook-form";
import { validateUrl, whetherLinkExists } from "utils/helpers/validations";

type ProjectGoalModalProps = {
  show: boolean;
  onClose: () => void;
  announcement: DeepPartial<Announcement>;
};

enum INPUT_FIELDS {
  title = "title",
  description = "description",
  links = "links",
}

const MAX_UPDATE_CHARS = 1000;
const MAX_TITLE_CHARS = 50;

type CreateAnnouncementFormInput = {
  [INPUT_FIELDS.title]: string;
  [INPUT_FIELDS.description]: string;
  [INPUT_FIELDS.links]: string;
};

export default function EditAnnouncementModal({
  show,
  onClose,
  announcement,
}: ProjectGoalModalProps) {
  const { isHighlighted, handleToggleHighlight } = useHighlightContext();
  const [shouldPostToSlack, setShouldPostToSlack] = useState(true);
  const [newlyAddedLinks, setNewlyAddedLinks] = useState<EmbeddedLinks[]>([]);
  const [enteredLink, setEnteredLink] = useState("");
  const [discardedLink, setDiscardedLink] = useState<EmbeddedLinks>({
    preview: "",
    url: "",
    title: "",
  });
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmDeleteLinkModal, setShowConfirmDeleteLinkModal] =
    useState(false);
  const [requestError, setRequestError] = useState("");
  const DEFAULT_VALUE = {
    title: announcement?.announcementTitle,
    description: announcement?.description,
    links: "",
  };

  useEffect(() => {
    handleToggleHighlight(!!announcement?.isHighlighted);
    const preEmbeddedLinks = announcement?.links?.map((link) =>
      pick(link, ["preview", "title", "url"]),
    );
    setNewlyAddedLinks(preEmbeddedLinks as EmbeddedLinks[]);
  }, [announcement]);

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
    resetField,
    setError,
    getValues,
  } = useForm<CreateAnnouncementFormInput>({
    defaultValues: DEFAULT_VALUE,
    mode: "onSubmit",
  });

  const { mutateAsync } = useUpdateAnnouncement();
  const { mutateAsync: deleteMutationAsync } = useDeleteAnnouncement();
  const { data, isLoading, error } = useFetchMetadata(enteredLink);

  const addFormError = (
    fieldName: INPUT_FIELDS,
    error: ErrorType,
    focusField = false,
  ) => {
    setError(
      fieldName,
      {
        type: error.type,
        message: error.message,
      },
      { shouldFocus: focusField },
    );
  };

  useEffect(() => {
    if (data?.url && whetherLinkExists(newlyAddedLinks, data?.url) === -1) {
      setNewlyAddedLinks((prev) => [
        ...prev,
        {
          url: data.url,
          title: data?.title || data?.domain,
          extension: data.domain,
          preview: data?.images[0] || "",
        },
      ]);
      resetField("links");
      setEnteredLink("");
    } else {
      data?.url &&
        addFormError(
          INPUT_FIELDS.links,
          {
            type: "custom",
            message: t("errors.linkAlreadyExists"),
          },
          true,
        );
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      switch (error.message) {
        case "Request failed with status code 404":
          addFormError(INPUT_FIELDS.links, {
            type: "pattern",
            message: t("errors.invalidLink"),
          });
          setEnteredLink("");
          break;
        default:
          addFormError(INPUT_FIELDS.links, {
            type: "custom",
            message: t("errors.somethingWentWrong"),
          });
          setEnteredLink("");
      }
    }
  }, [error]);

  const handleEmbedLink = (event: React.FormEvent) => {
    event.preventDefault();
    const url: string = getValues("links");

    if (validateUrl(url)) {
      setEnteredLink(url);
    } else {
      addFormError(
        INPUT_FIELDS.links,
        {
          type: "pattern",
          message: t("errors.invalidLink"),
        },
        true,
      );
    }
  };

  const removeEmbeddedLinks = (event: React.FormEvent, url: string) => {
    event.preventDefault();
    const newEmbeddedLinks = [...newlyAddedLinks];
    const linkIndex = newEmbeddedLinks.findIndex((links) => links.url === url);
    if (linkIndex > -1) {
      newEmbeddedLinks.splice(linkIndex, 1);
      setNewlyAddedLinks(newEmbeddedLinks);
      setShowConfirmDeleteLinkModal(false);
      setEnteredLink("");
    }
  };

  const renderEmbeddedLinks = () => {
    return (
      <>
        <Base2>
          {newlyAddedLinks?.length}{" "}
          {newlyAddedLinks?.length > 1 ? "Links" : "Link"}
        </Base2>
        <div className="align-center flex flex-wrap gap-4">
          {newlyAddedLinks.map((link) => (
            <Link
              metadata={link}
              key={link.url}
              isRemovable={true}
              onClick={() => {
                setDiscardedLink(link);
                setShowConfirmDeleteLinkModal(true);
              }}
            />
          ))}
        </div>
      </>
    );
  };

  const handleClose = () => {
    reset(DEFAULT_VALUE);
    onClose();
  };

  const onSubmitSuccess = () => {
    setRequestError("");
    onClose();
  };

  const onDelete = async () => {
    try {
      await deleteMutationAsync(
        {
          id: announcement?.id || "",
        },
        {
          onSuccess: () => {
            setShowConfirmDeleteModal(false);
            onSubmitSuccess();
          },
        },
      );
    } catch {
      setRequestError(t("errors.somethingWentWrong"));
      setShowConfirmDeleteModal(false);
    }
  };

  const onSubmit: SubmitHandler<CreateAnnouncementFormInput> = async ({
    title,
    description,
  }: CreateAnnouncementFormInput) => {
    try {
      await mutateAsync(
        {
          input: {
            title,
            id: announcement?.id || "",
            description,
            links: newlyAddedLinks,
            shouldPostToSlack,
            isHighlighted,
          },
        },
        {
          onSuccess: () => {
            onSubmitSuccess();
          },
        },
      );
    } catch {
      setRequestError(t("errors.somethingWentWrong"));
    }
  };

  return (
    <InteractionModal
      isOpen={show}
      closeModal={onClose}
      header={t("component.editAnnouncement.editAnnouncement")}
      icon={<Megaphone mirrored />}
      variant="yellow"
      slideFromLeft
      isHighlighted={isHighlighted}
      showHighlightFeature={true}
      fromEditModal={true}
    >
      <div className="flex h-[calc(100vh-12rem)] overflow-y-auto ">
        <InteractionForm
          onClose={handleClose}
          onSubmit={handleSubmit(onSubmit) as () => void}
        >
          <Input
            id={INPUT_FIELDS.title}
            label={t("component.editAnnouncement.title")}
            maxLength={MAX_TITLE_CHARS}
            initialCount={DEFAULT_VALUE.title?.length}
            value={watch(INPUT_FIELDS.title)}
            {...register(INPUT_FIELDS.title, {
              required: t("errors.titlePresence"),
            })}
            error={errors?.[INPUT_FIELDS.title]?.message}
            expandable
            rows={8}
            initialCount={DEFAULT_VALUE.title?.length}
            resizable
            showCounter
            longCountDescription
          />
          <Input
            id={INPUT_FIELDS.description}
            label={t("component.editAnnouncement.announcement")}
            maxLength={MAX_UPDATE_CHARS}
            value={watch(INPUT_FIELDS.description)}
            {...register(INPUT_FIELDS.description, {
              required: t("errors.presence"),
            })}
            initialCount={DEFAULT_VALUE.description?.length}
            error={errors?.[INPUT_FIELDS.description]?.message}
            multiline
            expandable
            rows={8}
            resizable
            showCounter
            longCountDescription
          />
          <SlackControl
            onToggle={setShouldPostToSlack}
            interactionType="announcements"
          />

          <>
            <Input
              id={INPUT_FIELDS.links}
              label={t("shared.linksLabel")}
              placeholder={t("shared.linksPlaceholder")}
              value={watch(INPUT_FIELDS.links)}
              {...register(INPUT_FIELDS.links)}
              error={errors?.[INPUT_FIELDS.links]?.message}
            />

            <Button onClick={handleEmbedLink}>
              {isLoading ? (
                <CircleNotch
                  size={20}
                  color="#fcfcfc"
                  className="animate-spin"
                />
              ) : (
                t("shared.embedLink")
              )}
            </Button>

            <Caption1 className="text-center leading-5 text-neutral-60">
              {t("shared.workWithLinks1")}
              <br />
              {t("shared.workWithLinks2")}
            </Caption1>
            {newlyAddedLinks?.length > 0 && renderEmbeddedLinks()}
          </>

          <Button
            onClick={() => setShowConfirmDeleteModal(true)}
            variant="destructive"
            className="!mt-20 flex w-72 flex-row px-5"
            aria-label="Delete Announcement"
            type="button"
          >
            <div className="w- flex flex-row">
              <X className="h-6 w-6" />
              <Base2 className="ml-2 mt-0.5">
                {t("component.editAnnouncement.deleteAnnouncement")}
              </Base2>
            </div>
          </Button>
          <FormInputErrors error={requestError} />
        </InteractionForm>
      </div>
      <ConfirmDialog
        open={showConfirmDeleteModal}
        onCancel={() => setShowConfirmDeleteModal(false)}
        onConfirm={onDelete as () => void}
      />
      <ConfirmDialog
        open={showConfirmDeleteLinkModal}
        onCancel={() => setShowConfirmDeleteLinkModal(false)}
        onConfirm={(event: React.FormEvent) =>
          removeEmbeddedLinks(event, discardedLink.url)
        }
        title={t(
          "component.editAnnouncement.deleteEmbeddedLinkConfirmDialog.title",
        )}
        subtitle={t(
          "component.editAnnouncement.deleteEmbeddedLinkConfirmDialog.subtitle",
        )}
        confirmButtonText={t(
          "component.editAnnouncement.deleteEmbeddedLinkConfirmDialog.confirm",
        )}
        cancelButtonText={t(
          "component.editAnnouncement.deleteEmbeddedLinkConfirmDialog.cancel",
        )}
      />
    </InteractionModal>
  );
}
