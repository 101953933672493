import { SectionStatus, SummarySectionType } from "components/partial";

import { SummaryEditValidation } from "./useSummaryEditEffects";
import {
  DATA_SECTION,
  DESCRIPTION_SECTION,
  NEXT_STEPS_SECTION,
  RECORDING_SECTION,
  RESOURCES_SECTION,
  StatelessSection,
} from "./useSummaryScroll";

// TODO: finish off logic for Recording, Data, and Resources sections
// ( there will be upcoming tickets for those sections)
export const validateSections = (
  sections: StatelessSection[],
  validation: SummaryEditValidation,
): SummarySectionType[] => {
  return sections.map((section) => {
    let thisSectionsStatus;
    switch (section.id) {
      case DESCRIPTION_SECTION:
        thisSectionsStatus =
          validation.description === true
            ? SectionStatus.Ready
            : SectionStatus.ActionRequired;
        break;
      case NEXT_STEPS_SECTION:
        thisSectionsStatus =
          validation.summary === true
            ? SectionStatus.Ready
            : SectionStatus.ActionRequired;
        break;
      case RECORDING_SECTION:
        thisSectionsStatus = SectionStatus.Processing;
        break;
      case DATA_SECTION:
        thisSectionsStatus = SectionStatus.Processing;
        break;
      case RESOURCES_SECTION:
      default:
        thisSectionsStatus = SectionStatus.Ready;
    }
    return { ...section, status: thisSectionsStatus };
  });
};
