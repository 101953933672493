import "./style.css";

import clsx from "clsx";
import { Base2, HStack, VStack } from "components";
import { Eye, EyeSlash } from "phosphor-react";

type SaveObjType = {
  defaultProjectRole?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  pronouns?: string;
  homebase?: string;
  password?: string;
  passwordConfirmation?: string;
  company?: string;
  linkdinLink?: string;
};
type property = {
  [name: string]: string;
  first_name: string;
  last_name: string;
  pronouns: string;
  default_role: string;
  email: string;
  password: string;
  confirm_password: string;
  homebase: string;
  company: string;
  linkdinLink: string;
};
let saveobj: SaveObjType = {};
import { noop } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

interface SettingsFieldProps {
  label: string;
  name: string;
  defaultValue: string;
  type: "text" | "email" | "password" | "select";
  hasAction?: boolean;
  editFlag?: boolean;
  placeholder?: string;
  ableToDoAction?: boolean;
  underline?: boolean;
  onSubmit: (value: string) => void;
  onAction?: (isFieldEnabled: boolean) => void;
  onChange?: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
}

export function SettingsField({
  label,
  name,
  defaultValue,
  type,
  hasAction = true,
  editFlag,
  placeholder,
  underline = true,
  // ableToDoAction = true,
  onSubmit,
  // onAction = noop,
  onChange = noop,
  onBlur = noop,
}: SettingsFieldProps) {
  const [value, setValue] = useState(defaultValue);
  const [isDisabled, setDisabled] = useState(true);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const togglePasswordVisibility = (visibility: boolean): void =>
    setIsPasswordVisible(!visibility);

  const inputRef = useRef<HTMLInputElement>(null);
  const nameToPropertyMap: property = {
    first_name: "firstName",
    last_name: "lastName",
    pronouns: "pronouns",
    default_role: "defaultProjectRole",
    email: "email",
    password: "password",
    confirm_password: "passwordConfirmation",
    homebase: "homebase",
    company: "company",
    linkdinLink: "linkdinLink",
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => {
    const property = nameToPropertyMap[name];
    if (
      property == "firstName" ||
      "lastName" ||
      ("defaultProjectRole" && event.target.value != "")
    ) {
      Object.assign(saveobj, { [property]: event.target.value });
    } else {
      Object.assign(saveobj, { [property]: event.target.value });
    }

    // if (name == "first_name" && event.target.value != "") {
    //   Object.assign(saveobj, { firstName: event.target.value });
    // }
    // if (name == "last_name" && event.target.value != "") {
    //   Object.assign(saveobj, { lastName: event.target.value });
    // }
    // if (name == "pronouns") {
    //   Object.assign(saveobj, { pronouns: event.target.value });
    // }
    // if (name == "default_role" && event.target.value != "") {
    //   Object.assign(saveobj, { defaultProjectRole: event.target.value });
    // }
    // if (name == "email") {
    //   Object.assign(saveobj, { email: event.target.value });
    // }
    // if (name == "password") {
    //   Object.assign(saveobj, { password: event.target.value });
    // }
    // if (name == "confirm_password") {
    //   Object.assign(saveobj, { passwordConfirmation: event.target.value });
    // }
    // if (name == "homebase") {
    //   Object.assign(saveobj, { homebase: event.target.value });
    // }
    // if (name == "company") {
    //   Object.assign(saveobj, { company: event.target.value });
    // }
    // if (name == "linkdinLink") {
    //   Object.assign(saveobj, { linkdinLink: event.target.value });
    // }

    const value = event.target.value;

    // onSubmit(value)
    onChange(value);
    setValue(value);
  };

  // useEffect(() => {
  //   if (!isDisabled) {
  //     inputRef?.current?.focus();
  //   }
  // }, [isDisabled]);

  useEffect(() => {
    if (editFlag == false && isDisabled == false) {
      onSubmit(saveobj);
    }
    saveobj = {};
    setDisabled(!editFlag);
    setIsPasswordVisible(false);
  }, [editFlag]);

  return (
    <HStack
      className={clsx(
        "w-full  border-neutral-20",
        underline ? " border-b-2 pb-4" : "",
      )}
      justify="between"
      align="center"
    >
      <VStack className="w-full gap-2">
        <Base2 as="label" className="text-neural-90">
          {label}
        </Base2>

        <input
          className="inputclass w-full font-bold focus:outline-transparent disabled:bg-transparent"
          type={isPasswordVisible ? "text" : type}
          name={name}
          id={name}
          value={value}
          autoComplete="off"
          disabled={hasAction && isDisabled}
          onChange={(event) => handleChange(event, name)}
          onBlur={(event) => onBlur(event)}
          ref={inputRef}
          placeholder={placeholder}
          data-testid={name}
        ></input>
      </VStack>
      {hasAction && isDisabled ? undefined : (
        <div
          key={`id-${name}-input`}
          id={`id-${name}-input`}
          className="align-center relative top-3 flex"
        >
          {type === "password" ? (
            <>
              <div
                className={clsx(
                  `align-end relative inset-y-0 right-0 mb-4 mr-4 flex justify-center px-2`,
                  Z_INDEX_LEVELS.BASE_CONTROL,
                )}
              >
                <input
                  className="js-password-toggle hidden"
                  id={`id-${name}-input-toggle`}
                  type="checkbox"
                  onClick={() => togglePasswordVisibility(isPasswordVisible)}
                />
                <label
                  className="cursor-pointer rounded px-2 text-sm text-neutral-90"
                  htmlFor={`id-${name}-input-toggle`}
                >
                  {isPasswordVisible ? (
                    <Eye size={24} />
                  ) : (
                    <EyeSlash size={24} />
                  )}
                </label>
              </div>
            </>
          ) : undefined}
        </div>
      )}
    </HStack>
  );
}
