import { useUpdateNotificationMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation UpdateNotification($ids: [Int!]!) {
    updateNotification(ids: $ids) {
      id
    }
  }
`;

function useUpdateNotification() {
  const queryClient = useQueryClient();
  return useUpdateNotificationMutation({
    onSuccess: () => {
      void queryClient.refetchQueries(["Notifications"]);
    },
  });
}

export default useUpdateNotification;
