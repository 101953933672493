import {
  SubFiltersQuery,
  SubFiltersQueryVariables,
  useSubFiltersQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query subFilters($projectId: ID!, $eventsFilters: EventsFilters!) {
    subFilters(projectId: $projectId, eventsFilters: $eventsFilters) {
      __typename
      ... on Meeting {
        id
        uid
        title
        meetingSource
        meetingDescription: description
        startTime
        durationInMinutes
        joinUrl
        host {
          id
          firstName
          lastName
          fullName
          avatarUrl
        }
        links {
          id
          title
          url
          preview
        }
        durationInMinutes
        createdAt
        invitees {
          id
          firstName
          lastName
          fullName
          avatarUrl
        }
        isHighlighted
      }
      ... on Announcement {
        id
        announcementTitle
        description
        links {
          id
          title
          url
          preview
        }
        isHighlighted
        isSchedule
        scheduleTime
        createdAt
        author {
          id
          firstName
          lastName
          fullName
          avatarUrl
        }
        isNewComment
      }
      ... on Workshop {
        id
        workshopTitle: title
        workshopDescription: description
        startTime
        endTime
        createdAt
        state
        highlight
        links {
          extension
          id
          preview
          title
          url
        }
        documents {
          id
          filename
          url
          contentType
        }
        workshopParticipants {
          role
          user {
            avatarUrl
            id
            firstName
            lastName
            fullName
          }
        }
      }
      ... on TemperatureResponse {
        isVisible
        weekDate
      }
      ... on Task {
        id
        taskTitle: title
        description
        createdAt
        isCompleted
        isHighlighted
        createdBy {
          id
          firstName
          lastName
          fullName
          avatarUrl
        }
        dueDate
        assignees {
          id
          firstName
          lastName
          fullName
          avatarUrl
        }
        links {
          id
          title
          url
          preview
        }
        isNewComment
        tasksAssignees {
          assigneeId
          assignee {
            avatarUrl
            firstName
            lastName
            fullName
            id
          }
          createdAt
          id
          isCompleted
          updatedAt
        }
      }
    }
  }
`;

function useSubFilters(variables: SubFiltersQueryVariables): {
  subFilters: SubFiltersQuery;
} & UseQueryResult<SubFiltersQuery, unknown> {
  const response = useSubFiltersQuery(variables, {
    suspense: true,
  });

  return {
    subFilters: response.data?.subFilters || [],
    ...response,
  };
}

export { useSubFilters };
