import {
  addHours,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  endOfDay,
  format,
  isEqual,
  startOfDay,
} from "date-fns";
import { t } from "i18n-js";

export const getElapsedTimeFromNow = (fromDate: string) => {
  const fromDateTime = new Date(fromDate);
  const dateNow = new Date(Date.now());

  const diffInSeconds = differenceInSeconds(dateNow, fromDateTime);
  const diffInMinutes = differenceInMinutes(dateNow, fromDateTime);
  const diffInHours = differenceInHours(dateNow, fromDateTime);
  const diffInDays = differenceInDays(dateNow, fromDateTime);
  const diffInMonths = differenceInMonths(dateNow, fromDateTime);

  if (diffInMinutes === 0) {
    return `${diffInSeconds} ${t("shared.secondsAgo")}`;
  }

  if (diffInHours === 0) {
    return `${diffInMinutes} ${t("shared.minutesAgo")}`;
  }

  if (diffInDays === 0) {
    return `${diffInHours} ${t("shared.hoursAgo")}`;
  }

  if (diffInMonths === 0) {
    return `${diffInDays} ${t("shared.daysAgo")}`;
  }

  return `${diffInMonths} ${t("shared.daysAgo")}`;
};

export interface IDictionary<T> {
  [Key: string]: T;
}

export const defaultFilters = {
  allEvents: true,
  announcement: false,
  workshop: false,
  task: false,
  onlyMyEvents: false,
  onlyCreatedByMe: false,
  hideCompletedTask: false,
};

export const dateToday = format(new Date(), "yyyy-MM-dd");

export const endOfDueDate = (dueDate: string) => endOfDay(new Date(dueDate));

export const sixPMToday = (): Date => addHours(startOfDay(new Date()), 18);

export const isSameDate = (dueDate: string, endOfToday: string): boolean =>
  isEqual(new Date(dueDate), new Date(endOfToday));

export const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Return's flag Overdue | Due Today
 */
export const getTaskFlag = (dueDate: string, dateToday: string) => {
  if (isSameDate(dueDate, dateToday) && new Date() < sixPMToday()) {
    return `Due Today`;
  } else if (new Date() > addHours(startOfDay(new Date(dueDate)), 18)) {
    return `Overdue`;
  }
};
