/* eslint-disable graphql/template-strings */
import {
  CurrentUserQuery,
  CurrentUserQueryVariables,
  useCurrentUserQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query CurrentUser {
    currentUser {
      id
      email
      firstName
      lastName
      fullName
      defaultProjectRole
      avatarUrl
      currentSignInAt
      homebase
      pronouns
      company
      linkdinLink
      timezone
    }
  }
`;

function useCurrentUser(variables?: CurrentUserQueryVariables): {
  currentUser?: CurrentUserQuery["currentUser"];
} & UseQueryResult<CurrentUserQuery, unknown> {
  const response = useCurrentUserQuery(variables, { suspense: true });

  return { currentUser: response.data?.currentUser, ...response };
}

export { useCurrentUser };
