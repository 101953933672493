import clsx from "clsx";
import moment from "moment-timezone";
import React, { ChangeEvent, useEffect, useState } from "react";

interface TimezoneDropdownProps {
  selectedTimezone: string;
  editFlag?: boolean;
  defaultValue: string;
  placeholder: string;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
}

type SaveObjType = {
  defaultProjectRole?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  pronouns?: string;
  homebase?: string;
  password?: string;
  passwordConfirmation?: string;
  company?: string;
  linkdinLink?: string;
};

let saveobj: SaveObjType = {};

const TimezoneDropdown: React.FC<TimezoneDropdownProps> = ({
  selectedTimezone,
  editFlag,
  defaultValue,
  placeholder,
  onSubmit,
  onChange,
}) => {
  const timezones = moment.tz.names();
  const [defaultSelectedTimezone] = useState(defaultValue);

  useEffect(() => {
    if (editFlag === false) {
      onSubmit(saveobj);
    }
    saveobj = {};
  }, [editFlag]);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedTimezone = event.target.value;
    Object.assign(saveobj, { timezone: event.target.value });
    onChange(selectedTimezone);
  };

  return (
    <div
      className={clsx(
        "mt-2 h-10 w-full appearance-none rounded-xl bg-gray-600 bg-opacity-10 p-2 pr-2",
        !editFlag ? "cursor-not-allowed" : "cursor-pointer",
      )}
    >
      <select
        className={clsx(
          "w-full bg-gray-600 bg-opacity-0",
          !editFlag ? "cursor-not-allowed" : "cursor-pointer",
        )}
        value={selectedTimezone || defaultSelectedTimezone}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChange(e)}
        disabled={!editFlag}
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {timezones.map((timezone) => (
          <option key={timezone} value={timezone}>
            {timezone}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimezoneDropdown;
