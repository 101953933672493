import { Button } from "components/base";
import { Base2, Base2Strong, Display3 } from "components/Typography";
import { useResendEmailConfirmationInstructionsMutation } from "graphql/generated";
import { t } from "i18n-js";
import { EnvelopeOpen } from "phosphor-react";
import React, { useState } from "react";

type VerifyEmailProps = {
  email: string;
};

function VerifyEmail({ email }: VerifyEmailProps) {
  const { mutateAsync, isLoading } =
    useResendEmailConfirmationInstructionsMutation();
  const [error, setError] = useState<string | undefined>();

  const resendEmailConfirmation = async () => {
    try {
      const { resendEmailConfirmationInstructions } = await mutateAsync({
        email: email?.toLowerCase(),
      });
      if (resendEmailConfirmationInstructions) {
        alert("E-mail sent!");
        setError(undefined);
      }
    } catch {
      setError(t("errors.genericError"));
    }
  };

  return (
    <div className="flex max-w-md flex-1 flex-col items-center justify-center">
      <EnvelopeOpen
        size="5rem"
        color="currentColor"
        weight="thin"
        className="text-primary-turquoise-70"
      />
      <Display3 className="my-10 text-neutral-90">
        {t("component.verifyEmail.title")}
      </Display3>
      <Base2 className="mb-9 text-neutral-90">
        {t("component.verifyEmail.instructionsFirst")}{" "}
        <Base2Strong as="span">{email}</Base2Strong>{" "}
        {t("component.verifyEmail.instructionsSecond")}
      </Base2>
      <Base2 className="text-neutral-90">
        {t("component.verifyEmail.resendInstructions")}{" "}
        <Button
          variant="link"
          onClick={resendEmailConfirmation as () => void}
          disabled={isLoading}
          className="!inline"
        >
          <Base2Strong
            as="span"
            className="text-primary-turquoise-70 transition-colors hover:text-primary-turquoise-50"
          >
            {t("component.verifyEmail.resendConfirmationEmail")}
          </Base2Strong>
        </Button>
        .
      </Base2>
      {error && <Base2 className="mt-4 text-secondary-red-70">{error}</Base2>}
    </div>
  );
}

export default VerifyEmail;
