/* eslint-disable graphql/template-strings */
import { gql } from "@apollo/client";
import { WorkshopActivitySubscription } from "graphql/generated";
import { WORKSHOP_ACTIVITY_FRAGMENT } from "queries/use-workshop";
import { useQueryClient } from "react-query";

import { useSubscription } from "./use-subscription";

export const WORKSHOP_ACTIVITY_SUBSCRIPTION = gql`
  ${WORKSHOP_ACTIVITY_FRAGMENT}
  subscription WorkshopActivity($workshopId: ID!) {
    currentWorkshopActivity(workshopId: $workshopId) {
      workshopActivity {
        ...LiveWorkshopActivity
      }
    }
  }
`;

export interface UseWorkshopActivitySubscriptionProps {
  workshopId: string;
}

/**
 * This hook provides a live connection to the server for consuming updates
 * about which workshop activity is active.
 *
 * See `app/javascript/subscriptions/use-subscription.ts` for notes on how there
 * are now two living cache systems in the Front-End.
 *
 * In this hook, to keep the two caches in sync, we're using query invalidation in the
 * `onData` callback because it's simpler and more maintainable.
 */
export const useWorkshopActivitySubscription = ({
  workshopId,
}: UseWorkshopActivitySubscriptionProps) => {
  const queryClient = useQueryClient();

  const output = useSubscription<WorkshopActivitySubscription>(
    WORKSHOP_ACTIVITY_SUBSCRIPTION,
    {
      variables: {
        workshopId: workshopId,
      },
      /**
       * 'onData' allows us to provide a callback to be
       * triggered when event data comes in from the API.
       */
      async onData() {
        await queryClient.invalidateQueries("WorkshopById");
      },
    },
  );

  return output;
};
