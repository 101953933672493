import { GroupedWeekFeedType } from "components/pages";
import React, { createContext, useState } from "react";

type WeekFeedContextType = {
  feedData: GroupedWeekFeedType;
  handleFeedData: (data: GroupedWeekFeedType) => void;
};

const WeekFeedContext = createContext<WeekFeedContextType | undefined>(
  undefined,
);

export function WeekFeedProvider({ children }: { children: React.ReactNode }) {
  const [feedData, setFeedData] = useState<GroupedWeekFeedType>({});

  const handleFeedData = (data: GroupedWeekFeedType) => setFeedData(data);

  return (
    <WeekFeedContext.Provider value={{ feedData, handleFeedData }}>
      {children}
    </WeekFeedContext.Provider>
  );
}

export function useWeekFeedContext() {
  const context = React.useContext(WeekFeedContext);
  if (context === undefined)
    throw new Error(
      "useWeekFeedContext must be used withing an WeekFeedProvider",
    );
  return context;
}
