import {
  WorkshopActivity,
  WorkshopActivityState,
  WorkshopByIdQuery,
} from "graphql/generated";

/**
 * Use this helpfer function to get the "current" WorkshopActivity in the Agenda.
 * (ie, the first one marked "in_progress").
 */
export const getCurrentWorkshopActivity = (
  agenda: WorkshopByIdQuery["workshopById"]["agenda"] | undefined,
): WorkshopActivity | undefined => {
  let activity;

  if (!agenda) {
    return undefined;
  }

  for (const agendaItem of agenda) {
    if (agendaItem.__typename === "Section" && agendaItem.workshopActivities) {
      for (const agendaActivity of agendaItem.workshopActivities) {
        if (agendaActivity.state === WorkshopActivityState.InProgress) {
          activity = agendaActivity;
        }
      }
    } else if (
      agendaItem.__typename === "WorkshopActivity" &&
      agendaItem.state === WorkshopActivityState.InProgress
    ) {
      activity = agendaItem;
    }
  }

  // Not sure this is really safe...
  return activity as WorkshopActivity;
};
