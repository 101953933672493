import clsx from "clsx";
import {
  CreateButton,
  FullPage,
  PhaseViewBody,
  PhaseViewSubheader,
  ProjectGoalModal,
} from "components";
import {
  ProjectViewBody,
  ProjectViewHeader,
  Team,
  WeekViewBody,
  WeekViewSubheader,
} from "components/pages";
import { PageLoader, ViewSubheader } from "components/partial";
import { withProjectErrorBoundary } from "components/utils";
import {
  InviteUsersProvider,
  ProjectNavigatorProvider,
  useGetEnvsContext,
  useProjectNavigator,
} from "contexts";
import { CreatedResourceCardContextProvider } from "contexts/CreatedResourceCardContext";
import { DayCardContextProvider } from "contexts/DayCardContext";
import { HighlightProvider } from "contexts/HighlightContext";
import { RefContextProvider } from "contexts/RefContext";
import { WeekFeedProvider } from "contexts/WeekFeedContext";
import { isPast, parseISO } from "date-fns";
import { Phase, Project, ProjectByIdQuery } from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";
import {
  getLastVisitedProjectPage,
  setLastVisitedProjectPage,
} from "utils/helpers/last-visited-project-page";

import { updateDocumentTitle } from "../../utils/updateDocumentTitle/updateDocumentTitle";
import { DraftsButton } from "./components";

function SubheaderSwitcher({
  project,
  openProjectGoalModal,
  level,
}: {
  project?: ProjectByIdQuery["projectById"];
  openProjectGoalModal: () => void;
  level: string;
}) {
  switch (level) {
    case "project":
      return <ViewSubheader openProjectGoalModal={openProjectGoalModal} />;
    case "phase":
      return project ? (
        <PhaseViewSubheader
          project={project}
          openProjectGoalModal={openProjectGoalModal}
        />
      ) : (
        <></>
      );
    case "team":
      return <></>;
    case "Timeline":
      return project ? (
        <WeekViewSubheader
          project={project}
          openProjectGoalModal={openProjectGoalModal}
        />
      ) : (
        <></>
      );
    default:
      return <></>;
  }
}

function BodySwitcher({
  level,
  project,
  openProjectGoalModal,
}: {
  level: string;
  openProjectGoalModal: () => void;
  project: ProjectByIdQuery["projectById"];
}) {
  switch (level) {
    case "project":
      return (
        <ProjectViewBody
          project={project}
          openProjectGoalModal={openProjectGoalModal}
        />
      );

    case "phase":
      return project ? (
        <PhaseViewBody
          project={project}
          openProjectGoalModal={openProjectGoalModal}
        />
      ) : (
        <></>
      );
    // case "phase":
    //   return <PhaseViewBody project={project} />;
    case "Timeline":
      return <WeekViewBody project={project as Project} />;
    case "team":
      return project ? (
        <InviteUsersProvider>
          <Team project={project} />{" "}
        </InviteUsersProvider>
      ) : (
        <></>
      );
    default:
      return <></>;
  }
}

function ProjectView(): JSX.Element {
  const location = useLocation();
  const currentFullPath = `${location.pathname}${location.search}`;

  const [projectGoalIsOpen, setProjectGoalIsOpen] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const { projectId = "" } = useParams();
  const search = location.search;
  const scheduledAt = new URLSearchParams(search).get("scheduledAt");

  const { currentUser } = useGetEnvsContext();
  const level = searchParams.get("level") || "project";
  const { project, updatePhaseAndWeek, goToToday } = useProjectNavigator();
  const { hasAdminAccess, hasMemberAccess } = useProjectAccessLevel({
    projectId,
  });
  const isProjectOver = isPast(parseISO(project?.endDate as string));

  useEffect(() => {
    if (project) {
      updateDocumentTitle(project?.name);
    }
  }, [projectId]);

  useEffect(() => {
    if (!projectId) return;

    if (!!scheduledAt) {
      goToToday(new Date(scheduledAt));
      return;
    }

    goToToday();
  }, [projectId, scheduledAt]);

  useEffect(() => {
    if (!project) return;
    let foundPhase: Phase | undefined;

    if (searchParams.get("phaseId")) {
      foundPhase = project.phases.find(
        (phase) => phase.id === searchParams.get("phaseId"),
      ) as Phase | undefined;
    }

    if (foundPhase) {
      updatePhaseAndWeek({ phase: foundPhase, weekNumber: 0 });
    }
  }, [project, searchParams, updatePhaseAndWeek]);

  useEffect(() => {
    if (!currentUser) return;

    const lastVisitedProjectPage = getLastVisitedProjectPage(currentUser?.id);
    if (lastVisitedProjectPage !== currentFullPath && project) {
      setLastVisitedProjectPage(currentUser?.id, currentFullPath);
    }
  }, [currentUser, currentFullPath, project]);

  const openProjectGoalModal = () => {
    setProjectGoalIsOpen(true);
  };

  const closeProjectGoalModal = () => {
    setProjectGoalIsOpen(false);
  };

  return (
    <DayCardContextProvider>
      <CreatedResourceCardContextProvider>
        <RefContextProvider>
          <FullPage className="project-gradient-bg flex flex-col">
            <div
              className={clsx(
                "pb-[26px]",
                Z_INDEX_LEVELS.BASE_OVERRIDE,
                level === "Timeline" && "group absolute w-full bg-neutral-5",
              )}
            >
              <ProjectViewHeader isProjectOver={isProjectOver} />
              <SubheaderSwitcher
                project={project}
                openProjectGoalModal={openProjectGoalModal}
                level={level}
              />
            </div>

            <PageLoader isShowing={isPageLoading} />

            {project && (
              <BodySwitcher
                project={project}
                openProjectGoalModal={openProjectGoalModal}
                level={level}
              />
            )}
            {/** ToDO refactor level to be enum */}
            {!isProjectOver && hasMemberAccess && level === "project" && (
              <DraftsButton />
            )}
            {!isProjectOver &&
              (hasAdminAccess || hasMemberAccess) &&
              level !== "team" && (
                <CreateButton onLoadingStateChange={setIsPageLoading} />
              )}
            {project && (
              <ProjectGoalModal
                project={project}
                open={projectGoalIsOpen}
                onClose={closeProjectGoalModal}
              />
            )}
          </FullPage>
        </RefContextProvider>
      </CreatedResourceCardContextProvider>
    </DayCardContextProvider>
  );
}

function ProjectViewWrapper() {
  return (
    <ProjectNavigatorProvider>
      <WeekFeedProvider>
        <HighlightProvider>
          <ProjectView />
        </HighlightProvider>
      </WeekFeedProvider>
    </ProjectNavigatorProvider>
  );
}

export default withProjectErrorBoundary(ProjectViewWrapper);
