import clsx from "clsx";
import { useToast } from "components/provider";
import { Base2, Caption1 } from "components/Typography";
import { Document } from "graphql/generated";
import { t } from "i18n-js";
import { File, NotePencil } from "phosphor-react";
import React from "react";
import { FILE_TRIM } from "utils/helpers/validations";

interface FileItemProps {
  resource: Pick<Document, "filename" | "id" | "url">;
  handleRemoveResource?: (linkId: string) => void;
  handleEditResource?: (resourceId: string) => void;
  large?: boolean;
}

const FileItem = ({
  resource,
  handleRemoveResource,
  large,
  handleEditResource,
}: FileItemProps) => {
  const { addToast } = useToast();

  const resourceName =
    !!resource && !!resource.filename
      ? resource.filename[0].toUpperCase() +
        resource.filename.slice(1).replace(FILE_TRIM, "")
      : "";

  const handleClick = () => {
    if (!resource || !resource.url) {
      addToast({
        message: t("component.fileItem.missingUrl"),
        variant: "error",
        hasCloseOption: true,
      });
      return;
    }

    addToast({
      message: t("component.fileItem.fileOpened"),
      hasCloseOption: true,
    });
  };

  return (
    <a
      href={resource.url}
      target="_blank"
      className={clsx(
        "mb-2 mr-4 flex flex-row items-center gap-2 rounded-lg border-neutral-70 bg-tint-dark-5 p-2",
        {
          "hover:cursor-pointer hover:bg-tint-dark-10":
            !!resource && !!resource.url,
        },
        {
          "mr-2 min-w-[90px] px-4 py-2": large,
        },
      )}
      onClick={handleClick}
    >
      <div
        className={clsx(
          "flex h-5 w-5 items-center justify-center rounded bg-tint-dark-100",
          { "!h-6 !w-6": large },
        )}
      >
        <File />
      </div>
      {large ? (
        <Base2 className="text-neutral-90">{resourceName}</Base2>
      ) : (
        <Caption1 className="text-neutral-90">{resourceName}</Caption1>
      )}
      {handleRemoveResource && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleEditResource && handleEditResource(resource.id);
          }}
        >
          <NotePencil size={16} />
        </button>
      )}
    </a>
  );
};

export default FileItem;
