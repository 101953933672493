import { EmbeddedLinks } from "components/CreateInteractions/CreateAnnouncementForm";
import {
  GeneralInformationForm,
  MilestonesForm,
  PhaseGoalsForm,
  PhasesForm,
  ProjectCreationSteps,
} from "components/pages";
import { ProjectInput, useCreateProjectMutation } from "graphql/generated";
import { t } from "i18n-js";
import { noop, omit } from "lodash";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";

import { CreateProjectFormInput, DefaultFieldsType } from "./types";
import { getDefaultFields, normalizePhases } from "./utils";

type CreateProjectFormProps = {
  className?: string;
  currentStep?: number;
  setCurrentStep?: Dispatch<SetStateAction<number>>;
  onSubmitSuccess?: (createdProjectId: string) => void;
  onCancelForm?: () => void;
  testProps?: EmbeddedLinks[];
};

function CreateProjectForm({
  className,
  currentStep = ProjectCreationSteps.Details,
  setCurrentStep = noop,
  onSubmitSuccess = noop,
  testProps = [],
}: CreateProjectFormProps) {
  const [fields, setFields] = useState(getDefaultFields<DefaultFieldsType>());
  const { organizationId = "" } = useParams();

  const { mutateAsync } = useCreateProjectMutation();

  const nextStep = () =>
    setCurrentStep((prevStep: number) =>
      prevStep > 0 && prevStep < Object.values(ProjectCreationSteps).length / 2
        ? prevStep + 1
        : prevStep,
    );

  const previousStep = () =>
    setCurrentStep((prevStep: number) =>
      prevStep > 1 ? prevStep - 1 : prevStep,
    );

  const buildNewProject = (project: CreateProjectFormInput) =>
    ({
      ...project,
      phases: normalizePhases(project.phases),
      organizationId,
    } as ProjectInput);

  const submitForm = async (project: CreateProjectFormInput) => {
    try {
      const newProject = buildNewProject(project);
      const { createProject: createdProject } = await mutateAsync({
        project: newProject,
      });

      if (createdProject) {
        setFields(getDefaultFields());
        onSubmitSuccess(createdProject.id);
      }
    } catch {
      console.error(t("errors.somethingWentWrong"));
    }
  };

  switch (currentStep) {
    case ProjectCreationSteps.Details: {
      return (
        <GeneralInformationForm
          testProps={testProps}
          className={className}
          fields={fields}
          onSubmitSuccess={(newFields = {}) => {
            setFields((prevFields) => ({ ...prevFields, ...newFields }));
            nextStep();
          }}
        />
      );
    }
    case ProjectCreationSteps.Phases: {
      return (
        <PhasesForm
          className={className}
          fields={fields}
          previousStep={(newFields) => {
            setFields((prevFields) => ({ ...prevFields, ...newFields }));
            previousStep();
          }}
          onSubmitSuccess={(newFields) => {
            setFields((prevFields) => ({
              ...prevFields,
              ...newFields,
              phases: newFields?.phases || [],
            }));
            nextStep();
          }}
          direction="create"
        />
      );
    }
    case ProjectCreationSteps.PhaseGoals: {
      return (
        <PhaseGoalsForm
          className={className}
          fields={fields}
          previousStep={(newFields) => {
            setFields((prevFields) => ({ ...prevFields, ...newFields }));
            previousStep();
          }}
          onSubmitSuccess={(newFields) => {
            setFields((prevFields) => ({ ...prevFields, ...newFields }));
            nextStep();
          }}
          direction="create"
        />
      );
    }
    case ProjectCreationSteps.Milestones: {
      return (
        <MilestonesForm
          className={className}
          fields={fields}
          previousStep={(newFields) => {
            setFields((prevFields) => ({ ...prevFields, ...newFields }));
            previousStep();
          }}
          onSubmitSuccess={
            (async (newFields) => {
              const newFilteredFields = omit(newFields, ["url"]);
              const filteredFields = omit(fields, ["url"]);
              await submitForm({
                ...filteredFields,
                ...newFilteredFields,
              } as CreateProjectFormInput);
              setFields((prevFields) => ({ ...prevFields, ...newFields }));
              nextStep();
            }) as (fields?: DefaultFieldsType) => void
          }
        />
      );
    }
    default: {
      return <></>;
    }
  }
}

export default CreateProjectForm;
