import { useUpdatePositionsMutation } from "graphql/generated";
import { gql } from "graphql-request";

gql`
  mutation UpdatePositions($positions: PositionsInput!) {
    updatePositions(positions: $positions) {
      __typename
    }
  }
`;

export function useUpdatePosition() {
  return useUpdatePositionsMutation({});
}
