import React, { createContext, Dispatch, useState } from "react";

type CreatedResourceContextType = {
  createdResourceId: string;
  setCreatedResourceId: Dispatch<string>;
};

const CreatedResourceCardContext = createContext<
  CreatedResourceContextType | undefined
>(undefined);

export function CreatedResourceCardContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [createdResourceId, setCreatedResourceId] = useState<string>("");

  return (
    <CreatedResourceCardContext.Provider
      value={{ createdResourceId, setCreatedResourceId }}
    >
      {children}
    </CreatedResourceCardContext.Provider>
  );
}

export function useCreatedResourceCardContext() {
  const context = React.useContext(CreatedResourceCardContext);
  if (context === undefined)
    throw new Error(
      "useCreatedResourceCardContext must be used withing an CreatedResourceCardContextProvider",
    );
  return context;
}
