import {
  AnnouncementCommentsQuery,
  AnnouncementCommentsQueryVariables,
  useAnnouncementCommentsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query AnnouncementComments($announcementId: ID!, $count: Int!) {
    announcementComments(announcementId: $announcementId, count: $count) {
      totalCount
      comments {
        content
        id
        isNew
        author {
          avatarUrl
          fullName
          firstName
          lastName
        }
        createdAt
      }
    }
  }
`;

function useAnnouncementComments(
  variables: AnnouncementCommentsQueryVariables,
): {
  comments: AnnouncementCommentsQuery["announcementComments"]["comments"];
  totalCount: AnnouncementCommentsQuery["announcementComments"]["totalCount"];
} & UseQueryResult<AnnouncementCommentsQuery, unknown> {
  const response = useAnnouncementCommentsQuery(variables);
  const { announcementComments } = response.data || {};
  return {
    comments: announcementComments?.comments || [],
    totalCount: announcementComments?.totalCount || 10,
    ...response,
  };
}

export { useAnnouncementComments };
