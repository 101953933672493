/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  OperationVariables,
  SubscriptionHookOptions,
  SubscriptionResult,
  useSubscription as useApolloUseSubscription,
} from "@apollo/client";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { DocumentNode } from "graphql";

import { apolloClient } from "./apollo-config";

/**
 * Custom wrapper hook to go around the Apollo `useSubscription` hook and manage
 * the tying in of our GQL Schema, as well as the connection to the ReactQuery cache.
 *
 * Since this uses an isolated ApolloClient for the `useSubscription` hook,
 * the data returned in the subscription doesn't automatically integrate into the
 * main app cache which is managed by ReactQuery.
 *
 * This hook provides an `onData` callback, which can be used to either:
 * 1. Trigger an invalidation of the queries which fetch the same data.
 * 2. Directly stitch the updated data into the react-query cache.
 *
 * There are pros and cons with both.
 *
 * With Query Invalidation, it's a bit less immediate of an update, and there's more
 * data retrieval happening. But it's simpler, cleaner, and more maintainable.
 *
 * With Direct Cache Manipulation, it takes a lot of really careful logic to correctly
 * update anything more complex than the most basic query values. But if the queries
 * in question are quite large, it's probably notable more performant.
 *
 * Another thing to be weary of with directly modifying the cache is that the potential
 * blast radius of something going wrong can be pretty large. There can be huge trickle-down
 * effects of corrupting the existing cache. And if there were problems, it wouldn't be
 * immediately obvious to come here to look for the cause.
 *
 * This is all just to say, you don't always have to use query invalidation in the `onData`
 * for keeping the app state in sync -- but if you do choose to deviate from that approach,
 * just beware the potential risks.
 *
 * For an example of what direct cache manipulation would look like, see:
 * (PR#1025's use-workshop-activity.ts onData callback)[https://github.com/watershedsoftware/coeuraj_web/pull/1025/files#diff-64324db502c959ba6db589f30c9d4071d02d45f2f87ba4652a106f8eaa162335R57-R130]
 */
export const useSubscription = <TData = any, TVariables = OperationVariables>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<TData, TVariables>,
): SubscriptionResult<TData, any> =>
  useApolloUseSubscription(subscription, {
    ...options,
    // This will utilize our apollo client (which we use only for subscriptions) to pull workshop activity
    // https://www.apollographql.com/docs/react/data/subscriptions#options
    client: apolloClient,
  });
