import {
  OrganizationByIdQuery,
  OrganizationByIdQueryVariables,
  useOrganizationByIdQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query OrganizationById($id: ID!) {
    organizationById(id: $id) {
      organizationImageUrl
      id
      name
      updatedAt
    }
  }
`;

function useOrganizationById(variables: OrganizationByIdQueryVariables): {
  organizationById: OrganizationByIdQuery["organizationById"];
} & UseQueryResult<OrganizationByIdQuery, unknown> {
  const response = useOrganizationByIdQuery(variables);

  return {
    organizationById: response?.data?.organizationById || undefined,
    ...response,
  };
}

export { useOrganizationById };
