import React from "react";

function CChartIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3898_100916)">
        <path
          d="M28 55C28 55.5523 27.5519 56.0019 27 55.9822C19.9371 55.73 13.2159 52.8138 8.20101 47.799C3.18617 42.7841 0.270017 36.0629 0.0178436 29C-0.00186253 28.4481 0.447715 28 1 28L27 28C27.5523 28 28 28.4477 28 29L28 55Z"
          fill="#EB8424"
        />
        <path
          d="M-0.5 5C-0.5 1.96243 1.96243 -0.5 5 -0.5H28H51C54.0376 -0.5 56.5 1.96243 56.5 5V28V51C56.5 54.0376 54.0376 56.5 51 56.5H28H5C1.96243 56.5 -0.5 54.0376 -0.5 51V28V5Z"
          stroke="#5F6368"
          stroke-opacity="0.1"
        />
        <path
          d="M20.6324 38.24H19.6324C19.3224 39.38 18.3424 40.21 17.0624 40.21C15.5624 40.21 14.4424 38.99 14.4424 37.48C14.4424 35.99 15.5624 34.76 17.0624 34.76C18.2824 34.76 19.2324 35.54 19.5824 36.62H20.6124C20.2424 35.03 18.8724 33.85 17.0724 33.85C14.9724 33.85 13.4424 35.49 13.4424 37.48C13.4424 39.48 14.9724 41.12 17.0724 41.12C18.9224 41.12 20.3024 39.87 20.6324 38.24Z"
          fill="white"
        />
      </g>
      <rect
        x="0.5"
        y="0.5"
        width="55"
        height="55"
        rx="27.5"
        stroke="#5F6368"
        stroke-opacity="0.1"
      />
      <defs>
        <clipPath id="clip0_3898_100916">
          <rect width="56" height="56" rx="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CChartIcon;
