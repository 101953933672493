/* eslint-disable graphql/template-strings */
import {
  GetProfileQuestionsQuery,
  GetProfileQuestionsQueryVariables,
  useGetProfileQuestionsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query GetProfileQuestions($questionType: ProfileQuestionEnum!) {
    getProfileQuestions(questionType: $questionType) {
      id
      isActive
      questionType
      question
    }
  }
`;
function useGetprofileQuestions(
  variables?: GetProfileQuestionsQueryVariables,
): {
  questions?: GetProfileQuestionsQuery["getProfileQuestions"];
} & UseQueryResult<GetProfileQuestionsQuery, unknown> {
  const response = useGetProfileQuestionsQuery(variables, { suspense: true });
  return { questions: response.data?.getProfileQuestions || [], ...response };
}

export { useGetprofileQuestions };
