import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Button } from "components/base";
import { isFuture, isPast } from "date-fns";
import { Phase } from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { BracketsCurly, X } from "phosphor-react";
import { usePulseCheckResponseById } from "queries/use-pulse-check-response";
import { useTemperatureResponseById } from "queries/use-temp-response";
import React, { Fragment, useState } from "react";
import { DeepPartial } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";
import { openInNewTab } from "utils/helpers/open-in-new-tab";

import { VStack } from "./layout/Stack";
import PulseChecksCards from "./PulseChecksCards";
import { Display2, Heading3, Paragraph1 } from "./Typography";

interface PhaseGoalModal {
  isOpen: boolean;
  closeModal: () => void;
  phase?: DeepPartial<Phase>;
  activeWeek: number;
}

export default function PhaseGoalModal({
  isOpen,
  closeModal,
  phase,
  activeWeek,
}: PhaseGoalModal): JSX.Element {
  const { projectId = "" } = useParams();
  const hasAdminAccess = useProjectAccessLevel({ projectId }).hasAdminAccess;
  const [showError, setShowError] = useState(false);
  const phasePosition = (phase?.position as number) + 1;
  const [phasePulseCheckFlag] = useState<boolean>(true);

  const { csvUrl } = useTemperatureResponseById(
    {
      id: phase?.id || "",
    },
    hasAdminAccess,
  );

  const exportCSVFile = () =>
    !!csvUrl ? openInNewTab(csvUrl || "") : setShowError(true);

  const { pulseCheckResponses } = usePulseCheckResponseById({
    id: phase?.id || "",
  });

  const isPhaseOver = isPast(new Date(phase?.endDate as string));
  const phaseYetToStart = isFuture(new Date(phase?.startDate as string));
  const [phaseStartDate] = useState(phase?.startDate as string);
  const [phaseEndDate] = useState(phase?.endDate as string);

  const renderCurrentPhase = () => {
    if (isPhaseOver) {
      return t("shared.phaseCompleted");
    } else if (phaseYetToStart) {
      return t("shared.phaseNotStarted");
    } else {
      return `${t("shared.phase")} ${phasePosition} ${t(
        "shared.week",
      ).toLowerCase()} ${activeWeek}`;
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={clsx("fixed inset-0 overflow-y-auto", Z_INDEX_LEVELS.MODAL)}
        onClose={closeModal}
      >
        <div className="flex min-h-screen items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative flex min-h-screen w-full bg-white">
              <Button
                className="absolute top-12 right-10"
                variant="tertiary"
                RightIcon={X}
                onClick={() => closeModal()}
              />
              <BracketsCurly
                size={64}
                color="#EB8424"
                weight="thin"
                className="absolute ml-14 mt-14 mb-12 text-secondary-orange-70"
              />
              <VStack className="mt-36 w-[55%] px-16">
                <VStack space={3} className="mb-14">
                  <Heading3 className="font-medium text-neutral-70">
                    {renderCurrentPhase()}
                  </Heading3>
                  <Display2>{phase?.name}</Display2>
                </VStack>
                {phase?.goal ? (
                  <VStack
                    space={4}
                    className="w-[544px] rounded-2xl bg-neutral-0 p-6"
                  >
                    <Heading3 className="font-medium text-neutral-90">
                      {t("shared.phaseGoal")}
                    </Heading3>
                    <Paragraph1 className="text-neutral-90">
                      {phase?.goal}
                    </Paragraph1>
                  </VStack>
                ) : (
                  <></>
                )}
              </VStack>
              <VStack align="center" space={4} className="mt-36 px-16">
                <PulseChecksCards
                  phasePulseCheckFlag={phasePulseCheckFlag}
                  phaseStartDate={phaseStartDate}
                  phaseEndDate={phaseEndDate}
                />
              </VStack>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
