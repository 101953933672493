/**
 * Determines if both datetimes are in the same half of the day.
 * ie, either both before noon or both after noon.
 */
export const getIsSameAMPM = (
  startDateTime: Date | undefined,
  endDateTime: Date | undefined,
) => {
  const startHour = startDateTime ? startDateTime.getHours() : undefined;
  const endHour = endDateTime ? endDateTime.getHours() : undefined;

  if ((!startHour && endHour) || (startHour && !endHour)) {
    return false;
  }

  const safeStartHour = startHour as number;
  const safeEndHour = endHour as number;
  return (
    (safeStartHour <= 12 && safeEndHour <= 12) ||
    (safeStartHour > 12 && safeEndHour > 12)
  );
};
