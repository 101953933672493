import {
  AhaMediumActiveIcon,
  AhaMediumIcon,
  AhaSmallActiveIcon,
  AhaSmallIcon,
  CheckinMediumActiveIcon,
  CheckinMediumIcon,
  CheckinSmallActiveIcon,
  CheckinSmallIcon,
  DecisionMediumActiveIcon,
  DecisionMediumIcon,
  DecisionSmallActiveIcon,
  DecisionSmallIcon,
  KickoffMediumActiveIcon,
  KickoffMediumIcon,
  KickoffSmallActiveIcon,
  KickoffSmallIcon,
  PresentationMediumActiveIcon,
  PresentationMediumIcon,
  PresentationSmallActiveIcon,
  PresentationSmallIcon,
  ShareoutMediumActiveIcon,
  ShareoutMediumIcon,
  ShareoutSmallActiveIcon,
  ShareoutSmallIcon,
  WorkshopMediumActiveIcon,
  WorkshopMediumIcon,
  WorkshopSmallActiveIcon,
  WorkshopSmallIcon,
} from "icons";
import React from "react";

import {
  MilestoneIconProps,
  MilestoneIconSizeObjectType,
} from "./MIlestoneIcon.types";

const MEDIUM_ICON: MilestoneIconSizeObjectType = {
  kickoff: { active: KickoffMediumActiveIcon, inactive: KickoffMediumIcon },
  decision: { active: DecisionMediumActiveIcon, inactive: DecisionMediumIcon },
  presentation: {
    active: PresentationMediumActiveIcon,
    inactive: PresentationMediumIcon,
  },
  share_out: { active: ShareoutMediumActiveIcon, inactive: ShareoutMediumIcon },
  workshop: { active: WorkshopMediumActiveIcon, inactive: WorkshopMediumIcon },
  check_in: { active: CheckinMediumActiveIcon, inactive: CheckinMediumIcon },
  aha: { active: AhaMediumActiveIcon, inactive: AhaMediumIcon },
};

const SMALL_ICON: MilestoneIconSizeObjectType = {
  kickoff: { active: KickoffSmallActiveIcon, inactive: KickoffSmallIcon },
  decision: { active: DecisionSmallActiveIcon, inactive: DecisionSmallIcon },
  presentation: {
    active: PresentationSmallActiveIcon,
    inactive: PresentationSmallIcon,
  },
  share_out: { active: ShareoutSmallActiveIcon, inactive: ShareoutSmallIcon },
  workshop: { active: WorkshopSmallActiveIcon, inactive: WorkshopSmallIcon },
  check_in: { active: CheckinSmallActiveIcon, inactive: CheckinSmallIcon },
  aha: { active: AhaSmallActiveIcon, inactive: AhaSmallIcon },
};

export default function MilestoneIcon({
  variant,
  size = "medium",
  active = false,
  ...props
}: MilestoneIconProps): JSX.Element {
  const iconsObject = size === "small" ? SMALL_ICON : MEDIUM_ICON;
  const { active: ActiveIcon, inactive: InactiveIcon } = iconsObject[variant];

  if (active) {
    return <ActiveIcon {...props} />;
  }

  return <InactiveIcon {...props} />;
}
