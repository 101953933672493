import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

type ToggleButtonProps = {
  startsOn?: boolean;
  onToggle: (isActive: boolean) => void;
};

function ToggleButton({
  startsOn = false,
  onToggle,
  ...props
}: ToggleButtonProps) {
  const [isActive, setIsActive] = useState(startsOn);
  const isMounted = useRef(false);

  const handleChange = () => {
    setIsActive((prevState) => !prevState);
  };

  useEffect(() => {
    !isMounted.current ? (isMounted.current = true) : onToggle(isActive);
  }, [isActive, onToggle]);

  return (
    <button
      onClick={handleChange}
      type="button"
      className={clsx(
        "box-content h-[30px] w-16 rounded-full px-0.75",
        isActive ? "bg-primary-turquoise-50" : "bg-tint-dark-10",
      )}
      {...props}
    >
      <div className="mx-0.75x relative h-full">
        <div
          className={clsx(
            "absolute top-0.75 h-full w-1/2 transition-transform",
            isActive ? "translate-x-full" : "translate-x-0",
          )}
        >
          <div
            className={clsx(
              "absolute h-6 w-6 rounded-full bg-white shadow-md",
              isActive ? "right-0" : "left-0",
            )}
          />
        </div>
      </div>
    </button>
  );
}

export default ToggleButton;
