import {
  GetWorkshopPersonalityProfileQuery,
  GetWorkshopPersonalityProfileQueryVariables,
  useGetWorkshopPersonalityProfileQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query GetWorkshopPersonalityProfile($workshopId: ID!) {
    getWorkshopPersonalityProfile(workshopId: $workshopId) {
      profileBreakdown
      discType
      discSegment
    }
  }
`;

function useWorkshopPersonalityProfile(
  variables: GetWorkshopPersonalityProfileQueryVariables,
): {
  getWorkshopPersonalityProfile?: GetWorkshopPersonalityProfileQuery["getWorkshopPersonalityProfile"];
} & UseQueryResult<GetWorkshopPersonalityProfileQuery, unknown> {
  const response = useGetWorkshopPersonalityProfileQuery(variables);

  return {
    getWorkshopPersonalityProfile: response.data?.getWorkshopPersonalityProfile,
    ...response,
  };
}
export { useWorkshopPersonalityProfile };
