import { Input } from "components/base";
import { MemoizeWordCloud } from "components/base/WordCloud/WordCloud";
import { VStack } from "components/layout";
import { PollAnswerBar } from "components/partial";
import { PollResponseCard } from "components/partial/PollResponseCard";
import { EmptyResponsesChip } from "components/partial/PostSessionActivity/components";
import { Caption2 } from "components/Typography";
import { Poll, PollAnswer, PollOption, PollPollType } from "graphql/generated";
import { t } from "i18n-js";
import { uniqueId } from "lodash";
import React, { useMemo, useState } from "react";

import { WorkshopActivityType } from "../LiveWorkshopActivity/LiveWorkshopActivity.types";

interface PollUIDisplayProps {
  participantAnswers: PollAnswer[] | undefined;
  setPollAnswer: React.Dispatch<React.SetStateAction<string[]>>;
  showResults: boolean;
  activity: Omit<WorkshopActivityType, "Discussion" | "Breakout" | "Resource">;
}

const PollUIDisplay = ({
  participantAnswers,
  setPollAnswer,
  showResults,
  activity,
}: PollUIDisplayProps) => {
  const [showWordCloudError, setShowWordCloudError] = useState(false);
  const {
    anonymous,
    answers,
    options,
    paragraphAnswers,
    pollType,
    wordCloudCount,
  } = activity as Poll;

  // TODO: perisist border color across open ended poll state https://coeurajtech.atlassian.net/browse/R10-479
  const renderPollResponseCard = useMemo(() => {
    return paragraphAnswers?.map((answer) => (
      <span key={uniqueId("pollResponseCard-")}>
        <PollResponseCard
          userIsAnonymous={anonymous === true}
          responseContent={answer.answer}
          user={answer.workshopParticipant.user}
        />
      </span>
    ));
  }, [paragraphAnswers]);

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputIndex: number,
  ) => {
    setPollAnswer((prev) => {
      const newIndexValue = event.target.value;
      const newArray = [...prev];
      newArray[inputIndex] = newIndexValue;
      if (newArray.some((el) => el.trim().split(" ").length > 1)) {
        setShowWordCloudError(true);
      } else {
        setShowWordCloudError(false);
      }
      return newArray;
    });
  };

  switch (pollType) {
    case PollPollType.SingleChoice:
    case PollPollType.MultipleChoice:
      return (
        <PollAnswerBar
          pollOptions={options as PollOption[] | undefined}
          pollType={pollType}
          showResultAttributes={showResults}
          setPollAnswer={setPollAnswer}
          participantAnswers={participantAnswers}
          anonymous={anonymous}
        />
      );

    case PollPollType.WordCloud:
      return showResults ? (
        <>
          {!wordCloudCount || wordCloudCount.length === 0 ? (
            <EmptyResponsesChip className="w-full" />
          ) : (
            <div className="max-h-[60%]">
              <h1 className="opacity-0">Placeholder</h1>
              <MemoizeWordCloud words={wordCloudCount} />
            </div>
          )}
        </>
      ) : (
        <VStack space={4}>
          <Input
            placeholder={t("pollActivityEditor.enterWord")}
            initialCount={0}
            className="h-[60px]"
            showCounter
            maxLength={25}
            onChange={(e) => handleChangeInput(e, 0)}
          />
          <Input
            placeholder={t("pollActivityEditor.enterAnotherWord")}
            initialCount={0}
            className="h-[60px]"
            showCounter
            maxLength={25}
            onChange={(e) => handleChangeInput(e, 1)}
          />
          <Input
            placeholder={t("pollActivityEditor.enterAnotherWord")}
            initialCount={0}
            className="h-[60px]"
            showCounter
            maxLength={25}
            onChange={(e) => handleChangeInput(e, 2)}
          />
          {showWordCloudError && (
            <Caption2 className="text-center text-red-500">
              {t("errors.responsesWithSpacesAreSingleWord")}
            </Caption2>
          )}
        </VStack>
      );

    case PollPollType.Paragraph:
      return showResults ? (
        <>
          {!answers || answers.length === 0 ? (
            <EmptyResponsesChip className="w-full" />
          ) : (
            <div className="grid max-h-[60vh] grid-cols-2 gap-4 overflow-y-auto">
              {renderPollResponseCard}
            </div>
          )}
        </>
      ) : (
        <Input
          multiline
          placeholder={t("pollActivityEditor.inputPlaceholder")}
          initialCount={0}
          showCounter
          maxLength={250}
          rows={7}
          longCountDescription={true}
          onChange={(e) => setPollAnswer([e.target.value])}
        />
      );

    default:
      return <EmptyResponsesChip className="w-full" />;
  }
};

export default PollUIDisplay;
