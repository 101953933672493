function getLastVisitedProjectPage(id: string) {
  return localStorage.getItem(`[${id}]lastVisitedProjectPage`);
}

function setLastVisitedProjectPage(id: string, pagePath: string) {
  localStorage.setItem(`[${id}]lastVisitedProjectPage`, pagePath);
}

function removeLastVisitedProjectPage(id: string) {
  localStorage.removeItem(`[${id}]lastVisitedProjectPage`);
}

export {
  getLastVisitedProjectPage,
  removeLastVisitedProjectPage,
  setLastVisitedProjectPage,
};
