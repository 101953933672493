import clsx from "clsx";
import { XCircle } from "phosphor-react";
import React from "react";

import { HStack } from "../../layout/Stack";
import { Base2 } from "../../Typography";

function Pill({
  fullName,
  onCancel,
  className,
  classForTextContent,
}: {
  fullName: string;
  onCancel: () => void;
  className?: string;
  classForTextContent?: string;
}): JSX.Element {
  return (
    <HStack
      space={2}
      align="center"
      className={clsx(
        "self-center overflow-hidden rounded-full bg-secondary-indigo-70 py-1 pl-2 pr-0.5 text-white",
        className,
      )}
    >
      <Base2 className={clsx(classForTextContent)}>{fullName}</Base2>
      <button onClick={onCancel} className="opacity-50">
        <XCircle size={24} />
      </button>
    </HStack>
  );
}

export default Pill;
