import clsx from "clsx";
import { Caption1 } from "components/Typography";
import { t } from "i18n-js";
import {
  ChatCircle,
  Gear,
  IconProps,
  MicrophoneSlash,
  Monitor,
  Record,
  SignOut,
  SquareHalf,
  SquaresFour,
  User,
  VideoCamera,
} from "phosphor-react";
import React from "react";

export enum VideoPlaceholderSizeVariant {
  Small = "small",
  Large = "large",
}
export interface VideoPlaceholderProps {
  className?: string;
  size?: VideoPlaceholderSizeVariant;
}

const TOP_ICONS_SIZE = 20;
const BOTTOM_ICONS_REGULAR_SIZE = 28;
const BOTTOM_ICONS_SMALL_SIZE = 20;

export const VideoPlaceholder = ({
  className,
  size = VideoPlaceholderSizeVariant.Large,
}: VideoPlaceholderProps) => (
  <div className={clsx("relative flex flex-col", className)}>
    {/* Overlay */}
    <div className="absolute inset-0 flex bg-neutral-700/70 px-8">
      <Caption1 className="mx-auto my-auto h-min rounded-lg bg-black px-3 py-2 text-center text-white">
        {t("workshop.preview.videoPlaceholder.videosHere")}
      </Caption1>
    </div>

    {/* Upper controls */}
    <div className="flex justify-between bg-white px-4 py-3">
      <div className="flex items-center gap-2">
        <Gear size={TOP_ICONS_SIZE} />
        <Caption1>
          {t("workshop.preview.videoPlaceholder.peopleCount", { count: 0 })}{" "}
          {t("workshop.preview.videoPlaceholder.inCall")}
        </Caption1>
      </div>

      <div className="flex items-center gap-1">
        <Caption1>{t("workshop.preview.videoPlaceholder.gridView")}</Caption1>
        <SquareHalf size={TOP_ICONS_SIZE} />
        <SquaresFour size={TOP_ICONS_SIZE} />
      </div>
    </div>

    {/* Center filler */}
    <div className="flex flex-1 bg-neutral-900" />

    {/* Lower controls */}
    <div className="flex justify-between bg-white px-4 py-3">
      <div className="flex">
        <FakeIconButton
          icon={VideoCamera}
          size={size}
          label={t("workshop.preview.videoPlaceholder.turnOff")}
        />
        <FakeIconButton
          icon={MicrophoneSlash}
          size={size}
          label={t("workshop.preview.videoPlaceholder.unmute")}
        />
      </div>

      <div className="flex">
        <FakeIconButton
          icon={User}
          size={size}
          label={t("workshop.preview.videoPlaceholder.people")}
        />
        <FakeIconButton
          icon={ChatCircle}
          size={size}
          label={t("workshop.preview.videoPlaceholder.chat")}
        />
        <FakeIconButton
          icon={Monitor}
          size={size}
          label={t("workshop.preview.videoPlaceholder.share")}
        />
        <FakeIconButton
          icon={Record}
          size={size}
          label={t("workshop.preview.videoPlaceholder.record")}
        />
      </div>

      <div className="flex">
        <FakeIconButton
          icon={SignOut}
          size={size}
          label={t("workshop.preview.videoPlaceholder.record")}
        />
      </div>
    </div>
  </div>
);

const FakeIconButton = ({
  icon,
  label,
  size = VideoPlaceholderSizeVariant.Large,
}: {
  icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  label?: string;
  size?: VideoPlaceholderSizeVariant;
}) => {
  const Icon = icon;

  return (
    <div
      className={clsx("mx-2 flex flex-col items-center gap-2", {
        "max-w-[12px]": size === VideoPlaceholderSizeVariant.Small,
      })}
    >
      <Icon
        size={
          size === VideoPlaceholderSizeVariant.Small
            ? BOTTOM_ICONS_SMALL_SIZE
            : BOTTOM_ICONS_REGULAR_SIZE
        }
      />
      {size !== VideoPlaceholderSizeVariant.Small && (
        <Caption1>{label}</Caption1>
      )}
    </div>
  );
};
