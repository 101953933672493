import React from "react";

export interface TeamViewPulseCheckIllustrationProps {
  width?: number;
  height?: number;
  className?: string;
}

export const TeamViewPulseCheckIllustration = ({
  width = 249,
  height = 56,
  className,
}: TeamViewPulseCheckIllustrationProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M45.3 12.5104H11.7C10.0663 12.5104 8.49955 13.1595 7.34417 14.3147C6.18899 15.4699 5.54004 17.0367 5.54004 18.6704V41.0704C5.54004 42.7043 6.18899 44.2711 7.34417 45.4262C8.49955 46.5814 10.0663 47.2304 11.7 47.2304H15.62L11.3024 51.5201C11.1839 51.6216 11.113 51.7678 11.107 51.9238C11.101 52.0797 11.1604 52.231 11.2707 52.3415C11.381 52.4518 11.5323 52.511 11.6883 52.505C11.8442 52.499 11.9906 52.4283 12.0919 52.3098L17.1319 47.2698C17.1443 47.2517 17.1556 47.2329 17.1657 47.2136H39.8457L44.8857 52.2536C45.0997 52.4069 45.3917 52.3905 45.5871 52.2139C45.7825 52.0374 45.8285 51.7487 45.6976 51.5201L41.4303 47.2528H45.3C46.9337 47.2528 48.5005 46.6039 49.6559 45.4487C50.8111 44.2935 51.46 42.7267 51.46 41.0928V18.6928C51.466 17.0553 50.8196 15.4827 49.6637 14.3226C48.5078 13.1627 46.9378 12.5104 45.3 12.5104L45.3 12.5104ZM50.34 41.0704C50.34 42.4071 49.809 43.6892 48.8638 44.6344C47.9186 45.5795 46.6368 46.1104 45.3 46.1104H11.7C10.3633 46.1104 9.08143 45.5795 8.13625 44.6344C7.19106 43.6892 6.66004 42.4071 6.66004 41.0704V18.6704C6.66004 17.3338 7.19106 16.0518 8.13625 15.1066C9.08143 14.1614 10.3633 13.6304 11.7 13.6304H45.3C46.6368 13.6304 47.9186 14.1614 48.8638 15.1066C49.809 16.0518 50.34 17.3338 50.34 18.6704V41.0704Z"
      fill="#5F6368"
      fill-opacity="0.3"
    />
    <path
      d="M13.9401 9.1504H43.0601C43.3695 9.1504 43.6201 8.89974 43.6201 8.5904C43.6201 8.28123 43.3695 8.0304 43.0601 8.0304H13.9401C13.6308 8.0304 13.3801 8.28123 13.3801 8.5904C13.3801 8.89974 13.6308 9.1504 13.9401 9.1504Z"
      fill="#5F6368"
      fill-opacity="0.3"
    />
    <path
      d="M25.1401 4.62H31.8601C32.1694 4.62 32.4201 4.36935 32.4201 4.06C32.4201 3.75065 32.1694 3.5 31.8601 3.5H25.1401C24.8307 3.5 24.5801 3.75065 24.5801 4.06C24.5801 4.36935 24.8307 4.62 25.1401 4.62Z"
      fill="#5F6368"
      fill-opacity="0.3"
    />
    <path
      d="M44.74 39.5752H40.3664V31.1752C40.3664 30.8661 40.1156 30.6152 39.8064 30.6152C39.4971 30.6152 39.2464 30.8661 39.2464 31.1752V39.5752H33.6464V33.9752C33.6464 33.6661 33.3956 33.4152 33.0864 33.4152C32.7771 33.4152 32.5264 33.6661 32.5264 33.9752V39.5752H26.9264V27.2552C26.9264 26.9461 26.6756 26.6952 26.3664 26.6952C26.0571 26.6952 25.8064 26.9461 25.8064 27.2552V39.5752H20.1V31.668C20.1 31.3587 19.8494 31.108 19.54 31.108C19.2307 31.108 18.98 31.3587 18.98 31.668V39.5752H13.66V22.1592C13.66 21.8499 13.4094 21.5992 13.1 21.5992C12.7907 21.5992 12.54 21.8499 12.54 22.1592V40.1352C12.54 40.2838 12.5991 40.4262 12.7041 40.5312C12.8091 40.6364 12.9515 40.6952 13.1 40.6952H44.74C45.0494 40.6952 45.3 40.4446 45.3 40.1352C45.3 39.8261 45.0494 39.5752 44.74 39.5752H44.74Z"
      fill="#5F6368"
      fill-opacity="0.3"
    />
  </svg>
);
