import { Base1, Base2, Caption1, HStack, VStack } from "components";
import { ReadOnlyRichTextEditor, UserPill } from "components/base";
import {
  ConfirmDialog,
  InteractionModal,
  WorkshopResources,
} from "components/partial";
import { format, parseISO } from "date-fns";
import { t } from "i18n-js";
import { useDeleteWorkshop } from "mutations/use-delete-workshop";
import useUpdateHighlightOfWorkshop from "mutations/use-update-highlight-workshop";
import { CaretRight, Presentation } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { WeekFeedWorkshopType } from "./pages/WeekView/WeekFeed";
import WorkshopCardButtonGroup from "./pages/WeekView/WorkshopDetailsButtonGroup";

type WorkshopDetailsModalProps = {
  show: boolean;
  onClose: () => void;
  workshop: WeekFeedWorkshopType;
  isParticipant: boolean;
};

export default function WorkshopDetailsModal({
  show,
  onClose,
  workshop,
  isParticipant,
}: WorkshopDetailsModalProps) {
  const {
    id = "",
    workshopTitle,
    title, // alternate value for workshopTitle
    createdAt,
    workshopDescription,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    description, // alternate value for workshopDescription
    links = [],
    workshopParticipants,
    state,
    documents = [],
  } = workshop;

  const queryClient = useQueryClient();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [requestError, setRequestError] = useState("");

  const startTime = parseISO(workshop.startTime);
  const endTime = parseISO(workshop.endTime);
  const formattedStartTime = format(startTime, "h:mm");
  const formattedEndTime = format(endTime, "h:mm a");
  const formattedDate = format(startTime, "EEEE, MMM d");
  const formattedCreatedAt = format(
    parseISO(createdAt as string),
    "MMM d, yyyy",
  );

  const { mutate: deleteWorkshop } = useDeleteWorkshop();

  const { mutate: updateHighlightOfWorkshop } = useUpdateHighlightOfWorkshop();

  const { highlight } = workshop || {};
  const [isHighlighted, setHighlighted] = useState(!!highlight);

  useEffect(() => {
    updateHighlightOfWorkshop({
      input: {
        id: workshop.id || "",
        highlight: isHighlighted || false,
      },
    });
  }, [isHighlighted]);

  const onSubmitSuccess = () => {
    setRequestError("");
    onClose();
  };

  const onDelete = () => {
    deleteWorkshop(
      { workshopId: id },
      {
        onSuccess: () => {
          setShowConfirmDeleteModal(false);
          onSubmitSuccess();
          void queryClient.refetchQueries(["ProjectById"]);
        },
        onError: () => {
          setRequestError(t("errors.somethingWentWrong"));
          setShowConfirmDeleteModal(false);
        },
      },
    );
  };

  const handleToggle = (isActive: boolean) => {
    setHighlighted(isActive);
  };

  return (
    <InteractionModal
      isOpen={show}
      closeModal={onClose}
      header={workshopTitle || title || ""}
      icon={<Presentation />}
      variant="blue"
      slideFromLeft
      onToggle={handleToggle}
      showHighlightFeature={true}
      isHighlighted={!!isHighlighted}
    >
      <div className="flex-grow overflow-y-auto px-14 py-12">
        <div className="mb-4 flex h-6">
          {/* TODO: Add createdby when available on workshop
            <Avatar
              user={host}
              className="mb-4"
              size="mini"
              textClassName="!text-xs"
            />
          */}
          <Caption1 className="self-center text-neutral-70">
            {t(`component.workshopCard.createdDate`)}
            <span className="ml-2 text-neutral-40">{formattedCreatedAt}</span>
          </Caption1>
        </div>
        <HStack space={2} align="center" className="text-neutral-90">
          <Base1>{formattedStartTime}</Base1>
          <CaretRight className="h-6 w-6" />
          <Base1>{formattedEndTime}</Base1>
          <Base1 className="pl-6 text-neutral-70">{formattedDate}</Base1>
        </HStack>
        <ReadOnlyRichTextEditor
          className="mt-4 pb-8 text-neutral-70"
          readOnlyContent={workshopDescription || description}
        />
        <Base2 className="mt-16 mb-2 text-neutral-90">
          {t("component.meetingCard.guests", {
            guests: workshopParticipants.length,
          })}
        </Base2>
        <HStack space={2} className="overflow-x-auto">
          {workshopParticipants?.map(({ user }) =>
            user ? <UserPill key={user.id} user={user} /> : <></>,
          )}
        </HStack>
        {links?.length || documents?.length ? (
          <VStack className="mt-8" space={4}>
            <Base1 className="text-neutral-90">{t("shared.links")}</Base1>
            <WorkshopResources
              className="h-2/3"
              links={links}
              documents={documents}
              workshopId={id}
              hasTitle={false}
              hasBorder={false}
              isEditable={false}
            />
          </VStack>
        ) : (
          <></>
        )}
      </div>
      <HStack
        className="px-14 pt-4 pb-6 shadow-t-lg"
        justify="between"
        align="center"
      >
        <WorkshopCardButtonGroup
          id={id}
          state={state}
          isParticipant={isParticipant}
          onDelete={() => setShowConfirmDeleteModal(true)}
        />
      </HStack>
      <ConfirmDialog
        open={showConfirmDeleteModal}
        onCancel={() => setShowConfirmDeleteModal(false)}
        onConfirm={onDelete as () => void}
      />
    </InteractionModal>
  );
}
