import {
  PulseCheckEntriesQuery,
  PulseCheckEntriesQueryVariables,
  usePulseCheckEntriesQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query PulseCheckEntries($input: TeamPulseCheckInput!) {
    getPulseCheck(input: $input) {
      pulseCheckHealth
      repeatationInweeks
      pulseCheckEntries {
        date
        numberOfResponses
        average
        highestScore
        lowestScore
        autonomy {
          average
          numberOfResponses
          lowestScore
          highestScore
        }
        alignment {
          average
          numberOfResponses
          lowestScore
          highestScore
        }
        connection {
          average
          numberOfResponses
          lowestScore
          highestScore
        }
        energy {
          average
          numberOfResponses
          lowestScore
          highestScore
        }
        highestScore
        lowestScore
      }
    }
  }
`;

function usePulseCheckEntries(variables: PulseCheckEntriesQueryVariables): {
  pulseCheckEntries: PulseCheckEntriesQuery["getPulseCheck"];
} & UseQueryResult<PulseCheckEntriesQuery, unknown> {
  const response = usePulseCheckEntriesQuery(variables);

  return {
    pulseCheckEntries: response?.data?.getPulseCheck || [],
    ...response,
  };
}
export { usePulseCheckEntries };
