import clsx from "clsx";
import {
  Base1,
  Base2,
  Display3,
  Heading1Strong,
  HStack,
  Paragraph3,
  VStack,
} from "components";
import { Button } from "components/base";
import ConfirmDialog from "components/partial/ConfirmDialog";
import { useGetEnvsContext } from "contexts";
import {
  OrganizationMembershipAccessLevel,
  Scalars,
  useLeaveWorkspaceMutation,
} from "graphql/generated";
import { t } from "i18n-js";
import { noop } from "lodash";
import useUpdateOrganization from "mutations/use-update-organisation";
import { SignOut } from "phosphor-react";
import { useOrganizationMemberships } from "queries";
import { useOrganizationById } from "queries/use-organization";
import { useOrganizationSettings } from "queries/use-organization-settings";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  getLastVisitedProjectPage,
  removeLastVisitedProjectPage,
} from "utils/helpers/last-visited-project-page";

import { SettingsField } from "../UserSettings/components/SettingsField";
import SettingsMenuCloseButton from "./SettingsMenuCloseButton";
import { isOrganizationAdmin } from "./WorkspaceProjectsContent";
import WorkspaceSettingImageUpload from "./WorkspaceSettingImageUpload";

function WorkspaceSettingsContent({
  onClose,
  organizationId,
}: {
  onClose: () => void;
  organizationId: Scalars["ID"];
}) {
  const [DISPLAY_LEAVE_WORKSHOP, SET_DISPLAY_LEAVE_WORKSHOP] = useState(
    process.env.SHOW_LEAVE_WORKSHOP === "true",
  );

  const [deleteWorkSpace, setDeleteWorkSpace] = useState(false);
  const { projectId = "" } = useParams();
  const { currentUser } = useGetEnvsContext();
  const { mutate: updateOrganization } = useUpdateOrganization();

  const queryClient = useQueryClient();
  const { organizationSettingsByOrganization } = useOrganizationSettings({
    organizationId,
  });

  const { envs } = useGetEnvsContext();

  useEffect(() => {
    if (envs) {
      const { SHOW_LEAVE_WORKSHOP } = envs;
      SET_DISPLAY_LEAVE_WORKSHOP(SHOW_LEAVE_WORKSHOP === "true");
    }
  }, [envs]);

  const joinDate = new Date(
    organizationSettingsByOrganization?.joinedDate || " ",
  )
    .toLocaleString("en-us", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");

  const { organizationById: currentOrganization } = useOrganizationById({
    id: organizationId,
  });

  const [defaultValue, setDefaultValue] = useState(
    currentOrganization?.name || "",
  );

  const [isEditable, setIsEditable] = useState(false);
  const [error, setError] = useState("");
  const [workspaceName, setWorkspaceName] = useState(
    currentOrganization?.name || "",
  );

  const handleLocalStorage = async (userId: string) => {
    const lastVisitedProjectPage = getLastVisitedProjectPage(userId);
    try {
      if (!!lastVisitedProjectPage) {
        removeLastVisitedProjectPage(userId);
        void handleLocalStorage(userId);
      } else {
        const resp = await leaveWorkspace({
          projectId,
        });
        if (!!resp.leaveWorkspace?.message) {
          setDeleteWorkSpace(false);
          navigate("/");
        }
      }
    } catch (error) {
      console.error(error);
      setDeleteWorkSpace(false);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    setError("");

    if (!workspaceName) {
      setError("Cannot be left blank");
      setLoading(false);
      return;
    }

    if (!workspaceName.trim()) {
      setError("Please remove leading spaces.");
      setLoading(false);
      return;
    }

    if (workspaceName !== currentOrganization.name) {
      try {
        updateOrganization({
          input: {
            id: currentOrganization?.id,
            name: workspaceName.trim(),
          },
        });
        void queryClient.refetchQueries(["OrganizationById"]);
        void queryClient.refetchQueries(["UserOrganizations"]);
        setIsEditable(false);
        setDefaultValue(workspaceName.trim());
      } catch {
        console.error;
      }
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    try {
      if (!!currentUser) {
        await handleLocalStorage(currentUser?.id);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const workplaceSetting = "text-5xl";
  const { mutateAsync: leaveWorkspace } = useLeaveWorkspaceMutation();
  const navigate = useNavigate();
  const { memberships } = useOrganizationMemberships({ organizationId });

  const isAdmin = isOrganizationAdmin(memberships, currentUser?.id);
  const adminMembers = memberships.filter(
    (membership) =>
      membership.accessLevel === OrganizationMembershipAccessLevel.AdminAccess,
  );

  return (
    <VStack
      space={16}
      className="grow overflow-y-auto px-14 py-8"
      data-testid="settings-workspace-settings"
    >
      <VStack space={6}>
        <HStack justify="between" align="center" className="mb-12">
          <Display3 data-testid="settings-workspace-title">
            {t("component.settings.tabs.settings.title")}
          </Display3>
          <SettingsMenuCloseButton onClose={onClose} />
        </HStack>
      </VStack>
      <VStack>
        <Heading1Strong>
          {t("component.settings.tabs.settings.subTitle")}
        </Heading1Strong>
        <HStack className="items-center py-4">
          {DISPLAY_LEAVE_WORKSHOP ? (
            <>
              <VStack space={2} className="mt-8 flex-1 ">
                <Base1>{organizationSettingsByOrganization?.fullName}</Base1>
                <Base1 className="text-neutral-70"> {joinDate}</Base1>
              </VStack>
              <VStack>
                <>
                  <Button
                    onClick={() => setDeleteWorkSpace(true)}
                    variant="destructive"
                    disabled={!!isAdmin && adminMembers?.length <= 1}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignContent: "center",
                        width: "200px",
                      }}
                      data-testid="LeaveWorkspace"
                    >
                      <SignOut size={24} />
                      Leave workspace
                    </div>
                  </Button>
                </>
              </VStack>
            </>
          ) : (
            <VStack space={2} className="mt-8 flex-1 ">
              <Base2>
                {t("component.settings.tabs.settings.disabledMessage")}
              </Base2>
            </VStack>
          )}

          <ConfirmDialog
            open={deleteWorkSpace}
            onCancel={() => setDeleteWorkSpace(false)}
            onConfirm={handleDelete as () => void}
            loading={loading}
            title={t("component.settings.tabs.settings.leaveWorkSpace.title", {
              organizationName: currentOrganization?.name,
            })}
            subtitle={t(
              "component.settings.tabs.settings.leaveWorkSpace.subTitle",
            )}
            confirmButtonText={t(
              "component.settings.tabs.settings.leaveWorkSpace.confirmButtonText",
            )}
            cancelButtonText={t(
              "component.settings.tabs.settings.leaveWorkSpace.cancelButtonText",
            )}
          />
        </HStack>
      </VStack>
      {!!isAdmin && (
        <VStack space={8}>
          <Heading1Strong className="mt-4">
            {t("component.settings.tabs.settings.workspace.title")}
          </Heading1Strong>
          <VStack>
            <Base1 className="mt-8">
              {t("component.settings.tabs.settings.workspace.image")}
            </Base1>
          </VStack>
          <HStack align="center" space={2}>
            <WorkspaceSettingImageUpload
              show={show}
              workplaceSetting={workplaceSetting}
              organizationId={currentOrganization.id}
              from={"organization"}
              organizationName={currentOrganization?.name}
              organizationImageUrl={
                currentOrganization.organizationImageUrl || ""
              }
            />
          </HStack>
          <VStack>
            <Base1>
              {t("component.settings.tabs.settings.workspace.name")}
            </Base1>
            <HStack
              className={clsx(
                "items-center border-b-2 py-4",
                isEditable ? "text-gray-500" : "",
              )}
            >
              <SettingsField
                underline={false}
                label={""}
                editFlag={isEditable}
                name="name"
                type="text"
                defaultValue={defaultValue}
                placeholder={t(
                  "component.settings.tabs.settings.workspace.placeholder",
                )}
                onChange={(value) => {
                  setWorkspaceName(value);
                }}
                onSubmit={noop}
              />

              <div className="flex gap-2">
                {isEditable ? (
                  <Button
                    variant="primary"
                    className="min-w-min self-start px-8 text-neutral-90"
                    disabled={
                      loading || !workspaceName || !workspaceName.trim()
                    }
                    onClick={handleSubmit}
                  >
                    {t("component.settings.tabs.settings.workspace.save")}
                  </Button>
                ) : (
                  <Button
                    variant="tertiary"
                    className="min-w-min self-start px-8 text-neutral-90"
                    disabled={loading}
                    onClick={() => setIsEditable(true)}
                  >
                    {t("component.workshopCard.edit")}
                  </Button>
                )}
                {isEditable && (
                  <Button
                    onClick={() => {
                      setIsEditable(false);
                      setDefaultValue(`${defaultValue} `);
                    }}
                    variant="destructive"
                    className="min-w-min self-start !px-6"
                    aria-label="Delete Announcement"
                    type="button"
                    disabled={loading}
                  >
                    <Base2 className="px-1">
                      {t("component.settings.tabs.settings.workspace.cancel")}
                    </Base2>
                  </Button>
                )}
              </div>
            </HStack>
            <HStack>
              {error && (
                <Paragraph3 className="text-red-400">{error}</Paragraph3>
              )}
            </HStack>
          </VStack>
        </VStack>
      )}
    </VStack>
  );
}
export default WorkspaceSettingsContent;
