/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import clsx from "clsx";
import {
  LiveWorkshopHeader,
  MissingDataErrorDisplay,
  PageLoader,
  VideoCall,
  WorkshopFooter,
  WorkshopSidebar,
} from "components/partial";
import { Caption2 } from "components/Typography";
import { useGetEnvsContext } from "contexts";
import {
  Agenda,
  WorkshopParticipantRole,
  WorkshopState,
} from "graphql/generated";
import { useWorkshopParticipant } from "hooks";
import { t } from "i18n-js";
import { useWorkshop } from "queries";
import { useWorkshopByIdStatus } from "queries/use-workshop-status";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useWorkshopActivitySubscription,
  useWorkshopStateSubscription,
} from "subscriptions";

import {
  LiveWorkshopActivity,
  useWorkshopContext,
  WaitingRoomIntro,
  WorkshopComplete,
  WorkshopProvider,
} from "./components";
import { VideoSize } from "./Workshop.types";

/**
 * Workshop handles the Live Workshop and Waiting Room (TBD on post-session) states of workshops.
 */
function BaseWorkshop() {
  const [videoCallSize, setVideoCallSize] = React.useState<VideoSize>(
    VideoSize.Large,
  );
  const [videcallWidth, setVideoCallWidth] = React.useState<string>("");
  /**
   * TODO: when "WorkshopCompleted" Event comes from gql Subscription,
   * we'll need to track it and trigger `workshopJustFinished` to change to "true".
   * https://coeurajtech.atlassian.net/browse/R10-243
   * https://coeurajtech.atlassian.net/browse/R10-245
   */
  const [workshopJustFinished] = React.useState(true);
  const [isMeetingBeingRecorded, setIsMeetingBeingRecorded] =
    React.useState(false);
  const { id: workshopId = "" } = useParams();
  const { updateParticipant, currentWorkshopParticipant: contextParticipant } =
    useWorkshopContext();
  const { workshop, isLoading, isError } = useWorkshop({
    id: workshopId,
  });
  const { data } = useWorkshopByIdStatus({
    id: workshopId,
  });

  const navigate = useNavigate();

  useWorkshopActivitySubscription({
    workshopId: workshopId,
  });

  useWorkshopStateSubscription({
    workshopId: workshopId,
  });

  React.useEffect(() => {
    if (isError && !data) {
      navigate(`/login`);
    } else if (!isLoading && !workshop) {
      navigate(`/workshop/unauthorized`);
    }
  }, [data, workshop, isLoading]);

  const { state, room, workshopParticipants } = workshop || {};

  const { currentUser } = useGetEnvsContext();
  const currentWorkshopParticipant = useWorkshopParticipant(
    workshop,
    currentUser,
  );
  const {
    role,
    raiseHand,
    id: currentParticipantId,
  } = currentWorkshopParticipant;

  React.useEffect(() => {
    if (!contextParticipant || contextParticipant.id !== currentParticipantId) {
      updateParticipant(currentWorkshopParticipant);
    }
    if (state === WorkshopState.Published || state === WorkshopState.Draft) {
      setVideoCallSize(VideoSize.ExtraLarge);
    }
  }, [
    contextParticipant,
    currentWorkshopParticipant,
    currentParticipantId,
    updateParticipant,
  ]);

  const currentWorkshopUser = workshopParticipants?.find(
    (participant) => participant.user.id === currentUser?.id,
  );

  const currentUserToken = currentWorkshopUser?.roomToken;

  const workshopIsToday = React.useMemo(() => {
    const today = new Date();
    const workshopDate = new Date(workshop?.startTime || "");
    return (
      today.getDate() === workshopDate.getDate() &&
      today.getMonth() === workshopDate.getMonth() &&
      today.getFullYear() === workshopDate.getFullYear()
    );
  }, [workshop?.startTime]);
  return workshop ? (
    <div className="flex h-screen max-h-screen flex-col overflow-hidden">
      {state === WorkshopState.PostSession ? (
        role && (
          <WorkshopComplete
            userRole={role}
            workshopJustFinished={workshopJustFinished}
          />
        )
      ) : (
        <>
          <LiveWorkshopHeader
            role={role}
            isMeetingBeingRecorded={isMeetingBeingRecorded}
          />

          {!workshop ? (
            <>
              {isLoading && <PageLoader isShowing />}
              {isError && <MissingDataErrorDisplay />}
            </>
          ) : (
            <>
              <div
                className={clsx(
                  "relative flex  flex-1 flex-row overflow-auto",
                  {
                    "max-h-[calc(100%-177px)]":
                      role !== WorkshopParticipantRole.Participant,
                    "max-h-full": role === WorkshopParticipantRole.Participant,
                  },
                )}
              >
                {/* PRE-SESSION */}
                {(state === WorkshopState.Published ||
                  state === WorkshopState.Draft) && (
                  <div className="ml-14 flex h-full max-w-[347px] flex-1 overflow-auto">
                    <WaitingRoomIntro
                      {...workshop}
                      role={role}
                      workshopIsToday={workshopIsToday}
                      setVideoCallWidth={setVideoCallWidth}
                    />
                  </div>
                )}

                {/* IN-SESSION */}
                {state === WorkshopState.Published && (
                  <WorkshopSidebar
                    workshopId={workshopId}
                    agenda={workshop.agenda as Agenda[]}
                    showAgenda={workshop.isAgendaVisibleToParticipants}
                    user={currentWorkshopParticipant}
                  />
                )}
                {state === WorkshopState.LiveSession && (
                  <>
                    <WorkshopSidebar
                      workshopId={workshopId}
                      agenda={workshop.agenda as Agenda[]}
                      showAgenda={workshop.isAgendaVisibleToParticipants}
                      user={currentWorkshopParticipant}
                    />

                    {/* Workshop Content */}
                    <LiveWorkshopActivity
                      workshop={workshop}
                      setVideoCallWidth={setVideoCallWidth}
                      role={role}
                      handleVideoCallSizeChange={(size) =>
                        setVideoCallSize(size)
                      }
                    />
                  </>
                )}

                {/* Workshop Video */}
                {state === WorkshopState.LiveSession || workshopIsToday ? (
                  room?.name ? (
                    <VideoCall
                      roomName={room?.name || ""}
                      className={`min-w-[275px] ${
                        videcallWidth ? videcallWidth : videoCallSize
                      } `}
                      userAuthToken={currentUserToken ?? ""}
                      currentWorkshopUser={currentWorkshopParticipant}
                      workshop={workshop}
                      raisedHand={raiseHand || false}
                      setIsMeetingBeingRecorded={setIsMeetingBeingRecorded}
                    />
                  ) : (
                    <div
                      className={`min-w-[275px] ${videoCallSize} bg-neutral-10`}
                    />
                  )
                ) : (
                  // Waiting room when the workshop is not today
                  <div
                    className={`relative h-full w-full w-3/4 min-w-[275px] `}
                  >
                    <div className="absolute flex h-full w-full justify-center bg-neutral-60 bg-opacity-50">
                      <div className="flex min-w-[389px] flex-col content-center justify-center">
                        <Caption2 className="rounded-md bg-neutral-90 px-3 py-2 text-center text-white">
                          {t("workshop.waitingRoom.disabledVideo")}
                        </Caption2>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {role !== WorkshopParticipantRole.Participant && (
                <WorkshopFooter agenda={workshop.agenda} />
              )}
            </>
          )}
        </>
      )}
    </div>
  ) : (
    <PageLoader isShowing />
  );
}

export const Workshop = () => (
  <WorkshopProvider>
    <BaseWorkshop />
  </WorkshopProvider>
);
