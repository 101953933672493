import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Button, Input } from "components/base";
import { HStack, VStack } from "components/layout/Stack";
import { Base2, Caption1, Display3 } from "components/Typography";
import { MilestoneInput, MilestoneTypeEnum, Phase } from "graphql/generated";
import { t } from "i18n-js";
import { has } from "lodash";
import { X } from "phosphor-react";
import React, { Fragment, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";
import { WeeksFromProjectPerPhasesReturn } from "utils/helpers/get-weeks-from-project-per-phases";
import { VALIDATE_STRING_REGEX } from "utils/helpers/validations";

import { MAX_MILESTONE_CHARS } from "../../constants";
import MilestonesFormMilestoneTypeList from "./MilestonesFormMilestoneTypeList";

type MilestonesFormSidebarProps = {
  phaseIndexSelected?: number;
  phaseDataSelected?: Partial<Phase>;
  weekSelected?: string;
  weekIndexSelected?: number;
  isOpen: boolean;
  weeksPerPhase: WeeksFromProjectPerPhasesReturn[][];
  closeModal: () => void;
  setWeeksPerPhase: React.Dispatch<
    React.SetStateAction<WeeksFromProjectPerPhasesReturn[][]>
  >;
  isEditing?: boolean;
};

function MilestonesFormSidebar({
  phaseIndexSelected = 0,
  phaseDataSelected,
  weekSelected,
  weekIndexSelected = 0,
  isOpen,
  weeksPerPhase,
  closeModal,
  setWeeksPerPhase,
  isEditing = false,
}: MilestonesFormSidebarProps) {
  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
    clearErrors,
  } = useForm<MilestoneInput>({
    mode: "onSubmit",
  });

  const initialFocusRef = useRef<HTMLButtonElement>(null);

  const onSelectMilestonesType = (milestoneType: MilestoneTypeEnum) => {
    setValue("milestoneType", milestoneType, { shouldValidate: false });
  };

  const onSubmit = () => {
    const newMilestone: WeeksFromProjectPerPhasesReturn = {
      ...weeksPerPhase[phaseIndexSelected][weekIndexSelected],
      milestoneType: getValues(`milestoneType`),
      weekNumber: weekIndexSelected,
      name: getValues(`name`),
    };

    weeksPerPhase[phaseIndexSelected][weekIndexSelected] = newMilestone;

    setWeeksPerPhase([...weeksPerPhase]);

    closeModal();
  };

  const onDelete = () => {
    const milestoneData = {
      ...weeksPerPhase[phaseIndexSelected][weekIndexSelected],
      name: undefined,
      milestoneType: undefined,
    };
    weeksPerPhase[phaseIndexSelected][weekIndexSelected] = milestoneData;

    setWeeksPerPhase(weeksPerPhase);
    closeModal();
  };

  useEffect(() => {
    if (!isOpen) return;
    reset(
      {
        name: "",
        milestoneType: undefined,
        weekNumber: 0,
      },
      { keepErrors: false, keepValues: false, keepTouched: false },
    );
    clearErrors();
  }, [isOpen, clearErrors, reset]);

  register("milestoneType", { required: true, minLength: 2 });
  register("weekNumber");

  useEffect(() => {
    setValue("weekNumber", weekIndexSelected + 1);
  }, [weekIndexSelected, setValue]);

  useEffect(() => {
    if (!phaseDataSelected || !isOpen) return;
    const savedMilestoneData = phaseDataSelected?.milestones?.find(
      (m) => m.weekNumber === weekIndexSelected,
    );
    const updatedMilestoneData =
      weeksPerPhase[phaseIndexSelected][weekIndexSelected];

    savedMilestoneData?.milestoneType &&
      setValue("milestoneType", savedMilestoneData.milestoneType);

    updatedMilestoneData?.milestoneType &&
      setValue("milestoneType", updatedMilestoneData.milestoneType);

    savedMilestoneData?.name && setValue("name", savedMilestoneData.name);
    updatedMilestoneData?.name && setValue("name", updatedMilestoneData.name);
  }, [
    phaseDataSelected,
    weekIndexSelected,
    phaseIndexSelected,
    weeksPerPhase,
    setValue,
    isOpen,
  ]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        open={isOpen}
        onClose={closeModal}
        className={clsx("fixed inset-0 overflow-y-auto", Z_INDEX_LEVELS.MODAL)}
        initialFocus={initialFocusRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-[#222222] bg-opacity-30" />
        </Transition.Child>
        <Transition.Child
          as="div"
          className="absolute right-0 top-0 flex h-full min-h-screen w-1/2 flex-col overflow-auto bg-white shadow-2xl"
          enter="ease-out duration-500"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="ease-in duration-300"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <form onSubmit={handleSubmit(onSubmit) as () => void}>
            <VStack className="h-screen w-full px-16 py-8">
              <HStack align="center" justify="end" className="!mb-12">
                <button
                  onClick={closeModal}
                  className="rounded-full bg-tint-dark-15 p-3"
                  ref={initialFocusRef}
                  type="button"
                >
                  <X className="text-neutral-90" size={24} />
                </button>
              </HStack>

              <VStack className="!mb-32">
                <Base2>
                  {t(`page.createProject.step.milestones.sidebar.title`, {
                    week: weekSelected,
                  })}
                </Base2>
                <Display3>
                  {isEditing
                    ? t(
                        `page.createProject.step.milestones.sidebar.editSubtitle`,
                      )
                    : t(`page.createProject.step.milestones.sidebar.subtitle`, {
                        phase: phaseIndexSelected + 1,
                      })}
                </Display3>
              </VStack>

              <VStack space={4} className="!mb-16">
                <MilestonesFormMilestoneTypeList
                  onSelect={onSelectMilestonesType}
                  selectedType={getValues("milestoneType")}
                />
              </VStack>

              <VStack space={4} className="!mb-16">
                <Input
                  label={t(
                    `page.createProject.step.milestones.sidebar.form.milestonesTitle`,
                  )}
                  placeholder="E.g. End of phase share out, or Research Workshop"
                  error={errors.name?.message}
                  showCounter
                  maxLength={40}
                  initialCount={
                    phaseDataSelected?.milestones?.filter(
                      (item) =>
                        item.milestoneType === getValues("milestoneType"),
                    )[0]?.name?.length
                  }
                  {...register("name", {
                    required: t("errors.presence"),
                    maxLength: {
                      value: MAX_MILESTONE_CHARS,
                      message: t("errors.maxLengthExceeded", {
                        size: MAX_MILESTONE_CHARS,
                      }),
                    },
                    pattern: {
                      value: VALIDATE_STRING_REGEX,
                      message: t("errors.noLeadingSpaces"),
                    },
                  })}
                />
                {has(errors, ["milestoneType"]) && (
                  <Caption1 className="text-secondary-red-70">
                    {t(
                      `page.createProject.step.milestones.sidebar.form.errorMessage`,
                    )}
                  </Caption1>
                )}
              </VStack>

              <HStack className="h-full items-end justify-between">
                <HStack space={4}>
                  <Button variant="outline" onClick={closeModal} type="button">
                    {t(`shared.cancel`)}
                  </Button>
                  {isEditing ? (
                    <Button
                      variant="destructive"
                      onClick={onDelete}
                      type="button"
                    >
                      {t(`component.editProjectForm.deleteMilestone`)}
                    </Button>
                  ) : (
                    <></>
                  )}
                </HStack>
                <Button variant="secondary" disabled={isSubmitting}>
                  {t(`shared.create`)}
                </Button>
              </HStack>
            </VStack>
          </form>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

export default MilestonesFormSidebar;
