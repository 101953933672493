import { Caption1, CenteredPage, Display3 } from "components";
import { Button, Input, Logo } from "components/base";
import { useGetEnvsContext } from "contexts";
import {
  useAcceptProjectInvitationMutation,
  useUpdateUserMutation,
} from "graphql/generated";
import { useProjectInvitationOrigin } from "hooks";
import { t } from "i18n-js";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { VALIDATE_STRING_REGEX } from "utils/helpers/validations";

type AboutYouFormData = {
  firstName: string;
  lastName: string;
  role: string;
};

function AboutYou() {
  const navigate = useNavigate();
  const { mutateAsync } = useUpdateUserMutation();
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<AboutYouFormData>({ mode: "onTouched" });
  const [submitError, setSubmitError] = useState<string | undefined>();
  const { mutateAsync: acceptProjectInvitationMutateAsync } =
    useAcceptProjectInvitationMutation();

  const { currentUser } = useGetEnvsContext();

  const {
    hasProjectInvitationOrigin,
    projectInvitationId,
    token,
    inviteeEmail,
  } = useProjectInvitationOrigin();

  const onSubmit = async (formData: AboutYouFormData) => {
    const { role, firstName, lastName } = formData;
    try {
      await mutateAsync({
        input: {
          firstName,
          lastName,
          defaultProjectRole: role,
        },
      });
      await queryClient.invalidateQueries(["CurrentUser"]);

      if (
        hasProjectInvitationOrigin &&
        currentUser?.email &&
        inviteeEmail === currentUser?.email
      ) {
        const result = await acceptProjectInvitationMutateAsync({
          input: {
            projectInvitationId,
            token,
            role,
          },
        });
        const projectId = result.acceptProjectInvitation?.project.id;
        navigate("/onboarding/upload_photo", {
          state: {
            projectId,
          },
        });
      } else {
        navigate("/onboarding/upload_photo");
      }
    } catch {
      setSubmitError(t("errors.genericError"));
    }
  };

  return (
    <CenteredPage className="bg-white">
      <div className="absolute top-10 left-14">
        <Logo size="medium" className="text-neutral-70" />
      </div>
      <div className="flex flex-col items-center justify-center py-10">
        <Display3 className="w-80 text-neutral-90">
          {t("pages.onboarding.aboutYou.title")}
        </Display3>
        <div className="mt-10">
          <form
            className="flex min-w-[432px] flex-col"
            onSubmit={handleSubmit(onSubmit) as () => void}
          >
            {submitError && (
              <Caption1 className="mb-2 text-center text-secondary-red-50">
                {submitError}
              </Caption1>
            )}
            <Input
              type="text"
              label={t("pages.onboarding.aboutYou.inputFirstNameLabel")}
              placeholder={t(
                "pages.onboarding.aboutYou.inputFirstNamePlaceholder",
              )}
              error={errors.firstName?.message}
              {...register("firstName", {
                required: t("errors.presence"),
                pattern: {
                  value: VALIDATE_STRING_REGEX,
                  message: t("errors.noLeadingSpaces"),
                },
              })}
            />
            <Input
              type="text"
              label={t("pages.onboarding.aboutYou.inputLastNameLabel")}
              placeholder={t(
                "pages.onboarding.aboutYou.inputLastNamePlaceholder",
              )}
              error={errors.lastName?.message}
              contentClassName="mt-4"
              {...register("lastName", {
                required: t("errors.presence"),
                pattern: {
                  value: VALIDATE_STRING_REGEX,
                  message: t("errors.noLeadingSpaces"),
                },
              })}
            />
            <Input
              type="text"
              label={t("pages.onboarding.aboutYou.inputRoleLabel")}
              placeholder={t("pages.onboarding.aboutYou.inputRolePlaceholder")}
              error={errors.role?.message}
              contentClassName="mt-4"
              {...register("role", {
                required: t("errors.presence"),
                pattern: {
                  value: VALIDATE_STRING_REGEX,
                  message: t("errors.noLeadingSpaces"),
                },
              })}
            />
            <Button
              variant="secondary"
              type="submit"
              disabled={isSubmitting}
              value="Continue"
              className="mt-4"
            >
              {t("shared.continue")}
            </Button>
          </form>
        </div>
      </div>
    </CenteredPage>
  );
}

export default AboutYou;
