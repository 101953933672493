import { Camera } from "phosphor-react";
import React, { useRef } from "react";

function PhotoUploadButton(props: {
  onChange: (e: File) => void;
  disable: boolean;
  className?: string;
  value: File | undefined;
}) {
  const { onChange, disable, className, value } = props;
  const fileRef = useRef<HTMLInputElement>(null);

  const onButtonClick = () => {
    fileRef?.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.length) {
      const file = event.target.files[0];
      onChange(file);
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileRef}
        accept="image/*"
        className="hidden"
        onChange={handleFileChange}
        data-testid="photo-uploader"
      />
      <button
        type="button"
        disabled={disable}
        className={className}
        onClick={onButtonClick}
      >
        {value ? (
          <img
            data-testid="upload-profile-picture"
            src={URL.createObjectURL(value)}
            className="h-[120px] w-[120px] rounded-full"
          />
        ) : (
          <Camera
            width={48}
            height={48}
            weight="thin"
            className="text-neutral-60"
          />
        )}
      </button>
    </>
  );
}

export default PhotoUploadButton;
