import clsx from "clsx";
import { LoadingSpinner } from "icons";
import { ArrowsClockwise } from "phosphor-react";
import React from "react";

type LoaderSizes = "small" | "medium" | "large";
type LoaderColors = "black" | "gray" | "primary";
type Variants = "arrows" | "circle";

type LoaderProps = {
  size?: LoaderSizes;
  color?: LoaderColors;
  variant?: Variants;
};

function getSize(size: LoaderSizes) {
  switch (size) {
    case "small":
      return 24;
    case "medium":
      return 48;
    default:
      return 72;
  }
}

function getColor(color: LoaderColors) {
  switch (color) {
    case "black":
      return "text-neutral-90";
    case "gray":
      return "text-neutral-30";
    default:
      return "text-primary-turquoise-30";
  }
}

export default function Loader({
  size = "medium",
  color = "primary",
  variant = "arrows",
}: LoaderProps) {
  return variant === "arrows" ? (
    <ArrowsClockwise
      className={clsx("animate-spin", getColor(color))}
      size={getSize(size)}
    />
  ) : (
    <LoadingSpinner width={getSize(size)} />
  );
}
