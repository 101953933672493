import clsx from "clsx";
import {
  Base1Strong,
  Base2,
  Caption1,
  Heading1Strong,
  HStack,
  Paragraph2,
  VStack,
} from "components";
import { Avatar, Button, Link } from "components/base";
import { EmbeddedLinks } from "components/CreateInteractions/CreateAnnouncementForm";
import {
  AnnouncementDetailsModal,
  EditAnnouncementModal,
  WeekFeedAnnouncementType,
} from "components/pages";
import { endOfDay, format, isAfter, parseISO } from "date-fns";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { Megaphone } from "phosphor-react";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

type AnnouncementCardProps = {
  announcement: WeekFeedAnnouncementType;
};

export default function AnnouncementCard({
  announcement,
}: AnnouncementCardProps) {
  const {
    id,
    author,
    isHighlighted,
    description,
    isSchedule,
    scheduleTime,
    links,
    createdAt,
    isNewComment,
    announcementTitle,
  } = announcement;

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const completed = isAfter(new Date(), endOfDay(parseISO(createdAt)));
  const search = useLocation().search;
  const announcementCardId = new URLSearchParams(search).get("card");
  const [cardRefId, setCardRefId] = useState<string>("");
  const [retryCount, setRetryCount] = useState(4);
  const { projectId = "" } = useParams();
  const { hasViewAccess } = useProjectAccessLevel({
    projectId,
  });

  const contextRef = useRef<HTMLDivElement>(null);

  const editAnnouncement = () => {
    setShowDetailsModal(false);
    setShowEditModal(true);
  };

  useEffect(() => {
    if (!!announcementCardId) setCardRefId(announcementCardId);
    if (cardRefId !== announcementCardId) {
      setRetryCount(4);
    }
  }, [announcementCardId]);

  useLayoutEffect(() => {
    let intervalId: NodeJS.Timer;

    if (
      retryCount > 0 &&
      cardRefId === `announcement-${id || ""}` &&
      !!contextRef.current
    ) {
      intervalId = setInterval(() => {
        setRetryCount(0);
        if (!!contextRef.current && cardRefId === `announcement-${id || ""}`) {
          contextRef?.current?.scrollIntoView({
            behavior: "smooth",
          });
        } else {
          clearInterval(intervalId);
          setRetryCount((retryCount) => retryCount - 1);
        }
      }, 1500);
    }
    return () => clearInterval(intervalId);
  }, [retryCount, cardRefId, contextRef, announcement]);

  return (
    <div
      id={`announcement-${id || ""}`}
      className="relative mb-4"
      ref={cardRefId === `announcement-${id || ""}` ? contextRef : undefined}
    >
      <div className="mb-4 flex h-6">
        <Avatar
          user={author}
          className="mb-4"
          size="mini"
          textClassName="!text-xs"
        />
        <Caption1 className=" mx-2 self-center text-neutral-70">
          {author?.fullName}
        </Caption1>
      </div>
      {!!isNewComment && !hasViewAccess && (
        <div
          className={clsx(
            `b-12 z-1 group absolute -right-6 top-6 box-border flex h-9 w-40 flex-row items-center justify-center rounded-xl !bg-primary-turquoise-10 !px-2 py-2 !text-neutral-90 focus:outline-none`,
            Z_INDEX_LEVELS.BASE,
          )}
        >
          <Base2>{t("constant.comments.newComment")}</Base2>
        </div>
      )}
      <div
        className={clsx(
          "flex w-[680px] flex-col overflow-hidden rounded-2xl shadow-feedCard",
          !completed && "bg-white",
          completed && "border border-tint-dark-15 drop-shadow-feedCard",
        )}
      >
        {!completed && isHighlighted && (
          <HStack className="bg-secondary-yellow-50 p-3"></HStack>
        )}
        <HStack className="justify-between p-6">
          <VStack space={4} className="ml-2 mt-2 w-[494px]">
            <HStack className="flex items-baseline gap-3">
              <Base1Strong className="text-secondary-yellow-70">
                {t("component.announcementCard.title")}
              </Base1Strong>
              <Caption1>
                {isSchedule
                  ? `Scheduled post ${format(
                      parseISO(scheduleTime || new Date().toISOString()),
                      "MMM dd yyyy",
                    )} `
                  : ""}
              </Caption1>
            </HStack>
            <Heading1Strong className="text-neutral-90">
              {announcementTitle}
            </Heading1Strong>
            <Paragraph2 className="h-22 grow-0 text-neutral-70 line-clamp-4">
              {description}
            </Paragraph2>
            <Button
              variant="outline"
              className="w-40"
              onClick={() => setShowDetailsModal(true)}
            >
              {t("component.announcementCard.readMore")}
            </Button>
          </VStack>
          <div className="flex h-18 w-18 items-center justify-center rounded-full bg-secondary-yellow-30">
            <Megaphone
              size={32}
              mirrored
              className={clsx("text-neutral-90", Z_INDEX_LEVELS.BASE)}
            />
          </div>
        </HStack>
        {links?.length ? (
          <VStack
            className={clsx(
              "px-7 pb-6",
              isHighlighted && !completed && "bg-white pt-6",
            )}
            space={4}
          >
            {(completed || !isHighlighted) && (
              <div className="border-t border-tint-dark-15" />
            )}
            <Caption1 className="text-neutral-60">
              {t("shared.attachedLinks")}
            </Caption1>
            <HStack align="center" className="flex-wrap gap-2">
              {links.map((link, index) => (
                <Link
                  key={index}
                  metadata={link as EmbeddedLinks}
                  isRemovable={false}
                />
              ))}
            </HStack>
          </VStack>
        ) : (
          <></>
        )}
      </div>
      {!!showDetailsModal && (
        <AnnouncementDetailsModal
          show={showDetailsModal}
          onEdit={editAnnouncement}
          onClose={() => setShowDetailsModal(false)}
          announcement={announcement}
        />
      )}
      {showEditModal && (
        <EditAnnouncementModal
          show={showEditModal}
          onClose={() => setShowEditModal(false)}
          announcement={announcement}
        />
      )}
    </div>
  );
}
