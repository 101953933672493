import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import { Avatar, Button, UserPill } from "components/base";
import { HStack, VStack } from "components/layout";
import { DropdownSelector, Modal } from "components/partial";
import { Base2, Base2Strong, Caption1, Display4 } from "components/Typography";
import {
  User,
  WorkshopParticipant,
  WorkshopParticipantRole,
} from "graphql/generated";
import { t } from "i18n-js";
import { noop } from "lodash";
import useCreateWorkshopParticipants from "mutations/workshop/use-create-workshop-participants";
import useDeleteWorkshopParticipant from "mutations/workshop/use-delete-workshop-participant";
import { CaretDown, X } from "phosphor-react";
import { useProject, useWorkshop } from "queries";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Z_INDEX_LEVELS } from "utils";

import AttendanceBadge, { AttendanceCategories } from "./AttendanceBadge";
import TeamMembers from "./TeamMembers";

interface ParticipantOptionProps {
  user: User;
  participantId?: string;
  type: AttendanceCategories;
  role?: WorkshopParticipantRole;
}

interface ShareSummaryDialogProps {
  open: boolean;
  onClose: () => void;
}

const ParticipantOption = ({
  user,
  type,
  role,
  participantId,
}: ParticipantOptionProps) => {
  const { id: workshopId } = useParams();
  const { mutateAsync: deleteParticipant } = useDeleteWorkshopParticipant();
  const { mutateAsync: createWorkshopParticipants } =
    useCreateWorkshopParticipants();

  const handleRemoveSharedSummary = async () => {
    try {
      await deleteParticipant({ id: participantId as string });
    } catch {
      throw new Error(t("errors.somethingWentWrong"));
    }
  };

  const handleShareSummary = async () => {
    try {
      await createWorkshopParticipants({
        role: WorkshopParticipantRole.SummaryShared,
        userIds: [user.id],
        workshopId,
      });
    } catch {
      throw new Error(t("errors.somethingWentWrong"));
    }
  };

  return (
    <HStack justify="between" align="center" className="py-3">
      <HStack space={3} align="center">
        <div className="relative">
          <Avatar user={user} />
          {type === "didAttend" && <AttendanceBadge type="didAttend" />}
          {type === "didNotAttend" && <AttendanceBadge type="didNotAttend" />}
          {type === "sharedWith" && <AttendanceBadge type="sharedWith" />}
        </div>
        <VStack>
          <Base2 className="text-neutral-900">{user?.fullName}</Base2>
          <Caption1 className="text-neutral-600">
            {user?.defaultProjectRole}
          </Caption1>
        </VStack>
      </HStack>
      {type === "sharedWith" && (
        <DropdownSelector
          dropdownType="SHARED_SUMMARY_LEVELS"
          currentLevel={WorkshopParticipantRole.SummaryShared}
          onSelect={noop}
          onDelete={() =>
            participantId ? void handleRemoveSharedSummary() : noop
          }
          canAssignAdmin={false}
          buttonContent={
            <HStack align="center" space={2}>
              <Base2 className="text-neutral-900">{t("shared.canView")}</Base2>
              <CaretDown />
            </HStack>
          }
        />
      )}
      {type === "projectMember" && (
        <Button
          variant="outline"
          size="small"
          onClick={() => void handleShareSummary()}
        >
          {t("shared.addMember")}
        </Button>
      )}
      {(type === "didAttend" || type === "didNotAttend") && (
        <Base2>
          {role === WorkshopParticipantRole.Owner ? "Owner" : "Can view"}
        </Base2>
      )}
    </HStack>
  );
};

const ShareSummaryDialog = ({ open, onClose }: ShareSummaryDialogProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [query, setQuery] = useState<string>("");
  const [selectedMembers, setSelectedMembers] = useState<User[]>([]);
  const [summarySharedUsers, setSummarySharedUsers] = useState<
    WorkshopParticipant[]
  >([]);
  const [workshopFacilitators, setWorkshopFacilitators] = useState<
    WorkshopParticipant[]
  >([]);
  const [workshopParticipants, setWorkshopParticipants] = useState<
    WorkshopParticipant[]
  >([]);
  const [projectMembers, setProjectMembers] = useState<User[]>([]);

  const { id: workshopId } = useParams();
  const { workshop } = useWorkshop({ id: workshopId || "" });
  const { project } = useProject({ id: String(workshop?.projectId) || "" });
  const { mutateAsync: createWorkshopParticipants } =
    useCreateWorkshopParticipants();

  const unselsectedMembers = useMemo(() => {
    return projectMembers?.filter(
      (member) => !selectedMembers.includes(member),
    );
  }, [projectMembers, selectedMembers]);

  const selectableTeamMembers = useMemo(() => {
    return query === ""
      ? unselsectedMembers
      : unselsectedMembers?.filter((user) => {
          if (user.fullName)
            return (
              user.fullName
                .toLowerCase()
                .includes(query?.toLowerCase() || "") ||
              user.email.toLowerCase().includes(query?.toLowerCase() || "")
            );
        });
  }, [query, unselsectedMembers]);

  const inviteTeamMembers = async () => {
    const Ids = selectedMembers?.map((member) => member.id);
    try {
      await createWorkshopParticipants({
        role: WorkshopParticipantRole.SummaryShared,
        userIds: [...Ids],
        workshopId,
      });
    } catch {
      throw new Error(t("errors.somethingWentWrong"));
    }
  };

  useEffect(() => {
    const workshopParticipantIds =
      workshop?.workshopParticipants.map(({ user }) => user.id) || [];
    const facilitator = workshop?.workshopParticipants?.filter(
      (user) =>
        user.role === WorkshopParticipantRole.Owner ||
        user.role === WorkshopParticipantRole.Facilitator,
    );
    const participants = workshop?.workshopParticipants?.filter(
      (user) => user.role === WorkshopParticipantRole.Participant,
    );
    const summarySharedUsersList = workshop?.workshopParticipants?.filter(
      (user) => user.role === WorkshopParticipantRole.SummaryShared,
    );
    const projectMembersList = project?.projectAssignments
      ?.filter(({ user }) => !workshopParticipantIds.includes(user.id))
      ?.map(({ user }) => user);

    setWorkshopFacilitators(facilitator as WorkshopParticipant[]);
    setWorkshopParticipants(participants as WorkshopParticipant[]);
    setSummarySharedUsers(summarySharedUsersList as WorkshopParticipant[]);
    setProjectMembers(projectMembersList as User[]);
  }, [project?.projectAssignments, workshop?.workshopParticipants]);

  return (
    <Modal
      isFullScreen
      open={open}
      onClose={onClose}
      className="no-scrollbar flex w-[634px] flex-col overflow-scroll rounded-lg"
    >
      <VStack space={4} className="p-[32px] shadow-lg">
        <HStack justify="between" align="center">
          <Display4 className="text-neutral-900">
            {t("shared.shareSummary")}
          </Display4>
          <X
            size={26}
            color="#292D30"
            className="cursor-pointer"
            onClick={onClose}
          />
        </HStack>
        <Combobox
          value={selectedMembers}
          onChange={(users) => {
            setSelectedMembers(users);
            setQuery("");
          }}
          multiple
        >
          {({ open }) => {
            return (
              <>
                <HStack
                  align="center"
                  justify="between"
                  className="min-h-[48px] rounded-md border-2 border-transparent bg-tint-dark-10 py-2.5 focus-within:border-primary-turquoise-30"
                >
                  <HStack space={1} className="flex w-full flex-wrap">
                    {selectedMembers?.map((user) => (
                      <UserPill
                        user={user}
                        key={user.id || user.email}
                        isComboboxOption
                        className={clsx(
                          "my-2 ml-2 mr-1 !bg-neutral-900 text-center",
                          Z_INDEX_LEVELS.MODAL_CONTROL,
                        )}
                        showAvatar={false}
                      />
                    ))}
                    <Combobox.Input
                      ref={inputRef}
                      placeholder={
                        selectedMembers.length === 0
                          ? t("workshop.shareSummaryDialog.inputPlaceholder")
                          : ""
                      }
                      className={clsx(
                        "box-border h-full w-full flex-1 self-center rounded-md px-4 text-neutral-90 caret-primary-turquoise-70 placeholder:text-base placeholder:text-tint-dark-50 focus:outline-none sm:bg-transparent md:bg-transparent xl:bg-transparent",
                      )}
                      onChange={({ target }) => {
                        return setQuery((target as { value: string }).value);
                      }}
                      displayValue={(person: User) => person.fullName}
                    />
                  </HStack>
                  <HStack
                    align="center"
                    space={4}
                    className={clsx("pr-2 pl-6", Z_INDEX_LEVELS.MODAL_CONTROL)}
                  >
                    <Base2 className="whitespace-nowrap text-neutral-900">
                      {t("shared.canView")}
                    </Base2>
                    <Button
                      onClick={() => {
                        void inviteTeamMembers();
                        setSelectedMembers([]);
                      }}
                      size="small"
                      disabled={selectedMembers.length === 0}
                    >
                      {t("shared.share")}
                    </Button>
                  </HStack>
                </HStack>
                {open && query !== "" ? (
                  <Combobox.Options>
                    <TeamMembers query={query} users={selectableTeamMembers} />
                  </Combobox.Options>
                ) : undefined}
              </>
            );
          }}
        </Combobox>
      </VStack>
      <div className="no-scrollbar max-h-[420px] overflow-scroll p-[32px]">
        <VStack space={2} className="mb-2">
          <Base2Strong className="text-neutral-900">
            {t("workshop.shareSummaryDialog.facilitators")}
          </Base2Strong>
          <div>
            {workshopFacilitators.map(({ user, didAttend, role }) => (
              <ParticipantOption
                key={user.id}
                user={user}
                type={didAttend === true ? "didAttend" : "didNotAttend"}
                role={role as WorkshopParticipantRole}
              />
            ))}
          </div>
        </VStack>
        {workshopParticipants.length > 0 && (
          <VStack space={2} className="mb-2">
            <hr className="mb-3 bg-neutral-100" />
            <Base2Strong className="text-neutral-900">
              {t("workshop.shareSummaryDialog.participants")}
            </Base2Strong>
            <div>
              {workshopParticipants.map(({ user, didAttend }) => (
                <ParticipantOption
                  key={user.id}
                  user={user}
                  type={didAttend === true ? "didAttend" : "didNotAttend"}
                />
              ))}
            </div>
          </VStack>
        )}
        {summarySharedUsers.length > 0 && (
          <VStack space={2} className="mb-2">
            <hr className="mb-3 bg-neutral-100" />
            <VStack space={1}>
              <Base2Strong className="text-neutral-900">
                {t("workshop.shareSummaryDialog.sharedWith")}
              </Base2Strong>
              <Caption1 className="text-neutral-70">
                {t("workshop.shareSummaryDialog.sharedWithSubText")}
              </Caption1>
            </VStack>
            <div>
              {summarySharedUsers.map(({ user, id }) => (
                <ParticipantOption
                  type="sharedWith"
                  user={user}
                  key={user.id}
                  participantId={id}
                />
              ))}
            </div>
          </VStack>
        )}
        {projectMembers.length > 0 && (
          <VStack space={2} className="mb-2">
            <hr className="mb-3 bg-neutral-100" />
            <VStack space={1}>
              <Base2Strong className="text-neutral-900">
                {t("workshop.shareSummaryDialog.teamMembers")}
              </Base2Strong>
              <Caption1 className="text-neutral-70">
                {t("workshop.shareSummaryDialog.teamMembersSubText")}
              </Caption1>
            </VStack>
            <div>
              {projectMembers.map((user) => (
                <ParticipantOption
                  type="projectMember"
                  user={user}
                  key={user.id}
                />
              ))}
            </div>
          </VStack>
        )}
      </div>
    </Modal>
  );
};

export default ShareSummaryDialog;
