import { Button } from "components/base";
import { HStack, VStack } from "components/layout/Stack";
import { Heading1Strong, Paragraph3 } from "components/Typography";
import { useGetEnvsContext } from "contexts";
import useUpdateUser from "hooks/mutations/useUpdateUser";
import { t } from "i18n-js";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { SettingsField } from "../../SettingsField";
import TimezoneDropdown from "./UserSettingsManualOfMe/TimezoneDropdown";

export function UserSettingsProfileInfo() {
  const queryClient = useQueryClient();
  const [selectedTimezone, setSelectedTimezone] = useState("");

  const [isEditable, setIsEditable] = useState(false);
  const { currentUser } = useGetEnvsContext();
  const { projectId = "" } = useParams();
  const [error, setError] = useState("");
  const [emptyFieldFirstname, setEmptyFieldFirstname] = useState("");
  const [emptyFieldLastname, setEmptyFieldLastname] = useState("");
  const [emptyFieldRole, setEmptyFieldRole] = useState("");
  const [emptyTimezone, setEmptyTimezone] = useState("");
  const { mutateAsync: updateUser } = useUpdateUser();

  // const isQueryCalled = (queryKey: string) => {
  //   const queryData = queryClient.getQueryData(queryKey);
  //   return !!queryData;
  // };

  const checkValue = () => {
    return isEditable;
  };

  const handleSettingData = () => {
    setIsEditable(!isEditable);
  };

  const handleChange = (key: string, value: string) => {
    const spaceRegex = /^\s*$/;
    if (key === "linkdinLink") {
      const linkedin =
        /(https?)?:?(\/\/)?((w{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:(\d)+)?(\/|\/([\w!#%&+./:=?@\-]))?/;
      setError("");
      if (value && !linkedin.test(value)) {
        setError("please enter valid URL");
        return;
      }
    }
    if (key === "firstname") {
      if (value === "") {
        setEmptyFieldFirstname("Cannot be left blank");
      } else if (spaceRegex.test(value)) {
        setEmptyFieldFirstname("Please remove leading spaces.");
      } else {
        setEmptyFieldFirstname("");
      }
    }
    if (key === "lastname") {
      if (value === "") {
        setEmptyFieldLastname("Cannot be left blank");
      } else if (spaceRegex.test(value)) {
        setEmptyFieldLastname("Please remove leading spaces.");
      } else {
        setEmptyFieldLastname("");
      }
    }
    if (key === "role") {
      if (value === "") {
        setEmptyFieldRole("Cannot be left blank");
      } else if (spaceRegex.test(value)) {
        setEmptyFieldRole("Please remove leading spaces.");
      } else {
        setEmptyFieldRole("");
      }
    }
    if (key === "timezone") {
      if (value === "") {
        setEmptyTimezone("Cannot be left blank");
      } else {
        setEmptyTimezone("");
      }
    }
  };

  useEffect(() => {
    checkValue();
  }, [isEditable, checkValue]);

  const handleFormSubmit = (value: string) => {
    if (
      error ||
      emptyFieldFirstname ||
      emptyFieldLastname ||
      emptyFieldRole ||
      emptyTimezone ||
      Object.keys(value).length <= 0
    )
      return;

    handleUpdateUser(value)
      .then(() => {
        void queryClient.refetchQueries(["CurrentUser"]);

        // isQueryCalled("ProjectInvitations") &&
        //   void queryClient.refetchQueries(["ProjectInvitations"]);
        // isQueryCalled("ProjectAssignments") &&
        //   void queryClient.refetchQueries(["ProjectAssignments"]);

        projectId && void queryClient.refetchQueries(["ProjectInvitations"]);
        projectId &&
          void queryClient.refetchQueries([
            "ProjectAssignments",
            { projectId },
          ]);
        projectId && void queryClient.invalidateQueries(["subFilters"]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleTimezoneChange = (value: string) => {
    setSelectedTimezone(value);
  };

  const handleUpdateUser = async (value: string) => {
    await updateUser({ input: value });
    return;
  };

  return (
    <VStack className="gap-8">
      <HStack align="center" className="gap-8">
        <Heading1Strong>
          {t(`component.userSettings.tabs.profile.sections.personalInfo.title`)}
        </Heading1Strong>
        {!isEditable ? (
          <Button variant="outline" onClick={handleSettingData}>
            {t(
              `component.userSettings.tabs.profile.sections.personalInfo.editbutton`,
            )}
          </Button>
        ) : (
          <Button
            type="submit"
            disabled={
              error ||
              emptyFieldFirstname ||
              emptyFieldLastname ||
              emptyFieldRole
            }
            variant="secondary"
            onClick={handleSettingData}
          >
            {t(
              `component.userSettings.tabs.profile.sections.personalInfo.savechangesbutton`,
            )}
          </Button>
        )}
      </HStack>

      <SettingsField
        label={t(
          `component.userSettings.tabs.profile.sections.personalInfo.fields.firstName`,
        )}
        editFlag={isEditable}
        name="first_name"
        type="text"
        defaultValue={currentUser?.firstName || ""}
        onChange={(value) => handleChange("firstname", value)}
        onSubmit={handleFormSubmit}
      />
      {emptyFieldFirstname && (
        <Paragraph3 className="text-red-400">{emptyFieldFirstname}</Paragraph3>
      )}

      <SettingsField
        label={t(
          `component.userSettings.tabs.profile.sections.personalInfo.fields.lastName`,
        )}
        editFlag={isEditable}
        name="last_name"
        type="text"
        defaultValue={currentUser?.lastName || ""}
        onChange={(value) => handleChange("lastname", value)}
        onSubmit={handleFormSubmit}
      />
      {emptyFieldLastname && (
        <Paragraph3 className="text-red-400">{emptyFieldLastname}</Paragraph3>
      )}

      <SettingsField
        label={t(
          `component.userSettings.tabs.profile.sections.personalInfo.fields.pronouns`,
        )}
        editFlag={isEditable}
        name="pronouns"
        type="text"
        defaultValue={currentUser?.pronouns || ""}
        placeholder="No pronouns added yet"
        onSubmit={handleFormSubmit}
      />

      <SettingsField
        label={t(
          `component.userSettings.tabs.profile.sections.personalInfo.fields.role/title`,
        )}
        editFlag={isEditable}
        name="default_role"
        type="text"
        defaultValue={currentUser?.defaultProjectRole || ""}
        onChange={(value) => handleChange("role", value)}
        onSubmit={handleFormSubmit}
      />
      {emptyFieldRole && (
        <Paragraph3 className="text-red-400">{emptyFieldRole}</Paragraph3>
      )}

      <SettingsField
        label={t(
          `component.userSettings.tabs.profile.sections.personalInfo.fields.company`,
        )}
        editFlag={isEditable}
        name="company"
        type="text"
        defaultValue={currentUser?.company || ""}
        placeholder="No company added yet"
        onSubmit={handleFormSubmit}
      />

      <SettingsField
        label={t(
          `component.userSettings.tabs.profile.sections.personalInfo.fields.homebase`,
        )}
        editFlag={isEditable}
        name="homebase"
        type="text"
        defaultValue={currentUser?.homebase || ""}
        placeholder="No homebase added yet"
        onSubmit={handleFormSubmit}
      />

      <SettingsField
        label={t(
          `component.userSettings.tabs.profile.sections.personalInfo.fields.linkedln`,
        )}
        editFlag={isEditable}
        name="linkdinLink"
        type="text"
        defaultValue={currentUser?.linkdinLink || ""}
        placeholder="No profile added yet"
        onSubmit={handleFormSubmit}
        onChange={(value) => handleChange("linkdinLink", value)}
      />

      <div>
        <label htmlFor="timezone">
          {t(
            `component.userSettings.tabs.profile.sections.personalInfo.fields.timezone`,
          )}
        </label>
        <TimezoneDropdown
          defaultValue={currentUser?.timezone || ""}
          editFlag={isEditable}
          selectedTimezone={selectedTimezone}
          placeholder={t(
            `component.userSettings.tabs.profile.sections.personalInfo.fields.timezonePlaceholder`,
          )}
          onChange={handleTimezoneChange}
          onSubmit={handleFormSubmit}
        />
      </div>
    </VStack>
  );
}
