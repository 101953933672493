import {
  GetDiscDetailsViewQuery,
  GetDiscDetailsViewQueryVariables,
  useGetDiscDetailsViewQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query GetDiscDetailsView($projectId: ID!) {
    getDiscDetailsView(projectId: $projectId) {
      dominanceSegments {
        segmentType
        segmentPercentage
        anonymousUserCount
        users {
          id
          firstName
          lastName
          fullName
          avatarUrl
          defaultProjectRole
        }
      }
      steadinessSegments {
        segmentType
        segmentPercentage
        anonymousUserCount
        users {
          id
          firstName
          lastName
          fullName
          avatarUrl
          defaultProjectRole
        }
      }
      conscientiousnessSegments {
        segmentType
        segmentPercentage
        anonymousUserCount
        users {
          id
          firstName
          lastName
          fullName
          avatarUrl
          defaultProjectRole
        }
      }
      influenceSegments {
        segmentType
        segmentPercentage
        anonymousUserCount
        users {
          id
          firstName
          lastName
          fullName
          avatarUrl
          defaultProjectRole
        }
      }
      visibleToTeam
    }
  }
`;

function useDiscDetailsView(variables: GetDiscDetailsViewQueryVariables): {
  discDetails: GetDiscDetailsViewQuery["getDiscDetailsView"];
} & UseQueryResult<GetDiscDetailsViewQuery, unknown> {
  const response = useGetDiscDetailsViewQuery(variables, {
    suspense: true,
    enabled: !!variables.projectId,
  });

  return {
    discDetails: response.data?.getDiscDetailsView,
    ...response,
  };
}

export { useDiscDetailsView };
