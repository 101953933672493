import clsx from "clsx";
import {
  Agenda,
  WorkshopActivity,
  WorkshopParticipantRole,
} from "graphql/generated";
import { t } from "i18n-js";
import React from "react";

import { AgendaActivity, AgendaSection } from "./components";

export interface WorkshopAgendaProps {
  agenda: Agenda[];
  userRole?: WorkshopParticipantRole;
  className?: string;
  currentActivity?: WorkshopActivity;
  onClickItem?: (activity: WorkshopActivity) => void;
  defaultSectionsOpen?: boolean;
}

/**
 * This WorkshopAgenda is an uneditable agenda which is used both in the in-session Sidebar
 * and the post-session Agenda tab.
 *
 * For the in-session version, the content is reduced for non-facilitator users.
 * However in the post-session summary, it should be the same for all users, so the default "Facilitator"
 * value can be left as is.
 */
export const WorkshopAgenda = ({
  agenda,
  userRole = WorkshopParticipantRole.Facilitator,
  className,
  onClickItem,
  currentActivity,
  defaultSectionsOpen,
}: WorkshopAgendaProps) => {
  return (
    <div
      id="workshopAgenda"
      aria-label={t("workshop.agenda")}
      className={clsx(
        "flex h-full w-108 flex-col overflow-y-scroll",
        className,
      )}
    >
      {agenda.map((agendaItem) => (
        <div
          key={`${agendaItem.__typename || ""}-${agendaItem.id}`}
          className="align-center flex flex-row "
        >
          {agendaItem.__typename === "WorkshopActivity" && (
            <AgendaActivity
              userRole={userRole}
              {...agendaItem}
              onClick={onClickItem}
              isActive={currentActivity?.id === agendaItem.id}
            />
          )}

          {agendaItem.__typename === "Section" && (
            <AgendaSection
              {...agendaItem}
              defaultOpen={defaultSectionsOpen}
              userRole={userRole}
              onClickItem={onClickItem}
              currentActivity={currentActivity}
            />
          )}
        </div>
      ))}
    </div>
  );
};
