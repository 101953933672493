import { addDays, addWeeks } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { MilestoneInput, Project } from "graphql/generated";
import { DeepPartial } from "react-hook-form";
import {
  formatWithoutTimezone,
  getTimeZone,
} from "utils/constants/time_helpers";

export type WeeksFromProjectPerPhasesReturn = Partial<MilestoneInput> & {
  weekText: string;
};

export default function getWeeksFromProjectPerPhases(
  project: DeepPartial<Project>,
): Array<Array<WeeksFromProjectPerPhasesReturn>> {
  const { phases, startDate } = project;

  if (!phases || !startDate) {
    return [];
  }

  const weeksPerPhases = [];

  let phaseStartDate = formatWithoutTimezone(startDate?.slice(0, 10));

  for (const phase of phases.values()) {
    const phaseWeeks: Array<WeeksFromProjectPerPhasesReturn> = [];

    const { weeksLength, milestones } = phase || { weeksLength: 0 };
    const weeksLengthNumber = weeksLength || 0;

    for (let weekIndex = 0; weekIndex < weeksLengthNumber; weekIndex++) {
      const weekStartDate = addDays(phaseStartDate, weekIndex * 7);
      const weekEndDate = addDays(weekStartDate, 6);

      let milestoneType;
      let milestoneName;
      if (milestones && milestones?.length > 0) {
        const milestonesFiltered = milestones.filter(
          (milestone) => milestone?.weekNumber === weekIndex,
        );

        if (milestonesFiltered.length > 0) {
          milestoneType = milestonesFiltered.at(0)?.milestoneType;
          milestoneName = milestonesFiltered.at(0)?.name;
        }
      }

      const timeZone = getTimeZone();
      const zonedWeekStartDate = utcToZonedTime(weekStartDate, timeZone);
      const zonedWeekEndDate = utcToZonedTime(weekEndDate, timeZone);

      phaseWeeks.push({
        weekText: `${format(zonedWeekStartDate, "LLL")} ${format(
          zonedWeekStartDate,
          "dd",
        )}-${format(zonedWeekEndDate, "LLL")} ${format(
          zonedWeekEndDate,
          "dd",
        )}`,
        milestoneType,
        name: milestoneName,
        weekNumber: weekIndex,
      });
    }

    phaseStartDate = addWeeks(phaseStartDate, weeksLengthNumber);
    weeksPerPhases.push(phaseWeeks);
  }

  return weeksPerPhases;
}
