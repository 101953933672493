/* eslint-disable @typescript-eslint/restrict-template-expressions */
import clsx from "clsx";
import { HStack } from "components/layout";
import { SignalDropdown } from "components/partial";
import { useToast } from "components/provider";
import { Caption1 } from "components/Typography";
import { useGetEnvsContext } from "contexts";
import { ActionCableProvider } from "contexts/ActionCableContext";
import { WorkshopPresenceChannelProvider } from "contexts/WorkshopPresenceContext";
import { WorkshopParticipantRole, WorkshopState } from "graphql/generated";
import { t } from "i18n-js";
import { Circle } from "phosphor-react";
import { useWorkshop } from "queries";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Z_INDEX_LEVELS } from "utils";

import AvatarGroupOnline from "../AvatarGroupOnline";
import BackToRecoder from "../BackToRecoder/BackToRecoder";
import { WorkshopMeta } from "../WorkshopMeta";

/**
 * For this header to work the route must include an `:id` for the given workshop.
 */
export const LiveWorkshopHeader = ({
  role = WorkshopParticipantRole.Participant,
  variant = "default",
  isMeetingBeingRecorded = false,
}: {
  role?: WorkshopParticipantRole;
  variant?: "preview" | "default";
  isMeetingBeingRecorded?: boolean;
}) => {
  const { id = "" } = useParams();
  const { workshop } = useWorkshop({ id });
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { currentUser } = useGetEnvsContext();

  const participants = useMemo(
    () => workshop?.workshopParticipants.map((participant) => participant.user),
    [workshop?.workshopParticipants],
  );

  const previewWorkshop = useMemo(
    () => ({
      ...workshop,
      state: WorkshopState.LiveSession,
    }),
    [workshop],
  );

  // This functionality will be implemented in a later sprint.
  /** TODO: https://coeurajtech.atlassian.net/browse/R10-386 */
  const handleSignalsDropdown = () => {
    // This is just a placeholder
    addToast({
      message: "This feature is a work in progress",
      variant: "info",
      hasCloseOption: true,
    });
  };

  // TODO: https://coeurajtech.atlassian.net/browse/R10-238
  const handleToggleStar = handleSignalsDropdown;

  const userRoleInWorkshop =
    workshop?.workshopParticipants?.find(
      (participant) => participant?.user?.id === currentUser?.id,
    )?.role || "";
  const isUserOwnerOrFacilitator =
    userRoleInWorkshop === "owner" || userRoleInWorkshop === "facilitator";

  const backToRecoderHandler = () => {
    const cardUrl = `/projects/${workshop?.projectId}?level=Timeline&card=workshop-${workshop?.id}&scheduledAt=${workshop?.startTime}`;
    window.open(cardUrl, "_blank");
  };

  return (
    <div
      className={clsx(
        "flex flex-col justify-center bg-neutral-0 pb-6",
        Z_INDEX_LEVELS.MODAL,
      )}
    >
      <div className="relative flex flex-row justify-between bg-neutral-0 px-14 pt-5">
        {isMeetingBeingRecorded ? (
          <HStack
            align="center"
            space={1}
            className="absolute right-[30%] -bottom-4"
          >
            <Circle size={6} weight="fill" className="text-secondary-red-500" />
            <Caption1 className="text-neutral-90">
              {t("shared.recording")}
            </Caption1>
          </HStack>
        ) : undefined}
        <WorkshopMeta
          workshop={previewWorkshop}
          isBuilderOpener={isUserOwnerOrFacilitator}
          isMeetingBeingRecorded={isMeetingBeingRecorded}
        />

        <div className="flex items-center gap-4">
          <SignalDropdown role={role} isDisabled={variant === "preview"} />

          {participants && (
            <ActionCableProvider>
              <WorkshopPresenceChannelProvider>
                <AvatarGroupOnline
                  projectId={workshop?.id.toString() || ""}
                  users={participants}
                  extraUserCountBgColor="bg-black"
                  liveMembers={true}
                  className={Z_INDEX_LEVELS.MODAL}
                />
              </WorkshopPresenceChannelProvider>
            </ActionCableProvider>
          )}

          <BackToRecoder onClick={backToRecoderHandler} />
        </div>
      </div>
    </div>
  );
};
