import React from "react";

function CheckinSmallIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_46_1529)">
        <path
          d="M12.5322 13.5429C12.5324 15.2641 13.1271 16.9325 14.2156 18.2659C15.3041 19.5992 16.8197 20.5157 18.5061 20.8604V6.22534C16.8197 6.57003 15.3041 7.48654 14.2156 8.8199C13.1271 10.1533 12.5324 11.8216 12.5322 13.5429Z"
          fill="#F2F4F4"
        />
        <path
          d="M27.2766 11.8864C26.958 10.4871 26.2432 9.20905 25.2176 8.20515C24.192 7.20125 22.899 6.51393 21.4932 6.22534V20.8604C22.899 20.5718 24.192 19.8845 25.2176 18.8807C26.2432 17.8768 26.958 16.5988 27.2766 15.1995C26.9829 14.1146 26.9829 12.9712 27.2766 11.8864Z"
          fill="#F2F4F4"
        />
        <path
          d="M11.0482 14.3719C10.2011 13.523 9.13317 12.9283 7.96541 12.6554V1.49349C7.96541 1.09739 7.80806 0.717517 7.52798 0.437433C7.24789 0.15735 6.86802 0 6.47192 0C6.07582 0 5.69595 0.15735 5.41586 0.437433C5.13578 0.717517 4.97843 1.09739 4.97843 1.49349V12.6555C3.55995 12.9883 2.29559 13.7908 1.39061 14.9326C0.485627 16.0745 -0.00683594 17.4887 -0.00683594 18.9457C-0.00683594 20.4027 0.485627 21.8169 1.39061 22.9588C2.29559 24.1007 3.55995 24.9031 4.97843 25.2359V38.3009C4.97843 38.697 5.13578 39.0768 5.41586 39.3569C5.69595 39.637 6.07582 39.7943 6.47192 39.7943C6.86802 39.7943 7.24789 39.637 7.52798 39.3569C7.80806 39.0768 7.96541 38.697 7.96541 38.3009V25.2357C9.08587 24.9711 10.1153 24.412 10.9473 23.6163C11.7794 22.8205 12.3838 21.817 12.6981 20.7095C13.0124 19.6019 13.0253 18.4305 12.7353 17.3164C12.4453 16.2022 11.863 15.1857 11.0487 14.3719H11.0482ZM8.93628 21.4125C8.65088 21.6922 8.31903 21.92 7.95555 22.0859C7.49146 22.3044 6.98485 22.4177 6.4719 22.4177C5.95896 22.4177 5.45235 22.3044 4.98827 22.0859C4.38867 21.8102 3.88057 21.3685 3.52406 20.8131C3.16755 20.2577 2.97755 19.6119 2.97651 18.9519C2.97548 18.292 3.16346 17.6455 3.51822 17.089C3.87299 16.5325 4.3797 16.0893 4.97843 15.8116C5.6281 15.5077 6.35503 15.4092 7.06215 15.5294C7.76926 15.6495 8.42291 15.9824 8.93579 16.4838H8.93628C9.58968 17.1375 9.95673 18.0239 9.95673 18.9482C9.95673 19.8724 9.58968 20.7589 8.93628 21.4125Z"
          fill="#A7A8B0"
        />
        <path
          d="M24.6293 24.8264C23.7692 23.9639 22.6815 23.3638 21.4931 23.0964V1.49349C21.4931 1.09739 21.3357 0.717517 21.0557 0.437433C20.7756 0.15735 20.3957 0 19.9996 0C19.6035 0 19.2236 0.15735 18.9435 0.437433C18.6635 0.717517 18.5061 1.09739 18.5061 1.49349V23.1233C17.0997 23.4658 15.849 24.2707 14.9547 25.409C14.0604 26.5472 13.5742 27.9528 13.5742 29.4003C13.5742 30.8479 14.0604 32.2534 14.9547 33.3917C15.849 34.5299 17.0997 35.3348 18.5061 35.6773V38.3008C18.5061 38.6969 18.6635 39.0768 18.9435 39.3569C19.2236 39.637 19.6035 39.7943 19.9996 39.7943C20.3957 39.7943 20.7756 39.637 21.0557 39.3569C21.3357 39.0768 21.4931 38.6969 21.4931 38.3008V35.7032C22.6203 35.4469 23.6582 34.8931 24.4987 34.0995C25.3391 33.3058 25.9514 32.3013 26.2717 31.1906C26.592 30.0799 26.6087 28.9036 26.3199 27.7842C26.0311 26.6649 25.4475 25.6435 24.6298 24.8264H24.6293ZM22.5174 31.867C21.8562 32.5058 20.9727 32.8629 20.0533 32.8629C19.1339 32.8629 18.2504 32.5058 17.5892 31.867C17.1935 31.4794 16.8969 31.0022 16.7246 30.4757C16.5523 29.9493 16.5093 29.3891 16.5992 28.8425C16.6892 28.296 16.9094 27.7791 17.2413 27.3356C17.5732 26.8922 18.0071 26.5351 18.5061 26.2948C19.1585 25.9713 19.8955 25.8594 20.6145 25.9748C21.3335 26.0901 21.9985 26.4269 22.517 26.9383H22.5174C23.1708 27.592 23.5379 28.4784 23.5379 29.4026C23.5379 30.3269 23.1708 31.2133 22.5174 31.867Z"
          fill="#A7A8B0"
        />
        <path
          d="M35.0214 7.25014V1.49349C35.0214 1.09739 34.8641 0.717517 34.584 0.437433C34.3039 0.15735 33.924 0 33.5279 0C33.1318 0 32.752 0.15735 32.4719 0.437433C32.1918 0.717517 32.0345 1.09739 32.0345 1.49349V7.2505C30.8951 7.51806 29.8502 8.091 29.0119 8.90777C28.1737 9.72454 27.5739 10.7543 27.2769 11.8863C26.9832 12.9711 26.9832 14.1145 27.2769 15.1994C27.5729 16.3314 28.1725 17.3611 29.011 18.1772C29.8494 18.9934 30.8949 19.5651 32.0345 19.8305V38.3009C32.0345 38.697 32.1918 39.0768 32.4719 39.3569C32.752 39.637 33.1318 39.7943 33.5279 39.7943C33.924 39.7943 34.3039 39.637 34.584 39.3569C34.8641 39.0768 35.0214 38.697 35.0214 38.3009V19.8306C36.4387 19.4964 37.7015 18.6933 38.6053 17.5516C39.5091 16.4099 40.0008 14.9965 40.0008 13.5404C40.0008 12.0842 39.5091 10.6708 38.6053 9.5291C37.7015 8.38741 36.4387 7.58437 35.0214 7.25015V7.25014ZM35.9923 16.0074C35.5066 16.4855 34.8905 16.8094 34.2213 16.9385C33.5521 17.0675 32.8596 16.9959 32.231 16.7327C31.6024 16.4694 31.0655 16.0262 30.688 15.4589C30.3104 14.8915 30.1089 14.2251 30.1089 13.5436C30.1088 12.8621 30.3101 12.1957 30.6876 11.6283C31.065 11.0608 31.6018 10.6175 32.2304 10.3542C32.859 10.0908 33.5514 10.019 34.2206 10.1479C34.8898 10.2768 35.506 10.6006 35.9918 11.0787H35.9923C36.316 11.4023 36.5727 11.7865 36.7479 12.2093C36.9231 12.6321 37.0132 13.0853 37.0132 13.543C37.0132 14.0007 36.9231 14.4539 36.7479 14.8768C36.5727 15.2996 36.316 15.6838 35.9923 16.0074Z"
          fill="#A7A8B0"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_1529">
          <rect width="40" height="39.7943" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CheckinSmallIcon;
