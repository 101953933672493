import { Button } from "components/base";
import { WorkshopSummaryHeader } from "components/partial";
import { Display3, Paragraph2 } from "components/Typography";
import { WorkshopParticipantRole } from "graphql/generated";
import { t } from "i18n-js";
import { RecoderLogo } from "icons";
import { ArrowRight } from "phosphor-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export interface WorkshopCompleteProps {
  userRole: WorkshopParticipantRole;
  workshopJustFinished?: boolean;
}

const handleRole = (userRole?: WorkshopParticipantRole) => {
  switch (userRole) {
    case WorkshopParticipantRole.Facilitator:
    case WorkshopParticipantRole.Owner:
      return WorkshopParticipantRole.Facilitator;
    case WorkshopParticipantRole.Participant:
    default:
      return WorkshopParticipantRole.Participant;
  }
};

export const WorkshopComplete = ({
  userRole,
  workshopJustFinished,
}: WorkshopCompleteProps) => {
  const isParticipant = userRole === WorkshopParticipantRole.Participant;
  const navigate = useNavigate();

  /**
   * Our intention is that this view only shows if the user has just completed a workshop.
   * If they're trying to navigate back into the workshop from elsewhere after it's complete,
   * we just want to redirect them to the Summary.
   */
  useEffect(() => {
    if (!workshopJustFinished) {
      navigate("./summary");
    }
  }, [workshopJustFinished, navigate]);

  /* Handlers */
  const handleViewSummary = () => {
    navigate("./summary");
  };

  const handleAddSummaryAndNextSteps = () => {
    navigate("./summary/edit");
  };

  const handleBackToRecoder = () => {
    navigate("/");
  };

  return (
    <div className="h-full">
      <WorkshopSummaryHeader />
      <div className="flex h-[calc(100%-88px)] flex-1 p-8">
        <div className="flex h-full flex-1 flex-col items-center justify-center rounded-lg bg-neutral-0">
          <RecoderLogo
            size={82}
            // neutrals-40
            fill="#C0C5C9"
          />

          <Display3 className="my-4">
            {t(`workshop.complete.${handleRole(userRole)}.title`)}
          </Display3>

          <Paragraph2 className="text-neutral-70">
            {t(`workshop.complete.${handleRole(userRole)}.subtitle`)}
          </Paragraph2>

          {/* TODO: i18n*/}
          {isParticipant ? (
            <div className="mt-10 flex gap-4">
              <Button variant="tertiary" onClick={handleBackToRecoder}>
                {t(`workshop.complete.backToRecoder`)}
              </Button>
              <Button variant="primary" onClick={handleViewSummary}>
                <span className="flex">
                  <span>{t(`workshop.complete.viewSummary`)}</span>
                  <ArrowRight size={20} className="ml-3" />
                </span>
              </Button>
            </div>
          ) : (
            <Button
              variant="primary"
              className="mt-10"
              onClick={handleAddSummaryAndNextSteps}
            >
              <span className="flex">
                <span>{t(`workshop.complete.addSummary`)}</span>
                <ArrowRight size={20} className="ml-3" />
              </span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
