import { useUpdateProfileAnswerMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation updateProfileAnswer($answer: String!, $profile_answer_id: ID!) {
    updateProfileAnswer(answer: $answer, profileAnswerId: $profile_answer_id) {
      id
      answer
    }
  }
`;

function useUpdateProfileAnswer() {
  const queryClient = useQueryClient();
  return useUpdateProfileAnswerMutation({
    onSuccess: () => {
      void queryClient.refetchQueries(["getAnswersForUser"]);
      void queryClient.refetchQueries(["getUserPersonalityProfile"]);
      void queryClient.refetchQueries(["getProfileAnswerOfUser"]);
      void queryClient.refetchQueries(["currentUser"]);
    },
  });
}

export default useUpdateProfileAnswer;
