import clsx from "clsx";
import { Base1 } from "components/Typography";
import { Check } from "phosphor-react";
import React from "react";
import { UseFormRegister } from "react-hook-form";

type OptionProps = {
  optionLabel: string;
  optionId: string;
  isDisabled: boolean;
  register: UseFormRegister<{
    multiChoicePollOption: string | (string | undefined)[] | undefined;
    singleChoicePollOption: string | undefined;
  }>;
  shouldShowControl?: boolean;
};

export const RadioWithLabel = ({
  optionLabel,
  optionId,
  isDisabled,
  register,
  shouldShowControl = true,
}: OptionProps) => (
  <Base1
    className={clsx("absolute top-0.5 left-2", {
      "top-2": !shouldShowControl,
    })}
  >
    <label>
      {shouldShowControl && (
        <>
          <input
            disabled={!shouldShowControl || isDisabled}
            type="radio"
            className="peer appearance-none"
            value={optionId}
            {...register("singleChoicePollOption")}
          />
          <span className="relative top-1.5 mr-2.5 inline-block h-6 w-6 rounded-xl bg-tint-dark-10  peer-checked:bg-primary-turquoise-50" />
          <span className="absolute left-[7px] top-[13px] hidden h-2.5 w-2.5 rounded-xl bg-white peer-checked:block" />
        </>
      )}
      {optionLabel}
    </label>
  </Base1>
);

export const CheckboxWithLabel = ({
  optionLabel,
  optionId,
  shouldShowControl = true,
  isDisabled,
  register,
}: OptionProps) => (
  <Base1
    className={clsx("absolute top-0.5 left-2", {
      "top-2": !shouldShowControl,
    })}
  >
    <label>
      {shouldShowControl && (
        <>
          <input
            disabled={!shouldShowControl || isDisabled}
            type="checkbox"
            className="peer appearance-none"
            value={optionId}
            {...register("multiChoicePollOption")}
          />
          <span className="relative top-1.5 mr-2.5 inline-block h-6 w-6 rounded bg-tint-dark-10 peer-checked:bg-primary-turquoise-50" />
          <Check
            color="white"
            size={24}
            weight="bold"
            className="absolute top-[6px] hidden peer-checked:block"
          />
        </>
      )}
      {optionLabel}
    </label>
  </Base1>
);
