import { useUpdateTasksAssigneeMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation UpdateTasksAssignee($tasksAssignee: UpdateTasksAssigneeInput!) {
    updateTasksAssignee(tasksAssignee: $tasksAssignee) {
      id
      isCompleted
    }
  }
`;

function useUpdateTasksAssignee() {
  const queryClient = useQueryClient();
  return useUpdateTasksAssigneeMutation({
    onSuccess: () => {
      void queryClient.refetchQueries(["subFilters"]);
      void queryClient.refetchQueries(["subFilters"]);
    },
  });
}

export default useUpdateTasksAssignee;
