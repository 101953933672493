/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HStack, VStack } from "components/layout";
import { Modal } from "components/partial";
import { Base2, Display4 } from "components/Typography";
import { CChartIcon, DChartIcon, IChartIcon, SChartIcon } from "icons";
import { X } from "phosphor-react";
import React from "react";

import Avatar from "../Avatar";
import Button from "../Button";
import AnnonymUser from "./AnnonymUser.png";

type PieChartModalProps = {
  open: boolean;
  onCancel: () => void;
  pieModalType: string;
  pieModalUsers: [] | null | undefined;
  chartImage?: string;
  AnnonymousUsers?: number | null | undefined;
};

function PieChartModal({
  open,
  onCancel,
  pieModalType,
  pieModalUsers,
  chartImage,
  AnnonymousUsers,
}: PieChartModalProps) {
  const MAX_DISPLAYED_USERS = 9;
  return (
    <Modal
      isFullScreen
      open={open}
      onClose={onCancel}
      className="flex h-[431px] w-[592px] flex-col overflow-scroll rounded-none p-10"
    >
      <HStack className="" align="center" justify="between">
        <HStack className="!space-x-4">
          <HStack>
            {pieModalType.toUpperCase().startsWith("D") && <DChartIcon />}
            {pieModalType.toUpperCase().startsWith("I") && <IChartIcon />}
            {pieModalType.toUpperCase().startsWith("S") && <SChartIcon />}
            {pieModalType.toUpperCase().startsWith("C") && <CChartIcon />}
          </HStack>
          <VStack className="">
            <Display4 className="">{pieModalType} Type</Display4>
            <Base2 className="font-normal text-neutral-800">
              {pieModalType.toUpperCase().startsWith("D") &&
                "Bold and Task focused"}
              {pieModalType.toUpperCase().startsWith("I") &&
                "Bold and People focused"}
              {pieModalType.toUpperCase().startsWith("S") &&
                "Calm and People focused"}
              {pieModalType.toUpperCase().startsWith("C") &&
                "Calm and Task focused"}
            </Base2>
          </VStack>
        </HStack>
        <Button
          onClick={onCancel}
          variant="secondaryDark"
          className="self-end !px-3 py-3 focus:!outline-0 focus:!ring-0 focus:!ring-offset-0"
          aria-label="Close Modal"
          data-testid="close-project-goal"
        >
          <X className="h-6 w-6 text-white" />
        </Button>
      </HStack>
      <div className="h-10 w-10"></div>
      <div className="ml-4">
        {pieModalUsers &&
          pieModalUsers?.map((user) => {
            return (
              <>
                <HStack className="max-w-4/5 flex-col items-center justify-center px-10 pl-0">
                  <HStack className="mt-2 mb-2 flex w-full">
                    <HStack>
                      <Avatar
                        size="small"
                        className="-ml-2 border border-tint-dark-15"
                        textClassName="!text-xs"
                        user={user}
                      />
                    </HStack>
                    <HStack className="!ml-4 flex-col">
                      <Base2>
                        {user.firstName} {user.lastName}
                      </Base2>
                      <HStack>{user.defaultProjectRole}</HStack>
                    </HStack>
                  </HStack>
                </HStack>
              </>
            );
          })}
        <HStack className="w-full">
          <HStack className="-ml-1">
            {!!AnnonymousUsers ? (
              <img className="h-6 w-6" src={AnnonymUser}></img>
            ) : (
              ""
            )}
          </HStack>
          <HStack>
            {!!AnnonymousUsers ? (
              <HStack className="ml-4">Anonymous Members</HStack>
            ) : (
              ""
            )}
          </HStack>
        </HStack>
      </div>
    </Modal>
  );
}

export default PieChartModal;
