/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable unicorn/no-nested-ternary */
import { Button } from "components/base";
import {
  DefaultHeader,
  ShareModalContent,
} from "components/pages/ShareModal/ShareModal";
import { useGetEnvsContext, useInviteUsers } from "contexts";
import { Project } from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import React, { useRef } from "react";

import { InviteProjectMembersHeader, MembersList } from "../../ShareModal";

export const TeamMembers = ({ project }: { project: Project }) => {
  const initialFocusRef = useRef<HTMLButtonElement>(null);
  const { isInInviteMode } = useInviteUsers();
  const members = project.projectAssignments;

  const { hasViewAccess } = useProjectAccessLevel({
    projectId: project?.id,
  });

  return (
    <>
      {isInInviteMode ? (
        <>
          {" "}
          <InviteProjectMembersHeader />
          <ShareModalContent />{" "}
        </>
      ) : (
        <div className="mt-6 flex flex-col gap-3">
          <DefaultHeader
            canInvite={!hasViewAccess}
            initialFocusRef={initialFocusRef}
          />
          <MembersList.PendingInvitations />
          {members.length > 0 ? (
            <MembersList.ProjectMembers />
          ) : (
            <div>Loading...</div>
          )}
        </div>
      )}
    </>
  );
};

export function InviteMemberFooter() {
  const {
    sendInvitations,
    selectedUsers,
    selectedEmails,
    cancelInviteModeHandler,
  } = useInviteUsers();
  const { currentUser } = useGetEnvsContext();

  return (
    <div className="flex justify-around ">
      <Button variant="outline" onClick={cancelInviteModeHandler} type="button">
        {t(`shared.cancel`)}
      </Button>

      <Button
        onClick={() => void sendInvitations()}
        size="regular"
        disabled={
          (selectedUsers?.length === 0 && selectedEmails?.length === 0) ||
          selectedEmails.includes(currentUser?.email || "")
        }
      >
        {t("component.shareModal.invite")}
      </Button>
    </div>
  );
}
