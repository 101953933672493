import React from "react";

function ShareoutSmallActiveIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_46_1447)">
        <path
          d="M12.8972 34.7004C17.0317 34.7004 20.3833 31.3488 20.3833 27.2144C20.3833 23.0799 17.0317 19.7283 12.8972 19.7283C8.76277 19.7283 5.41113 23.0799 5.41113 27.2144C5.41113 31.3488 8.76277 34.7004 12.8972 34.7004Z"
          fill="#8B4BBF"
        />
        <path
          d="M38.4024 36.9909H38.4014L12.7927 37.0056H12.7879C10.5208 36.9695 8.33405 36.1605 6.58953 34.7122C4.84501 33.264 3.64735 31.2634 3.1948 29.0417C2.74225 26.82 3.06194 24.5104 4.10097 22.4951C5.13999 20.4799 6.83604 18.8799 8.90835 17.96L21.1401 13.3489L19.7837 16.6977C19.7098 16.8799 19.6725 17.0749 19.674 17.2715C19.6755 17.4681 19.7157 17.6625 19.7923 17.8436C19.869 18.0246 19.9805 18.1889 20.1206 18.3268C20.2607 18.4648 20.4265 18.5738 20.6088 18.6477C20.791 18.7215 20.986 18.7588 21.1826 18.7573C21.3792 18.7558 21.5736 18.7156 21.7546 18.639C21.9357 18.5624 22.0999 18.4508 22.2379 18.3108C22.3759 18.1707 22.4849 18.0048 22.5588 17.8226L25.2052 11.2897C25.3544 10.9216 25.3512 10.5092 25.1964 10.1435C25.0415 9.77768 24.7478 9.48838 24.3796 9.33921L17.8468 6.69276C17.4788 6.54427 17.0669 6.54794 16.7016 6.70296C16.3363 6.85798 16.0474 7.15167 15.8985 7.51952C15.7496 7.88736 15.7527 8.29928 15.9073 8.66479C16.0619 9.03029 16.3553 9.31949 16.7229 9.46885L19.7223 10.6838L7.78984 15.1828C5.07228 16.3626 2.84162 18.4388 1.47028 21.0648C0.0989323 23.6909 -0.330101 26.708 0.254803 29.6122C0.839707 32.5165 2.40315 35.1322 4.68411 37.0228C6.96508 38.9133 9.82552 39.9642 12.7879 40H12.7942L38.4034 39.9854C38.8004 39.9852 39.1812 39.8274 39.4619 39.5465C39.7426 39.2656 39.9002 38.8847 39.9001 38.4877C39.8999 38.0906 39.7421 37.7098 39.4612 37.4291C39.1803 37.1484 38.7994 36.9908 38.4024 36.9909H38.4024Z"
          fill="#35363E"
        />
        <path
          d="M33.4113 12.9722C34.6945 12.9721 35.9488 12.5915 37.0157 11.8785C38.0826 11.1656 38.9142 10.1523 39.4053 8.96681C39.8963 7.78131 40.0249 6.47682 39.7747 5.21827C39.5244 3.95972 38.9067 2.80361 37.9994 1.89613H37.999C37.2445 1.1418 36.3156 0.58517 35.2946 0.27555C34.2736 -0.0340693 33.192 -0.0871261 32.1457 0.121079C31.0993 0.329284 30.1204 0.792326 29.2957 1.46919C28.471 2.14606 27.8259 3.01585 27.4177 4.00155C27.0094 4.98724 26.8505 6.0584 26.9551 7.12017C27.0596 8.18193 27.4244 9.20152 28.0171 10.0886C28.6099 10.9757 29.4122 11.703 30.3531 12.206C31.294 12.709 32.3444 12.9721 33.4113 12.9722ZM30.9413 4.01328C31.2652 3.68684 31.6505 3.42758 32.0749 3.25038C32.4993 3.07318 32.9545 2.98153 33.4145 2.98068C33.8744 2.97983 34.3299 3.06981 34.755 3.24544C35.1801 3.42107 35.5663 3.6789 35.8914 4.00415C36.2166 4.32939 36.4744 4.71564 36.6499 5.14074C36.8255 5.56583 36.9153 6.02141 36.9144 6.48132C36.9135 6.94124 36.8217 7.39644 36.6444 7.82082C36.4672 8.2452 36.2078 8.63039 35.8813 8.9543C35.2159 9.58964 34.3313 9.94415 33.4113 9.94415C32.4913 9.94415 31.6067 9.58964 30.9413 8.9543C30.2862 8.29898 29.9183 7.41035 29.9183 6.48379C29.9183 5.55723 30.2862 4.6686 30.9413 4.01328Z"
          fill="#35363E"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_1447">
          <rect width="39.8995" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShareoutSmallActiveIcon;
