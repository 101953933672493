/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { format } from "date-fns";
import { PulseCheckEntry } from "graphql/generated";
import { t } from "i18n-js";
import { TeamViewPulseCheckIllustration } from "icons/illustrations";
import { noop } from "lodash";
import { CaretDown, Check, Export } from "phosphor-react";
import { useProject } from "queries";
import { usePulseCheckCSVResponse } from "queries/use-pulse-check-csv-response";
import { usePulseCheckEntries } from "queries/use-pulse-check-entries";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { openInNewTab, Z_INDEX_LEVELS } from "utils";

import { Dropdown, WhiteCard } from "./base";
import LineChart from "./base/LineChart";
import { HStack, VStack } from "./layout";
import { TeamSection } from "./pages/TeamView/TeamView";
import { Base2, Caption1, Caption2, Heading2 } from "./Typography";

interface Label {
  date: string;
  value: string;
}
interface tooltipDataType {
  date: string;
  numberOfResponses: string;
  average: number;
  autonomy: number;
  alignment: number;
  connection: number;
  energy: number;
  highestScore: number;
  lowestScore: number;
}

const GRAPH_FILTERING_OPTIONS = {
  OVERALL: {
    TYPE: "OVERALL",
    TITLE: "Overall",
    DESCRIPTION: "An average of the 4 collaboration metrics",
    KEY: "average",
  },
  ALIGNMENT_METRIC: {
    TYPE: "ALIGNMENT_METRIC",
    TITLE: "Alignment metric",
    DESCRIPTION: "Measures team alignment",
    KEY: "alignment",
  },
  CONNECTION_METRIC: {
    TYPE: "CONNECTION_METRIC",
    TITLE: "Connection metric",
    DESCRIPTION: "Measures emotional sense of belonging",
    KEY: "connection",
  },
  ENERGY_METRIC: {
    TYPE: "ENERGY_METRIC",
    TITLE: "Energy metric",
    DESCRIPTION: "Measures inspiration towards shared vision",
    KEY: "energy",
  },
  AUTONOMY_METRIC: {
    TYPE: "AUTONOMY_METRIC",
    TITLE: "Autonomy metric",
    DESCRIPTION: "Measures feelings of ownership",
    KEY: "autonomy",
  },
};

type PulseChecksCardsProps = {
  phasePulseCheckFlag?: boolean;
  phaseStartDate?: string;
  phaseEndDate?: string;
};

const PulseChecksCards = ({
  phasePulseCheckFlag,
  phaseStartDate,
  phaseEndDate,
}: PulseChecksCardsProps) => {
  const [averageScores, setAverageScores] = useState<number[]>([]);
  const [tooltipData, setTooltipData] = useState<tooltipDataType[]>([]);
  const [labels, setLabels] = useState<Label[]>([]);

  const [graphFilterOption, setGraphFilterOption] = useState(
    GRAPH_FILTERING_OPTIONS.OVERALL,
  );

  const { projectId } = useParams();
  const { project } = useProject({ id: projectId as string });

  const { csvUrl } = usePulseCheckCSVResponse({
    id: projectId as string,
    pulseInputForExport: {
      fromDate: project?.startDate as string,
      toDate: format(new Date(), "yyyy-MM-dd"),
    },
  });

  const exportCSVFile = () =>
    !!csvUrl
      ? openInNewTab(csvUrl || "")
      : alert(`${t("errors.noPulseCheckSubmitted")}`);

  const { pulseCheckEntries } = usePulseCheckEntries({
    input: {
      projectId: projectId as string,
      fromDate: phasePulseCheckFlag
        ? (phaseStartDate as string)
        : (project?.startDate as string),
      toDate: phasePulseCheckFlag
        ? (phaseEndDate as string)
        : format(new Date(), "yyyy-MM-dd"),
    },
  });

  useEffect(() => {
    if (
      (pulseCheckEntries?.pulseCheckEntries as PulseCheckEntry[])?.length > 0
    ) {
      const key = graphFilterOption.KEY;

      setAverageScores(
        (pulseCheckEntries?.pulseCheckEntries as PulseCheckEntry[])?.map(
          (item) =>
            key === GRAPH_FILTERING_OPTIONS?.OVERALL.KEY
              ? item?.[key]
              : item?.[key]?.average || "",
        ),
      );

      setTooltipData(() => []);
      setLabels(() => []);

      // eslint-disable-next-line unicorn/no-array-for-each
      pulseCheckEntries?.pulseCheckEntries?.forEach((item) => {
        const tooltip: tooltipDataType = {
          date: "",
          numberOfResponses: "0",
          alignment: 0,
          autonomy: 0,
          average: 0,
          connection: 0,
          energy: 0,
          highestScore: 0,
          lowestScore: 0,
        };
        const label: Label = { value: "", date: "" };
        label.value = `${
          graphFilterOption !== GRAPH_FILTERING_OPTIONS.OVERALL
            ? item?.[key]?.average || 0
            : item?.[key]
        }`;
        label.date = format(new Date(item?.date as string), "MMM dd");
        tooltip.date = format(new Date(item?.date as string), "MMM dd");
        tooltip.numberOfResponses = item?.numberOfResponses || "0";
        tooltip.highestScore = item?.highestScore || 0;
        tooltip.average = item?.average || 0;
        tooltip.lowestScore = item?.lowestScore || 0;

        if (graphFilterOption !== GRAPH_FILTERING_OPTIONS.OVERALL) {
          tooltip.highestScore = item?.[key]?.highestScore || 0;
          tooltip.average = item?.[key]?.average || 0;
          tooltip.lowestScore = item?.[key]?.lowestScore || 0;
        }

        if (graphFilterOption === GRAPH_FILTERING_OPTIONS.OVERALL) {
          tooltip.alignment = item?.alignment?.average || 0;
          tooltip.autonomy = item?.autonomy?.average || 0;
          tooltip.connection = item?.connection?.average || 0;
          tooltip.energy = item?.energy?.average || 0;
        }

        setTooltipData((prev) => [...prev, tooltip]);
        setLabels((prev) => [...prev, label]);
      });
    }
  }, [pulseCheckEntries, graphFilterOption]);

  return (
    <>
      {pulseCheckEntries?.pulseCheckEntries?.length > 0 ? (
        <WhiteCard
          onClick={noop}
          className={`${
            !!phasePulseCheckFlag
              ? "border-bg-[#5F636826] w-[544px] border"
              : "!w-full"
          } "h-[280px] !bg-white p-5`}
        >
          <HStack
            space={2}
            align="center"
            className="mb-5 flex justify-between"
          >
            <Heading2 className="block text-neutral-900">
              {t("component.pulseChecks.heading")}
            </Heading2>
            <HStack className="g-2">
              <div
                className="flex items-center rounded-full  p-1 px-4"
                onClick={() => exportCSVFile()}
              >
                <span
                  data-testid="alignment-dropdown"
                  className="font-Steradian ml-1 flex h-6 items-center text-center text-base font-normal not-italic"
                >
                  {t("component.pulseChecks.export")}
                </span>
                <span className="mt-1 ml-1">
                  <Export className="mb-[6px] text-neutral-60" size={18} />
                </span>
              </div>
              {!phasePulseCheckFlag && (
                <Dropdown
                  button={
                    <Menu.Button
                      as="button"
                      className="flex items-center rounded-full border border-solid border-gray-400 p-1 px-4"
                    >
                      <span
                        data-testid="alignment-dropdown"
                        className="font-Steradian ml-1 flex h-6 items-center text-center text-base font-normal not-italic"
                      >
                        {graphFilterOption.TITLE}
                      </span>
                      <span className="mt-1 ml-1">
                        <CaretDown className="mb-1 text-neutral-60" size={18} />
                      </span>
                    </Menu.Button>
                  }
                  items={
                    <Menu.Items
                      className={clsx(
                        "absolute right-0 top-12 flex max-h-[409px] w-64 origin-top-right flex-col overflow-hidden rounded-xl bg-white p-4 text-left shadow-membersOnline focus:outline-none",
                        Z_INDEX_LEVELS.BASE_CONTROL,
                      )}
                    >
                      {Object.values(GRAPH_FILTERING_OPTIONS).map((option) => (
                        <Menu.Item>
                          <button
                            className="mb-2 flex cursor-pointer "
                            onClick={() => setGraphFilterOption(option)}
                          >
                            <Check
                              size={18}
                              className={clsx(
                                "mr-3",
                                graphFilterOption.TYPE !== option.TYPE &&
                                  "invisible",
                              )}
                            />
                            <VStack className="para-w-stretch-space text-left">
                              <Base2
                                className={clsx(
                                  "text-left",
                                  graphFilterOption.TYPE === option.TYPE &&
                                    "font-medium",
                                )}
                              >
                                {option.TITLE}
                              </Base2>
                              <Caption1 className="text-left text-neutral-700">
                                {option.DESCRIPTION}
                              </Caption1>
                            </VStack>
                          </button>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  }
                />
              )}
            </HStack>
          </HStack>
          <HStack space={6} className="h-[190px]" align="center">
            <VStack
              className={`h-full ${
                !!phasePulseCheckFlag ? "w-[148px]" : "w-[200px]"
              } justify-around`}
            >
              <Base2 className="text-neutral-900">
                {!!phasePulseCheckFlag
                  ? t("component.pulseChecks.phaseSummaryLabel1")
                  : t("component.pulseChecks.label1")}

                <span
                  className={clsx(
                    "inline",
                    pulseCheckEntries?.pulseCheckHealth === "High"
                      ? "text-[#00AE8F]"
                      : "text-secondary-red-700",
                  )}
                >
                  {pulseCheckEntries?.pulseCheckHealth?.toLowerCase() || ""}
                </span>

                {!!phasePulseCheckFlag
                  ? t("component.pulseChecks.phaseSummaryLabel2")
                  : t("component.pulseChecks.label2", {
                      weeks:
                        pulseCheckEntries?.repeatationInweeks &&
                        pulseCheckEntries?.repeatationInweeks > 1
                          ? `${pulseCheckEntries?.repeatationInweeks} weeks`
                          : "1 week",
                    })}
              </Base2>
              <Caption2 className="text-neutral-600">
                {t("component.pulseChecks.description")}
              </Caption2>
            </VStack>
            {averageScores?.length && (
              <LineChart
                data={averageScores}
                labels={labels}
                max={6}
                min={0}
                stepSize={1}
                xGrid
                ticksDisplay
                tooltipData={tooltipData}
                displayPoints={phasePulseCheckFlag ? 1 : 5}
                alterWidth={phasePulseCheckFlag}
              />
            )}
          </HStack>
        </WhiteCard>
      ) : (
        <TeamSection className="pb-16">
          <Heading2 className="mb-4 text-neutral-900">
            {t("team.pulseChecks.title")}
          </Heading2>
          <div className="flex flex-col items-center gap-2">
            <TeamViewPulseCheckIllustration />
            <Caption1>{t("team.pulseChecks.emptyDescriptionTitle")}</Caption1>
            <Caption2 className="max-w-[544px] text-center text-neutral-70">
              {t("team.pulseChecks.emptyDescriptionSubtitle")}
            </Caption2>
          </div>
        </TeamSection>
      )}
    </>
  );
};

export default PulseChecksCards;
