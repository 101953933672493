import { useCreateDailyRoomMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation CreateDailyRoom($dailyRoom: CreateDailyRoomInput!) {
    createDailyRoom(dailyRoom: $dailyRoom) {
      id
      title
    }
  }
`;

export function useCreateBreakoutRoom() {
  const queryClient = useQueryClient();
  return useCreateDailyRoomMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["WorkshopById"]);
      await queryClient.refetchQueries(["WorkshopById"]);
    },
  });
}
