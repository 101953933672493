export enum TEMPERATURE_CHECK_VALUES {
  GREAT = "great",
  GOOD = "good",
  FINE = "fine",
  BAD = "bad",
  AWFUL = "awful",
}

export enum PULSE_CHECK_X_LABELS {
  GREAT = "Great",
  GOOD = "Pretty Good",
  OKAY = "Okay",
  BAD = "Pretty Bad",
  AWFUL = "Awful",
}

// RESPONSE_DELAY in seconds
export const RESPONSE_DELAY = 10;
