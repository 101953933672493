/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable unicorn/no-null */
import { ItemId, TreeItem } from "@atlaskit/tree";
import { Button } from "components/base";
import {
  ActivityTypes,
  DraggableList,
  TypeSelectorView,
} from "components/partial";
import { ActivityProps } from "components/partial/DraggableList/DraggableItem/DraggableItem.types";
import { Activity, PollPollType, WorkshopActivity } from "graphql/generated";
import { t } from "i18n-js";
import _ from "lodash";
import {
  useCreateActivity,
  useCreateSection,
  useDeleteActivity,
  useDeleteSection,
  useUpdateActivity,
  useUpdatePosition,
} from "mutations/workshop";
import { VideoCamera } from "phosphor-react";
import { useWorkshop } from "queries";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import ActivityContent from "./ActivityContent";
import { ActivityContentHeader } from "./ActivityContentHeader";
import { AgendaTimeMeter } from "./AgendaTimeMeter";
import { AgendaVisibilitySelector } from "./AgendaVisibilitySelector";

const getErrorMessage = (activity: Activity) => {
  if (
    activity.__typename === "Resource" &&
    activity.documents?.length === 0 &&
    activity.link === null
  ) {
    return t("workshopEditor.agendaErrors.missingResource");
  }

  if (
    activity.__typename === "Poll" &&
    (activity.pollType === PollPollType.SingleChoice ||
      activity.pollType === PollPollType.MultipleChoice) &&
    (!activity.question || activity.options?.length === 0)
  ) {
    return t("workshopEditor.agendaErrors.missingPollQuestionOrOption");
  }

  if (
    activity.__typename === "Poll" &&
    (activity.pollType === PollPollType.Paragraph ||
      activity.pollType === PollPollType.WordCloud) &&
    !activity.question
  ) {
    return t("workshopEditor.agendaErrors.missingPollQuestion");
  }

  if (activity.__typename === "Breakout") {
    const areRoomsEmpty = activity.dailyRooms.some(
      (room) =>
        room?.workshopParticipants && room?.workshopParticipants.length === 0,
    );

    if (activity.dailyRooms.length === 0) {
      return t("workshopEditor.agendaErrors.missingBreakoutRooms");
    }

    if (activity.dailyRooms.length > 0 && areRoomsEmpty) {
      return t("workshopEditor.agendaErrors.missingRoomAssignments");
    }
  }

  return "";
};

const getItemObject = (item: WorkshopActivity) => ({
  id: `WorkshopActivity-${item.id}`,
  children: [],
  hasChildren: false,
  isExpanded: false,
  isChildrenLoading: false,
  sectionId: item.sectionId,
  data: {
    title: item.title,
    type: item.activity.__typename?.toLowerCase(),
    time: item.duration,
    errorMessage: getErrorMessage(item.activity),
  },
});

type WorkshopAgendaProps = {
  startTime: Date;
  endTime: Date;
  workshopIsReady: boolean;
  onMutationStateChange: ({ isLoading }: { isLoading: boolean }) => void;
  joinLiveHandler?: () => void;
  isWorkshopLive?: boolean;
};

/**
 * This WorkshopAgenda is for use in the pre-session configuration of a Workshop.
 * It allows the user to add, remove, and reorder items in the agenda.
 */
const WorkshopAgenda = ({
  startTime,
  endTime,
  workshopIsReady,
  onMutationStateChange,
  joinLiveHandler,
  isWorkshopLive = false,
}: WorkshopAgendaProps) => {
  const [items, setItems] = useState<Record<ItemId, TreeItem>>({});
  const [rootItems, setRootItems] = useState<ItemId[]>([]);
  const [selectedItem, setSelectedItem] = useState<
    (TreeItem & { sectionId?: string }) | undefined
  >(undefined);
  const [addingActivity, setAddingActivity] = useState(false);

  const { id: workshopId = "" } = useParams();
  const { workshop: { agenda = [] } = {} } = useWorkshop({ id: workshopId });
  const { mutate: addActivity, isLoading: isAddingActivity } =
    useCreateActivity();
  const { mutate: deleteActivity } = useDeleteActivity();
  const { mutate: deleteSection } = useDeleteSection();
  const { mutate: updateActivity } = useUpdateActivity();
  const { mutate: updatePosition } = useUpdatePosition();
  const { mutate: addSection } = useCreateSection();

  const debouncedUpdateActivity = useCallback(
    _.debounce(updateActivity, 2000),
    [],
  );

  // This formats the data coming from the server into a format that the DraggableList component can use
  useEffect(() => {
    const newItems = {} as Record<ItemId, TreeItem>;
    const newRootItems = [];

    for (const item of agenda) {
      const id = `${item.__typename || ""}-${item.id}`;
      const isSection = item.__typename === "Section";
      newRootItems.push(id);

      if (isSection) {
        const hasChildren = !!item.workshopActivities?.length;

        newItems[id as ItemId] = {
          id: `Section-${item.id}`,
          children: item.workshopActivities
            ? item.workshopActivities.map(
                (activity) => `WorkshopActivity-${activity.id}`,
              )
            : [],
          hasChildren,
          isExpanded: hasChildren,
          isChildrenLoading: false,
          data: {
            type: "section",
            title: item.title,
          },
        } as TreeItem;

        for (const child of item.workshopActivities || []) {
          newItems[`WorkshopActivity-${child.id}`] = getItemObject(child);
        }
      } else {
        newItems[id] = getItemObject(item as WorkshopActivity);
      }
    }

    if (selectedItem && !_.isEqual(selectedItem, newItems[selectedItem.id])) {
      setSelectedItem(newItems[selectedItem.id]);
    }

    setItems(newItems);
    setRootItems(newRootItems);
  }, [agenda, selectedItem?.sectionId]);

  const handleGoToPreview = () => {
    window.open(`/workshop/${workshopId}/preview`);
  };

  const handleAddItem = useCallback(
    (type: ActivityTypes) => {
      addActivity(
        {
          workshopActivity: {
            title: t("workshopEditor.untitled"),
            activityType: type.charAt(0).toUpperCase() + type.slice(1),
            workshopId,
            duration: 10,
          },
        },
        {
          onSuccess: ({ createWorkshopActivity }) => {
            setAddingActivity(false);

            const id = createWorkshopActivity?.id;

            if (id) {
              setSelectedItem({
                id: `WorkshopActivity-${id}`,
                children: [],
                hasChildren: false,
                isExpanded: false,
                isChildrenLoading: false,
                data: {
                  title: t("workshopEditor.untitled"),
                  time: 10,
                  type,
                },
              });
            }
          },
          onError: () => {
            console.error(t("errors.somethingWentWrong"));
          },
        },
      );
    },
    [addActivity, workshopId],
  );

  // This removes an item from the list and resets selectedItem if it's the one being removed.
  const handleDeleteItem = useCallback(
    (itemId: ItemId) => {
      const [type, id] = itemId.toString().split("-");
      const onComplete = {
        onSuccess: () => {
          // remove the item from local state
          const newItems = { ...items };
          delete newItems[itemId];
          setItems(newItems);

          // reset the selected item if it's the one being removed
          if (selectedItem?.id === itemId) {
            setSelectedItem(undefined);
          }
        },
        onError: () => {
          console.error(t("errors.somethingWentWrong"));
        },
      };

      if (type === "Section") {
        deleteSection({ id }, onComplete);
      } else {
        deleteActivity({ id }, onComplete);
      }
    },
    [deleteActivity, deleteSection, items, selectedItem],
  );

  const onActivityDataUpdate = useCallback(
    (dataChanges: ActivityProps) => {
      if (selectedItem) {
        const newData = {
          ...(selectedItem.data as ActivityProps),
          ...dataChanges,
        };

        setSelectedItem({ ...selectedItem, data: newData });
        const newItems = { ...items };

        const id = selectedItem.id;

        const type = dataChanges.type as string;

        debouncedUpdateActivity({
          updateWorkshopActivity: {
            title: dataChanges?.title as string,
            duration: dataChanges?.time as number,
            activityType: type && type.charAt(0).toUpperCase() + type.slice(1),
            id: id.toString().split("-")[1],
          },
        });

        newItems[id].data = newData;
        setItems(newItems);
      }
    },
    [debouncedUpdateActivity, items, selectedItem],
  );

  const onAddToSection = useCallback(
    (itemId: ItemId, sectionId: ItemId) => {
      updateActivity({
        updateWorkshopActivity: {
          id: itemId.toString().split("-")[1],
          sectionId: sectionId.toString().split("-").at(-1),
        },
      });
    },
    [updateActivity],
  );

  const onSectionCreate = useCallback(
    (itemIds: ItemId[], sectionName: string) => {
      addSection(
        { section: { title: sectionName, workshopId } },
        {
          onSuccess: async ({ createSection }) => {
            if (createSection) {
              await Promise.allSettled(
                itemIds.map((id) => onAddToSection(id, createSection.id)),
              );
            }
          },
          onError: () => {
            console.error(t("errors.somethingWentWrong"));
          },
        },
      );
    },
    [addSection, onAddToSection, workshopId],
  );

  const onItemMove = useCallback(
    (items: ItemId[], leavingSectionID?: ItemId) => {
      const activities = [] as { id: string; position: number }[];
      const sections = [] as { id: string; position: number }[];

      for (const [index, item] of items.entries()) {
        const [type, id] = item.toString().split("-");

        if (type === "WorkshopActivity") {
          activities.push({ id, position: index });
        } else {
          sections.push({ id, position: index });
        }
      }

      if (leavingSectionID) {
        updateActivity({
          updateWorkshopActivity: {
            id: leavingSectionID.toString().split("-")[1],
            // eslint-disable-next-line unicorn/no-null
            sectionId: null,
          },
        });
      }

      updatePosition({
        positions: {
          workshopActivities: activities.map(({ id, position }) => ({
            id,
            position,
          })),
          sections: sections.map(({ id, position }) => ({
            id,
            position,
          })),
        },
      });
    },
    [updateActivity, updatePosition],
  );

  const handleSetSelectedItem = (itemId: ItemId) => {
    setSelectedItem(items[itemId]);
    if (setAddingActivity) {
      setAddingActivity(false);
    }
  };

  const { selectedType, selectedActivityId } = useMemo(() => {
    return {
      selectedType: (selectedItem?.data as ActivityProps)?.type,
      selectedActivityId: selectedItem?.id?.toString().split("-")[1],
    };
  }, [selectedItem]);

  const JoinLiveIcon = () => <VideoCamera size={18} />;
  const isTypeSelectorView =
    addingActivity || !selectedType || !selectedActivityId;

  return (
    <div className="relative flex flex-1">
      <div className="flex w-full max-w-[424px] flex-col overflow-hidden bg-neutral-0">
        <div className="relative flex-1">
          <div className="absolute h-full max-h-full w-full overflow-auto p-6">
            <div className="mb-2 flex justify-between align-middle">
              <AgendaVisibilitySelector workshopId={workshopId} />
            </div>
            {Object.keys(rootItems).length > 0 && (
              <DraggableList
                rootItems={rootItems}
                items={items}
                onItemDelete={handleDeleteItem}
                onItemSelect={handleSetSelectedItem}
                onSectionCreate={onSectionCreate}
                onAddToSection={onAddToSection}
                selectedItemId={selectedItem?.id}
                onItemMove={onItemMove}
              />
            )}
          </div>
        </div>

        <div className="px-6 py-4 shadow-picker">
          <AgendaTimeMeter
            startTime={startTime}
            endTime={endTime}
            agendaItems={items}
          />
          <div className="flex gap-4">
            {isWorkshopLive ? (
              <Button
                variant="secondary"
                className="flex-1"
                onClick={
                  joinLiveHandler
                    ? joinLiveHandler
                    : () => {
                        window.open(`/workshop/${workshopId}`);
                      }
                }
                disabled={!workshopIsReady}
                LeftIcon={JoinLiveIcon}
              >
                {t("workshopEditor.joinLive")}
              </Button>
            ) : (
              <Button
                variant="outline"
                className="flex-1"
                onClick={handleGoToPreview}
                disabled={!workshopIsReady}
              >
                {t("workshopEditor.preview")}
              </Button>
            )}
            <Button
              className="flex-1"
              onClick={() => setAddingActivity(true)}
              disabled={!workshopIsReady || isTypeSelectorView}
            >
              {t("workshopEditor.addActivity")}
            </Button>
          </div>
        </div>
      </div>
      <div className="relative flex-1">
        <div className="absolute h-full w-full overflow-auto px-14 py-12">
          {selectedItem && selectedType && !addingActivity && (
            <ActivityContentHeader
              selectedItem={selectedItem}
              onActivityDataUpdate={onActivityDataUpdate}
            />
          )}

          {isTypeSelectorView ? (
            <TypeSelectorView
              onChange={(activityType) => {
                handleAddItem(activityType);
              }}
              isLoading={isAddingActivity}
            />
          ) : (
            <ActivityContent
              type={selectedType}
              activityId={selectedActivityId}
              workshopId={workshopId}
              onMutationStateChange={onMutationStateChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkshopAgenda;
