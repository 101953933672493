import { Avatar } from "components/base";
import { HStack, VStack } from "components/layout/Stack";
import { Base1, Base1Strong, Caption1 } from "components/Typography";
import { useGetEnvsContext } from "contexts";
import { t } from "i18n-js";
import React from "react";

function UserSettingsUserInfo() {
  const { currentUser } = useGetEnvsContext();

  if (!currentUser) {
    return <Base1>{t(`component.userSettings.sidebar.notLoggedIn`)}</Base1>;
  }

  const { fullName, defaultProjectRole } = currentUser;

  return (
    <HStack className="ml-14 gap-4" align="center">
      <Avatar user={currentUser} />
      <VStack>
        <Base1Strong className="text-neutral-90">{fullName}</Base1Strong>
        <Caption1 className="text-neutral-70">{defaultProjectRole}</Caption1>
      </VStack>
    </HStack>
  );
}

export default UserSettingsUserInfo;
