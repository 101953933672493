import {
  ProjectAssignmentsQuery,
  ProjectAssignmentsQueryVariables,
  useProjectAssignmentsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query ProjectAssignments($projectId: ID!) {
    projectById(id: $projectId) {
      projectAssignments {
        id
        user {
          id
          firstName
          lastName
          fullName
          email
          defaultProjectRole
          avatarUrl
          pronouns
          company
          discType
          visibleToTeam
          linkdinLink
          homebase
        }
        accessLevel
        manualOfMe {
          answer
          profileQuestion {
            question
          }
        }
        role
        lastAccessLevel
      }
    }
  }
`;

function useProjectAssignments(variables: ProjectAssignmentsQueryVariables): {
  projectAssignments: ProjectAssignmentsQuery["projectById"]["projectAssignments"];
} & UseQueryResult<ProjectAssignmentsQuery, unknown> {
  const response = useProjectAssignmentsQuery(variables, {
    suspense: true,
    enabled: !!variables.projectId,
  });

  return {
    projectAssignments: response.data?.projectById.projectAssignments || [],
    ...response,
  };
}

export { useProjectAssignments };
