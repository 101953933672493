import {
  ParticipantsBreakoutsRoomsQuery,
  ParticipantsBreakoutsRoomsQueryVariables,
  useParticipantsBreakoutsRoomsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query ParticipantsBreakoutsRooms($workshopId: ID!, $participantsIds: [ID!]!) {
    participantsBreakoutsRooms(
      participantsIds: $participantsIds
      workshopId: $workshopId
    ) {
      id
      workshopParticipantId
      token
      dailyRoomId
      createdAt
      updatedAt
      userAssignedToRoom
    }
  }
`;

function useParticipantsBreakoutsRooms(
  variables: ParticipantsBreakoutsRoomsQueryVariables,
): {
  participantsBreakoutsRooms?: ParticipantsBreakoutsRoomsQuery["participantsBreakoutsRooms"];
} & UseQueryResult<ParticipantsBreakoutsRoomsQuery, unknown> {
  const response = useParticipantsBreakoutsRoomsQuery(variables, {
    suspense: true,
  });

  return {
    participantsBreakoutsRooms: response.data?.participantsBreakoutsRooms,
    ...response,
  };
}

export { useParticipantsBreakoutsRooms };
