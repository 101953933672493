import React from "react";

function ProjectViewIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <circle cx="21" cy="21" r="20" fill="white" />{" "}
      <circle cx="21" cy="21" r="20.5" stroke="#5F6368" stroke-opacity="0.1" />{" "}
      <path
        d="M25.3788 21.246L34.9785 21.7769C34.9928 21.5188 35 21.2597 35 21C35 20.7403 34.9928 20.4812 34.9785 20.2231L25.3788 20.754L25.3792 20.7617C25.3834 20.8406 25.3855 20.9201 25.3855 21C25.3855 21.0799 25.3834 21.1594 25.3792 21.2383L25.3788 21.246Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M25.326 20.2754L25.3242 20.265C25.2969 20.1033 25.2608 19.9447 25.2163 19.7895L25.2152 19.7854L34.455 17.1279C34.5995 17.6302 34.7166 18.1434 34.8047 18.6654L25.326 20.2754Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M25.0528 19.3213L25.0514 19.3181C24.9887 19.167 24.9177 19.0202 24.8391 18.8783L24.8352 18.8713L33.2497 14.2201C33.5028 14.677 33.7315 15.1504 33.9339 15.6384L25.0528 19.3213Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M24.577 18.462L24.5726 18.4559C24.4787 18.3243 24.3777 18.198 24.2701 18.0777L24.2681 18.0755L31.4369 11.6687C31.7841 12.0571 32.1103 12.4653 32.4135 12.8918L24.577 18.462Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M23.9245 17.7319L23.9223 17.7299C23.802 17.6223 23.6757 17.5213 23.5441 17.4274L23.538 17.423L29.1082 9.58654C29.5347 9.8897 29.9429 10.2159 30.3313 10.5631L23.9245 17.7319Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M23.1287 17.1648L23.1217 17.1609C22.9798 17.0823 22.833 17.0113 22.6819 16.9486L22.6787 16.9472L26.3616 8.06611C26.8496 8.26847 27.323 8.49721 27.7799 8.75031L23.1287 17.1648Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M22.2146 16.7848L22.2105 16.7837C22.0553 16.7392 21.8967 16.7031 21.735 16.6758L21.7246 16.674L23.3346 7.19533C23.8566 7.2834 24.3698 7.40047 24.8721 7.54495L22.2146 16.7848Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M21.246 16.6212L21.2383 16.6208C21.1594 16.6166 21.0799 16.6145 21 16.6145C20.9201 16.6145 20.8406 16.6166 20.7617 16.6208L20.754 16.6212L20.2231 7.02145C20.4812 7.00718 20.7403 7 21 7C21.2597 7 21.5188 7.00718 21.7769 7.02145L21.246 16.6212Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M20.2754 16.674L20.265 16.6758C20.1033 16.7031 19.9447 16.7392 19.7895 16.7837L19.7854 16.7848L17.1279 7.54495C17.6302 7.40047 18.1434 7.2834 18.6654 7.19533L20.2754 16.674Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M19.3213 16.9472L19.3181 16.9486C19.167 17.0113 19.0202 17.0823 18.8783 17.1609L18.8713 17.1648L14.2201 8.75031C14.677 8.49721 15.1504 8.26847 15.6384 8.06611L19.3213 16.9472Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M18.462 17.423L18.4559 17.4274C18.3243 17.5213 18.198 17.6223 18.0777 17.7299L18.0755 17.7319L11.6687 10.5631C12.0571 10.2159 12.4653 9.8897 12.8918 9.58654L18.462 17.423Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M17.7319 18.0755L17.7299 18.0777C17.6223 18.198 17.5213 18.3243 17.4274 18.4559L17.423 18.462L9.58654 12.8918C9.8897 12.4653 10.2159 12.0571 10.5631 11.6687L17.7319 18.0755Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M17.1648 18.8713L17.1609 18.8783C17.0823 19.0202 17.0113 19.167 16.9486 19.3181L16.9472 19.3213L8.06611 15.6384C8.26847 15.1504 8.49721 14.677 8.75031 14.2201L17.1648 18.8713Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M16.7848 19.7854L16.7837 19.7895C16.7392 19.9447 16.7031 20.1033 16.6758 20.265L16.674 20.2754L7.19533 18.6654C7.2834 18.1434 7.40047 17.6302 7.54495 17.1279L16.7848 19.7854Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M16.6212 20.754L16.6208 20.7617C16.6166 20.8406 16.6145 20.9201 16.6145 21C16.6145 21.0799 16.6166 21.1594 16.6208 21.2383L16.6212 21.246L7.02145 21.7769C7.00718 21.5188 7 21.2597 7 21C7 20.7403 7.00718 20.4812 7.02145 20.2231L16.6212 20.754Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M16.674 21.7246L16.6758 21.735C16.7031 21.8967 16.7392 22.0553 16.7837 22.2105L16.7848 22.2146L7.54495 24.8721C7.40047 24.3698 7.2834 23.8566 7.19533 23.3346L16.674 21.7246Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M16.9472 22.6787L16.9486 22.6819C17.0113 22.833 17.0823 22.9798 17.1609 23.1217L17.1648 23.1287L8.75031 27.7799C8.49721 27.323 8.26847 26.8496 8.06611 26.3616L16.9472 22.6787Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M17.423 23.538L17.4274 23.5441C17.5213 23.6757 17.6223 23.802 17.7299 23.9223L17.7319 23.9245L10.5631 30.3313C10.2159 29.9429 9.8897 29.5347 9.58654 29.1082L17.423 23.538Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M18.0755 24.2681L18.0777 24.2701C18.198 24.3777 18.3243 24.4787 18.4559 24.5726L18.462 24.577L12.8918 32.4135C12.4653 32.1103 12.0571 31.7841 11.6687 31.4369L18.0755 24.2681Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M18.8713 24.8352L18.8783 24.8391C19.0202 24.9177 19.167 24.9887 19.3181 25.0514L19.3213 25.0528L15.6384 33.9339C15.1504 33.7315 14.677 33.5028 14.2201 33.2497L18.8713 24.8352Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M19.7854 25.2152L19.7895 25.2163C19.9447 25.2608 20.1033 25.2969 20.265 25.3242L20.2754 25.326L18.6654 34.8047C18.1434 34.7166 17.6302 34.5995 17.1279 34.455L19.7854 25.2152Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M20.754 25.3788L20.7617 25.3792C20.8406 25.3834 20.9201 25.3855 21 25.3855C21.0799 25.3855 21.1594 25.3834 21.2383 25.3792L21.246 25.3788L21.7769 34.9785C21.5188 34.9928 21.2597 35 21 35C20.7403 35 20.4812 34.9928 20.2231 34.9785L20.754 25.3788Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M21.7246 25.326L21.735 25.3242C21.8967 25.2969 22.0553 25.2608 22.2105 25.2163L22.2146 25.2152L24.8721 34.455C24.3698 34.5995 23.8566 34.7166 23.3346 34.8047L21.7246 25.326Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M22.6787 25.0528L22.6819 25.0514C22.833 24.9887 22.9798 24.9177 23.1217 24.8391L23.1287 24.8352L27.7799 33.2497C27.323 33.5028 26.8496 33.7315 26.3616 33.9339L22.6787 25.0528Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M23.538 24.577L23.5441 24.5726C23.6757 24.4787 23.802 24.3777 23.9223 24.2701L23.9245 24.2681L30.3313 31.4369C29.9429 31.7841 29.5347 32.1103 29.1082 32.4135L23.538 24.577Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M24.2681 23.9245L24.2701 23.9223C24.3777 23.802 24.4787 23.6757 24.5726 23.5441L24.577 23.538L32.4135 29.1082C32.1103 29.5347 31.7841 29.9429 31.4369 30.3313L24.2681 23.9245Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M24.8352 23.1287L24.8391 23.1217C24.9177 22.9798 24.9887 22.833 25.0514 22.6819L25.0528 22.6787L33.9339 26.3616C33.7315 26.8496 33.5028 27.323 33.2497 27.7799L24.8352 23.1287Z"
        fill="#DFBB00"
      />{" "}
      <path
        d="M25.2152 22.2146L25.2163 22.2105C25.2608 22.0553 25.2969 21.8967 25.3242 21.735L25.326 21.7246L34.8047 23.3346C34.7166 23.8566 34.5995 24.3698 34.455 24.8721L25.2152 22.2146Z"
        fill="#DFBB00"
      />{" "}
    </svg>
  );
}

export default ProjectViewIcon;
