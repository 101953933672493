import { getFooterActivities } from "components/partial";
import { FooterNavControl } from "components/partial/WorkshopFooter/FooterNavControl";
import { Base2, Caption1 } from "components/Typography";
import { WorkshopByIdQuery } from "graphql/generated";
import { t } from "i18n-js";
import React from "react";
import { useNavigate } from "react-router-dom";

export interface PreviewControlsProps {
  agenda?: WorkshopByIdQuery["workshopById"]["agenda"] | undefined;
  onNext: () => void;
  onPrev: () => void;
}

/** Akin to the AgendaPagination component except this doesn't mutate real workshop state. */
export const PreviewControls = ({
  agenda,
  onNext,
  onPrev,
}: PreviewControlsProps) => {
  const { prev, current, next } = getFooterActivities(agenda);
  const navigate = useNavigate();

  const inOvertime = false;

  const handleNext = () => {
    onNext();
  };
  const handlePrev = () => {
    onPrev();
  };

  const handleFinishPreview = () => {
    navigate("../edit");
  };

  return (
    <div className="flex flex-row text-white">
      {/* Workshop Not Yet Started */}
      {!current && (
        <FooterNavControl
          variant="solo"
          label={t("workshop.startSession")}
          title={next?.title || t("workshop.firstActivity")}
          isHighlighted
          onClick={handleNext}
        />
      )}

      {/* On First Activity */}
      {!prev && current && (
        <>
          <FooterNavControl
            variant="left"
            label="Current"
            title={current.title}
            isDisabled
          />

          <FooterNavControl
            variant="right"
            label={t("shared.next")}
            title={next?.title ?? t("workshop.endWorkshop")}
            onClick={next ? handleNext : handleFinishPreview}
            isHighlighted={inOvertime}
          />
        </>
      )}

      {/* Past First Activity */}
      {!!prev && !!current && (
        <>
          <FooterNavControl
            variant="left"
            label={t("shared.previous")}
            title={prev?.title}
            onClick={handlePrev}
            isDisabled={!prev}
          />

          <div
            className={`relative flex flex-1 flex-col items-center border py-[6.5px] px-5 text-center ${
              !inOvertime ? "border-white" : "border-neutral-70"
            }`}
          >
            <div className="absolute left-0 top-0 h-full bg-tint-light-20" />
            <Caption1>{t("shared.current")}</Caption1>
            <Base2 className="whitespace-nowrap">{current?.title}</Base2>
          </div>

          {/* Next / End Workshop Button */}
          <FooterNavControl
            variant="right"
            label={t("shared.next")}
            title={next?.title ?? t("workshop.preview.endPreview")}
            onClick={next ? handleNext : handleFinishPreview}
            isHighlighted={inOvertime}
          />
        </>
      )}
    </div>
  );
};
