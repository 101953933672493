import clsx from "clsx";
import { Base2, Caption1 } from "components/Typography";
import React from "react";

export interface FooterNavControlProps {
  variant: "left" | "right" | "solo";
  onClick?: () => void;
  isDisabled?: boolean;
  isHighlighted?: boolean;
  progress?: number;

  label: string;
  title?: string | null;
}

/**
 * This component provides a button which can be used in groups of 1-3
 * for assembling pill-shaped segmented button controls.
 * It has lots of context-specific visuals, so it's not really intended for
 * use in other contexts.
 */
export const FooterNavControl = ({
  variant,
  onClick,
  isHighlighted,
  isDisabled,
  progress,
  label,
  title,
}: FooterNavControlProps) => (
  <button
    disabled={isDisabled}
    onClick={onClick}
    className={clsx(
      `relative flex flex-1 basis-16 flex-col border border-neutral-70 py-[6.5px]`,
      {
        "border-neutral-70 bg-white": isHighlighted,
        "hover:bg-tint-light-20": !isDisabled,
        "cursor-not-allowed": isDisabled,
        "items-start rounded-r-full border-l-0 pl-3 pr-5": variant === "right",
        "items-end rounded-l-full border-r-0 pr-3 pl-5": variant === "left",
        "items-center rounded-full px-5": variant === "solo",
      },
    )}
  >
    {!!progress && (
      <div
        className={clsx(
          "absolute left-0 top-0 h-full w-full overflow-hidden border",
          {
            "rounded-r-full": variant === "right",
            "rounded-l-full": variant === "left",
            "border-white": progress < 100,
            "border-neutral-70": progress >= 100,
          },
        )}
      >
        <div
          className={clsx("h-full bg-tint-light-20")}
          style={{ width: `${progress}%` }}
        />
      </div>
    )}

    <Caption1
      className={isHighlighted ? "text-tint-dark-50" : "text-tint-light-50"}
    >
      {label}
    </Caption1>
    <Base2
      className={clsx("whitespace-nowrap", {
        "text-neutral-90": isHighlighted,
        "text-white": !isHighlighted,
      })}
    >
      {title ?? undefined}
    </Base2>
  </button>
);
