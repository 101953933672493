/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Loader } from "components/base";
import { useWorkshop } from "queries";
import { useWorkshopDocumentById } from "queries/use-documentById";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function WorkshopEmailDocuments() {
  const { id, docId } = useParams();
  const { document } = useWorkshopDocumentById({
    workshop_id: id as string,
    document_id: docId as string,
  });
  const { workshop } = useWorkshop({ id });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const redirect = document?.url;
  useEffect(() => {
    window.location.href = redirect;
  }, []);
  return (
    <div className="flex h-full w-full items-center justify-center ">
      <Loader />
    </div>
  );
}

export default WorkshopEmailDocuments;
