import { Menu } from "@headlessui/react";
import clsx from "clsx";
import {
  Base2,
  Caption1,
  Caption2,
  Heading3Strong,
  HStack,
  VStack,
} from "components";
import { Dropdown } from "components/base";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Notification } from "graphql/generated";
import { t } from "i18n-js";
import { BellRinging } from "icons";
import useUpdateNotification from "mutations/use-update-notification";
import { Bell, Circle } from "phosphor-react";
import { useNotifications } from "queries";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  MEETING_HEADING_REGEX,
  MEETING_NOFITIABLE_TYPE,
  NOTIFICATION_OFFSET,
} from "utils/constants/notification";
import { getTimeDifference, getTimeZone } from "utils/constants/time_helpers";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

const notificationType = (type: string) => {
  switch (type) {
    case (type = "workshop"):
      return <Circle color="#3A61EB" weight="fill" className="mr-1 inline" />;
    case (type = "announcement"):
      return <Circle color="#DEB30F" weight="fill" className="mr-1 inline" />;
    case (type = "task"):
      return <Circle color="#0FB256" weight="fill" className="mr-1 inline" />;
    case (type = "meeting"):
      return <Circle color="#2C8BC0" weight="fill" className="mr-1 inline" />;
    case (type = "huddle"):
      return <Circle color="#2B4FCF" weight="fill" className="mr-1 inline" />;
    default:
      break;
  }
};

function NotificationItem({
  notification,
  isLast,
}: {
  notification?: Notification;
  isLast: boolean;
}) {
  const [isNotificationDeleted, setIsNotificationDeleted] = useState(false);
  const { projectId: currentProjectId = "" } = useParams();
  const {
    isRead,
    notifiable,
    createdAt,
    projectId,
    heading,
    isDeleted,
    startDate,
  } = notification as Notification;
  const NAVIGATION_DATE = new Date(startDate as string).toISOString();

  const NAVIGATE_URL =
    currentProjectId === projectId
      ? `?level=Timeline&card=${notifiable?.type?.toLowerCase() as string}-${
          notifiable?.id as string
        }&scheduledAt=${NAVIGATION_DATE}`
      : `/projects/${projectId || ""}?level=Timeline&card=${
          notifiable?.type?.toLowerCase() as string
        }-${notifiable?.id as string}&scheduledAt=${NAVIGATION_DATE}`;

  const navigate = useNavigate();
  const [showUpdatedMeetingTitle, setShowUpdatedMeetingTitle] = useState(false);
  const [updatedMeetingNotification, setUpdatedMeetingNotification] = useState(
    heading || "",
  );

  useEffect(() => {
    if (
      notifiable?.type === MEETING_NOFITIABLE_TYPE &&
      heading?.match(MEETING_HEADING_REGEX)
    ) {
      const newNotificationTitle = String(updatedMeetingNotification)?.split(
        " at ",
      );
      const zonedMeetingDate = format(
        utcToZonedTime(new Date(startDate as string), getTimeZone()),
        "h:mmaaa MMM d, y",
      );
      newNotificationTitle[1] = zonedMeetingDate;
      setUpdatedMeetingNotification(newNotificationTitle.join(" at "));
      setShowUpdatedMeetingTitle(true);
    }
  }, []);

  return (
    <HStack
      space={2}
      className={clsx(
        !isLast && "border-b-2 border-neutral-10",
        "mb-2 cursor-pointer pb-2",
      )}
      data-testid="notification"
    >
      {isNotificationDeleted ? (
        <div
          className="flex h-14 w-full items-center justify-center rounded-md bg-neutral-5 py-2"
          onClick={() => setIsNotificationDeleted(!isNotificationDeleted)}
        >
          <Caption1 className="text-neutral-70">
            {t("shared.eventGotDeleted")}
          </Caption1>
        </div>
      ) : (
        <>
          <HStack
            onClick={() =>
              isDeleted
                ? setIsNotificationDeleted(true)
                : navigate(NAVIGATE_URL)
            }
          >
            {!isRead && (
              <div
                style={{ minWidth: "8px" }}
                className="h-auto -translate-x-4 rounded-xl bg-teal-400"
                data-testid="unread-notification"
              />
            )}
            <VStack space={1} className={clsx(!isRead && "-translate-x-2")}>
              <div>
                <Base2
                  className="ml-1 line-clamp-3"
                  data-testid="notification-heading"
                >
                  {notificationType(notifiable?.type?.toLowerCase() || "")}
                  {showUpdatedMeetingTitle ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: updatedMeetingNotification,
                      }}
                    />
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{ __html: heading as string }}
                    />
                  )}
                </Base2>
              </div>
              <Caption2 className="ml-1 text-neutral-70">
                {getTimeDifference(createdAt, new Date())}
              </Caption2>
            </VStack>
          </HStack>
        </>
      )}
    </HStack>
  );
}

export default function NotificationButton() {
  const [isNewNotification, setIsNewNotification] = useState<boolean>(false);
  const [notificationCount, setNotificationCount] = useState(1);
  const [areNotificationsVisible, setAreNotificationsVisible] = useState<
    boolean | undefined
  >();
  const [unreadNotificationIds, setUnreadNotificationIds] = useState<number[]>(
    [],
  );

  const { notifications, isError, isLoading } = useNotifications({
    count: notificationCount,
  });
  const { mutateAsync } = useUpdateNotification();

  const handleReadNotifications = async () => {
    await mutateAsync({
      ids: unreadNotificationIds,
    });
  };

  useEffect(() => {
    if (areNotificationsVisible === false) {
      void handleReadNotifications();
    }
  }, [areNotificationsVisible]);

  const [userNotifications, setUserNotifications] = useState<Notification[]>(
    notifications as unknown as Notification[],
  );

  useEffect(() => {
    for (const notification of userNotifications) {
      const notificationId = Number(notification?.id);
      if (
        !notification.isRead &&
        !unreadNotificationIds.includes(notificationId)
      ) {
        setUnreadNotificationIds((prev) => [...prev, notificationId]);
      }
    }
  }, [userNotifications]);

  const handleNotificationScroll = (event: React.UIEvent<HTMLElement>) => {
    const { clientHeight, scrollHeight, scrollTop } = event.currentTarget;
    const hasReachedEnd = scrollHeight - scrollTop <= clientHeight * 1.2;
    if (
      hasReachedEnd &&
      userNotifications?.length >= notificationCount * NOTIFICATION_OFFSET
    ) {
      setNotificationCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if ((notifications as unknown as Notification[])?.length > 0) {
      setUserNotifications(notifications as unknown as Notification[]);
    }
  }, [notifications]);

  useEffect(() => {
    const newNotifications = userNotifications?.filter(
      (notification) => notification.isRead === false,
    );
    newNotifications.length > 0
      ? setIsNewNotification(true)
      : setIsNewNotification(false);
  }, [userNotifications]);

  const renderNotificationContent = (notificationList: Notification[]) => {
    if (isLoading) {
      return (
        <div className="flex h-5/6 items-center justify-center">
          <Base2 className="text-neutral-70">
            {t("shared.loadingWithPeriod")}
          </Base2>
        </div>
      );
    } else if (isError) {
      return (
        <VStack className="h-5/6 text-center" space={2} justify="center">
          <Base2 className="text-neutral-70">{t("shared.oops")}</Base2>
          <Base2 className="text-neutral-70">
            {t("errors.unableToRetriveNotifications")}
          </Base2>
          <Base2 className="text-neutral-70">
            {t("shared.checkBackLater")}
          </Base2>
        </VStack>
      );
    } else if (notificationList?.length > 0) {
      return notificationList.map((item, index) => (
        <>
          <NotificationItem
            notification={item}
            key={item.notifiable?.id}
            isLast={index === notificationList?.length - 1}
          />
        </>
      ));
    } else if (notificationList.length === 0) {
      return (
        <div className="flex h-5/6 items-center justify-center">
          <Base2 className="text-neutral-70">
            {t("shared.noNotifications")}
          </Base2>
        </div>
      );
    }
  };

  return (
    <div>
      <Dropdown
        className="!block"
        button={
          <Menu.Button
            as="button"
            className=" mt-[0.5px] flex h-[41px] w-10 items-center justify-center rounded-full border border-tint-dark-30 hover:bg-tint-dark-10 focus:outline-none focus:ring focus:ring-primary-turquoise-10 focus:ring-offset-1"
            data-testid="notificationBell"
          >
            {isNewNotification ? (
              <BellRinging
                data-testid="ringing-bell"
                className="animate-buzzing-bell"
              />
            ) : (
              <Bell size={24} color={"#494b55"} data-testid="bell" />
            )}
          </Menu.Button>
        }
        items={
          <Menu.Items
            className={clsx(
              "fixed top-18 right-8 h-[354px] w-80 origin-bottom-right overflow-hidden overflow-y-scroll rounded-2xl border bg-white py-6 px-7 text-left shadow-createButton focus:outline-none",
              Z_INDEX_LEVELS.BASE_CONTROL,
            )}
            onScroll={handleNotificationScroll}
            data-testid="notification-list"
          >
            {({ open }) => {
              setAreNotificationsVisible(open);
              return (
                <>
                  <div className="flex border-neutral-50 pb-4">
                    <Heading3Strong className="text-black">
                      {t("shared.notifications")}
                    </Heading3Strong>
                  </div>
                  {renderNotificationContent(userNotifications)}
                </>
              );
            }}
          </Menu.Items>
        }
      />
    </div>
  );
}
