import React from "react";

function WorkshopMediumActiveIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_46_1606)">
        <path
          d="M39.7041 56.5649C46.6077 56.5649 52.2041 50.9685 52.2041 44.0649C52.2041 37.1614 46.6077 31.5649 39.7041 31.5649C32.8005 31.5649 27.2041 37.1614 27.2041 44.0649C27.2041 50.9685 32.8005 56.5649 39.7041 56.5649Z"
          fill="#F29E50"
        />
        <path
          d="M39.7038 22.9902C42.3631 22.9887 44.9396 22.0651 46.9943 20.3769C49.0491 18.6887 50.4549 16.3402 50.9723 13.7317C51.4896 11.1232 51.0866 8.41603 49.8318 6.07138C48.5769 3.72673 46.548 1.88969 44.0905 0.873256C41.6331 -0.143176 38.8993 -0.276116 36.3549 0.497085C33.8105 1.27029 31.6128 2.90179 30.1364 5.11363C28.66 7.32547 27.9962 9.98079 28.258 12.6272C28.5199 15.2736 29.6912 17.7473 31.5724 19.6269C32.6394 20.6955 33.9071 21.5427 35.3025 22.1199C36.6979 22.6971 38.1937 22.9929 39.7038 22.9902ZM44.3004 6.89892C45.3669 7.96259 46.0314 9.36322 46.1808 10.862C46.3301 12.3608 45.955 13.8651 45.1194 15.1182C44.2838 16.3714 43.0394 17.296 41.5984 17.7344C40.1574 18.1728 38.6089 18.0979 37.217 17.5224C35.8251 16.9468 34.6758 15.9064 33.9651 14.5783C33.2545 13.2503 33.0264 11.7168 33.3198 10.2395C33.6133 8.76209 34.41 7.4322 35.5742 6.47651C36.7384 5.52081 38.198 4.99847 39.7043 4.99853C41.4275 4.99853 43.0804 5.68195 44.3004 6.89892Z"
          fill="#35363E"
        />
        <path
          d="M19.6258 52.2251C18.0179 50.6187 15.9698 49.525 13.7404 49.0824C11.5111 48.6397 9.20054 48.868 7.10088 49.7383C5.00122 50.6085 3.20671 52.0818 1.94419 53.9718C0.681664 55.8617 0.0078125 58.0836 0.0078125 60.3565C0.0078125 62.6293 0.681664 64.8512 1.94419 66.7411C3.20671 68.6311 5.00122 70.1044 7.10088 70.9746C9.20054 71.8449 11.5111 72.0732 13.7404 71.6305C15.9698 71.1879 18.0179 70.0942 19.6258 68.4878C20.7027 67.4251 21.5578 66.1591 22.1414 64.7632C22.7251 63.3673 23.0256 61.8694 23.0256 60.3565C23.0256 58.8435 22.7251 57.3456 22.1414 55.9497C21.5578 54.5538 20.7027 53.2878 19.6258 52.2251ZM6.89774 64.9526C5.98867 64.0436 5.36956 62.8854 5.11871 61.6245C4.86786 60.3636 4.99654 59.0567 5.48847 57.8689C5.9804 56.6812 6.81349 55.6659 7.88239 54.9517C8.95129 54.2374 10.208 53.8561 11.4936 53.856C12.7792 53.8559 14.0359 54.2371 15.1049 54.9513C16.1739 55.6655 17.0071 56.6806 17.4992 57.8683C17.9912 59.056 18.12 60.3629 17.8693 61.6238C17.6186 62.8848 16.9996 64.043 16.0906 64.9521C14.8707 66.1696 13.2177 66.8533 11.4943 66.8534C9.77084 66.8535 8.11775 66.1699 6.89774 64.9526Z"
          fill="#35363E"
        />
        <path
          d="M76.0458 52.2251C74.4379 50.6184 72.3897 49.5245 70.1602 49.0816C67.9307 48.6387 65.6199 48.8668 63.52 49.7371C61.4201 50.6073 59.6253 52.0806 58.3626 53.9706C57.0999 55.8607 56.4259 58.0828 56.4258 60.3558C56.4257 62.6289 57.0996 64.851 58.3622 66.7412C59.6248 68.6313 61.4195 70.1047 63.5193 70.9751C65.6192 71.8454 67.9299 72.0737 70.1595 71.6309C72.389 71.1882 74.4373 70.0944 76.0453 68.4878C77.1221 67.4251 77.9771 66.159 78.5607 64.7632C79.1443 63.3673 79.4448 61.8695 79.4449 60.3566C79.4449 58.8436 79.1445 57.3458 78.561 55.9499C77.9775 54.554 77.1225 53.2879 76.0458 52.2251ZM63.3173 64.9526C62.7129 64.3492 62.2333 63.6326 61.906 62.8438C61.5787 62.055 61.41 61.2094 61.4096 60.3553C61.4093 59.5013 61.5772 58.6555 61.9039 57.8664C62.2306 57.0773 62.7096 56.3603 63.3135 55.7564C63.9174 55.1525 64.6344 54.6736 65.4235 54.3469C66.2126 54.0202 67.0583 53.8523 67.9123 53.8526C68.7664 53.853 69.612 54.0216 70.4008 54.349C71.1897 54.6763 71.9063 55.1559 72.5097 55.7603H72.5101C73.7273 56.9797 74.4106 58.6325 74.4098 60.3554C74.409 62.0784 73.7242 63.7305 72.5058 64.9488C71.2875 66.1671 69.6353 66.8519 67.9123 66.8526C66.1894 66.8533 64.5366 66.1699 63.3173 64.9526Z"
          fill="#35363E"
        />
        <path
          d="M54.7374 18.0226C59.3021 20.67 63.0931 24.4678 65.7322 29.0372C68.3713 33.6067 69.7664 38.7882 69.7784 44.0651C69.7784 44.7281 70.0418 45.364 70.5107 45.8328C70.9795 46.3017 71.6154 46.5651 72.2784 46.5651C72.9415 46.5651 73.5773 46.3017 74.0462 45.8328C74.515 45.364 74.7784 44.7281 74.7784 44.0651C74.7649 37.9116 73.1385 31.8691 70.0614 26.5402C66.9843 21.2113 62.564 16.7822 57.2413 13.6945C56.6674 13.3624 55.985 13.272 55.3444 13.443C54.7038 13.6141 54.1573 14.0326 53.8253 14.6066C53.4933 15.1805 53.4028 15.8629 53.5739 16.5035C53.7449 17.1441 54.1635 17.6905 54.7374 18.0226Z"
          fill="#35363E"
        />
        <path
          d="M7.12988 46.5651C7.45819 46.5651 7.7833 46.5004 8.08662 46.3748C8.38995 46.2492 8.66555 46.065 8.89771 45.8329C9.12986 45.6007 9.314 45.3251 9.43963 45.0218C9.56526 44.7185 9.62991 44.3934 9.62988 44.0651C9.642 38.7883 11.0371 33.6069 13.6761 29.0375C16.3152 24.468 20.1059 20.6702 24.6704 18.0226C24.9546 17.8582 25.2036 17.6394 25.4032 17.3787C25.6029 17.1181 25.7492 16.8207 25.8339 16.5035C25.9186 16.1863 25.94 15.8555 25.8969 15.53C25.8537 15.2045 25.7469 14.8907 25.5825 14.6066C25.4181 14.3224 25.1993 14.0733 24.9387 13.8737C24.678 13.6741 24.3806 13.5277 24.0634 13.443C23.7462 13.3583 23.4154 13.3369 23.0899 13.3801C22.7645 13.4232 22.4507 13.53 22.1665 13.6944C16.844 16.7824 12.4239 21.2116 9.34689 26.5405C6.2699 31.8693 4.64352 37.9117 4.62988 44.0651C4.62986 44.3934 4.69451 44.7185 4.82013 45.0218C4.94576 45.3251 5.12991 45.6007 5.36206 45.8329C5.59421 46.065 5.86982 46.2492 6.17314 46.3748C6.47647 46.5004 6.80157 46.5651 7.12988 46.5651Z"
          fill="#35363E"
        />
        <path
          d="M54.7375 70.1074C50.1675 72.7483 44.9826 74.1387 39.7045 74.1387C34.4264 74.1387 29.2415 72.7483 24.6715 70.1074C24.3873 69.9427 24.0735 69.8357 23.7479 69.7924C23.4223 69.749 23.0913 69.7703 22.774 69.8549C22.4566 69.9395 22.159 70.0859 21.8982 70.2855C21.6374 70.4852 21.4185 70.7344 21.254 71.0187C21.0895 71.303 20.9827 71.6169 20.9396 71.9425C20.8965 72.2682 20.918 72.5991 21.0028 72.9164C21.0877 73.2337 21.2342 73.5312 21.4341 73.7919C21.6339 74.0525 21.8832 74.2713 22.1676 74.4355C27.4986 77.5165 33.5472 79.1387 39.7045 79.1387C45.8618 79.1387 51.9104 77.5165 57.2414 74.4355C57.8142 74.1029 58.2316 73.5566 58.402 72.9165C58.5724 72.2764 58.4818 71.5949 58.1501 71.0215C57.8184 70.4482 57.2727 70.0299 56.6329 69.8585C55.993 69.6871 55.3113 69.7766 54.7375 70.1074Z"
          fill="#35363E"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_1606">
          <rect width="79.409" height="79.1385" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default WorkshopMediumActiveIcon;
