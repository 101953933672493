import clsx from "clsx";
import { Button, Input } from "components/base";
import { HStack, VStack } from "components/layout/Stack";
import {
  Base1Strong,
  Caption1,
  Heading1Strong,
  Paragraph3,
} from "components/Typography";
import { useGetEnvsContext, useProjectNavigator } from "contexts";
import { isPast, isSameWeek, parseISO } from "date-fns";
import {
  Announcement,
  Project,
  ProjectStatus,
  UpdateQuestionResponseInput,
  useCreateQuestionResponseMutation,
  User,
} from "graphql/generated";
import { t } from "i18n-js";
import RecoderIcon from "icons/RecoderLogo";
import { noop, uniqBy } from "lodash";
import useUpdateQuestionResponse from "mutations/use-update-question-response";
// import useCreateTemperatureCheck from "mutations/use-create-temperature-response";
import { Heartbeat } from "phosphor-react";
import { useQuestionResponseForUser, useQuestions } from "queries";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import { WeekFeedTemperatureCheck } from "./WeekFeed";

export type AnnouncementType = Partial<
  Omit<Announcement, "author"> & { author: Partial<User> }
>;
interface TemperatureCheckFormInput {
  note?: string;
}

type TemperatureInfoProps = {
  temperatureInfo: WeekFeedTemperatureCheck;
  testPropIsResponseSubmitted?: boolean;
  project?: Project;
};

type AnswerInputType = {
  projectId: string;
  answerInput?: {
    id?: string;
    questionId: string;
    answerChoiceId: string;
    note: string | undefined;
    weekDate: string;
    phaseId: string;
  }[];
};

export default function TemperatureCard({
  temperatureInfo,
  testPropIsResponseSubmitted,
  project,
}: TemperatureInfoProps) {
  const queryClient = useQueryClient();

  const [pulseCheckChoiceId, setPulseCheckChoiceId] = useState("");
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [iteratingQuestion, setIteratingQuestion] = useState(0);
  const [isResponseSubmitted, setIsResponseSubmitted] = useState(
    testPropIsResponseSubmitted || false,
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const { projectId = "" } = useParams();
  const { currentPhase } = useProjectNavigator();
  const { currentUser } = useGetEnvsContext();
  const isProjectOver = isPast(parseISO(project?.endDate as string));
  const isWeekActive = isSameWeek(
    new Date(temperatureInfo?.weekDate),
    new Date(),
  );
  const [answersInput, setAnswersInput] = useState<AnswerInputType>({
    projectId: projectId,
  });

  const { register, getValues, setValue } = useForm<TemperatureCheckFormInput>({
    mode: "onTouched",
  });
  const { mutateAsync: submitQuestionResponse } =
    useCreateQuestionResponseMutation();

  const { mutateAsync: updateQuestionResponse } = useUpdateQuestionResponse();

  const { questions } = useQuestions({});

  const { questionResponseForUser } = useQuestionResponseForUser({
    projectId: projectId,
    weekEndDate: temperatureInfo?.weekDate,
  });

  // ------- CURRENT USER'S PREVIOUSLY SUBMITTED QUESTION RESPONSE
  const currentUsersLastQuestionResponse = questionResponseForUser
    ?.filter(
      (response) =>
        response?.question?.id === questions[iteratingQuestion]?.id &&
        response?.author?.id === currentUser?.id,
    )
    ?.slice(-1)[0];

  useEffect(() => {
    if (currentUsersLastQuestionResponse) {
      const answerChoice = answersInput?.answerInput?.find(
        (el) => el.questionId === questions[iteratingQuestion]?.id,
      );
      setPulseCheckChoiceId(
        answerChoice?.answerChoiceId ||
          currentUsersLastQuestionResponse?.answerChoice?.id,
      );
      setValue(
        "note",
        answerChoice?.note || currentUsersLastQuestionResponse?.note,
      );
    }
  }, [currentUsersLastQuestionResponse, isEditMode]);

  // ------- CURRENT USER'S PREVIOUSLY SUBMITTED RESPONSES
  const currentUsersSubmittedQuestionResponses = uniqBy(
    questionResponseForUser,
    "question.id",
  )?.filter((resp) => resp?.author?.id === currentUser?.id);

  const hasCurrentUserSubmittedAllQuestionResponses =
    currentUsersSubmittedQuestionResponses?.length === questions?.length;

  useEffect(() => {
    setTotalQuestions(questions?.length);
  }, [questions]);

  useEffect(() => {
    hasCurrentUserSubmittedAllQuestionResponses === true && !isEditMode
      ? setIsResponseSubmitted(true)
      : setIsResponseSubmitted(false);
    testPropIsResponseSubmitted &&
      setIsResponseSubmitted(testPropIsResponseSubmitted);
  }, [questionResponseForUser, questions]);

  useEffect(() => {
    if (!currentUsersLastQuestionResponse?.id) {
      setPulseCheckChoiceId("");
      setValue("note", "");
    }
  }, [currentUsersLastQuestionResponse]);

  const headingText = () =>
    isResponseSubmitted
      ? t("component.temperatureCard.submittedHeading")
      : questions[iteratingQuestion]?.title;

  const updateAnswersInputHandler = () => {
    const questionData = {
      questionId: questions[iteratingQuestion]?.id,
      answerChoiceId: pulseCheckChoiceId,
      note: getValues("note"),
      weekDate: temperatureInfo?.weekDate,
      phaseId: currentPhase?.id || "",
    };
    let finalAnswers;
    setAnswersInput((prev: AnswerInputType) => {
      const finalAnswerInputArr = prev?.answerInput?.length
        ? [...prev?.answerInput]
        : [];

      const quesIndex = finalAnswerInputArr?.findIndex(
        (el) => el.questionId === questions[iteratingQuestion]?.id,
      );
      if (quesIndex > -1) {
        finalAnswerInputArr[quesIndex] = {
          ...finalAnswerInputArr[quesIndex],
          ...questionData,
        };
        delete finalAnswerInputArr[quesIndex].id; // Remove the id field
      } else {
        finalAnswerInputArr.push(questionData);
      }

      finalAnswers = {
        ...prev,
        projectId: projectId,
        answerInput: finalAnswerInputArr,
      };

      return finalAnswers;
    });
    return finalAnswers;
  };

  const prevButtonPressHandler = () => {
    const questionInAnswersInput = answersInput?.answerInput?.find(
      (el) => el?.questionId === questions[iteratingQuestion - 1]?.id,
    );

    if (questionInAnswersInput?.answerChoiceId) {
      setPulseCheckChoiceId(questionInAnswersInput?.answerChoiceId);
    }
    if (questionInAnswersInput?.note) {
      setValue("note", questionInAnswersInput.note);
    }
    setIteratingQuestion((prev) => prev - 1);
  };

  const nextButtonPressHandler = () => {
    setTimeout(() => {
      const updatedAnswers = updateAnswersInputHandler();

      if (iteratingQuestion + 1 === totalQuestions)
        void submitAnswers(updatedAnswers);
      else if (iteratingQuestion + 1 < totalQuestions) gotoNextQuestion();

      const questionInAnswersInput = answersInput?.answerInput?.find(
        (el) =>
          questions[iteratingQuestion + 1]?.id &&
          el?.questionId === questions[iteratingQuestion + 1]?.id,
      );

      if (!questionInAnswersInput) {
        setPulseCheckChoiceId("");
        setValue("note", "");
      } else {
        if (questionInAnswersInput?.answerChoiceId) {
          setPulseCheckChoiceId(questionInAnswersInput?.answerChoiceId);
        }
        if (questionInAnswersInput?.note) {
          setValue("note", questionInAnswersInput.note);
        }
      }
    }, 300);
  };

  const gotoNextQuestion = () => {
    setIteratingQuestion((prev) => prev + 1);
  };

  const submitAnswers = async (answers = answersInput) => {
    try {
      if (!hasCurrentUserSubmittedAllQuestionResponses) {
        await submitQuestionResponse({ questionResponse: answers });
      } else {
        const answersToSubmit = generateUpdatedAnswersPayload(answers);
        await updateQuestionResponse({
          input: answersToSubmit as UpdateQuestionResponseInput[],
        });
      }
      setIsResponseSubmitted(true);
      await queryClient.refetchQueries(["QuestionResponseForUser"]);
      void handleEditPulseCheckResponse(false);
    } catch {
      console.error(t("errors.somethingWentWrong"));
    }
    setIsResponseSubmitted(true);
  };

  const generateUpdatedAnswersPayload = (answers = answersInput) => {
    return answers?.answerInput?.map((el) => ({
      answerChoiceId: el?.answerChoiceId,
      note: el?.note,
      id: currentUsersSubmittedQuestionResponses?.find(
        (u) => u.question?.id === el?.questionId,
      )?.id,
    }));
  };

  const handleEditPulseCheckResponse = (edit: boolean) => {
    if (edit) {
      setIteratingQuestion(0);
      setIsEditMode(true);
      setIsResponseSubmitted(false);
    } else {
      setIteratingQuestion(0);
      setIsEditMode(false);
    }
  };

  const questionTracker = () => {
    const indicatorWidth = (110 / totalQuestions) * (iteratingQuestion + 1);

    return (
      <VStack space={2}>
        <Caption1 className="text-neutral-900">
          {t("component.temperatureCard.currentQuestion", {
            currentQuestion: iteratingQuestion + 1,
            totalQuestions,
          })}
        </Caption1>
        <div
          className="relative h-[6px] w-[110px] overflow-hidden rounded-lg bg-white"
          data-testid="question-tracker"
        >
          <span
            className={clsx(
              "absolute top-0 left-0 h-full rounded-lg bg-secondary-green-70",
            )}
            style={{ width: `${indicatorWidth}px` }}
          />
        </div>
      </VStack>
    );
  };

  return (
    <div
      id={`temperature-check-card`}
      className={clsx("mb-4", Z_INDEX_LEVELS.BASE)}
    >
      <div className="mb-4 flex h-6">
        <div
          className={clsx(
            "items-center justify-center rounded-full border-[0.5px] border-tint-dark-15 bg-tint-dark-15",
          )}
        >
          <RecoderIcon fill="white" />
        </div>
        <Caption1 className="mx-2 self-center text-neutral-70">
          {t("component.temperatureCard.caption")}
        </Caption1>
      </div>
      <div
        className={clsx(
          "temp-check-gradient-bg flex w-[680px] flex-col rounded-2xl p-6 shadow-feedCard",
        )}
      >
        <VStack className="justify-between">
          <HStack className="justify-between">
            <VStack space={4} className="ml-2 mt-2 mb-8 w-[494px]">
              <VStack space={1} className="mb-2">
                <Base1Strong className="text-neutral-70">
                  {t("component.temperatureCard.title")}
                </Base1Strong>
                {!isResponseSubmitted && (
                  <Paragraph3 className="text-neutral-70">
                    {t("component.temperatureCard.subtext")}
                  </Paragraph3>
                )}
              </VStack>
              <Heading1Strong className="text-neutral-90">
                {headingText()}
              </Heading1Strong>
            </VStack>
            <div className="flex h-18 w-18 items-center justify-center rounded-full bg-white">
              <Heartbeat
                size={40}
                className={clsx(
                  "text-secondary-red-70",
                  Z_INDEX_LEVELS.BASE_CONTROL,
                )}
              />
            </div>
          </HStack>
          {isResponseSubmitted ? (
            <HStack justify="between" align="center" className="px-2">
              <Caption1 className="w-8/12 text-neutral-70">
                {t("component.temperatureCard.submittedInfoMessage")}
              </Caption1>
              <Button
                variant="outline"
                size="small"
                onClick={
                  isWeekActive ? () => handleEditPulseCheckResponse(true) : noop
                }
                disabled={!isWeekActive}
              >
                {t("shared.edit")}
              </Button>
            </HStack>
          ) : (
            <VStack space={3}>
              <HStack className={`justify-between`}>
                {questions[iteratingQuestion]?.answerChoices?.map((answer) => (
                  <Button
                    key={answer?.id}
                    data-testid="answer-choice"
                    variant="outline"
                    className={clsx(
                      "w-28 space-x-4",
                      (answer.id === pulseCheckChoiceId ||
                        (!pulseCheckChoiceId &&
                          answersInput?.answerInput?.find(
                            (el) =>
                              el?.questionId ===
                              questions[iteratingQuestion].id,
                          )?.answerChoiceId === answer?.id)) &&
                        "bg-tint-dark-30",
                    )}
                    onClick={() => setPulseCheckChoiceId(answer.id)}
                  >
                    {answer?.text}
                  </Button>
                ))}
              </HStack>
              <HStack justify="between" className="px-2">
                <Caption1 className="text-neutral-900">
                  {t("component.temperatureCard.stronglyDisagree")}
                </Caption1>
                <Caption1 className="text-neutral-900">
                  {t("component.temperatureCard.stronglyAgree")}
                </Caption1>
              </HStack>
              {!!pulseCheckChoiceId && (
                <VStack className="!mt-6 flex-grow">
                  <Input
                    multiline
                    placeholder={t("component.temperatureCard.placeholder")}
                    expandable={true}
                    showCounter
                    maxLength={280}
                    longCountDescription
                    className={"mb-2 border-tint-light-80 bg-tint-light-80"}
                    rows={3}
                    data-testid="note-input"
                    {...register("note")}
                  />
                </VStack>
              )}
              <HStack justify="between" align="center" className="!mt-8 px-2">
                {questionTracker()}
                <HStack space={3}>
                  {iteratingQuestion + 1 > 1 && !isResponseSubmitted ? (
                    <Button
                      size="small"
                      variant="outline"
                      onClick={prevButtonPressHandler}
                    >
                      {t("shared.previous")}
                    </Button>
                  ) : undefined}
                  <Button
                    size="small"
                    onClick={() => void nextButtonPressHandler()}
                    disabled={
                      !pulseCheckChoiceId ||
                      project?.status === ProjectStatus.Archived ||
                      project?.status === ProjectStatus.Unarchived ||
                      isProjectOver
                    }
                  >
                    {t("shared.next")}
                  </Button>
                </HStack>
              </HStack>
            </VStack>
          )}
        </VStack>
      </div>
    </div>
  );
}
