import React from "react";

function ListNumbersIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="-5 -5 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 12C9 11.5858 9.33579 11.25 9.75 11.25H20.2499C20.6641 11.25 20.9999 11.5858 20.9999 12C20.9999 12.4142 20.6641 12.75 20.2499 12.75H9.75C9.33579 12.75 9 12.4142 9 12Z"
        fill="#292D30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 6C9 5.58579 9.33579 5.25 9.75 5.25H20.2499C20.6641 5.25 20.9999 5.58579 20.9999 6C20.9999 6.41421 20.6641 6.75 20.2499 6.75H9.75C9.33579 6.75 9 6.41421 9 6Z"
        fill="#292D30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 18C9 17.5858 9.33579 17.25 9.75 17.25H20.25C20.6642 17.25 21 17.5858 21 18C21 18.4142 20.6642 18.75 20.25 18.75H9.75C9.33579 18.75 9 18.4142 9 18Z"
        fill="#292D30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.64446 4.23701C5.86558 4.37367 6.00016 4.61507 6.00016 4.875V10.1244C6.00016 10.5387 5.66438 10.8744 5.25016 10.8744C4.83595 10.8744 4.50016 10.5387 4.50016 10.1244V6.08853L4.08557 6.29582C3.71509 6.48106 3.26459 6.33089 3.07934 5.96041C2.8941 5.58993 3.04427 5.13942 3.41475 4.95418L4.91475 4.20418C5.14725 4.08793 5.42335 4.10036 5.64446 4.23701Z"
        fill="#292D30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.10705 14.2518C5.02883 14.2455 4.95017 14.2557 4.87611 14.2817C4.80206 14.3077 4.73426 14.3488 4.67706 14.4025C4.61986 14.4563 4.57453 14.5214 4.54398 14.5936C4.38272 14.9752 3.94271 15.1538 3.56117 14.9925C3.17963 14.8312 3.00106 14.3912 3.16231 14.0097C3.2743 13.7447 3.44046 13.5061 3.65013 13.3092C3.85981 13.1123 4.10835 12.9614 4.3798 12.8662C4.65125 12.771 4.93961 12.7337 5.22635 12.7565C5.51309 12.7794 5.79188 12.862 6.04481 12.999C6.29774 13.136 6.51921 13.3244 6.695 13.5521C6.87079 13.7798 6.99702 14.0418 7.06557 14.3211C7.13413 14.6005 7.14349 14.8911 7.09307 15.1743C7.04265 15.4575 6.93354 15.727 6.77278 15.9656C6.76578 15.9759 6.75853 15.9861 6.75102 15.9962L5.24929 18.0001H6.375C6.78922 18.0001 7.125 18.3358 7.125 18.7501C7.125 19.1643 6.78922 19.5001 6.375 19.5001H3.75C3.46598 19.5001 3.20632 19.3396 3.07925 19.0856C2.95218 18.8316 2.9795 18.5276 3.14983 18.3003L5.53706 15.1148C5.57647 15.0528 5.60341 14.9837 5.6163 14.9114C5.63005 14.8341 5.6275 14.7548 5.6088 14.6786C5.59009 14.6024 5.55566 14.5309 5.5077 14.4688C5.45974 14.4067 5.39933 14.3553 5.33033 14.3179C5.26133 14.2806 5.18528 14.258 5.10705 14.2518Z"
        fill="#292D30"
      />
    </svg>
  );
}

export default ListNumbersIcon;
