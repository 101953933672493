import React from "react";

function UnorderedListActiveIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#F2F4F4" />
      <path
        d="M12.25 10H24.25"
        stroke="#00AE8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.251 16H24.2504"
        stroke="#00AE8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.251 22H24.2504"
        stroke="#00AE8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1875 10C9.1875 10.5868 8.7118 11.0625 8.125 11.0625C7.5382 11.0625 7.0625 10.5868 7.0625 10C7.0625 9.4132 7.5382 8.9375 8.125 8.9375C8.7118 8.9375 9.1875 9.4132 9.1875 10Z"
        fill="#00AE8F"
        stroke="#00AE8F"
        strokeWidth="0.125"
      />
      <path
        d="M8.125 17.125C8.74632 17.125 9.25 16.6213 9.25 16C9.25 15.3787 8.74632 14.875 8.125 14.875C7.50368 14.875 7 15.3787 7 16C7 16.6213 7.50368 17.125 8.125 17.125Z"
        fill="#00AE8F"
      />
      <path
        d="M8.125 23.125C8.74632 23.125 9.25 22.6213 9.25 22C9.25 21.3787 8.74632 20.875 8.125 20.875C7.50368 20.875 7 21.3787 7 22C7 22.6213 7.50368 23.125 8.125 23.125Z"
        fill="#00AE8F"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#00AE8F" />
    </svg>
  );
}

export default UnorderedListActiveIcon;
