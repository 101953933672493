import {
  OrganizationMembersByProjectQuery,
  OrganizationMembersByProjectQueryVariables,
  useOrganizationMembersByProjectQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query OrganizationMembersByProject(
    $projectId: ID!
    $input: OrganizationMembersByProjectFiltersInput
  ) {
    organizationMembersByProject(projectId: $projectId, input: $input) {
      id
      firstName
      lastName
      fullName
      email
      defaultProjectRole
      pronouns
      avatarUrl
    }
  }
`;

function useOrganizationMembers(
  variables: OrganizationMembersByProjectQueryVariables,
): {
  members: OrganizationMembersByProjectQuery["organizationMembersByProject"];
} & UseQueryResult<OrganizationMembersByProjectQuery, unknown> {
  const response = useOrganizationMembersByProjectQuery(variables, {
    suspense: true,
  });

  return {
    members: response.data?.organizationMembersByProject || [],
    ...response,
  };
}

export { useOrganizationMembers };
