import clsx from "clsx";
import { Caption2 } from "components/Typography";
import React from "react";

export enum DiSCQuadrantsEnum {
  D = "D",
  I = "i",
  S = "S",
  C = "C",
}

export interface DiscQuadrantProps {
  quadrant: DiSCQuadrantsEnum;
  hasBorders?: boolean;
}

export const DiscQuadrant = ({
  quadrant,
  hasBorders = true,
}: DiscQuadrantProps) => (
  <div
    className={clsx(
      "relative h-[58px] w-[58px] overflow-hidden rounded-full bg-white",
      {
        "border border-tint-dark-200": hasBorders,
      },
    )}
    aria-label={`DiSC Profile: ${quadrant}`}
  >
    {Object.values(DiSCQuadrantsEnum).map((q) => (
      <div
        key={q}
        className={clsx(
          "absolute flex h-1/2 w-1/2 px-2 py-1",
          {
            "border-[0.5px] border-tint-dark-200": hasBorders,
          },
          {
            "top-0 left-0 items-end justify-end": q === DiSCQuadrantsEnum.D,
            "top-0 right-0 items-end": q === DiSCQuadrantsEnum.I,
            "bottom-0 right-0": q === DiSCQuadrantsEnum.S,
            "bottom-0 left-0 justify-end": q === DiSCQuadrantsEnum.C,
          },
          {
            "rounded-sm bg-[#0FB356]":
              q === DiSCQuadrantsEnum.D && q === quadrant,
            "rounded-sm bg-[#E34B4B]":
              q === DiSCQuadrantsEnum.I && q === quadrant,
            "rounded-sm bg-[#2C8BC0]":
              q === DiSCQuadrantsEnum.S && q === quadrant,
            "rounded-sm bg-[#EB8424]":
              q === DiSCQuadrantsEnum.C && q === quadrant,
          },
        )}
      >
        {quadrant === q && (
          <Caption2 className="text-white">{quadrant}</Caption2>
        )}
      </div>
    ))}
  </div>
);
