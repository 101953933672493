import React from "react";

function CreateTaskIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.90909 1.90914C2.90909 2.71246 2.25787 3.36368 1.45455 3.36368C0.651222 3.36368 0 2.71246 0 1.90914C0 1.10581 0.651222 0.45459 1.45455 0.45459C2.25787 0.45459 2.90909 1.10581 2.90909 1.90914Z"
        fill="#A7A8B0"
      />
      <path
        d="M2.90909 7.00004C2.90909 7.80337 2.25787 8.45459 1.45455 8.45459C0.651222 8.45459 0 7.80337 0 7.00004C0 6.19672 0.651222 5.5455 1.45455 5.5455C2.25787 5.5455 2.90909 6.19672 2.90909 7.00004Z"
        fill="#A7A8B0"
      />
      <path
        d="M2.90909 12.091C2.90909 12.8943 2.25787 13.5455 1.45455 13.5455C0.651222 13.5455 0 12.8943 0 12.091C0 11.2876 0.651222 10.6364 1.45455 10.6364C2.25787 10.6364 2.90909 11.2876 2.90909 12.091Z"
        fill="#A7A8B0"
      />
      <path
        d="M8 1.90914C8 2.71246 7.34878 3.36368 6.54545 3.36368C5.74213 3.36368 5.09091 2.71246 5.09091 1.90914C5.09091 1.10581 5.74213 0.45459 6.54545 0.45459C7.34878 0.45459 8 1.10581 8 1.90914Z"
        fill="#A7A8B0"
      />
      <path
        d="M8 7.00004C8 7.80337 7.34878 8.45459 6.54545 8.45459C5.74213 8.45459 5.09091 7.80337 5.09091 7.00004C5.09091 6.19672 5.74213 5.5455 6.54545 5.5455C7.34878 5.5455 8 6.19672 8 7.00004Z"
        fill="#A7A8B0"
      />
      <path
        d="M8 12.091C8 12.8943 7.34878 13.5455 6.54545 13.5455C5.74213 13.5455 5.09091 12.8943 5.09091 12.091C5.09091 11.2876 5.74213 10.6364 6.54545 10.6364C7.34878 10.6364 8 11.2876 8 12.091Z"
        fill="#A7A8B0"
      />
    </svg>
  );
}

export default CreateTaskIcon;
