import { useUpdateOrganizationMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      name
    }
  }
`;

function useUpdateOrganization() {
  const queryClient = useQueryClient();
  return useUpdateOrganizationMutation({
    onSuccess: () => {
      void queryClient.refetchQueries(["OrganizationById"]);
      void queryClient.refetchQueries(["UserOrganizations"]);
      void queryClient.refetchQueries(["UserProjects"]);
      void queryClient.refetchQueries(["ProjectById"]);
    },
  });
}

export default useUpdateOrganization;
