import clsx from "clsx";
import { CheckboxWithLabel, RadioWithLabel } from "components/base";
import { Caption1 } from "components/Typography";
import { PollPollType } from "graphql/generated";
import { t } from "i18n-js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { EmptyResponsesChip } from "../PostSessionActivity/components";
import { PollAnswerBarComponent } from "./PollAnswerBar.types";
import PollTooltip from "./PollTooltip";

const BACKGROUND_CLASSES = [
  "bg-primary-turquoise-30",
  "bg-secondary-indigo-10",
  "bg-secondary-green-30",
  "bg-secondary-orange-50",
  "bg-secondary-red-50",
  "bg-secondary-yellow-30",
  "bg-tertiary-blue-10",
  "bg-tertiary-brick-10",
  "bg-tertiary-casal-10",
  "bg-tertiary-purple-10",
];

/**
 * Ensures each bar background color is unique to each bar.
 * @param quantity the total number of poll questions
 * @returns array of unique numbers within the length of BACKGROUND_CLASSES
 */
const randomColorIndexGenerator = (quantity: number) => {
  const colorIndex: number[] = [];

  while (colorIndex.length < quantity) {
    const randomNum = Math.floor(Math.random() * BACKGROUND_CLASSES.length);

    if (quantity > BACKGROUND_CLASSES.length || !colorIndex.includes(randomNum))
      colorIndex.push(randomNum);
  }
  return colorIndex;
};

const PollAnswerBar: PollAnswerBarComponent = ({
  pollOptions,
  pollType,
  showResultAttributes,
  shouldShowControls = true,
  setPollAnswer,
  participantAnswers = [],
  anonymous,
}) => {
  const [bgColorValues, setBgColorValues] = useState<number[]>([]);
  const [showTooltip, setShowTooltip] = useState<string | undefined>(undefined);

  const totalNumberOfVoters =
    pollOptions?.reduce(
      (acc, curr) => acc + (curr.participants?.length || 0),
      0,
    ) || 0;

  const uniqueColorBackground = randomColorIndexGenerator(
    pollOptions?.length || 0,
  );

  useEffect(() => setBgColorValues(uniqueColorBackground), [pollOptions]);

  const participantAnswerIds = () => {
    return pollOptions
      ?.map((option) =>
        participantAnswers[0]?.answers?.includes(option.title as string)
          ? option.id
          : undefined,
      )
      .filter((opt) => opt);
  };

  const defaultPollAnswers =
    pollOptions && pollOptions.length > 0 ? participantAnswerIds() : "";

  const { register, watch } = useForm({
    defaultValues: {
      multiChoicePollOption: defaultPollAnswers,
      singleChoicePollOption: defaultPollAnswers && defaultPollAnswers[0],
    },
  });

  const multiChoicePollOption = watch("multiChoicePollOption");
  const singleChoicePollOption = watch("singleChoicePollOption");

  const singleChoiceOptionDetials = () =>
    pollOptions &&
    pollOptions.find((option) => option.id === singleChoicePollOption);

  const multiChoiceOptionDetials = () => {
    return pollOptions
      ?.filter((option) => multiChoicePollOption?.includes(option.id))
      .map((option) => option.title);
  };

  useEffect(() => {
    const optionDetails =
      pollType === PollPollType.SingleChoice
        ? [singleChoiceOptionDetials()?.title]
        : multiChoiceOptionDetials();

    setPollAnswer(optionDetails as string[]);
  }, [singleChoicePollOption, multiChoicePollOption]);

  if (!pollOptions || pollOptions.length === 0) {
    return <EmptyResponsesChip className="w-full" />;
  }

  return (
    <form>
      {pollOptions.map((answer, i) => {
        const voteCountPercentage =
          totalNumberOfVoters === 0
            ? 0
            : ((answer.participants?.length || 0) / totalNumberOfVoters) * 100;

        let bgColor = "";

        if (showResultAttributes) {
          bgColor =
            (answer.participants?.length || 0) === 0
              ? "bg-[#F2F3F4]"
              : BACKGROUND_CLASSES[bgColorValues[i]];
        }

        return (
          <div
            key={answer.id}
            className={clsx("relative mb-6 h-10 w-full rounded-lg", {
              "bg-neutral-5": showResultAttributes,
            })}
            onMouseEnter={
              anonymous ? undefined : () => setShowTooltip(answer.id)
            }
            onMouseLeave={
              anonymous ? undefined : () => setShowTooltip(undefined)
            }
          >
            <div
              className={clsx(`h-full rounded-lg ${bgColor}`)}
              // Number of votes/question divided by number of total voters
              style={{
                width: `${voteCountPercentage}%`,
              }}
            />
            {pollType === PollPollType.SingleChoice ? (
              <RadioWithLabel
                optionLabel={answer.title as string}
                optionId={answer.id}
                shouldShowControl={shouldShowControls}
                isDisabled={showResultAttributes}
                register={register}
              />
            ) : (
              <CheckboxWithLabel
                optionLabel={answer.title as string}
                optionId={answer.id}
                shouldShowControl={shouldShowControls}
                isDisabled={showResultAttributes}
                register={register}
              />
            )}
            {showResultAttributes && (
              <Caption1 className="absolute top-3 right-2">
                {answer.answersCount} {t("pollActivity.votes")}
              </Caption1>
            )}
            {!anonymous &&
              answer.participants &&
              answer.participants?.length > 0 &&
              showResultAttributes && (
                <PollTooltip
                  participants={answer.participants}
                  show={showTooltip === answer.id}
                />
              )}
          </div>
        );
      })}
    </form>
  );
};

export default PollAnswerBar;
