import {
  ProjectTeamCollabMetricsQuery,
  ProjectTeamCollabMetricsQueryVariables,
  useProjectTeamCollabMetricsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query ProjectTeamCollabMetrics($projectId: ID!) {
    teamCollabMetrics(projectId: $projectId) {
      genuine {
        userValue
        teamValue
        highest
        lowest
      }
      affiliation {
        userValue
        teamValue
        highest
        lowest
      }
      empathetic {
        teamValue
        userValue
        highest
        lowest
      }
      openToChange {
        userValue
        teamValue
        highest
        lowest
      }
    }
  }
`;

function useProjectTeamCollabMetrics(
  variables: ProjectTeamCollabMetricsQueryVariables,
): {
  teamCollabMetrics: ProjectTeamCollabMetricsQuery["teamCollabMetrics"];
} & UseQueryResult<ProjectTeamCollabMetricsQuery, unknown> {
  const response = useProjectTeamCollabMetricsQuery(variables, {
    suspense: true,
  });

  return {
    teamCollabMetrics: response.data?.teamCollabMetrics || {},
    ...response,
  };
}

export { useProjectTeamCollabMetrics };
