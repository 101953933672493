import { Transition } from "@headlessui/react";
import { Avatar } from "components/base";
import { Caption1 } from "components/Typography";
import { WorkshopParticipant } from "graphql/generated";
import React, { Fragment } from "react";

const PollTooltip = ({
  participants,
  show,
}: {
  participants: WorkshopParticipant[];
  show: boolean;
}) => {
  return (
    <Transition show={show} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-100"
        enterFrom="opacity-0 -translate-y-1"
        enterTo="opacity-100 -translate-y-0"
        leave="ease-in duration-100"
        leaveFrom="opacity-100 -translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <div className="absolute top-full left-1/2 z-10 -translate-x-1/2 select-none">
          <div className="relative mt-1 rounded-lg border border-neutral-5 bg-white px-3 drop-shadow-feedCard">
            <div className="no-scrollbar max-h-40 overflow-auto">
              {participants.map((participant) => (
                <div
                  className="z-10 mb-3 flex items-center gap-2 first:mt-3"
                  key={participant.id}
                >
                  <Avatar
                    {...participant}
                    size="mini"
                    textClassName="text-sm"
                  />
                  <Caption1 className="text-neutral-800">
                    {participant.user.fullName}
                  </Caption1>
                </div>
              ))}

              <div className="absolute -top-[5.5px] left-1/2 -z-10 h-[10px] w-[10px] -translate-x-1/2 rotate-45 border border-b-0 border-r-0 border-neutral-5 bg-white" />
            </div>
          </div>
        </div>
      </Transition.Child>
    </Transition>
  );
};

export default PollTooltip;
