import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import {
  Base2,
  Caption1,
  Caption2,
  HStack,
  Overline,
  VStack,
} from "components";
import { Avatar, Button } from "components/base";
import { useToast } from "components/provider";
import {
  AccessLevel,
  ProjectInvitation,
  useCreateProjectInvitationsMutation,
  User,
  WorkshopParticipant,
} from "graphql/generated";
import { t } from "i18n-js";
import { noop } from "lodash";
import { EnvelopeSimple, UsersThree } from "phosphor-react";
import React from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { EMAIL_REGEX } from "utils";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

interface UserItemProps {
  user: User;
}

export const UserItem = ({ user }: UserItemProps) => {
  return (
    <HStack className="cursor-pointer p-4" justify="between" align="center">
      <HStack space={2}>
        <Avatar user={user} />
        <VStack space={1}>
          <Base2>{user.fullName}</Base2>
          <Caption1 className="text-neutral-50">
            {user.defaultProjectRole}
          </Caption1>
        </VStack>
      </HStack>
      <Button variant="outline">{t("shared.addMember")}</Button>
    </HStack>
  );
};

interface UserOptionsProps {
  users: Record<"user", User>[];
  query: string;
  pendingProjectInvites: Partial<User>[];
  projectId: string;
  workshopParticipants: WorkshopParticipant[];
  workshopPendingInvitations: ProjectInvitation[];
  resetInputValue?: () => void;
}

const UserOptions = ({
  users,
  query,
  pendingProjectInvites,
  projectId,
  workshopParticipants = [],
  workshopPendingInvitations = [],
  resetInputValue = noop,
}: UserOptionsProps) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const { id: workshopId = "" } = useParams();
  const { mutateAsync: createProjectInvitationsMutateAsync } =
    useCreateProjectInvitationsMutation();
  // Handles if no users match query or there are no more users to add to the workshop
  const userListIsEmpty =
    (query.length > 0 && users.length === 0) || users.length === 0;
  const pendingProjectInvitesListEmpty =
    (query.length > 0 && pendingProjectInvites.length === 0) ||
    pendingProjectInvites.length === 0;

  // Email IDS of workshop participants
  const workshopParticipantsEmails = workshopParticipants.map(
    (user) => user.user.email,
  );
  // Email IDS of pending workshop invites
  const workshopPendingsUsersEmails = workshopPendingInvitations.map(
    (user) => user.recipient?.email || user.recipientEmail || "",
  );

  const isQueryUnique =
    !workshopParticipantsEmails.includes(query) &&
    !workshopPendingsUsersEmails.includes(query);
  const isQueryValid = query?.match(EMAIL_REGEX) && isQueryUnique;

  const handleAddToProjectAndWorkshop = async () => {
    try {
      await createProjectInvitationsMutateAsync(
        {
          projectId,
          input: {
            workshopId,
            inviteeEmails: [query],
            inviteeIds: [],
            accessLevel: AccessLevel.ViewAccess,
          },
        },
        {
          onSuccess: () => {
            void queryClient.refetchQueries(["WorkshopById"]);
            resetInputValue();
            addToast({
              message: t("shared.inviteSent"),
              variant: "success",
              hasCloseOption: true,
            });
          },
        },
      );
    } catch {
      throw new Error(t("errors.somethingWentWrong"));
    }
  };

  return (
    <VStack
      justify="between"
      className={clsx(
        "absolute left-8 right-8 mt-2 max-h-[416px] overflow-auto rounded-xl border bg-white shadow-lg",
        Z_INDEX_LEVELS.MODAL_CONTROL,
      )}
    >
      {isQueryValid && userListIsEmpty && pendingProjectInvitesListEmpty && (
        <VStack className="px-4 pt-4">
          <Overline className="pb-4 text-neutral-90">
            {t("workshopEditor.managePeople.selectPerson")}
          </Overline>
          <Combobox.Option key={query} value={query} className="list-none">
            <HStack
              className="cursor-pointer p-4"
              justify="between"
              align="center"
              onClick={(event) => event.stopPropagation()}
            >
              <HStack space={2} align="center">
                <HStack
                  align="center"
                  justify="center"
                  className="h-[40px] w-[40px] rounded-full bg-neutral-100"
                >
                  <EnvelopeSimple size={25} weight="light" />
                </HStack>
                <VStack space={1}>
                  <Base2>{query}</Base2>
                </VStack>
              </HStack>
              <Button
                variant="outline"
                onClick={(event) => {
                  event.stopPropagation();
                  void handleAddToProjectAndWorkshop();
                }}
              >
                {t("shared.addToProjectAndWorkshop")}
              </Button>
            </HStack>
          </Combobox.Option>
        </VStack>
      )}

      {!isQueryValid && userListIsEmpty && pendingProjectInvitesListEmpty && (
        <VStack className="px-4 py-8" align="center">
          <UsersThree
            className="mb-2 rounded-full bg-neutral-100 p-1.5"
            size={28}
            color="#A7A8B0"
          />
          <Caption2 className="text-neutral-50">
            {t("workshopEditor.managePeople.emptyList")}
          </Caption2>
          {/* TODO: Add support for invite via email empty state. https://coeurajtech.atlassian.net/browse/R10-168 */}
        </VStack>
      )}

      {(!userListIsEmpty || !pendingProjectInvitesListEmpty) && (
        <VStack className="px-4 pt-4">
          <Overline className="pb-4 text-neutral-90">
            {t("workshopEditor.managePeople.selectPerson")}
          </Overline>
          {/* TODO: Add support for invite via email. https://coeurajtech.atlassian.net/browse/R10-168 */}
          {users.map(({ user }: UserItemProps) => (
            <Combobox.Option key={user.id} value={user} className="list-none">
              <UserItem user={user} />
            </Combobox.Option>
          ))}
          {pendingProjectInvites?.map((invite) => {
            if (!invite?.email) {
              return (
                <Combobox.Option
                  key={invite?.id}
                  value={invite}
                  className="list-none"
                  onClick={noop}
                >
                  <UserItem user={invite} />
                </Combobox.Option>
              );
            }

            if (invite?.email) {
              return (
                <Combobox.Option
                  key={invite?.email}
                  value={invite}
                  className="list-none"
                >
                  <HStack
                    className="cursor-pointer p-4"
                    justify="between"
                    align="center"
                  >
                    <HStack space={2} align="center">
                      <HStack
                        align="center"
                        justify="center"
                        className="h-[40px] w-[40px] rounded-full bg-neutral-100"
                      >
                        <EnvelopeSimple size={25} weight="light" />
                      </HStack>
                      <VStack space={1}>
                        <Base2>{invite?.email}</Base2>
                      </VStack>
                    </HStack>
                    <Button variant="outline">{t("shared.addMember")}</Button>
                  </HStack>
                </Combobox.Option>
              );
            }
          })}
        </VStack>
      )}
    </VStack>
  );
};

export default UserOptions;
