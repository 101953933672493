import { Button } from "components/base";
import { ModalProps } from "components/pages/UserSettings/components/UserSettingsInsightsContent/components";
import { Modal } from "components/partial";
import { Base2, Heading1Strong, Paragraph1 } from "components/Typography";
import { X } from "phosphor-react";
import React from "react";

export const EmotionalExpressionModal = ({ isOpen, setIsOpen }: ModalProps) => {
  return (
    <Modal
      className="h-min max-h-112 w-235 overflow-scroll rounded-xl"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      children={
        <div className="flex flex-col gap-6 p-10">
          <div className="flex items-center justify-between">
            <Heading1Strong>
              {/* {t(
                "component.userSettings.tabs.insights.modal.personalityProfile.title",
              )} */}
              What is Emotional Expression?
            </Heading1Strong>
            <Button
              variant="tertiary"
              RightIcon={X}
              onClick={() => setIsOpen(false)}
            />
          </div>

          <Paragraph1 className="text-neutral-90">
            {/* {t(
              "component.userSettings.tabs.insights.modal.personalityProfile.description",
            )} */}
            Emotional expression is the overall degree to which the language
            used within the conversation contains emotion.
          </Paragraph1>

          <div className="flex justify-between">
            <Paragraph1 className="text-neutral-90">
              {/* {t(
                "component.userSettings.tabs.insights.modal.personalityProfile.copy1",
              )} */}
              Humans are driven by their emotions first, and their logical
              thinking follows. Therefore, measuring the points in conversations
              where a high degree of emotional language is used, helps to better
              understand what topics matter to individuals and the group.
            </Paragraph1>
          </div>
          <Base2 className="">Reference:</Base2>
          <Paragraph1 className="text-neutral-90">
            {/* {t(
              "component.userSettings.tabs.insights.modal.personalityProfile.copy2",
            )} */}
            Yi-Tai Seih,Cindy K. Chung &James W. Pennebaker (2011) -
            "Experimental manipulations of perspective taking and perspective
            switching in expressive writing". Cognition & Emotion 25 (5)
          </Paragraph1>
        </div>
      }
    />
  );
};
