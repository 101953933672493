import { useUpdateRaiseHandFlagMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation UpdateRaiseHandFlag($workshopParticipantId: ID!) {
    updateRaiseHandFlag(workshopParticipantId: $workshopParticipantId) {
      id
    }
  }
`;

export function useUpdateRaiseHandFlag() {
  const queryClient = useQueryClient();
  return useUpdateRaiseHandFlagMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["WorkshopById"]);
      await queryClient.refetchQueries(["WorkshopById"]);
    },
  });
}
