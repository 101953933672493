import React from "react";

// Using Polymorphic Component pattern from https://itnext.io/react-polymorphic-components-with-typescript-f7ce72ea7af2
// https://stackoverflow.com/a/66568474

type TextProps<T extends React.ElementType> = {
  className?: string;
  as?: T;
};

function createTypographyComponent(
  baseClassNames: string,
  defaultTag: React.ElementType,
) {
  return function Typography<T extends React.ElementType = typeof defaultTag>({
    as,
    className = "",
    ...props
  }: TextProps<T> & React.ComponentPropsWithoutRef<T>) {
    const Component = as || defaultTag;
    return (
      <Component className={`${baseClassNames} ${className}`} {...props} />
    );
  };
}

export const Heading1 = createTypographyComponent(
  "font-sans font-normal text-4xl leading-ui",
  "h2",
);

export const Heading1Strong = createTypographyComponent(
  "font-sans font-medium text-4xl leading-ui",
  "h2",
);

export const Heading2 = createTypographyComponent(
  "font-sans font-normal text-2xl leading-ui",
  "h2",
);

export const Heading2Strong = createTypographyComponent(
  "font-sans font-medium text-2xl leading-ui",
  "h2",
);

export const Heading3 = createTypographyComponent(
  "font-sans font-normal text-xl leading-ui",
  "h2",
);

export const Heading3Strong = createTypographyComponent(
  "font-sans font-medium text-xl leading-ui",
  "h2",
);

export const Base1 = createTypographyComponent(
  "font-sans font-normal text-lg leading-ui",
  "p",
);

export const Base1Strong = createTypographyComponent(
  "font-sans font-medium text-lg leading-ui",
  "p",
);

export const Base2 = createTypographyComponent(
  "font-sans font-normal text-base leading-ui",
  "p",
);

export const Base2Strong = createTypographyComponent(
  "font-sans font-medium text-base leading-ui",
  "p",
);

export const Caption1 = createTypographyComponent(
  "font-sans font-normal text-xs leading-ui tracking-wider",
  "p",
);

export const Caption2 = createTypographyComponent(
  "font-sans font-normal text-2xs leading-ui tracking-wider",
  "p",
);

export const Overline = createTypographyComponent(
  "font-sans font-normal text-xs leading-ui uppercase tracking-wider",
  "p",
);

export const Display1 = createTypographyComponent(
  "font-sans font-extralight text-7xl leading-display",
  "h2",
);
export const Display2 = createTypographyComponent(
  "font-sans font-extralight text-6xl leading-display",
  "h2",
);

export const Display3 = createTypographyComponent(
  "font-sans font-light text-5xl leading-display",
  "h2",
);

export const Display4 = createTypographyComponent(
  "font-sans font-light text-4xl leading-display",
  "h2",
);

export const Display5 = createTypographyComponent(
  "font-sans font-normal text-1.5xl leading-display",
  "h2",
);

export const Paragraph1 = createTypographyComponent(
  "font-sans font-normal text-lg leading-paragraph tracking-widest whitespace-pre-wrap break-words",
  "p",
);

export const Paragraph2 = createTypographyComponent(
  "font-sans font-normal text-base leading-paragraph tracking-widest whitespace-pre-wrap break-words",
  "p",
);

export const Paragraph3 = createTypographyComponent(
  "font-sans font-normal text-sm leading-paragraph tracking-wide whitespace-pre-wrap break-words",
  "p",
);
