import React, { useEffect, useState } from "react";
import { MIRO_REGEX } from "utils/helpers/validations";

import { MiroBoardEmbedComponent } from ".";

const MiroBoardEmbed: MiroBoardEmbedComponent = ({ link, showMiroUi }) => {
  const [boardId, setBoardId] = useState("");

  useEffect(() => {
    if (MIRO_REGEX.test(link)) {
      setBoardId(
        link.split("https://miro.com/app/board/").pop()?.split("/")[0] || "",
      );
    }
  }, [link, setBoardId]);

  return boardId.length > 0 ? (
    <iframe
      src={`https://miro.com/app/live-embed/${boardId}?embedAutoplay=true&embedMode=${
        showMiroUi ? "" : "view_only_without_ui"
      }`}
      className="h-full w-full"
    />
  ) : (
    <div>check your link</div>
  );
};

export default MiroBoardEmbed;
