import clsx from "clsx";
import DashedBorder from "components/partial/WorkshopEditorActivityContent/dashedBorder";
import { useToast } from "components/provider";
import { DocumentInput } from "graphql/generated";
import React, { useEffect, useRef, useState } from "react";

import ResourceModal from "./ResourceModal";
import UploadStatus from "./UploadStatus";

const SUCCESS_TIMEOUT = 1500;

type FileUploadType = {
  handleAddResource: (resource: DocumentInput) => void;
  acceptMultiple?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  afterSuccessShow?: () => void;
};

const readFile = (fileToRead: File) => {
  return new Promise((resolve) => {
    const fr = new FileReader();

    fr.addEventListener(
      "load",
      ({ target }) => {
        resolve(target?.result);
      },
      { once: true },
    );

    fr.readAsDataURL(fileToRead);
  });
};

const FileUploader = ({
  handleAddResource,
  acceptMultiple = true,
  isSuccess = false,
  isError = false,
  isLoading = false,
  isDisabled,
  afterSuccessShow,
}: FileUploadType) => {
  const { addToast } = useToast();

  const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
  const [resourceFile, setResourceFile] = useState<File | undefined>(undefined);

  const [highlight, setHighlight] = useState(false);
  const [uploadState, setUploadState] = useState<
    "idle" | "uploading" | "success" | "error"
  >("idle");
  const [filename, setFilename] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const closeResourceModal = () => setIsResourceModalOpen(false);

  useEffect(() => {
    if (
      isSuccess &&
      !isLoading &&
      uploadState === "uploading" &&
      !isResourceModalOpen
    ) {
      setUploadState("success");
      setTimeout(
        () => (afterSuccessShow ? afterSuccessShow() : setUploadState("idle")),
        SUCCESS_TIMEOUT,
      );
    } else if (isError && uploadState === "uploading") {
      setUploadState("error");
    }
  }, [isSuccess, isError, afterSuccessShow, isLoading]);

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  /**
   * convertBase64Data maps over an array of typeof FileList,
   * passing a newly created object consisting of the file
   * in base64 format, its file name and file type.
   * @param event is an uploaded File
   */
  const convertBase64Data = (files: File[]) => {
    for (const file of files) {
      readFile(file)
        /**
         * base64Data will return an object that looks like the following
         * "data:image/png;base64,{base64Data}". We only want to return the
         * base64Data from the response which we would need to split from the preceding info.
         */
        .then((base64Data) => {
          handleAddResource({
            base64File: (base64Data as string).split(",")[1],
            contentType: file.type,
            filename: file.name,
            isActive: true,
          });
          setResourceFile(undefined);
        })
        .catch((error) => {
          console.error(error);
          setUploadState("error");
        });
    }
  };

  const filterSupportedFiles = (files: FileList) => {
    const result = [];
    if (files && files?.length > 0) {
      for (let i = 0; i < files?.length; i++) {
        const file = files[i];
        if (
          file?.type === "image/png" ||
          file?.type === "image/jpg" ||
          file?.type === "application/pdf"
        ) {
          result.push(file);
        } else {
          addToast({
            message: file?.name
              ? `${file?.name} is not a supported file`
              : "Only .jpg, .png, and .pdf are supported files ",
            variant: "error",
            hasCloseOption: true,
          });
        }
      }
    }
    return result;
  };

  const handleChange = (event: {
    dataTransfer?: { files: FileList };
    target?: { files: FileList };
  }) => {
    if (event.target?.files) {
      const files = filterSupportedFiles(event.target?.files);
      files?.length > 0 && handleUpload(event.target?.files);
    }
  };

  const onDragOver = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setHighlight(true);
  };

  const onDragLeave = () => setHighlight(false);

  const onDrop = (event: {
    preventDefault: () => void;
    dataTransfer: { files: FileList };
  }) => {
    event.preventDefault();
    handleUpload(event.dataTransfer.files);
    setHighlight(false);
  };

  const handleUpload = (fileList: FileList) => {
    const files = Array.prototype.slice.call(fileList) as File[];
    if (files.length > 0) {
      setUploadState("uploading");
      const newFilename = files.map((file) => file.name).join(", ");
      setFilename(newFilename);

      if (files.length === 1) {
        setResourceFile(files[0]);
        setIsResourceModalOpen(true);
      } else {
        convertBase64Data(files);
      }
    }
  };

  const onSaveCallback = () => {
    const newFile = new File([resourceFile as File], filename, {
      type: resourceFile?.type,
    });
    convertBase64Data([newFile]);
    closeResourceModal();
  };

  const onCancelCallback = () => {
    closeResourceModal();
    setUploadState("idle");
    setFilename("");
    setResourceFile(undefined);
  };

  return (
    <>
      <div
        aria-disabled={isDisabled}
        onDragOver={isDisabled ? undefined : onDragOver}
        onDragLeave={isDisabled ? undefined : onDragLeave}
        onDrop={isDisabled ? undefined : onDrop}
        onClick={isDisabled ? undefined : openFileDialog}
        className={clsx("cursor-pointer", {
          "rounded-lg bg-tint-dark-5": highlight,
          "cursor-not-allowed": isDisabled,
        })}
      >
        <DashedBorder
          className={clsx(
            "flex !h-30 cursor-pointer flex-col items-center justify-center bg-tint-dark-5 transition-colors hover:bg-tint-dark-10",
            { "!border-primary-turquoise-50 !bg-tint-dark-10": highlight },
          )}
        >
          <input
            ref={fileInputRef}
            className="hidden"
            type="file"
            disabled={isDisabled || uploadState !== "idle"}
            multiple={acceptMultiple}
            accept=".pdf,.jpg,.png"
            onChange={
              handleChange as React.ChangeEventHandler<HTMLInputElement>
            }
          />
          <UploadStatus
            filename={filename}
            uploadState={uploadState}
            setUploadState={setUploadState}
          />
        </DashedBorder>
      </div>
      <ResourceModal
        fileName={filename}
        setFileName={setFilename}
        onSaveCallback={onSaveCallback}
        open={isResourceModalOpen}
        onCancelCallback={onCancelCallback}
      />
    </>
  );
};

export default FileUploader;
