import React from "react";

function PresentationSmallActiveIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_46_1450)">
        <path
          d="M20.0005 20.8965C24.1386 20.8965 27.4932 17.5419 27.4932 13.4038C27.4932 9.26572 24.1386 5.91113 20.0005 5.91113C15.8624 5.91113 12.5078 9.26572 12.5078 13.4038C12.5078 17.5419 15.8624 20.8965 20.0005 20.8965Z"
          fill="#ECC73A"
        />
        <path
          d="M38.5015 0H1.49854C1.10113 0.000103285 0.720033 0.158018 0.439025 0.439025C0.158018 0.720033 0.000103285 1.10113 0 1.49854L0 25.3093C0.000103285 25.7067 0.158018 26.0878 0.439025 26.3688C0.720033 26.6498 1.10113 26.8077 1.49854 26.8078H21.6287C21.8511 28.0486 22.4323 29.197 23.3004 30.111C24.1685 31.0251 25.2855 31.6646 26.5132 31.9505V38.4166C26.5132 38.814 26.6711 39.1952 26.9521 39.4762C27.2331 39.7572 27.6143 39.9151 28.0117 39.9151C28.4091 39.9151 28.7903 39.7572 29.0713 39.4762C29.3524 39.1952 29.5102 38.814 29.5102 38.4166V31.9502C30.7378 31.6636 31.8546 31.0239 32.723 30.1101C33.5914 29.1964 34.1733 28.0484 34.3969 26.8078H38.5015C38.8989 26.8077 39.28 26.6498 39.561 26.3688C39.842 26.0878 39.9999 25.7067 40 25.3093V1.49854C39.9999 1.10113 39.842 0.720033 39.561 0.439025C39.28 0.158018 38.8989 0.000103285 38.5015 0V0ZM30.4839 28.1141C30.2003 28.3921 29.871 28.6191 29.5102 28.7851C28.7518 29.1285 27.8922 29.1755 27.1008 28.9169C26.3094 28.6584 25.6434 28.113 25.2339 27.3881C24.8244 26.6632 24.701 25.8113 24.8881 25C25.0751 24.1887 25.559 23.4768 26.2446 23.0044C26.9301 22.5319 27.7677 22.3332 28.5924 22.4472C29.4171 22.5613 30.1693 22.9799 30.7009 23.6206C31.2325 24.2614 31.5051 25.0779 31.4649 25.9095C31.4247 26.7411 31.0748 27.5276 30.4839 28.1141ZM37.0029 23.8107H34.2424C33.8475 22.4644 33.0271 21.2822 31.904 20.4413C30.781 19.6003 29.4157 19.1458 28.0127 19.1458C26.6096 19.1458 25.2443 19.6003 24.1213 20.4413C22.9982 21.2822 22.1778 22.4644 21.7829 23.8107H2.99707V2.99707H37.0029V23.8107Z"
          fill="#35363E"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_1450">
          <rect width="40" height="39.9151" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PresentationSmallActiveIcon;
