import React from "react";

function ChevronDownIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width={19}
      height={20}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.78 7.886a.75.75 0 010 1.06l-4.75 4.75a.75.75 0 01-1.06 0l-4.75-4.75a.75.75 0 011.06-1.06l4.22 4.22 4.22-4.22a.75.75 0 011.06 0z"
        fill={props.fill || "#222"}
      />
    </svg>
  );
}

export default ChevronDownIcon;
