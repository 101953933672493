import clsx from "clsx";
import { Button, WorkshopStatusBadge } from "components/base";
import { HStack, VStack } from "components/layout";
import { Caption1, Display3, Heading2 } from "components/Typography";
import { t } from "i18n-js";
import RecoderIcon from "icons/RecoderLogo";
import RecoderIconGray from "icons/RecoderLogoGray";
import { ArrowRight, CaretRight, Presentation } from "phosphor-react";
import { useWorkshop } from "queries";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getFormattedWorkshopTimes } from "utils";

const WorkshopRejoin = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const { workshop } = useWorkshop({
    id,
  });
  const { startTime, endTime, date } = getFormattedWorkshopTimes(
    workshop?.startTime,
    workshop?.endTime,
  );

  const navigateToProject = () => {
    navigate(`/projects/${workshop?.projectId || ""}`);
  };

  const redirectToWorkshop = () => navigate(`/workshop/${id}`);

  return (
    <>
      <header>
        <div
          className={clsx(
            "flex flex-row items-center justify-between gap-4 bg-neutral-5 px-14 py-6",
          )}
        >
          <HStack space={4} justify="between" className="w-full">
            <HStack space={4}>
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-tertiary-blue-50">
                <Presentation size={24} className=" text-white" />
              </div>
              <VStack space={1}>
                <HStack align="center" space={2}>
                  <Heading2
                    className={"text-neutral-90"}
                    aria-label={t("workshop.header.aria.title")}
                  >
                    {workshop?.title}
                  </Heading2>
                  <WorkshopStatusBadge
                    status={
                      workshop?.state !== null ? workshop?.state : undefined
                    }
                  />
                </HStack>
                <HStack>
                  {(startTime || endTime || date) && (
                    <div className="mt-[2px] flex items-center text-neutral-70">
                      <Caption1
                        aria-label={t("workshop.header.aria.startTime")}
                      >
                        {startTime}
                      </Caption1>

                      <CaretRight size={8} className="mx-1" />

                      <Caption1 aria-label={t("workshop.header.aria.endTime")}>
                        {endTime}
                      </Caption1>

                      <Caption1
                        aria-label={t("workshop.header.aria.date")}
                        className="ml-4"
                      >
                        {date}
                      </Caption1>
                    </div>
                  )}
                </HStack>
              </VStack>
            </HStack>
            <Button
              variant="outline"
              LeftIcon={RecoderIcon}
              onClick={navigateToProject}
            >
              {t("shared.goToProject")}
            </Button>
          </HStack>
        </div>
      </header>
      <VStack
        className="mx-auto mt-[32px] h-[646px] w-[92%] rounded-lg bg-neutral-5"
        justify="center"
        align="center"
        space={6}
      >
        <RecoderIconGray />
        <Display3>{t("shared.workshopInProgress")}</Display3>
        <Button RightIcon={ArrowRight} onClick={redirectToWorkshop}>
          {t("shared.rejoinWorkshop")}
        </Button>
      </VStack>
    </>
  );
};

export default WorkshopRejoin;
