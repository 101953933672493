import { DiSCProfileOption } from "./DiSCProfile";

export const getActiveWedgeFillHexcodeByProfile = (
  profile: DiSCProfileOption,
) => {
  switch (profile) {
    case DiSCProfileOption.D:
    case DiSCProfileOption.Dc:
    case DiSCProfileOption.Di:
      return "#0FB356";
    case DiSCProfileOption.i:
    case DiSCProfileOption.iS:
    case DiSCProfileOption.iD:
      return "#E34B4B";
    case DiSCProfileOption.S:
    case DiSCProfileOption.Sc:
    case DiSCProfileOption.Si:
      return "#2C8BC0";
    case DiSCProfileOption.C:
    case DiSCProfileOption.Cd:
    case DiSCProfileOption.Cs:
      return "#EB8424";
  }
};
