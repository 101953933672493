/* eslint-disable no-console */
import { Base2, Display3, HStack } from "components";
import { ProjectByIdQuery } from "graphql/generated";
import { ArrowLeft, Info } from "phosphor-react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "./base/Button/Button";
import { PersonalityProfileInfoModal } from "./pages/UserSettings/components/UserSettingsInsightsContent/components";

type PhaseViewSubheaderProps = {
  project: ProjectByIdQuery["projectById"];
};

export default function TeamViewSubheader() {
  const navigate = useNavigate();
  const { params } = useParams();
  const [modalsVisibility, setModalsVisibility] = useState({
    personalityProfile: false,
    languageCohesion: false,
  });
  const togglePersonaliltyProfileModal = (shouldShow: boolean) => {
    // close others
    setModalsVisibility({
      ...modalsVisibility,
      personalityProfile: shouldShow,
    });
  };

  return (
    <>
      <div className="flex w-full flex-row items-center justify-around">
        <HStack>
          <button
            onClick={() => window.location.reload()}
            data-testid="subheader-back-btn"
          >
            <ArrowLeft className="mr-6 h-6 w-6" />
          </button>
          <Base2>Back to Team</Base2>
        </HStack>
        <Display3>Personality Profile</Display3>
        <HStack className="items-center">
          <Button
            variant="link"
            onClick={() => togglePersonaliltyProfileModal(true)}
            RightIcon={() => <Info size={24} className="text-neutral-70" />}
            className="mr-1"
          />
          <Base2 className="text-md font-normal text-neutral-700">
            What is Personality Profile?
          </Base2>
        </HStack>
        {modalsVisibility.personalityProfile && (
          <PersonalityProfileInfoModal
            isOpen={modalsVisibility.personalityProfile}
            setIsOpen={(shouldShow) =>
              togglePersonaliltyProfileModal(shouldShow)
            }
          />
        )}
      </div>
    </>
  );
}
