export const VALIDATION_FAILURE = "VALIDATION_FAILURE";
export const NOT_FOUND = "NOT_FOUND";
export const FORBIDDEN = "FORBIDDEN";
export const INVALID_CREDENTIALS = "INVALID_CREDENTIALS";
export const UNCONFIRMED = "UNCONFIRMED";

export type GraphQLValidationErrorDetail = {
  error: string;
  attribute: string;
  model?: string;
};
