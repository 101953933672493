import clsx from "clsx";
import { HStack, Overline, Paragraph3 } from "components";
import { WhiteCard } from "components/base";
import { ViewNavigator, ViewSubheader } from "components/partial";
import { useProjectNavigator } from "contexts";
import { useDayCardContext } from "contexts/DayCardContext";
import { useWeekFeedContext } from "contexts/WeekFeedContext";
import { addDays, format, formatISO, isToday, parseISO } from "date-fns";
import { Phase, ProjectByIdQuery } from "graphql/generated";
import { t } from "i18n-js";
import {
  ArrowDown,
  CalendarBlank,
  CheckSquare,
  Headphones,
  Heartbeat,
  MegaphoneSimple,
  Plus,
  Presentation,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { generateWeekdayIndexArray } from "utils/helpers/generate-weekday-indexes";

import { TIMEOUT_SECONDS } from "./constanst";
import {
  GroupedWeekFeedType,
  isAnnouncementResource,
  isHuddleResource,
  isMeetingResource,
  isTaskResource,
  isTemperatureCheckResource,
  isWorkshopResource,
  ResourceProps,
} from "./WeekFeed";
import WeekFeedDayCardDStrip from "./WeekFeedDayCardStrip";

type WeekViewSubheaderProps = {
  project: ProjectByIdQuery["projectById"];
  openProjectGoalModal: () => void;
};

const formatDate = (date: Date) => {
  return [format(date, "MMM dd"), format(date, "iii")];
};

function WeekCard({
  date,
  feedData,
}: {
  date: Date;
  feedData: GroupedWeekFeedType;
}) {
  const [formattedDate, day] = formatDate(date);
  const isTodayCard = isToday(date);
  const { setSelectedDay } = useDayCardContext();
  const [displayEmptyError, setDisplayEmptyError] = useState(false);

  let emptyDayTimeoutID: NodeJS.Timeout;

  useEffect(() => {
    return () => {
      clearTimeout(emptyDayTimeoutID);
      setDisplayEmptyError(false);
    };
  }, []);

  const sortData = (date: string) => {
    const meetings =
      feedData?.[date]?.filter(
        (data) => data.__typename === "Meeting" && !!data.startTime,
      ) || [];
    const tasks =
      feedData?.[date]?.filter((data) => data.__typename === "Task") || [];
    const announcements =
      feedData?.[date]?.filter((data) => data.__typename === "Announcement") ||
      [];
    const temperatureCheck =
      feedData?.[date]?.filter(
        (data) => data.__typename === "TemperatureResponse",
      ) || [];
    const huddles =
      feedData?.[date]?.filter(
        (data) =>
          data.__typename === "Meeting" && !data.startTime && !!data.createdAt,
      ) || [];
    const workshops =
      feedData?.[date]?.filter(
        (data) => data.__typename === "Workshop" && !!data.startTime,
      ) || [];

    const sortedData = [
      ...temperatureCheck,
      ...meetings,
      ...tasks,
      ...announcements,
      ...huddles,
      ...workshops,
    ];

    return sortedData;
  };

  function Resource({ item }: ResourceProps) {
    if (isAnnouncementResource(item)) {
      return (
        <WeekFeedDayCardDStrip description={item.announcementTitle || ""}>
          <MegaphoneSimple
            className="mr-2"
            color={"#F29E50"}
            weight="fill"
            mirrored={true}
          />
        </WeekFeedDayCardDStrip>
      );
    }

    if (isMeetingResource(item)) {
      return (
        <WeekFeedDayCardDStrip description={item.title || ""}>
          <CalendarBlank
            className="mr-2"
            color={"#4999C6"}
            weight="fill"
            mirrored={true}
          />
        </WeekFeedDayCardDStrip>
      );
    }

    if (isHuddleResource(item)) {
      return (
        <WeekFeedDayCardDStrip description={item.meetingDescription || ""}>
          <Headphones
            className="mr-2"
            color={"#638AF5"}
            weight="fill"
            mirrored={true}
          />
        </WeekFeedDayCardDStrip>
      );
    }

    if (isTaskResource(item)) {
      return (
        <WeekFeedDayCardDStrip description={item.taskTitle || ""}>
          <CheckSquare className="mr-2" color="#30CD74" weight="fill" />
        </WeekFeedDayCardDStrip>
      );
    }

    if (isTemperatureCheckResource(item)) {
      return (
        <WeekFeedDayCardDStrip description={"Pulse check"}>
          <Heartbeat className="mr-2" color="#FF6060" weight="fill" />
        </WeekFeedDayCardDStrip>
      );
    }

    if (isWorkshopResource(item)) {
      return (
        <WeekFeedDayCardDStrip description={item.workshopTitle || ""}>
          <Presentation
            className="mr-2"
            color={"#3A61EB"}
            weight="fill"
            mirrored={true}
          />
        </WeekFeedDayCardDStrip>
      );
    }

    return <></>;
  }

  const handleSelectedDay = (day: string) => {
    if (sortData(formatISO(date)).length === 0) {
      setDisplayEmptyError(true);
      emptyDayTimeoutID = setTimeout(
        () => setDisplayEmptyError(false),
        TIMEOUT_SECONDS * 1000,
      );
    } else {
      setSelectedDay(day);
    }
  };

  const feedDataLength = (day: string) => sortData(day).length;

  return (
    <WhiteCard
      isSelected={isTodayCard}
      className={"h-[38px] w-[183px] cursor-default group-hover:h-[250px]"}
      onClick={() => handleSelectedDay(formatISO(date))}
    >
      <div className="flex flex-col py-2 px-4 transition-all duration-300 group-hover:py-4">
        <Overline className="mb-2 hidden uppercase text-neutral-70 transition-all duration-300 group-hover:block group-hover:text-neutral-90">
          {day}
        </Overline>
        <HStack align="center" justify="between" className="mb-8">
          <h2
            className={clsx(
              "text-lg leading-ui transition-colors group-hover:text-neutral-90",
              isTodayCard
                ? "font-medium text-neutral-90 group-hover:text-2xl"
                : "font-normal text-neutral-70 group-hover:text-xl",
            )}
          >
            {formattedDate}
          </h2>
          {displayEmptyError && feedDataLength(formatISO(date)) === 0 ? (
            <ArrowDown className="mr-2" color="#5F6068" size={24} />
          ) : undefined}
        </HStack>
        {sortData(formatISO(date))?.map((item, index) =>
          index < 3 ? (
            <Resource item={item} key={`${day}-${index}`} />
          ) : undefined,
        )}
        {feedDataLength(formatISO(date)) > 3 ? (
          <WeekFeedDayCardDStrip
            description={`${feedDataLength(formatISO(date)) - 3} more`}
          >
            <Plus className="mr-2" color="#5F6068" />
          </WeekFeedDayCardDStrip>
        ) : undefined}
        {displayEmptyError && feedDataLength(formatISO(date)) === 0 ? (
          <Paragraph3 className="text-neutral-70">
            {t("component.dayActionCard.emptyDay")}
          </Paragraph3>
        ) : undefined}
      </div>
    </WhiteCard>
  );
}

const WEEKDAYS_INDEXES = generateWeekdayIndexArray();

export default function WeekViewSubheader({
  project,
  openProjectGoalModal,
}: WeekViewSubheaderProps) {
  const {
    currentWeek,
    currentPhase,
    setCurrentWeek,
    setCurrentPhase,
    goToToday,
  } = useProjectNavigator();

  const [firstPhase] = project.phases;
  const phase = currentPhase || firstPhase;
  const lastPhasePosition = project.phases.length - 1;

  const { feedData } = useWeekFeedContext();

  const search = useLocation().search;
  const scheduledAt = new URLSearchParams(search).get("scheduledAt");
  const navigate = useNavigate();

  const handleNavigateLeft = () => {
    if (currentWeek > 0) {
      setCurrentWeek((current: number) => current - 1);
      return;
    }

    if (phase.position > 0) {
      const nextPosition = phase.position - 1 || 0;
      const newPhase = project.phases.find(
        (phase) => phase.position === nextPosition,
      );

      if (newPhase) {
        setCurrentPhase(newPhase as Phase);
        setCurrentWeek(newPhase.weeksLength - 1);
      }
    }
    navigate(`/projects/${project.id}?level=Timeline`);
  };

  const handleNavigateRight = () => {
    if (currentWeek < totalWeeks - 1) {
      setCurrentWeek((current: number) => current + 1);
      return;
    }

    if (phase.position < lastPhasePosition) {
      const nextPosition = phase.position + 1 || lastPhasePosition;
      const newPhase = project.phases.find(
        (phase) => phase.position === nextPosition,
      );
      if (newPhase) {
        setCurrentPhase(newPhase as Phase);
        setCurrentWeek(0);
      }
    }
    navigate(`/projects/${project.id}?level=Timeline`);
  };

  const canNavigateLeft = () => phase.position > 0 || currentWeek > 0;
  const canNavigateRight = () =>
    phase.position < lastPhasePosition || currentWeek < totalWeeks - 1;

  const totalWeeks = phase.weeksLength || 2;
  const startDate = parseISO(phase.startDate || "");

  useEffect(() => {
    if (!!scheduledAt && !!currentPhase) {
      goToToday(new Date(scheduledAt));
    }
  }, [scheduledAt, currentPhase]);

  if (!currentPhase) {
    return <></>;
  }

  return (
    <div className="flex w-full flex-col items-center">
      <ViewSubheader
        className="!mb-14 hidden w-full group-hover:flex group-hover:justify-between"
        openProjectGoalModal={openProjectGoalModal}
        goToToday={() => goToToday(new Date())}
      >
        <ViewNavigator
          label={`${t("shared.phase")} ${currentPhase.position + 1} :`}
          title={`${t("shared.Week")} ${currentWeek + 1}`}
          onLeftClick={canNavigateLeft() ? handleNavigateLeft : undefined}
          onRightClick={canNavigateRight() ? handleNavigateRight : undefined}
        />
      </ViewSubheader>
      <div className="no-scrollbar flex items-start justify-center overflow-x-auto">
        {WEEKDAYS_INDEXES.map((index) => (
          <WeekCard
            key={addDays(
              startDate,
              WEEKDAYS_INDEXES.length * currentWeek + index,
            ).toString()}
            date={addDays(
              startDate,
              WEEKDAYS_INDEXES.length * currentWeek + index,
            )}
            data-testid={`week-card-${index}`}
            feedData={feedData}
          />
        ))}
      </div>
    </div>
  );
}
