import clsx from "clsx";
import {
  Avatar,
  DiscQuadrant,
  DiSCQuadrantsEnum,
  Tooltip,
} from "components/base";
import { HStack, VStack } from "components/layout";
import {
  Base1,
  Base2,
  Base2Strong,
  Caption1,
  Caption2,
} from "components/Typography";
import { User } from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { Info, UserPlus, UsersFour } from "phosphor-react";
import React from "react";
import { useParams } from "react-router-dom";

const titleColorMapping = (type: DiSCQuadrantsEnum) => {
  switch (type) {
    case DiSCQuadrantsEnum.D:
      return "text-secondary-green-300";
    case DiSCQuadrantsEnum.C:
      return "text-secondary-orange-300";
    case DiSCQuadrantsEnum.I:
      return "text-secondary-red-300";
    case DiSCQuadrantsEnum.S:
      return "text-secondary-indigo-300";
    default:
      return "text-white";
  }
};

const AnonymousUsers = () => (
  <div className="rounded-lg bg-neutral-900 text-white">
    <Caption2>{t("team.personalityProfile.anonymousUsers")}</Caption2>
  </div>
);

const People = ({
  users,
  anonymousUserCount,
}: {
  users: User[];
  anonymousUserCount: number;
}) => {
  const shownUsers = users.length > 7 ? users.slice(0, 6) : users;
  const remainingUsers = users.length - shownUsers.length;

  return (
    <HStack className="!mt-3 gap-2">
      {shownUsers.map((user) => (
        <Avatar
          user={user}
          size="mini"
          textClassName="!text-xs"
          className="z-[0]"
        />
      ))}
      {remainingUsers > 0 && (
        <div className="flex h-6 w-6 items-center justify-center rounded-full bg-neutral-800">
          <UserPlus className="text-neutral-400" />
        </div>
      )}
      {anonymousUserCount > 0 && (
        <Tooltip content={AnonymousUsers} placement="bottom" offset={6}>
          <div className="flex h-6 w-6 items-center justify-center rounded-full bg-neutral-800">
            <UsersFour className="h-5 w-5 text-neutral-400" />
          </div>
        </Tooltip>
      )}
    </HStack>
  );
};

const ProfileOption = ({
  title,
  description,
  value,
  users,
  anonymousUserCount,
  percentage,
  type,
  tooltipDescription,
}: {
  title: string;
  description: string;
  value: number;
  users: User[];
  anonymousUserCount: number;
  percentage: number;
  type: DiSCQuadrantsEnum;
  tooltipDescription: string;
}) => {
  const { projectId = "" } = useParams();
  const color = titleColorMapping(type);

  const TooltipContent = () => (
    <div className="z-10 w-80 rounded-xl bg-neutral-900 p-3">
      <Base1 className={color}>{title}</Base1>
      <Caption1 className="mb-4 text-tint-light-600">{description}</Caption1>
      <Base2 className="text-white">{tooltipDescription}</Base2>
    </div>
  );

  const { hasViewAccess } = useProjectAccessLevel({ projectId });
  const hasUsers = users?.length > 0 || anonymousUserCount > 0;
  const empty = value === 0;

  return (
    <HStack align={hasUsers ? "start" : "center"} className="max-w-[420px]">
      <DiscQuadrant quadrant={type} />
      <VStack className="!ml-4 flex-1">
        <HStack align="center" justify="between" className="flex-1">
          <VStack className={clsx("flex-1 gap-1", { "opacity-40": empty })}>
            <Base2Strong className="text-neutral-900">{title}</Base2Strong>
            <Caption1 className="text-neutral-700">{description}</Caption1>
          </VStack>
          <Base2
            className={clsx("!mr-2 text-neutral-700", { "opacity-40": empty })}
          >
            {`${Math.ceil(percentage || 0)}%`}
          </Base2>
          <Tooltip content={TooltipContent} placement="right" offset={10}>
            <Info className="text-neutral-700" />
          </Tooltip>
        </HStack>
        {hasUsers && !hasViewAccess && (
          <People users={users} anonymousUserCount={anonymousUserCount} />
        )}
      </VStack>
    </HStack>
  );
};

interface PersonalitySegment {
  value: number;
  users: User[];
  anonymousUserCount: number;
}

const PersonalityProfile = ({
  personalityProfile,
}: {
  personalityProfile: {
    dominance: PersonalitySegment;
    conscientiousness: PersonalitySegment;
    influence: PersonalitySegment;
    steadiness: PersonalitySegment;
  };
}) => {
  const { dominance, conscientiousness, influence, steadiness } =
    personalityProfile;

  return (
    <div className="grid grid-cols-2 gap-x-10 gap-y-5">
      <ProfileOption
        title={t("team.personalityProfile.profiles.dominance.title")}
        description={t(
          "team.personalityProfile.profiles.dominance.description",
        )}
        {...dominance}
        type={DiSCQuadrantsEnum.D}
        tooltipDescription={t(
          "team.personalityProfile.profiles.dominance.tooltip",
        )}
      />
      <ProfileOption
        title={t("team.personalityProfile.profiles.influence.title")}
        description={t(
          "team.personalityProfile.profiles.influence.description",
        )}
        {...influence}
        type={DiSCQuadrantsEnum.I}
        tooltipDescription={t(
          "team.personalityProfile.profiles.influence.tooltip",
        )}
      />
      <ProfileOption
        title={t("team.personalityProfile.profiles.conscientiousness.title")}
        description={t(
          "team.personalityProfile.profiles.conscientiousness.description",
        )}
        {...conscientiousness}
        type={DiSCQuadrantsEnum.C}
        tooltipDescription={t(
          "team.personalityProfile.profiles.conscientiousness.tooltip",
        )}
      />
      <ProfileOption
        title={t("team.personalityProfile.profiles.steadiness.title")}
        description={t(
          "team.personalityProfile.profiles.steadiness.description",
        )}
        {...steadiness}
        type={DiSCQuadrantsEnum.S}
        tooltipDescription={t(
          "team.personalityProfile.profiles.steadiness.tooltip",
        )}
      />
    </div>
  );
};

export default PersonalityProfile;
