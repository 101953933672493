import clsx from "clsx";
import { differenceInWeeks, isBefore, isSameDay, setDay } from "date-fns";
import { Phase } from "graphql/generated";
import { DropIcon } from "icons";
import React from "react";
import { DeepPartial } from "react-hook-form";
import { formatWithoutTimezone, getToday } from "utils/constants/time_helpers";

type PhaseBarProps = {
  phase: DeepPartial<Phase>;
  width: number;
};

type MiniTimelineProps = {
  phases: DeepPartial<Phase>[];
  totalWidth: number;
};

function PhaseBar({ phase, width }: PhaseBarProps) {
  if (!phase.startDate || !phase.weeksLength) return <></>;
  const today = getToday();

  const latestMonday = setDay(today, 1).setHours(0, 0, 0, 0);
  const startDate = formatWithoutTimezone(phase.startDate);

  const firstMondayOfPhase = setDay(new Date(startDate), 1).setHours(
    0,
    0,
    0,
    0,
  );
  const weeksFromStartDate = differenceInWeeks(
    latestMonday,
    firstMondayOfPhase,
  );

  const isActive =
    weeksFromStartDate < phase.weeksLength &&
    (isBefore(firstMondayOfPhase, latestMonday) ||
      isSameDay(firstMondayOfPhase, latestMonday));
  const weekWidth = width / phase.weeksLength;

  return (
    <div
      className={clsx(
        "relative mr-1.5 h-1 rounded-sm",
        isActive ? "bg-white" : "bg-tint-dark-15",
      )}
      style={{ width }}
    >
      {isActive ? (
        <div
          className="absolute pt-0.5"
          style={{ left: weekWidth * weeksFromStartDate }}
          data-testid="current-indicator"
        >
          <DropIcon />
        </div>
      ) : undefined}
    </div>
  );
}

export default function MiniTimeline({
  phases,
  totalWidth,
}: MiniTimelineProps) {
  const totalWeeks = phases.reduce((a, phase) => {
    return a + (phase.weeksLength || 0);
  }, 0);

  return (
    <div className="flex flex-row" style={{ width: totalWidth }}>
      {phases.map((phase) => {
        const phaseWidth = ((phase.weeksLength || 0) / totalWeeks) * totalWidth;
        return <PhaseBar key={phase.id} phase={phase} width={phaseWidth} />;
      })}
    </div>
  );
}
