import {
  PulseCheckPhaseSummaryQuery,
  PulseCheckPhaseSummaryQueryVariables,
  usePulseCheckPhaseSummaryQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query PulseCheckPhaseSummary($phaseIds: [ID!]!) {
    pulseCheckPhaseSummary(phaseIds: $phaseIds) {
      average
      lowestAverage
      highestAverage
      phaseId
    }
  }
`;

function usePulseCheckPhaseSummary(
  variables: PulseCheckPhaseSummaryQueryVariables,
): {
  pulseCheckPhaseSummary: PulseCheckPhaseSummaryQuery["pulseCheckPhaseSummary"];
} & UseQueryResult<PulseCheckPhaseSummaryQuery, unknown> {
  const response = usePulseCheckPhaseSummaryQuery(variables);

  return {
    pulseCheckPhaseSummary: response?.data?.pulseCheckPhaseSummary || [],
    ...response,
  };
}

export { usePulseCheckPhaseSummary };
