import clsx from "clsx";
import { GeneralProps } from "components/layout";
import React from "react";

/**
 * This component is used to create consistent styling for the individual sections
 * within the WorkshopSummary contents.
 */
export const SummarySection = React.forwardRef<
  HTMLDivElement,
  GeneralProps & { disabled?: boolean }
>(({ children, className, disabled, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx(
      "rounded-lg border border-neutral-5 bg-white p-6",
      { "border-dashed": disabled },
      className,
    )}
    {...props}
  >
    {children}
  </div>
));
