import { Tab } from "@headlessui/react";
import clsx from "clsx";
import {
  PostSessionActivity,
  TabNavigator,
  WorkshopAgenda,
  WorkshopSummaryHeader,
} from "components/partial";
import { Base2, Display3 } from "components/Typography";
import {
  Agenda,
  WorkshopActivity,
  WorkshopByIdQuery,
  WorkshopParticipantRole,
} from "graphql/generated";
import { t } from "i18n-js";
import { WorkshopSummaryIllustration } from "icons/illustrations";
import React, { useEffect, useState } from "react";
import { getActivityInAgendaByIndex, getIndexOfActivityInAgenda } from "utils";

import { HStack } from "../Stack";

export type GeneralProps = {
  children: React.ReactNode;
  className?: string;
  id?: string;
  isSaving?: boolean;
  isContentUpdated?: boolean;
};

/**
 * Use SummaryLayout and the various subcomponents to establish a consistent WorkshopSummary View/Edit layout
 *
 * Example Usage:
 * <SummaryLayout>
 *  <TabNav>
 *    <SummaryTab>
 *      <SummarySidebar {/* ... /} />
 *      <Main>
 *        {/* Your Page Content Goes Here /}
 *      </Main>
 *      <SummaryFooter />
 *    </SummaryTab>
 *
 *    <AgendaTab />
 *  </TabNav>
 * </SummaryLayout>
 */
export const SummaryLayout = ({
  children,
  hasFooter = false,
}: GeneralProps & { hasFooter?: boolean }) => {
  return (
    <div
      className={clsx("flex flex-col items-stretch", {
        "h-screen": !hasFooter,
        "h-[calc(100vh-80px)]": hasFooter,
      })}
    >
      <WorkshopSummaryHeader />
      {children}
    </div>
  );
};

/**
 * Takes TabNavigator and applies the tab titles that we expect in the Workshop complete/summary view.
 */
export const SummaryLayoutTabNav = ({
  children,
  className,
  isSaving,
  isContentUpdated,
}: GeneralProps) => {
  const [publishMessage, setPublishMessage] = useState("");

  useEffect(() => {
    isContentUpdated &&
      setPublishMessage(t("workshopEditor.publishedWorkshop"));

    isSaving && setPublishMessage(t("workshopEditor.saving"));
  }, [isSaving, isContentUpdated]);

  return (
    <TabNavigator
      tabs={[
        { title: t("workshop.summary.tabs.summary") },
        { title: t("workshop.summary.tabs.agenda") },
      ]}
      className={clsx("h-full overflow-hidden", className)}
      rightContent={
        <HStack align="center">
          <Base2 className="mr-4 text-green-700">{publishMessage}</Base2>
        </HStack>
      }
    >
      {children}
    </TabNavigator>
  );
};

/**
 * Tabes Tab.Panel and applies some styles
 */
export const SummaryLayoutSummaryTab = ({ children }: GeneralProps) => {
  return (
    <Tab.Panel className="w-full">
      <div className="flex h-full flex-1">{children}</div>
    </Tab.Panel>
  );
};

/**
 * Slots existing Agenda component into a conventient Tab
 */
export const SummaryLayoutAgendaTab = ({
  agenda,
  userRole,
}: {
  agenda?: Agenda[];
  userRole?: WorkshopParticipantRole;
}) => {
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const currentActivity = getActivityInAgendaByIndex(
    agenda,
    currentActivityIndex,
  );

  const handleCurrentActivityChange = (newIndex: number) => {
    if (!getActivityInAgendaByIndex(agenda, newIndex)) {
      console.error("invalid index provided to post session agenda");
      return;
    }

    setCurrentActivityIndex(newIndex);
  };

  return (
    <Tab.Panel className="flex h-full w-full">
      {agenda && (
        <WorkshopAgenda
          agenda={agenda}
          defaultSectionsOpen
          className="justify-start bg-neutral-0 p-6"
          currentActivity={currentActivity}
          onClickItem={(item: WorkshopActivity) => {
            handleCurrentActivityChange(
              getIndexOfActivityInAgenda(item, agenda),
            );
          }}
        />
      )}

      {currentActivity && (
        <PostSessionActivity
          workshopActivity={currentActivity}
          userRole={userRole}
        />
      )}
    </Tab.Panel>
  );
};
/**
 * Establishes a consistent layout for the main content of the SummaryTab.
 * Expects a 'workshop' object to be passed in.
 */
export const SummaryLayoutMain = ({
  children,
  className,
  workshop,
  onScroll,
  id,
}: GeneralProps & {
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  workshop?: WorkshopByIdQuery["workshopById"];
}) => {
  const dateString = workshop?.startTime;
  const date = new Date(dateString || "");
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[monthIndex];
  const day = date.getDate();
  let dayPostfix = "";
  switch (day) {
    case 1:
    case 21:
    case 31: {
      dayPostfix = "st";

      break;
    }
    case 2:
    case 22: {
      dayPostfix = "nd";

      break;
    }
    case 3:
    case 23: {
      dayPostfix = "rd";

      break;
    }
    default: {
      dayPostfix = "th";
    }
  }
  const formattedDate = date.toLocaleDateString();
  const workshopStartTime = `${monthName} ${day}${dayPostfix} ${year}`;
  return (
    <div
      id={id}
      className={clsx("flex flex-1 flex-col overflow-scroll", className)}
      onScroll={onScroll}
    >
      {/** Blue-Background Workshop Summary Header */}
      <div className="relative -z-10 flex w-full shrink-0 overflow-hidden bg-tertiary-blue-70">
        <div className="flex w-full flex-col gap-4  p-14 pb-30">
          {workshop ? (
            <>
              <Display3
                className="text-white"
                aria-label={t("workshop.summary.workshopTitle")}
              >
                {workshop?.title}
              </Display3>
              <Base2
                className="text-neutral-30"
                aria-label={t("workshop.summary.workshopDate")}
              >
                {workshopStartTime}
              </Base2>
            </>
          ) : (
            <Display3 className="text-white">
              {t("workshop.summary.loading")}
            </Display3>
          )}
        </div>
        <WorkshopSummaryIllustration className="absolute top-0 right-0 bottom-[-10px]" />
      </div>
      <div className="mt-[-64px] flex flex-col gap-10 px-14 pb-96">
        {children}
      </div>
    </div>
  );
};
