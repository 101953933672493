import { Base2, CenteredPage, Display2, Display3, VStack } from "components";
import { Button } from "components/base";
import { FloatingLogo } from "components/partial";
import { t } from "i18n-js";
import React from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  return (
    <CenteredPage className="bg-white">
      <FloatingLogo />
      <VStack className="w-108" space={16}>
        <VStack space={6}>
          <Display2 className="text-primary-turquoise-30">
            {t("pages.notFound.code")}
          </Display2>
          <Display3 className="text-neutral-90">
            {t("pages.notFound.title")}
          </Display3>
          <Base2 className="text-neutral-90">
            {t("pages.notFound.description")}
          </Base2>
        </VStack>
        <Button variant="secondary" onClick={() => navigate("/")}>
          {t("pages.notFound.backButton")}
        </Button>
      </VStack>
    </CenteredPage>
  );
}

export default NotFound;
