import {
  GetWorkshopByIdQuery,
  GetWorkshopByIdQueryVariables,
  useGetWorkshopByIdQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryOptions, UseQueryResult } from "react-query";

gql`
  query GetWorkshopById($id: ID!) {
    getWorkshopById(id: $id) {
      status
      workshop {
        id
        title
        startTime
        endTime
        duration
        description
        createdAt
        highlight
        hostId
        isActive
        isAgendaVisibleToParticipants
        projectId
        recordings {
          id
          url
          recordingId
          createdAt
          startTime
          status
          recordable {
            __typename
          }
        }
        state
        summary
        updatedAt
        documents {
          id
          filename
          url
        }
        room {
          id
          name
          privacy
          url
        }
        links {
          extension
          id
          preview
          title
          url
        }
        emotionalSignals {
          emojiName
          timeStamp
        }
        agenda {
          __typename
          ... on Section {
            id
            title
            createdAt
            isActive
            position
            updatedAt
            workshopActivities {
              ...LiveWorkshopActivity
            }
          }
          ... on WorkshopActivity {
            ...LiveWorkshopActivity
          }
        }
        workshopParticipants {
          id
          role
          createdAt
          role
          updatedAt
          roomToken
          didAttend
          raiseHand
          user {
            avatarUrl
            currentSignInAt
            defaultProjectRole
            email
            firstName
            id
            lastName
            fullName
          }
        }
      }
    }
  }
`;

function useWorkshopByIdStatus(
  variables: GetWorkshopByIdQueryVariables,
  options?: UseQueryOptions<GetWorkshopByIdQuery>,
): {
  workshop?: GetWorkshopByIdQuery["getWorkshopById"];
} & UseQueryResult<GetWorkshopByIdQuery, unknown> {
  const response = useGetWorkshopByIdQuery(variables, {
    enabled: !!variables.id,
    ...options,
  });

  const { data } = response;
  if (!data) {
    localStorage.setItem("lastVisitedProjectPage", window.location.pathname);
  }

  return { workshop: data?.getWorkshopById?.workshop, ...response };
}
export { useWorkshopByIdStatus };
