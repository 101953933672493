import { JSONContent } from "@tiptap/core";
import { RichTextEditor } from "components/base";
import { PreviewResource } from "components/partial";
import { Base2, Caption2, Paragraph3 } from "components/Typography";
import {
  UpdateWorkshopActivityInput,
  WorkshopActivity,
} from "graphql/generated";
import { t } from "i18n-js";
import { MiroIcon } from "icons";
import _ from "lodash";
import { useUpdateActivity } from "mutations/workshop";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { AddResource, AddResourceInput } from "../AddResource";
import DashedBorder from "../dashedBorder";
import { linkValidator } from "../utils";

const borderClass = "rounded-lg border border-neutral-5 p-4";

interface ResourceViewProps {
  activity: WorkshopActivity;
  onMutationStateChange: ({ isLoading }: { isLoading: boolean }) => void;
}

const ResourceView = ({
  activity,
  onMutationStateChange,
}: ResourceViewProps) => {
  const {
    mutate: updateActivity,
    isLoading,
    isSuccess,
    isError,
  } = useUpdateActivity();

  const debouncedUpdateActivity = useCallback(
    _.debounce(updateActivity, 2000),
    [],
  );

  useEffect(() => {
    onMutationStateChange({ isLoading });
  }, [isLoading, onMutationStateChange]);

  const activityData = useMemo(() => {
    if (activity.activity.__typename === "Resource") {
      return activity?.activity;
    }
  }, [activity]);

  const [hasResource, setHasResource] = useState(
    activityData?.link ||
      (activityData?.documents && activityData?.documents?.length > 0),
  );

  useEffect(() => {
    const newHasResource =
      activityData?.link ||
      (activityData?.documents && activityData?.documents?.length > 0);

    if (newHasResource !== hasResource) {
      setHasResource(newHasResource);
    }
  }, [activityData]);

  const handleUpdateActivity = (content: AddResourceInput) => {
    let updateWorkshopActivity = {
      id: activity.id,
    } as UpdateWorkshopActivityInput;

    if (content.file) {
      updateWorkshopActivity = {
        ...updateWorkshopActivity,
        ...content.file,
      } as UpdateWorkshopActivityInput;
    }

    if (content.link) {
      updateWorkshopActivity = {
        ...updateWorkshopActivity,
        link: content.link,
      };
    }

    debouncedUpdateActivity({
      updateWorkshopActivity,
    });
  };

  const handleFacilitationNotesChange = (content: JSONContent) => {
    debouncedUpdateActivity({
      updateWorkshopActivity: {
        id: activity.id,
        facilitatorNote: content,
      },
    });
  };

  const handleDeleteResource = (id: string, type: "link" | "document") => {
    setHasResource(false);

    const updateWorkshopActivity = {
      id: activity.id,
    } as UpdateWorkshopActivityInput;

    if (type === "link") {
      updateWorkshopActivity.removeLinkId = id;
    } else if (type === "document") {
      updateWorkshopActivity.removeResourceDocumentsId = id;
    }
    debouncedUpdateActivity(
      {
        updateWorkshopActivity,
      },
      {
        onError: () => {
          setHasResource(
            activityData?.link ||
              (activityData?.documents && activityData?.documents?.length > 0),
          );
        },
      },
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <DashedBorder className="flex gap-6 !p-6">
        {hasResource && activityData ? (
          <div className="flex flex-1 flex-col gap-6">
            <div>
              <Base2 className="mb-1 text-neutral-90">
                {t("resourceActivityEditor.yourResource")}
              </Base2>
              <Caption2 className="text-neutral-60">
                {t("resourceActivityEditor.resourcesAdded")}
              </Caption2>
            </div>
            <PreviewResource
              activityData={activityData}
              onDeleteLink={handleDeleteResource}
            />
          </div>
        ) : (
          <>
            <AddResource
              onAddResource={handleUpdateActivity}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              isDisabled={
                isLoading ||
                !!(
                  activityData?.link ||
                  (activityData?.documents &&
                    activityData?.documents?.length > 0)
                )
              }
              linkValidator={linkValidator}
            />
            <div className={`${borderClass} flex w-60 flex-col`}>
              <div className="flex-1">
                <Base2 className="mb-3 text-neutral-90">
                  {t("resourceActivityEditor.tryTemplates")}
                </Base2>
              </div>
              <a
                className="flex h-8 w-full cursor-pointer items-center justify-center rounded-full border border-tint-dark-400 hover:bg-tint-dark-200"
                href="https://miro.com/app/board/uXjVMTXGraM=/?share_link_id=754579705150"
                target="_blank"
              >
                <MiroIcon className="mr-2" />
                <Paragraph3>{t("resourceActivityEditor.templates")}</Paragraph3>
              </a>
            </div>
          </>
        )}
      </DashedBorder>
      <RichTextEditor
        label={t("workshop.facilitationNotes")}
        showCharLimit={false}
        placeholder={t("workshop.notesPlaceholder")}
        minHeight="min-h-[75px]"
        maxHeight="max-h-40"
        showFocusedRing={false}
        maxCharCount={1000}
        onChange={handleFacilitationNotesChange}
        content={activity?.facilitatorNote as JSONContent}
        focusState={activity?.title !== t("workshopEditor.untitled") && "end"}
      />
    </div>
  );
};

export default ResourceView;
