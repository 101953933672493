import { Button } from "components/base";
import { PageLoader } from "components/partial";
import { t } from "i18n-js";
import { noop } from "lodash";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { QueryErrorResetBoundary } from "react-query";

export function ErrorFallback({ onTryAgain = noop }) {
  return (
    <div className="flex-1 items-center justify-center">
      <p>{t("errors.genericError")}</p>
      <Button onClick={() => onTryAgain()}>{t("shared.tryAgain")}</Button>
    </div>
  );
}

export default function QueryErrorResetBoundaryWithSuspense({
  children,
  ErrorFallbackComponent = ErrorFallback,
  suspenseFallback = <PageLoader isShowing />,
}) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ resetErrorBoundary }) => (
            <ErrorFallbackComponent onTryAgain={resetErrorBoundary} />
          )}
        >
          <Suspense fallback={suspenseFallback}>{children}</Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
