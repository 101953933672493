import { useCreateSectionMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation CreateSection($section: CreateSectionInput!) {
    createSection(section: $section) {
      id
      title
    }
  }
`;

export function useCreateSection() {
  const queryClient = useQueryClient();
  return useCreateSectionMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["WorkshopById"]);
      await queryClient.refetchQueries(["WorkshopById"]);
    },
  });
}
