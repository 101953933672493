import { Button } from "components/base";
import { Modal } from "components/partial";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { Completed } from "icons";
import { noop } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";

import { HStack, VStack } from "../../layout/Stack";
import { Display4, Heading2Strong, Paragraph2 } from "../../Typography";

type ArchiveDialogProps = {
  onCancel: () => void;
  onArchive: () => void;
  onGotIt?: () => void;
  open: boolean;
};

function ArchiveDialog({
  onCancel,
  onArchive,
  onGotIt,
  open,
}: ArchiveDialogProps) {
  const { projectId = "" } = useParams();
  const { hasAdminAccess } = useProjectAccessLevel({
    projectId,
  });

  return (
    <Modal
      isFullScreen
      open={open}
      onClose={noop}
      className="flex w-[634px] flex-col rounded-none p-10"
    >
      <VStack space={14}>
        <VStack space={6}>
          <Completed />
          <Heading2Strong className="text-neutral-90">
            {t("component.archiveProjectConfirmDialog.title")}
          </Heading2Strong>
          <Display4 className="text-neutral-90">
            {t("component.archiveProjectConfirmDialog.subtitle")}
          </Display4>
          {hasAdminAccess && (
            <Paragraph2 className="text-neutral-90">
              {t("component.archiveProjectConfirmDialog.suggestion")}
            </Paragraph2>
          )}
        </VStack>
        <HStack space={4}>
          <Button onClick={onCancel} variant="outline" className="flex-1">
            {t("component.archiveProjectConfirmDialog.cancel")}
          </Button>
          {hasAdminAccess ? (
            <Button onClick={onArchive} className="flex-1">
              {t("component.archiveProjectConfirmDialog.archiveProject")}
            </Button>
          ) : (
            <Button onClick={onGotIt || onCancel} className="flex-1">
              {t("component.archiveProjectConfirmDialog.gotIt")}
            </Button>
          )}
        </HStack>
      </VStack>
    </Modal>
  );
}

type ArchiveWithoutSummaryDialogProps = {
  onCancel: () => void;
  onArchive: () => void;
  onGotIt?: () => void;
  open: boolean;
};

function ArchiveWithoutSummaryDialog({
  onCancel,
  onArchive,
  onGotIt,
  open,
}: ArchiveWithoutSummaryDialogProps) {
  const { projectId = "" } = useParams();
  const { hasAdminAccess } = useProjectAccessLevel({
    projectId,
  });

  return (
    <Modal
      isFullScreen
      open={open}
      onClose={noop}
      className="flex w-[440px] flex-col rounded-none p-10"
    >
      <VStack space={14}>
        <VStack space={6}>
          <Display4 className="text-neutral-90">
            {t("component.archiveWithoutPhaseSummaryConfirmDialog.title")}
          </Display4>
        </VStack>
        <VStack space={6}>
          {hasAdminAccess ? (
            <Button onClick={onArchive} className="flex-1 p-[14px]">
              {t(
                "component.archiveWithoutPhaseSummaryConfirmDialog.archiveProject",
              )}
            </Button>
          ) : (
            <Button onClick={onGotIt || onCancel} className="flex-1 p-[14px]">
              {t("component.archiveWithoutPhaseSummaryConfirmDialog.gotIt")}
            </Button>
          )}
          <Button
            onClick={onCancel}
            variant="outline"
            className="flex-1 p-[14px]"
          >
            {t("component.archiveWithoutPhaseSummaryConfirmDialog.cancel")}
          </Button>
        </VStack>
      </VStack>
    </Modal>
  );
}

export { ArchiveDialog, ArchiveWithoutSummaryDialog };
