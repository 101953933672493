import { useLocation } from "react-router-dom";
import { atobQueryString } from "utils/helpers/decode-url";

export type LocationState = {
  originUrl?: string;
  originPathname?: string;
  originSearch?: string;
};

export default function useProjectInvitationOrigin() {
  const location = useLocation();
  const locationState = location.state as LocationState;

  const queryString = locationState?.originUrl?.split("?")[1] || "";
  const encodedString = atobQueryString(window.decodeURIComponent(queryString));
  const originParams = new URLSearchParams(encodedString);

  const projectInvitationId = originParams.get("projectInvitationId") as string;
  const projectName = originParams.get("projectName") as string;
  const inviteeEmail = originParams.get("inviteeEmail") as string;
  const redirect_path = originParams.get("redirect_path") as string;
  const googleAuthError = originParams.get("google_auth_error") as string;
  const token = originParams.get("token") as string;

  const hasProjectInvitationOrigin =
    (Boolean(locationState?.originUrl) &&
      Boolean(projectInvitationId) &&
      Boolean(token)) ||
    false;

  const isInvitee = (email: string) => {
    return email === inviteeEmail;
  };

  return {
    locationState,
    originUrl: locationState?.originUrl,
    projectInvitationId,
    projectName,
    inviteeEmail,
    token,
    hasProjectInvitationOrigin,
    redirect_path,
    googleAuthError,
    isInvitee,
  };
}
