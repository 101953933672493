import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { Dropdown, Tooltip } from "components/base";
import { HStack, VStack } from "components/layout";
import { Caption2, Paragraph3 } from "components/Typography";
import { MusicStatus } from "graphql/generated";
import { t } from "i18n-js";
import { truncate } from "lodash";
import { useStartStopMusic } from "mutations/workshop";
import { CaretUp, Circle, Pause, Play } from "phosphor-react";
import { useMusicLists, useWorkshopMusic } from "queries";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useMusicStreamSubscription } from "subscriptions";
import { Z_INDEX_LEVELS } from "utils";

export type MusicItem = {
  id: string;
  url: string;
  status: MusicStatus;
  timeStamp: number;
  title: string;
  isActive: boolean;
};

type MusicPlayerProps = {
  open: boolean;
  isUserJoined: boolean;
};

const MusicPlayer = ({ open, isUserJoined }: MusicPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { id: workshopId = "" } = useParams();
  const [selectedMusic, setSelectedMusic] = useState<MusicItem | undefined>(
    undefined,
  );
  const [musicStatus, setMusicStatus] = useState(MusicStatus.Pause);

  const { musicDetails } = useWorkshopMusic({ id: workshopId });
  const { musicList = [] } = useMusicLists({});
  const { data: streamData } = useMusicStreamSubscription({ workshopId });
  const { mutate: startStopMusic } = useStartStopMusic();

  useEffect(() => {
    if (musicDetails.selectedMusic?.url) {
      setSelectedMusic(musicDetails.selectedMusic as MusicItem);
    } else {
      setSelectedMusic(musicList?.[0] as MusicItem);
    }
  }, [musicDetails.selectedMusic, musicList]);

  // sets the last timestamp
  useEffect(() => {
    if (selectedMusic?.id && audioRef.current) {
      audioRef.current.currentTime = selectedMusic?.timeStamp || 0;
    }
  }, [selectedMusic]);

  useEffect(() => {
    if (musicStatus === MusicStatus.Play && isUserJoined) {
      void audioRef.current?.play();
    }

    if (musicStatus === MusicStatus.Pause && isUserJoined) {
      audioRef.current?.pause();
    }
  }, [isUserJoined, musicStatus]);

  useEffect(() => {
    const status = streamData?.musicStream?.musicData?.status;
    if (status) {
      setMusicStatus(status as MusicStatus);
    }
  }, [streamData?.musicStream?.musicData?.status]);

  const playMusic = () => {
    startStopMusic({
      input: {
        musicId: selectedMusic?.id || "",
        workshopId,
        status: MusicStatus.Play,
      },
    });
  };

  const pauseMusic = (id?: string) => {
    startStopMusic({
      input: {
        musicId: id ? id : selectedMusic?.id || "",
        workshopId,
        status: MusicStatus.Pause,
        timeStamp: Math.floor(audioRef.current?.currentTime || 0),
      },
    });
  };

  const handleChangeMusic = (musicItem: MusicItem) => {
    if (musicStatus === MusicStatus.Play && audioRef.current) {
      setSelectedMusic(musicItem);
      pauseMusic(musicItem.id);
    } else {
      setSelectedMusic(musicItem);
    }
  };

  const MusicTitleTooltipContent = () => {
    if (selectedMusic?.title) {
      return (
        <div className="rounded-lg bg-neutral-900 text-white">
          <Caption2>{selectedMusic?.title}</Caption2>
        </div>
      );
    }

    return <></>;
  };

  return (
    <>
      <audio
        src={streamData?.musicStream?.musicData?.url || ""}
        ref={audioRef}
        key={streamData?.musicStream?.musicData?.url || ""}
        loop
      />
      {open && (
        <HStack
          align="center"
          className={`absolute right-1/4 bottom-[8%] h-[52px] w-[350px] gap-[8px] rounded-lg bg-white p-[15px] ${Z_INDEX_LEVELS.BASE_OVERRIDE}`}
        >
          {musicStatus === MusicStatus.Play && (
            <button
              onClick={() => pauseMusic(selectedMusic?.id)}
              className="disabled:hover:cursor-not-allowed"
              disabled={musicList?.length === 0}
            >
              <Pause size={20} color="#292D30" />
            </button>
          )}
          {musicStatus === MusicStatus.Pause && (
            <button
              onClick={playMusic}
              className="disabled:hover:cursor-not-allowed"
              disabled={musicList?.length === 0}
            >
              <Play size={20} color="#292D30" />
            </button>
          )}
          <Dropdown
            button={
              <Menu.Button
                as="button"
                className="mx-2 flex w-[270px] items-center justify-between rounded-sm bg-tint-dark-200 p-[6px]"
              >
                <Tooltip
                  content={MusicTitleTooltipContent}
                  placement="top"
                  offset={12}
                >
                  <span
                    data-testid="alignment-dropdown"
                    className="font-Steradian ml-1 flex items-center text-center text-base font-normal not-italic"
                  >
                    {truncate(selectedMusic?.title || t("shared.selectMusic"), {
                      length: 26,
                      separator: ".",
                    })}
                  </span>
                </Tooltip>
                <span className="mt-1 ml-1">
                  <CaretUp className="mb-1 text-neutral-60" size={18} />
                </span>
              </Menu.Button>
            }
            items={
              <Menu.Items
                className={clsx(
                  "absolute right-0 -top-20 flex max-h-[80px] w-[300px] origin-top-right flex-col overflow-scroll rounded-xl bg-white p-4 text-left shadow-membersOnline focus:outline-none",
                  Z_INDEX_LEVELS.BASE_CONTROL,
                )}
              >
                <VStack space={3}>
                  {musicList?.map((musicItem) => (
                    <Menu.Item key={musicItem?.id}>
                      <button
                        type="button"
                        onClick={() =>
                          handleChangeMusic(musicItem as MusicItem)
                        }
                      >
                        <HStack align="center" space={4}>
                          <HStack
                            justify="center"
                            align="center"
                            className={clsx(
                              "h-[26px] w-[26px] rounded-full",
                              selectedMusic?.title === musicItem.title
                                ? "bg-primary-turquoise-500"
                                : "bg-tint-dark-300",
                            )}
                          >
                            {selectedMusic?.title === musicItem.title && (
                              <Circle size={18} weight="fill" color="#FFFFFF" />
                            )}
                          </HStack>
                          <Paragraph3>
                            {truncate(musicItem?.title || "", {
                              length: 28,
                              separator: ".",
                            })}
                          </Paragraph3>
                        </HStack>
                      </button>
                    </Menu.Item>
                  ))}
                </VStack>
              </Menu.Items>
            }
          />
        </HStack>
      )}
    </>
  );
};

export default MusicPlayer;
