import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import { Avatar, Button, UserPill } from "components/base";
import { HStack, VStack } from "components/layout/Stack";
import { DropdownSelector } from "components/partial";
import { Base2, Base2Strong, Caption1, Display4 } from "components/Typography";
import {
  AccessLevel,
  ProjectInvitation,
  useCreateProjectInvitationsMutation,
  useDeleteProjectInvitationMutation,
  User,
  WorkshopParticipant,
  WorkshopParticipantRole,
} from "graphql/generated";
import { t } from "i18n-js";
import { XIcon } from "icons";
import { noop } from "lodash";
import useCreateWorkshopParticipants from "mutations/workshop/use-create-workshop-participants";
import { CaretDown, EnvelopeSimple } from "phosphor-react";
import { useProject, useProjectMembers } from "queries";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import ParticipantRole from "./ParticipantRole";
import UserOptions from "./UserOptions";

interface AddParticipantsModalProps {
  closeModal: () => void;
  workshopParticipants: WorkshopParticipant[];
  workshopPendingInvitations: ProjectInvitation[];
  projectId: string;
  workshopId: string;
}

const AddParticipantsModal = ({
  closeModal,
  workshopParticipants,
  workshopPendingInvitations,
  projectId,
  workshopId,
}: AddParticipantsModalProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [workshopRole, setWorkshopRole] = useState<WorkshopParticipantRole>(
    WorkshopParticipantRole.Participant,
  );
  const [pendingMembers, setPendingMembers] = useState<Partial<User>[]>([]);
  const [pendingProjectInvites, setPendingProjectInvites] = useState<
    Partial<User>[]
  >([]);
  const [selectableMembers, setSelectableMembers] = useState<
    Array<Record<"user", User>> | undefined
  >();
  const [query, setQuery] = useState<string>("owirhg");

  const queryClient = useQueryClient();
  const { project } = useProject({ id: projectId });
  const { members } = useProjectMembers({ projectId });
  const { mutateAsync: createWorkshopParticipants } =
    useCreateWorkshopParticipants();
  const { mutateAsync: createProjectInvitationsMutateAsync } =
    useCreateProjectInvitationsMutation();
  const { mutateAsync: deleteProjectInvitation } =
    useDeleteProjectInvitationMutation();
  const { user: currentUser } = workshopParticipants.find(
    (participant) => participant.role === "owner",
  ) as WorkshopParticipant;

  // Updates dropdown to ensure list has selectable members
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(() => {
    // email field will be available on non existing user invites only
    const pendingInvites = project?.pendingInvitations?.map((invite) => {
      return {
        firstName: invite?.recipient?.firstName || "",
        lastName: invite?.recipient?.lastName || "",
        fullName: invite?.recipient?.fullName || "",
        id: invite?.recipient?.id || "",
        defaultProjectRole: invite?.recipient?.defaultProjectRole || "",
        email: invite?.recipientEmail || "",
      };
    });

    setSelectableMembers(members);
    setPendingProjectInvites(pendingInvites as Partial<User>[]);
  }, []);

  // Checks and updates dropdown to ensure list of project memebers
  // does not include selected workshopParticipanst
  useEffect(() => {
    // eslint-disable-next-line unicorn/prefer-set-has
    const participantsList = workshopParticipants.map((user) => user.user.id);

    const membersList = members.filter(
      (member) => !participantsList.includes(member.user.id),
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setSelectableMembers(membersList);
  }, [workshopParticipants]);

  // Project memebers that are not the owner and not already selected
  const unselectedUsers = useMemo(
    () =>
      selectableMembers?.filter(
        (member: Record<string, User>) =>
          member.user.id !== currentUser.id &&
          !pendingMembers.includes(member.user),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectableMembers, pendingMembers],
  );

  // Pending project invites that are not already selected & not in workshop participant list
  const unselectedPendingProjectInvites = useMemo(() => {
    // eslint-disable-next-line unicorn/prefer-set-has
    const participantsList = workshopParticipants.map((user) => user.user.id);
    const workshopPendingInvitees = workshopPendingInvitations?.map(
      (invitation) => invitation?.recipient?.id || invitation.recipientEmail,
    );

    return pendingProjectInvites?.filter(
      (invite) =>
        !pendingMembers.includes(invite) &&
        !participantsList.includes(invite?.id || invite.email || "") &&
        !workshopPendingInvitees.includes(invite?.id || invite.email || ""),
    );
  }, [
    pendingMembers,
    pendingProjectInvites,
    workshopParticipants,
    workshopPendingInvitations,
  ]);

  // Filters dropdown list based on user search value and unselectedUsers
  const selectableUsers = useMemo(() => {
    return query === ""
      ? unselectedUsers
      : unselectedUsers?.filter(({ user }) => {
          if (user.fullName)
            return user?.fullName
              .toLowerCase()
              .includes(query?.toLowerCase() || "");
        });
  }, [query, unselectedUsers]);

  const selectablePendingInvites = useMemo(() => {
    return query === ""
      ? unselectedPendingProjectInvites
      : unselectedPendingProjectInvites?.filter((invite) => {
          if (invite?.fullName) {
            return invite?.fullName
              .toLowerCase()
              .includes(query?.toLowerCase() || "");
          }
          if (invite?.email) {
            return invite?.email
              .toLowerCase()
              .includes(query?.toLowerCase() || "");
          }
        });
  }, [query, unselectedPendingProjectInvites]);

  // Adds selected project members to workshop
  const addPendingMembers = async () => {
    const pendingMembersIdsOrEmails = pendingMembers.map(
      (member) => member.id || member.email,
    ) as string[];

    // IDs of users who are already in project
    const projectMembersIds = selectableMembers
      ?.filter((members) => pendingMembersIdsOrEmails.includes(members.user.id))
      .map((member) => member.user.id || "");

    // IDs of users who're invited to project / workshop
    const pendingUsersIds = pendingProjectInvites
      ?.filter((invite) => pendingMembersIdsOrEmails.includes(invite.id || ""))
      ?.map((invite) => invite.id || "");

    // Emails of the users who're invited to project / workshop
    const pendingUsersEmails = pendingProjectInvites
      .filter((invite) =>
        pendingMembersIdsOrEmails.includes(invite.email || ""),
      )
      .map((invite) => invite.email || "");

    try {
      if (projectMembersIds && projectMembersIds?.length > 0) {
        await createWorkshopParticipants({
          role: workshopRole,
          userIds: projectMembersIds,
          workshopId: workshopId,
        });
      }

      if (pendingUsersEmails.length > 0 || pendingUsersIds.length > 0) {
        await createProjectInvitationsMutateAsync(
          {
            projectId,
            input: {
              workshopId,
              inviteeEmails: pendingUsersEmails,
              inviteeIds: pendingUsersIds,
              accessLevel: AccessLevel.ViewAccess,
            },
          },
          {
            onSuccess: () => queryClient.refetchQueries(["WorkshopById"]),
          },
        );
      }
    } catch {
      throw new Error(t("errors.somethingWentWrong"));
    }
  };

  const handleDeleteProjectInvitation = async (inviteId: string) => {
    if (!inviteId) return;

    await deleteProjectInvitation(
      { id: inviteId },
      {
        onSuccess: () => queryClient.refetchQueries(["WorkshopById"]),
      },
    );
  };

  const resetInputValue = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      query && setQuery("");
    }
  };

  return (
    <>
      <div className="p-8 shadow-projectPhaseCard">
        <div className="mb-4 flex flex-row justify-between">
          <Display4>{t("workshopEditor.managePeople.managePeople")}</Display4>
          <button onClick={closeModal}>
            <XIcon />
          </button>
        </div>

        <Combobox
          value={pendingMembers}
          onChange={(users) => {
            const selectedUsers = users.map((user) => user as User);
            setPendingMembers(selectedUsers);
            setQuery("");
          }}
          multiple
        >
          {({ open }) => {
            return (
              <>
                <HStack
                  align="center"
                  justify="between"
                  className="min-h-[48px] rounded-md border-2 border-transparent bg-tint-dark-10 py-2.5 focus-within:border-primary-turquoise-30"
                >
                  <HStack space={1} className="flex w-full flex-wrap pl-4">
                    {pendingMembers?.map((user) => (
                      <UserPill
                        user={user}
                        key={user.id || user.email}
                        isComboboxOption
                        className={clsx(
                          "my-2 mr-1 !bg-neutral-900 text-center",
                          Z_INDEX_LEVELS.MODAL_CONTROL,
                        )}
                        showAvatar={false}
                      />
                    ))}
                    <Combobox.Input
                      ref={inputRef}
                      placeholder={
                        pendingMembers.length === 0
                          ? t("component.createMeeting.inviteGuestsPlaceholder")
                          : ""
                      }
                      className={clsx(
                        "box-border h-full w-full flex-1 self-center rounded-md px-4 text-neutral-90 caret-primary-turquoise-70 placeholder:text-base placeholder:text-tint-dark-50 focus:outline-none sm:bg-transparent md:bg-transparent xl:bg-transparent",
                      )}
                      onChange={({ target }) => {
                        return setQuery((target as { value: string }).value);
                      }}
                      onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
                        const userRecord = selectableUsers?.filter(({ user }) =>
                          user.fullName
                            .toLowerCase()
                            .includes(event.target.value as string),
                        )[0]?.user;
                        if (event.key === "Enter" && userRecord?.id) {
                          event.preventDefault();
                          setPendingMembers((prev) => [...prev, userRecord]);
                        }
                        if (event.key === "Enter" && !userRecord?.id) {
                          event.preventDefault();
                        }
                      }}
                      displayValue={(person: User) => person.fullName}
                    />
                  </HStack>
                  <HStack
                    align="center"
                    space={5}
                    className={clsx("pr-2 pl-6", Z_INDEX_LEVELS.MODAL_CONTROL)}
                  >
                    <DropdownSelector
                      dropdownType="WORKSHOP_LEVELS"
                      currentLevel={workshopRole}
                      onSelect={(workshopLevel) => {
                        setWorkshopRole(
                          workshopLevel as WorkshopParticipantRole,
                        );
                      }}
                      canAssignAdmin={false}
                      buttonContent={
                        <HStack align="center" space={2}>
                          <Base2>
                            {t(`workshopEditor.managePeople.${workshopRole}`)}
                          </Base2>
                          <CaretDown />
                        </HStack>
                      }
                    />
                    <Button
                      onClick={() => {
                        void addPendingMembers();
                        setPendingMembers([]);
                      }}
                      size="small"
                      disabled={pendingMembers.length === 0}
                    >
                      {t("workshopEditor.managePeople.add")}
                    </Button>
                  </HStack>
                </HStack>
                {open && query !== "" ? (
                  <Combobox.Options>
                    <UserOptions
                      users={selectableUsers as Record<"user", User>[]}
                      pendingProjectInvites={selectablePendingInvites}
                      query={query}
                      projectId={projectId}
                      workshopParticipants={workshopParticipants}
                      workshopPendingInvitations={workshopPendingInvitations}
                      resetInputValue={resetInputValue}
                    />
                  </Combobox.Options>
                ) : undefined}
              </>
            );
          }}
        </Combobox>
      </div>

      {/* Facilitators */}
      <div className="mb-2 px-8 pt-8">
        <Base2Strong>
          {t("workshopEditor.managePeople.facilitator")}
        </Base2Strong>
        <Caption1 className="py-2">
          {t("workshopEditor.managePeople.facilitatorDescription")}
        </Caption1>

        <HStack justify="between" align="center" className="mb-2 py-4">
          <HStack space={2}>
            <Avatar user={currentUser} />
            <VStack align="start">
              <Base2>
                {currentUser.fullName} ({t("shared.you")})
              </Base2>
              <Caption1 className="pt-1 text-neutral-50">
                {currentUser.defaultProjectRole}
              </Caption1>
            </VStack>
          </HStack>
          <Base2>{t("workshopEditor.managePeople.owner")}</Base2>
        </HStack>
        {workshopParticipants
          .filter((user) => user.role === "facilitator")
          .map((user) => (
            <div key={user.user.id}>
              <ParticipantRole
                user={user.user}
                role={user.role as string}
                participantId={user.id}
              />
            </div>
          ))}
      </div>

      {/* Participants */}
      <div className="mx-8 border-t pt-4">
        <Base2Strong>
          {t("workshopEditor.managePeople.participant")}
        </Base2Strong>
        <Caption1 className="py-2 pb-2">
          {t("workshopEditor.managePeople.participantDescription")}
        </Caption1>
        {workshopParticipants
          .filter((user) => user.role === "participant")
          .map((user) => (
            <div key={user.user.id}>
              <ParticipantRole
                user={user.user}
                role={user.role as string}
                participantId={user.id}
              />
            </div>
          ))}
        {workshopPendingInvitations.map((invitation) => (
          <HStack justify="between" align="center" className="mb-2 py-4">
            <HStack space={2} align="center">
              {invitation?.recipient ? (
                <>
                  <Avatar user={invitation.recipient} />
                  <VStack align="start">
                    <Base2>{invitation.recipient?.fullName}</Base2>
                    <Caption1 className="pt-1 text-neutral-50">
                      {invitation.recipient?.defaultProjectRole}
                    </Caption1>
                  </VStack>
                </>
              ) : (
                <>
                  <HStack
                    align="center"
                    justify="center"
                    className="h-[40px] w-[40px] rounded-full bg-neutral-100"
                  >
                    <EnvelopeSimple size={24} />
                  </HStack>
                  <VStack align="start">
                    <Base2>{invitation.recipientEmail}</Base2>
                  </VStack>
                </>
              )}
            </HStack>
            <DropdownSelector
              dropdownType="WORKSHOP_PENDING_INVITES_LEVELS"
              onDelete={() => void handleDeleteProjectInvitation(invitation.id)}
              currentLevel={WorkshopParticipantRole.Participant}
              onSelect={noop}
              key={invitation.id}
              canAssignAdmin={false}
              buttonContent={
                <HStack align="center" space={2}>
                  <Base2>
                    {t(
                      `workshopEditor.managePeople.${WorkshopParticipantRole.Participant}`,
                    )}
                  </Base2>
                  <CaretDown />
                </HStack>
              }
            />
          </HStack>
        ))}
      </div>
    </>
  );
};

export default AddParticipantsModal;
