import { useDeleteProfileAnswerMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation deleteProfileAnswer($profile_answer_id: ID!) {
    deleteProfileAnswer(profileAnswerId: $profile_answer_id) {
      id
      answer
    }
  }
`;

function useDeleteProfileAnswer() {
  const queryClient = useQueryClient();
  return useDeleteProfileAnswerMutation({
    onSuccess: () => {
      void queryClient.refetchQueries(["getAnswersForUser"]);
    },
  });
}

export default useDeleteProfileAnswer;
