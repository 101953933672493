/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import "./PieChart.css";

import HoverCard from "components/partial/HoverCard/HoverCard";
import { Caption2 } from "components/Typography";
import { User } from "graphql/generated";
import { useDiscDetailsView } from "queries/use-disc-details";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import DiscSegmentDialog from "./DiscSegmentDialog";
import PieChartModal from "./PieChartModal";

export type SegmentConfigType = {
  segmentType: string;
  segmentUsers: User[];
  anonymousSubmissionsCount: number;
};

function PieChart() {
  const [hoverCard, setHoverCard] = useState(false);
  const { projectId = "" } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { discDetails } = useDiscDetailsView({ projectId });
  const [diskType, setDiskType] = useState<string>("");
  const [diskPercent, setDiskPercent] = useState<number | null | undefined>();
  const [diskDescription, setDiskDescription] = useState<string>("");
  const [AvatarUser, setAvatarUser] = useState<User[]>([]);
  const [pieModalUsers, setPieModalUsers] = useState<[]>();
  const [pieModalType, setPieModalType] = useState<string>("");
  const [pieOpenModal, setPieOpenModal] = useState<boolean>(false);
  const [chartImage, setChartImage] = useState<string>("");
  const [AnonymUserCount, setAnonymUserCount] = useState<
    number | null | undefined
  >();
  const [selectedSegmentConfig, setSelectedSegmentConfig] =
    useState<SegmentConfigType>({
      segmentType: "",
      segmentUsers: [],
      anonymousSubmissionsCount: 0,
    });
  const [isSegmentDialogOpen, setIsSegmentDialogOpen] = useState(false);
  const closePieModal = () => {
    setPieOpenModal(false);
  };

  const closeSegmentDialog = () => setIsSegmentDialogOpen(false);

  function onHover(e: React.MouseEvent) {
    const id = e.currentTarget.id;
    const Type = e.currentTarget.id.indexOf("-");
    const trimType = id.slice(Type + 1);
    setDiskType(trimType);

    const [first, second] = e.currentTarget.className.split(" ");

    if (first === "dominanceSegments") {
      discDetails?.dominanceSegments?.map((res) => {
        if (res.segmentType === trimType) {
          setDiskPercent(res.segmentPercentage);
          setAnonymUserCount(res.anonymousUserCount);
          if (res.users && res.users.length > 0) {
            setAvatarUser(res.users as User[]);
          }

          setDiskDescription("Bold and Task focused");
        }
      });
    }
    if (first === "influenceSegments") {
      discDetails?.influenceSegments?.map((res) => {
        if (res.segmentType === trimType) {
          setDiskPercent(res.segmentPercentage);
          setAnonymUserCount(res.anonymousUserCount);
          if (res.users && res.users.length > 0) {
            setAvatarUser(res.users as User[]);
          }
          setDiskDescription("Bold and People focused");
        }
      });
    }
    if (first === "conscientiousnessSegments") {
      discDetails?.conscientiousnessSegments?.map((res) => {
        if (res.segmentType === trimType) {
          setDiskPercent(res.segmentPercentage);
          setAnonymUserCount(res.anonymousUserCount);
          setDiskDescription("Calm and Task focused");
          if (res.users && res.users.length > 0) {
            setAvatarUser(res.users as User[]);
          }
        }
      });
    }
    if (first === "steadinessSegments") {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const DObj = discDetails?.steadinessSegments?.map((res) => {
        if (res.segmentType === trimType) {
          setDiskPercent(res.segmentPercentage);
          setAnonymUserCount(res.anonymousUserCount);
          setDiskDescription("Calm and People focused");
          if (res.users && res.users.length > 0) {
            setAvatarUser(res.users as User[]);
          }
        }
      });
    }
    setHoverCard(true);
  }

  function removeHover() {
    setHoverCard(false);
    setDiskPercent(undefined);
    setDiskType("");
    setAvatarUser([]);
  }

  function handleClick(e: React.MouseEvent) {
    {
      discDetails.visibleToTeam ? setPieOpenModal(true) : undefined;
    }
    const id = e.currentTarget.id;
    const Type = e.currentTarget.id.indexOf("-");
    const trimType = id.slice(Type + 1);
    setPieModalType(trimType);
    const [first, second] = e.currentTarget.className.split(" ");

    if (first === "dominanceSegments") {
      const DObj = discDetails?.dominanceSegments?.map((res) => {
        if (res.segmentType === trimType) {
          setPieModalUsers(res.users);
          setAnonymUserCount(res.anonymousUserCount);
        }
      });
    }
    if (first === "influenceSegments") {
      const DObj = discDetails?.influenceSegments?.map((res) => {
        if (res.segmentType === trimType) {
          setPieModalUsers(res.users);
          setAnonymUserCount(res.anonymousUserCount);
        }
      });
    }
    if (first === "steadinessSegments") {
      const DObj = discDetails?.steadinessSegments?.map((res) => {
        if (res.segmentType === trimType) {
          setPieModalUsers(res.users);
          setAnonymUserCount(res.anonymousUserCount);
        }
      });
    }
    if (first === "conscientiousnessSegments") {
      const DObj = discDetails?.conscientiousnessSegments?.map((res) => {
        if (res.segmentType === trimType) {
          setPieModalUsers(res.users);
          setAnonymUserCount(res.anonymousUserCount);
        }
      });
    }
  }

  return (
    <>
      <div className="Boundary" style={{ width: "260px", height: "260px" }}>
        <div className="D">D</div>
        <div className="I">I</div>
        <div className="S">S</div>
        <div className="C">C</div>
        <Caption2 className="Bold">Bold</Caption2>
        <Caption2 className="People">People</Caption2>
        <Caption2 className="Calm">Calm</Caption2>
        <Caption2 className="Task">Task</Caption2>

        <ul className="circle same">
          {discDetails?.influenceSegments?.map((value) => {
            return (
              <li>
                <div
                  id={`Type-${value?.segmentType || ""}`}
                  style={{
                    background: `rgb(227, 75, 75,${
                      !!value.segmentPercentage
                        ? value.segmentPercentage / 100 || ""
                        : "0"
                    })`,

                    color: "#E34B4B",
                    fontSize: "16px !important",
                  }}
                  onMouseEnter={(e) => {
                    onHover(e);
                  }}
                  onClick={(e) => {
                    handleClick(e);
                    setSelectedSegmentConfig({
                      segmentType: value.segmentType || "",
                      segmentUsers: (value.users as User[]) || [],
                      anonymousSubmissionsCount: value.anonymousUserCount || 0,
                    });
                    setIsSegmentDialogOpen(true);
                  }}
                  onMouseLeave={removeHover}
                  className="influenceSegments text"
                >
                  <p className="text-normal text-[13px] text-tint-dark-400">
                    {value.segmentType}
                  </p>
                  <p>
                    {!!value.segmentPercentage
                      ? `${Math.ceil(value.segmentPercentage)}%`
                      : ""}
                  </p>
                </div>
              </li>
            );
          })}

          {discDetails?.steadinessSegments?.map((value) => {
            return (
              <li>
                <div
                  id={`Type-${value.segmentType || ""}`}
                  style={{
                    background: `rgb(44, 139, 192,${
                      !!value.segmentPercentage
                        ? value.segmentPercentage / 100 || ""
                        : "0"
                    })`,
                    color: "rgb(44, 139, 192)",
                  }}
                  onMouseEnter={(e) => {
                    onHover(e);
                  }}
                  onClick={(e) => {
                    handleClick(e);
                    setSelectedSegmentConfig({
                      segmentType: value.segmentType || "",
                      segmentUsers: (value.users as User[]) || [],
                      anonymousSubmissionsCount: value.anonymousUserCount || 0,
                    });
                    setIsSegmentDialogOpen(true);
                  }}
                  onMouseLeave={removeHover}
                  className="steadinessSegments text"
                >
                  <p className="text-normal text-[13px] text-tint-dark-400">
                    {value.segmentType}
                  </p>
                  {!!value.segmentPercentage
                    ? `${Math.ceil(value.segmentPercentage)}%`
                    : ""}
                </div>
              </li>
            );
          })}

          {discDetails?.conscientiousnessSegments?.map((value) => {
            return (
              <li>
                <div
                  id={`Type-${value.segmentType || ""}`}
                  style={{
                    background: `rgb(235, 132, 36,${
                      !!value.segmentPercentage
                        ? value.segmentPercentage / 100 || ""
                        : "0"
                    })`,
                    color: "rgb(235, 132, 36)",
                  }}
                  onMouseEnter={(e) => {
                    onHover(e);
                  }}
                  onClick={(e) => {
                    handleClick(e);
                    setSelectedSegmentConfig({
                      segmentType: value.segmentType || "",
                      segmentUsers: (value.users as User[]) || [],
                      anonymousSubmissionsCount: value.anonymousUserCount || 0,
                    });
                    setIsSegmentDialogOpen(true);
                  }}
                  onMouseLeave={removeHover}
                  className="conscientiousnessSegments text"
                >
                  <p className="text-normal text-[13px] text-tint-dark-400">
                    {value.segmentType}
                  </p>
                  {!!value.segmentPercentage
                    ? `${Math.ceil(value.segmentPercentage)}%`
                    : ""}
                </div>
              </li>
            );
          })}

          {discDetails?.dominanceSegments?.map((value) => {
            return (
              <li>
                <div
                  id={`Type-${value.segmentType || ""}`}
                  style={{
                    background: `rgb(15, 178, 86,${
                      !!value.segmentPercentage
                        ? value.segmentPercentage / 100 || ""
                        : "0"
                    })`,
                    color: "rgb(15, 178, 86)",
                  }}
                  onMouseEnter={(e) => {
                    onHover(e);
                  }}
                  onClick={(e) => {
                    handleClick(e);
                    setSelectedSegmentConfig({
                      segmentType: value.segmentType || "",
                      segmentUsers: (value.users as User[]) || [],
                      anonymousSubmissionsCount: value.anonymousUserCount || 0,
                    });
                    setIsSegmentDialogOpen(true);
                  }}
                  onMouseLeave={removeHover}
                  className="dominanceSegments text"
                >
                  <p className="text-normal text-[13px] text-tint-dark-400">
                    {value.segmentType}
                  </p>
                  {!!value.segmentPercentage
                    ? `${Math.ceil(value.segmentPercentage)}%`
                    : ""}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <HoverCard
        open={hoverCard}
        pieType={diskType}
        piePercentage={diskPercent}
        pieDescription={diskDescription}
        pieUsers={AvatarUser}
        AnnonymousUsers={AnonymUserCount}
      />
      <PieChartModal
        open={pieOpenModal}
        pieModalType={pieModalType}
        pieModalUsers={pieModalUsers}
        onCancel={closePieModal}
        chartImage={chartImage}
        AnnonymousUsers={AnonymUserCount}
      />
      <DiscSegmentDialog
        open={isSegmentDialogOpen}
        segmentConfig={selectedSegmentConfig}
        onClose={closeSegmentDialog}
      />
    </>
  );
}

export default PieChart;
