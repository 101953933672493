/* eslint-disable graphql/template-strings */
import { gql } from "@apollo/client";
import { BreakoutRoomSubscription } from "graphql/generated";
import { useQueryClient } from "react-query";

import { useSubscription } from "./use-subscription";

export const BREAKOUT_ROOM_SUBSCRIPTION = gql`
  subscription BreakoutRoom($workshopId: ID!) {
    breakoutRoomsData(workshopId: $workshopId) {
      breakoutRooms {
        title
        id
        isActive
        name
        roomId
        url
        workshopParticipants {
          id
          roomToken
          tokens {
            dailyRoom {
              id
            }
            token
          }
          user {
            fullName
          }
        }
      }
    }
  }
`;

export interface UseBreakoutRoomSubscriptionProps {
  workshopId: string;
}

export const useBreakoutRoomSubscription = ({
  workshopId,
}: UseBreakoutRoomSubscriptionProps) => {
  const queryClient = useQueryClient();

  const output = useSubscription<BreakoutRoomSubscription>(
    BREAKOUT_ROOM_SUBSCRIPTION,
    {
      variables: {
        workshopId: workshopId,
      },
      async onData() {
        await queryClient.invalidateQueries("WorkshopById");
        await queryClient.refetchQueries(["ParticipantsBreakoutsRooms"]);
      },
    },
  );

  return output;
};
