export enum SectionStatus {
  Ready = "ready",
  ActionRequired = "action_required",
  Processing = "processing",
}
export interface SummarySectionType {
  title: string;
  id: string;
  status: SectionStatus | undefined;
}

export interface SummarySectionItemProps extends SummarySectionType {
  isActive?: boolean;
}
