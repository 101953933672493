import { t } from "i18n-js";
import { PulseCheckAverageIcon } from "icons";
import React from "react";

import { HStack, VStack } from "../../layout/Stack";
import { Caption1, Caption2 } from "../../Typography";

function PulseCheckAverage({
  start,
  average,
  end,
  multiplier = 10,
}: {
  start: number;
  average: number;
  end: number;

  multiplier: number;
}): JSX.Element {
  const left = start * 2 * multiplier;
  const right = end * 2 * multiplier;
  const gradLeft = 500 / (end - start);
  const gradRight = (gradLeft * (100 - right)) / 100;

  const avg = average * 2 * multiplier;

  return (
    <VStack className="w-full">
      <HStack className="mb-1 flex w-full justify-start gap-1">
        <PulseCheckAverageIcon />
        <Caption2 className="h-2  font-normal !text-[#7F8489]">
          {t(`component.pulseCheck.pulseCheckAverage`)}
        </Caption2>
      </HStack>
      <HStack className="!mt-1 flex w-full items-center gap-2">
        <div className=" flex w-full flex-col">
          <div className=" flex !w-full justify-start gap-[1px]">
            <div className="h-1 w-full rounded-l-2xl bg-[#d5d7d7]"></div>
            <div className="h-1 w-full bg-[#d5d7d7]"></div>
            <div className="h-1 w-full bg-[#d5d7d7]"></div>
            <div className="h-1 w-full bg-[#d5d7d7]"></div>
            <div className="h-1 w-full rounded-r-2xl bg-[#d5d7d7]"></div>
          </div>
          <div
            style={{
              left: `${left}%`,
              width: `${right - left}%`,
              overflowY: "hidden",
              overflowX: "hidden",
            }}
            className={`relative mt-[-4px] h-1 rounded-r-2xl rounded-l-2xl`}
          >
            <div
              style={{
                right: `-${gradRight}%`,
                width: `${gradLeft}%`,
              }}
              className="pulse-check-average-gradient-bg absolute h-1 rounded-2xl opacity-40"
            ></div>
          </div>

          <div
            style={{
              marginLeft: `calc(${avg}% - 6px)`,
              background: average >= 3 ? "#0FB256" : "#E34B4B",
            }}
            className={`
            mt-[-8px]
             h-3 w-3 rounded-2xl `}
          ></div>
        </div>

        <Caption1
          style={{
            color: average >= 3 ? "#0FB256" : "#E34B4B",
          }}
          className="mt-[-4px] "
        >
          {average}
        </Caption1>
      </HStack>
    </VStack>
  );
}

export default PulseCheckAverage;
