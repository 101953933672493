import "./SmallPieChart.css";

import React from "react";
function SmallPieChartGreen() {
  return (
    <>
      <div className="absolute left-3 top-1 text-base text-white">D</div>
      <div className="test greenpie-4">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
}
export default SmallPieChartGreen;
