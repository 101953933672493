import { Base2, Base2Strong, CenteredPage, Display3 } from "components";
import { Button } from "components/base";
import { FloatingLogo } from "components/partial";
import { useGetEnvsContext } from "contexts";
import { t } from "i18n-js";
import React from "react";
import { useNavigate } from "react-router-dom";

function ProjectUnauthorized() {
  const navigate = useNavigate();
  const { currentUser } = useGetEnvsContext();

  return (
    <CenteredPage className="bg-white">
      <FloatingLogo />
      <div className="flex flex-1 flex-col justify-center py-28">
        <div className="flex w-[27rem] flex-col">
          <Display3 className="text-neutral-90">
            {t("pages.projectUnauthorized.title")}
          </Display3>
        </div>
        <Base2 className="mt-10 mb-12 text-neutral-90">
          {t("pages.projectUnauthorized.description")}
        </Base2>
        <Button variant="secondary" onClick={() => navigate("/")}>
          {t("shared.backToMyProjects")}
        </Button>
        <Base2 className="mt-22 text-neutral-60">
          {t("pages.projectUnauthorized.loggedIn")}{" "}
          <Base2Strong as="span">{currentUser?.email}</Base2Strong>
        </Base2>
      </div>
    </CenteredPage>
  );
}

export default ProjectUnauthorized;
