import {
  ProjectMembersQuery,
  ProjectMembersQueryVariables,
  useProjectMembersQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query ProjectMembers($projectId: ID!) {
    projectById(id: $projectId) {
      projectAssignments {
        user {
          id
          firstName
          lastName
          fullName
          defaultProjectRole
        }
      }
    }
  }
`;

type UseProjectMembersReturnType = {
  members: ProjectMembersQuery["projectById"]["projectAssignments"];
} & UseQueryResult<ProjectMembersQuery, unknown>;

function useProjectMembers(
  variables: ProjectMembersQueryVariables,
): UseProjectMembersReturnType {
  const response = useProjectMembersQuery(variables, {
    suspense: true,
    enabled: !!variables.projectId,
  });

  return {
    members: response.data?.projectById.projectAssignments || [],
    ...response,
  };
}

export { useProjectMembers, UseProjectMembersReturnType };
