import { useRemoveParticipantFromBreakoutRoomMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation removeParticipantFromBreakoutRoom(
    $breakoutDailyRoomId: ID!
    $workshopParticipantId: ID!
  ) {
    removeParticipantFromBreakoutRoom(
      breakoutDailyRoomId: $breakoutDailyRoomId
      workshopParticipantId: $workshopParticipantId
    ) {
      id
      userAssignedToRoom
      dailyRoom {
        id
      }
    }
  }
`;

export function useRemoveParticipantFromBreakoutRoom() {
  const queryClient = useQueryClient();
  return useRemoveParticipantFromBreakoutRoomMutation({
    onSuccess: async () => {
      await queryClient.refetchQueries(["WorkshopById"]);
      await queryClient.refetchQueries(["ParticipantsBreakoutsRooms"]);
    },
  });
}
