import { Logo } from "components/base";
import React from "react";

function FloatingLogo() {
  return (
    <div className="absolute top-10 left-14">
      <Logo size="medium" className="text-neutral-70" />
    </div>
  );
}

export default FloatingLogo;
