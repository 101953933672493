/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import clsx from "clsx";
import Button from "components/base/Button";
import { GeneralProps, HStack, VStack } from "components/layout";
// import PersonalityViewBody from "components/PersonalityViewBody";
import LanguageCohision from "components/pages/TeamView/LanguageCohision";
import PersonalityViewBody from "components/PersonalityViewBody";
import PulseChecksCards from "components/PulseChecksCards";
import {
  Caption1,
  Caption2,
  Heading1,
  Heading2,
  Paragraph1,
  Paragraph3,
} from "components/Typography";
import { useInviteUsers } from "contexts";
import { Project, User } from "graphql/generated";
import { t } from "i18n-js";
import {
  TeamViewInsightsIllustration,
  TeamViewPersonalityProfileIllustration,
} from "icons/illustrations";
import { isEmpty } from "lodash";
import { ArrowRight, Info } from "phosphor-react";
import { useGetTeamPersonalityProfile } from "queries/use-get-team-personality-profile";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { usePersonalityProfileSubscription } from "subscriptions/use-personality-profile";

import { PersonalityProfileInfoModal } from "../UserSettings/components/UserSettingsInsightsContent/components";
import TeamCollaborationMetrics from "./components/CollaborationMetrics";
import PersonalityProfile from "./components/PersonalityProfile";
import { InviteMemberFooter, TeamMembers } from "./components/TeamMembers";

export const TeamSection = React.forwardRef<HTMLDivElement, GeneralProps>(
  ({ children, className, ...props }) => (
    <div
      className={clsx(
        "rounded-lg border border-neutral-5 bg-white p-6",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  ),
);

const user1 = {
  avatarUrl: "https://avatars.githubusercontent.com/u/1234567?v=4",
  fullName: "John Doe",
  firstName: "John",
  lastName: "Doe",
} as User;

const user2 = {
  avatarUrl: undefined,
  fullName: "Jane Doe",
  firstName: "Jane",
  lastName: "Doe",
} as User;

const user3 = {
  avatarUrl: "https://avatars.githubusercontent.com/u/1234567?v=4",
  fullName: "Lorenzo Matterhorn",
  firstName: "Lorenzo",
  lastName: "Matterhorn",
} as User;

const personalityProfile = {
  dominance: {
    value: 0.53,
    users: [user1, user2, user3],
    anonymousUserCount: 2,
  },
  conscientiousness: { value: 0.42, users: [user1], anonymousUserCount: 0 },
  influence: { value: 0.05, users: [], anonymousUserCount: 5 },
  steadiness: { value: 0, users: [], anonymousUserCount: 0 },
};

function TeamView({ project }: { project: Project }): JSX.Element {
  const { isInInviteMode } = useInviteUsers();
  const [personalityProfileModalOpen, setPersonalityProfileModalOpen] =
    useState(false);
  const { projectId = "" } = useParams();

  const requestData = useGetTeamPersonalityProfile({
    personalityProfileInput: { projectId },
  });

  const personalityProfileSubscription = usePersonalityProfileSubscription({
    projectId: projectId,
  });

  const { getPersonalityProfile } = !isEmpty(
    personalityProfileSubscription.getPersonalityProfile,
  )
    ? personalityProfileSubscription
    : requestData;

  const [personalityProfileClick, setHandlePersonalityProfileClick] =
    useState<boolean>(false);

  function handlePersonalityProfileClick() {
    setHandlePersonalityProfileClick(true);
  }

  return (
    <>
      {!personalityProfileClick ? (
        <HStack className="flex w-full justify-center gap-6 px-14 py-4">
          <div className="flex flex-col">
            <TeamSection className="TeamMemberscrollbar h-[713px] w-[450px] overflow-y-scroll">
              <TeamMembers project={project} />
            </TeamSection>

            {isInInviteMode && (
              <div className="shadow-[0px -8px 60px -24px rgba(0, 0, 0, 0.25)] mt-[-10px] rounded-lg border-2 border-transparent bg-gray-50 p-4">
                {" "}
                <InviteMemberFooter />{" "}
              </div>
            )}
          </div>

          <VStack className="gap-6">
            <TeamSection className="relative min-h-[320px] min-w-[800px] overflow-hidden pb-11">
              <div
                style={{
                  position: "absolute",
                  left: "0px",
                  right: "0px",
                  top: "0px",
                  bottom: "0px",
                  background:
                    "linear-gradient(120.58deg, rgba(255, 255, 255, 0) 33.73%, #58EBD2 98.17%)",
                  opacity: "0.25",
                }}
              ></div>
              <Heading1 className="mb-4 text-neutral-900">
                {t("team.teamInsights.title")}
              </Heading1>
              <Paragraph3 className="max-w-[350px] text-neutral-70">
                {t("team.teamInsights.emptyDescription")}
              </Paragraph3>
              <TeamViewInsightsIllustration className="absolute top-0 right-0 bottom-[-10px]" />
            </TeamSection>
            <PulseChecksCards />
            <TeamSection>
              <HStack align="center" className="mb-2">
                <VStack space={2} className="mb-8 w-full">
                  <HStack justify="between">
                    <HStack space={2}>
                      <Heading2 className="text-neutral-900">
                        {t("team.personalityProfile.title")}
                      </Heading2>
                      <button
                        className="!ml-3"
                        onClick={() =>
                          setPersonalityProfileModalOpen(
                            !personalityProfileModalOpen,
                          )
                        }
                      >
                        <Info className="h-5 w-5 text-neutral-700" />
                      </button>
                    </HStack>
                    <Button variant="link" className="!ml-auto">
                      <Paragraph3
                        onClick={handlePersonalityProfileClick}
                        className="inline-block"
                      >
                        {t("team.personalityProfile.details")}
                      </Paragraph3>
                      <ArrowRight className="ml-2 inline-block" />
                    </Button>
                  </HStack>
                  {(getPersonalityProfile?.dominance?.value !== 0 ||
                    getPersonalityProfile?.conscientiousness?.value !== 0 ||
                    getPersonalityProfile?.influence?.value !== 0 ||
                    getPersonalityProfile?.steadiness?.value !== 0) && (
                    <Paragraph1 className="text-neutral-800">
                      {t("team.personalityProfile.description")}
                    </Paragraph1>
                  )}
                </VStack>
              </HStack>
              {getPersonalityProfile?.dominance?.value !== 0 ||
              getPersonalityProfile?.conscientiousness?.value !== 0 ||
              getPersonalityProfile?.influence?.value !== 0 ||
              getPersonalityProfile?.steadiness?.value !== 0 ? (
                <PersonalityProfile
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  personalityProfile={getPersonalityProfile}
                />
              ) : (
                <>
                  <div className="flex flex-col items-center gap-2">
                    <TeamViewPersonalityProfileIllustration />
                    <Caption1>
                      {t("team.personalityProfile.emptyDescriptionTitle")}
                    </Caption1>
                    <Caption2 className="max-w-[250px] text-center text-neutral-70">
                      {t("team.personalityProfile.emptyDescriptionSubtitle")}
                    </Caption2>
                  </div>
                </>
              )}

              <PersonalityProfileInfoModal
                isOpen={personalityProfileModalOpen}
                setIsOpen={(shouldShow) =>
                  setPersonalityProfileModalOpen(shouldShow)
                }
              />
            </TeamSection>
            <TeamSection>
              <TeamCollaborationMetrics />
            </TeamSection>
            <TeamSection className="pb-10">
              <LanguageCohision />
            </TeamSection>
          </VStack>
        </HStack>
      ) : (
        <PersonalityViewBody />
      )}
    </>
  );
}

export default TeamView;
