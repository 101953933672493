import { Caption1, CenteredPage, Display3, VStack } from "components";
import { Button, Input, Logo } from "components/base";
import { useGetEnvsContext } from "contexts";
import { GraphQLError } from "graphql";
import { useResetPasswordMutation } from "graphql/generated";
import { t } from "i18n-js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { VALIDATION_FAILURE } from "utils/constants/graphql_errors";
import { getLastVisitedProjectPage } from "utils/helpers/last-visited-project-page";
import { PASSWORD_REGEX } from "utils/helpers/validations";

type UpdatePasswordFormData = {
  newPassword: string;
  confirmPassword: string;
};

function UpdatePassword() {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { currentUser } = useGetEnvsContext();
  const { mutateAsync } = useResetPasswordMutation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm<UpdatePasswordFormData>({
    mode: "onTouched",
  });
  const [submitError, setSubmitError] = useState<string | undefined>();

  const onSubmit = async ({
    newPassword,
    confirmPassword,
  }: UpdatePasswordFormData) => {
    try {
      const token = searchParams.get("reset_password_token") || "";
      const { resetPassword } = await mutateAsync({
        input: {
          password: newPassword,
          passwordConfirmation: confirmPassword,
          token,
        },
      });
      if (resetPassword) {
        await queryClient.refetchQueries(["CurrentUser"]);
      }
    } catch (error) {
      const errorCode = (error as GraphQLError)?.extensions?.code;

      if (errorCode === VALIDATION_FAILURE) {
        setSubmitError(t("errors.token"));
      } else {
        setSubmitError(t("errors.genericError"));
      }
    }
  };
  useEffect(() => {
    if (currentUser) {
      const lastVisitedProjectPage = getLastVisitedProjectPage(currentUser?.id);
      navigate(lastVisitedProjectPage || "/");
    }
  }, [currentUser, navigate]);
  return (
    <CenteredPage className="bg-neutral-10">
      <div className="absolute top-10 left-14">
        <Logo size="small" />
      </div>
      <VStack className="w-[27rem]">
        <Display3 className="mb-24">{t("pages.updatePassword.title")}</Display3>
        <form onSubmit={handleSubmit(onSubmit) as () => void}>
          {submitError && (
            <Caption1 className="text-center text-secondary-red-50">
              {submitError}
            </Caption1>
          )}
          <VStack space={4}>
            <Input
              type="password"
              aria-label="New Password"
              label={t("shared.newPassword")}
              error={errors.newPassword?.message}
              {...register("newPassword", {
                required: t("errors.presence"),
                pattern: {
                  value: PASSWORD_REGEX,
                  message: t("errors.password"),
                },
              })}
              className="!bg-neutral-5"
            />
            <Input
              type="password"
              aria-label="Confirm Password"
              label={t("shared.confirmPassword")}
              error={errors.confirmPassword?.message}
              {...register("confirmPassword", {
                required: t("errors.presence"),
                validate: {
                  passwordMatch: (value) =>
                    value === getValues().newPassword ||
                    t("errors.passwordConfirmation"),
                },
              })}
              className="!bg-neutral-5"
            />
            <Button type="submit" value="Confirm">
              {t("shared.confirm")}
            </Button>
          </VStack>
        </form>
      </VStack>
    </CenteredPage>
  );
}

export default UpdatePassword;
