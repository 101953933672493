import { useSendBreakoutsRemainingTimeMutation } from "graphql/generated";
import { gql } from "graphql-request";

gql`
  mutation sendBreakoutsRemainingTime(
    $remainingTime: Int!
    $workshopActivityId: ID!
  ) {
    sendBreakoutsRemainingTime(
      remainingTime: $remainingTime
      workshopActivityId: $workshopActivityId
    ) {
      id
    }
  }
`;

export function useSendBreakoutsRemainingTime() {
  return useSendBreakoutsRemainingTimeMutation();
}
