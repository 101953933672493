import clsx from "clsx";
import React from "react";

type StackProps = {
  justify?: JustifyValues;
  align?: AlignValues;
  space?: StackSpace;
  className?: string;
} & React.ComponentPropsWithRef<"div">;

type JustifyValues = "center" | "between" | "start" | "end";
type AlignValues = "center" | "start" | "end" | "baseline";

const justifyValues: Record<JustifyValues, string> = {
  center: "justify-center",
  between: "justify-between",
  start: "justify-start",
  end: "justify-end",
};

const alignValues: Record<AlignValues, string> = {
  center: "items-center",
  baseline: "items-baseline",
  start: "items-start",
  end: "items-end",
};

// Extend if needed https://tailwindcss.com/docs/space
type StackSpace = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 12 | 14 | 16;

// Follwing Tailwinds' recommendation to use complete class names for class detection
// See tailwindcss.com/docs/content-configuration#class-detection-in-depth
const vSpaces: Record<StackSpace, string> = {
  0: "space-y-0",
  1: "space-y-1",
  2: "space-y-2",
  3: "space-y-3",
  4: "space-y-4",
  5: "space-y-5",
  6: "space-y-6",
  7: "space-y-7",
  8: "space-y-8",
  9: "space-y-9",
  10: "space-y-10",
  12: "space-y-12",
  14: "space-y-14",
  16: "space-y-16",
};

const hSpaces: Record<StackSpace, string> = {
  0: "space-x-0",
  1: "space-x-1",
  2: "space-x-2",
  3: "space-x-3",
  4: "space-x-4",
  5: "space-x-5",
  6: "space-x-6",
  7: "space-x-7",
  8: "space-x-8",
  9: "space-x-9",
  10: "space-x-10",
  12: "space-x-12",
  14: "space-x-14",
  16: "space-x-16",
};

function BaseStack({
  dir,
  justify,
  align,
  space = 0,
  className,
  ...props
}: StackProps & { dir: "vertical" | "horizontal" }) {
  const isVertical = dir === "vertical";
  return (
    <div
      className={clsx(
        "flex",
        isVertical ? ["flex-col", vSpaces[space]] : hSpaces[space],
        justify && justifyValues[justify],
        align && alignValues[align],
        className,
      )}
      {...props}
    />
  );
}

export function HStack(props: StackProps) {
  return <BaseStack {...props} dir="horizontal" />;
}

export function VStack(props: StackProps) {
  return <BaseStack {...props} dir="vertical" />;
}
