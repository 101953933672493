import React from "react";

function Completed() {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.019 56.981C8.14278 54.1047 10.0505 48.0661 8.58649 44.5278C7.06887 40.8599 1.5 37.9078 1.5 33.9999C1.5 30.092 7.0689 27.14 8.58651 23.4721C10.0505 19.9338 8.14278 13.8953 11.019 11.019C13.8953 8.14278 19.9339 10.0505 23.4722 8.58649C27.1401 7.06887 30.0922 1.5 34.0001 1.5C37.908 1.5 40.86 7.0689 44.5279 8.58651C48.0662 10.0505 54.1047 8.14278 56.981 11.019C59.8572 13.8953 57.9495 19.9339 59.4135 23.4722C60.9311 27.1401 66.5 30.0922 66.5 34.0001C66.5 37.908 60.9311 40.86 59.4135 44.5279C57.9495 48.0662 59.8572 54.1047 56.981 56.981C54.1047 59.8572 48.0661 57.9495 44.5278 59.4135C40.8599 60.9311 37.9078 66.5 33.9999 66.5C30.092 66.5 27.14 60.9311 23.4721 59.4135C19.9338 57.9495 13.8953 59.8572 11.019 56.981Z"
        stroke="#30CD74"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <svg
        width="30"
        x={20}
        y={24}
        height="20"
        viewBox="0 0 30 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.75 1.5L10.4166 19L1.25 10.25"
          stroke="#30CD74"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </svg>
  );
}

export default Completed;
