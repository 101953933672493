import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { HStack, VStack } from "components";
import { Button, Link } from "components/base";
import { Overlay } from "components/partial";
import { ProjectByIdQuery } from "graphql/generated";
import { t } from "i18n-js";
import { X } from "phosphor-react";
import React, { Fragment } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import { EmbeddedLinks } from "../../CreateInteractions/CreateAnnouncementForm";
import {
  Caption1,
  Heading1,
  Heading3Strong,
  Paragraph1,
} from "../../Typography";

type ProjectGoalModalProps = {
  open: boolean;
  onClose: () => void;
  project: ProjectByIdQuery["projectById"];
};

export default function ProjectGoalModal({
  open,
  onClose,
  project,
}: ProjectGoalModalProps) {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className={clsx("fixed inset-x-0 top-0", Z_INDEX_LEVELS.MODAL)}
        onClose={onClose}
      >
        <Overlay />
        <Transition.Child
          as="div"
          className="absolute top-0 right-0 w-[720px] bg-white"
          enter="ease-out duration-500"
          enterFrom="-right-full"
          leave="ease-in duration-500"
          leaveTo="-right-full"
        >
          <div className="flex h-72 flex-col justify-between bg-[url('../assets/images/Goal_Recoder.png')] bg-cover py-8 px-10">
            <Button
              onClick={onClose}
              variant="secondaryDark"
              className="self-end !px-3 py-3 focus:!outline-0 focus:!ring-0 focus:!ring-offset-0"
              aria-label="Close Modal"
              data-testid="close-project-goal"
            >
              <X className="h-6 w-6 text-white" />
            </Button>
          </div>
          <div className="h-[calc(100vh-18rem)] overflow-y-scroll px-14 py-12">
            <Heading1
              className="mb-12 text-neutral-90"
              data-testid="modal-header"
            >
              {t("shared.projectGoal")}
            </Heading1>
            <Heading3Strong
              className="mb-4 truncate text-neutral-70"
              data-testid="modal-project"
            >
              {project.name}
            </Heading3Strong>
            <Paragraph1
              className={clsx(
                "flex-1 text-neutral-90",
                project?.links ? "mb-8" : "mb-72",
              )}
              data-testid="modal-goal"
            >
              {project.goal}
            </Paragraph1>
            {project?.links?.length ? (
              <VStack className="mb-30" space={4}>
                <Caption1 className="text-neutral-60">
                  {t("shared.attachedLinks")}
                </Caption1>
                <HStack align="center" className="flex-wrap gap-2">
                  {project.links.map((link) => (
                    <Link key={link?.url} metadata={link as EmbeddedLinks} />
                  ))}
                </HStack>
              </VStack>
            ) : (
              <></>
            )}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
