import { Transition } from "@headlessui/react";
import { IconToggle } from "components/base";
import { Caption2 } from "components/Typography";
import { EmojiNames, WorkshopParticipantRole } from "graphql/generated";
import { t } from "i18n-js";
import {
  useCreateEmotionalSignal,
  useUpdateRaiseHandFlag,
} from "mutations/workshop";
import {
  EyeClosed,
  HandPalm,
  Heart,
  Lightbulb,
  ListDashes,
  Question,
  X,
} from "phosphor-react";
import React, { useEffect, useState } from "react";

import { WorkshopAgenda } from "../WorkshopAgenda";
import { WorkshopSidebarComponent } from "./WorkshopSidebar.types";

/**
 * Workshop Sidebar uses absolute positioning.
 * Please ensure it is placed within a `relative` parent.
 */
export const WorkshopSidebar: WorkshopSidebarComponent = ({
  isAgendaOpen: isOpenProp,
  agenda,
  showAgenda,
  user,
  workshopId,
}) => {
  const [isAgendaOpen, setIsAgendaOpen] = useState(isOpenProp || false);
  const [isHandRaised, setIsHandRaised] = useState(user.raiseHand || false);

  const { mutate: updateRaiseHand } = useUpdateRaiseHandFlag();
  const { mutate: createEmotionalSignal } = useCreateEmotionalSignal();

  const handleToggleAgenda = () => {
    setIsAgendaOpen(!isAgendaOpen);
  };

  const handleToggleHand = () => {
    setIsHandRaised(!isHandRaised);

    updateRaiseHand({
      workshopParticipantId: user.id,
    });
  };

  const handleSignalClick = (signal: EmojiNames) => {
    createEmotionalSignal({
      emotionalSignal: {
        workshopParticipantId: user.id,
        emojiName: signal,
        workshopId,
        timeStamp: new Date().toISOString(),
      },
    });
  };

  const handleAgendaCloseFromExternalClick = (e: MouseEvent) => {
    const container = document.querySelector("#workshopAgenda");
    const sidebarButton = document.querySelector("#workshopSidebar");

    if (
      !container?.contains(e.target as Node) &&
      !sidebarButton?.contains(e.target as Node)
    ) {
      setIsAgendaOpen(false);
    }
  };

  useEffect(() => {
    // Track clicks outside of the agenda to enable closing it that way.
    document.addEventListener("mouseup", handleAgendaCloseFromExternalClick);

    return () => {
      document.removeEventListener(
        "mouseup",
        handleAgendaCloseFromExternalClick,
      );
    };
  }, [setIsAgendaOpen]);

  const isParticipant = user.role === WorkshopParticipantRole.Participant;
  const isFacilitatorOrOwner =
    user.role === WorkshopParticipantRole.Facilitator ||
    WorkshopParticipantRole.Facilitator;

  return (
    <div className="absolute left-0 flex h-full items-center">
      <div
        id="workshopSidebar"
        aria-label={t("workshop.sidebar")}
        className=" z-[2] flex h-min flex-col space-y-1 rounded-r-[20px] border border-l-0 border-tint-dark-15 bg-white py-1 pr-1 pl-2 shadow-md"
      >
        {showAgenda && (
          <IconToggle
            ariaLabel={t(
              isAgendaOpen
                ? "workshop.sidebarButtons.closeAgenda"
                : "workshop.sidebarButtons.openAgenda",
            )}
            activeIcon={X}
            inactiveIcon={ListDashes}
            isActive={isAgendaOpen}
            onToggle={handleToggleAgenda}
            TooltipContent={() => (
              <Caption2 className="whitespace-nowrap">
                {t(
                  isAgendaOpen
                    ? "workshop.sidebarButtons.closeAgenda"
                    : "workshop.sidebarButtons.openAgenda",
                )}
              </Caption2>
            )}
          />
        )}
        {(isParticipant || isFacilitatorOrOwner) && (
          <>
            <IconToggle
              ariaLabel={t(
                isHandRaised
                  ? "workshop.sidebarButtons.lowerHand"
                  : "workshop.sidebarButtons.raiseHand",
              )}
              activeIcon={HandPalm}
              isActive={isHandRaised}
              onToggle={handleToggleHand}
              TooltipContent={() => (
                <Caption2 className="whitespace-nowrap">
                  {t(
                    isHandRaised
                      ? "workshop.sidebarButtons.lowerHand"
                      : "workshop.sidebarButtons.raiseHand",
                  )}
                </Caption2>
              )}
            />
            <div className="mt-0 h-px w-full bg-neutral-200" />
            <Caption2 className="!mt-3 text-neutral-400">
              {t("workshop.sidebarButtons.signal")}
            </Caption2>
            <IconToggle
              ariaLabel={`${t("workshop.sidebarButtons.question")} ${t(
                "workshop.sidebarButtons.anonymous",
              )}`}
              activeIcon={Lightbulb}
              onClick={() => handleSignalClick(EmojiNames.Star)}
              className="text-neutral-600 hover:text-secondary-yellow-70"
              TooltipContent={() => (
                <div className="flex gap-1">
                  <Caption2 className="whitespace-nowrap">
                    {t("workshop.sidebarButtons.lightbulb")}
                  </Caption2>
                  <Caption2 className="text-neutral-600">
                    {t("workshop.sidebarButtons.anonymous")}
                  </Caption2>
                </div>
              )}
            />
            <IconToggle
              ariaLabel={`${t("workshop.sidebarButtons.loveThis")} ${t(
                "workshop.sidebarButtons.anonymous",
              )}`}
              activeIcon={Heart}
              onClick={() => handleSignalClick(EmojiNames.Heart)}
              className="text-neutral-600 hover:text-secondary-red-70"
              TooltipContent={() => (
                <div className="flex gap-1">
                  <Caption2 className="whitespace-nowrap">
                    {t("workshop.sidebarButtons.loveThis")}
                  </Caption2>
                  <Caption2 className="text-neutral-600">
                    {t("workshop.sidebarButtons.anonymous")}
                  </Caption2>
                </div>
              )}
            />
            <IconToggle
              ariaLabel={`${t("workshop.sidebarButtons.confused")} ${t(
                "workshop.sidebarButtons.anonymous",
              )}`}
              activeIcon={Question}
              onClick={() => handleSignalClick(EmojiNames.Question)}
              className="text-neutral-600 hover:text-tertiary-blue-70"
              TooltipContent={() => (
                <div className="flex gap-1">
                  <Caption2 className="whitespace-nowrap">
                    {t("workshop.sidebarButtons.confused")}
                  </Caption2>
                  <Caption2 className="text-neutral-600">
                    {t("workshop.sidebarButtons.anonymous")}
                  </Caption2>
                </div>
              )}
            />
            <IconToggle
              ariaLabel={`${t("workshop.sidebarButtons.break")} ${t(
                "workshop.sidebarButtons.anonymous",
              )}`}
              activeIcon={EyeClosed}
              onClick={() => handleSignalClick(EmojiNames.Eye)}
              className="text-neutral-600 hover:text-tertiary-purple-70"
              TooltipContent={() => (
                <div className="flex gap-1">
                  <Caption2 className="whitespace-nowrap">
                    {t("workshop.sidebarButtons.break")}
                  </Caption2>
                  <Caption2 className="text-neutral-600">
                    {t("workshop.sidebarButtons.anonymous")}
                  </Caption2>
                </div>
              )}
            />
          </>
        )}
      </div>

      <Transition
        show={isAgendaOpen}
        enter="transition-all ease-in-out duration-500 transform"
        enterFrom="-translate-x-20 opacity-0"
        enterTo="translate-x-0 opacity-100"
        leave="transition-all ease-in-out duration-500 transform"
        leaveFrom="translate-x-0 opacity-100"
        leaveTo="-translate-x-20 opacity-0"
        className="absolute left-0 bottom-0 top-2 z-[1]"
      >
        <WorkshopAgenda
          agenda={agenda}
          userRole={user.role || WorkshopParticipantRole.Participant}
          className="justify-center bg-white pr-10 pl-20 shadow-2xl"
        />
      </Transition>
    </div>
  );
};
