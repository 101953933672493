import { HStack } from "components/layout/Stack";
import { Caption2 } from "components/Typography";
import React from "react";

type WeekFeedDayCardDStripProps = {
  description: string;
  children: React.ReactNode;
};

export default function WeekFeedDayCardDStrip({
  description,
  children,
}: WeekFeedDayCardDStripProps) {
  return (
    <HStack className="mb-4" align="center">
      {children}
      <Caption2 className="w-[115px] truncate text-neutral-70">
        {description}
      </Caption2>
    </HStack>
  );
}
