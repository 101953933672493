import { Consumer, Subscription } from "@rails/actioncable";
// eslint-disable-next-line unicorn/prefer-node-protocol
import EventEmitter from "events";

export default class SubscriptionChannel<T, K> {
  private emitter: EventEmitter = new EventEmitter();
  private subscription: Subscription<Consumer> | undefined;
  private subscriptionConfirmed = false;

  constructor(consumer: Consumer, channelName: string, params: T) {
    this.initialize(consumer, channelName, params);
  }

  onConnected(cb: () => void) {
    this.subscriptionConfirmed ? cb() : this.emitter.once("connect", cb);
  }

  addReceiver(eventName: string, cb: (data: K) => void) {
    this.emitter.on(eventName, cb);
  }

  perform(action: string, data: object = {}) {
    return this.subscription?.perform(action, data);
  }

  private initialize(consumer: Consumer, channelName: string, params: T) {
    this.subscription = consumer.subscriptions.create(
      {
        channel: channelName,
        ...params,
      },
      {
        connected: () => {
          this.emitter.emit("connect");
          this.subscriptionConfirmed = true;
        },
        disconnected: () => {
          this.emitter.emit("disconnect");
          this.subscriptionConfirmed = false;
        },
        received: ({ type, ...data }: { type: string } & K) => {
          this.emitter.emit(type, data);
        },
      },
    );
  }
}
