import { addMinutes, formatDistance } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const getTimeZone = (): string =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getToday = (): Date =>
  addMinutes(
    utcToZonedTime(new Date(), getTimeZone()),
    utcToZonedTime(new Date(), getTimeZone()).getTimezoneOffset(),
  );

export const formatWithoutTimezone = (date: string): Date =>
  addMinutes(
    utcToZonedTime(new Date(date), getTimeZone()),
    utcToZonedTime(new Date(date), getTimeZone()).getTimezoneOffset(),
  );

export const getTimeDifference = (
  startDate: string | Date,
  endDate: string | Date,
): string => {
  const timePeriod = formatDistance(new Date(startDate), new Date(endDate));
  return `${timePeriod.replace("about", "")} ago`;
};
export const getZonedDate = (date: string | Date): Date => {
  return utcToZonedTime(date, getTimeZone());
};
