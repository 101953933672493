import { Display3, VStack } from "components";
import { Button } from "components/base";
import { HStack } from "components/layout/Stack";
import { useGetEnvsContext } from "contexts";
import { t } from "i18n-js";
import { noop } from "lodash";
import { X } from "phosphor-react";
import React, { useEffect, useState } from "react";

import {
  UserSettingsManualOfMe,
  UserSettingsProfileInfo,
  UserSettingsProfilePhoto,
} from "./components";

type UserSettingsProfileContentProps = {
  onClose?: () => void;
};

export function UserSettingsProfileContent({
  onClose = noop,
}: UserSettingsProfileContentProps) {
  const [DISPLAY_MANUAL_OF_ME, SET_DISPLAY_MANUAL_OF_ME] = useState(
    process.env.SHOW_MANUAL_OF_ME === "true",
  );

  const { envs } = useGetEnvsContext();

  useEffect(() => {
    if (envs) {
      const { SHOW_MANUAL_OF_ME } = envs;
      SET_DISPLAY_MANUAL_OF_ME(SHOW_MANUAL_OF_ME === "true");
    }
  }, [envs]);

  return (
    <VStack className="w-2/3 overflow-y-auto p-10 px-14" space={10}>
      <HStack justify="between">
        <Display3>{t("component.userSettings.tabs.profile.title")}</Display3>

        <Button
          data-testid="settings-menu-close-button"
          onClick={onClose}
          variant="tertiary"
          LeftIcon={X}
        />
      </HStack>

      <VStack className="flex-col-reverse gap-20">
        {DISPLAY_MANUAL_OF_ME && <UserSettingsManualOfMe />}
        <UserSettingsProfileInfo />
        {/* <UserSettingImageUpload /> */}
        <UserSettingsProfilePhoto />
      </VStack>
    </VStack>
  );
}
