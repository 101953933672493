import clsx from "clsx";
import { Display5 } from "components";
import { Button } from "components/base";
import { NavigatorLeftIcon, NavigatorRightIcon } from "icons";
import React, { ReactNode } from "react";

export default function ViewNavigator({
  label,
  title,
  onLeftClick,
  onRightClick,
  className,
}: {
  label?: string;
  title: ReactNode;
  onLeftClick?: () => void;
  onRightClick?: () => void;
  className?: string;
}) {
  return (
    <div
      className={clsx("flex w-[400px] items-center justify-between", className)}
    >
      <Button
        variant="link"
        onClick={onLeftClick}
        className={clsx("border-none", !onLeftClick && "invisible")}
        data-testid="view-navigator-left"
      >
        <NavigatorLeftIcon />
      </Button>
      <div className="flex">
        <Display5 className="truncate p-2 text-neutral-70">{label}</Display5>

        {typeof title === "string" ? (
          <Display5 className="truncate p-2">{title}</Display5>
        ) : (
          title
        )}
      </div>
      <Button
        variant="link"
        onClick={onRightClick}
        className={clsx("border-none", !onRightClick && "invisible")}
        data-testid="view-navigator-right"
      >
        <NavigatorRightIcon />
      </Button>
    </div>
  );
}
