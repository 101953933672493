import { useAnnouncementCommentFlagResetMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation AnnouncementCommentFlagReset($announcementId: ID!) {
    announcementCommentFlagReset(announcementId: $announcementId) {
      id
    }
  }
`;

function useAnnouncementCommentFlagReset() {
  const queryClient = useQueryClient();
  return useAnnouncementCommentFlagResetMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries(["subFilters"]);
    },
  });
}

export default useAnnouncementCommentFlagReset;
