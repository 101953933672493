import { Button } from "components/base";
import { useToast } from "components/provider";
import { t } from "i18n-js";
import { RecoderLogo } from "icons";
import { useWorkshop } from "queries";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { WorkshopMeta } from "../WorkshopMeta";

/**
 * For this header to work the route must include an `:id` for the given workshop.
 */
export const WorkshopSummaryHeader = ({}) => {
  const { id = "" } = useParams();
  const { workshop } = useWorkshop({ id });
  const { addToast } = useToast();
  const navigate = useNavigate();

  const handleGoToProject = () => {
    const projectId = workshop?.projectId;
    if (!projectId) {
      addToast({
        message: t("workshop.header.missingProjectId"),
        variant: "error",
        hasCloseOption: true,
      });
    } else {
      navigate(`/projects/${projectId}`);
    }
  };

  // TODO: https://coeurajtech.atlassian.net/browse/R10-238
  const handleToggleStar = handleGoToProject;

  return (
    <div className="flex flex-row justify-between bg-neutral-0 py-5 px-14">
      <WorkshopMeta workshop={workshop} />

      <div className="flex gap-4">
        <Button
          variant="outline"
          LeftIcon={RecoderLogo}
          onClick={handleGoToProject}
        >
          Go to Project
        </Button>

        {/* <Button variant="outline" RightIcon={Star} onClick={handleToggleStar} /> */}
      </div>
    </div>
  );
};
