import React from "react";

function LogoIconNew(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width={652}
      height={234}
      viewBox="0 0 652 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#FFFFFF"
      {...props}
    >
      <path
        d="M241.787 140.399H234.015C233.678 140.399 233.404 140.125 233.404 139.787V91.6359C233.404 91.2985 233.678 91.0249 234.015 91.0249H241.397C241.734 91.0249 242.008 91.2985 242.008 91.6359V97.9897C242.008 98.5986 242.791 98.8212 243.126 98.3129C246.614 93.0217 251.875 90.3411 257.846 90.3411C258.567 90.3411 259.288 90.3943 259.853 90.4615C260.161 90.4983 260.388 90.7585 260.388 91.0694V98.3562C260.388 98.7168 260.079 99.0024 259.721 98.9639C259.109 98.8982 258.431 98.8467 257.65 98.8467C247.384 98.8467 242.398 106.375 242.398 114.49V139.787C242.398 140.125 242.124 140.399 241.787 140.399V140.399Z"
        fill="currentColor"
      />
      <path
        d="M314.757 124.853C315.18 124.853 315.485 125.279 315.338 125.676C311.801 135.195 302.978 141.572 291.381 141.572C276.422 141.572 265.57 129.937 265.57 115.663C265.57 101.487 276.814 89.8518 291.479 89.8518C306.437 89.8518 316.899 101.095 316.899 115.076V117.594C316.899 117.932 316.625 118.205 316.288 118.205H275.174C274.801 118.205 274.506 118.54 274.562 118.908C275.894 127.633 282.526 133.848 291.479 133.848C298.284 133.848 303.772 130.56 306.565 125.181C306.669 124.981 306.871 124.853 307.097 124.853H314.757V124.853ZM275.765 110.775H307.005C307.408 110.775 307.712 110.384 307.603 109.996C305.347 101.982 298.963 97.5757 291.479 97.5757C283.452 97.5757 277.341 102.684 275.171 109.993C275.055 110.382 275.359 110.775 275.765 110.775V110.775Z"
        fill="currentColor"
      />
      <path
        d="M367.782 110.09C367.521 110.09 367.294 109.921 367.211 109.674C364.947 102.951 358.878 98.1629 351.214 98.1629C341.241 98.1629 333.909 105.985 333.909 115.663C333.909 125.44 341.241 133.262 351.214 133.262C358.972 133.262 365.23 128.193 367.411 121.368C367.492 121.116 367.72 120.943 367.985 120.943H375.666C376.06 120.943 376.361 121.315 376.27 121.699C373.571 133.055 363.936 141.572 351.214 141.572C335.864 141.572 324.914 129.937 324.914 115.663C324.914 101.487 335.864 89.8518 351.214 89.8518C363.836 89.8518 373.56 98.1764 376.176 109.337C376.265 109.72 375.964 110.09 375.571 110.09H367.782Z"
        fill="currentColor"
      />
      <path
        d="M436.66 115.663C436.66 129.937 425.612 141.572 410.36 141.572C395.01 141.572 384.061 129.937 384.061 115.663C384.061 101.487 395.01 89.8518 410.36 89.8518C425.612 89.8518 436.66 101.487 436.66 115.663ZM392.958 115.663C392.958 125.44 400.388 133.262 410.36 133.262C420.333 133.262 427.763 125.44 427.763 115.663C427.763 105.985 420.333 98.1629 410.36 98.1629C400.388 98.1629 392.958 105.985 392.958 115.663Z"
        fill="currentColor"
      />
      <path
        d="M444.773 115.859C444.773 101.095 455.332 89.8517 469.9 89.8517C477.179 89.8517 483.22 92.8269 487.414 97.6361C487.783 98.0591 488.476 97.7957 488.476 97.2345V68.5632C488.476 68.2257 488.749 67.9521 489.087 67.9521H496.86C497.197 67.9521 497.471 68.2257 497.471 68.5632V139.787C497.471 140.125 497.197 140.398 496.86 140.398H489.575C489.238 140.398 488.964 140.125 488.964 139.787V133.495C488.964 132.924 488.253 132.666 487.891 133.107C483.655 138.27 477.332 141.572 469.606 141.572C455.234 141.572 444.773 130.426 444.773 115.859V115.859ZM488.769 115.761C488.769 105.985 481.534 98.0649 471.463 98.0649C461.297 98.0649 454.061 106.081 454.061 115.761C454.061 125.342 461.297 133.36 471.463 133.36C481.534 133.36 488.769 125.44 488.769 115.761Z"
        fill="currentColor"
      />
      <path
        d="M558.583 124.853C559.007 124.853 559.312 125.279 559.164 125.676C555.628 135.195 546.804 141.572 535.207 141.572C520.248 141.572 509.396 129.937 509.396 115.663C509.396 101.487 520.64 89.8518 535.305 89.8518C550.263 89.8518 560.725 101.095 560.725 115.076V117.594C560.725 117.932 560.451 118.205 560.114 118.205H519C518.628 118.205 518.332 118.54 518.389 118.908C519.72 127.633 526.353 133.848 535.305 133.848C542.11 133.848 547.598 130.56 550.391 125.181C550.495 124.981 550.697 124.853 550.923 124.853H558.583V124.853ZM519.591 110.775H550.831C551.235 110.775 551.538 110.384 551.429 109.996C549.173 101.982 542.79 97.5757 535.305 97.5757C527.278 97.5757 521.167 102.684 518.997 109.993C518.881 110.382 519.185 110.775 519.591 110.775V110.775Z"
        fill="currentColor"
      />
      <path
        d="M581.033 140.399H573.261C572.924 140.399 572.65 140.125 572.65 139.787V91.6359C572.65 91.2985 572.924 91.0249 573.261 91.0249H580.643C580.98 91.0249 581.254 91.2985 581.254 91.6359V97.9897C581.254 98.5986 582.037 98.8212 582.372 98.3129C585.861 93.0217 591.121 90.3411 597.092 90.3411C597.813 90.3411 598.535 90.3943 599.099 90.4615C599.407 90.4983 599.634 90.7585 599.634 91.0694V98.3562C599.634 98.7168 599.325 99.0024 598.967 98.9639C598.355 98.8982 597.677 98.8467 596.896 98.8467C586.63 98.8467 581.644 106.375 581.644 114.49V139.787C581.644 140.125 581.371 140.399 581.033 140.399V140.399Z"
        fill="currentColor"
      />
      <path
        d="M74.4865 145.866L79.6706 140.682C80.1478 140.205 80.1478 139.431 79.6706 138.954L70.159 129.442C62.5228 121.806 62.5228 109.426 70.159 101.789L89.3112 82.6373C88.8795 74.6066 91.6912 66.4324 97.8121 60.3115L63.6799 94.4437C58.308 99.8156 54.9387 106.971 54.6746 114.563C54.3884 122.788 57.459 130.567 63.2466 136.355L72.7582 145.866C73.2354 146.344 74.0092 146.344 74.4865 145.866H74.4865Z"
        fill="currentColor"
      />
      <path
        d="M147.833 148.553C147.364 139.926 143.18 131.994 136.039 126.621C132.096 123.654 127.412 121.749 122.519 121.11C113.235 119.899 104.28 122.975 97.8124 129.442L87.605 139.649L87.6109 139.655L69.2094 158.056L69.2034 158.05L64.1099 163.144C63.6326 163.621 63.6326 164.395 64.1099 164.872L69.294 170.056C69.7713 170.533 70.545 170.533 71.0223 170.056L83.9853 157.094L84.0011 157.11L90.915 150.197L90.8988 150.181L104.725 136.355C109.572 131.509 116.514 129.518 123.509 131.235C125.268 131.666 126.962 132.371 128.508 133.316C136.107 137.961 139.633 146.549 137.481 155.207C137.057 156.911 136.376 158.553 135.466 160.056C131.458 166.678 124.47 170.235 117.031 169.677C112.243 169.317 107.79 167.073 104.395 163.678L95.235 154.517C94.7577 154.04 93.9839 154.04 93.5067 154.517L88.3215 159.702C87.8442 160.179 87.8442 160.953 88.3215 161.43L97.3817 170.49C102.755 175.862 109.912 179.231 117.506 179.493C125.729 179.776 133.505 176.706 139.29 170.92C144.83 165.38 147.881 158.016 147.881 150.181C147.881 149.635 147.862 149.093 147.833 148.553Z"
        fill="currentColor"
      />
      <path
        d="M153.117 87.9634L153.133 87.9789L160.046 81.0654L160.031 81.0499L172.993 68.088C173.47 67.6108 173.47 66.837 172.993 66.3598L167.808 61.1756C167.331 60.6984 166.558 60.6983 166.08 61.1755L158.963 68.2914L158.97 68.298L140.568 86.6984L140.562 86.6923L132.776 94.4795C125.229 102.028 112.895 102.603 105.135 95.2744C95.5902 86.2606 97.1976 70.0057 109.957 63.4309C110.762 63.0162 111.605 62.6739 112.472 62.412C119.84 60.1845 127.272 62.1186 132.379 67.2239L141.89 76.7361C142.367 77.2133 143.141 77.2134 143.619 76.7361L148.804 71.5514C149.281 71.0741 149.281 70.3003 148.804 69.823L139.722 60.7422C134.349 55.3695 127.192 52.0011 119.598 51.7392C111.375 51.4556 103.599 54.526 97.8135 60.3115C91.6926 66.4324 88.8809 74.6067 89.3126 82.6373C89.6873 89.61 92.4988 96.4748 97.8135 101.789C109.25 113.226 127.857 113.225 139.291 101.789L153.117 87.9634Z"
        fill="currentColor"
      />
      <path
        d="M173.426 94.4463L164.366 85.3858C163.889 84.9085 163.115 84.9085 162.638 85.3858L157.453 90.5707C156.976 91.048 156.976 91.8218 157.453 92.299L166.944 101.789C174.581 109.425 174.581 121.806 166.945 129.442L147.834 148.553C147.863 149.093 147.881 149.635 147.881 150.181C147.881 158.016 144.831 165.38 139.291 170.92L173.856 136.355C179.642 130.569 182.712 122.794 182.429 114.571C182.167 106.977 178.799 99.8194 173.426 94.4463V94.4463Z"
        fill="currentColor"
      />
      <path
        d="M57.6436 181.955C61.0183 181.955 63.754 179.219 63.754 175.845C63.754 172.47 61.0183 169.734 57.6436 169.734C54.2689 169.734 51.5332 172.47 51.5332 175.845C51.5332 179.219 54.2689 181.955 57.6436 181.955Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default LogoIconNew;
