import { HStack, VStack } from "components/layout";
import { Modal } from "components/partial";
import { Caption1, Heading2 } from "components/Typography";
import { t } from "i18n-js";
import { noop } from "lodash";
import { useUpdateWorkshop } from "mutations/workshop";
import { useWorkshop } from "queries";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Button from "../Button";

interface ResourceModalProps {
  open: boolean;
  onClose?: () => void;
  fileName: string;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
  resourceId?: string;
  onSaveCallback?: () => void;
  onCancelCallback?: () => void;
}

const ResourceModal = ({
  open,
  onClose = noop,
  fileName,
  setFileName,
  resourceId = "",
  onSaveCallback = noop,
  onCancelCallback = noop,
}: ResourceModalProps) => {
  const [resourceName, setResourceName] = useState("");

  const { id: workshopId } = useParams();
  const { workshop } = useWorkshop({ id: workshopId || "" });
  const { mutate: updateWorkshop } = useUpdateWorkshop();

  useEffect(() => {
    if (resourceId) {
      const resource = workshop?.documents?.filter(
        (doc) => doc.id === resourceId,
      )?.[0];

      if (resource?.filename) {
        setResourceName(resource.filename);
      }
    }
  }, [workshop, resourceId]);

  const handleUpdateResource = () => {
    if (resourceId) {
      updateWorkshop(
        {
          workshop: {
            id: workshopId as string,
            documentsToBeUpdate: [
              {
                id: resourceId,
                filename: resourceName,
              },
            ],
          },
        },
        {
          onSuccess: () => onCancelCallback(),
        },
      );
    }
  };

  const handleDeleteResource = () => {
    if (resourceId) {
      updateWorkshop(
        {
          workshop: {
            id: workshopId as string,
            documentsToBeRemoved: [resourceId],
          },
        },
        {
          onSuccess: () => onCancelCallback(),
        },
      );
    }
  };

  return (
    <Modal
      isFullScreen
      open={open}
      onClose={onClose || noop}
      className="flex w-[560px] flex-col rounded-lg p-[24px]"
    >
      <VStack space={6}>
        <VStack space={2}>
          <Heading2>
            {resourceId
              ? t("component.ResourceDialog.editResources")
              : t("component.ResourceDialog.nameResources")}
          </Heading2>
          <Caption1 className="text-neutral-700">
            {resourceId
              ? t("component.ResourceDialog.editResourcesDesc")
              : t("component.ResourceDialog.nameResourcesDesc")}
          </Caption1>
        </VStack>
        <input
          type="text"
          value={resourceId ? resourceName : fileName}
          onChange={(event) =>
            resourceId
              ? setResourceName(event.target.value)
              : setFileName(event.target.value)
          }
          className="box-border w-full rounded-md border-2 border-transparent bg-tint-dark-200 px-[16px] py-[13px] caret-primary-turquoise-300 focus:border-primary-turquoise-300 focus:outline-none"
        />
        <HStack justify="between">
          <Button
            size="small"
            variant="destructive"
            onClick={resourceId ? handleDeleteResource : onCancelCallback}
          >
            {resourceId
              ? t("component.ResourceDialog.delete")
              : t("component.ResourceDialog.cancelUpload")}
          </Button>
          <HStack space={2}>
            {resourceId && (
              <Button
                size="small"
                variant="tertiary"
                onClick={onCancelCallback}
              >
                {t("component.ResourceDialog.cancel")}
              </Button>
            )}
            <Button
              size="small"
              variant="primary"
              onClick={resourceId ? handleUpdateResource : onSaveCallback}
              disabled={!fileName && !resourceName}
            >
              {t("component.ResourceDialog.save")}
            </Button>
          </HStack>
        </HStack>
      </VStack>
    </Modal>
  );
};

export default ResourceModal;
