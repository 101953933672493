/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import clsx from "clsx";
import { HStack, VStack } from "components/layout/Stack";
import { Base2, Caption1 } from "components/Typography";
import { t } from "i18n-js";
import { noop, uniqueId } from "lodash";
import { ArrowRight, CaretDown, Info, WarningCircle } from "phosphor-react";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import Button from "../Button";
import { InputIconProps, InputProps } from "./Input.types";

function SelectIcon({ hasError = false, action = noop }: InputIconProps) {
  return (
    <CaretDown
      className={clsx(
        "cursor-pointer text-neutral-90",
        hasError && "text-secondary-red-70",
      )}
      size={18}
      weight="bold"
      onClick={action}
    />
  );
}

function ErrorIcon() {
  return <WarningCircle className="text-secondary-red-70" size={24} />;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      contentClassName = "",
      inputContainerClassName = "",
      id = uniqueId("input-"),
      name,
      initialCount,
      label = "",
      showCounter = false,
      longCountDescription = false,
      error = "",
      maxLength = 0,
      disabled = false,
      onChange = noop,
      onFocus = noop,
      isSelect = false,
      multiline = false,
      resizable = false,
      expandable = false,
      rows = 3,
      getValues,
      type,
      isComment = false,
      onSubmit = noop,
      onInfoClick,
      errorOnClick,
      errorLinkContent,
      ...props
    },
    ref,
  ) => {
    const initialcount = initialCount ? initialCount : 0;
    const [count, setCount] = useState(0);
    const hasError = error.length > 0;

    useEffect(() => {
      if (initialCount !== null) {
        setCount(initialcount);
      }
    }, [initialCount]);

    useEffect(() => {
      if (hasError) {
        setCount(0);
      }
    }, [hasError]);

    if (showCounter && maxLength === 0) {
      throw new Error(
        "Component Usage Error: missing maxLength at Input Component",
      );
    }

    if (isComment && maxLength === 0) {
      throw new Error("Must add a comment before posting.");
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (showCounter) {
        setCount(e?.target?.value.length);
      }
      onChange(e);
    };

    const computedClasses = useMemo(
      () =>
        clsx(
          "box-border w-full rounded-md border-2 border-transparent bg-tint-dark-10 px-4 text-neutral-90 caret-primary-turquoise-70 transition-colors placeholder:text-base placeholder:text-tint-dark-50 focus:border-primary-turquoise-30 focus:outline-none disabled:cursor-not-allowed disabled:text-tint-dark-50 pr-6",
          multiline ? "py-3 flex-grow h-full" : "h-12",
          multiline && resizable ? "" : "resize-none",
          hasError
            ? "border-secondary-red-50 caret-secondary-red-50 focus:border-secondary-red-50"
            : "",
          hasError || onInfoClick ? "pr-10" : "",
          className,
        ),
      [multiline, resizable, hasError, className, onInfoClick],
    );

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = (visibility: boolean): void =>
      setIsPasswordVisible(!visibility);

    const getType = (type = "") => {
      if (type === "password") {
        return isPasswordVisible ? "text" : "password";
      } else {
        return type;
      }
    };

    const handleSubmitComment = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      setCount(0);
      onSubmit(e);
    };

    return (
      <VStack
        space={2}
        className={clsx(
          disabled && "opacity-60",
          expandable && "flex-grow",
          contentClassName,
        )}
      >
        {(label || showCounter) && (
          <HStack justify={label ? "between" : "end"} align="center">
            {label ? (
              <Base2 as="label" className="text-neutral-90" htmlFor={id}>
                {label}
              </Base2>
            ) : undefined}

            {showCounter ? (
              <Caption1
                className={clsx(
                  "opacity-50",
                  hasError && "text-secondary-red-50",
                )}
              >
                {`${count}/${maxLength}${
                  longCountDescription ? " " + t("shared.characters") : ""
                }`}
              </Caption1>
            ) : undefined}
          </HStack>
        )}

        <div className={clsx("relative flex-grow", inputContainerClassName)}>
          {React.createElement(multiline ? "textarea" : "input", {
            id,
            className: computedClasses,
            onChange: handleChange,
            onFocus: onFocus,
            maxLength: maxLength > 0 ? maxLength : undefined,
            name,
            disabled,
            ref,
            rows,
            type: getType(type),
            ...props,
          })}
          <HStack className={`align-center absolute right-2 top-3 flex`}>
            {isSelect ? (
              <SelectIcon hasError={hasError} action={onFocus} />
            ) : undefined}
            {isComment ? (
              <button
                onClick={(e) => handleSubmitComment(e)}
                data-testid="add-new-comment-button"
                className="mt-6 flex h-6 w-6 cursor-pointer items-center justify-center self-end rounded-full bg-primary-turquoise-10"
              >
                <ArrowRight className="text-neutral-90" size={18} />
              </button>
            ) : undefined}
            {type === "password" ? (
              <>
                <div
                  className={clsx(
                    `align-end absolute inset-y-0 right-0 mb-4 flex justify-center ${
                      hasError && !isSelect ? `mr-4 px-2` : ""
                    }`,
                    Z_INDEX_LEVELS.BASE_CONTROL,
                  )}
                >
                  <input
                    className={`js-password-toggle hidden`}
                    id={`toggle-${id}`}
                    type="checkbox"
                    onClick={() => togglePasswordVisibility(isPasswordVisible)}
                  />
                  <label
                    className="cursor-pointer rounded px-2 text-sm text-neutral-90"
                    htmlFor={`toggle-${id}`}
                  >
                    {isPasswordVisible ? "hide" : "show"}
                  </label>
                </div>
                {hasError && !isSelect ? <ErrorIcon /> : undefined}
              </>
            ) : undefined}
            {hasError && !isSelect && type !== "password" && !isComment ? (
              <ErrorIcon />
            ) : undefined}

            {onInfoClick && !hasError && (
              <button onClick={onInfoClick} type="button">
                <Info size={24} weight="fill" className="text-neutral-500" />
              </button>
            )}
          </HStack>
        </div>

        {hasError ? (
          <div className="flex">
            <Caption1 className="text-secondary-red-50">{error}</Caption1>
            {errorOnClick && errorLinkContent && (
              <Button onClick={errorOnClick} variant="link">
                <Caption1 className="ml-1 text-neutral-900 underline">
                  {errorLinkContent}
                </Caption1>
              </Button>
            )}
          </div>
        ) : undefined}
      </VStack>
    );
  },
);

export { Input as default };
