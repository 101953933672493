import { JSONContent } from "@tiptap/core";
import { RichTextEditor } from "components/base";
import {
  SummaryLayout,
  SummaryLayoutAgendaTab as AgendaTab,
  SummaryLayoutMain as Main,
  SummaryLayoutSummaryTab as SummaryTab,
  SummaryLayoutTabNav as TabNav,
} from "components/layout";
import {
  MissingDataErrorDisplay,
  PageLoader,
  SummaryFooter,
  SummarySidebar,
  WorkshopResources,
  WorkshopSummaryParticipants,
  WorkshopSummaryRecording,
} from "components/partial";
import { useGetEnvsContext } from "contexts";
import { Agenda, WorkshopParticipantRole } from "graphql/generated";
import { useWorkshopParticipant } from "hooks";
import { t } from "i18n-js";
import _ from "lodash";
import { useWorkshop } from "queries";
import { useWorkshopVTT } from "queries/use-workshop-vtt";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { timeZone } from "../WeekView/utils";
import { SummarySection } from "./components";
import {
  MAX_DESC_LENGTH,
  useSummaryEditEffects,
} from "./useSummaryEditEffects";
import {
  DATA_SECTION,
  DESCRIPTION_SECTION,
  NEXT_STEPS_SECTION,
  RECORDING_SECTION,
  RESOURCES_SECTION,
  SUMMARY_SCROLL_CONTAINER_ID,
  SUMMARY_SECTIONS,
  useSummaryScroll,
} from "./useSummaryScroll";
import { validateSections } from "./WorkshopSummaryEdit.util";

/**
 * This page is similar to WorkshopSummary but allows for editing of the Summary contents,
 * and is only intended for use by Workshop Faciliators / Owners.
 */

export const WorkshopSummaryEdit = () => {
  const [isChangeSaving, setIsChangeSaving] = useState(false);
  const [isContentUpdated, setIsContentUpdated] = useState(false);

  const { id = "" } = useParams();
  const { isLoading, isError, workshop } = useWorkshop({ id });
  const { refetch: refetchVTT, getWorkshopVtt } = useWorkshopVTT({
    workshopId: id,
    timeZone,
  });

  const { currentUser } = useGetEnvsContext();
  const { role } = useWorkshopParticipant(workshop, currentUser);
  const [editflag, seteditFlag] = useState(false);

  const isFacilitator =
    role === WorkshopParticipantRole.Facilitator ||
    role === WorkshopParticipantRole.Owner;
  const {
    description,
    handleDescriptionChange,
    summary,
    handleSummaryChange,
    validation,
    isLoading: isSummaryLoading,
  } = useSummaryEditEffects({ workshop, id });

  useEffect(() => {
    setIsChangeSaving(isSummaryLoading);
  }, [isSummaryLoading]);
  useEffect(() => {
    seteditFlag(!editflag);
  }, [description]);

  const validatedSections = validateSections(SUMMARY_SECTIONS, validation);

  const {
    activeSection,
    handleMainScroll,
    handleToNextSection,
    handleToPrevSection,
    descRef,
    nextStepsRef,
    recordingRef,
    dataInsightsRef,
    resourcesRef,
  } = useSummaryScroll(validatedSections);

  const handleWorkshopDescription = _.debounce((value: JSONContent) => {
    handleDescriptionChange(value);
    isContentUpdated === false && setIsContentUpdated(true);
  }, 1000);

  const handleWorkshopSummary = _.debounce((value: JSONContent) => {
    handleSummaryChange(value);
    isContentUpdated === false && setIsContentUpdated(true);
  }, 1000);

  useEffect(() => {
    const intervalId = setInterval(() => {
      void refetchVTT();
      // eslint-disable-next-line unicorn/numeric-separators-style
    }, 270000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <SummaryLayout hasFooter={true}>
      {isLoading && <PageLoader isShowing />}

      {!isLoading && (
        <TabNav isSaving={isChangeSaving} isContentUpdated={isContentUpdated}>
          <SummaryTab>
            <SummarySidebar
              userRole={role}
              sections={validatedSections}
              activeSection={activeSection}
            />
            <Main
              workshop={workshop}
              onScroll={handleMainScroll}
              id={SUMMARY_SCROLL_CONTAINER_ID}
            >
              {isError ? (
                <SummarySection id="summary-load-error">
                  <MissingDataErrorDisplay
                    message={t("workshop.summary.errorMessage")}
                    className="pt-10 pb-20"
                  />
                </SummarySection>
              ) : (
                <>
                  {/* Row with Desciption & People sections */}
                  <div className="flex gap-6">
                    <SummarySection
                      className="max-h-[260px] flex-1"
                      id={DESCRIPTION_SECTION}
                      ref={descRef}
                    >
                      <RichTextEditor
                        label={t(
                          `workshop.summary.section.${DESCRIPTION_SECTION}`,
                        )}
                        content={description}
                        maxCharCount={MAX_DESC_LENGTH}
                        placeholder={t(
                          `workshop.summary.section.${DESCRIPTION_SECTION}`,
                        )}
                        onChange={handleWorkshopDescription}
                        focusState="end"
                      />
                    </SummarySection>

                    <SummarySection className="flex max-h-[260px] basis-70 flex-col">
                      <WorkshopSummaryParticipants workshop={workshop} />
                    </SummarySection>
                  </div>

                  {/* Summary & Next Steps */}
                  <SummarySection ref={nextStepsRef} id={NEXT_STEPS_SECTION}>
                    <RichTextEditor
                      label={t(
                        `workshop.summary.section.${NEXT_STEPS_SECTION}`,
                      )}
                      content={summary}
                      maxCharCount={MAX_DESC_LENGTH}
                      placeholder={t(
                        `workshop.summary.section.${NEXT_STEPS_SECTION}`,
                      )}
                      onChange={handleWorkshopSummary}
                      focusState="end"
                    />
                  </SummarySection>

                  {/* Recording & Transcript */}
                  <SummarySection
                    ref={recordingRef}
                    id={RECORDING_SECTION}
                    disabled={workshop?.recordings.length === 0}
                  >
                    <WorkshopSummaryRecording />
                  </SummarySection>

                  {/* Data Insights */}
                  <SummarySection ref={dataInsightsRef} id={DATA_SECTION}>
                    {t(`workshop.summary.section.${DATA_SECTION}`)}
                  </SummarySection>

                  {/* Resources */}
                  <WorkshopResources
                    workshopId={id}
                    elementId={RESOURCES_SECTION}
                    links={workshop?.links}
                    documents={workshop?.documents}
                    vttLinks={getWorkshopVtt}
                    ref={resourcesRef}
                    isEditable={role && isFacilitator}
                    hasOptionToAdd
                  />
                </>
              )}
            </Main>

            <SummaryFooter
              editflag={editflag}
              activeSection={activeSection}
              validatedSections={validatedSections}
              onNextClick={handleToNextSection}
              onPrevClick={handleToPrevSection}
            />
          </SummaryTab>

          <AgendaTab
            agenda={workshop?.agenda as Agenda[] | undefined}
            userRole={role}
          />
        </TabNav>
      )}
    </SummaryLayout>
  );
};
