import {
  PulseCheckWeekSummaryQuery,
  PulseCheckWeekSummaryQueryVariables,
  usePulseCheckWeekSummaryQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query PulseCheckWeekSummary($phaseId: ID!) {
    pulseCheckWeekSummary(phaseId: $phaseId) {
      average
      highestAverage
      lowestAverage
      weekDate
      phaseId
    }
  }
`;

function usePulseCheckWeekSummary(
  variables: PulseCheckWeekSummaryQueryVariables,
): {
  pulseCheckWeekSummary?: PulseCheckWeekSummaryQuery["pulseCheckWeekSummary"];
} & UseQueryResult<PulseCheckWeekSummaryQuery, unknown> {
  const response = usePulseCheckWeekSummaryQuery(variables);

  return {
    pulseCheckWeekSummary: response?.data?.pulseCheckWeekSummary || [],
    ...response,
  };
}

export { usePulseCheckWeekSummary };
