import { useGetEnvsContext } from "contexts";
import { AccessLevel, Scalars } from "graphql/generated";
import { useProjectAssignments } from "queries";
import { useMemo } from "react";

type useProjectAccessLevelProps = {
  projectId: Scalars["ID"];
};

type useProjectLevel = {
  accessLevel?: AccessLevel;
  hasMemberAccess: boolean;
  hasAdminAccess: boolean;
  hasViewAccess: boolean;
};

export default function useProjectAccessLevel({
  projectId,
}: useProjectAccessLevelProps): useProjectLevel {
  const { currentUser } = useGetEnvsContext();
  const { projectAssignments } = useProjectAssignments({ projectId });
  const accessLevel = useMemo<useProjectLevel>(() => {
    const accessLevel = projectAssignments.find(
      ({ user }) => user.id === currentUser?.id,
    )?.accessLevel;
    return {
      accessLevel,
      hasMemberAccess: accessLevel !== AccessLevel.ViewAccess,
      hasAdminAccess: accessLevel === AccessLevel.AdminAccess,
      hasViewAccess: accessLevel === AccessLevel.ViewAccess,
    };
  }, [currentUser, projectAssignments]);

  return accessLevel;
}
