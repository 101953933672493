import clsx from "clsx";
import { Button, Input, Link } from "components/base";
import { FormInputErrors } from "components/partial";
import { Base2, Caption1 } from "components/Typography";
import {
  Phase,
  useCreatePhaseSummaryMutation,
  useUpdatePhaseSummaryMutation,
} from "graphql/generated";
import { useFetchMetadata } from "hooks";
import { t } from "i18n-js";
import { noop } from "lodash";
import { CircleNotch } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { DeepPartial, SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { validateUrl, whetherLinkExists } from "utils/helpers/validations";

import { EmbeddedLinks } from "./CreateInteractions/CreateAnnouncementForm";

interface AddPhaseSummaryFormInput {
  description?: string;
  links?: string;
}

interface AddPhaseSummaryFormProps {
  className?: string;
  onSubmitSuccess?: () => void;
  onFormCancel?: () => void;
  phase?: DeepPartial<Phase>;
  testProps?: EmbeddedLinks[];
  toggleEditPhaseSummaryModal?: () => void;
}

const DEFAULT_VALUES = {
  description: "",
  links: "",
};

function AddPhaseSummaryForm({
  className,
  onSubmitSuccess = noop,
  onFormCancel = noop,
  phase,
  testProps = [],
  toggleEditPhaseSummaryModal = noop,
}: AddPhaseSummaryFormProps): JSX.Element {
  const queryClient = useQueryClient();
  const [requestError, setRequestError] = useState("");
  const [enteredLink, setEnteredLink] = useState("");
  const [embeddedLinks, setEmbeddedLinks] = useState<EmbeddedLinks[]>(
    testProps.length > 0 ? testProps : [],
  );
  const { mutateAsync } = useCreatePhaseSummaryMutation();
  const { mutateAsync: editPhaseMutateAsync } = useUpdatePhaseSummaryMutation();
  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    getValues,
    setError,
  } = useForm<AddPhaseSummaryFormInput>({
    defaultValues: phase?.phaseSummary
      ? {
          description: phase?.phaseSummary.description,
        }
      : DEFAULT_VALUES,
    mode: "onTouched",
  });

  const { data, isLoading, error } = useFetchMetadata(enteredLink);

  useEffect(() => {
    if (phase?.phaseSummary?.links) {
      setEmbeddedLinks(phase.phaseSummary.links as EmbeddedLinks[]);
    }
  }, [phase]);

  useEffect(() => {
    if (data?.url && whetherLinkExists(embeddedLinks, data?.url) === -1) {
      setEmbeddedLinks((prev) => [
        ...prev,
        {
          url: data.url,
          title: data?.title || data?.domain,
          preview: data?.images[0] || "",
        },
      ]);
      reset({ links: "" });
      setEnteredLink("");
    } else {
      data?.url &&
        setError(
          "links",
          {
            type: "custom",
            message: t("errors.linkAlreadyExists"),
          },
          { shouldFocus: true },
        );
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      switch (error?.message) {
        case "Request failed with status code 404":
          setError(
            "links",
            {
              type: "pattern",
              message: t("errors.invalidLink"),
            },
            { shouldFocus: true },
          );
          setEnteredLink("");
          break;
        default:
          setError(
            "links",
            {
              type: "custom",
              message: t("errors.somethingWentWrong"),
            },
            { shouldFocus: true },
          );
          setEnteredLink("");
      }
    }
  }, [error]);

  const handleEmbedLink = (event: React.FormEvent) => {
    event.preventDefault();
    const url = String(getValues("links"));

    if (validateUrl(url)) {
      setEnteredLink(url);
    } else {
      setError(
        "links",
        {
          type: "pattern",
          message: t("errors.invalidLink"),
        },
        { shouldFocus: true },
      );
    }
  };

  const removeEmbeddedLinks = (event: React.FormEvent, url: string) => {
    event.preventDefault();
    const newEmbeddedLinks = [...embeddedLinks];
    const linkIndex = embeddedLinks.findIndex((links) => links.url === url);
    if (linkIndex > -1) {
      newEmbeddedLinks.splice(linkIndex, 1);
      setEmbeddedLinks(newEmbeddedLinks);
      setEnteredLink("");
    }
  };

  const renderEmbeddedLinks = (list: EmbeddedLinks[]) => {
    return (
      <>
        <Base2 className="mb-2">Links</Base2>
        <div className="align-center flex flex-wrap gap-4">
          {list.map((link) => (
            <Link
              metadata={link}
              key={link.url}
              isRemovable={true}
              onRemove={(event, url = link.url) =>
                removeEmbeddedLinks(event, url)
              }
            />
          ))}
        </div>
      </>
    );
  };

  const clearRequestError = () => {
    if (requestError) {
      setRequestError("");
    }
  };

  const resetForm = () => {
    reset(DEFAULT_VALUES);
  };

  const onSubmit: SubmitHandler<AddPhaseSummaryFormInput> = async ({
    description,
  }: AddPhaseSummaryFormInput) => {
    try {
      const result = await mutateAsync({
        summary: {
          phaseId: phase?.id || "",
          description: description || "",
          links: embeddedLinks,
        },
      });

      if (result?.createPhaseSummary !== null) {
        onSubmitSuccess();
        resetForm();
        clearRequestError();
        await queryClient.refetchQueries(["ProjectById"]);
      }
    } catch {
      setRequestError(t("errors.somethingWentWrong"));
      await queryClient.refetchQueries(["UserAuthorizations"]);
    }
  };

  const onSubmitEdit: SubmitHandler<AddPhaseSummaryFormInput> = async ({
    description,
  }: AddPhaseSummaryFormInput) => {
    try {
      const result = await editPhaseMutateAsync({
        input: {
          id: phase?.phaseSummary?.id || "",
          description: description || "",
          links: embeddedLinks,
        },
      });

      if (result?.updatePhaseSummary?.description !== null) {
        onSubmitSuccess();
        toggleEditPhaseSummaryModal();
        resetForm();
        clearRequestError();
        await queryClient.refetchQueries(["ProjectById"]);
      }
    } catch {
      setRequestError(t("errors.somethingWentWrong"));
      await queryClient.refetchQueries(["UserAuthorizations"]);
    }
  };

  useEffect(() => {
    register("description");
  }, [register]);

  return (
    <>
      <form
        className={clsx(
          "flex h-full w-full flex-col justify-between px-14 py-8 text-left",
          className,
        )}
        onSubmit={
          handleSubmit(
            phase?.phaseSummary ? onSubmitEdit : onSubmit,
          ) as () => void
        }
      >
        <div className="mt-32">
          <div className="flex w-full justify-center">
            <FormInputErrors error={requestError || ""} />
          </div>
          <Input
            multiline
            placeholder="What is the most important takeaway from this phase?"
            label={t("shared.phaseSummary")}
            initialCount={phase?.phaseSummary?.description?.length}
            showCounter
            maxLength={1000}
            rows={7}
            error={errors?.description?.message}
            className="!bg-neutral-5"
            {...register("description", {
              required: t("errors.presence"),
            })}
          />
          <>
            <Input
              placeholder={t("shared.linksPlaceholder")}
              label={t("shared.addKeyLinks")}
              {...register("links")}
              contentClassName="mt-6"
              className="!bg-neutral-5"
              error={errors?.links?.message}
            />
            <Button onClick={handleEmbedLink} className="mt-4 w-full">
              {isLoading ? (
                <CircleNotch
                  size={20}
                  color="#fcfcfc"
                  className="animate-spin"
                />
              ) : (
                t("shared.embedLink")
              )}
            </Button>
            <Caption1 className="mt-4 text-center leading-5 text-neutral-60">
              {t("shared.workWithLinks1")}
              <br />
              {t("shared.workWithLinks2")}
            </Caption1>
            {embeddedLinks.length > 0 && renderEmbeddedLinks(embeddedLinks)}
          </>
        </div>
        <div className="flex justify-between">
          <Button
            variant="outline"
            onClick={() => {
              resetForm();
              onFormCancel();
            }}
          >
            {t("shared.cancel")}
          </Button>

          <Button
            disabled={isSubmitting}
            variant="secondary"
            type="submit"
            className="ml-4"
          >
            {phase?.phaseSummary ? t("shared.saveChanges") : t("shared.create")}
          </Button>
        </div>
      </form>
    </>
  );
}

export default AddPhaseSummaryForm;
