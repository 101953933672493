import { useDeleteAnnouncementMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation DeleteAnnouncement($id: ID!) {
    deleteAnnouncement(id: $id) {
      id
    }
  }
`;

function useDeleteAnnouncement() {
  const queryClient = useQueryClient();
  return useDeleteAnnouncementMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries(["subFilters"]);
      void queryClient.refetchQueries(["subFilters"]);
      void queryClient.invalidateQueries(["Notifications"]);
    },
  });
}

export default useDeleteAnnouncement;
