import { JSONContent } from "@tiptap/core";
import { ReadOnlyRichTextEditor } from "components/base";
import { X } from "phosphor-react";
import React from "react";

const FacilitationNotes = ({
  onClose,
  notes,
}: {
  onClose: () => void;
  notes: JSONContent;
}) => {
  return (
    <div className="relative w-full">
      <button className="absolute top-4 right-4" onClick={onClose}>
        <X size={20} color="#35363E" />
      </button>
      <ReadOnlyRichTextEditor
        readOnlyContent={notes}
        className="max-h-72 w-full overflow-auto bg-neutral-70 py-5 pl-14 pr-12 text-gray-200"
      />
    </div>
  );
};

export default FacilitationNotes;
