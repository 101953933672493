import { CenteredPage, Display3 } from "components";
import { Button, Logo, PhotoUploadButton } from "components/base";
import Compressor from "compressorjs";
import { useUploadUserProfilePictureMutation } from "graphql/generated";
import { t } from "i18n-js";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import uploadToActiveStorage from "utils/helpers/upload-to-active-storage";

type LocationState = {
  projectId?: string;
};

function UploadPhoto() {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const [image, setImage] = useState<File>();
  const { mutateAsync: uploadUserProfilePicture } =
    useUploadUserProfilePictureMutation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleFileChange = (file: File) => {
    new Compressor(file, {
      quality: 0.8,
      maxHeight: 300,
      maxWidth: 300,
      resize: "cover",
      success: (compressedResult: File) => {
        setImage(compressedResult);
      },
    });
  };

  const submitPhoto = async () => {
    setIsSubmitting(true);
    const blob = await uploadToActiveStorage(image as File);
    await uploadUserProfilePicture({ signedBlobId: blob.signed_id });
    setIsSubmitting(false);
    navigateToNextPage();
  };

  const navigateToNextPage = () => {
    if (locationState?.projectId) {
      navigate(`/projects/${locationState?.projectId}`);
    } else {
      navigate("/");
    }
  };

  const handleSkip = () => {
    navigateToNextPage();
  };

  return (
    <CenteredPage className="bg-white">
      <div className="absolute top-10 left-14">
        <Logo size="medium" className="text-neutral-70" />
      </div>
      <div className="flex flex-col items-center justify-center py-10">
        <Display3 className="w-80 text-neutral-90">
          {t("pages.onboarding.uploadPhoto.title")}
        </Display3>
        <div className="mt-10">
          <div className="flex min-w-[432px] flex-col items-center justify-center">
            <p className="w-[288px] text-center">
              {t("pages.onboarding.uploadPhoto.subTitle")}
            </p>
            <PhotoUploadButton
              className="mt-4 mb-12 flex h-[120px] w-[120px] items-center justify-center self-center rounded-full bg-neutral-5"
              onChange={handleFileChange}
              disable={isSubmitting}
              value={image}
            />
            <Button
              variant="secondary"
              className="w-full"
              onClick={submitPhoto as () => void}
              disabled={!image}
            >
              {t("shared.continue")}
            </Button>
            <Button variant="link" className="mt-[30px]" onClick={handleSkip}>
              {t("shared.skipStep")}
            </Button>
          </div>
        </div>
      </div>
    </CenteredPage>
  );
}

export default UploadPhoto;
