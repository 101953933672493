import { VStack } from "components/layout/Stack";
import { Base2, Heading1Strong } from "components/Typography";
import { t } from "i18n-js";
import React from "react";

export function UserSettingsProfileDeleteAccount() {
  return (
    <VStack className="gap-8">
      <Heading1Strong>
        {t(`component.userSettings.tabs.profile.sections.deleteAccount.title`)}
      </Heading1Strong>
      <Base2 className="text-neutral-90">
        {t(
          `component.userSettings.tabs.profile.sections.deleteAccount.subtitle`,
        )}
      </Base2>
    </VStack>
  );
}
