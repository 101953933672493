import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";

type DayCardContextType = {
  selectedDay: string;
  setSelectedDay: Dispatch<SetStateAction<string>>;
};

const DayCardContext = createContext<DayCardContextType | undefined>(undefined);

export function DayCardContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [selectedDay, setSelectedDay] = useState("");

  return (
    <DayCardContext.Provider value={{ selectedDay, setSelectedDay }}>
      {children}
    </DayCardContext.Provider>
  );
}

export function useDayCardContext() {
  const context = React.useContext(DayCardContext);
  if (context === undefined)
    throw new Error(
      "useDayCardContext must be used withing an DayCardContextProvider",
    );
  return context;
}
