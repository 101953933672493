import { Button } from "components/base";
import { Base2, Base2Strong, Display4 } from "components/Typography";
import { useModal } from "hooks";
import { t } from "i18n-js";
import { noop } from "lodash";
import React from "react";

import { DefaultFieldsType } from "../types";

type MilestonesFormModalProps = {
  onSubmitSuccess?: (args?: DefaultFieldsType | undefined) => void;
  isOpen: boolean;
  closeModal: () => void;
};

function MilestonesFormModal({
  onSubmitSuccess = noop,
  isOpen,
  closeModal,
}: MilestonesFormModalProps) {
  const { Modal: ConfirmationModal } = useModal();

  return (
    <ConfirmationModal open={isOpen} onClose={closeModal} className="w-1/4">
      <div className="flex flex-col gap-4 p-8">
        <Display4 className="text-neutral-90">
          {t("page.createProject.step.milestones.modal.title")}
        </Display4>
        <Base2 className="text-neutral-70">
          <Base2Strong as="span">
            {t("page.createProject.step.milestones.modal.subtitleHighlight")}
          </Base2Strong>{" "}
          {t("page.createProject.step.milestones.modal.subtitle")}
        </Base2>
        <div className="mt-6 flex w-full flex-col gap-4">
          <Button onClick={closeModal}>
            {t("page.createProject.step.milestones.modal.declineButton")}
          </Button>
          <Button variant="destructive" onClick={() => onSubmitSuccess()}>
            {t("page.createProject.step.milestones.modal.confirmButton")}
          </Button>
        </div>
      </div>
    </ConfirmationModal>
  );
}

export default MilestonesFormModal;
