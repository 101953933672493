import {
  PulseCheckResponseQuery,
  PulseCheckResponseQueryVariables,
  usePulseCheckResponseQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query PulseCheckResponse($id: ID!) {
    graphPulseCheckResponse(id: $id) {
      week
      lowestRating
      highestRating
      ratingLevel
    }
  }
`;

function usePulseCheckResponseById(
  variables: PulseCheckResponseQueryVariables,
): {
  pulseCheckResponses: PulseCheckResponseQuery["graphPulseCheckResponse"];
} & UseQueryResult<PulseCheckResponseQuery, unknown> {
  const response = usePulseCheckResponseQuery(variables, {
    enabled: !!variables.id,
    suspense: true,
  });

  return {
    pulseCheckResponses: response.data?.graphPulseCheckResponse || [],
    ...response,
  };
}

export { usePulseCheckResponseById };
