import { WordCloudCount } from "graphql/generated";
import React from "react";
import ReactWordcloud, { Optional, Options } from "react-wordcloud";

// Further documentation https://react-wordcloud.netlify.app/
interface WordCloudProps {
  words: WordCloudCount[];
}

const WordCloud = ({ words }: WordCloudProps) => {
  const options: Optional<Options> = {
    colors: [
      "#16C7A7",
      "#2C8BC0",
      "#0FB256",
      "#EB8424",
      "#E34B4B",
      "#2B4FCF",
      "#BE1E44",
      "#3F738C",
      "#8B4BBF",
    ],
    enableTooltip: false,
    fontFamily: "Steradian",
    fontSizes: [16, 48],
    padding: 4,
    rotations: 0,
  };

  return <ReactWordcloud options={options} words={words} />;
};

export const MemoizeWordCloud = React.memo(WordCloud);
