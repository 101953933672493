import { Center } from "components";
import React from "react";

import { CenterProps } from "./Center";

type CenteredPageProps = CenterProps;

function CenteredPage({
  className = "",
  children,
  ...props
}: CenteredPageProps) {
  return (
    <Center
      className={`min-h-screen flex-col text-center ${className}`}
      {...props}
    >
      {children}
    </Center>
  );
}

export default CenteredPage;
