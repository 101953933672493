/* eslint-disable graphql/template-strings */
import { gql } from "@apollo/client";
import { MusicStreamSubscription } from "graphql/generated";
import { useQueryClient } from "react-query";

import { useSubscription } from "./use-subscription";

export const MUSIC_STREAM_SUBSCRIPTION = gql`
  subscription MusicStream($workshopId: ID!) {
    musicStream(workshopId: $workshopId) {
      musicData {
        id
        url
        status
        timeStamp
        title
        isActive
      }
    }
  }
`;

export interface UseMusicStreamSubscriptionProps {
  workshopId: string;
}

export const useMusicStreamSubscription = ({
  workshopId,
}: UseMusicStreamSubscriptionProps) => {
  const queryClient = useQueryClient();

  const output = useSubscription<MusicStreamSubscription>(
    MUSIC_STREAM_SUBSCRIPTION,
    {
      variables: {
        workshopId: workshopId,
      },
      async onData() {
        await queryClient.refetchQueries(["WorkshopMusic"]);
      },
    },
  );

  return output;
};
