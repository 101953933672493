import { useAddActivityLogsMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation AddActivityLogs($workshopActivityId: ID!) {
    addActivityLogs(workshopActivityId: $workshopActivityId) {
      id
      logData
    }
  }
`;

export function useAddActivityLogs() {
  const queryClient = useQueryClient();
  return useAddActivityLogsMutation({
    onSuccess: async () => {
      await queryClient.refetchQueries(["WorkshopById"]);
    },
  });
}
