import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { Base1Strong, Base2, Caption1, HStack, VStack } from "components";
import { Dropdown } from "components/base";
import { AccessLevel } from "graphql/generated";
import { t } from "i18n-js";
import { Check, X } from "phosphor-react";
import React, { ReactNode, useEffect, useRef } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

type AccessLevelText = Record<
  AccessLevel,
  { label: string; description: string; order: number }
>;

export const ACCESS_LEVEL_TEXT: AccessLevelText = {
  [AccessLevel.ViewAccess]: {
    label: t("constant.accessLevel.view.label"),
    description: t("constant.accessLevel.view.description"),
    order: 1,
  },
  [AccessLevel.MemberAccess]: {
    label: t("constant.accessLevel.member.label"),
    description: t("constant.accessLevel.member.description"),
    order: 2,
  },
  [AccessLevel.AdminAccess]: {
    label: t("constant.accessLevel.admin.label"),
    description: t("constant.accessLevel.admin.description"),
    order: 3,
  },
};

type AccessLevelDropdownProps = {
  buttonContent: ReactNode;
  currentLevel: AccessLevel;
  onSelect: (level: AccessLevel) => void;
  onDelete?: () => void;
  canAssignAdmin?: boolean;
  viewOnly?: boolean;
};

const OrderedAccessLevels: AccessLevel[] = Object.values(AccessLevel).sort(
  (a, b) => ACCESS_LEVEL_TEXT[a].order - ACCESS_LEVEL_TEXT[b].order,
);

export default function AccessLevelDropdown({
  buttonContent,
  currentLevel,
  onSelect,
  onDelete,
  canAssignAdmin = false,
  viewOnly = false,
}: AccessLevelDropdownProps) {
  return (
    <Dropdown
      button={
        <Menu.Button data-testid="access-dropdown-button" className="w-full">
          {({ open }) => {
            return (
              <div className={clsx("rounded-lg", open && "bg-transparent")}>
                {buttonContent}
              </div>
            );
          }}
        </Menu.Button>
      }
      items={
        <Menu.Items
          as="span"
          className={clsx(
            "absolute top-full -right-2 rounded-xl bg-white py-9 pl-5 pr-11 shadow-lg",
            Z_INDEX_LEVELS.MODAL_CONTROL,
          )}
          data-testid="access-dropdown"
        >
          <VStack space={7}>
            {OrderedAccessLevels.map((accessLevel) => {
              if (accessLevel === AccessLevel.AdminAccess && !canAssignAdmin)
                return;
              const { label, description } = ACCESS_LEVEL_TEXT[accessLevel];
              return (
                <AccessLevelOption
                  key={accessLevel}
                  isActive={accessLevel === currentLevel}
                  onClick={() => onSelect(accessLevel)}
                  label={label}
                  description={description}
                  disabled={viewOnly}
                />
              );
            })}
            {onDelete ? (
              <Menu.Item as="button" onClick={onDelete} disabled={viewOnly}>
                <HStack
                  className={clsx(
                    viewOnly ? "text-neutral-40" : "text-secondary-red-70",
                  )}
                  align="center"
                  space={3}
                >
                  <X size={24} />
                  <Base2>{t("shared.remove")}</Base2>
                </HStack>
              </Menu.Item>
            ) : undefined}
          </VStack>
        </Menu.Items>
      }
    />
  );
}

type AccessLevelOptionProps = {
  isActive?: boolean;
  onClick: () => void;
  label: string;
  description: string;
  disabled?: boolean;
};

function AccessLevelOption({
  isActive = false,
  onClick,
  label,
  description,
  disabled = false,
}: AccessLevelOptionProps) {
  const dropdownItemRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (dropdownItemRef.current) {
      dropdownItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [dropdownItemRef]);

  return (
    <Menu.Item
      as="button"
      onClick={onClick}
      disabled={disabled}
      ref={dropdownItemRef}
    >
      <HStack space={2}>
        <Check
          size={24}
          className={clsx(isActive ? "text-neutral-90" : "text-transparent")}
        />
        <VStack align="start" space={2}>
          <Base1Strong
            className={clsx(
              "whitespace-nowrap text-neutral-90",
              disabled && label !== t("constant.accessLevel.admin.label")
                ? "!font-normal text-neutral-40"
                : "font-medium",
            )}
            data-testid="access-option-label"
          >
            {label}
          </Base1Strong>
          <Caption1 className="whitespace-nowrap text-neutral-60">
            {description}
          </Caption1>
          {label === t("constant.accessLevel.admin.label") && disabled && (
            <>
              <Caption1 className="w-full text-left text-secondary-red-70">
                {t("shared.changeAccessLevelWarning1")}
              </Caption1>
              <Caption1 className="w-full -translate-y-2 text-left text-secondary-red-70">
                {t("shared.changeAccessLevelWarning2")}
              </Caption1>
            </>
          )}
        </VStack>
      </HStack>
    </Menu.Item>
  );
}
