import { Button } from "components/base";
import { PollPollType } from "graphql/generated";
import { t } from "i18n-js";
import React from "react";

interface PollActivityButtonsProps {
  isDisabled: boolean;
  allowSkip: boolean;
  answerCanBeUpdated: boolean;
  createPollResponse: () => Promise<void>;
  multipleAnswers: boolean;
  pollType: PollPollType;
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>;
  showResults: boolean;
  updatePollResponse: () => Promise<void>;
}

const PollActivityButtons = ({
  isDisabled,
  allowSkip,
  answerCanBeUpdated,
  createPollResponse,
  multipleAnswers,
  pollType,
  setShowResults,
  showResults,
  updatePollResponse,
}: PollActivityButtonsProps) => {
  let submitBtn = "";
  let skipBtn = "";
  let addOrChangeAnswerBtn = "";

  if (!showResults) {
    switch (pollType) {
      case PollPollType.SingleChoice:
        submitBtn = t("pollActivityEditor.pollButtons.submit");
        skipBtn = t("pollActivityEditor.pollButtons.allowSkip");
        break;
      case PollPollType.MultipleChoice:
        submitBtn = t("pollActivityEditor.pollButtons.submitSeeResults");
        skipBtn = t("pollActivityEditor.pollButtons.allowSkip");
        break;
      case PollPollType.WordCloud:
        submitBtn = t("pollActivityEditor.pollButtons.submit");
        skipBtn = t("pollActivityEditor.pollButtons.seeAnswers");
        break;
      case PollPollType.Paragraph:
        submitBtn = t("pollActivityEditor.pollButtons.submitSeeAnswers");
        skipBtn = t("pollActivityEditor.pollButtons.seeAnswers");
        break;
    }
  } else {
    addOrChangeAnswerBtn =
      pollType === PollPollType.SingleChoice ||
      pollType === PollPollType.MultipleChoice
        ? t("pollActivityEditor.pollButtons.changeAnswer")
        : t("pollActivityEditor.pollButtons.addAnswer");
  }

  const handleSubmitButton = () => {
    if (
      pollType === PollPollType.SingleChoice ||
      pollType === PollPollType.MultipleChoice
    ) {
      !answerCanBeUpdated
        ? void createPollResponse()
        : void updatePollResponse();
    } else {
      void createPollResponse();
    }
    setShowResults(true);
  };

  return showResults ? (
    <>
      {(pollType === PollPollType.MultipleChoice ||
        pollType === PollPollType.SingleChoice) && (
        <Button
          variant="tertiary"
          className="w-full"
          onClick={() => setShowResults(false)}
        >
          {addOrChangeAnswerBtn}
        </Button>
      )}
      {multipleAnswers && (
        <Button
          variant="tertiary"
          className="w-full"
          onClick={() => setShowResults(false)}
        >
          {addOrChangeAnswerBtn}
        </Button>
      )}
    </>
  ) : (
    <>
      {allowSkip && (
        <Button
          variant="tertiary"
          className="w-full"
          onClick={() => setShowResults(true)}
        >
          {skipBtn}
        </Button>
      )}
      <Button
        disabled={isDisabled}
        variant="secondaryDark"
        className="w-full"
        onClick={handleSubmitButton}
      >
        {submitBtn}
      </Button>
    </>
  );
};

export default PollActivityButtons;
