import { CenteredPage } from "components";
import { GraphQLError } from "graphql";
import {
  useConfirmEmailMutation,
  useResendEmailConfirmationInstructionsMutation,
} from "graphql/generated";
import { useLogout } from "hooks/mutations";
import { t } from "i18n-js";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  GraphQLValidationErrorDetail,
  VALIDATION_FAILURE,
} from "utils/constants/graphql_errors";
import {
  ALREADY_CONFIRMED,
  CONFIRMATION_PERIOD_EXPIRED,
} from "utils/constants/validation_codes";

function ResendEmailConfirmation({ token }: { token: string }) {
  const { mutateAsync, isLoading } =
    useResendEmailConfirmationInstructionsMutation();
  const [error, setError] = useState<string | undefined>();
  const resendEmailConfirmation = async () => {
    try {
      const { resendEmailConfirmationInstructions } = await mutateAsync({
        token,
      });
      if (resendEmailConfirmationInstructions) {
        alert("E-mail sent!");
        setError(undefined);
      }
    } catch {
      setError(t("errors.genericError"));
    }
  };
  return (
    <>
      {t("errors.auth.confirmationPeriodExpired")}
      <button
        onClick={resendEmailConfirmation as () => void}
        disabled={isLoading}
        className="font-bold text-purple-500"
      >
        Resend the email
      </button>
      {error && <span>{error}</span>}
    </>
  );
}

function EmailConfirmation() {
  const [searchParams] = useSearchParams();
  const { mutateAsync } = useConfirmEmailMutation();
  const navigate = useNavigate();
  const [error, setError] = useState<React.ReactNode | undefined>();
  const { mutate: logout } = useLogout();

  useEffect(() => {
    async function confirmEmail(token: string) {
      try {
        const response = await mutateAsync({ token });
        if (response?.confirmEmail?.isOnboarded) {
          logout({});
          navigate("/login");
        } else {
          navigate("/onboarding");
        }
      } catch (error) {
        const graphQLError = error as GraphQLError;
        const errorCode = graphQLError?.extensions?.code;
        if (errorCode === VALIDATION_FAILURE) {
          const details = graphQLError.extensions
            .details as GraphQLValidationErrorDetail[];

          const periodExpired = details.some(
            (detail) => detail.error === CONFIRMATION_PERIOD_EXPIRED,
          );

          periodExpired && setError(<ResendEmailConfirmation token={token} />);

          const alreadyConfirmed = details.some(
            (detail) => detail.error === ALREADY_CONFIRMED,
          );

          alreadyConfirmed && navigate("/onboarding");
        } else {
          setError(t("errors.genericError"));
        }
      }
    }
    const token = searchParams.get("confirmation_token");
    token ? void confirmEmail(token) : setError(t("errors.genericError"));
  }, [mutateAsync, navigate, searchParams]);

  return (
    <CenteredPage>
      <div className="flex min-h-screen flex-col items-center justify-center">
        {error ? error : "Confirming Email..."}
      </div>
    </CenteredPage>
  );
}

export default EmailConfirmation;
