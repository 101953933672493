import React from "react";

const RecoderIconGray: React.FC<
  JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { size?: number }
> = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.86772 33.2532L9.69884 31.4221C9.86741 31.2536 9.86741 30.9803 9.69884 30.8117L6.33918 27.4521C3.64194 24.7549 3.64194 20.3818 6.3392 17.6847L13.1041 10.9199C12.9516 8.08341 13.9447 5.19618 16.1067 3.0342L4.05064 15.0901C2.1532 16.9875 0.963133 19.5148 0.869828 22.1965C0.768751 25.1016 1.85331 27.8493 3.8976 29.8936L7.25726 33.2532C7.42584 33.4218 7.69915 33.4218 7.86772 33.2532Z"
        fill="#C0C5C9"
      />
      <path
        d="M33.7749 34.2021C33.6092 31.1549 32.1315 28.3532 29.609 26.4554C28.2162 25.4075 26.5619 24.7346 24.8337 24.5091C21.5542 24.0811 18.3913 25.1676 16.1068 27.4521L12.5013 31.0571L12.5034 31.0592L6.00368 37.5588L6.00157 37.5567L4.20247 39.3558C4.03389 39.5244 4.03389 39.7977 4.20247 39.9662L6.03359 41.7973C6.20216 41.9659 6.47546 41.9659 6.64404 41.7974L11.2228 37.2191L11.2284 37.2246L13.6705 34.7827L13.6648 34.7771L18.5484 29.8936C20.2606 28.182 22.7126 27.4788 25.1832 28.0851C25.8047 28.2376 26.4029 28.4866 26.9489 28.8203C29.6331 30.461 30.8787 33.4942 30.1182 36.5523C29.9685 37.1544 29.7281 37.7344 29.4068 38.2653C27.9911 40.6042 25.5227 41.8606 22.895 41.6633C21.2039 41.5364 19.631 40.7438 18.4319 39.5446L15.1964 36.3088C15.0278 36.1402 14.7545 36.1402 14.5859 36.3088L12.7544 38.1401C12.5858 38.3087 12.5858 38.582 12.7544 38.7506L15.9546 41.9505C17.8525 43.8481 20.3806 45.0379 23.063 45.1304C25.9675 45.2306 28.7139 44.1461 30.7575 42.1026C32.7143 40.1458 33.7918 37.5445 33.7918 34.7771C33.7918 34.5845 33.7853 34.3929 33.7749 34.2021Z"
        fill="#C0C5C9"
      />
      <path
        d="M35.6401 12.8012L35.6456 12.8067L38.0876 10.3647L38.0821 10.3592L42.6604 5.78097C42.829 5.6124 42.829 5.33909 42.6604 5.17052L40.8293 3.33941C40.6607 3.17085 40.3874 3.17084 40.2188 3.33939L37.705 5.85278L37.7073 5.85513L31.2076 12.3544L31.2055 12.3522L28.4553 15.1027C25.7896 17.7689 21.4329 17.9721 18.6918 15.3835C15.3205 12.1997 15.8883 6.45831 20.3951 4.136C20.6793 3.98954 20.9773 3.86864 21.2834 3.77612C23.8861 2.98934 26.5111 3.6725 28.3149 5.47574L31.6745 8.83554C31.8431 9.00412 32.1164 9.00413 32.285 8.83557L34.1164 7.00425C34.285 6.83568 34.285 6.56235 34.1164 6.39378L30.9087 3.18632C29.0107 1.28862 26.4827 0.0988769 23.8003 0.00636951C20.8958 -0.0937999 18.1494 0.990698 16.1058 3.03421C13.9438 5.19619 12.9507 8.08341 13.1032 10.9199C13.2355 13.3828 14.2286 15.8075 16.1058 17.6847C20.1454 21.7243 26.7177 21.7238 30.7565 17.6847L35.6401 12.8012Z"
        fill="#C0C5C9"
      />
      <path
        d="M42.8137 15.091L39.6137 11.8908C39.4451 11.7222 39.1718 11.7222 39.0032 11.8908L37.1717 13.7221C37.0031 13.8907 37.0031 14.1641 37.1717 14.3326L40.5242 17.6847C43.2215 20.3817 43.2216 24.7547 40.5244 27.4518L33.774 34.2021C33.7844 34.3929 33.7909 34.5845 33.7909 34.7771C33.7909 37.5445 32.7134 40.1458 30.7566 42.1026L42.9657 29.8936C45.0093 27.8501 46.0938 25.1037 45.9936 22.1992C45.9011 19.517 44.7114 16.9889 42.8137 15.091Z"
        fill="#C0C5C9"
      />
      <path
        d="M2.1583 46C3.35029 46 4.31659 45.0337 4.31659 43.8417C4.31659 42.6498 3.35029 41.6835 2.1583 41.6835C0.966303 41.6835 0 42.6498 0 43.8417C0 45.0337 0.966303 46 2.1583 46Z"
        fill="#C0C5C9"
      />
    </svg>
  );
};

export default RecoderIconGray;
