import { Input } from "components/base";
import { HStack } from "components/layout/Stack";
import { t } from "i18n-js";
import useAddCommentToAnnouncement from "mutations/use-add-comment-announcement";
import useAddCommentToTask from "mutations/use-add-comment-task";
import React, { ChangeEvent, FormEvent, useState } from "react";

type CommentsProps = {
  eventId: string;
  eventType: string;
  hasErrors?: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Comments({
  eventId = "",
  eventType = "",
  hasErrors,
}: CommentsProps) {
  const [newComment, setNewComment] = useState<string>("");
  const { mutateAsync: addCommentToAnnouncement } =
    useAddCommentToAnnouncement();
  const { mutateAsync: addCommentToTask } = useAddCommentToTask();
  const [error, setError] = useState<string>("");

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError("");
    !!hasErrors ? hasErrors(false) : undefined;
    setNewComment(e.target.value);
  };

  const addNewComment = (
    e: FormEvent<HTMLFormElement> | FormEvent<HTMLInputElement>,
  ) => {
    e.preventDefault();
    if (!newComment.trim()) {
      setError("Must add a comment before posting.");
      !!hasErrors ? hasErrors(false) : undefined;
    }
    if (!!newComment.trim()) {
      if (eventType === "announcement") {
        void addCommentToAnnouncement({
          announcementId: eventId,
          comment: newComment,
        });
      } else if (eventType === "task") {
        void addCommentToTask({ taskId: eventId, comment: newComment });
      }
    }
    setNewComment("");
  };

  return (
    <form className="relative w-full" onSubmit={(e) => addNewComment(e)}>
      <HStack
        align="center"
        className="rounded-md"
        data-testid="comment-input-field"
      >
        <div className="w-full pl-4">
          <Input
            placeholder={t(`component.comments.placeholder`)}
            onChange={handleInputChange}
            className="!focus:outline-none !h-[68px] w-full overflow-visible rounded-xl"
            data-testid="add-new-comment"
            showCounter
            longCountDescription
            maxLength={500}
            value={newComment}
            defaultValue={newComment}
            rows={2}
            multiline
            isComment={true}
            onSubmit={(e) => addNewComment(e)}
            autoFocus={true}
            label=" "
            error={error}
          />
        </div>
      </HStack>
    </form>
  );
}
