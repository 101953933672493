/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable unicorn/no-nested-ternary */
import clsx from "clsx";
import { Button } from "components/base";
import ResourceModal from "components/base/FileUploader/ResourceModal";
import { FileItem, LinkItem, VttItem } from "components/base/ResourceItem";
import { HStack } from "components/layout";
import { Base2, Caption1, Heading3 } from "components/Typography";
import { GetWorkshopVttQuery, WorkshopByIdQuery } from "graphql/generated";
import { t } from "i18n-js";
import { noop } from "lodash";
import { useUpdateWorkshop } from "mutations/workshop";
import { Link, X } from "phosphor-react";
import React, { useState } from "react";
import { URL_REGEX_EXTENDED } from "utils";

import Modal from "../Modal";
import {
  AddResource,
  AddResourceInput,
} from "../WorkshopEditorActivityContent/AddResource";

export type WorkshopResourcesProps = Pick<
  WorkshopByIdQuery["workshopById"],
  "links" | "documents"
> & {
  workshopId: string;
  elementId?: string;
  isEditable?: boolean;
  hasOptionToAdd?: boolean;
  hasBorder?: boolean;
  hasTitle?: boolean;
  onRemoveLink?: (linkId: string) => void;
  onRemoveResource?: (linkId: string) => void;
  className?: string;
  vttLinks?: GetWorkshopVttQuery["getWorkshopVtt"];
};

const isJsonFile = (filePath: string) => {
  if (filePath) {
    const filterJson = filePath?.lastIndexOf(".");
    const jsonExtension = filePath.slice(filterJson + 1);
    return jsonExtension == "json" ? true : false;
  } else {
    return false;
  }
};

/**
 * WorkshopResources handles rendering the resources which have been added
 * to a Workshop via either File Embeds or Resource Links.
 * The resource chips are rendered with the LinkItem and FileItem components.
 */
export const WorkshopResources = React.forwardRef<
  HTMLDivElement,
  WorkshopResourcesProps
>(
  (
    {
      workshopId,
      links = [],
      documents = [],
      vttLinks = [],
      elementId,
      isEditable = true,
      hasOptionToAdd = false,
      hasTitle = true,
      hasBorder = true,
      className,
      onRemoveLink,
      onRemoveResource,
    },
    ref,
  ) => {
    const {
      mutate: updateWorkshop,
      isLoading,
      isSuccess,
      isError,
    } = useUpdateWorkshop();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
    const [resourceId, setResourceId] = useState("");

    const shouldShowResources =
      (documents ?? { length: 0 }).length > 0 ||
      (links ?? { length: 0 }).length > 0 ||
      (vttLinks ?? { length: 0 }).length > 0;

    const totalResources = [...documents, ...links, ...vttLinks];

    const handleRemoveLink = (linkId: string) => {
      onRemoveLink?.(linkId);
      updateWorkshop({
        workshop: {
          id: workshopId,
          removeLinkIds: [linkId],
        },
      });
    };

    const handleRemoveDocument = (resourceId: string) => {
      onRemoveResource?.(resourceId);
      updateWorkshop({
        workshop: {
          id: workshopId,
          documentsToBeRemoved: [resourceId],
        },
      });
    };

    const handleEditResource = (resourceId: string) => {
      setIsResourceModalOpen(true);
      setResourceId(resourceId);
    };

    const onCancelCallback = () => {
      setIsResourceModalOpen(false);
      setResourceId("");
    };

    const handleAddResource = (input: AddResourceInput) => {
      if (input.link) {
        const update = { id: workshopId, links: [input.link] };
        updateWorkshop({ workshop: update });
      }

      if (input.file) {
        const update = { id: workshopId, documents: [input.file] };
        updateWorkshop({ workshop: update });
      }
    };
    const [showMore, setShowMore] = useState(false);
    const [showButtonClick, setShowButtonClick] = useState(false);

    function handleDisplay() {
      setShowMore(!showMore);
      setShowButtonClick(true);
    }

    return (
      <>
        <div
          id={elementId}
          ref={ref}
          className={clsx(
            hasBorder ? "rounded-md border border-neutral-5 p-6" : "",
            className,
          )}
        >
          <Heading3 className="mb-6">
            {t("workshopOverview.resourcesTitle")}
          </Heading3>

          {shouldShowResources && isEditable ? (
            <HStack className="flex flex-wrap">
              {links?.map((link, index) => (
                <HStack key={index}>
                  <LinkItem
                    link={link}
                    handleRemoveLink={isEditable ? handleRemoveLink : undefined}
                  />
                </HStack>
              ))}
              {documents?.map((resource, index) => (
                <>
                  {resource?.filename && resource.url && (
                    <HStack key={index}>
                      {isJsonFile(resource?.filename) ? (
                        ""
                      ) : (
                        <FileItem
                          resource={resource}
                          handleRemoveResource={
                            isEditable ? handleRemoveDocument : undefined
                          }
                          handleEditResource={
                            isEditable ? handleEditResource : undefined
                          }
                        />
                      )}
                    </HStack>
                  )}
                </>
              ))}
              {vttLinks?.map((vtt, index) => (
                <HStack key={index}>
                  <VttItem resource={vtt} />
                </HStack>
              ))}
            </HStack>
          ) : shouldShowResources && !isEditable ? (
            <>
              <HStack className="flex flex-wrap">
                {showMore
                  ? totalResources?.map((link, index) => {
                      return (
                        <HStack key={index}>
                          {!!link?.filename &&
                          link?.__typename !== "VttLink" ? (
                            <FileItem
                              resource={link}
                              handleRemoveResource={
                                isEditable ? handleRemoveDocument : undefined
                              }
                              handleEditResource={
                                isEditable ? handleEditResource : undefined
                              }
                            />
                          ) : !link?.filename &&
                            link?.__typename !== "VttLink" ? (
                            <LinkItem
                              link={link}
                              handleRemoveLink={
                                isEditable ? handleRemoveLink : undefined
                              }
                            />
                          ) : (
                            <VttItem resource={link} />
                          )}
                        </HStack>
                      );
                    })
                  : totalResources
                      ?.slice(0, 6)
                      ?.map((link, index) => (
                        <HStack key={index}>
                          {!!link?.filename &&
                          link?.__typename !== "VttLink" ? (
                            <FileItem
                              resource={link}
                              handleRemoveResource={
                                isEditable ? handleRemoveDocument : undefined
                              }
                              handleEditResource={
                                isEditable ? handleEditResource : undefined
                              }
                            />
                          ) : !link?.filename &&
                            link?.__typename !== "VttLink" ? (
                            <LinkItem
                              link={link}
                              handleRemoveLink={
                                isEditable ? handleRemoveLink : undefined
                              }
                            />
                          ) : (
                            <VttItem resource={link} />
                          )}
                        </HStack>
                      ))}
              </HStack>
              {showButtonClick
                ? ""
                : totalResources?.length > 6 && (
                    <HStack className="!inline-block">
                      <Button
                        className="m-4"
                        onClick={handleDisplay}
                        variant="outline"
                      >
                        Show {totalResources?.length - 6} More
                      </Button>
                    </HStack>
                  )}
            </>
          ) : (
            <Caption1 className="text-neutral-60">
              {t("workshopOverview.resourcesDescription")}
            </Caption1>
          )}

          {hasOptionToAdd && (
            <div className="relative top-[3px] mt-6 inline-block">
              <Button
                LeftIcon={Link}
                onClick={() => setIsModalOpen(true)}
                variant="tertiary"
              >
                {t("workshopOverview.resourcesAdd")}
              </Button>

              <Modal
                className="h-min w-120 flex-col gap-4 overflow-scroll rounded-xl"
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                children={
                  <div className="py-8 px-6">
                    <div className="mb-6 flex items-center justify-between">
                      <Base2>{t("workshopOverview.resourcesAdd")}</Base2>
                      <Button
                        variant="link"
                        RightIcon={X}
                        onClick={() => setIsModalOpen(false)}
                      />
                    </div>
                    {/** TODO: replace with whatever results from https://coeurajtech.atlassian.net/browse/R10-307 */}
                    <AddResource
                      onAddResource={handleAddResource}
                      isLoading={isLoading}
                      isError={isError}
                      isSuccess={isSuccess}
                      afterSuccessShow={() => setIsModalOpen(false)}
                      linkValidator={(value) =>
                        URL_REGEX_EXTENDED.test(value)
                          ? true
                          : t("workshop.linkError")
                      }
                    />
                  </div>
                }
              />
            </div>
          )}
        </div>
        <ResourceModal
          fileName={""}
          setFileName={noop}
          open={isResourceModalOpen}
          onClose={onCancelCallback}
          onCancelCallback={onCancelCallback}
          resourceId={resourceId}
        />
      </>
    );
  },
);
