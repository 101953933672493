import { useUpdateTaskMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation UpdateTask($task: UpdateTaskInput!) {
    updateTask(task: $task) {
      id
      description
      createdBy {
        id
        firstName
      }
      assignees {
        id
        firstName
      }
      dueDate
      links {
        url
        title
        extension
        preview
      }
      isCompleted
      isHighlighted
      project {
        id
        name
      }
    }
  }
`;

function useUpdateTask() {
  const queryClient = useQueryClient();
  return useUpdateTaskMutation({
    onSuccess: () => {
      void queryClient.refetchQueries(["subFilters"]);
      void queryClient.invalidateQueries(["subFilters"]);
    },
  });
}

export default useUpdateTask;
