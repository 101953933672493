import {
  OrganizationProjectsQuery,
  Scalars,
  useOrganizationProjectsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

type OrganizationProjectsArguments = { organizationId: Scalars["ID"] };
type OrganizationProjectsQueryResult = {
  projects: OrganizationProjectsQuery["projects"];
} & UseQueryResult<OrganizationProjectsQuery, unknown>;

gql`
  query OrganizationProjects($input: ProjectsQueryInput) {
    projects(input: $input) {
      id
      name
      createdAt
      membersCount
      status
      projectAssignments {
        accessLevel
        user {
          id
          fullName
        }
      }
    }
  }
`;

function useOrganizationProjects({
  organizationId,
}: OrganizationProjectsArguments): OrganizationProjectsQueryResult {
  const response = useOrganizationProjectsQuery(
    { input: { organizationId: organizationId } },
    { suspense: true },
  );

  return { projects: response.data?.projects || [], ...response };
}

export { useOrganizationProjects };
