import React from "react";

type ToolTipProps = {
  message: string;
  children?: React.ReactElement;
};

function ToolTip({ message, children }: ToolTipProps) {
  return (
    <div className="z-10 h-auto w-[300px] rounded-lg bg-neutral-900 pl-3 pr-3 pt-3 pb-3">
      <div className="m-auto h-full w-[276px] text-xs font-normal text-neutral-white">
        {message}
      </div>
    </div>
  );
}
export default ToolTip;
