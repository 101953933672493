import { CenteredPage } from "components";
import { t } from "i18n-js";
import { useUserProjects } from "queries";
import React from "react";
import { Link, useParams } from "react-router-dom";

function Organization() {
  const { organizationId = "" } = useParams();
  const { projects } = useUserProjects();

  return (
    <CenteredPage>
      <h1 className="text-4xl font-bold">
        {t("page.organizationPage.pageTitle")}
      </h1>
      <Link
        className="my-4 flex justify-center rounded-full border border-[#E5E5E5] px-4 py-3 text-base font-bold font-semibold text-black"
        to={`/organizations/${organizationId}/project/new`}
      >
        {t("page.organizationPage.createProjectToOrganization")}
      </Link>

      {projects.length > 0 ? (
        <>
          <h1 className="text-2xl font-bold">
            {t("page.organizationPage.projects")}
          </h1>
          {projects.map((project) => (
            <Link
              key={project.name}
              className="my-4 flex justify-center rounded-full border border-[#E5E5E5] px-4 py-3 text-base font-bold font-semibold text-black"
              to={`/projects/${project.id}`}
            >
              {t("page.organizationPage.accessProject", {
                projectName: project.name,
              })}
            </Link>
          ))}
        </>
      ) : undefined}
    </CenteredPage>
  );
}

export default Organization;
