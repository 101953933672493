import React from "react";

export interface TeamViewInsightsIllustrationProps {
  width?: number;
  className?: string;
}

export const TeamViewInsightsIllustration = ({
  width = 249,
  className,
}: TeamViewInsightsIllustrationProps) => (
  <svg
    width={width}
    height="320"
    viewBox="0 0 249 320"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g filter="url(#filter0_dd_3445_94624)">
      <rect x="28" y="-20" width="373" height="112" rx="5.38628" fill="white" />
      <path
        d="M95.2 18.2976H56.8C54.9329 18.2976 53.1423 19.0395 51.8219 20.3597C50.5017 21.6799 49.76 23.4705 49.76 25.3376V50.9376C49.76 52.8049 50.5017 54.5955 51.8219 55.9157C53.1423 57.236 54.9329 57.9776 56.8 57.9776H61.28L56.3456 62.8801C56.2102 62.9962 56.1292 63.1632 56.1223 63.3416C56.1154 63.5197 56.1833 63.6926 56.3094 63.8189C56.4354 63.9449 56.6083 64.0126 56.7867 64.0057C56.9648 63.9989 57.1321 63.918 57.2479 63.7826L63.0079 58.0226C63.0221 58.002 63.035 57.9805 63.0464 57.9585H88.9664L94.7264 63.7185C94.971 63.8937 95.3048 63.8749 95.5281 63.673C95.7514 63.4714 95.8039 63.1414 95.6543 62.8801L90.7775 58.0033H95.2C97.0671 58.0033 98.8577 57.2616 100.178 55.9414C101.498 54.6212 102.24 52.8306 102.24 50.9633V25.3633C102.247 23.4918 101.508 21.6945 100.187 20.3687C98.866 19.0431 97.0717 18.2977 95.2 18.2977L95.2 18.2976ZM100.96 50.9376C100.96 52.4653 100.353 53.9305 99.2729 55.0107C98.1927 56.0909 96.7277 56.6976 95.2 56.6976H56.8C55.2723 56.6976 53.8073 56.0909 52.7271 55.0107C51.6469 53.9305 51.04 52.4653 51.04 50.9376V25.3376C51.04 23.8101 51.6469 22.3449 52.7271 21.2647C53.8073 20.1845 55.2723 19.5776 56.8 19.5776H95.2C96.7277 19.5776 98.1927 20.1845 99.2729 21.2647C100.353 22.3449 100.96 23.8101 100.96 25.3376V50.9376Z"
        fill="#E5E8EA"
      />
      <path
        d="M59.36 14.4575H92.64C92.9935 14.4575 93.28 14.171 93.28 13.8175C93.28 13.4642 92.9935 13.1775 92.64 13.1775H59.36C59.0064 13.1775 58.72 13.4642 58.72 13.8175C58.72 14.171 59.0064 14.4575 59.36 14.4575Z"
        fill="#E5E8EA"
      />
      <path
        d="M72.16 9.28H79.84C80.1936 9.28 80.48 8.99354 80.48 8.64C80.48 8.28646 80.1936 8 79.84 8H72.16C71.8065 8 71.52 8.28646 71.52 8.64C71.52 8.99354 71.8065 9.28 72.16 9.28Z"
        fill="#E5E8EA"
      />
      <path
        d="M94.56 49.2288H89.5616V39.6288C89.5616 39.2755 89.2749 38.9888 88.9216 38.9888C88.5681 38.9888 88.2816 39.2755 88.2816 39.6288V49.2288H81.8816V42.8288C81.8816 42.4755 81.5949 42.1888 81.2416 42.1888C80.8881 42.1888 80.6016 42.4755 80.6016 42.8288V49.2288H74.2016V35.1488C74.2016 34.7955 73.9149 34.5088 73.5616 34.5088C73.2081 34.5088 72.9216 34.7955 72.9216 35.1488V49.2288H66.4V40.192C66.4 39.8385 66.1136 39.552 65.76 39.552C65.4065 39.552 65.12 39.8385 65.12 40.192V49.2288H59.04V29.3248C59.04 28.9713 58.7536 28.6848 58.4 28.6848C58.0465 28.6848 57.76 28.9713 57.76 29.3248V49.8688C57.76 50.0386 57.8275 50.2013 57.9475 50.3213C58.0675 50.4415 58.2302 50.5088 58.4 50.5088H94.56C94.9136 50.5088 95.2 50.2224 95.2 49.8688C95.2 49.5155 94.9136 49.2288 94.56 49.2288H94.56Z"
        fill="#E5E8EA"
      />
      <rect x="124" y="10" width="240" height="4" rx="2" fill="#F2F3F4" />
      <rect x="124" y="26" width="149" height="4" rx="2" fill="#F2F3F4" />
      <rect x="124" y="42" width="61" height="4" rx="2" fill="#F2F3F4" />
      <rect x="124" y="58" width="118" height="4" rx="2" fill="#F2F3F4" />
      <rect x="28" y="104" width="373" height="112" rx="5.38628" fill="white" />
      <path
        d="M78.5527 135.758C78.3784 135.758 78.2194 135.826 78.0981 135.947C77.9769 136.068 77.9087 136.235 77.9087 136.402V158.144C77.9087 158.5 78.1966 158.788 78.5526 158.788H99.5755C99.924 158.788 100.212 158.515 100.219 158.167L100.227 158.046C100.235 157.849 100.242 157.652 100.242 157.447C100.242 145.493 90.5076 135.765 78.553 135.758L78.5527 135.758ZM98.9465 157.508H79.1965V137.061C90.1436 137.402 98.9465 146.417 98.9465 157.447V157.508V157.508Z"
        fill="#E5E8EA"
      />
      <path
        d="M94.4708 162.614H74.092V141.515C74.092 141.159 73.7966 140.872 73.4405 140.872C61.4859 140.872 51.7588 150.599 51.7588 162.561C51.7588 168.538 54.1452 174.114 58.4784 178.242C62.5389 182.106 67.8496 184.242 73.4405 184.242C85.2055 184.242 94.7284 175.038 95.1071 163.28C95.1148 163.106 95.0466 162.939 94.9253 162.811C94.8116 162.682 94.6449 162.614 94.4707 162.614L94.4708 162.614ZM89.1374 175.629L77.4099 163.902H81.8037L90.9553 173.053C90.4176 173.962 89.804 174.818 89.1373 175.629L89.1374 175.629ZM82.1145 181.038L69.3495 168.273L71.5465 166.076L84.9177 179.447C84.0313 180.046 83.0995 180.583 82.1147 181.038L82.1145 181.038ZM77.4253 182.568L66.2432 171.379L68.4402 169.182L80.8419 181.583C79.7434 182 78.6072 182.333 77.4254 182.568H77.4253ZM72.8041 142.167V162.992L58.9253 176.864C55.1297 173.03 53.0541 167.97 53.0541 162.561C53.0541 151.523 61.857 142.508 72.8041 142.167V142.167ZM62.2207 175.394L69.357 182.53C65.8419 181.818 62.5766 180.181 59.857 177.757L62.2207 175.394ZM71.5012 182.856L63.1374 174.484L65.3269 172.295L75.8419 182.803C75.054 182.894 74.2585 182.946 73.4403 182.946C72.7888 182.946 72.1448 182.916 71.5009 182.856L71.5012 182.856ZM85.9782 178.682L72.4557 165.159L73.7134 163.901H75.5847L88.2813 176.591C87.5693 177.348 86.7965 178.045 85.9783 178.681L85.9782 178.682ZM91.607 171.879L83.6295 163.902H93.7962C93.6069 166.773 92.8341 169.47 91.6069 171.879H91.607Z"
        fill="#E5E8EA"
      />
      <rect x="124" y="134" width="240" height="4" rx="2" fill="#F2F3F4" />
      <rect x="124" y="150" width="60" height="4" rx="2" fill="#F2F3F4" />
      <rect x="124" y="166" width="101" height="4" rx="2" fill="#F2F3F4" />
      <rect x="124" y="182" width="86" height="4" rx="2" fill="#F2F3F4" />
      <rect x="28" y="228" width="373" height="112" rx="5.38628" fill="white" />
      <path
        d="M50.8481 306.678H100.065"
        stroke="#E5E8EA"
        stroke-width="1.78"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M84.668 280.334C84.668 290.46 76.4598 298.668 66.334 298.668C56.2084 298.668 48 290.46 48 280.334C48 270.209 56.2084 262 66.334 262C76.4595 262 84.668 270.208 84.668 280.334Z"
        stroke="#E5E8EA"
        stroke-width="1.78"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M103.18 280.334C103.18 290.459 94.9717 298.668 84.8462 298.668C74.7207 298.668 66.5122 290.459 66.5122 280.334C66.5122 270.208 74.7204 262 84.8462 262C94.9717 262 103.18 270.208 103.18 280.334Z"
        stroke="#E5E8EA"
        stroke-width="1.78"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M76.124 271.879L84.579 280.245"
        stroke="#E5E8EA"
        stroke-width="0.89"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M71.7629 267.429L74.2551 270.01"
        stroke="#E5E8EA"
        stroke-width="0.89"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M66.5122 280.868L79.3281 293.684"
        stroke="#E5E8EA"
        stroke-width="0.89"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M68.3811 273.659L82.888 288.166"
        stroke="#E5E8EA"
        stroke-width="0.89"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect x="124" y="258" width="240" height="4" rx="2" fill="#F2F3F4" />
      <rect x="124" y="274" width="89" height="4" rx="2" fill="#F2F3F4" />
      <rect x="124" y="290" width="141" height="4" rx="2" fill="#F2F3F4" />
      <rect x="124" y="306" width="26" height="4" rx="2" fill="#F2F3F4" />
    </g>
    <defs>
      <filter
        id="filter0_dd_3445_94624"
        x="0"
        y="-28"
        width="429"
        height="416"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="8"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_3445_94624"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="18" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3445_94624"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_3445_94624"
          result="effect2_dropShadow_3445_94624"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_3445_94624"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
