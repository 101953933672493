import {
  TopMomentsOfWorkshopQuery,
  TopMomentsOfWorkshopQueryVariables,
  useTopMomentsOfWorkshopQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query TopMomentsOfWorkshop(
    $workshopId: ID!
    $recordingId: String!
    $dailyRoomId: ID!
  ) {
    topMomentsOfWorkshop(
      workshopId: $workshopId
      recordingId: $recordingId
      dailyRoomId: $dailyRoomId
    ) {
      timeStamp
      signalCount
      starCount
      eyeCount
      heartCount
      questionCount
    }
  }
`;

function useTopMoment(variables: TopMomentsOfWorkshopQueryVariables): {
  TopMoment: TopMomentsOfWorkshopQuery["topMomentsOfWorkshop"];
} & UseQueryResult<TopMomentsOfWorkshopQuery, unknown> {
  const response = useTopMomentsOfWorkshopQuery(variables);

  return {
    TopMoment: response.data?.topMomentsOfWorkshop,
    ...response,
  };
}

export { useTopMoment };
