import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { Base1Strong, Base2, Caption1, HStack, VStack } from "components";
import { Dropdown } from "components/base";
import { AccessLevel, WorkshopParticipantRole } from "graphql/generated";
import { t } from "i18n-js";
import { Check, X } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import {
  AccessLevelText,
  DropdownSelectorOptionProps,
  DropdownSelectorProps,
  WorkshopAccessText,
} from "./DropdownSelector.types";

function DropdownSelectorOption({
  isActive = false,
  onClick,
  label,
  description,
  disabled = false,
}: DropdownSelectorOptionProps) {
  const dropdownItemsRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (dropdownItemsRef.current) {
      dropdownItemsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [dropdownItemsRef]);

  return (
    <Menu.Item
      ref={dropdownItemsRef}
      as="button"
      onClick={onClick}
      disabled={disabled}
    >
      <HStack space={2}>
        <Check
          size={24}
          className={clsx(isActive ? "text-neutral-90" : "text-transparent")}
        />
        <VStack align="start" space={2}>
          <Base1Strong
            className="whitespace-nowrap text-neutral-90"
            data-testid="access-option-label"
          >
            {label}
          </Base1Strong>
          <Caption1 className="whitespace-nowrap text-neutral-60">
            {description}
          </Caption1>
        </VStack>
      </HStack>
    </Menu.Item>
  );
}

export default function DropdownSelector({
  buttonContent,
  currentLevel,
  onSelect,
  onDelete,
  canAssignAdmin = false,
  viewOnly = false,
  dropdownType,
}: DropdownSelectorProps) {
  const [access, setAccess] = useState(currentLevel);

  useEffect(() => {
    if (access !== currentLevel) {
      onSelect(access);
      setAccess(access);
    }
  }, [access]);

  const ACCESS_LEVEL_TEXT: AccessLevelText = {
    [AccessLevel.ViewAccess]: {
      label: t("constant.accessLevel.view.label"),
      description: t("constant.accessLevel.view.description"),
      order: 1,
    },
    [AccessLevel.MemberAccess]: {
      label: t("constant.accessLevel.member.label"),
      description: t("constant.accessLevel.member.description"),
      order: 2,
    },
    [AccessLevel.AdminAccess]: {
      label: t("constant.accessLevel.admin.label"),
      description: t("constant.accessLevel.admin.description"),
      order: 3,
    },
  };

  const WORKSHOP_LEVEL_TEXT: WorkshopAccessText = {
    [WorkshopParticipantRole.Participant]: {
      label: t("workshopEditor.managePeople.participant"),
      description: "",
      order: 1,
    },
    [WorkshopParticipantRole.Facilitator]: {
      label: t("workshopEditor.managePeople.facilitator"),
      description: "",
      order: 2,
    },
  };

  const OrderedDropdownList = () => {
    switch (dropdownType) {
      case "ACCESS_LEVELS":
        return Object.values(AccessLevel).sort(
          (a, b) => ACCESS_LEVEL_TEXT[a].order - ACCESS_LEVEL_TEXT[b].order,
        ) as AccessLevel[];
      case "WORKSHOP_LEVELS":
        return Object.values(WorkshopParticipantRole)
          .filter(
            (option) =>
              option !== WorkshopParticipantRole.Owner &&
              option !== WorkshopParticipantRole.SummaryShared,
          )
          .sort(
            (a, b) =>
              WORKSHOP_LEVEL_TEXT[a].order - WORKSHOP_LEVEL_TEXT[b].order,
          ) as WorkshopParticipantRole[];
      case "WORKSHOP_PENDING_INVITES_LEVELS":
        return Object.values(WorkshopParticipantRole).filter(
          (option) => option === WorkshopParticipantRole.Participant,
        ) as WorkshopParticipantRole[];
      case "SHARED_SUMMARY_LEVELS":
        return [];
    }
  };

  return (
    <Dropdown
      button={
        <Menu.Button data-testid="access-dropdown-button" className="w-full">
          {({ open }) => {
            return (
              <div className={clsx("rounded-lg", open && "bg-transparent")}>
                {buttonContent}
              </div>
            );
          }}
        </Menu.Button>
      }
      items={
        <Menu.Items
          as="span"
          className={clsx(
            "absolute top-full -right-2 rounded-xl bg-white py-9 pl-5 pr-11 shadow-lg",
            Z_INDEX_LEVELS.MODAL_CONTROL,
          )}
          data-testid="access-dropdown"
        >
          <VStack space={7}>
            {OrderedDropdownList().map((accessLevel) => {
              if (accessLevel === AccessLevel.AdminAccess && !canAssignAdmin)
                return;

              const { label, description } =
                dropdownType === "ACCESS_LEVELS"
                  ? ACCESS_LEVEL_TEXT[accessLevel as AccessLevel]
                  : WORKSHOP_LEVEL_TEXT[accessLevel as WorkshopParticipantRole];

              return (
                <DropdownSelectorOption
                  key={accessLevel}
                  isActive={access === accessLevel}
                  onClick={() => setAccess(accessLevel)}
                  label={label}
                  description={description}
                  disabled={viewOnly}
                />
              );
            })}
            {onDelete ? (
              <Menu.Item as="button" onClick={onDelete} disabled={viewOnly}>
                <HStack
                  className="text-secondary-red-70"
                  align="center"
                  space={3}
                >
                  <X size={24} />
                  <Base2>{t("shared.remove")}</Base2>
                </HStack>
              </Menu.Item>
            ) : undefined}
          </VStack>
        </Menu.Items>
      }
    />
  );
}
