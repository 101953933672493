import {
  useWorkshopMusicQuery,
  WorkshopMusicQuery,
  WorkshopMusicQueryVariables,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query WorkshopMusic($id: ID!) {
    workshopById(id: $id) {
      id
      selectedMusic {
        id
        url
        status
        timeStamp
        title
        isActive
      }
    }
  }
`;

export function useWorkshopMusic(variables: WorkshopMusicQueryVariables): {
  musicDetails: WorkshopMusicQuery["workshopById"];
} & UseQueryResult<WorkshopMusicQuery, unknown> {
  const response = useWorkshopMusicQuery(variables, {
    suspense: true,
  });

  return {
    musicDetails: response.data?.workshopById,
    ...response,
  };
}
