import React from "react";

function LinkActiveIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#F2F4F4" />
      <path
        d="M11.5 15.999H20.5"
        stroke="#00AE8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.749 20.4995H9.99902C8.80555 20.4995 7.66096 20.0254 6.81704 19.1815C5.97313 18.3376 5.49902 17.193 5.49902 15.9995C5.49902 14.806 5.97313 13.6614 6.81704 12.8175C7.66096 11.9736 8.80555 11.4995 9.99902 11.4995H13.749"
        stroke="#00AE8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.25 20.4995H22C23.1935 20.4995 24.3381 20.0254 25.182 19.1815C26.0259 18.3376 26.5 17.193 26.5 15.9995C26.5 14.806 26.0259 13.6614 25.182 12.8175C24.3381 11.9736 23.1935 11.4995 22 11.4995H18.25"
        stroke="#00AE8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#00AE8F" />
    </svg>
  );
}

export default LinkActiveIcon;
