import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import { DropdownTrigger } from "components/partial";
import { Paragraph3 } from "components/Typography";
import { t } from "i18n-js";
import { TimeIcon } from "icons";
import React, { ChangeEvent, useState } from "react";

import { ActivityTimeSelectorComponent } from ".";

const MAX_TIME = 999;

const ActivityTimeSelector: ActivityTimeSelectorComponent = ({
  selectedTime,
  onChange,
  times,
}) => {
  const [filteredTimes, setFilteredTimes] = useState(
    times.filter((time) => time !== selectedTime),
  );

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newTime = Math.min(Number.parseInt(e.target.value), MAX_TIME);

    setFilteredTimes(times.filter((time) => time !== newTime));
    onChange(newTime);
    e.target.value = newTime.toString();
  };

  return (
    <Combobox
      value={selectedTime}
      onChange={onChange}
      as="div"
      className="relative w-44"
    >
      {({ open }) => (
        <>
          <DropdownTrigger as={Combobox.Button} open={open}>
            <TimeIcon className="mr-4 mt-px flex-shrink-0" />
            <div className="relative mt-[2px]">
              <Combobox.Input
                displayValue={(time: string) =>
                  (Number.parseInt(time) || 0).toString()
                }
                value={selectedTime}
                onChange={handleOnChange}
                className="w-full bg-transparent text-neutral-90 outline-none"
                type="number"
              />
              <div
                className={clsx("absolute top-0 text-neutral-90", {
                  "!text-neutral-60": open,
                })}
              >
                <span className="opacity-0">
                  {(selectedTime || 0).toString()}
                </span>
                {selectedTime !== undefined && selectedTime > 0
                  ? t("workshopEditor.min")
                  : ""}
              </div>
            </div>
          </DropdownTrigger>
          <Combobox.Options className="absolute z-10 flex w-full flex-col rounded-b-lg border-2 border-t-0 border-tint-dark-10 bg-white py-2 shadow-projectPhaseCard">
            {selectedTime !== undefined && selectedTime > 0 && (
              <Combobox.Option value={selectedTime}>
                {({ active }) => (
                  <div
                    className={`px-4 py-2 text-left hover:bg-tint-dark-10 ${
                      active ? "bg-tint-dark-10" : ""
                    }`}
                  >
                    <Paragraph3>
                      {selectedTime}
                      {t("workshopEditor.min")}
                    </Paragraph3>
                  </div>
                )}
              </Combobox.Option>
            )}
            {filteredTimes.map((time) => (
              <Combobox.Option value={time} key={time}>
                {({ active }) => (
                  <div
                    className={`px-4 py-2 text-left hover:bg-tint-dark-10 ${
                      active ? "bg-tint-dark-10" : ""
                    }`}
                  >
                    <Paragraph3>
                      {time}
                      {t("workshopEditor.min")}
                    </Paragraph3>
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </>
      )}
    </Combobox>
  );
};

export default ActivityTimeSelector;
