import { JSONContent } from "@tiptap/core";
import { RichTextEditor } from "components/base";
import { WorkshopActivity } from "graphql/generated";
import { t } from "i18n-js";
import _ from "lodash";
import { useUpdateActivity } from "mutations/workshop";
import React, { useCallback, useEffect, useMemo } from "react";

import DashedBorder from "../dashedBorder";

interface ContentViewProps {
  activity: WorkshopActivity;
  onMutationStateChange: ({ isLoading }: { isLoading: boolean }) => void;
}

const ContentView = ({ activity, onMutationStateChange }: ContentViewProps) => {
  const { mutate: updateActivity, isLoading } = useUpdateActivity();

  const debouncedUpdateActivity = useCallback(
    _.debounce(updateActivity, 2000),
    [],
  );

  useEffect(() => {
    onMutationStateChange({ isLoading });
  }, [isLoading, onMutationStateChange]);

  const activityData = useMemo(() => {
    if (activity.activity.__typename === "Discussion") {
      return activity?.activity;
    }
  }, [activity]);

  const handleChange = (editor: "content" | "notes", content: JSONContent) => {
    debouncedUpdateActivity({
      updateWorkshopActivity: {
        id: activity.id,
        [editor === "content" ? "description" : "facilitatorNote"]: content,
      },
    });
  };

  const handleDiscussionDetailsChange = (content: JSONContent) =>
    handleChange("content", content);

  const handleFacilitationNotesChange = (content: JSONContent) =>
    handleChange("notes", content);

  return (
    <div className="flex flex-col gap-4">
      <DashedBorder>
        <RichTextEditor
          showLabel={false}
          showCharLimit={false}
          placeholder={t("workshop.descriptionPlaceholder")}
          bgColor="bg-transparent"
          minHeight="min-h-[150px]"
          maxHeight="max-h-72"
          showFocusedRing={false}
          maxCharCount={1000}
          onChange={handleDiscussionDetailsChange}
          content={activityData?.description as JSONContent}
          key={`activity-${activity.id}-content`}
          focusState={activity?.title !== t("workshopEditor.untitled") && "end"}
        />
      </DashedBorder>
      <RichTextEditor
        label={t("workshop.facilitationNotes")}
        showCharLimit={false}
        placeholder={t("workshop.notesPlaceholder")}
        minHeight="min-h-[75px]"
        maxHeight="max-h-40"
        showFocusedRing={false}
        maxCharCount={1000}
        onChange={handleFacilitationNotesChange}
        content={activity?.facilitatorNote as JSONContent}
        key={`activity-${activity.id}-notes`}
      />
    </div>
  );
};

export default ContentView;
