import {
  AuthorizationsQueryInput,
  UserAuthorizationsQuery,
  useUserAuthorizationsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query UserAuthorizations($input: AuthorizationsQueryInput) {
    authorizations(input: $input) {
      id
      provider
      project {
        id
      }
    }
  }
`;

function useUserAuthorizations(input?: AuthorizationsQueryInput): {
  authorizations: UserAuthorizationsQuery["authorizations"];
} & UseQueryResult<UserAuthorizationsQuery, unknown> {
  const response = useUserAuthorizationsQuery(
    { input: input },
    { suspense: true },
  );

  return { authorizations: response.data?.authorizations || [], ...response };
}

export { useUserAuthorizations };
