import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Avatar, Button, Link, Logo } from "components/base";
import { FormInputErrors } from "components/partial";
import { Phase } from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { BracketsCurly, PencilSimple, Trophy, X } from "phosphor-react";
import { usePulseCheckResponseById } from "queries/use-pulse-check-response";
import { useTemperatureResponseById } from "queries/use-temp-response";
import React, { Fragment, useState } from "react";
import { DeepPartial } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";
import { openInNewTab } from "utils/helpers/open-in-new-tab";

import AddPhaseSummaryForm from "./AddPhaseSummaryForm";
import { EmbeddedLinks } from "./CreateInteractions/CreateAnnouncementForm";
import { HStack, VStack } from "./layout/Stack";
import PulseChecksCards from "./PulseChecksCards";
import {
  Base1,
  Base2,
  Display2,
  Display3,
  Display5,
  Heading3Strong,
  Paragraph1,
  Paragraph2,
} from "./Typography";

interface EditPhaseSummaryModal {
  isOpen: boolean;
  closeModal: () => void;
  phase?: DeepPartial<Phase>;
  toggleEditModal: () => void;
}

export default function EditPhaseSummaryModal({
  isOpen,
  closeModal,
  phase,
  toggleEditModal,
}: EditPhaseSummaryModal): JSX.Element {
  const { projectId = "" } = useParams();
  const hasAdminAccess = useProjectAccessLevel({ projectId }).hasAdminAccess;
  const hasMemberAccess = useProjectAccessLevel({ projectId }).hasMemberAccess;
  const [showError, setShowError] = useState(false);
  const [phasePulseCheckFlag] = useState<boolean>(true);

  const { csvUrl } = useTemperatureResponseById(
    {
      id: phase?.id || "",
    },
    hasAdminAccess,
  );
  const exportCSVFile = () =>
    !!csvUrl ? openInNewTab(csvUrl || "") : setShowError(true);

  const { pulseCheckResponses } = usePulseCheckResponseById({
    id: phase?.id || "",
  });

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={clsx("fixed inset-0 overflow-y-auto", Z_INDEX_LEVELS.MODAL)}
        onClose={closeModal}
      >
        <div className="flex min-h-screen items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative flex min-h-screen w-full bg-white">
              {(hasAdminAccess || hasMemberAccess) && phase?.phaseSummary && (
                <Button
                  className="absolute top-4 right-20"
                  LeftIcon={PencilSimple}
                  variant="tertiary"
                  onClick={() => toggleEditModal()}
                >
                  {"Edit"}
                </Button>
              )}
              <Button
                className="absolute top-4 right-4"
                variant="tertiary"
                RightIcon={X}
                onClick={() => closeModal()}
              ></Button>
              {phase?.phaseSummary ? (
                <div>
                  <BracketsCurly
                    size={64}
                    color="#EB8424"
                    weight="thin"
                    className="absolute ml-14 mt-14 mb-12 text-secondary-orange-70"
                  />
                </div>
              ) : (
                <Logo
                  size="medium"
                  className="absolute top-8 left-10 text-white"
                />
              )}
              <div
                className={`flex w-[55%] flex-col ${
                  phase?.phaseSummary
                    ? `mt-28`
                    : `justify-center bg-tertiary-casal-50`
                } px-14`}
              >
                <div>
                  <HStack align="center" className="gap-4">
                    <Base2
                      className={`text-white ${
                        phase?.phaseSummary ? `mt-12 text-neutral-70` : ``
                      }`}
                    >{`${t("shared.phase")} ${phase?.position || ""}: ${
                      phase?.name || ""
                    }`}</Base2>
                  </HStack>

                  {phase?.phaseSummary ? (
                    <Display2 className={`my-4 max-w-[550px] text-neutral-70`}>
                      {`"${t("shared.phaseSummaryTitle", {
                        firstName: phase?.phaseSummary?.createdBy?.firstName,
                      })}"`}
                    </Display2>
                  ) : (
                    <Display3 className={`my-4 max-w-[550px] text-white`}>
                      {t("pages.addPhaseSummary.title")}
                    </Display3>
                  )}

                  {phase?.phaseSummary ? (
                    <>
                      <HStack align="center" className="mt-10">
                        <Avatar
                          // className="min-w-[48px]"
                          className="mr-4"
                          size="medium"
                          user={phase?.phaseSummary?.createdBy}
                        />
                        <Base1 className="truncate text-neutral-70">
                          {t("shared.phaseSummaryAdded", {
                            firstName:
                              phase?.phaseSummary?.createdBy?.firstName,
                          })}
                        </Base1>
                      </HStack>
                      <Display5 className="mt-4 max-w-[550px] text-neutral-90">
                        {phase?.phaseSummary?.description}
                      </Display5>
                      {phase?.phaseSummary?.links?.length ? (
                        <>
                          <div className="my-10 border border-tint-dark-15" />
                          <VStack space={4}>
                            <Base2 className="text-neutral-60">
                              {t("shared.keyLinks")}
                            </Base2>
                            <HStack align="center" className="flex-wrap gap-2">
                              {phase?.phaseSummary?.links?.map((link) => (
                                <Link
                                  key={link?.url}
                                  metadata={link as EmbeddedLinks}
                                />
                              ))}
                            </HStack>
                          </VStack>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <Paragraph2 className="max-w-[550px] text-gray-200 ">
                        {t("pages.addPhaseSummary.subtitle")}
                      </Paragraph2>
                      <img
                        src={"/supporting-graphic.png"}
                        className="m-auto mt-18"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="mt-36 w-[40%]">
                {phase?.phaseSummary ? (
                  <>
                    <VStack
                      space={4}
                      className="h-[372px] w-[544px] rounded-2xl bg-neutral-0 p-6"
                    >
                      <HStack>
                        <HStack align="start" justify="start">
                          <Trophy size={80} color="#16C7A7" className="mb-6" />
                        </HStack>
                      </HStack>

                      <HStack className="mb-3">
                        <Heading3Strong>{t("shared.phaseGoal")}</Heading3Strong>
                      </HStack>

                      <HStack>
                        <Paragraph1>{phase?.goal}</Paragraph1>
                      </HStack>
                    </VStack>
                    {hasAdminAccess && (
                      <VStack>
                        <VStack className={clsx("mt-6 mb-4")}>
                          <HStack>
                            <FormInputErrors
                              error={
                                showError
                                  ? t("errors.noPulseCheckSubmitted")
                                  : ""
                              }
                            />
                          </HStack>
                          <HStack className={clsx("mt-6 mb-4")}>
                            <PulseChecksCards
                              phasePulseCheckFlag={phasePulseCheckFlag}
                              phaseStartDate={phase?.startDate as string}
                              phaseEndDate={phase?.endDate as string}
                            />
                          </HStack>
                        </VStack>
                      </VStack>
                    )}
                  </>
                ) : (
                  <AddPhaseSummaryForm
                    onSubmitSuccess={() => closeModal()}
                    onFormCancel={closeModal}
                    phase={phase}
                  />
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
