import clsx from "clsx";
import Avatar, { AvatarProps } from "components/base/Avatar/Avatar";
import { ArrowUpRight, Check, X } from "phosphor-react";
import React from "react";

/**
 * AttendanceAvatar wraps the base Avatar component and is used to indicate
 * a user/participant's attendance overlaid on the Avatar itself.
 */
export const AttendanceAvatar = ({
  size,
  user,
  className,
  didAttend,
  didSummaryShared,
  ...props
}: AvatarProps & { didAttend?: boolean; didSummaryShared?: boolean }) => {
  return (
    <div className={clsx("relative h-6", className)} {...props}>
      <Avatar size={size} user={user} />

      <div
        className={clsx(
          "absolute bottom-0 right-0 flex h-3 w-3 items-center justify-center rounded-full",
          {
            "bg-red-500": !didAttend,
            "bg-green-500": didAttend,
            "!bg-tertiary-blue-700": !didAttend && didSummaryShared,
          },
        )}
      >
        {didAttend && <Check size={10} className="text-white" />}
        {!didAttend && !didSummaryShared && (
          <X size={10} className="text-white" />
        )}
        {!didAttend && didSummaryShared && (
          <ArrowUpRight size={10} className="text-white" />
        )}
      </div>
    </div>
  );
};
