import { useCreateAnnouncementMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation CreateAnnouncement($announcement: CreateAnnouncementInput!) {
    createAnnouncement(announcement: $announcement) {
      description
      id
      author {
        id
        firstName
      }
      project {
        id
        name
      }
      links {
        url
        title
        extension
        preview
      }
      isHighlighted
    }
  }
`;

function useCreateAnnouncement() {
  const queryClient = useQueryClient();
  return useCreateAnnouncementMutation({
    onSuccess: () => {
      void queryClient.refetchQueries(["subFilters"]);
      void queryClient.refetchQueries(["Notifications"]);
      void queryClient.refetchQueries(["ProjectById"]);
    },
  });
}

export default useCreateAnnouncement;
