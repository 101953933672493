import { WorkshopEdit } from "components/pages";
import { Modal } from "components/partial";
import React from "react";

interface WorkshopBuilderModalProps {
  open: boolean;
  onClose: () => void;
  workshopId: string;
}

export default function WorkshopBuilderModal({
  open,
  onClose,
}: WorkshopBuilderModalProps) {
  return (
    <Modal
      className="-m-10 h-[693px] w-[1248px] overflow-y-scroll"
      open={open}
      onClose={onClose}
      data-testid="workshop-builder-modal"
    >
      <WorkshopEdit
        isBuilderModal
        joinLiveHandler={onClose}
        activeTabIndex={1}
        data-testid="workshop-edit"
      />
    </Modal>
  );
}
