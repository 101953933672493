import { Avatar, Button, Loader, OrganizationAvatar } from "components/base";
import Center from "components/layout/Center";
import { HStack, VStack } from "components/layout/Stack";
import { Heading1Strong, Paragraph3 } from "components/Typography";
import Compressor from "compressorjs";
import { useGetEnvsContext } from "contexts";
import { useOrganizationPictureMutation } from "graphql/generated";
import { t } from "i18n-js";
import React, { useEffect, useRef, useState } from "react";
import uploadToActiveStorage from "utils/helpers/upload-to-active-storage";

import { PROFILE } from "../constants";

interface UserSettingImageUploadProps {
  show?: boolean;
  workplaceSetting?: string;
  from?: "profile" | "organization";
  organizationId?: string;
  organizationImageUrl?: string;
  organizationName?: string;
}
function UserSettingImageUpload({
  show,
  workplaceSetting,
  from = PROFILE,
  organizationId = "",
  organizationImageUrl = "",
  organizationName = "",
}: UserSettingImageUploadProps) {
  const { currentUser } = useGetEnvsContext();
  const [image, setImage] = useState<File>();
  const [errorOnUploadPhoto, setErrorOnUploadPhoto] = useState(false);
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
  const { mutateAsync: uploadOrganizationImage } =
    useOrganizationPictureMutation();
  const fileRef = useRef<HTMLInputElement>(null);

  const onButtonClick = () => {
    fileRef?.current?.click();
  };
  useEffect(() => {
    fileRef?.current?.focus();
  }, []);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.length) {
      setIsLoadingPhoto(true);
      const file = event.target.files[0];
      new Compressor(file, {
        maxHeight: 300,
        maxWidth: 300,
        quality: 0.8,
        resize: "cover",
        success: (compressedResult: File) => {
          submitPhoto(compressedResult)
            .then(() => {
              setImage(compressedResult);
            })
            .catch(() => {
              setErrorOnUploadPhoto(true);
            })
            .finally(() => {
              setIsLoadingPhoto(false);
            });
        },
      });
    }
  };
  const submitPhoto = async (file: File) => {
    const blob = await uploadToActiveStorage(file);
    if (from === "organization") {
      await uploadOrganizationImage({
        signedBlobId: blob.signed_id,
        organizationId,
      });
    }
  };
  return (
    <VStack className="!mt-12 gap-8">
      <Heading1Strong className={show ? "hidden" : ""}>
        {t(`component.userSettings.tabs.profile.sections.photo.title`)}
      </Heading1Strong>
      <HStack align="center" className="gap-8">
        <input
          type="file"
          ref={fileRef}
          accept="image/*"
          className="hidden"
          onChange={handleFileChange}
          data-testid="photo-uploader"
        />
        <Center className="h-[120px] w-[120px]">
          {isLoadingPhoto && <Loader />}
          {image && !isLoadingPhoto && (
            <img
              className="rounded-full"
              src={
                typeof image === "string" ? image : URL.createObjectURL(image)
              }
            />
          )}
          {!image && !isLoadingPhoto && from === "profile" ? (
            <Avatar
              user={currentUser || undefined}
              size="large"
              textClassName={workplaceSetting}
            />
          ) : (
            <OrganizationAvatar
              organizationImageUrl={organizationImageUrl}
              organizationName={organizationName}
              size="large"
            />
          )}
        </Center>
        <Button variant="outline" onClick={onButtonClick}>
          {t(`component.userSettings.tabs.profile.sections.photo.uploadPhoto`)}
        </Button>

        {/* <Button variant="outline" onClick={() => removeProfilePIcture()}>
          {t(`component.userSettings.tabs.profile.sections.photo.deletePhoto`)}
        </Button> */}

        {errorOnUploadPhoto && (
          <Paragraph3 className="text-red-400">
            {t(
              `component.userSettings.tabs.profile.sections.photo.failedToUpload`,
            )}
          </Paragraph3>
        )}
      </HStack>
    </VStack>
  );
}
export default UserSettingImageUpload;
