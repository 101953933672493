import { WorkshopByIdQuery, WorkshopParticipantRole } from "graphql/generated";

import { VideoSize } from "../../Workshop.types";
import { useActivityType } from "./useActivityType";

export enum MimeTypes {
  PDF = "application/pdf",
  JPEG = "image/jpeg",
  PNG = "image/png",
}
export enum ActivityType {
  Poll = "Poll",
  Breakout = "Breakout",
  Discussion = "Discussion",
  GoogleDoc = "Resource:GoogleDoc",
  Image = "Resource:Image",
  PDF = "Resource:PDF",
  Miro = "Resource:Miro",
}

export type WorkshopActivityType = ReturnType<typeof useActivityType>;

export interface LiveWorkshopActivityProps {
  workshop: WorkshopByIdQuery["workshopById"];
  handleVideoCallSizeChange: (callSize: VideoSize) => void;
  role?: WorkshopParticipantRole;
  setVideoCallWidth?: React.Dispatch<React.SetStateAction<string>>;
}
