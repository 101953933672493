/* eslint-disable graphql/template-strings */
import { gql } from "@apollo/client";
import { EmotionalSignalsSubscription } from "graphql/generated";
import { useQueryClient } from "react-query";

import { useSubscription } from "./use-subscription";

export const EMOTIONAL_SIGNALS_SUBSCRIPTION = gql`
  subscription EmotionalSignals($workshopId: ID!) {
    emotionalSignals(workshopId: $workshopId) {
      emotionalReaction {
        emojiName
        timeStamp
      }
    }
  }
`;

export interface UseEmotionalSignalsProps {
  workshopId: string;
}

export const useEmotionalSignalsSubscription = ({
  workshopId,
}: UseEmotionalSignalsProps) => {
  const queryClient = useQueryClient();

  const output = useSubscription<EmotionalSignalsSubscription>(
    EMOTIONAL_SIGNALS_SUBSCRIPTION,
    {
      variables: {
        workshopId: workshopId,
      },

      async onData() {
        await queryClient.invalidateQueries("WorkshopById");
      },
    },
  );

  return output?.data?.emotionalSignals || { emotionalReaction: [] };
};
