/* eslint-disable graphql/template-strings */
/* eslint-disable unicorn/no-nested-ternary */

import {
  Base2,
  Base2Strong,
  Caption1,
  CenteredPage,
  Display3,
} from "components";
import { Button, Input } from "components/base";
import { FloatingLogo, GoogleLoginButton } from "components/partial";
import { GraphQLError } from "graphql";
import {
  useLogInMutation,
  useResendEmailConfirmationInstructionsMutation,
} from "graphql/generated";
import { useProjectInvitationOrigin } from "hooks";
import { t } from "i18n-js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  INVALID_CREDENTIALS,
  UNCONFIRMED,
} from "utils/constants/graphql_errors";
import { EMAIL_REGEX } from "utils/helpers/validations";

type LogInFormData = {
  email: string;
  password: string;
};

function LogIn() {
  const lastVisitedProjectPage = localStorage.getItem("lastVisitedProjectPage");
  const { mutateAsync } = useLogInMutation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [submitError, setSubmitError] = useState<string | undefined>();
  const {
    originUrl,
    hasProjectInvitationOrigin,
    projectInvitationId,
    projectName,
    inviteeEmail,
    token,
    googleAuthError,
    isInvitee,
  } = useProjectInvitationOrigin();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
    getValues,
  } = useForm<LogInFormData>({
    mode: "onTouched",
    defaultValues: { email: inviteeEmail },
  });
  const { mutateAsync: resendMutateAsync } =
    useResendEmailConfirmationInstructionsMutation();
  const CONFIRM_EMAIL_MESSAGE =
    "You have to confirm your email address before continuing.";

  const onSubmit = async (formData: LogInFormData) => {
    const { email, password } = formData;

    try {
      const { logIn } = await mutateAsync({
        email: email?.toLowerCase(),
        password,
      });
      if (logIn) {
        await queryClient.refetchQueries(["CurrentUser"]);
        const navigateUrl = originUrl
          ? originUrl || "/"
          : lastVisitedProjectPage
          ? lastVisitedProjectPage
          : "/";
        if (hasProjectInvitationOrigin && !isInvitee(email)) {
          navigate(lastVisitedProjectPage || "/");
        } else {
          navigate(navigateUrl);
        }
      }
    } catch (error) {
      const graphQLError = error as GraphQLError;

      switch (graphQLError?.extensions?.code) {
        case INVALID_CREDENTIALS:
          setSubmitError(t("errors.auth.invalid"));
          break;
        case UNCONFIRMED:
          setSubmitError(t("errors.auth.unconfirmed"));
          break;
        default:
          setSubmitError(graphQLError.message || t("errors.genericError"));

          break;
      }
    }
  };

  const resendEmailConfirmation = async () => {
    const email = getValues("email");
    try {
      const { resendEmailConfirmationInstructions } = await resendMutateAsync({
        email: email?.toLowerCase(),
      });
      if (resendEmailConfirmationInstructions) {
        alert("E-mail sent!");
      }
    } catch {
      alert("something went wrong");
    }
  };

  const navigateToSignUp = () => {
    if (hasProjectInvitationOrigin) {
      const encodedPath = window.btoa(
        `redirect_path=${"sign_up"}&projectInvitationId=${projectInvitationId}&projectName=${projectName}&token=${token}&inviteeEmail=${inviteeEmail}`,
      );
      const navigateUrl = `/project-invitations/role?${encodedPath}`;
      navigate(navigateUrl);
    }
  };

  useEffect(() => {
    setSubmitError(
      googleAuthError ? t(`errors.auth.${googleAuthError}`) : undefined,
    );
  }, [googleAuthError]);

  return (
    <CenteredPage className="bg-neutral-10">
      <FloatingLogo />
      <div className="flex flex-1 flex-col justify-center py-28">
        <div className="flex w-108 flex-col">
          <Display3 className="mb-24 text-neutral-90">
            {t("pages.logIn.title")}
          </Display3>
          <GoogleLoginButton />
          <Base2 className="my-4 text-neutral-90">or</Base2>
          <form
            className="flex flex-col text-left"
            onSubmit={handleSubmit(onSubmit) as () => void}
          >
            {submitError && (
              <Caption1 className="mb-8 text-center text-secondary-red-50">
                {submitError}
                {submitError === CONFIRM_EMAIL_MESSAGE && (
                  <Caption1
                    onClick={resendEmailConfirmation}
                    className="mt-[4px] inline-block cursor-pointer border-b-[0.8px] border-secondary-red-50 text-center text-secondary-red-50"
                  >
                    {t("component.verifyEmail.resendConfirmationEmail")}
                  </Caption1>
                )}
              </Caption1>
            )}
            <Input
              type="email"
              placeholder="name@example.com"
              label={t("shared.emailAddress")}
              error={errors.email?.message}
              className="!bg-neutral-5"
              {...register("email", {
                required: t("errors.presence"),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t("errors.invalidEmail"),
                },
              })}
            />
            <Input
              type="password"
              placeholder="Password"
              label={t("shared.password")}
              error={errors.password?.message}
              className="!bg-neutral-5"
              contentClassName="mt-4"
              {...register("password", {
                required: t("errors.presence"),
              })}
            />
            <Button
              className="mt-4"
              type="submit"
              disabled={isSubmitting}
              value={t("shared.logIn")}
            >
              {t("shared.logIn")}
            </Button>
          </form>
        </div>

        <Base2 className="mt-12 text-neutral-90">
          <Button
            variant="link"
            className="!inline"
            onClick={() => navigate("/request_password_reset")}
          >
            <Base2Strong as="span">{t("shared.forgotPassword")}</Base2Strong>
          </Button>
        </Base2>
        {hasProjectInvitationOrigin && (
          <Base2 className="mt-4 text-neutral-90">
            {t("shared.noAccount")}{" "}
            <Button
              variant="link"
              onClick={navigateToSignUp}
              className="!inline"
            >
              <Base2Strong as="span">{t("shared.createOne")}</Base2Strong>
            </Button>
          </Base2>
        )}
      </div>
    </CenteredPage>
  );
}

export default LogIn;
