import { Menu } from "@headlessui/react";
import clsx from "clsx";
import {
  Base1,
  Base1Strong,
  Base2,
  Base2Strong,
  HStack,
  VStack,
} from "components";
import { Button, Dropdown } from "components/base";
import { useGetEnvsContext } from "contexts";
import { formatDistance, parseISO } from "date-fns";
import {
  Scalars,
  SlackAuthorization,
  SlackChannel,
  SlackPostSettings,
  useDeleteSlackAuthorizationMutation,
  useUpdateSlackAuthorizationMutation,
} from "graphql/generated";
import { t } from "i18n-js";
import { SlackLogo } from "icons";
import { capitalize } from "lodash";
import { CaretDown, Check } from "phosphor-react";
import { useProject, useProjectAssignments, useSlackChannels } from "queries";
import React, { useMemo } from "react";
import { useQueryClient } from "react-query";

type LinkInstructionsProps = {
  projectId: Scalars["ID"];
};

function LinkInstructions({ projectId }: LinkInstructionsProps) {
  const SLACK_CLIENT_ID =
    window?.env?.vars?.SLACK_CLIENT_ID || process.env.SLACK_CLIENT_ID;
  const { projectAssignments } = useProjectAssignments({ projectId });
  const { currentUser } = useGetEnvsContext();
  const currentUserAssignment = useMemo(() => {
    return projectAssignments.find(({ user }) => user.id === currentUser?.id);
  }, [currentUser, projectAssignments]);
  return (
    <VStack space={6} className="max-w-[448px] text-neutral-90">
      <Base2Strong>{t("integrations.slack.linking.header")}</Base2Strong>
      <Base2>{t("integrations.slack.linking.description")}</Base2>

      <a
        data-testid="slack-link"
        href={`https://slack.com/oauth/v2/authorize?client_id=${
          SLACK_CLIENT_ID || ""
        }&redirect_uri=${
          window.location.origin
        }/users/omniauth_callbacks/slack&scope=chat:write,chat:write.public,channels:read&state=${JSON.stringify(
          { projectAssignmentId: currentUserAssignment?.id },
        )}`}
      >
        <Button>{t("integrations.slack.add")}</Button>
      </a>
    </VStack>
  );
}

type SlackAuthorizationHeaderProps = {
  slackAuthorization: Partial<SlackAuthorization>;
};

function SlackAuthorizationHeader({
  slackAuthorization,
}: SlackAuthorizationHeaderProps) {
  const formattedCreatedAt = formatDistance(
    parseISO(slackAuthorization.createdAt || ""),
    new Date(),
    {
      addSuffix: true,
    },
  );

  return (
    <HStack space={4} className="rounded-lg border border-neutral-10 py-5 px-4">
      <SlackLogo />
      <VStack space={1}>
        <Base1Strong className="text-neutral-90">
          {t("integrations.slack.title")}
        </Base1Strong>
        <Base2 className="text-neutral-70">
          {t("integrations.slack.added", {
            date: formattedCreatedAt,
          })}
        </Base2>
      </VStack>
    </HStack>
  );
}

type SlackChannelsDropdown = {
  slackAuthorization: Partial<SlackAuthorization>;
  onUpdate: () => Promise<void>;
};

function SlackChannelsDropdown({
  slackAuthorization,
  onUpdate,
}: SlackChannelsDropdown) {
  const { slackChannels } = useSlackChannels(slackAuthorization.id || "");
  const { mutateAsync } = useUpdateSlackAuthorizationMutation();

  const handleUpdateSlackChannel = async (
    channel: SlackChannel,
  ): Promise<void> => {
    await mutateAsync({
      id: slackAuthorization.id || "",
      settings: {
        channelId: channel.id,
        channelName: channel.name,
      },
    });
    await onUpdate();
  };

  return (
    <Dropdown
      button={
        <Menu.Button data-testid="channel-selector">
          <HStack className="w-108 justify-between rounded-md bg-tint-dark-10 py-3 px-4 text-neutral-90">
            <Base2>
              {slackAuthorization.channel?.name ||
                t("integrations.slack.channels.placeholder")}
            </Base2>
            <CaretDown />
          </HStack>
        </Menu.Button>
      }
      items={
        <Menu.Items
          className="absolute top-0 w-108 rounded-md bg-white shadow-lg"
          data-testid="slack-channels-dropdown"
        >
          <VStack>
            <HStack className="border-neutral-05 w-108 justify-between rounded-t-md border-b-2 py-3 px-4 text-neutral-90">
              <Base2>
                {slackAuthorization.channel?.name ||
                  t("integrations.slack.channels.placeholder")}
              </Base2>
              <CaretDown />
            </HStack>
            <VStack
              space={12}
              className="max-h-80 overflow-scroll pl-6 pb-7 pt-9"
            >
              {slackChannels.map((channel) => (
                <Menu.Item
                  key={channel.id}
                  as="button"
                  onClick={
                    (() => handleUpdateSlackChannel(channel)) as () => void
                  }
                >
                  <HStack space={1} align="center">
                    {channel.id === slackAuthorization?.channel?.id ? (
                      <HStack space={4}>
                        <Base1Strong>{channel.name}</Base1Strong>
                        <Check size={20} />
                      </HStack>
                    ) : (
                      <Base1>{channel.name}</Base1>
                    )}
                  </HStack>
                </Menu.Item>
              ))}
            </VStack>
          </VStack>
        </Menu.Items>
      }
    />
  );
}

type SlackPostSettingsOption = Exclude<keyof SlackPostSettings, "__typename">;
type SlackPostSettingsFieldProps = {
  type: SlackPostSettingsOption;
  label: string;
  isChecked?: boolean;
  slackAuthorizationId?: Scalars["ID"];
  onUpdate: () => Promise<void>;
};
function SlackPostSettingsField({
  type,
  label,
  isChecked = false,
  slackAuthorizationId,
  onUpdate,
}: SlackPostSettingsFieldProps) {
  const { mutateAsync } = useUpdateSlackAuthorizationMutation();
  const handleToggle = async () => {
    if (!slackAuthorizationId) return;
    await mutateAsync({
      id: slackAuthorizationId,
      settings: {
        [`shouldPost${capitalize(type)}`]: !isChecked,
      },
    });

    await onUpdate();
  };
  return (
    <HStack space={4} align="center">
      <input
        id={type}
        name={type}
        type="checkbox"
        checked={isChecked}
        className="hidden"
        onChange={handleToggle as () => void}
      />
      <span
        onClick={handleToggle as () => void}
        className={clsx([
          "flex h-6 w-6 cursor-pointer items-center justify-center rounded",
          isChecked ? "bg-primary-turquoise-50" : "bg-tint-dark-15",
        ])}
      >
        {isChecked && <Check size={24} color="white" weight="bold" />}
      </span>
      <label htmlFor={type}>
        <Base2 className="text-neutral-90">{label}</Base2>
      </label>
    </HStack>
  );
}

type SlackPostSettingsOptionsProps = {
  slackAuthorization: Partial<SlackAuthorization>;
  onUpdate: () => Promise<void>;
};
function SlackPostSettingsOptions({
  slackAuthorization,
  onUpdate,
}: SlackPostSettingsOptionsProps) {
  return (
    <VStack space={6}>
      <Base2Strong className="text-neutral-90">
        {t("integrations.slack.postSettings.description")}
      </Base2Strong>
      <SlackPostSettingsField
        type="announcements"
        label={t("integrations.slack.postSettings.announcements")}
        isChecked={slackAuthorization.postSettings?.announcements}
        slackAuthorizationId={slackAuthorization.id}
        onUpdate={onUpdate}
      />
      <SlackPostSettingsField
        type="tasks"
        label={t("integrations.slack.postSettings.tasks")}
        isChecked={slackAuthorization.postSettings?.tasks}
        slackAuthorizationId={slackAuthorization.id}
        onUpdate={onUpdate}
      />
      <SlackPostSettingsField
        type="workshops"
        label={t("integrations.slack.postSettings.workshops")}
        isChecked={slackAuthorization.postSettings?.workshops}
        slackAuthorizationId={slackAuthorization.id}
        onUpdate={onUpdate}
      />
    </VStack>
  );
}

type DisconnectSlackProps = {
  slackAuthorization?: Partial<SlackAuthorization>;
  onUpdate: () => Promise<void>;
};

function DisconnectSlack({
  slackAuthorization,
  onUpdate,
}: DisconnectSlackProps) {
  const { mutateAsync } = useDeleteSlackAuthorizationMutation();

  const handleDisconnectSlack = async (): Promise<void> => {
    if (!slackAuthorization?.id) return;
    await mutateAsync({ id: slackAuthorization.id });
    await onUpdate();
  };
  return (
    <VStack space={6} align="start">
      <Base2Strong className="text-neutral-90">
        {t("integrations.slack.disconnect.header")}
      </Base2Strong>
      <Button onClick={handleDisconnectSlack as () => void}>
        {t("integrations.slack.disconnect.action")}
      </Button>
    </VStack>
  );
}

type SlackSettingsProps = {
  projectId: Scalars["ID"];
};

export default function SlackSettings({ projectId }: SlackSettingsProps) {
  const queryClient = useQueryClient();
  const { project } = useProject({
    id: projectId,
  });

  const handleUpdate = async (): Promise<void> =>
    queryClient.refetchQueries(["ProjectById", { id: projectId }]);

  if (!project?.slackAuthorization) {
    return <LinkInstructions projectId={projectId} />;
  }

  return (
    <VStack space={6}>
      <SlackAuthorizationHeader
        slackAuthorization={project.slackAuthorization}
      />
      <VStack space={12}>
        <VStack space={2}>
          <Base2 className="text-neutral-90">
            {t("integrations.slack.channels.description")}
          </Base2>
          <SlackChannelsDropdown
            slackAuthorization={project.slackAuthorization}
            onUpdate={handleUpdate}
          />
        </VStack>
        <SlackPostSettingsOptions
          slackAuthorization={project.slackAuthorization}
          onUpdate={handleUpdate}
        />
        <DisconnectSlack
          slackAuthorization={project.slackAuthorization}
          onUpdate={handleUpdate}
        />
      </VStack>
    </VStack>
  );
}
