import {
  EyeClosed,
  HandPalm,
  Heart,
  Lightbulb,
  Question,
} from "phosphor-react";
import React from "react";

import { badgeVariant } from "../SignalDropdown.types";

const SignalBadge = ({
  variant,
  className,
  onAnimationEnd,
}: {
  variant: badgeVariant;
  className?: string;
  onAnimationEnd?: () => void;
}) => {
  const Icon = () => {
    switch (variant) {
      case "lightbulb":
        return <Lightbulb />;
      case "lightbulb_secondary":
        return <Lightbulb className="text-neutral-500" />;
      case "love":
        return <Heart />;
      case "question":
        return <Question />;
      case "break":
        return <EyeClosed />;
      case "hand":
        return <HandPalm className="text-neutral-500" />;
    }
  };

  const color = {
    lightbulb: "bg-secondary-yellow-70",
    lightbulb_secondary: "bg-neutral-00",
    love: "bg-secondary-red-70",
    question: "bg-tertiary-blue-70",
    break: "bg-tertiary-purple-70",
    hand: "bg-neutral-00",
  };

  return (
    <div
      className={`rounded-full ${
        color[variant]
      } flex h-6 w-6 items-center justify-center bg-neutral-5 text-white ${
        className || ""
      }`}
      onAnimationEnd={onAnimationEnd}
    >
      <Icon />
    </div>
  );
};

export default SignalBadge;
