import { useStartForceCloudRecordingMutation } from "graphql/generated";
import { gql } from "graphql-request";

gql`
  mutation StartForceCloudRecording($input: StartForceCloudRecordingInput!) {
    startForceCloudRecording(input: $input) {
      recordingId
      status
    }
  }
`;

export function useStartForceCloudRecording() {
  return useStartForceCloudRecordingMutation();
}
