import {
  UserPersonalCollabMetricsQuery,
  useUserPersonalCollabMetricsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query UserPersonalCollabMetrics {
    userPersonalCollabMetrics {
      affiliation
      openToChange
      empathetic
      genuine
    }
  }
`;

function useUserPersonalCollabMetrics(): {
  personalCollabMetrics: UserPersonalCollabMetricsQuery["userPersonalCollabMetrics"];
} & UseQueryResult<UserPersonalCollabMetricsQuery, unknown> {
  const response = useUserPersonalCollabMetricsQuery({}, { suspense: true });
  return {
    personalCollabMetrics: response.data?.userPersonalCollabMetrics || {},
    ...response,
  };
}

export { useUserPersonalCollabMetrics };
