import { Disclosure, Transition } from "@headlessui/react";
import { JSONContent } from "@tiptap/core";
import { ReadOnlyRichTextEditor } from "components/base";
import { PreviewResource } from "components/partial";
import { Base2, Caption1 } from "components/Typography";
import { Breakout } from "graphql/generated";
import { t } from "i18n-js";
import { CaretDown } from "phosphor-react";
import React from "react";

import { ParticipantCard } from "../ParticipantCard";

export interface PostSessionBreakoutProps {
  activity: Breakout;
}

export const PostSessionBreakout = ({ activity }: PostSessionBreakoutProps) => {
  return (
    <div className="flex gap-6 rounded-lg border border-neutral-5 p-6">
      <div className="w-1/2">
        <Base2>{t("workshop.postSessionActivity.breakout.instructions")}</Base2>

        <ReadOnlyRichTextEditor
          className="my-4 text-neutral-800"
          readOnlyContent={activity.instruction as JSONContent}
        />

        {(activity.documents || activity.link) && (
          <PreviewResource activityData={activity} />
        )}
      </div>

      <div className="h-full w-[1px] bg-neutral-10" />

      <div className="w-1/2">
        <Base2 className="mb-6">
          {t("workshop.postSessionActivity.breakout.rooms")}
        </Base2>

        <div className="flex flex-col gap-2">
          {activity.dailyRooms.length <= 0 ? (
            <div className="flex h-14 items-center justify-center">
              <Caption1 className="text-tint-dark-50">
                {t("breakoutActivityEditor.noRooms")}
              </Caption1>
            </div>
          ) : (
            activity.dailyRooms.map((room, roomIndex) => (
              <Disclosure key={room.id} defaultOpen={false}>
                {({ open }) => (
                  <div className="flex flex-col rounded-md border p-2">
                    <Disclosure.Button className="flex items-center justify-between p-4">
                      <div className="flex items-center">
                        <CaretDown
                          size={24}
                          className={`mr-2 transition duration-500 ease-in-out ${
                            open ? "rotate-180 transform" : ""
                          }`}
                        />
                        <Base2>
                          {room.title ||
                            `${t("breakoutActivityEditor.room")} ${
                              roomIndex + 1
                            }`}
                        </Base2>
                      </div>

                      <Caption1 className="text-neutral-500">
                        {t(
                          "workshop.postSessionActivity.breakout.participants",
                          { count: room?.workshopParticipants?.length || 0 },
                        )}
                      </Caption1>
                    </Disclosure.Button>

                    <Transition
                      as={Disclosure.Panel}
                      enter="transition-all ease-in-out duration-500 transform"
                      enterFrom="-translate-y-10 opacity-0"
                      enterTo="translate-y-0 opacity-100"
                      leave="transition-all ease-in-out duration-500 transform"
                      leaveFrom="translate-y-0 opacity-100"
                      leaveTo="-translate-y-10 opacity-0"
                      className="flex flex-col gap-2"
                    >
                      {(room?.workshopParticipants?.length || 0) > 0 ? (
                        room.workshopParticipants?.map((participant) => (
                          <ParticipantCard
                            key={participant.id}
                            participant={participant}
                          />
                        ))
                      ) : (
                        <div>
                          <Caption1 className="p-4 text-tint-dark-50">
                            {t(
                              "workshop.postSessionActivity.breakout.noParticipants",
                            )}
                          </Caption1>
                        </div>
                      )}
                    </Transition>
                  </div>
                )}
              </Disclosure>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
