import React from "react";

import { getActiveWedgeFillHexcodeByProfile } from "./DiSCProfile.util";

export enum DiSCProfileOption {
  D = "D",
  Di = "DI",
  Dc = "DC",
  i = "I",
  iD = "ID",
  iS = "IS",
  S = "S",
  Si = "SI",
  Sc = "SC",
  C = "C",
  Cs = "CS",
  Cd = "CD",
}

export interface DiscProfileProps {
  profile: DiSCProfileOption;
  width?: number;
  className?: string;
}

export const DiSCProfile = ({
  profile,
  className,
  width = 140,
}: DiscProfileProps) => {
  const borderColor = "#DFDFE0";

  const focusFill = getActiveWedgeFillHexcodeByProfile(profile);
  const passiveWedgeFill = "#EEEEEE";

  const passiveStrokeColor = "#5F6368";
  const focusStrokeColor = "#FFF";

  const focusStrokeOpacity = "1";
  const passiveStrokeOpacity = "0.3";

  return (
    <svg
      className={className}
      width={width}
      height={width}
      // Since the vector paths are a full 140 units across, I've added a pixel of margin around the viewbox
      viewBox="-1 -1 142 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Dc */}
      <path
        d="M9.37823 35C3.23694 45.637 0.00258254 57.7027 1.54547e-06 69.9853L70 70L9.37823 35Z"
        fill={profile === DiSCProfileOption.Dc ? focusFill : passiveWedgeFill}
        stroke={borderColor}
      />
      <path
        d="M14.197 61C15.529 61 16.609 60.616 17.449 59.848C18.301 59.068 18.721 58.036 18.721 56.752C18.721 55.48 18.301 54.46 17.449 53.704C16.609 52.948 15.529 52.564 14.197 52.564H11.257V61H14.197ZM14.161 53.656C15.169 53.656 15.985 53.932 16.597 54.496C17.209 55.048 17.521 55.804 17.521 56.752C17.521 57.724 17.209 58.504 16.597 59.068C15.985 59.632 15.169 59.908 14.161 59.908H12.409V53.656H14.161ZM23.3134 54.796C22.3894 54.796 21.6214 55.096 21.0094 55.708C20.3974 56.32 20.0854 57.064 20.0854 57.964C20.0854 58.864 20.3974 59.62 21.0094 60.232C21.6214 60.844 22.3894 61.144 23.3134 61.144C24.0814 61.144 24.7534 60.916 25.3174 60.448C25.8934 59.98 26.2534 59.368 26.4094 58.612H25.3174C25.0654 59.512 24.2614 60.124 23.3134 60.124C22.7134 60.124 22.2094 59.92 21.8014 59.5C21.3934 59.08 21.1894 58.576 21.1894 57.964C21.1894 57.352 21.3934 56.848 21.8014 56.44C22.2094 56.02 22.7134 55.816 23.3134 55.816C24.2374 55.816 25.0174 56.404 25.2934 57.28H26.3974C26.2414 56.548 25.8814 55.96 25.3174 55.492C24.7534 55.024 24.0814 54.796 23.3134 54.796Z"
        fill={
          profile === DiSCProfileOption.Dc
            ? focusStrokeColor
            : passiveStrokeColor
        }
        fill-opacity={
          profile === DiSCProfileOption.Dc
            ? focusStrokeOpacity
            : passiveStrokeOpacity
        }
      />

      {/* D */}
      <path
        d="M35 9.37822C24.363 15.5195 15.5291 24.3515 9.38557 34.9873L70 70L35 9.37822Z"
        fill={profile === DiSCProfileOption.D ? focusFill : passiveWedgeFill}
        stroke={borderColor}
      />
      <path
        d="M29.4798 34C30.8118 34 31.8918 33.616 32.7318 32.848C33.5838 32.068 34.0038 31.036 34.0038 29.752C34.0038 28.48 33.5838 27.46 32.7318 26.704C31.8918 25.948 30.8118 25.564 29.4798 25.564H26.5398V34H29.4798ZM29.4438 26.656C30.4518 26.656 31.2678 26.932 31.8798 27.496C32.4918 28.048 32.8038 28.804 32.8038 29.752C32.8038 30.724 32.4918 31.504 31.8798 32.068C31.2678 32.632 30.4518 32.908 29.4438 32.908H27.6918V26.656H29.4438Z"
        fill={
          profile === DiSCProfileOption.D
            ? focusStrokeColor
            : passiveStrokeColor
        }
        fill-opacity={
          profile === DiSCProfileOption.D
            ? focusStrokeOpacity
            : passiveStrokeOpacity
        }
      />

      {/* Di */}
      <path
        d="M70 0C57.7174 -1.46468e-07 45.651 3.23182 35.0127 9.37088L70 70V0Z"
        fill={profile === DiSCProfileOption.Di ? focusFill : passiveWedgeFill}
        stroke={borderColor}
      />
      <path
        d="M53.3181 22C54.6501 22 55.7301 21.616 56.5701 20.848C57.4221 20.068 57.8421 19.036 57.8421 17.752C57.8421 16.48 57.4221 15.46 56.5701 14.704C55.7301 13.948 54.6501 13.564 53.3181 13.564H50.3781V22H53.3181ZM53.2821 14.656C54.2901 14.656 55.1061 14.932 55.7181 15.496C56.3301 16.048 56.6421 16.804 56.6421 17.752C56.6421 18.724 56.3301 19.504 55.7181 20.068C55.1061 20.632 54.2901 20.908 53.2821 20.908H51.5301V14.656H53.2821ZM59.4121 14.008C59.4121 14.44 59.7481 14.764 60.1921 14.764C60.6361 14.764 60.9721 14.44 60.9721 14.008C60.9721 13.576 60.6601 13.252 60.1921 13.252C59.7241 13.252 59.4121 13.576 59.4121 14.008ZM60.7441 15.94H59.6401V22H60.7441V15.94Z"
        fill={
          profile === DiSCProfileOption.Di
            ? focusStrokeColor
            : passiveStrokeColor
        }
        fill-opacity={
          profile === DiSCProfileOption.Di
            ? focusStrokeOpacity
            : passiveStrokeOpacity
        }
      />

      {/* iD */}
      <path
        d="M105 9.37822C94.363 3.23693 82.2973 0.00257868 70.0147 1.54086e-06L70 70L105 9.37822Z"
        fill={profile === DiSCProfileOption.iD ? focusFill : passiveWedgeFill}
        stroke={borderColor}
      />
      <path
        d="M78.0301 14.008C78.0301 14.44 78.3661 14.764 78.8101 14.764C79.2541 14.764 79.5901 14.44 79.5901 14.008C79.5901 13.576 79.2781 13.252 78.8101 13.252C78.3421 13.252 78.0301 13.576 78.0301 14.008ZM79.3621 15.94H78.2581V22H79.3621V15.94ZM84.6415 22C85.9735 22 87.0535 21.616 87.8935 20.848C88.7455 20.068 89.1655 19.036 89.1655 17.752C89.1655 16.48 88.7455 15.46 87.8935 14.704C87.0535 13.948 85.9735 13.564 84.6415 13.564H81.7015V22H84.6415ZM84.6055 14.656C85.6135 14.656 86.4295 14.932 87.0415 15.496C87.6535 16.048 87.9655 16.804 87.9655 17.752C87.9655 18.724 87.6535 19.504 87.0415 20.068C86.4295 20.632 85.6135 20.908 84.6055 20.908H82.8535V14.656H84.6055Z"
        fill={
          profile === DiSCProfileOption.iD
            ? focusStrokeColor
            : passiveStrokeColor
        }
        fill-opacity={
          profile === DiSCProfileOption.iD
            ? focusStrokeOpacity
            : passiveStrokeOpacity
        }
      />

      {/* I */}
      <path
        d="M130.622 35C124.48 24.363 115.648 15.5291 105.013 9.38556L70 70L130.622 35Z"
        fill={profile === DiSCProfileOption.i ? focusFill : passiveWedgeFill}
        stroke={borderColor}
      />
      <path
        d="M110.575 25.564H109.423V34H110.575V25.564Z"
        fill={
          profile === DiSCProfileOption.i
            ? focusStrokeColor
            : passiveStrokeColor
        }
        fill-opacity={
          profile === DiSCProfileOption.i
            ? focusStrokeOpacity
            : passiveStrokeOpacity
        }
      />

      {/* iS */}
      <path
        d="M140 70C140 57.7174 136.768 45.651 130.629 35.0127L70 70H140Z"
        fill={profile === DiSCProfileOption.iS ? focusFill : passiveWedgeFill}
        stroke={borderColor}
      />
      <path
        d="M121.921 53.008C121.921 53.44 122.257 53.764 122.701 53.764C123.145 53.764 123.481 53.44 123.481 53.008C123.481 52.576 123.169 52.252 122.701 52.252C122.233 52.252 121.921 52.576 121.921 53.008ZM123.253 54.94H122.149V61H123.253V54.94ZM128.122 52.42C127.306 52.42 126.634 52.636 126.106 53.068C125.578 53.488 125.314 54.04 125.314 54.724C125.314 55.3 125.47 55.768 125.794 56.128C126.118 56.476 126.682 56.776 127.51 57.016L128.506 57.304C129.142 57.484 129.562 57.688 129.766 57.892C129.982 58.096 130.09 58.348 130.09 58.672C130.09 59.596 129.418 60.124 128.278 60.124C127.03 60.124 126.286 59.464 126.238 58.264H125.074C125.134 60.124 126.322 61.144 128.302 61.144C130.114 61.144 131.254 60.184 131.254 58.6C131.254 57.46 130.666 56.788 128.998 56.308L127.786 55.96C126.742 55.66 126.466 55.324 126.466 54.664C126.466 53.908 127.138 53.428 128.11 53.428C129.202 53.428 129.838 54.004 129.922 55.072H131.098C130.99 53.38 129.898 52.42 128.122 52.42Z"
        fill={
          profile === DiSCProfileOption.iS
            ? focusStrokeColor
            : passiveStrokeColor
        }
        fill-opacity={
          profile === DiSCProfileOption.iS
            ? focusStrokeOpacity
            : passiveStrokeOpacity
        }
      />

      {/* Si */}
      <path
        d="M130.622 105C136.763 94.363 139.997 82.2973 140 70.0147L70 70L130.622 105Z"
        fill={profile === DiSCProfileOption.Si ? focusFill : passiveWedgeFill}
        stroke={borderColor}
      />
      <path
        d="M124.729 79.42C123.913 79.42 123.241 79.636 122.713 80.068C122.185 80.488 121.921 81.04 121.921 81.724C121.921 82.3 122.077 82.768 122.401 83.128C122.725 83.476 123.289 83.776 124.117 84.016L125.113 84.304C125.749 84.484 126.169 84.688 126.373 84.892C126.589 85.096 126.697 85.348 126.697 85.672C126.697 86.596 126.025 87.124 124.885 87.124C123.637 87.124 122.893 86.464 122.845 85.264H121.681C121.741 87.124 122.929 88.144 124.909 88.144C126.721 88.144 127.861 87.184 127.861 85.6C127.861 84.46 127.273 83.788 125.605 83.308L124.393 82.96C123.349 82.66 123.073 82.324 123.073 81.664C123.073 80.908 123.745 80.428 124.717 80.428C125.809 80.428 126.445 81.004 126.529 82.072H127.705C127.597 80.38 126.505 79.42 124.729 79.42ZM129.522 80.008C129.522 80.44 129.858 80.764 130.302 80.764C130.746 80.764 131.082 80.44 131.082 80.008C131.082 79.576 130.77 79.252 130.302 79.252C129.834 79.252 129.522 79.576 129.522 80.008ZM130.854 81.94H129.75V88H130.854V81.94Z"
        fill={
          profile === DiSCProfileOption.Si
            ? focusStrokeColor
            : passiveStrokeColor
        }
        fill-opacity={
          profile === DiSCProfileOption.Si
            ? focusStrokeOpacity
            : passiveStrokeOpacity
        }
      />

      {/* S */}
      <path
        d="M105 130.622C115.637 124.48 124.471 115.648 130.614 105.013L70 70L105 130.622Z"
        fill={profile === DiSCProfileOption.S ? focusFill : passiveWedgeFill}
        stroke={borderColor}
      />
      <path
        d="M109.926 106.42C109.11 106.42 108.438 106.636 107.91 107.068C107.382 107.488 107.118 108.04 107.118 108.724C107.118 109.3 107.274 109.768 107.598 110.128C107.922 110.476 108.486 110.776 109.314 111.016L110.31 111.304C110.946 111.484 111.366 111.688 111.57 111.892C111.786 112.096 111.894 112.348 111.894 112.672C111.894 113.596 111.222 114.124 110.082 114.124C108.834 114.124 108.09 113.464 108.042 112.264H106.878C106.938 114.124 108.126 115.144 110.106 115.144C111.918 115.144 113.058 114.184 113.058 112.6C113.058 111.46 112.47 110.788 110.802 110.308L109.59 109.96C108.546 109.66 108.27 109.324 108.27 108.664C108.27 107.908 108.942 107.428 109.914 107.428C111.006 107.428 111.642 108.004 111.726 109.072H112.902C112.794 107.38 111.702 106.42 109.926 106.42Z"
        fill={
          profile === DiSCProfileOption.S
            ? focusStrokeColor
            : passiveStrokeColor
        }
        fill-opacity={
          profile === DiSCProfileOption.S
            ? focusStrokeOpacity
            : passiveStrokeOpacity
        }
      />

      {/* Sc */}
      <path
        d="M70 140C82.2826 140 94.349 136.768 104.987 130.629L70 70V140Z"
        fill={profile === DiSCProfileOption.Sc ? focusFill : passiveWedgeFill}
        stroke={borderColor}
      />
      <path
        d="M80.6428 118.42C79.8268 118.42 79.1548 118.636 78.6268 119.068C78.0988 119.488 77.8348 120.04 77.8348 120.724C77.8348 121.3 77.9908 121.768 78.3148 122.128C78.6388 122.476 79.2028 122.776 80.0308 123.016L81.0268 123.304C81.6628 123.484 82.0828 123.688 82.2868 123.892C82.5028 124.096 82.6108 124.348 82.6108 124.672C82.6108 125.596 81.9388 126.124 80.7988 126.124C79.5508 126.124 78.8068 125.464 78.7588 124.264H77.5948C77.6548 126.124 78.8428 127.144 80.8228 127.144C82.6348 127.144 83.7748 126.184 83.7748 124.6C83.7748 123.46 83.1868 122.788 81.5188 122.308L80.3068 121.96C79.2628 121.66 78.9868 121.324 78.9868 120.664C78.9868 119.908 79.6588 119.428 80.6308 119.428C81.7228 119.428 82.3588 120.004 82.4428 121.072H83.6188C83.5108 119.38 82.4188 118.42 80.6428 118.42ZM88.3876 120.796C87.4636 120.796 86.6956 121.096 86.0836 121.708C85.4716 122.32 85.1596 123.064 85.1596 123.964C85.1596 124.864 85.4716 125.62 86.0836 126.232C86.6956 126.844 87.4636 127.144 88.3876 127.144C89.1556 127.144 89.8276 126.916 90.3916 126.448C90.9676 125.98 91.3276 125.368 91.4836 124.612H90.3916C90.1396 125.512 89.3356 126.124 88.3876 126.124C87.7876 126.124 87.2836 125.92 86.8756 125.5C86.4676 125.08 86.2636 124.576 86.2636 123.964C86.2636 123.352 86.4676 122.848 86.8756 122.44C87.2836 122.02 87.7876 121.816 88.3876 121.816C89.3116 121.816 90.0916 122.404 90.3676 123.28H91.4716C91.3156 122.548 90.9556 121.96 90.3916 121.492C89.8276 121.024 89.1556 120.796 88.3876 120.796Z"
        fill={
          profile === DiSCProfileOption.Sc
            ? focusStrokeColor
            : passiveStrokeColor
        }
        fill-opacity={
          profile === DiSCProfileOption.Sc
            ? focusStrokeOpacity
            : passiveStrokeOpacity
        }
      />

      {/* Cs */}
      <path
        d="M35 130.622C45.637 136.763 57.7027 139.997 69.9853 140L70 70L35 130.622Z"
        fill={profile === DiSCProfileOption.Cs ? focusFill : passiveWedgeFill}
        stroke={borderColor}
      />
      <path
        d="M55.037 123.688C54.845 124.396 54.473 124.972 53.909 125.404C53.345 125.836 52.697 126.052 51.953 126.052C51.065 126.052 50.321 125.74 49.709 125.104C49.109 124.468 48.809 123.7 48.809 122.776C48.809 121.864 49.109 121.096 49.709 120.46C50.321 119.824 51.065 119.512 51.953 119.512C52.661 119.512 53.285 119.716 53.825 120.124C54.377 120.532 54.761 121.072 54.977 121.744H56.213C55.985 120.748 55.481 119.956 54.701 119.344C53.921 118.732 53.009 118.42 51.965 118.42C50.729 118.42 49.697 118.84 48.857 119.68C48.029 120.52 47.609 121.552 47.609 122.776C47.609 124 48.029 125.032 48.857 125.884C49.697 126.724 50.729 127.144 51.965 127.144C53.033 127.144 53.969 126.82 54.749 126.184C55.529 125.536 56.033 124.708 56.237 123.688H55.037ZM59.9541 120.796C58.5981 120.796 57.6501 121.492 57.6501 122.548C57.6501 123.448 58.1301 123.952 59.4141 124.24L60.3021 124.444C61.0581 124.624 61.3101 124.876 61.3101 125.32C61.3101 125.908 60.8181 126.256 60.0021 126.256C59.1021 126.256 58.5861 125.836 58.5741 125.056H57.4221C57.4701 126.4 58.4181 127.144 60.0381 127.144C61.4781 127.144 62.4261 126.412 62.4261 125.212C62.4261 124.3 61.9221 123.784 60.6141 123.484L59.8821 123.316C58.9701 123.112 58.7541 122.896 58.7541 122.464C58.7541 121.996 59.2341 121.684 59.9421 121.684C60.7461 121.684 61.1661 122.02 61.1781 122.692H62.3061C62.2461 121.48 61.3821 120.796 59.9541 120.796Z"
        fill={
          profile === DiSCProfileOption.Cs
            ? focusStrokeColor
            : passiveStrokeColor
        }
        fill-opacity={
          profile === DiSCProfileOption.Cs
            ? focusStrokeOpacity
            : passiveStrokeOpacity
        }
      />

      {/* C */}
      <path
        d="M9.37822 105C15.5195 115.637 24.3515 124.471 34.9873 130.614L70 70L9.37822 105Z"
        fill={profile === DiSCProfileOption.C ? focusFill : passiveWedgeFill}
        stroke={borderColor}
      />
      <path
        d="M33.1695 111.688C32.9775 112.396 32.6055 112.972 32.0415 113.404C31.4775 113.836 30.8295 114.052 30.0855 114.052C29.1975 114.052 28.4535 113.74 27.8415 113.104C27.2415 112.468 26.9415 111.7 26.9415 110.776C26.9415 109.864 27.2415 109.096 27.8415 108.46C28.4535 107.824 29.1975 107.512 30.0855 107.512C30.7935 107.512 31.4175 107.716 31.9575 108.124C32.5095 108.532 32.8935 109.072 33.1095 109.744H34.3455C34.1175 108.748 33.6135 107.956 32.8335 107.344C32.0535 106.732 31.1415 106.42 30.0975 106.42C28.8615 106.42 27.8295 106.84 26.9895 107.68C26.1615 108.52 25.7415 109.552 25.7415 110.776C25.7415 112 26.1615 113.032 26.9895 113.884C27.8295 114.724 28.8615 115.144 30.0975 115.144C31.1655 115.144 32.1015 114.82 32.8815 114.184C33.6615 113.536 34.1655 112.708 34.3695 111.688H33.1695Z"
        fill={
          profile === DiSCProfileOption.C
            ? focusStrokeColor
            : passiveStrokeColor
        }
        fill-opacity={
          profile === DiSCProfileOption.C
            ? focusStrokeOpacity
            : passiveStrokeOpacity
        }
      />

      {/* Cd */}
      <path
        d="M0 70C-1.07378e-06 82.2826 3.23182 94.349 9.37087 104.987L70 70L0 70Z"
        fill={profile === DiSCProfileOption.Cd ? focusFill : passiveWedgeFill}
        stroke={borderColor}
      />
      <path
        d="M17.0468 84.688C16.8548 85.396 16.4828 85.972 15.9188 86.404C15.3548 86.836 14.7068 87.052 13.9628 87.052C13.0748 87.052 12.3308 86.74 11.7188 86.104C11.1188 85.468 10.8188 84.7 10.8188 83.776C10.8188 82.864 11.1188 82.096 11.7188 81.46C12.3308 80.824 13.0748 80.512 13.9628 80.512C14.6708 80.512 15.2948 80.716 15.8348 81.124C16.3868 81.532 16.7708 82.072 16.9868 82.744H18.2228C17.9948 81.748 17.4908 80.956 16.7108 80.344C15.9308 79.732 15.0188 79.42 13.9748 79.42C12.7388 79.42 11.7068 79.84 10.8668 80.68C10.0388 81.52 9.6188 82.552 9.6188 83.776C9.6188 85 10.0388 86.032 10.8668 86.884C11.7068 87.724 12.7388 88.144 13.9748 88.144C15.0428 88.144 15.9788 87.82 16.7588 87.184C17.5388 86.536 18.0428 85.708 18.2468 84.688H17.0468ZM22.5399 88.144C23.5119 88.144 24.3759 87.7 24.9159 86.932V88H25.9599V79.108H24.8559V82.912C24.3159 82.192 23.4999 81.796 22.5759 81.796C21.6879 81.796 20.9559 82.096 20.3679 82.708C19.7799 83.32 19.4919 84.076 19.4919 84.988C19.4919 85.888 19.7799 86.644 20.3559 87.244C20.9439 87.844 21.6639 88.144 22.5399 88.144ZM20.6319 84.976C20.6319 84.364 20.8359 83.86 21.2439 83.44C21.6519 83.02 22.1559 82.804 22.7679 82.804C23.3799 82.804 23.8839 83.008 24.2799 83.428C24.6879 83.848 24.8919 84.364 24.8919 84.976C24.8919 85.588 24.6879 86.092 24.2799 86.512C23.8839 86.932 23.3799 87.136 22.7679 87.136C22.1559 87.136 21.6519 86.932 21.2439 86.512C20.8359 86.092 20.6319 85.588 20.6319 84.976Z"
        fill={
          profile === DiSCProfileOption.Cd
            ? focusStrokeColor
            : passiveStrokeColor
        }
        fill-opacity={
          profile === DiSCProfileOption.Cd
            ? focusStrokeOpacity
            : passiveStrokeOpacity
        }
      />
    </svg>
  );
};
