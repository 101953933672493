import React from "react";

function AhaSmallActiveIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1599_103169)">
        <path
          d="M15.3828 15.3829C16.332 14.4335 17.5535 13.8035 18.8774 13.5807C17.7047 12.8607 16.3555 12.4795 14.9794 12.4792C14.7865 12.4792 14.5973 12.4938 14.4082 12.5081C14.5227 12.8277 14.5248 13.1767 14.4143 13.4977C14.3038 13.8187 14.0872 14.0925 13.8003 14.2739C13.5133 14.4553 13.1731 14.5335 12.8357 14.4956C12.4983 14.4577 12.1839 14.306 11.9443 14.0655L11.3167 13.4378C10.3762 13.9643 9.56017 14.687 8.924 15.557C8.28782 16.427 7.84645 17.4238 7.62988 18.4795H10.1152C10.5131 18.4795 10.8946 18.6376 11.1759 18.9189C11.4572 19.2002 11.6152 19.5817 11.6152 19.9795C11.6152 20.3774 11.4572 20.7589 11.1759 21.0402C10.8946 21.3215 10.5131 21.4795 10.1152 21.4795H7.63C7.84663 22.5352 8.28799 23.5318 8.92409 24.4016C9.56019 25.2715 10.3761 25.9942 11.3164 26.5206L11.9434 25.8936C12.1826 25.652 12.4971 25.4993 12.8349 25.4607C13.1727 25.4222 13.5136 25.5002 13.801 25.6818C14.0884 25.8634 14.3052 26.1378 14.4155 26.4594C14.5257 26.781 14.5228 27.1306 14.4073 27.4504C14.5967 27.4647 14.7863 27.4793 14.9794 27.4793C16.354 27.479 17.7017 27.0986 18.8736 26.3801C17.6996 26.1788 16.6035 25.6585 15.7054 24.8761C14.8072 24.0937 14.1415 23.0793 13.7811 21.9439C13.4208 20.8086 13.3796 19.596 13.6621 18.4388C13.9446 17.2817 14.5399 16.2245 15.3829 15.3829L15.3828 15.3829Z"
          fill="#58EBD2"
        />
        <path
          d="M24.5749 15.3828C23.8527 14.659 22.97 14.1158 21.9983 13.7973C21.0267 13.4788 19.9936 13.394 18.9831 13.5498C17.9725 13.7056 17.013 14.0976 16.1824 14.694C15.3519 15.2904 14.6737 16.0743 14.2031 16.9821C13.7325 17.8898 13.4826 18.8958 13.4739 19.9183C13.4651 20.9407 13.6977 21.9508 14.1527 22.8665C14.6078 23.7822 15.2724 24.5776 16.0926 25.1881C16.9129 25.7986 17.8655 26.207 18.8733 26.3801C19.2385 26.4444 19.6085 26.4776 19.9793 26.4795C21.2649 26.4795 22.5217 26.0982 23.5906 25.3839C24.6596 24.6696 25.4927 23.6544 25.9846 22.4666C26.4765 21.2788 26.6052 19.9718 26.3542 18.7109C26.1033 17.45 25.4841 16.2918 24.5749 15.3828ZM22.4538 22.4541C22.2824 22.6241 22.0926 22.7745 21.8879 22.9025C21.2107 23.3239 20.4127 23.5086 19.6192 23.4274C18.8257 23.3463 18.0815 23.0039 17.5037 22.4541C17.0141 21.9646 16.6806 21.3408 16.5455 20.6618C16.4104 19.9828 16.4797 19.2789 16.7447 18.6393C17.0096 17.9996 17.4583 17.4529 18.0339 17.0682C18.6096 16.6836 19.2864 16.4783 19.9787 16.4783C20.6711 16.4783 21.3479 16.6836 21.9236 17.0682C22.4992 17.4529 22.9479 17.9996 23.2128 18.6393C23.4777 19.2789 23.547 19.9828 23.412 20.6618C23.2769 21.3408 22.9434 21.9646 22.4538 22.4541Z"
          fill="#35363E"
        />
        <path
          d="M19.9795 28.3438C19.5817 28.3439 19.2002 28.5019 18.9189 28.7832C18.6377 29.0645 18.4796 29.446 18.4795 29.8438V38.458C18.4795 38.8558 18.6375 39.2374 18.9188 39.5187C19.2001 39.8 19.5817 39.958 19.9795 39.958C20.3773 39.958 20.7588 39.8 21.0402 39.5187C21.3215 39.2374 21.4795 38.8558 21.4795 38.458V29.8438C21.4794 29.446 21.3213 29.0645 21.04 28.7832C20.7588 28.5019 20.3773 28.3439 19.9795 28.3438Z"
          fill="#35363E"
        />
        <path
          d="M28.0145 25.8936C27.7332 25.6124 27.3517 25.4543 26.9539 25.4543C26.5562 25.4543 26.1747 25.6124 25.8934 25.8936C25.6121 26.1749 25.4541 26.5564 25.4541 26.9542C25.4541 27.352 25.6121 27.7335 25.8934 28.0147L31.9852 34.1065C32.1245 34.2458 32.2898 34.3563 32.4718 34.4317C32.6538 34.507 32.8488 34.5458 33.0457 34.5458C33.2427 34.5458 33.4377 34.507 33.6197 34.4317C33.8017 34.3563 33.967 34.2458 34.1063 34.1065C34.2456 33.9673 34.356 33.8019 34.4314 33.62C34.5068 33.438 34.5456 33.243 34.5456 33.046C34.5456 32.849 34.5068 32.654 34.4314 32.472C34.356 32.2901 34.2456 32.1247 34.1063 31.9854L28.0145 25.8936Z"
          fill="#35363E"
        />
        <path
          d="M38.458 18.4795H29.8438C29.4459 18.4795 29.0644 18.6375 28.7831 18.9188C28.5018 19.2001 28.3438 19.5817 28.3438 19.9795C28.3438 20.3773 28.5018 20.7588 28.7831 21.0402C29.0644 21.3215 29.4459 21.4795 29.8438 21.4795H38.458C38.8558 21.4795 39.2374 21.3215 39.5187 21.0402C39.8 20.7588 39.958 20.3773 39.958 19.9795C39.958 19.5817 39.8 19.2001 39.5187 18.9188C39.2374 18.6375 38.8558 18.4795 38.458 18.4795Z"
          fill="#35363E"
        />
        <path
          d="M26.9529 14.505C27.1499 14.5052 27.345 14.4665 27.527 14.3911C27.709 14.3157 27.8743 14.205 28.0134 14.0655L34.1062 7.97372C34.2455 7.83444 34.356 7.6691 34.4313 7.48713C34.5067 7.30516 34.5455 7.11013 34.5455 6.91317C34.5455 6.71621 34.5067 6.52117 34.4313 6.3392C34.356 6.15723 34.2455 5.99189 34.1062 5.85262C33.9669 5.71335 33.8016 5.60287 33.6196 5.5275C33.4377 5.45212 33.2426 5.41333 33.0457 5.41333C32.8487 5.41333 32.6537 5.45213 32.4717 5.5275C32.2897 5.60287 32.1244 5.71335 31.9851 5.85263L25.8923 11.9444C25.6826 12.1542 25.5398 12.4215 25.4819 12.7124C25.4241 13.0033 25.4538 13.3049 25.5673 13.579C25.6808 13.853 25.873 14.0873 26.1197 14.2521C26.3663 14.4169 26.6562 14.5049 26.9529 14.505Z"
          fill="#35363E"
        />
        <path
          d="M19.9795 11.6152C20.3773 11.6151 20.7588 11.4571 21.04 11.1758C21.3213 10.8945 21.4794 10.513 21.4795 10.1152V1.5C21.4795 1.10218 21.3215 0.720644 21.0402 0.43934C20.7588 0.158035 20.3773 0 19.9795 0C19.5817 0 19.2001 0.158035 18.9188 0.43934C18.6375 0.720644 18.4795 1.10218 18.4795 1.5V10.1152C18.4796 10.513 18.6377 10.8945 18.9189 11.1758C19.2002 11.4571 19.5817 11.6151 19.9795 11.6152Z"
          fill="#35363E"
        />
        <path
          d="M11.3164 13.4377L11.9441 14.0654C12.1833 14.3071 12.4979 14.4598 12.8358 14.4983C13.1736 14.5367 13.5145 14.4587 13.802 14.277C14.0894 14.0953 14.3062 13.8208 14.4164 13.4991C14.5266 13.1774 14.5236 12.8277 14.4079 12.508C14.3377 12.2966 14.2205 12.1039 14.0651 11.9443L7.97335 5.8525C7.69188 5.57214 7.31068 5.41489 6.91341 5.41528C6.51614 5.41567 6.13525 5.57366 5.85433 5.85458C5.57342 6.13549 5.41543 6.51638 5.41504 6.91365C5.41465 7.31093 5.57189 7.69213 5.85226 7.97359L11.3164 13.4377Z"
          fill="#35363E"
        />
        <path
          d="M10.1152 21.4795C10.5131 21.4795 10.8946 21.3215 11.1759 21.0402C11.4572 20.7588 11.6152 20.3773 11.6152 19.9795C11.6152 19.5817 11.4572 19.2001 11.1759 18.9188C10.8946 18.6375 10.5131 18.4795 10.1152 18.4795H1.5C1.10218 18.4795 0.720644 18.6375 0.43934 18.9188C0.158035 19.2001 0 19.5817 0 19.9795C0 20.3773 0.158035 20.7588 0.43934 21.0402C0.720644 21.3215 1.10218 21.4795 1.5 21.4795H10.1152Z"
          fill="#35363E"
        />
        <path
          d="M11.9432 25.8937L11.3162 26.5207L5.85238 31.9855C5.71311 32.1247 5.60263 32.2901 5.52726 32.4721C5.45188 32.654 5.41309 32.8491 5.41309 33.046C5.41309 33.243 5.45188 33.438 5.52725 33.62C5.60263 33.802 5.7131 33.9673 5.85238 34.1066C5.99165 34.2458 6.15699 34.3563 6.33896 34.4317C6.52093 34.5071 6.71596 34.5459 6.91292 34.5459C7.10989 34.5459 7.30492 34.5071 7.48689 34.4317C7.66886 34.3563 7.8342 34.2458 7.97347 34.1066L14.0643 28.0148C14.2198 27.8551 14.3371 27.6621 14.4071 27.4504C14.5226 27.1307 14.5255 26.7811 14.4152 26.4594C14.305 26.1378 14.0882 25.8635 13.8008 25.6819C13.5133 25.5003 13.1725 25.4223 12.8347 25.4608C12.4969 25.4993 12.1824 25.652 11.9432 25.8937Z"
          fill="#35363E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1599_103169">
          <rect width="39.958" height="39.958" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AhaSmallActiveIcon;
