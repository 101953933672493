import { Button } from "components/base";
import { Scalars } from "graphql/generated";
import { t } from "i18n-js";
import React from "react";
import getCsrfToken from "utils/helpers/get-csrf-token";

function ZoomOauthButton({
  userId,
  projectId,
  id = "zoom-oauth",
  hasOwnButton = true,
}: {
  userId: Scalars["ID"];
  projectId: Scalars["ID"];
  id?: string;
  hasOwnButton?: boolean;
}) {
  return (
    <form
      id={id}
      method="post"
      action={`/users/auth/zoom?user_id=${userId}&project_id=${projectId}`}
      data-testid="zoom-oauth"
    >
      {hasOwnButton ? (
        <Button type="submit" className="w-full">
          {t("shared.addZoom")}
        </Button>
      ) : undefined}
      <input type="hidden" name="authenticity_token" value={getCsrfToken()} />
    </form>
  );
}

export default ZoomOauthButton;
