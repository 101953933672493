import { Caption1, Display4, Heading3 } from "components/Typography";
import { t } from "i18n-js";
import { ChartBar, Files, ListNumbers, SquaresFour } from "icons";
import React, { ReactNode } from "react";

import { TypeSelectorViewComponent } from "./TypeSelectorView.types";

const Type = ({
  Icon,
  title,
  description,
  onClick,
  disabled,
}: {
  Icon: ReactNode;
  title: string;
  description: string;
  onClick: () => void;
  disabled: boolean;
}) => (
  <button
    className="flex min-h-[248px] flex-col justify-between rounded border border-neutral-5 p-8 text-left"
    onClick={onClick}
    disabled={disabled}
  >
    <div className="flex h-8 w-8 items-center justify-center rounded-full bg-neutral-0">
      {Icon}
    </div>
    <div>
      <Heading3 className="mb-2 text-neutral-90">{title}</Heading3>
      <Caption1 className="text-neutral-70">{description}</Caption1>
    </div>
  </button>
);

const TypeSelectorView: TypeSelectorViewComponent = ({
  onChange,
  isLoading,
}) => {
  return (
    <>
      <Display4 className="mb-6">
        {t("workshopEditor.activityTypeSelector.title")}
      </Display4>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <Type
          Icon={<ListNumbers />}
          title={t("workshopEditor.activityTypeSelector.discussion.title")}
          description={t(
            "workshopEditor.activityTypeSelector.discussion.description",
          )}
          onClick={() => onChange("discussion")}
          disabled={isLoading}
        />
        <Type
          Icon={<Files />}
          title={t("workshopEditor.activityTypeSelector.resource.title")}
          description={t(
            "workshopEditor.activityTypeSelector.resource.description",
          )}
          onClick={() => onChange("resource")}
          disabled={isLoading}
        />
        <Type
          Icon={<SquaresFour />}
          title={t("workshopEditor.activityTypeSelector.breakout.title")}
          description={t(
            "workshopEditor.activityTypeSelector.breakout.description",
          )}
          onClick={() => onChange("breakout")}
          disabled={isLoading}
        />
        <Type
          Icon={<ChartBar />}
          title={t("workshopEditor.activityTypeSelector.poll.title")}
          description={t(
            "workshopEditor.activityTypeSelector.poll.description",
          )}
          onClick={() => onChange("poll")}
          disabled={isLoading}
        />
      </div>
    </>
  );
};

export default TypeSelectorView;
