import {
  OrganizationSettingsByOrganizationQuery,
  OrganizationSettingsByOrganizationQueryVariables,
  useOrganizationSettingsByOrganizationQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query OrganizationSettingsByOrganization($organizationId: ID!) {
    organizationSettingsByOrganization(organizationId: $organizationId) {
      firstName
      fullName
      joinedDate
      lastName
    }
  }
`;

function useOrganizationSettings(
  variables: OrganizationSettingsByOrganizationQueryVariables,
): {
  organizationSettingsByOrganization?: OrganizationSettingsByOrganizationQuery["organizationSettingsByOrganization"];
} & UseQueryResult<OrganizationSettingsByOrganizationQuery, unknown> {
  const response = useOrganizationSettingsByOrganizationQuery(variables, {
    suspense: true,
  });

  return {
    organizationSettingsByOrganization:
      response.data?.organizationSettingsByOrganization,
    ...response,
  };
}

export { useOrganizationSettings };
