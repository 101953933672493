import { Input } from "components/base";
import {
  ActivityTimeSelector,
  ActivityTypeSelector,
  WorkshopSectionSelector,
} from "components/partial";
import { ActivityProps } from "components/partial/DraggableList/DraggableItem/DraggableItem.types";
import { Display4 } from "components/Typography";
import { t } from "i18n-js";
import React, { useEffect, useState } from "react";

import { ActivityContentHeaderComponent } from "./ActivityContentHeader.types";

export const ActivityContentHeader: ActivityContentHeaderComponent = ({
  selectedItem,
  onActivityDataUpdate,
}) => {
  const selectedData = selectedItem?.data as ActivityProps;
  const isNewItem = selectedData.title === t("workshopEditor.untitled");

  const [inputValue, setInputValue] = useState(
    isNewItem ? "" : selectedData.title,
  );

  useEffect(() => {
    setInputValue(isNewItem ? "" : selectedData.title);

    if (isNewItem) {
      titleInputRef.current?.focus();
    }
  }, [selectedItem.id, isNewItem]);

  const titleInputRef = React.useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onActivityDataUpdate({ title: value });
    setInputValue(value);
  };

  return (
    <>
      <Display4 className="mb-6 text-neutral-900">
        {t("workshopEditor.addDetailsToActivity")}
      </Display4>
      <Input
        value={inputValue}
        placeholder={isNewItem ? t("workshopEditor.untitled") : undefined}
        onChange={handleInputChange}
        ref={titleInputRef}
        autoFocus={isNewItem}
      />
      <div className="my-4 flex justify-between">
        <div className="flex gap-4">
          <ActivityTypeSelector
            selectedType={selectedData.type}
            onChange={(type) => onActivityDataUpdate({ type })}
          />
          <ActivityTimeSelector
            times={[5, 15, 30, 45, 60]}
            selectedTime={selectedData.time}
            onChange={(time) => onActivityDataUpdate({ time })}
          />
        </div>
        <WorkshopSectionSelector selectedItem={selectedItem} />
      </div>
    </>
  );
};
