/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable unicorn/no-null */

import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartType,
  Legend,
  LinearScale,
  LineElement,
  Plugin,
  PointElement,
  ScriptableTooltipContext,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { noop } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { getElementAtEvent, Line } from "react-chartjs-2";

import { LineChartComponent } from "./LineChart.types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

// a function to truncate a string to fit within a max width
const truncateString = (
  ctx: CanvasRenderingContext2D,
  str: string,
  maxWidth: number,
) => {
  let width = ctx.measureText(str).width;
  const ellipsis = "…";
  const ellipsisWidth = ctx.measureText(ellipsis).width;
  if (width <= maxWidth || width <= ellipsisWidth) {
    return str;
  } else {
    let len = str.length;
    while (width >= maxWidth - ellipsisWidth && len-- > 0) {
      str = str.slice(0, Math.max(0, len));
      width = ctx.measureText(str).width;
    }
    return str + ellipsis;
  }
};

// tooltip position 'average' is customized with positioners to show it in top
// DOCS: https://www.chartjs.org/docs/latest/configuration/tooltip.html#custom-position-modes

Tooltip.positioners.average = function (elements, position) {
  const {
    chartArea: { top },
    scales,
  } = this.chart;
  return {
    x: scales.x.getPixelForValue(
      scales.x.getValueForPixel(position.x) as number,
    ),
    y: top,
    xAlign: "center",
    yAlign: "bottom",
  };
};

const LineChart: LineChartComponent = ({
  data,
  labels,
  min,
  max,
  stepSize,
  tooltipData = null,
  attendeeCountList = [],
  alterWidth,
  onClick = noop,
  hoverable = false,
  breakevenPoint,
  displayPoints = 5,
  xGrid = false,
  ticksDisplay = false,
}) => {
  const chartRef = useRef<ChartJS>(null);
  const containerBodyRef = useRef<HTMLDivElement>(null);
  const outerContainerRef = useRef<HTMLDivElement>(null);
  const { current: chart } = chartRef;
  const [chartKey, setChartKey] = useState<number>(Date.now());

  const handleClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!chart) {
      return;
    }
    const elementDetails = getElementAtEvent(chart, event);
    onClick(elementDetails[0]?.index);
  };

  useEffect(() => {
    if (outerContainerRef?.current) {
      outerContainerRef.current.scrollTo({
        left:
          outerContainerRef.current.scrollWidth -
          outerContainerRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  }, [outerContainerRef?.current]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.3,
      },
    },
    // fixes the blur chart
    devicePixelRatio: 3,
    scales: {
      x: {
        grid: {
          display: xGrid,
        },
        border: {
          display: false,
        },
      },
      y: {
        min,
        max,
        ticks: {
          stepSize: stepSize,
          display: ticksDisplay,
        },
        grid: {
          color: "#E5E8EA",
          drawBorder: false,
        },
        border: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        bottom: 50,
        left: 70,
      },
    },
    plugins: {
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: !tooltipData,
        // tooltip position 'average' is customized with positioners to show it in top
        position: "average",
        external: tooltipData
          ? function (context: ScriptableTooltipContext<ChartType>) {
              // Tooltip Element
              let tooltipEl = document.querySelector("#chartjs-tooltip");

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "chartjs-tooltip";
                tooltipEl.innerHTML =
                  "<table style='background: #000000 ; color : #ffffff; padding:10px; border-radius:20px'></table>";
                document.body.append(tooltipEl);
              }

              // Hide if no tooltip
              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove("above", "below", "no-transform");
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add("no-transform");
              }

              // Set Text
              if (tooltipModel.body) {
                const titleLines = tooltipModel.title || [];
                const [dp] = [...tooltipModel?.dataPoints];
                const bodyData = tooltipData?.[dp.dataIndex];

                const progressBarHtml = (data: number, label: string) => {
                  const percentage = (data / 5) * 100;
                  const barWidth = (percentage * 75) / 100;
                  let barColor;
                  if (percentage > 0 && percentage < 40) {
                    barColor = "red";
                  } else if (percentage >= 40 && percentage < 65) {
                    barColor = "#EB8424";
                  } else {
                    barColor = "#00AE8F";
                  }
                  return `<td><div class='mx-[15px] mt-[10px] text-left'>${label}</div>
                    <div class='mt-[10px] mx-[15px] w-[75px] rounded' style='background-color:#d9d9d9'>
                      <div class='h-[2px]' style='background-color:${barColor}; width:${
                    barWidth ? barWidth : "0"
                  }px'></div>
                  </div>
                  </td>
                  `;
                };

                let innerHtml = "<thead></thead><tbody>";

                innerHtml +=
                  "<tr><td>" +
                  "<div class='mx-[15px] mt-[10px] text-neutral-700'>" +
                  bodyData?.date +
                  "</div>" +
                  "</td></tr>" +
                  "<tr style='border-bottom:1px solid #ffffff80; margin-bottom:10px'><td>" +
                  "<div class='mx-[15px] mb-[10px]'>Number of responses:</div>" +
                  "</td> <td>" +
                  "<div class='mx-[15px] mb-[10px]'>" +
                  bodyData.numberOfResponses +
                  "</div></td></tr>" +
                  "<tr><td>" +
                  "<div class='mx-[15px] mt-[10px]'>Average</div>" +
                  "</td> <td>" +
                  '<div class="mx-[15px] mt-[10px]" style="color: ' +
                  (bodyData?.average >= 3 ? "#58EBD2" : "#FFA3A3") +
                  ';">' +
                  bodyData?.average +
                  "</div></td></tr>";
                innerHtml +=
                  "<tr><td>" +
                  "<div class='mx-[15px]'>Lowest score</div>" +
                  "</td> <td>" +
                  '<div class="mx-[15px]" style="color: ' +
                  (bodyData?.lowestScore >= 3 ? "#58EBD2" : "#FFA3A3") +
                  '">' +
                  bodyData?.lowestScore +
                  "</div></td></tr>";
                innerHtml +=
                  "<tr style='border-bottom:1px solid #ffffff80; margin-bottom:10px'><td>" +
                  "<div class='mx-[15px] mb-[10px]'>Highest score</div>" +
                  "</td> <td>" +
                  '<div class="mx-[15px] mb-[10px]" style="color: ' +
                  (bodyData?.highestScore >= 3 ? "#58EBD2" : "#FFA3A3") +
                  '">' +
                  bodyData?.highestScore +
                  "</div></td></tr>";

                if (bodyData?.alignment)
                  innerHtml +=
                    "<tr>" + progressBarHtml(bodyData?.alignment, "Alignment");

                if (bodyData?.autonomy)
                  innerHtml += !bodyData?.alignment
                    ? "<tr>"
                    : "" + progressBarHtml(bodyData?.autonomy, "Autonomy");
                innerHtml += "</tr>";

                if (bodyData?.connection)
                  innerHtml +=
                    "<tr>" +
                    "<div class='mb-[20px]'>" +
                    progressBarHtml(bodyData?.connection, "Connection") +
                    "</div>";

                if (bodyData?.energy)
                  innerHtml += !bodyData?.connection
                    ? "<tr>"
                    : "" +
                      "<div class='mb-[20px]'>" +
                      progressBarHtml(bodyData?.energy, "Energy") +
                      "</div>";
                innerHtml += "</tr>";

                innerHtml += "</tbody>";

                const tableRoot = tooltipEl.querySelector("table");
                tableRoot.innerHTML = innerHtml;
              }

              const position = context.chart.canvas.getBoundingClientRect();
              //  const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = "absolute";
              tooltipEl.style.left =
                position.left +
                // window.pageXOffset +
                tooltipModel.caretX -
                60 +
                "px";
              tooltipEl.style.top =
                position.bottom +
                window.pageYOffset +
                // tooltipModel.caretY +
                10 +
                "px";
              // tooltipEl.style.font = bodyFont.string;
              tooltipEl.style.padding =
                tooltipModel.padding + 5 + "px " + tooltipModel.padding + "px";
              tooltipEl.style.pointerEvents = "none";
              tooltipEl.style.borderRadius = "15px 15px 15px 15px";
            }
          : noop,
        yAlign: "bottom",
        backgroundColor: "#292D30",
        callbacks: {
          label: function (tooltipItem: TooltipItem<ChartType>) {
            return attendeeCountList[tooltipItem?.dataIndex] <
              MIN_ATTENDEE_COUNT || data[tooltipItem?.dataIndex] === 0
              ? "Not enough data"
              : "View workshop summary";
          },
        },
        displayColors: false,
      },
      legend: {
        display: false,
      },
    },
  };

  const half = breakevenPoint ? breakevenPoint : (max - min) / 2 + min;
  const pointRadiusList: number[] = [];
  const pointHoverRadiusList: number[] = [];
  const MIN_ATTENDEE_COUNT = 2;
  const POINT_RADIUS = 6;
  const POINT_HOVER_RADIUS = 4;

  // Creates array of points to be plotted on graph
  if (attendeeCountList?.length > 0) {
    for (const [index, item] of attendeeCountList.entries()) {
      if (item >= MIN_ATTENDEE_COUNT && data[index] > 0) {
        pointRadiusList.push(POINT_RADIUS);
        pointHoverRadiusList.push(POINT_HOVER_RADIUS);
      } else {
        pointRadiusList.push(0);
        pointHoverRadiusList.push(0);
      }
    }
  }

  const lineData = {
    labels: [...labels, undefined].map(() => ""),
    datasets: [
      {
        data: [...data, undefined],
        pointRadius: attendeeCountList?.length > 0 ? pointRadiusList : 6,
        pointHoverRadius:
          attendeeCountList?.length > 0 ? pointHoverRadiusList : 4,
        pointHitRadius: 60,
        backgroundColor: data.map((value) =>
          value >= half ? "#16C7A7" : "#FF6060",
        ),
        borderWidth: 2,
        spanGaps: true,
        borderColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          const dataset = context.dataset.data;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }

          const step = 1 / (dataset.length - 1);
          const gradient = ctx.createLinearGradient(0, 0, chartArea.right, 0);

          for (const [index, value] of dataset.entries()) {
            gradient.addColorStop(
              index * step,
              value === null || value >= half
                ? "rgba(22, 199, 167, 0.3)"
                : "rgba(255, 96, 96, 0.3)",
            );
          }

          return gradient;
        },
      },
    ],
  } as ChartData<"line", number[], string>;

  // a chart.js plugin to render custom colors and truncation on the ticks
  const customTicksPlugin = {
    id: "customTicks",
    beforeDatasetDraw: (chart) => {
      const {
        ctx,
        chartArea: { bottom, top, height },
        scales: { x },
      } = chart;
      const activeElements = chart.getActiveElements();

      // to add the hover effect
      if (activeElements[0] && hoverable) {
        const index = activeElements[0]?.index;
        const sectionWidth =
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          x?._gridLineItems[0]?.x1 - x?._gridLineItems[1]?.x1;
        ctx.fillStyle = "rgba(95, 99, 104, 0.1)";
        ctx.fillRect(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (x?._gridLineItems[index]?.x1 as number) + Math.abs(sectionWidth) / 2,
          top,
          sectionWidth,
          height + 80,
        );
      }

      ctx.save();

      for (const [index, tick] of x.ticks.entries()) {
        const label = labels[index];
        let spacing = 25;

        if (!label) {
          continue;
        }

        if (label.title) {
          ctx.fillStyle = "#292D30";
          ctx.font = "bold 10px sans-serif";
          ctx.textAlign = "center";
          ctx.fillText(
            truncateString(ctx, label.title, 90),
            x.getPixelForTick(tick.value),
            bottom + spacing,
          );
          ctx.restore();

          spacing += 15;
        }

        if (label.date) {
          ctx.fillStyle = "#292D30";
          ctx.font = "10px sans-serif";
          ctx.textAlign = "center";
          ctx.fillText(
            truncateString(ctx, label.date, 90),
            x.getPixelForTick(tick.value),
            bottom + spacing,
          );
          ctx.restore();

          spacing += 15;
        }

        if (label.value) {
          ctx.fillStyle = "#959A9F";
          ctx.font = "10px sans-serif";
          ctx.textAlign = "center";
          ctx.fillText(
            truncateString(ctx, label.value.toString(), 90),
            x.getPixelForTick(tick.value),
            bottom + spacing,
          );
          ctx.restore();

          spacing += 15;
        }

        if (label.numberOfAttendees) {
          ctx.fillStyle = "#A7ABB0";
          ctx.font = "8px sans-serif";
          ctx.textAlign = "center";
          ctx.fillText(
            truncateString(ctx, label.numberOfAttendees.toString(), 90),
            x.getPixelForTick(tick.value),
            bottom + spacing,
          );
          ctx.restore();
        }
      }
    },
  } as Plugin<"line">;
  if (containerBodyRef.current && labels.length > displayPoints) {
    containerBodyRef.current.style.width = `${
      (alterWidth ? 340 : 600) + (labels.length - displayPoints) * 80
    }px`;
  }

  useEffect(() => {
    setChartKey(Date.now());
  }, [labels, data]);

  //using chartKey to render updated on change of data or labels.
  const renderChart = () => (
    <Line
      key={chartKey}
      ref={chartRef}
      options={options}
      data={lineData}
      plugins={[customTicksPlugin]}
      onClick={handleClick}
    />
  );

  return (
    <div className="relative h-full overflow-x-auto">
      <div className="absolute h-full w-[10%] bg-gradient-to-r from-white" />
      <div
        className={`h-full ${
          !!alterWidth ? "w-[300px]" : "w-[640px]"
        } !no-scrollbar max-w-[640px] overflow-x-scroll`}
        ref={outerContainerRef}
      >
        <div
          className="!no-scrollbar h-full"
          style={{ width: "640px" }}
          ref={containerBodyRef}
        >
          {renderChart()}
        </div>
      </div>
    </div>
  );
};

export default LineChart;
