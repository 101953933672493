import { useCreateWorkshopActivityMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation CreateWorkshopActivity(
    $workshopActivity: CreateWorkshopActivityInput!
  ) {
    createWorkshopActivity(workshopActivity: $workshopActivity) {
      id
    }
  }
`;

export function useCreateActivity() {
  const queryClient = useQueryClient();
  return useCreateWorkshopActivityMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["WorkshopById"]);
      await queryClient.refetchQueries(["WorkshopById"]);
    },
  });
}
