import {
  GetEmotionExpressionQuery,
  GetEmotionExpressionQueryVariables,
  useGetEmotionExpressionQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";
gql`
  query GetEmotionExpression($workshopId: ID!) {
    getEmotionExpression(workshopId: $workshopId) {
      timeStamp
      sentiment
    }
  }
`;

function useEmotionExpression(variables: GetEmotionExpressionQueryVariables): {
  getEmotionExpression?: GetEmotionExpressionQuery["getEmotionExpression"];
} & UseQueryResult<GetEmotionExpressionQuery, unknown> {
  const response = useGetEmotionExpressionQuery(variables);

  return {
    getEmotionExpression: response.data?.getEmotionExpression || [],
    ...response,
  };
}
export { useEmotionExpression };
