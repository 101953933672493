import clsx from "clsx";
import { Heading3, Heading3Strong, HStack, VStack } from "components";
import { Input } from "components/base";
import { t } from "i18n-js";
import { noop, uniqueId } from "lodash";
import { Check } from "phosphor-react";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import {
  WeekLengthOptionProps,
  WeekLengthPickerDropdownProps,
  WeekLengthPickerProps,
} from "./WeekLengthPicker.types";

const getWeekLabel = (weekLength: string) =>
  t(
    `component.weekLengthPicker.weekLabel.${
      Number(weekLength) === 1 ? "singular" : "plural"
    }`,
    { weekLength },
  );

function WeekLengthOption({
  weekLength,
  isSelected,
  onClick = noop,
}: WeekLengthOptionProps) {
  const label = getWeekLabel(weekLength);
  return (
    <HStack
      className="cursor-pointer py-2 pl-5 text-white hover:bg-neutral-70"
      onClick={onClick}
      aria-label={label}
    >
      <span className="mr-4">
        {isSelected ? <Check size={24} /> : <div className="w-6" />}
      </span>
      {isSelected ? (
        <Heading3Strong>{label}</Heading3Strong>
      ) : (
        <Heading3>{label}</Heading3>
      )}
    </HStack>
  );
}

const MAX_WEEKS = 20;

const WEEK_LENGTH_PICKER_OPTIONS = [
  ...Array.from({ length: MAX_WEEKS + 1 }).keys(),
].slice(1);
function WeekLengthPickerDropdown({
  onSelect = noop,
  hasLabel = false,
  selectedWeekLength,
}: WeekLengthPickerDropdownProps) {
  const selectedOptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    selectedOptionRef?.current?.scrollIntoView();
  }, []);

  const renderTimes = () =>
    WEEK_LENGTH_PICKER_OPTIONS.map((weekLength: number) => {
      const strWeekLength = weekLength.toString();
      const isSelectedWeekLength = strWeekLength === selectedWeekLength;
      return (
        <div
          key={uniqueId("option-")}
          ref={isSelectedWeekLength ? selectedOptionRef : undefined}
        >
          <WeekLengthOption
            weekLength={strWeekLength}
            isSelected={isSelectedWeekLength}
            onClick={() => {
              onSelect(strWeekLength);
            }}
          />
        </div>
      );
    });

  return (
    <VStack
      className={clsx(
        "no-scrollbar absolute top-12 max-h-48 w-44 overflow-scroll rounded-lg bg-neutral-90 pt-2",
        Z_INDEX_LEVELS.MODAL_CONTROL,
        hasLabel && "top-20",
      )}
    >
      {renderTimes()}
    </VStack>
  );
}

const WeekLengthPicker = forwardRef<HTMLInputElement, WeekLengthPickerProps>(
  (
    {
      className,
      placeholder = t("component.weekLengthPicker.defaultPlaceholder"),
      onChange = noop,
      value = "",
      label = "",
      name = "",
      error,
      disabled = false,
      ...props
    },
    inputRef,
  ) => {
    const ref = useRef<HTMLDivElement>(null);
    const [displayValue, setDisplayValue] = useState(
      value ? getWeekLabel(value) : "",
    );
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setShowDropdown(false);
        }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }, []);

    useEffect(() => {
      setDisplayValue(value ? getWeekLabel(value) : "");
    }, [value, setDisplayValue]);

    return (
      <div className={clsx("relative", className)} ref={ref}>
        <input
          value={value}
          hidden
          readOnly
          name={name}
          ref={inputRef}
          disabled={disabled}
          {...props}
        />
        <Input
          data-testId={t(
            "component.createProjectForm.phaseWeeksInputPlaceholder",
          )}
          type="text"
          placeholder={placeholder}
          onFocus={() => setShowDropdown(true)}
          onChange={noop}
          label={label}
          error={error}
          disabled={disabled}
          value={displayValue}
          isSelect
          showCounter={false}
        />
        {showDropdown ? (
          <WeekLengthPickerDropdown
            selectedWeekLength={value}
            hasLabel={label.length > 0}
            onSelect={(selectedWeekLength: string) => {
              onChange(selectedWeekLength);
              setShowDropdown(false);
            }}
          />
        ) : undefined}
      </div>
    );
  },
);

export default WeekLengthPicker;
