import React from "react";

function HeaderActiveIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <>
      <svg
        width={20}
        height={20}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#F2F4F4" />
        <path
          d="M7.75 9.25V20.5"
          stroke="#00AE8F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 14.875H7.75"
          stroke="#00AE8F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 9.25V20.5"
          stroke="#00AE8F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.375 15.6243L24.625 14.125V22.75"
          stroke="#00AE8F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="0.5"
          y="0.5"
          width="31"
          height="31"
          rx="15.5"
          stroke="#00AE8F"
        />
      </svg>
    </>
  );
}

export default HeaderActiveIcon;
