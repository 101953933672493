import clsx from "clsx";
import { Button, Tooltip } from "components/base";
import { HStack } from "components/layout";
import { Caption2, Heading1, Paragraph1 } from "components/Typography";
import { WorkshopParticipantRole } from "graphql/generated";
import { t } from "i18n-js";
import { CaretRight } from "phosphor-react";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { useWorkshopActivitySubscription } from "subscriptions";
import { getCurrentWorkshopActivity } from "utils";

import { VideoSize } from "../../Workshop.types";
import { BreakoutActivity } from "../BreakoutActivity";
import { DiscussionActivity } from "../DisscussionActivity";
import PollActivity from "../PollActivity";
import { ResourceActivity } from "../ResourceActivity";
import { LiveWorkshopActivityProps } from "./LiveWorkshopActivity.types";
import { useActivityType } from "./useActivityType";
import { useManagedActivityVideoRatio } from "./useManagedVideoRatio";

const MAX_CONTAINER_WIDTH = 347;
const MIN_CONTAINER_WIDTH = 86;

/**
 * LiveWorkshopActivity handles rendering the appropriate component for the active WorkshopActivity.
 */
export const LiveWorkshopActivity = ({
  handleVideoCallSizeChange,
  role = WorkshopParticipantRole.Participant,
  workshop,
  setVideoCallWidth,
}: LiveWorkshopActivityProps) => {
  const [isResizing, setIsResizing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [defaultContainerWidth, setDefaultContainerWidth] =
    useState(MAX_CONTAINER_WIDTH);
  const [currentActivityType, setCurrentActivityType] = useState("");
  const [lastActivityUpdate, setLastActivityUpdate] = useState("");
  const alertRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const currentActivity = React.useMemo(() => {
    return getCurrentWorkshopActivity(workshop?.agenda);
  }, [workshop]);
  const activityType = useActivityType(currentActivity?.activity);
  useManagedActivityVideoRatio(activityType, handleVideoCallSizeChange);
  const isResourceActivity =
    currentActivity?.activity?.__typename === "Resource";
  const TooltipContent = () => (
    <div className="rounded-lg bg-neutral-900 text-white">
      <Caption2>
        {defaultContainerWidth > MIN_CONTAINER_WIDTH
          ? t("shared.collapse")
          : t("shared.expand")}
      </Caption2>
    </div>
  );

  const { data: activityResponse } = useWorkshopActivitySubscription({
    workshopId: workshop.id,
  });

  useEffect(() => {
    if (currentActivity?.activity.__typename) {
      setCurrentActivityType(currentActivity?.activity.__typename);
      setLastActivityUpdate(currentActivity.updatedAt);
    }
  }, []);

  useEffect(() => {
    const alertEl = alertRef.current;
    const containerEl = containerRef.current;

    if (
      (currentActivity?.activity.__typename !== currentActivityType ||
        activityResponse?.currentWorkshopActivity.workshopActivity
          ?.updatedAt !== lastActivityUpdate) &&
      containerEl?.clientWidth < MAX_CONTAINER_WIDTH
    ) {
      alertEl?.classList.remove("hidden");
    } else {
      alertEl?.classList.add("hidden");
    }
  }, [
    currentActivity,
    currentActivityType,
    containerRef,
    activityResponse,
    lastActivityUpdate,
  ]);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  if (!currentActivity) {
    return (
      <div className="my-auto ml-14 flex flex-1  flex-col">
        <Heading1>{t("workshop.noActivity.title")}</Heading1>
        <Paragraph1>{t("workshop.noActivity.body")}</Paragraph1>
      </div>
    );
  }

  if (currentActivity.activity.__typename == "Discussion") {
    setVideoCallWidth && setVideoCallWidth("w-3/4");
  } else if (currentActivity.activity.__typename == "Breakout") {
    if (
      currentActivity?.activity?.link?.url ||
      currentActivity?.activity?.documents?.length
    ) {
      setVideoCallWidth && setVideoCallWidth("");
    } else {
      setVideoCallWidth && setVideoCallWidth("w-3/4");
    }
  } else {
    setVideoCallWidth && setVideoCallWidth("");
  }
  let activityContent;
  switch (currentActivity.activity.__typename) {
    case "Discussion":
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { description } = currentActivity.activity;

      activityContent = (
        <DiscussionActivity
          title={currentActivity.title as string | undefined}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          description={description}
        />
      );
      break;

    case "Resource":
      activityContent = (
        <ResourceActivity
          activityType={activityType}
          {...currentActivity}
          className="ml-14"
          activityTitle={currentActivity.title as string}
        />
      );
      break;

    case "Poll":
      activityContent = role && (
        <PollActivity
          {...currentActivity}
          role={role}
          workshopActivityId={currentActivity.id}
        />
      );

      break;

    case "Breakout":
      activityContent = (
        <BreakoutActivity
          activityType={activityType}
          activity={currentActivity}
          workshop={workshop}
          role={role}
        />
      );
      break;

    default:
      activityContent = (
        <>
          <Button onClick={() => handleVideoCallSizeChange(VideoSize.Small)}>
            Small Video Call
          </Button>
          <Button onClick={() => handleVideoCallSizeChange(VideoSize.Medium)}>
            Medium Video Call
          </Button>
          <Button onClick={() => handleVideoCallSizeChange(VideoSize.Large)}>
            Large Video Call
          </Button>
        </>
      );
      break;
  }

  const toggleContainerWidth = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const containerEl = containerRef.current;
    const alertEl = alertRef.current;

    if (defaultContainerWidth > MIN_CONTAINER_WIDTH && containerEl) {
      containerEl.removeAttribute("style");
      setDefaultContainerWidth(MIN_CONTAINER_WIDTH);
    }

    if (
      defaultContainerWidth <= MIN_CONTAINER_WIDTH &&
      containerEl &&
      alertEl
    ) {
      containerEl.removeAttribute("style");
      alertEl.classList.add("hidden");
      setDefaultContainerWidth(MAX_CONTAINER_WIDTH);
    }
  };

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsResizing(true);

    if (event.clientY > 140 && event.clientY < 170) {
      toggleContainerWidth(event);
    }

    const overlayEl = overlayRef.current;
    const containerEl = containerRef.current;

    if (overlayEl && containerEl) {
      overlayEl.classList.add("z-50");
    }
  };

  const handleMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsResizing(false);

    const overlayEl = overlayRef.current;
    if (overlayEl) {
      overlayEl.classList.remove("z-50");
    }
  };

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const containerEl = containerRef.current;
    const alertEl = alertRef.current;

    if (isResizing && containerEl) {
      containerEl.style.width = `${event.clientX}px`;
      setDefaultContainerWidth(event.clientX);
    }

    if (alertEl && event.clientX >= MAX_CONTAINER_WIDTH) {
      alertEl.classList.add("hidden");
    }
  };

  return (
    <>
      {isResourceActivity ? (
        <div className="flex h-full w-full">{activityContent}</div>
      ) : (
        <>
          <div
            className="absolute top-0 left-0 h-full w-full"
            ref={overlayRef}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          />
          <div
            className={`relative flex h-full w-[${defaultContainerWidth}px] min-w-[86px] max-w-[347px]`}
            ref={containerRef}
          >
            <div className={`absolute flex h-full w-[347px]`}>
              {activityContent}
            </div>
          </div>
          <div
            className={clsx(
              "relative z-[22] border-l-4 border-l-neutral-100 hover:cursor-col-resize hover:border-l-primary-turquoise-100",
            )}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleMouseDown}
          >
            <HStack
              justify="center"
              align="center"
              className={clsx(
                "absolute -left-4 top-12 h-[32px] w-[32px] cursor-pointer rounded-full bg-neutral-white shadow-lg duration-300 ease-in hover:bg-primary-turquoise-100",
                isHovered ? "opacity-100" : "opacity-0",
              )}
            >
              <div
                ref={alertRef}
                className="absolute -top-[2px] right-[2px] hidden h-[10px] w-[10px] rounded-full bg-white  drop-shadow-md"
              />
              <Tooltip content={TooltipContent} placement="right" offset={18}>
                <CaretRight
                  size={20}
                  weight="bold"
                  color="#35363E"
                  mirrored={defaultContainerWidth > MIN_CONTAINER_WIDTH}
                />
              </Tooltip>
            </HStack>
          </div>
        </>
      )}
    </>
  );
};
