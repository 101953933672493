import clsx from "clsx";
import { HStack, VStack } from "components/layout";
import { Modal } from "components/partial";
import { Base2, Caption1, Display4 } from "components/Typography";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { UsersFour, X } from "phosphor-react";
import React from "react";
import { useParams } from "react-router-dom";

import Avatar from "../Avatar";
import { SegmentConfigType } from "./PieChart";
import SmallPieChartBlue from "./SmallPieChartBlue";
import SmallPieChartGreen from "./SmallPieChartGreen";
import SmallPieChartOrange from "./SmallPieChartOrange";
import SmallPieChartRed from "./SmallPieChartRed";

interface DiscSegmentDialogProps {
  open: boolean;
  segmentConfig: SegmentConfigType;
  onClose: () => void;
}

const SegmentPieChart = ({ segmentType }: { segmentType: string }) => {
  if (segmentType.startsWith("i")) {
    return <SmallPieChartRed />;
  } else if (segmentType.startsWith("s")) {
    return <SmallPieChartBlue />;
  } else if (segmentType.startsWith("c")) {
    return <SmallPieChartOrange />;
  } else if (segmentType.startsWith("d")) {
    return <SmallPieChartGreen />;
  } else {
    return <></>;
  }
};

const getSegmentDescription = (segmentType: string) => {
  if (segmentType.startsWith("i")) {
    return t("team.personalityProfile.profiles.influence.description");
  } else if (segmentType.startsWith("s")) {
    return t("team.personalityProfile.profiles.steadiness.description");
  } else if (segmentType.startsWith("c")) {
    return t("team.personalityProfile.profiles.conscientiousness.description");
  } else if (segmentType.startsWith("d")) {
    return t("team.personalityProfile.profiles.dominance.description");
  } else {
    return "";
  }
};

const DiscSegmentDialog = ({
  onClose,
  open,
  segmentConfig,
}: DiscSegmentDialogProps) => {
  const { projectId = "" } = useParams();
  const { anonymousSubmissionsCount, segmentType, segmentUsers } =
    segmentConfig;
  const { hasViewAccess } = useProjectAccessLevel({ projectId });

  return (
    <Modal
      isFullScreen
      open={open}
      onClose={onClose}
      className="no-scrollbar flex max-h-[520px] w-[592px] flex-col overflow-scroll rounded-xl p-[40px]"
    >
      <VStack>
        <HStack
          justify="between"
          align="center"
          className={clsx(
            (anonymousSubmissionsCount > 0 || segmentUsers.length > 0) &&
              "mb-[40px]",
          )}
        >
          <HStack space={4}>
            <div className="relative">
              <SegmentPieChart segmentType={segmentType.toLowerCase()} />
            </div>
            <VStack space={1}>
              <Display4 className="text-neutral-900">
                {t("shared.segmentTypeProfile", { segmentType })}
              </Display4>
              <Caption1 className="text-neutral-800">
                {getSegmentDescription(segmentType.toLowerCase())}
              </Caption1>
            </VStack>
          </HStack>
          <HStack
            className="h-[44px] w-[44px] cursor-pointer rounded-full bg-tint-dark-300"
            justify="center"
            align="center"
            onClick={onClose}
          >
            <X size={24} />
          </HStack>
        </HStack>
        {segmentUsers.length > 0 && !hasViewAccess && (
          <VStack className="pl-2" space={5}>
            {segmentUsers.map((user) => (
              <HStack align="center" space={3}>
                <Avatar user={user} size="medium" key={user.id} />
                <VStack>
                  <Base2 className="text-neutral-900">{user.fullName}</Base2>
                  <Caption1 className="text-neutral-600">
                    {user.defaultProjectRole}
                  </Caption1>
                </VStack>
              </HStack>
            ))}
          </VStack>
        )}
        {anonymousSubmissionsCount > 0 && !hasViewAccess && (
          <HStack
            space={3}
            align="center"
            className={clsx("px-2", segmentUsers.length > 0 && "!mt-4")}
          >
            <HStack
              className="h-[40px] w-[40px] rounded-full bg-neutral-800"
              justify="center"
              align="center"
            >
              <UsersFour size={24} color="#C0C5C9" />
            </HStack>
            <Base2 className="text-neutral-900">
              {t("team.personalityProfile.anonymousUsers")}
            </Base2>
          </HStack>
        )}
      </VStack>
    </Modal>
  );
};

export default DiscSegmentDialog;
