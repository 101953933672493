import { Agenda, WorkshopActivity } from "graphql/generated";

/**
 * Use this helper when you need to work with the pure list of
 * WorkshopActivities in a Workshop without having to worrying about
 * the nesting within Sections.
 */
export const getFlattenedWorkshopActivities = (
  agenda: Agenda[] | undefined,
) => {
  return agenda
    ?.map((item) =>
      item.__typename === "Section"
        ? item.workshopActivities
        : (item as WorkshopActivity | null | undefined),
    )
    .flat();
};
