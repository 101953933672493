import clsx from "clsx";
import { SUMMARY_SCROLL_PROPS } from "components/pages/WorkshopSummaryEdit/useSummaryScroll";
import { Caption2, Paragraph3 } from "components/Typography";
import { t } from "i18n-js";
import React from "react";
import { Link } from "react-scroll";

import {
  SectionStatus,
  SummarySectionItemProps,
} from "./SummarySectionItem.types";
import { getIconByStatus } from "./SummarySectionItem.util";

/**
 * SummarySectionItem handles the differences in renderings for the indiviudal items
 * within the SummarySidebar. It takes into account both the user's role relative to
 * the workshop, and if the user is a facilitator/owner, then the state of the validation
 * on the individual sections.
 */
export const SummarySectionItem = ({
  title,
  id,
  status,
  isActive,
}: SummarySectionItemProps) => {
  /**
   * Rendering this when the user is of role "participant" on the workshop, OR through the
   * WorkshopSummary (rather than WorkshopSummaryEdit) page should cause `status` to be
   * undefined, resulting in no StatusIcon.
   */
  const StatusIcon = getIconByStatus(status);

  return (
    <Link
      to={id}
      aria-label={`${t("workshop.summary.sidebar.anchorLink")}: ${title}`}
      {...SUMMARY_SCROLL_PROPS}
      className={clsx(
        "flex cursor-pointer flex-row items-center px-2 pt-5 pb-4.5 transition-all duration-300",
        {
          "rounded-lg border border-neutral-40 bg-tint-dark-5": isActive,
          "border border-transparent": !isActive,
        },
      )}
    >
      {!!StatusIcon && (
        <StatusIcon
          size={24}
          aria-label={
            !!status
              ? `${t(
                  "workshop.summary.sidebar.sectionStatus.description",
                )}: ${t(`workshop.summary.sidebar.sectionStatus.${status}`)}`
              : undefined
          }
          className={clsx("mr-2", {
            "text-secondary-orange-70": status === SectionStatus.ActionRequired,
          })}
        />
      )}

      <div>
        <Paragraph3>{title}</Paragraph3>

        {!!status && (
          <Caption2 className="text-neutral-70">
            {t(`workshop.summary.sidebar.sectionStatus.${status}`)}
          </Caption2>
        )}
      </div>
    </Link>
  );
};
