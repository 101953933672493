import { Avatar, Button } from "components/base";
import { Caption1, Display3, Heading3 } from "components/Typography";
import { format } from "date-fns";
import {
  DraftWorkshopsQuery,
  WorkshopParticipantRole,
} from "graphql/generated";
import { t } from "i18n-js";
import { ArrowLeft, NotePencil } from "phosphor-react";
import { useDraftWorkshop } from "queries/use-draft-workshops";
import React, { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { WorkshopDraftCard } from "./components";

export type CardDraftWorkshop = DraftWorkshopsQuery["draftWorkshop"][number];

const getDraftCreator = (draft: CardDraftWorkshop) => {
  return draft.workshopParticipants.find(
    (participant) => participant.role === WorkshopParticipantRole.Owner,
  );
};

/**
 * This feed shows the list of the workshops of state "draft" to the user.
 * The button for navigating here only shows in the projects dashboard.
 * Without this view, the user wouldn't have any way to revisit their drafts
 * and publish or delete them.
 */
export const WorkshopDraftsFeed = () => {
  const { projectId = "" } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { draftWorkshops } = useDraftWorkshop({ projectId });

  const handleBack = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    navigate(location?.state?.from || "..");
  };

  /*
   * This array comes in chronological order, and we want to display the feed in reverse-chronological order.
   * ie, newest drafts should appear first.
   */
  const orderedDrafts = useMemo(
    () => draftWorkshops.reverse(),
    [draftWorkshops],
  );

  return (
    <div className="h-full w-full overflow-y-scroll bg-draft-feed-gradient">
      <div className="mx-auto flex h-full w-170 flex-col pt-16">
        {/* NAV */}
        <div className="mb-20 flex gap-5">
          <Button
            RightIcon={ArrowLeft}
            variant="primary"
            onClick={handleBack}
          />
          <Display3>{t("workshop.draftsFeed.title")}</Display3>
        </div>

        {orderedDrafts.length <= 0 ? (
          <div className="mx-auto mt-20 flex w-1/2 flex-col items-center gap-2">
            {/* EMPTY STATE */}
            <NotePencil
              size={120}
              className="mb-6 text-neutral-700"
              weight="thin"
            />
            <Heading3 className="text-neutral-900">
              {t("workshop.draftsFeed.appearHere")}
            </Heading3>
            <Caption1 className="text-center text-neutral-800">
              {t("workshop.draftsFeed.noDrafts")}
            </Caption1>
          </div>
        ) : (
          <div className="flex flex-col gap-8">
            {/* LIST VIEW */}
            {orderedDrafts?.map((draft) => {
              const creator = getDraftCreator(draft);
              return (
                <div key={draft.id} className="flex flex-col">
                  {/* DATE CREATED */}
                  <div className="mb-4 flex items-center gap-2">
                    {creator && <Avatar user={creator.user} size="mini" />}
                    {creator ? (
                      <Caption1>
                        {creator.user.firstName}{" "}
                        {t("workshop.draftsFeed.wasCreated")}
                      </Caption1>
                    ) : (
                      <Caption1>{t("workshop.draftsFeed.noCreator")}</Caption1>
                    )}
                    <Caption1 className="text-neutral-500">
                      {format(new Date(draft.createdAt), "MMM d, Y")}
                    </Caption1>
                  </div>

                  <WorkshopDraftCard draft={draft} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
