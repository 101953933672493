import {
  addWeeks,
  eachWeekOfInterval,
  endOfWeek,
  isWithinInterval,
  parseISO,
  startOfWeek,
} from "date-fns";
import { Maybe } from "graphql/generated";
import { WEEK_START_DAY } from "utils/constants/week_start";

type findPhaseWeekIndexProps = {
  startDate: Maybe<string> | undefined;
  weeksLength: number;
  searchedDate?: string;
};

const findPhaseWeekIndex = ({
  startDate,
  weeksLength,
  searchedDate,
}: findPhaseWeekIndexProps): number => {
  const searched = searchedDate ? parseISO(searchedDate) : new Date();
  const parsedStartDate = startOfWeek(parseISO(startDate || ""), {
    weekStartsOn: WEEK_START_DAY,
  });

  const weeks = eachWeekOfInterval(
    {
      start: parsedStartDate,
      end: addWeeks(parsedStartDate, weeksLength),
    },
    {
      weekStartsOn: WEEK_START_DAY,
    },
  );

  return weeks.findIndex((weekStart) =>
    isWithinInterval(searched, {
      start: weekStart,
      end: endOfWeek(weekStart, { weekStartsOn: WEEK_START_DAY }),
    }),
  );
};

export { findPhaseWeekIndex };
