/* eslint-disable graphql/template-strings */
import {
  DraftWorkshopsQuery,
  DraftWorkshopsQueryVariables,
  useDraftWorkshopsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

export const DRAFT_WORKSHOP_FRAGMENT = gql`
  fragment DraftWorkshop on Workshop {
    id
    title
    startTime
    endTime
    createdAt
    description
    workshopParticipants {
      id
      role
      user {
        id
        firstName
      }
    }
  }
`;

gql`
  ${DRAFT_WORKSHOP_FRAGMENT}
  query DraftWorkshops($projectId: ID!) {
    draftWorkshop(projectId: $projectId) {
      ...DraftWorkshop
    }
  }
`;

export function useDraftWorkshop(variables: DraftWorkshopsQueryVariables): {
  draftWorkshops: DraftWorkshopsQuery["draftWorkshop"];
} & UseQueryResult<DraftWorkshopsQuery, unknown> {
  const response = useDraftWorkshopsQuery(variables, { suspense: true });

  return {
    draftWorkshops: response?.data?.draftWorkshop || [],
    ...response,
  };
}
