import { Button } from "components/base";
import { Base2 } from "components/Typography";
import { WorkshopState } from "graphql/generated";
import { t } from "i18n-js";
import { noop } from "lodash";
import { ArrowRight, ArrowSquareOut } from "phosphor-react";
import React from "react";

interface WorkshopDetailsButtonGroupProps {
  id: string;
  state: WorkshopState;
  isParticipant: boolean;
  onDelete?: () => void;
}

const WorkshopDetailsButtonGroup = ({
  id,
  state,
  isParticipant,
  onDelete = noop,
}: WorkshopDetailsButtonGroupProps) => {
  switch (state) {
    case WorkshopState.PostSession:
      return isParticipant ? (
        <Button
          variant="primary"
          onClick={() => window.open(`/workshop/${id}/summary`, "_blank")}
        >
          <span className="flex">
            <span>{t(`workshop.complete.viewSummary`)}</span>
            <ArrowRight size={20} className="ml-3" />
          </span>
        </Button>
      ) : (
        <>
          <Button
            variant="outline"
            onClick={() =>
              window.open(`/workshop/${id}/summary/edit`, "_blank")
            }
          >
            <span className="flex">
              <span>{t(`workshop.complete.editSummary`)}</span>
            </span>
          </Button>
          <Button
            variant="primary"
            onClick={() => window.open(`/workshop/${id}/summary`, "_blank")}
          >
            <span className="flex">
              <span>{t(`workshop.complete.viewSummary`)}</span>
              <ArrowRight size={20} className="ml-3" />
            </span>
          </Button>
        </>
      );
    case WorkshopState.LiveSession:
      return isParticipant ? (
        <Button
          RightIcon={ArrowSquareOut}
          className="min-w-min self-start px-8"
          onClick={() => window.open(`/workshop/${id}`, "_blank")}
        >
          {t("component.workshopCard.join")}
        </Button>
      ) : (
        <>
          <Button
            variant="outline"
            className="min-w-min self-start px-8 text-neutral-90"
            onClick={() => window.open(`/workshop/${id}/edit`, "_blank")}
          >
            {t("component.workshopCard.continueEditing")}
          </Button>
          <Button
            RightIcon={ArrowSquareOut}
            className="min-w-min self-start px-8"
            onClick={() => window.open(`/workshop/${id}`, "_blank")}
          >
            {t("component.workshopCard.join")}
          </Button>
        </>
      );
    case WorkshopState.Published:
      return isParticipant ? (
        <Button
          RightIcon={ArrowSquareOut}
          className="min-w-min self-start px-8"
          onClick={() => window.open(`/workshop/${id}`, "_blank")}
        >
          {t("component.workshopCard.join")}
        </Button>
      ) : (
        <>
          <div className="flex gap-2">
            <Button
              variant="outline"
              className="min-w-min self-start px-8 text-neutral-90"
              onClick={() => window.open(`/workshop/${id}/edit`, "_blank")}
            >
              {t("component.workshopCard.edit")}
            </Button>
            <Button
              onClick={onDelete}
              variant="destructive"
              className="min-w-min self-start !px-6"
              aria-label="Delete Announcement"
              type="button"
            >
              <Base2 className="px-1">
                {t("component.workshopCard.delete")}
              </Base2>
            </Button>
          </div>
          <Button
            RightIcon={ArrowSquareOut}
            className="min-w-min self-start px-8"
            onClick={() => window.open(`/workshop/${id}`, "_blank")}
          >
            {t("component.workshopCard.join")}
          </Button>
        </>
      );
    default:
      return <></>;
  }
};

export default WorkshopDetailsButtonGroup;
