import clsx from "clsx";
import {
  Base1,
  Caption1,
  Heading3,
  HStack,
  Paragraph1,
  Paragraph2,
  VStack,
} from "components";
import { Avatar, Button, ButtonVariant, Link } from "components/base";
import { InteractionModal } from "components/partial";
import { useGetEnvsContext } from "contexts";
import { format, parseISO } from "date-fns";
import { Comment, Task } from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import useTaskCommentFlagReset from "mutations/use-task-comment-flag-reset";
import { Check, Checks, Clock } from "phosphor-react";
import { useTaskComments } from "queries/use-task-comments";
import React, { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Comments from "./Comments/Comments";
import ShowComments from "./Comments/ShowComments";
import { EmbeddedLinks } from "./CreateInteractions/CreateAnnouncementForm";
import { formatTaskDueDate } from "./pages/WeekView/TaskCard";
import { dateToday, getTaskFlag } from "./pages/WeekView/utils";

type TaskDetailsModalProps = {
  show: boolean;
  onClose: () => void;
  task: Partial<Task> & { taskTitle: string };
  actionButton: (variant?: ButtonVariant) => ReactNode;
  getTasks: () => ReactNode;
  onEdit: () => void;
};

export default function TaskDetailsModal({
  show,
  onClose,
  task,
  actionButton,
  onEdit,
}: TaskDetailsModalProps) {
  const {
    description,
    createdBy,
    createdAt,
    assignees,
    tasksAssignees,
    isHighlighted,
    links,
    taskTitle,
    dueDate = "",
    isCompleted,
    id,
  } = task;
  const [assigneeCount, setAttendeeCount] = useState(10);
  const { projectId = "" } = useParams();

  const { hasAdminAccess, hasMemberAccess } = useProjectAccessLevel({
    projectId,
  });
  const { mutateAsync: TaskCommentFlagReset } = useTaskCommentFlagReset();
  const { currentUser } = useGetEnvsContext();

  useEffect(() => {
    return () => {
      if (!!id) {
        void TaskCommentFlagReset({
          taskId: id,
        });
      }
    };
  }, []);
  const [commentCount, setCommentCount] = useState<number>(10);

  const { comments, totalCount } = useTaskComments({
    taskId: id as string,
    count: commentCount,
  });
  const hasAllAssigneesCompletedTask = tasksAssignees
    ?.map((assignee) => assignee.isCompleted)
    .every((value) => value === true);
  const currentUsersTaskAssignment = tasksAssignees?.find(
    (assignee) => assignee.assigneeId === currentUser?.id,
  );
  const assigneesCompletedTask = tasksAssignees?.filter(
    (assignee) => assignee.isCompleted === true,
  );
  const loadMoreComments = () => {
    setCommentCount(commentCount + 10);
  };

  const title =
    ((taskTitle?.length || 0) > 75
      ? `${taskTitle?.slice(0, 75) || ""}...`
      : taskTitle) || "";
  const handleUpdateAttendeeCount = () => {
    setAttendeeCount((prev) => prev + 10);
  };

  const getTaskAssignees = () => (
    <VStack space={3}>
      <Paragraph2 className="text-neutral-900">
        {t("shared.assignees")}
        <span className="ml-2 text-neutral-700">
          {t("component.taskCard.completedThisTask", {
            assigneesCompleted: assigneesCompletedTask?.length,
            totalAssignees: tasksAssignees?.length,
          })}
        </span>
      </Paragraph2>
      <div className="flex flex-wrap gap-2">
        {tasksAssignees
          ?.sort((a, b) => {
            return a.assignee.lastName.localeCompare(b.assignee.lastName);
          })
          .slice(0, assigneeCount)
          .map((user) => (
            <HStack
              space={2}
              className="h-10 rounded-full bg-neutral-0 py-2 pl-1 pr-4"
              align="center"
              key={user.id}
            >
              {user.assignee.avatarUrl ? (
                <img
                  src={user.assignee.avatarUrl}
                  alt={user.assignee.id}
                  className="h-8 w-8 rounded-full"
                />
              ) : (
                <Avatar
                  user={user.assignee}
                  size="small"
                  textClassName="text-sm"
                />
              )}
              <span className="text-neutral-800">
                {currentUser?.id === user.assigneeId
                  ? `${user.assignee.fullName} (${t("shared.you")})`
                  : user.assignee.fullName}
              </span>
              {user.isCompleted && (
                <HStack
                  justify="center"
                  align="center"
                  className="h-5 w-5 rounded-full bg-secondary-green-70"
                >
                  <Check size={16} color="white" />
                </HStack>
              )}
            </HStack>
          ))}
      </div>
      {assigneeCount <= assignees?.length ? (
        <HStack>
          <Button
            className="h-10"
            variant="outline"
            onClick={handleUpdateAttendeeCount}
          >
            {t("shared.viewMore")}
          </Button>
        </HStack>
      ) : undefined}
    </VStack>
  );

  return (
    <InteractionModal
      isOpen={show}
      closeModal={onClose}
      header={title}
      icon={
        hasAllAssigneesCompletedTask ? (
          <Checks color="white" />
        ) : (
          <Check color="white" />
        )
      }
      variant={
        currentUsersTaskAssignment?.isCompleted || isCompleted
          ? "green"
          : "gray"
      }
      slideFromLeft
      disableHighlightClick={true}
      isHighlighted={!!isHighlighted}
      showHighlightFeature={true}
    >
      <div className="flex-grow overflow-y-auto p-12">
        <div className="mb-4 flex h-6">
          <Avatar
            user={createdBy}
            className="mb-4"
            size="mini"
            textClassName="!text-xs"
          />
          <Caption1 className="mx-2 self-center text-neutral-70">
            {currentUser?.id === createdBy?.id
              ? t("shared.youCreatedThisTaskOn")
              : t("component.taskCard.createdThisTask", {
                  createdBy: createdBy?.fullName,
                })}
            <span className="ml-1 text-neutral-70">
              {format(parseISO(createdAt as string), "MMM d, yyyy")}
            </span>
          </Caption1>
        </div>
        <HStack
          align="center"
          space={2}
          className="mb-4"
          data-testid="task-due"
        >
          <Clock size={28} color="black" weight="light" />
          <Base1 className="text-neutral-900">
            {t(`shared.due`, { dueDate: formatTaskDueDate(dueDate) })}
          </Base1>
          {currentUsersTaskAssignment?.isCompleted === false &&
            !!getTaskFlag(dueDate, dateToday) && (
              <Caption1
                className={clsx(
                  "rounded py-1 px-2",
                  getTaskFlag(dueDate, dateToday) === "Due Today"
                    ? "bg-secondary-orange-100"
                    : "bg-secondary-red-100",
                )}
              >
                {getTaskFlag(dueDate, dateToday) === "Due Today"
                  ? t("shared.dueToday")
                  : t("shared.overdue")}
              </Caption1>
            )}
        </HStack>
        <Paragraph1 className="pb-8 text-neutral-800">{description}</Paragraph1>
        {getTaskAssignees()}

        {links?.length ? (
          <VStack className="mt-8" space={4}>
            <Base1 className="text-neutral-90">{t("shared.resources")}</Base1>
            <HStack align="center" className="flex-wrap gap-2">
              {links.map((link) => (
                <Link key={link?.url} metadata={link as EmbeddedLinks} />
              ))}
            </HStack>
          </VStack>
        ) : (
          <></>
        )}

        <hr className="mt-8 h-px border-0 bg-tint-dark-10" />

        {hasMemberAccess && (
          <>
            <Heading3 className={clsx("mt-4")}>
              {t("constant.comments.comments")}
            </Heading3>
            <ShowComments
              comments={comments as Comment[]}
              loadMoreComments={loadMoreComments}
              totalCommentCount={totalCount}
            />
            <div className="flex items-center">
              <Avatar
                user={currentUser}
                size="mini"
                textClassName="!text-xs"
                className="-mb-6"
              />
              <Comments eventId={id || ""} eventType="task" />
            </div>
          </>
        )}
      </div>
      <HStack
        className="px-14 pt-4 pb-6 shadow-t-lg"
        justify="between"
        align="center"
      >
        <Button
          onClick={onEdit}
          variant="outline"
          aria-label={t("shared.edit")}
          disabled={!(hasAdminAccess || currentUser?.id === createdBy?.id)}
        >
          {t("shared.edit")}
        </Button>
        {currentUsersTaskAssignment?.assignee?.id && (
          <div className="flex w-full justify-end">
            {actionButton("primary")}
          </div>
        )}
      </HStack>
    </InteractionModal>
  );
}
