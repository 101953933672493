import clsx from "clsx";
import { Caption1 } from "components/Typography";
import { WorkshopState } from "graphql/generated";
import { t } from "i18n-js";
import React from "react";

export interface WorkshopStatusBadgeProps {
  status?: WorkshopState | undefined;
}

/**
 * Shows the "state" of the Workshop in a badge.
 * For use in the various Workshop Header components.
 */
export const WorkshopStatusBadge = ({
  status = WorkshopState.Draft,
}: WorkshopStatusBadgeProps) => {
  return status ? (
    <Caption1
      aria-label={`${t("workshop.statusBadge.description")}: ${t(
        `workshop.statusBadge.${status}`,
      )}`}
      className={clsx(`rounded px-2 py-1 text-white`, {
        "bg-secondary-red-70": status === WorkshopState.LiveSession,
        "bg-neutral-5 text-neutral-70":
          status === WorkshopState.PostSession ||
          status === WorkshopState.Draft ||
          status === WorkshopState.Published,
      })}
    >
      {t(`workshop.statusBadge.${status}`)}
    </Caption1>
  ) : (
    <></>
  );
};
