import { LogoIconNew } from "icons";
import React from "react";

type SizeVariants = "large" | "medium" | "small";

interface LogoProps extends React.SVGProps<SVGSVGElement> {
  size: SizeVariants;
}

const sizeVariantAttributes: Record<
  SizeVariants,
  { width: number; height: number }
> = {
  large: {
    width: 318,
    height: 120,
  },
  medium: {
    width: 212,
    height: 80,
  },
  small: {
    width: 106,
    height: 40,
  },
};

export default function Logo({ size, ...props }: LogoProps) {
  return <LogoIconNew {...sizeVariantAttributes[size]} {...props} />;
}
