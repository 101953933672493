import {
  CurrentUserQuery,
  WorkshopByIdQuery,
  WorkshopParticipant,
} from "graphql/generated";
import React from "react";

export const useWorkshopParticipant = (
  workshop?: WorkshopByIdQuery["workshopById"],
  currentUser?: CurrentUserQuery["currentUser"],
) => {
  const [workshopParticipant, setWorkshopParticipant] =
    React.useState<WorkshopParticipant>({} as WorkshopParticipant);

  React.useEffect(() => {
    if (!workshop || !currentUser) {
      return;
    }

    const currentParticipant = workshop.workshopParticipants.find(
      (participant) => participant.user.id === currentUser?.id,
    ) as WorkshopParticipant;

    if (currentParticipant) {
      setWorkshopParticipant(currentParticipant);
    }
  }, [workshop, currentUser]);

  return {
    ...workshopParticipant,
    role: workshopParticipant.role ?? undefined,
  };
};
