import { Button } from "components/base";
import { HStack, VStack } from "components/layout";
import { Modal } from "components/partial";
import { Base2, Caption1, Heading2 } from "components/Typography";
import { t } from "i18n-js";
import React from "react";

type NotificationConfirmModalProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export default function NotificationConfirmModal({
  open,
  onCancel,
  onConfirm,
}: NotificationConfirmModalProps) {
  return (
    <Modal
      isFullScreen
      open={open}
      onClose={onCancel}
      className="w-[560px] rounded-lg p-6"
    >
      <Heading2>
        {t("workshop.summary.footer.notifyParticipantsConfirmDialog.title")}
      </Heading2>
      <VStack className="my-6">
        <Base2 className="mb-2">
          {t(
            "workshop.summary.footer.notifyParticipantsConfirmDialog.subtitle",
          )}
        </Base2>
        <Caption1 className="text-neutral-70">
          {t("workshop.summary.footer.notifyParticipantsConfirmDialog.desc")}
        </Caption1>
      </VStack>

      <HStack justify="end">
        <Button variant="tertiary" onClick={onCancel} className="mr-2">
          {t(
            "workshop.summary.footer.notifyParticipantsConfirmDialog.nevermind",
          )}
        </Button>

        <Button variant="primary" onClick={onConfirm}>
          {t(
            "workshop.summary.footer.notifyParticipantsConfirmDialog.notifyParticipants",
          )}
        </Button>
      </HStack>
    </Modal>
  );
}
