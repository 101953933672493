/* eslint-disable no-console */
import { ViewNavigator, ViewSubheader } from "components/partial";
import { PhaseType, useProjectNavigator } from "contexts";
import { ProjectByIdQuery } from "graphql/generated";
import { t } from "i18n-js";
import React, { useEffect, useState } from "react";

type PhaseViewSubheaderProps = {
  project: ProjectByIdQuery["projectById"];
  openProjectGoalModal: () => void;
};

export default function PhaseViewSubheader({
  project,
  openProjectGoalModal,
}: PhaseViewSubheaderProps) {
  const [phasePosition, setPhasePosition] = useState(0);
  const {
    currentPhase: phase,
    updatePhaseAndWeek,
    goToToday,
  } = useProjectNavigator();

  const phases = project.phases;

  useEffect(() => {
    if (!phase) return;

    setPhasePosition(phase.position);
  }, [phase]);

  const handleNavigateLeft = () => {
    const nextValue = phasePosition === 0 ? 0 : phasePosition - 1;
    updatePhase(nextValue);
  };

  const handleNavigateRight = () => {
    const nextValue =
      phasePosition === phases.length - 1 ? phasePosition : phasePosition + 1;
    updatePhase(nextValue);
  };

  const updatePhase = (phaseNumber: number) => {
    const nextPhase = phases[phaseNumber] as PhaseType;

    if (nextPhase) {
      setPhasePosition(phaseNumber);
      updatePhaseAndWeek({ phase: nextPhase, weekNumber: 0 });
    }
  };

  return (
    <div className="flex w-full flex-col items-center">
      <ViewSubheader
        className="w-full"
        goToToday={goToToday}
        openProjectGoalModal={openProjectGoalModal}
      >
        <ViewNavigator
          className="self-center"
          title={`${t("shared.phase")} ${phasePosition + 1}: ${
            phases[phasePosition].name
          }`}
          onLeftClick={phasePosition > 0 ? handleNavigateLeft : undefined}
          onRightClick={
            phasePosition < phases.length - 1 ? handleNavigateRight : undefined
          }
        />
      </ViewSubheader>
    </div>
  );
}
