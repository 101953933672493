import { Tooltip, WorkshopStatusBadge } from "components/base";
import { VStack } from "components/layout";
import { Caption1, Caption2, Heading2 } from "components/Typography";
import { WorkshopByIdQuery } from "graphql/generated";
import { t } from "i18n-js";
import { CaretRight, PencilSimple, Presentation } from "phosphor-react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useWorkshopStateSubscription } from "subscriptions";
import { getFormattedWorkshopTimes } from "utils";
import {
  getWorkshopBadge,
  workshopStatusType,
} from "utils/helpers/workshop-status";

import WorkshopBuilderModal from "./WorkshopBuilderModal";

export interface WorkshopMetaProps {
  workshop?: Pick<
    WorkshopByIdQuery["workshopById"],
    "startTime" | "endTime" | "title" | "state"
  >;
  hideStatusBadge?: boolean;
  hideIcon?: boolean;
  isBuilderOpener?: boolean;
  isMeetingBeingRecorded?: boolean;
}

/**
 * WorkshopMeta summarizes metadata about the Workshop including: "title", "start" and "end" dates, and "status".
 */
export const WorkshopMeta = ({
  workshop,
  hideStatusBadge = false,
  hideIcon = false,
  isBuilderOpener = false,
  isMeetingBeingRecorded = false,
}: WorkshopMetaProps) => {
  const { startTime, endTime, date } = getFormattedWorkshopTimes(
    workshop?.startTime,
    workshop?.endTime,
  );
  const { id: workshopId = "" } = useParams();

  const [isBuilderModalOpen, setIsBuilderModalOpen] = useState<boolean>(false);
  const { data: workshopData } = useWorkshopStateSubscription({
    workshopId,
  });

  const editWorkshopHandler = () => {
    if (workshopId) {
      setIsBuilderModalOpen(true);
    }
  };

  const EditWorkshopTooltipContent = () => (
    <Caption2>{t("workshop.header.editTooltipMsg")}</Caption2>
  );

  return (
    <div className="flex gap-4">
      {!hideIcon && (
        <>
          {isBuilderOpener ? (
            <>
              <Tooltip
                content={EditWorkshopTooltipContent}
                placement="top"
                offset={12}
              >
                <div
                  className="relative flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-tertiary-blue-50 text-white"
                  onClick={editWorkshopHandler}
                >
                  <Presentation size={24} />
                  <div className="absolute top-[-20%] right-[-15%] flex h-6 w-6  items-center justify-center rounded-full bg-neutral-90">
                    <PencilSimple size={12} />
                  </div>
                </div>
              </Tooltip>

              <WorkshopBuilderModal
                open={isBuilderModalOpen}
                onClose={() => setIsBuilderModalOpen(false)}
                workshopId={workshopId}
              />
            </>
          ) : (
            <div className="relative flex h-12 w-12 items-center justify-center rounded-full bg-tertiary-blue-50 text-white">
              <Presentation size={24} />
            </div>
          )}
        </>
      )}

      <div>
        <div className="flex items-center gap-[10px]">
          <Heading2
            className={"text-neutral-90"}
            aria-label={t("workshop.header.aria.title")}
          >
            {workshop?.title}
          </Heading2>

          {!hideStatusBadge && (
            <WorkshopStatusBadge
              status={getWorkshopBadge(
                workshopData?.workshopStateUpdated
                  ?.workshop as workshopStatusType,
              )}
            />
          )}
        </div>

        {(startTime || endTime || date) && (
          <VStack space={1}>
            <div className="mt-[2px] flex items-center text-neutral-70">
              <Caption1 aria-label={t("workshop.header.aria.startTime")}>
                {startTime}
              </Caption1>

              <CaretRight size={8} className="mx-1" />

              <Caption1 aria-label={t("workshop.header.aria.endTime")}>
                {endTime}
              </Caption1>

              <Caption1
                aria-label={t("workshop.header.aria.date")}
                className="ml-4"
              >
                {date}
              </Caption1>
            </div>
          </VStack>
        )}
      </div>
    </div>
  );
};
