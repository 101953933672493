import clsx from "clsx";
import { Button } from "components/base";
import { HStack, VStack } from "components/layout/Stack";
import {
  ACCESS_LEVEL_TEXT,
  AccessLevelDropdown,
  DeleteAssignmentModalContent,
} from "components/pages";
import {
  Base1Strong,
  Base2,
  Base2Strong,
  Caption1,
  Display4,
} from "components/Typography";
import { useGetEnvsContext, useInviteUsers } from "contexts";
import { format } from "date-fns";
import {
  AccessLevel,
  Exact,
  Project,
  ProjectInvitationsScope,
  Scalars,
  UpdatePendingInvitationMutation,
  UpdateProjectAssignmentAccessLevelMutation,
  useDeleteProjectInvitationMutation,
  useUpdatePendingInvitationMutation,
  useUpdateProjectAssignmentRoleMutation,
} from "graphql/generated";
import { useModal, useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { capitalize, noop, startCase } from "lodash";
import {
  ArrowLeft,
  CaretDown,
  CaretRight,
  CheckSquare,
  PencilSimpleLine,
} from "phosphor-react";
import { useProjectInvitations } from "queries";
import React, { ReactNode, useEffect, useState } from "react";
import {
  QueryClient,
  UseMutateAsyncFunction,
  useQueryClient,
} from "react-query";
import { VALIDATE_STRING_REGEX } from "utils/helpers/validations";

import SettingsMenuCloseButton from "./SettingsMenuCloseButton";
import { WORKSPACE_CONTENT_TYPE } from "./WorkspaceMembersContent";
import {
  PendingInvitation,
  ProjectAssignments,
} from "./WorkspaceProjectsContent";

type MemberDetailsProps = {
  children: ReactNode;
};

type ActiveMemberDetailsProps = {
  project?: Project;
  member: ProjectAssignments;
  onClose: () => void;
  onModalClose: () => void;
  from: string;
  isOrgAdmin?: boolean;
};

type PendingMemberDetailsProps = {
  project: Project;
  member: PendingInvitation;
  onClose: () => void;
  onModalClose: () => void;
  isOrgAdmin: boolean;
};

type ProjectRoleButtonProps = {
  isEditable: boolean;
  projectRole: string;
  assignmentId: string;
  project: Project;
};

const getAccessLevel = (accessLevel = "") => {
  switch (accessLevel) {
    case AccessLevel.AdminAccess:
      return "Admin";
    case AccessLevel.MemberAccess:
      return "Member";
    default:
      return "View";
  }
};

function MemberDetails({ children }: MemberDetailsProps) {
  return (
    <VStack space={6} className="pt-6">
      <VStack space={2}>{children}</VStack>
    </VStack>
  );
}

function ProjectRoleButton({
  projectRole,
  assignmentId,
  project,
  isEditable,
}: ProjectRoleButtonProps) {
  const { id } = project;
  const queryClient = useQueryClient();
  const [newProjectRole, setNewProjectRole] = useState(projectRole);
  const [editMode, setEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { mutateAsync } = useUpdateProjectAssignmentRoleMutation();

  const updateProjectRole = async () => {
    try {
      await mutateAsync({
        id: assignmentId,
        role: newProjectRole,
      });
      void queryClient.refetchQueries(["ProjectById", { id }]);
      errorMessage && setErrorMessage("");
      setEditMode(false);
    } catch {
      setErrorMessage(t("errors.somethingWentWrong"));
    }
  };

  const validateProjectRole = () => {
    if (!newProjectRole) {
      setErrorMessage(t("errors.presence"));
    } else if (!VALIDATE_STRING_REGEX.test(newProjectRole)) {
      setErrorMessage(t("errors.noLeadingSpaces"));
    } else {
      errorMessage && setErrorMessage("");
      void updateProjectRole();
    }
  };

  return (
    <VStack space={2}>
      <Base2
        className="flex items-center bg-tint-dark-15 py-3 px-6 text-neutral-90"
        style={{ borderRadius: "32px" }}
        data-testid="project-role"
      >
        {editMode ? (
          <HStack space={2} align="center" className="min-w-[280px]">
            <input
              type="text"
              value={newProjectRole}
              onChange={(e) => setNewProjectRole(e.target.value)}
              maxLength={30}
              placeholder="Input your project role here.."
              className="w-full bg-transparent px-3 outline-none"
              data-testid="projectRoleInput"
            />
            <CheckSquare
              size={24}
              color="#35363E"
              className="inline-block cursor-pointer"
              onClick={validateProjectRole}
            />
          </HStack>
        ) : (
          <>
            {newProjectRole}
            {isEditable && (
              <PencilSimpleLine
                className="ml-2 inline-block cursor-pointer"
                size={24}
                color="#35363E"
                onClick={() => setEditMode(true)}
                data-testid="editProjectRoleButton"
              />
            )}
          </>
        )}
      </Base2>
      {errorMessage && (
        <Caption1 className="text-right text-secondary-red-50">
          {errorMessage}
        </Caption1>
      )}
    </VStack>
  );
}

export function ActiveMemberDetails({
  project,
  member,
  onClose,
  onModalClose,
  from,
  isOrgAdmin = false,
}: ActiveMemberDetailsProps) {
  const { user, joinedAt, accessLevel, role, id } = member;
  const { projectAssignments } = (project as Project) || {};
  const { currentUser } = useGetEnvsContext();

  const currentUserAssignment = projectAssignments?.find(
    (assignment) => assignment.user.id === currentUser?.id,
  );
  const isOrgAdminProjectMember = projectAssignments?.some(
    (assignment) => assignment.user.id === currentUser?.id,
  );
  const isUserProjectAdmin =
    currentUserAssignment?.accessLevel === AccessLevel.AdminAccess;

  const isProjectRoleEditable =
    (isOrgAdmin && isOrgAdminProjectMember) || isUserProjectAdmin;

  return (
    <div className="grow overflow-y-scroll px-14 py-8">
      <HStack justify="between">
        <HStack
          space={2}
          className="mb-9 cursor-pointer text-neutral-90"
          onClick={onClose}
          align="center"
        >
          <ArrowLeft size={24} />
          <Base2>{from}</Base2>
        </HStack>
        <SettingsMenuCloseButton onClose={onModalClose} />
      </HStack>
      <Display4 className="mb-36">{startCase(user?.fullName)}</Display4>
      <VStack space={2}>
        <HStack
          justify="between"
          align="center"
          className="border-b border-neutral-20 py-4 pr-8"
        >
          <VStack space={4}>
            <Base1Strong className="text-neutral-90">{user?.email}</Base1Strong>
            {joinedAt && (
              <Base2 className="text-neutral-70">
                {t("shared.joined")} {format(new Date(joinedAt), "MMM d, yyyy")}
              </Base2>
            )}
          </VStack>
          <Base2
            className="bg-tint-dark-15 py-3 px-6 text-neutral-90"
            style={{ borderRadius: "32px" }}
            data-testid="access-level"
          >
            {getAccessLevel(accessLevel)}
          </Base2>
        </HStack>
        {from === WORKSPACE_CONTENT_TYPE.PROJECTS && (
          <HStack
            justify="between"
            align="center"
            className="border-b border-neutral-20 py-4 pr-8"
          >
            <VStack space={4}>
              <Base1Strong className="text-neutral-90">
                {t("shared.projectRole")}
              </Base1Strong>
              <Base2 className="text-neutral-70">
                {t("shared.projectRolePhrase")}
              </Base2>
            </VStack>
            <ProjectRoleButton
              projectRole={role || user.defaultProjectRole}
              assignmentId={id}
              project={project as Project}
              isEditable={isProjectRoleEditable}
            />
          </HStack>
        )}
      </VStack>
    </div>
  );
}

function PendingMemberDetails({
  project,
  member,
  onClose,
  onModalClose,
  isOrgAdmin,
}: PendingMemberDetailsProps) {
  const {
    recipient,
    recipientEmail,
    accessLevel,
    createdAt,
    id: invitationId,
  } = member;
  useEffect(() => {
    if (!!accessLevel) setInviteAccessLevel(accessLevel);
  }, []);
  const { inviteAccessLevel, setInviteAccessLevel } = useInviteUsers();
  const {
    isOpen,
    openModal,
    closeModal,
    Modal: DeleteAssignmentModal,
  } = useModal();
  const { id, projectAssignments } = project;
  const queryClient = useQueryClient();
  const { currentUser } = useGetEnvsContext();

  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const { hasAdminAccess } = useProjectAccessLevel({
    projectId: id,
  });
  const { invitations } = useProjectInvitations({
    projectId: id,
    scope: ProjectInvitationsScope.All,
  });
  const isProjectMember = projectAssignments.find(
    (assignment) => assignment?.user?.email === currentUser?.email,
  );
  const currentMemberInvitation = invitations?.find(
    (invitation) => invitation?.recipient?.email === recipient?.email,
  );
  const { mutateAsync: updateAccessLevel } =
    useUpdatePendingInvitationMutation();

  const handleChangeAccessLevel = async (
    id: Scalars["ID"],
    accessLevel: AccessLevel,
    updateAccessLevel:
      | UseMutateAsyncFunction<
          UpdatePendingInvitationMutation,
          unknown,
          Exact<{
            id: string;
            accessLevel: AccessLevel;
          }>,
          unknown
        >
      | UseMutateAsyncFunction<
          UpdateProjectAssignmentAccessLevelMutation,
          unknown,
          Exact<{
            id: string;
            accessLevel: AccessLevel;
          }>,
          unknown
        >,
    queryClient: QueryClient,
  ) => {
    await updateAccessLevel({
      id,
      accessLevel,
    });
    setInviteAccessLevel(accessLevel);
    await queryClient.refetchQueries("ProjectInvitations");
  };

  const { mutateAsync: deleteProjectInvitation } =
    useDeleteProjectInvitationMutation();
  const [invitationToDelete, setInvitationToDelete] =
    useState<string>(invitationId);

  const handleOpenModal = (invitationId: string) => {
    openModal();
    setInvitationToDelete(invitationId);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const handleDeleteProjectInvitation = async () => {
    if (!invitationToDelete) return;
    await deleteProjectInvitation({ id: invitationToDelete });
    closeModal();
    onClose();
    await queryClient.refetchQueries("ProjectInvitations");
  };

  const handleOnDelete = (invitationId: string) =>
    handleOpenModal(invitationId);

  return (
    <div className="grow overflow-y-scroll px-14 py-8">
      <HStack justify="between">
        <HStack
          space={2}
          className="mb-9 cursor-pointer text-neutral-90"
          onClick={onClose}
          align="center"
        >
          <ArrowLeft size={24} />
          <Base2>{t("component.settings.tabs.projects.title")}</Base2>
        </HStack>
        <SettingsMenuCloseButton onClose={onModalClose} />
      </HStack>
      <VStack space={3} className="mb-36">
        <Display4 data-testid="recipientEmail">
          {recipientEmail || recipient?.email}
        </Display4>
        {showErrorMsg && (
          <Caption1 className="text-secondary-red-50">
            {t("errors.noAccessToPerformAction")}
          </Caption1>
        )}
      </VStack>
      <VStack space={8}>
        <HStack
          justify="between"
          align="center"
          className="border-b border-neutral-20 py-4 pr-8"
        >
          <VStack space={4}>
            <Base1Strong className="text-neutral-90">
              {recipientEmail || recipient?.email}
            </Base1Strong>
            {createdAt && (
              <Base2 className="text-neutral-70">
                {t("shared.invited")}{" "}
                {format(new Date(createdAt), "MMM d, yyyy")}
              </Base2>
            )}
          </VStack>
          <Base2 className="text-neutral-90">
            <AccessLevelDropdown
              currentLevel={inviteAccessLevel}
              onSelect={
                // IF CURRENT USER IS ORGADMIN OR PROJECT ADMIN AND IS MEMBER OF THE PROJECT
                (isOrgAdmin || hasAdminAccess) && isProjectMember
                  ? (newLevel) =>
                      void handleChangeAccessLevel(
                        currentMemberInvitation?.id as string,
                        newLevel,
                        updateAccessLevel,
                        queryClient,
                      )
                  : () => setShowErrorMsg(true)
              }
              canAssignAdmin={hasAdminAccess}
              buttonContent={
                <Button variant="tertiary" RightIcon={CaretDown}>
                  {ACCESS_LEVEL_TEXT[inviteAccessLevel].label} (
                  {t("shared.pending")})
                </Button>
              }
              data-testid="access-dropdown"
            />
          </Base2>
        </HStack>
        {/* IF CURRENT USER IS ORGADMIN OR PROJECT ADMIN AND IS MEMBER OF THE PROJECT */}
        {(hasAdminAccess || isOrgAdmin) && isProjectMember && (
          <Button
            variant="destructive"
            className="self-start"
            onClick={() => handleOnDelete(invitationToDelete)}
            data-testid="revoke-invite"
          >
            {t("shared.revokeInvitation")}
          </Button>
        )}
      </VStack>
      <DeleteAssignmentModal isFullScreen open={isOpen} onClose={closeModal}>
        <DeleteAssignmentModalContent
          userName={recipient?.email || ""}
          onClose={handleCloseModal}
          onDelete={handleDeleteProjectInvitation}
        />
      </DeleteAssignmentModal>
    </div>
  );
}

function MembersTable({
  activeMembers,
  pendingInvitations,
  setSelectedActiveMember,
  setSelectedPendingMember,
  isOrgAdmin,
}: {
  activeMembers: ProjectAssignments[];
  pendingInvitations: PendingInvitation[];
  setSelectedActiveMember: React.Dispatch<
    React.SetStateAction<ProjectAssignments | undefined>
  >;
  setSelectedPendingMember: React.Dispatch<
    React.SetStateAction<PendingInvitation | undefined>
  >;
  isOrgAdmin: boolean;
}) {
  const { currentUser } = useGetEnvsContext();
  const isCurrentUserHaveViewAccess =
    activeMembers?.find(
      (memberAssignment) => memberAssignment?.user?.id === currentUser?.id,
    )?.accessLevel === AccessLevel.ViewAccess;

  return (
    <VStack space={4}>
      <HStack justify="between" className="text-primary-turquoise-70">
        <Base2Strong className="flex-1">{t("shared.teamMember")}</Base2Strong>
        <Base2Strong className="flex-1">
          {t("shared.permissionLevel")}
        </Base2Strong>
        <div className="w-32" />
      </HStack>
      <VStack className="divide-y divide-neutral-20">
        {activeMembers?.map((member) => (
          <HStack
            key={member?.id}
            justify="between"
            className={clsx(
              "pt-6 pb-4",
              (isOrgAdmin || !isCurrentUserHaveViewAccess) && "cursor-pointer",
            )}
            onClick={
              // IF CURRENT USER IS ORG ADMIN OR IS NOT HAVING VIEW ACCESS
              // IN SELECTED PROJECT
              isOrgAdmin || !isCurrentUserHaveViewAccess
                ? () => setSelectedActiveMember(member)
                : noop
            }
            data-testid="active-member"
          >
            <VStack space={2} className="flex-1">
              <Base1Strong>{startCase(member?.user?.fullName)}</Base1Strong>
              <Base2 className="text-neutral-70">
                {capitalize(member?.role)}
              </Base2>
            </VStack>
            <Base1Strong className="flex-1">
              {getAccessLevel(member?.accessLevel)}
            </Base1Strong>
            <Base1Strong className="w-32">
              <CaretRight size={20} className="ml-auto self-center" />
            </Base1Strong>
          </HStack>
        ))}
        {pendingInvitations?.map((member) => (
          <HStack
            key={member?.id}
            justify="between"
            className={clsx(
              "pt-6 pb-4",
              (isOrgAdmin || !isCurrentUserHaveViewAccess) && "cursor-pointer",
            )}
            onClick={
              // IF CURRENT USER IS ORG ADMIN OR IS NOT HAVING VIEW ACCESS
              // IN SELECTED PROJECT
              isOrgAdmin || !isCurrentUserHaveViewAccess
                ? () => setSelectedPendingMember(member)
                : noop
            }
            data-testid="pending-member"
          >
            <Base1Strong className="flex-1">
              {member.recipientEmail || member?.recipient?.email}
            </Base1Strong>
            <Base1Strong className="flex flex-1 gap-1">
              {getAccessLevel(member?.accessLevel)}
              <Base1Strong className="text-secondary-orange-70">
                ({t("shared.pending")})
              </Base1Strong>
            </Base1Strong>
            <Base1Strong className="w-32">
              <CaretRight size={20} className="ml-auto self-center" />
            </Base1Strong>
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
}

MemberDetails.ActiveMemberDetails = ActiveMemberDetails;
MemberDetails.PendingMemberDetails = PendingMemberDetails;
MemberDetails.MembersTable = MembersTable;

export default MemberDetails;
