import clsx from "clsx";
import { Caption1, Heading3 } from "components/Typography";
import { t } from "i18n-js";
import { Warning } from "phosphor-react";
import React from "react";

export interface MissingDataErrorDisplayProps {
  title?: string;
  message?: string;
  className?: string;
}

/**
 * If there's no custom error state designed for a view, try slotting in this
 * display component to ensure there's some indication that something went wrong.
 *
 * Its default width is 360px so as to keep the text tight,
 * but that can be easily overriden with the `className` prop.
 */
export const MissingDataErrorDisplay = ({
  title,
  message,
  className,
}: MissingDataErrorDisplayProps) => {
  return (
    <div
      className={clsx(
        "mx-auto flex h-full flex-col items-center justify-center gap-2 md:w-[360px]",
        className,
      )}
    >
      <Warning size={120} weight="thin" className="text-neutral-600" />

      <Heading3 className="text-center text-neutral-800">
        {title || t("component.missingDataErrorDisplay.title")}
      </Heading3>

      <Caption1 className="text-center text-neutral-700">
        {message || t("component.missingDataErrorDisplay.message")}
      </Caption1>
    </div>
  );
};
