import {
  PersonalTalkTimeMetricsQuery,
  usePersonalTalkTimeMetricsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query PersonalTalkTimeMetrics {
    personalTalkTimeMetrics {
      overallProjectData {
        totalWorkshops
        totalWords
        averageTalkTime
      }
      activeProjectsData {
        totalWords
        totalWorkshops
        averageTalkTime
      }
      activeProjectsList {
        totalWords
        totalWorkshops
        project {
          name
          id
        }
        averageTalkTime
      }
      archivedProjectsList {
        totalWords
        totalWorkshops
        project {
          name
          id
        }
        averageTalkTime
      }
    }
  }
`;

function usePersonalTalkTimeMetrics(): {
  personalTalkTimeMetrics: PersonalTalkTimeMetricsQuery["personalTalkTimeMetrics"];
} & UseQueryResult<PersonalTalkTimeMetricsQuery, unknown> {
  const response = usePersonalTalkTimeMetricsQuery({}, { suspense: true });

  return {
    personalTalkTimeMetrics: response.data?.personalTalkTimeMetrics || {},
    ...response,
  };
}

export { usePersonalTalkTimeMetrics };
