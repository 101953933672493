import { Button, Input } from "components/base";
import { HStack } from "components/layout/Stack";
import { useToast } from "components/provider";
import { Display5, Paragraph1 } from "components/Typography";
import { ProfileQuestion } from "graphql/generated";
import { t } from "i18n-js";
import useCreateProfileAnswer from "mutations/use-create-answer";
import { Plus } from "phosphor-react";
import React, { useState } from "react";
export interface ManualOfMeQuestionProps {
  profileQuestion: ProfileQuestion;
}
export function ManualOfMeQuestion({
  profileQuestion,
}: ManualOfMeQuestionProps) {
  const [editingAnswer, setEditingAnswer] = useState(false);
  const [questionInput, setQuestionInput] = useState("");
  const { mutate } = useCreateProfileAnswer();
  const { addToast } = useToast();
  const toggleEditingAnswer = (editingAnswer: boolean): void =>
    setEditingAnswer(!editingAnswer);
  const saveQuestionAnswer = (answer: string, questionId: string) => {
    mutate(
      {
        answer,
        profile_question_id: questionId,
      },
      {
        onSuccess: () => {
          addToast({
            message: t(
              "component.userSettings.tabs.profile.sections.manualOfMe.added",
            ),
            hasCloseOption: true,
          });
        },
      },
    );
  };
  const handleQuestionInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ques = event.target.value;
    ques.trim() === ""
      ? setQuestionInput("")
      : setQuestionInput(event.target.value);
  };
  return (
    <div className="mb-4 border-b border-neutral-20 pt-4">
      <HStack justify="between" align="center">
        <Display5 className="text-neutral-90">
          <Paragraph1>{profileQuestion.question}</Paragraph1>
        </Display5>
        {!editingAnswer ? (
          <Button
            onClick={() => toggleEditingAnswer(editingAnswer)}
            variant="tertiary"
            className="mb-4"
            LeftIcon={Plus}
          />
        ) : (
          <div className="flex gap-2">
            <Button
              onClick={() =>
                saveQuestionAnswer(questionInput, profileQuestion.id)
              }
              variant="primary"
              disabled={!questionInput || questionInput.length === 0}
            >
              {t(
                "component.userSettings.tabs.profile.sections.manualOfMe.questionsList.QuestionEditConfirm",
              )}
            </Button>
            <Button
              onClick={() => toggleEditingAnswer(editingAnswer)}
              variant="outline"
            >
              {t(
                "component.userSettings.tabs.profile.sections.manualOfMe.questionsList.QuestionEditCancel",
              )}
            </Button>
          </div>
        )}
      </HStack>
      {editingAnswer && (
        <Input
          autoFocus
          value={questionInput}
          onChange={(event) => {
            handleQuestionInput(event);
          }}
          className="!border-none !bg-transparent tracking-widest placeholder:text-tint-dark-400"
          placeholder={t(
            "component.userSettings.tabs.profile.sections.manualOfMe.questionsList.QuestionEditInputPlaceholder",
          )}
        />
      )}
    </div>
  );
}
