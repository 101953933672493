import { Menu } from "@headlessui/react";
import clsx from "clsx";
import {
  Base1Strong,
  Base2,
  Base2Strong,
  Caption1,
  Display3,
  Display4,
  Heading1Strong,
  HStack,
  VStack,
} from "components";
import { Button, Dropdown } from "components/base";
import { EditProjectModal } from "components/partial";
import { InviteUsersProvider, useGetEnvsContext } from "contexts";
import { format, parseISO } from "date-fns";
import { GraphQLError } from "graphql";
import {
  AccessLevel,
  OrganizationMembershipAccessLevel,
  ProjectStatus,
  Scalars,
  useUpdateProjectAssignmentAccessLevelMutation,
} from "graphql/generated";
import { useModal, useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { noop } from "lodash";
import useChangeProjectStatus from "mutations/use-archive-project";
import {
  ArrowLeft,
  CaretRight,
  DotsThreeVertical,
  FolderOpen,
  FolderSimplePlus,
  PencilSimple,
} from "phosphor-react";
import {
  useOrganizationMemberships,
  useOrganizationProjects,
  useProject,
  useProjectAssignments,
} from "queries";
import React, { Suspense, useState } from "react";
import { useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import Integrations from "./Integrations";
import MemberDetails from "./MemberDetails";
import SettingsMenuCloseButton from "./SettingsMenuCloseButton";
import { WORKSPACE_CONTENT_TYPE } from "./WorkspaceMembersContent";

type ProjectAssignment = {
  accessLevel: AccessLevel;
  user: {
    id: Scalars["ID"];
    fullName: Scalars["String"];
  };
};

type Project = {
  id: Scalars["ID"];
  name: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  membersCount: Scalars["Int"];
  projectAssignments: Array<ProjectAssignment>;
};

type OrganizationMembership = {
  id: Scalars["ID"];
  accessLevel: OrganizationMembershipAccessLevel;
  user: {
    id: Scalars["ID"];
    fullName: Scalars["String"];
    email: Scalars["String"];
  };
};

const getProjectAdmins = (
  assignments: Array<ProjectAssignment>,
  currentUserId?: Scalars["ID"],
): Scalars["String"] => {
  return assignments
    .filter(({ accessLevel }) => accessLevel === AccessLevel.AdminAccess)
    .map(({ user: { id, fullName } }) =>
      id === currentUserId ? `${fullName} (You)` : fullName,
    )
    .join(", ");
};

export const isOrganizationAdmin = (
  memberships?: Array<OrganizationMembership>,
  currentUserId?: Scalars["ID"],
): Scalars["Boolean"] => {
  return memberships
    ? memberships.some(
        ({ accessLevel, user: { id } }) =>
          accessLevel === OrganizationMembershipAccessLevel.AdminAccess &&
          currentUserId === id,
      )
    : false;
};

export interface ProjectAssignments {
  id: string;
  user: {
    id: string;
    fullName: string;
    email: string;
    defaultProjectRole: string;
  };
  accessLevel: AccessLevel;
  role: string;
  joinedAt?: string;
}

export interface PendingInvitation {
  id: string;
  recipient: {
    id: string;
    fullName: string;
    email: string;
  };
  recipientEmail: string;
  acceptedAt: string;
  accessLevel: AccessLevel;
  createdAt: string;
}

function NoResultsCard({ text }: { text: Scalars["String"] }) {
  return (
    <VStack
      space={4}
      className="rounded-lg bg-tint-dark-10 pt-9 pb-7 text-center text-neutral-60"
    >
      <FolderOpen size={40} weight="light" className="self-center" />
      <Base2>{text}</Base2>
    </VStack>
  );
}

// TEMPORARY COMPONENT: COEUR-39 WILL IMPLEMENT FULLY
function ProjectItem({
  id,
  onClose,
  onModalClose,
  isOrgAdmin,
}: {
  id: Scalars["ID"];
  onClose: () => void;
  onModalClose: () => void;
  isOrgAdmin: boolean;
}) {
  const queryClient = useQueryClient();
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const { project } = useProject({ id });
  const navigate = useNavigate();
  const [selectedActiveMember, setSelectedActiveMember] =
    useState<ProjectAssignments>();
  const [selectedPendingMember, setSelectedPendingMember] =
    useState<PendingInvitation>();
  const { isOpen, openModal, closeModal } = useModal();
  const [editProjectModalId, setEditProjectModalId] = useState("");

  const { currentUser } = useGetEnvsContext();
  const { hasAdminAccess } = useProjectAccessLevel({ projectId: id });
  const { projectAssignments } = useProjectAssignments({
    projectId: id,
  });

  const assignment = projectAssignments?.find(
    (assignment) => assignment?.accessLevel === AccessLevel.AdminAccess,
  );
  const currentUserProjectAssignment = projectAssignments?.find(
    (assignment) => assignment.user.id === currentUser?.id,
  );

  const { mutateAsync } = useUpdateProjectAssignmentAccessLevelMutation();
  if (!project) return <div>Loading...</div>;

  if (selectedActiveMember) {
    return (
      <MemberDetails.ActiveMemberDetails
        project={project as unknown as Project}
        member={selectedActiveMember}
        onClose={() => setSelectedActiveMember(undefined)}
        onModalClose={onModalClose}
        from={WORKSPACE_CONTENT_TYPE.PROJECTS}
        isOrgAdmin={isOrgAdmin}
      />
    );
  }

  if (selectedPendingMember) {
    return (
      <InviteUsersProvider>
        <MemberDetails.PendingMemberDetails
          project={project as unknown as Project}
          member={selectedPendingMember}
          onClose={() => setSelectedPendingMember(undefined)}
          onModalClose={onModalClose}
          from={WORKSPACE_CONTENT_TYPE.PROJECTS}
          isOrgAdmin={isOrgAdmin}
        />
      </InviteUsersProvider>
    );
  }

  const handleUpdateAccessLevel = async (
    assignmentId: string,
    accessLevel: AccessLevel,
  ) => {
    try {
      await mutateAsync({
        id: assignmentId,
        accessLevel,
      });
      void queryClient.invalidateQueries(["ProjectAssignments"]);
      void queryClient.refetchQueries(["ProjectById", { id: project.id }]);
      void queryClient.refetchQueries(["UserProjects"]);
    } catch (error) {
      const err = error as GraphQLError;
      if (err.message === t("errors.noAccessToPerformAction")) {
        setShowErrorMsg(true);
      }
    }
  };

  return (
    <div className="grow overflow-y-scroll px-14 py-8">
      <HStack justify="between">
        <HStack
          space={2}
          className="mb-9 cursor-pointer text-neutral-90"
          onClick={onClose}
          align="center"
        >
          <ArrowLeft size={24} />
          <Base2>{t("component.settings.tabs.projects.title")}</Base2>
        </HStack>
        <SettingsMenuCloseButton onClose={onModalClose} />
      </HStack>
      <VStack space={2} className="mb-16">
        <Display4>{project?.name}</Display4>
        {showErrorMsg && (
          <Caption1 className="text-secondary-red-50">
            {t("errors.mustAssignAnotherProjectAdmin")}
          </Caption1>
        )}
      </VStack>
      <HStack space={6} className="mb-22">
        {/* IF CURRENT USER IS ORG ADMIN OR PROJECT ADMIN AND IS PROJECT MEMBER */}
        {(isOrgAdmin || hasAdminAccess) && currentUserProjectAssignment && (
          <Button
            onClick={() => {
              setEditProjectModalId(project?.id);
              openModal();
            }}
            variant="tertiary"
            LeftIcon={PencilSimple}
          >
            {t("shared.editProject")}
          </Button>
        )}
        <Button
          variant="tertiary"
          onClick={
            // WHEN CURRENT USER IS ORG ADMIN BUT NOT A PROJECT MEMBER AND
            // TRIES TO VIEW PROJECT DETAILS
            isOrgAdmin && !currentUserProjectAssignment?.id
              ? () => {
                  onModalClose();
                  navigate("/projects/unauthorized");
                }
              : () => {
                  onModalClose();
                  navigate(`/projects/${project?.id}`);
                }
          }
        >
          {t("shared.viewProject")}
        </Button>
        {isOrgAdmin && (
          <Button
            onClick={() =>
              hasAdminAccess
                ? void handleUpdateAccessLevel(
                    currentUserProjectAssignment?.id as string,
                    (currentUserProjectAssignment?.lastAccessLevel as AccessLevel) ||
                      AccessLevel.MemberAccess,
                  )
                : void handleUpdateAccessLevel(
                    currentUserProjectAssignment?.id
                      ? currentUserProjectAssignment?.id
                      : (assignment?.id as string),
                    AccessLevel.AdminAccess,
                  )
            }
            variant="tertiary"
          >
            {hasAdminAccess ? t("shared.unAssign") : t("shared.selfAssign")}
          </Button>
        )}
      </HStack>
      <VStack space={8} className="mb-22">
        <Heading1Strong className="text-neutral-90">
          {t("shared.projectTeam")}
        </Heading1Strong>
        <MemberDetails.MembersTable
          activeMembers={project?.projectAssignments}
          pendingInvitations={project?.pendingInvitations}
          setSelectedActiveMember={setSelectedActiveMember}
          setSelectedPendingMember={setSelectedPendingMember}
          isOrgAdmin={isOrgAdmin}
        />
      </VStack>
      <EditProjectModal
        editProjectId={editProjectModalId}
        show={isOpen}
        onClose={closeModal}
      />
      <Suspense fallback={"Loading..."}>
        <Integrations projectId={id} />
      </Suspense>
    </div>
  );
}

function ProjectsTable({
  projects,
  status,
  onSelect = noop,
  buttonClickHandler = noop,
  isOrgAdmin,
}: {
  projects: Array<Project>;
  status: ProjectStatus;
  onSelect?: (id: Scalars["ID"]) => void;
  buttonClickHandler?: (statusCode: number, projectId: string) => void;
  isOrgAdmin: boolean;
}) {
  const { currentUser } = useGetEnvsContext();
  const { isOpen, openModal, closeModal } = useModal();
  const [editProjectModalId, setEditProjectModalId] = useState("");

  return (
    <VStack className="border-b border-neutral-20">
      <HStack justify="between" className="mt-4 text-primary-turquoise-70">
        <Base2Strong>{t("shared.project")}</Base2Strong>
        <Base2Strong>{t("shared.members")}</Base2Strong>
        <Base2Strong>{t("shared.admins")}</Base2Strong>
        <div className="w-52" />
      </HStack>
      <VStack className="divide-y divide-neutral-20">
        {projects.map(
          ({ id, name, createdAt, membersCount, projectAssignments }) => {
            // CHECK IF CURRENT USER HAS VIEW ACCESS IN ITERATING PROJECT
            const isCurrentUserHaveViewAccess =
              projectAssignments?.find(
                (assignment) => assignment?.user?.id === currentUser?.id,
              )?.accessLevel === AccessLevel.ViewAccess;

            return (
              <HStack
                key={id}
                justify="between"
                className={clsx(
                  "pt-6 pb-4",
                  (isOrgAdmin || !isCurrentUserHaveViewAccess) &&
                    "cursor-pointer",
                )}
                data-testid={`workspace-project-${status}`}
                onClick={() =>
                  (isOrgAdmin || !isCurrentUserHaveViewAccess) && onSelect(id)
                }
              >
                <VStack space={2} className="flex-1">
                  <Base1Strong>{name}</Base1Strong>
                  <Base2 className="text-neutral-70">
                    {t("shared.created")}{" "}
                    {format(parseISO(createdAt), "MMMM d, yyyy")}
                  </Base2>
                </VStack>
                <Base1Strong className="flex-1">{membersCount}</Base1Strong>
                <Base1Strong className="flex-1">
                  {getProjectAdmins(projectAssignments, currentUser?.id)}
                </Base1Strong>

                <HStack
                  className={clsx(
                    "w-52",
                    status === ProjectStatus.Unarchived && "justify-end",
                  )}
                >
                  {status === ProjectStatus.Active &&
                    (isOrgAdmin || !isCurrentUserHaveViewAccess) && (
                      <CaretRight
                        size={24}
                        data-testid="active-project"
                        className="ml-auto self-center"
                      />
                    )}

                  {status === ProjectStatus.Archived && isOrgAdmin && (
                    <Button
                      onClick={() => buttonClickHandler(2, id)}
                      variant="primaryDark"
                      data-testid="unarchive-button"
                    >
                      {t("shared.unarchiveProject")}
                    </Button>
                  )}

                  {status === ProjectStatus.Unarchived && isOrgAdmin && (
                    <Dropdown
                      className="relative bg-neutral-30"
                      button={
                        <Menu.Button
                          className="absolute right-2 bottom-4"
                          as="button"
                          data-testid="unarchive-options"
                        >
                          <DotsThreeVertical size={32} color="#000000" />
                        </Menu.Button>
                      }
                      items={
                        <Menu.Items
                          className={clsx(
                            "flex-column absolute right-4 -bottom-22 w-40 overflow-hidden rounded-md bg-white drop-shadow-md",
                            Z_INDEX_LEVELS.BASE_CONTROL,
                          )}
                        >
                          <Menu.Button
                            as="button"
                            onClick={() => buttonClickHandler(1, id)}
                            className="h-12 w-full hover:bg-neutral-10"
                          >
                            {t("shared.archiveProject")}
                          </Menu.Button>
                          <Menu.Button
                            as="button"
                            onClick={() => {
                              setEditProjectModalId(id);
                              openModal();
                            }}
                            className="h-12 w-full hover:bg-neutral-10"
                          >
                            {t("shared.editProject")}
                          </Menu.Button>
                        </Menu.Items>
                      }
                    />
                  )}
                </HStack>
              </HStack>
            );
          },
        )}
      </VStack>
      <EditProjectModal
        editProjectId={editProjectModalId}
        show={isOpen}
        onClose={closeModal}
      />
    </VStack>
  );
}

function WorkspaceProjectsContent({
  organizationId,
  onClose,
  projectId,
}: {
  organizationId: Scalars["ID"];
  onClose: () => void;
  projectId?: Scalars["ID"];
}) {
  const { currentUser } = useGetEnvsContext();
  const { projects } = useOrganizationProjects({ organizationId });
  const { memberships } = useOrganizationMemberships({ organizationId });

  const isAdmin = isOrganizationAdmin(memberships, currentUser?.id);
  const { mutateAsync } = useChangeProjectStatus();
  const queryClient = useQueryClient();

  const activeProjects = projects.filter(
    ({ status }) => status === ProjectStatus.Active,
  );
  const archivedProjects = projects.filter(
    ({ status }) => status === ProjectStatus.Archived,
  );
  const unarchivedProjects = projects.filter(
    ({ status }) => status === ProjectStatus.Unarchived,
  );

  const [selectedProjectId, setSelectedProjectId] = useState<
    Scalars["ID"] | undefined
  >(projectId ? projectId : undefined);

  if (selectedProjectId) {
    return (
      <ProjectItem
        id={selectedProjectId}
        onClose={() => setSelectedProjectId(undefined)}
        onModalClose={onClose}
        isOrgAdmin={isAdmin}
      />
    );
  }

  const updateProjectStatus = async (statusCode: number, projectId: string) => {
    try {
      await mutateAsync({
        project: {
          id: projectId,
          status: statusCode,
        },
      });
      void queryClient.invalidateQueries(["OrganizationProjects"]);
    } catch {
      console.error(t("errors.somethingWentWrong"));
    }
  };

  return (
    <VStack
      space={14}
      className="grow overflow-y-auto px-14 py-8"
      data-testid="settings-workspace-projects"
    >
      <VStack space={6}>
        <HStack justify="between" align="center">
          <Display3>{t("component.settings.tabs.projects.title")}</Display3>
          <SettingsMenuCloseButton onClose={onClose} />
        </HStack>
        <HStack>
          {isAdmin && (
            <Link to={`/organizations/${organizationId}/project/new`}>
              <Button
                variant="secondary"
                onClick={noop}
                LeftIcon={FolderSimplePlus}
              >
                {t("shared.createANewProject")}
              </Button>
            </Link>
          )}
        </HStack>
      </VStack>
      <VStack space={8}>
        <Heading1Strong className="mt-8">
          {t("component.settings.tabs.projects.activeProjectsLabel")}
        </Heading1Strong>
        {activeProjects.length > 0 ? (
          <ProjectsTable
            projects={activeProjects}
            status={ProjectStatus.Active}
            onSelect={setSelectedProjectId}
            isOrgAdmin={isAdmin}
          />
        ) : (
          <NoResultsCard
            text={t("component.settings.tabs.projects.emptyActiveProjects")}
          />
        )}
      </VStack>
      <VStack space={8}>
        <Heading1Strong className="mt-8">
          {t("component.settings.tabs.projects.unarchivedProjectsLabel")}
        </Heading1Strong>
        {unarchivedProjects.length > 0 ? (
          <VStack className="-mx-7 rounded-lg bg-tint-dark-10 px-7 py-8">
            <ProjectsTable
              projects={unarchivedProjects}
              status={ProjectStatus.Unarchived}
              buttonClickHandler={
                updateProjectStatus as (
                  statusCode: number,
                  projectId: string,
                ) => void
              }
              isOrgAdmin={isAdmin}
            />
          </VStack>
        ) : (
          <NoResultsCard
            text={t("component.settings.tabs.projects.emptyUnarchivedProjects")}
          />
        )}
      </VStack>
      <VStack space={8}>
        <Heading1Strong className="mt-8">
          {t("component.settings.tabs.projects.archivedProjectsLabel")}
        </Heading1Strong>
        {archivedProjects.length > 0 ? (
          <VStack className="-mx-7 rounded-lg bg-tint-dark-10 px-7 py-8">
            <ProjectsTable
              projects={archivedProjects}
              status={ProjectStatus.Archived}
              buttonClickHandler={
                updateProjectStatus as (
                  statusCode: number,
                  projectId: string,
                ) => void
              }
              isOrgAdmin={isAdmin}
            />
          </VStack>
        ) : (
          <NoResultsCard
            text={t("component.settings.tabs.projects.emptyArchivedProjects")}
          />
        )}
      </VStack>
    </VStack>
  );
}

export default WorkspaceProjectsContent;
