import { Button, Input } from "components/base";
import { HStack } from "components/layout/Stack";
import { Display5, Paragraph1 } from "components/Typography";
import { ProfileAnswer } from "graphql/generated";
import { t } from "i18n-js";
import useDeleteProfileAnswer from "mutations/use-delete-answer";
import useUpdateProfileAnswer from "mutations/use-update-answer";
import React, { useState } from "react";

export interface ManualOfMeQuestionProps {
  profileAnswer: ProfileAnswer;
}

export function ManualOfMeAnswer({ profileAnswer }: ManualOfMeQuestionProps) {
  const [editingAnswer, setEditingAnswer] = useState(false);
  const [answerInput, setAnswerInput] = useState(profileAnswer.answer || "");

  const { mutate } = useUpdateProfileAnswer();
  const { mutate: deleteProfileAnswerMutate } = useDeleteProfileAnswer();

  const toggleEditingAnswer = (editingAnswer: boolean): void =>
    setEditingAnswer(!editingAnswer);

  const saveQuestionAnswer = (answer: string, answerId: string) => {
    mutate({
      answer,
      profile_answer_id: answerId,
    });
    setEditingAnswer(false);
  };

  const deleteQuestionAnswer = (answerId: string) => {
    deleteProfileAnswerMutate({
      profile_answer_id: answerId,
    });
    setEditingAnswer(false);
  };

  return (
    <div className="mb-2 border-neutral-20 py-4 [&:not(:last-child)]:border-b">
      <HStack justify="between" align="center">
        <Display5 className="text-neutral-90">
          <Paragraph1>{profileAnswer.profileQuestion?.question}</Paragraph1>
        </Display5>

        {!editingAnswer ? (
          <Button
            onClick={() => toggleEditingAnswer(editingAnswer)}
            variant="tertiary"
          >
            {t(
              "component.userSettings.tabs.profile.sections.manualOfMe.answersList.editButton",
            )}
          </Button>
        ) : (
          <div className="flex gap-2">
            <Button
              onClick={() => saveQuestionAnswer(answerInput, profileAnswer.id)}
              variant="primary"
              disabled={answerInput.trim().length === 0}
            >
              {t(
                "component.userSettings.tabs.profile.sections.manualOfMe.answersList.saveButton",
              )}
            </Button>
            <Button
              onClick={() => deleteQuestionAnswer(profileAnswer.id)}
              variant="destructive"
            >
              {t(
                "component.userSettings.tabs.profile.sections.manualOfMe.answersList.deleteButton",
              )}
            </Button>
          </div>
        )}
      </HStack>
      {editingAnswer ? (
        <Input
          value={answerInput}
          onChange={(event) => setAnswerInput(event.target.value)}
          className="my-1 !bg-transparent !px-0 text-lg font-normal tracking-widest text-neutral-800 text-neutral-90 placeholder:text-tint-dark-400"
          placeholder={t(
            "component.userSettings.tabs.profile.sections.manualOfMe.questionsList.QuestionEditInputPlaceholder",
          )}
        />
      ) : (
        <Display5 className="my-4 ml-px text-neutral-800">
          <Paragraph1>{profileAnswer.answer}</Paragraph1>
        </Display5>
      )}
    </div>
  );
}
