import React from "react";

export interface TeamViewPersonalityProfileIllustrationProps {
  width?: number;
  height?: number;
  className?: string;
}

export const TeamViewPersonalityProfileIllustration = ({
  width = 249,
  height = 56,
  className,
}: TeamViewPersonalityProfileIllustrationProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M28.0527 0.75769C27.8784 0.75769 27.7194 0.825886 27.5981 0.947145C27.4769 1.0684 27.4087 1.23507 27.4087 1.40173V23.1442C27.4087 23.5003 27.6966 23.7881 28.0526 23.7881H49.0755C49.424 23.7881 49.7119 23.5153 49.7194 23.1669L49.727 23.0456C49.7347 22.8487 49.7421 22.6517 49.7421 22.4471C49.7421 10.4926 40.0076 0.76547 28.053 0.75797L28.0527 0.75769ZM48.4465 22.5077H28.6965V2.06061C39.6436 2.40159 48.4465 11.4169 48.4465 22.4469V22.5076V22.5077Z"
      fill="#5F6368"
      fill-opacity="0.3"
    />
    <path
      d="M43.9708 27.6138H23.592V6.51546C23.592 6.15934 23.2966 5.87158 22.9405 5.87158C10.9859 5.87158 1.25879 15.5987 1.25879 27.5607C1.25879 33.5378 3.64516 39.1137 7.97837 43.2424C12.0389 47.1062 17.3496 49.2424 22.9405 49.2424C34.7055 49.2424 44.2284 40.0378 44.6071 28.2803C44.6148 28.106 44.5466 27.9393 44.4253 27.8106C44.3116 27.6819 44.1449 27.6137 43.9707 27.6137L43.9708 27.6138ZM38.6374 40.6288L26.9099 28.9017H31.3037L40.4553 38.0534C39.9176 38.9624 39.304 39.8185 38.6373 40.629L38.6374 40.6288ZM31.6145 46.038L18.8495 33.273L21.0465 31.076L34.4177 44.4472C33.5313 45.0455 32.5995 45.5835 31.6147 46.0381L31.6145 46.038ZM26.9253 47.5682L15.7432 36.3787L17.9402 34.1817L30.3419 46.5834C29.2434 47 28.1072 47.3334 26.9254 47.5682H26.9253ZM22.3041 7.16658V27.9924L8.42533 41.8637C4.62975 38.0303 2.55408 32.9699 2.55408 27.5607C2.55408 16.5228 11.357 7.50783 22.3041 7.167V7.16658ZM11.7207 40.3937L18.857 47.5299C15.3419 46.8178 12.0766 45.1815 9.357 42.757L11.7207 40.3937ZM21.0012 47.8557L12.6374 39.4845L14.8269 37.295L25.3419 47.8025C24.554 47.8935 23.7585 47.9464 22.9403 47.9464C22.2888 47.9464 21.6448 47.9161 21.0009 47.8556L21.0012 47.8557ZM35.4782 43.6816L21.9557 30.1587L23.2134 28.901H25.0847L37.7813 41.5906C37.0693 42.3481 36.2965 43.045 35.4783 43.6814L35.4782 43.6816ZM41.107 36.8787L33.1295 28.9016H43.2962C43.1069 31.7728 42.3341 34.4699 41.1069 36.8787H41.107Z"
      fill="#5F6368"
      fill-opacity="0.3"
    />
  </svg>
);
