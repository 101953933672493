/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import clsx from "clsx";
import {
  Avatar,
  Button,
  DiSCProfile,
  ReadOnlyRichTextEditor,
  Tooltip,
} from "components/base";
import {
  HStack,
  SummaryLayout,
  SummaryLayoutAgendaTab as AgendaTab,
  SummaryLayoutMain as Main,
  SummaryLayoutSummaryTab as SummaryTab,
  SummaryLayoutTabNav as TabNav,
  VStack,
} from "components/layout";
import {
  MissingDataErrorDisplay,
  PageLoader,
  SummarySectionType,
  SummarySidebar,
  WorkshopResources,
  WorkshopSummaryParticipants,
  WorkshopSummaryRecording,
} from "components/partial";
import { Caption1, Caption2, Heading3, Overline } from "components/Typography";
import { useGetEnvsContext } from "contexts";
import { Agenda, User, WorkshopParticipantRole } from "graphql/generated";
import { useWorkshopParticipant } from "hooks";
import { t } from "i18n-js";
import { Info, PencilSimple } from "phosphor-react";
import { useFetchWorkshopTalkTime, useWorkshop } from "queries";
import { useEmotionExpression } from "queries/use-emotion-expression";
import { useWorkshopPersonalityProfile } from "queries/use-workshop-personality-profile";
import { useWorkshopVTT } from "queries/use-workshop-vtt";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  getPositionBgColor,
  getPositionPercentage,
} from "../UserSettings/components";
import { PersonalityProfileInfoModal } from "../UserSettings/components/UserSettingsInsightsContent/components";
import { formatTime } from "../UserSettings/components/UserSettingsInsightsContent/util";
import { timeZone } from "../WeekView/utils";
import { SummarySection } from "../WorkshopSummaryEdit";
import { EMPTY_JSON_CONTENT } from "../WorkshopSummaryEdit/useSummaryEditEffects";
import {
  DATA_SECTION,
  DESCRIPTION_SECTION,
  NEXT_STEPS_SECTION,
  RECORDING_SECTION,
  RESOURCES_SECTION,
  SUMMARY_SCROLL_CONTAINER_ID,
  SUMMARY_SECTIONS,
  useSummaryScroll,
} from "../WorkshopSummaryEdit/useSummaryScroll";
import { FilteredDiscType } from "./components/DiscTypeUtil";
import EmotionalExpressionData from "./components/EmotionalExpressionData";
import { EmotionalExpressionModal } from "./components/EmotionalExpressionModal";
import { EmptyContentSection } from "./components/EmptyContentSection";
import WorkshopSummaryDataCardTemplate from "./components/WorkshopSummaryDataCardTemplate";
import PersonalIcon from "./Personal_icon.png";
import TeamIcon from "./Team_icon.png";

/**
 * This page allows the user to review the summary of a workshop.
 * If the user is a Facilitator/Owner, they have the option to navigate
 * to the nearby /edit route and edit the workshop summary.
 *
 */

const TooltipContent = (userName: string, totalTalkTime: number) => (
  <div className="rounded-lg bg-neutral-900 text-white">
    <Caption2 className="whitespace-nowrap">
      {userName}, {formatTime(totalTalkTime)}
    </Caption2>
  </div>
);

export const WorkshopSummary = () => {
  const { id = "" } = useParams();
  const { isLoading, isError, workshop, data } = useWorkshop({ id });
  const { refetch: refetchVTT, getWorkshopVtt } = useWorkshopVTT({
    workshopId: id,
    timeZone,
  });

  const { currentUser } = useGetEnvsContext();
  const navigate = useNavigate();
  const { id: workshopId = "" } = useParams();
  const { getWorkshopPersonalityProfile } = useWorkshopPersonalityProfile({
    workshopId,
  });
  const { workshopTalkTime } = useFetchWorkshopTalkTime({ workshopId });
  const [modalsVisibility, setModalsVisibility] = useState({
    EmotionalExpression: false,
    WorkshopPersonality: false,
  });

  const { getEmotionExpression } = useEmotionExpression({ workshopId });
  const { role } = useWorkshopParticipant(workshop, currentUser);
  const isParticipant = role === WorkshopParticipantRole.Participant;
  const isSummaryShared = role === WorkshopParticipantRole.SummaryShared;
  const isFacilitator =
    role === WorkshopParticipantRole.Facilitator ||
    role === WorkshopParticipantRole.Owner;

  const toggleEmotionalExpressionModal = (shouldShow: boolean) => {
    // close others
    setModalsVisibility({
      ...modalsVisibility,
      EmotionalExpression: shouldShow,
    });
  };

  const toggleWorkshopPersonalityModal = (shouldShow: boolean) => {
    // close others
    setModalsVisibility({
      ...modalsVisibility,
      WorkshopPersonality: shouldShow,
    });
  };

  React.useEffect(() => {
    if (!isLoading && !workshop) {
      navigate(`/workshop/unauthorized`);
    }
  }, [workshop, isLoading]);

  const {
    activeSection,
    handleMainScroll,
    descRef,
    nextStepsRef,
    recordingRef,
    dataInsightsRef,
    resourcesRef,
  } = useSummaryScroll(SUMMARY_SECTIONS as SummarySectionType[]);

  const talkTimeOccurances: { [score: number]: number } = {};

  for (const record of workshopTalkTime) {
    const score = record.talkTimeScore || 0;
    talkTimeOccurances[score] = talkTimeOccurances[score] + 1 || 1;
  }

  const navigateToEditIconButton =
    isParticipant || isSummaryShared ? undefined : (
      <Button
        RightIcon={PencilSimple}
        aria-label={t("workshop.summary.edit")}
        variant="tertiary"
        size="small"
        onClick={() => navigate("./edit")}
      />
    );

  const colorVariants = {
    D: "bg-secondary-green-700",
    I: "bg-secondary-red-700",
    S: "bg-secondary-indigo-700",
    C: "bg-secondary-orange-700",
    DText: "text-secondary-green-700",
    IText: "text-secondary-red-700",
    SText: "text-secondary-indigo-700",
    CText: "text-secondary-orange-700",
  };

  const discType = getWorkshopPersonalityProfile?.discSegment;

  const [first, second] = getWorkshopPersonalityProfile?.profileBreakdown || [];

  useEffect(() => {
    const intervalId = setInterval(() => {
      void refetchVTT();
      // eslint-disable-next-line unicorn/numeric-separators-style
    }, 270000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <SummaryLayout>
      {isLoading && <PageLoader isShowing />}

      {!isLoading && (
        <TabNav>
          <SummaryTab>
            <SummarySidebar
              userRole={role}
              sections={SUMMARY_SECTIONS as SummarySectionType[]}
              activeSection={activeSection}
            />
            <Main
              workshop={workshop}
              onScroll={handleMainScroll}
              id={SUMMARY_SCROLL_CONTAINER_ID}
            >
              {isError ? (
                <SummarySection id="summary-load-error">
                  <MissingDataErrorDisplay
                    message={t("workshop.summary.errorMessage")}
                    className="pt-10 pb-20"
                  />
                </SummarySection>
              ) : (
                <>
                  {/* Row with Desciption & People sections */}
                  <div className="relative flex min-h-[240px]">
                    <SummarySection
                      id={DESCRIPTION_SECTION}
                      ref={descRef}
                      className="flex max-h-60 w-2/3 flex-col overflow-auto"
                    >
                      {!!workshop && !!workshop?.description ? (
                        <ReadOnlyRichTextEditor
                          label={t(
                            `workshop.summary.section.${DESCRIPTION_SECTION}`,
                          )}
                          headerEndAdornment={navigateToEditIconButton}
                          readOnlyContent={
                            workshop?.description || EMPTY_JSON_CONTENT
                          }
                        />
                      ) : (
                        <EmptyContentSection
                          label={t(
                            `workshop.summary.section.${DESCRIPTION_SECTION}`,
                          )}
                          emptyMessage={t(
                            "workshop.summary.section.description.empty",
                          )}
                          endAdornment={navigateToEditIconButton}
                        />
                      )}
                    </SummarySection>

                    <SummarySection className="absolute inset-y-0 right-0 flex max-h-60 w-[calc(33%-24px)] basis-70 flex-col overflow-y-auto pb-0">
                      {/** TODO: prevent People section from growing larger than Description -- https://coeurajtech.atlassian.net/browse/R10-249*/}
                      <WorkshopSummaryParticipants workshop={workshop} />
                    </SummarySection>
                  </div>

                  {/* Summary & Next Steps */}
                  <SummarySection ref={nextStepsRef} id={NEXT_STEPS_SECTION}>
                    {!!workshop && !!workshop?.summary ? (
                      <ReadOnlyRichTextEditor
                        label="Summary"
                        headerEndAdornment={navigateToEditIconButton}
                        readOnlyContent={
                          workshop?.summary || EMPTY_JSON_CONTENT
                        }
                      />
                    ) : (
                      <EmptyContentSection
                        label="Summary"
                        emptyMessage={t(
                          "workshop.summary.section.summary.empty",
                        )}
                        endAdornment={navigateToEditIconButton}
                      />
                    )}
                  </SummarySection>

                  {/* Recording & Transcript */}
                  <SummarySection
                    ref={recordingRef}
                    id={RECORDING_SECTION}
                    disabled={workshop?.recordings.length === 0}
                    className="flex justify-center border-0 p-0"
                  >
                    <WorkshopSummaryRecording />
                  </SummarySection>

                  {/* Data Insights */}
                  <SummarySection ref={dataInsightsRef} id={DATA_SECTION}>
                    <Heading3 className="mb-6">
                      {t(`workshop.summary.section.${DATA_SECTION}`)}
                    </Heading3>
                    <div className="mb-6 max-h-[144px] rounded-lg border border-neutral-500 p-6">
                      <Overline className="mb-2 text-neutral-900">
                        {t("workshopSummary.teamParticipation.title")}
                      </Overline>
                      <Caption1 className="text-neutral-700">
                        {t("workshopSummary.teamParticipation.subText")}
                      </Caption1>
                      <HStack space={1} className="relative mt-8">
                        <div className="h-2 flex-1 rounded-tl-md rounded-bl-md bg-secondary-yellow-500 opacity-75" />
                        <div className="h-2 flex-1 bg-secondary-yellow-500 opacity-25" />
                        <div className="h-2 flex-1 bg-secondary-green-500 opacity-25" />
                        <div className="h-2 flex-1 bg-secondary-indigo-700 opacity-25" />
                        <div className="h-2 flex-1 rounded-tr-md rounded-br-md bg-secondary-indigo-700 opacity-75" />
                        {workshopTalkTime.map((record, index) => {
                          const score = record.talkTimeScore || 0;
                          const positionPercentage = getPositionPercentage(
                            record.talkTimeScore || 0,
                          );
                          const positionBgColor =
                            getPositionBgColor(positionPercentage);

                          talkTimeOccurances[score] =
                            talkTimeOccurances[score] + 1 || 1;

                          return (
                            <div
                              className={clsx(
                                "absolute -top-2 cursor-pointer text-center hover:z-[20]",
                              )}
                              style={{
                                left: `${
                                  talkTimeOccurances[score] === 1
                                    ? positionPercentage
                                    : positionPercentage - index
                                }%`,
                              }}
                            >
                              {role === WorkshopParticipantRole.Owner ||
                              role === WorkshopParticipantRole.Facilitator ||
                              currentUser?.id === record.user?.id ? (
                                <Tooltip
                                  content={() =>
                                    TooltipContent(
                                      record.user?.fullName as string,
                                      record.totalTalkTime as number,
                                    )
                                  }
                                  placement="top"
                                  offset={8}
                                >
                                  <Avatar
                                    size="mini"
                                    user={record.user as User}
                                    key={record.user?.id}
                                    textClassName="!text-xs"
                                    className="border-2 border-neutral-50 hover:shadow-md"
                                  />
                                </Tooltip>
                              ) : (
                                <div
                                  className={clsx(
                                    "h-[24px] w-[24px] rounded-full border-2 border-tint-dark-200",
                                    positionBgColor,
                                  )}
                                />
                              )}
                            </div>
                          );
                        })}
                      </HStack>
                      <HStack justify="between" className="mt-3">
                        <Caption2 className="text-neutral-600">
                          {t("workshopSummary.teamParticipation.listeners")}
                        </Caption2>
                        <Caption2 className="text-neutral-600">
                          {t("workshopSummary.teamParticipation.average")}
                        </Caption2>
                        <Caption2 className="text-neutral-600">
                          {t("workshopSummary.teamParticipation.sharers")}
                        </Caption2>
                      </HStack>
                    </div>
                    {!!getWorkshopPersonalityProfile ||
                    getEmotionExpression?.length > 0 ? (
                      <>
                        <div className="mb-6 !mt-6 h-[193px] rounded-lg border border-neutral-50 p-4">
                          <div className="flex flex-row items-center">
                            <HStack className="uppercase">
                              Emotional EXPRESSION
                            </HStack>
                            <Button
                              variant="link"
                              onClick={() =>
                                toggleEmotionalExpressionModal(true)
                              }
                              RightIcon={() => (
                                <Info size={14} className="text-neutral-70" />
                              )}
                              className="ml-1"
                            />
                            {modalsVisibility.EmotionalExpression && (
                              <EmotionalExpressionModal
                                isOpen={modalsVisibility.EmotionalExpression}
                                setIsOpen={(shouldShow) =>
                                  toggleEmotionalExpressionModal(shouldShow)
                                }
                              />
                            )}
                          </div>
                          <VStack className="text-xs tracking-wide text-neutral-700">
                            <HStack>
                              {t("workshopSummary.emotionalExpressionTitle")}
                            </HStack>
                            <HStack>
                              {t(
                                "workshopSummary.emotionalExpressionDescription",
                              )}
                            </HStack>
                          </VStack>
                          {getEmotionExpression?.length > 0 ? (
                            <HStack
                              className="w-full space-x-2 "
                              align="center"
                            >
                              {getEmotionExpression?.map(
                                ({ timeStamp, sentiment }) => {
                                  return (
                                    <EmotionalExpressionData
                                      timestamp={timeStamp}
                                      sentiment={sentiment}
                                    />
                                  );
                                },
                              )}
                            </HStack>
                          ) : (
                            <HStack
                              className="!mt-3 h-9 w-full rounded-lg bg-tint-dark-100 !text-center !text-xs"
                              align="center"
                              justify="center"
                            >
                              {t("workshopSummary.insufficientData")}
                            </HStack>
                          )}
                        </div>
                        <div className="mb-6 h-[193px] rounded-lg border border-neutral-50 p-4">
                          {getWorkshopPersonalityProfile?.discType ? (
                            <HStack className="h-38 flex w-full flex-row space-x-2">
                              <HStack className="w-1/5">
                                <DiSCProfile
                                  profile={discType?.toUpperCase()}
                                />
                              </HStack>
                              <HStack className="w-4/5 flex-col">
                                <div className="flex flex-row items-center">
                                  <HStack className="uppercase">
                                    {t("workshopSummary.workshopPersonality")}
                                  </HStack>
                                  <Button
                                    variant="link"
                                    onClick={() =>
                                      toggleWorkshopPersonalityModal(true)
                                    }
                                    RightIcon={() => (
                                      <Info
                                        size={14}
                                        className="text-neutral-70"
                                      />
                                    )}
                                    className="ml-1"
                                  />
                                  {modalsVisibility.EmotionalExpression && (
                                    <EmotionalExpressionModal
                                      isOpen={
                                        modalsVisibility.EmotionalExpression
                                      }
                                      setIsOpen={(shouldShow) =>
                                        toggleEmotionalExpressionModal(
                                          shouldShow,
                                        )
                                      }
                                    />
                                  )}
                                  {modalsVisibility.WorkshopPersonality && (
                                    <PersonalityProfileInfoModal
                                      isOpen={
                                        modalsVisibility.WorkshopPersonality
                                      }
                                      setIsOpen={(shouldShow) =>
                                        toggleWorkshopPersonalityModal(
                                          shouldShow,
                                        )
                                      }
                                    />
                                  )}
                                </div>
                                <HStack align="center">
                                  <div
                                    className={`${
                                      colorVariants[
                                        getWorkshopPersonalityProfile.discSegment
                                          .charAt(0)
                                          .toUpperCase()
                                      ]
                                    } h-5 w-4 rounded-tl-lg text-center text-white`}
                                  >
                                    {getWorkshopPersonalityProfile?.discSegment
                                      .charAt(0)
                                      .toUpperCase()}
                                  </div>
                                  <HStack
                                    className={`${
                                      colorVariants[
                                        getWorkshopPersonalityProfile.discSegment
                                          .charAt(0)
                                          .toUpperCase() + "Text"
                                      ]
                                    } mt-2 !ml-1 text-xs`}
                                  >
                                    DISC type{" "}
                                    {FilteredDiscType(
                                      getWorkshopPersonalityProfile?.discSegment.toUpperCase(),
                                    )}
                                  </HStack>
                                </HStack>

                                <HStack className="mt-2 text-base font-normal">
                                  During this workshop the team was mostly
                                  <strong className="ml-1 mr-1">
                                    {" "}
                                    {first}
                                  </strong>{" "}
                                  and
                                  <strong className="!ml-1 !mr-1">
                                    {" "}
                                    {second}
                                  </strong>{" "}
                                  focused
                                </HStack>
                                <HStack className="mt-2 text-xs text-neutral-700">
                                  {t(
                                    "workshopSummary.workshopPersonalityDescription",
                                  )}
                                </HStack>
                              </HStack>
                            </HStack>
                          ) : (
                            <HStack className="h-38 flex w-full flex-row space-x-2">
                              <HStack className="w-1/5">
                                <img src={"/EmptyState.png"}></img>
                              </HStack>
                              <HStack className="w-4/5 flex-col">
                                <div className="flex flex-row items-center">
                                  <HStack className="uppercase">
                                    {t("workshopSummary.workshopPersonality")}
                                  </HStack>
                                  <Button
                                    variant="link"
                                    onClick={() =>
                                      toggleWorkshopPersonalityModal(true)
                                    }
                                    RightIcon={() => (
                                      <Info
                                        size={14}
                                        className="text-neutral-70"
                                      />
                                    )}
                                    className="ml-1"
                                  />
                                  {modalsVisibility.EmotionalExpression && (
                                    <EmotionalExpressionModal
                                      isOpen={
                                        modalsVisibility.EmotionalExpression
                                      }
                                      setIsOpen={(shouldShow) =>
                                        toggleEmotionalExpressionModal(
                                          shouldShow,
                                        )
                                      }
                                    />
                                  )}
                                  {modalsVisibility.WorkshopPersonality && (
                                    <PersonalityProfileInfoModal
                                      isOpen={
                                        modalsVisibility.WorkshopPersonality
                                      }
                                      setIsOpen={(shouldShow) =>
                                        toggleWorkshopPersonalityModal(
                                          shouldShow,
                                        )
                                      }
                                    />
                                  )}
                                </div>

                                <HStack className="mt-2 text-xs text-neutral-700">
                                  {t(
                                    "workshopSummary.workshopPersonalityDescription",
                                  )}
                                </HStack>
                                <HStack
                                  className="!mt-3 h-9 w-full rounded-lg bg-tint-dark-100 !text-center !text-xs"
                                  align="center"
                                  justify="center"
                                >
                                  {t("workshopSummary.insufficientData")}
                                </HStack>
                              </HStack>
                            </HStack>
                          )}
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <WorkshopSummaryDataCardTemplate
                            title="Team Insights"
                            description={t(
                              "workshopSummary.workshopTeamDescription",
                            )}
                            btnText="See Team Insights"
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            image={PersonalIcon}
                          />

                          <WorkshopSummaryDataCardTemplate
                            title="Personal insights"
                            description={t(
                              "workshopSummary.workshopInsightsDescription",
                            )}
                            btnText="See Personal Insights"
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            image={TeamIcon}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="flex h-30 w-full items-center justify-center">
                        <Caption2 className="rounded-lg bg-neutral-50 bg-opacity-15 px-3 py-2 text-neutral-800">
                          {t("workshopSummary.noDataInWorkshop")}
                        </Caption2>
                      </div>
                    )}
                  </SummarySection>

                  {/* Resources */}

                  <WorkshopResources
                    elementId={RESOURCES_SECTION}
                    ref={resourcesRef}
                    workshopId={id}
                    vttLinks={getWorkshopVtt}
                    links={workshop?.links}
                    documents={workshop?.documents}
                    isEditable={role && isFacilitator}
                    hasOptionToAdd={
                      isParticipant || isSummaryShared ? false : true
                    }
                  />
                </>
              )}
            </Main>
          </SummaryTab>
          <AgendaTab
            agenda={workshop?.agenda as Agenda[] | undefined}
            userRole={role}
          />
        </TabNav>
      )}
    </SummaryLayout>
  );
};
