import { Button, DatePicker, Input, Link, TimePicker } from "components/base";
import { HStack, VStack } from "components/layout/Stack";
import {
  ConfirmDialog,
  FormInputErrors,
  InteractionForm,
  ToggleControl,
} from "components/partial";
import { Base2, Caption1 } from "components/Typography";
import { useCreatedResourceCardContext } from "contexts/CreatedResourceCardContext";
import { useHighlightContext } from "contexts/HighlightContext";
import { addHours, format, formatISO, isAfter, startOfToday } from "date-fns";
import { useFetchMetadata } from "hooks";
import { t } from "i18n-js";
import { noop } from "lodash";
import useCreateAnnouncement from "mutations/use-create-announcement";
import useUpdateProjectEndDate from "mutations/use-update-project-end-date";
import { CircleNotch } from "phosphor-react";
import { useProject } from "queries";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Z_INDEX_LEVELS } from "utils";
import { validateUrl, whetherLinkExists } from "utils/helpers/validations";

import { SlackControl } from ".";

const now = addHours(startOfToday(), 8);

enum INPUT_FIELDS {
  title = "title",
  description = "description",
  links = "links",
  date = "date",
  time = "time",
}
type CreateAnnouncementFormInput = {
  [INPUT_FIELDS.title]: string;
  [INPUT_FIELDS.description]: string;
  [INPUT_FIELDS.links]: string;
  [INPUT_FIELDS.date]: string;
  [INPUT_FIELDS.time]: Date;
};
export interface EmbeddedLinks {
  preview: string;
  url: string;
  extension?: string;
  title: string;
}
const DEFAULT_VALUES: CreateAnnouncementFormInput = {
  [INPUT_FIELDS.title]: "",
  [INPUT_FIELDS.description]: "",
  [INPUT_FIELDS.links]: "",
  [INPUT_FIELDS.date]: new Date().toISOString(),
  [INPUT_FIELDS.time]: new Date(),
};
const MAX_UPDATE_CHARS = 1000;
const MAX_TITLE_CHARS = 50;

type CreateAnnouncementFormProps = {
  onClose?: () => void;
  userHasAdminAccess?: boolean;
  testProps?: EmbeddedLinks[];
};
export type ErrorType = {
  type: string;
  message: string;
};

function CreateAnnouncementForm({
  onClose = noop,
  testProps = [],
  userHasAdminAccess = false,
}: CreateAnnouncementFormProps) {
  const [requestError, setRequestError] = useState("");
  const { projectId = "" } = useParams();
  const [shouldPostToSlack, setShouldPostToSlack] = useState(true);
  const [selectedDate, setSelectedDate] = useState(
    formatISO(new Date(), { representation: "date" }),
  );
  const [selectedTime, setSelectedTime] = useState("08:00");
  const { isHighlighted } = useHighlightContext();
  const [enteredLink, setEnteredLink] = useState("");
  const [isSchedule, setIsSchedule] = useState(false);
  const [embeddedLinks, setEmbeddedLinks] = useState<EmbeddedLinks[]>(
    testProps.length > 0 ? testProps : [],
  );
  const [embeddedOtherMeetingLinks, setembeddedOtherMeetingLinks] = useState<
    EmbeddedLinks[]
  >(testProps.length > 0 ? testProps : []);
  const [selectedInvalidDate, setSelectedInvalidDate] = useState(false);
  const [showInvalidDateModal, setShowInvalidDateModal] = useState(false);
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
    resetField,
    setError,
    setValue,
    getValues,
  } = useForm<CreateAnnouncementFormInput>({
    defaultValues: DEFAULT_VALUES,
    mode: "onSubmit",
  });

  const { mutateAsync } = useCreateAnnouncement();
  const { data, isLoading, error } = useFetchMetadata(enteredLink);
  const { project } = useProject({ id: projectId });
  const { mutate: updateProjectEndDate } = useUpdateProjectEndDate();
  const navigate = useNavigate();
  const projectEndDate = project?.endDate;

  const addFormError = (
    fieldName: INPUT_FIELDS,
    error: ErrorType,
    focusField = false,
  ) => {
    setError(
      fieldName,
      {
        type: error.type,
        message: error.message,
      },
      { shouldFocus: focusField },
    );
  };

  useEffect(() => {
    if (data?.url && whetherLinkExists(embeddedLinks, data?.url) === -1) {
      setEmbeddedLinks((prev) => [
        ...prev,
        {
          url: data.url,
          title: data?.title || data?.domain,
          preview: data?.images[0] || "",
        },
      ]);
      resetField("links");
      setEnteredLink("");
    } else {
      data?.url &&
        addFormError(
          INPUT_FIELDS.links,
          {
            type: "custom",
            message: t("errors.linkAlreadyExists"),
          },
          true,
        );
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      switch (error?.message) {
        case "Request failed with status code 404":
          addFormError(INPUT_FIELDS.links, {
            type: "pattern",
            message: t("errors.invalidLink"),
          });
          setEnteredLink("");
          break;
        default:
          addFormError(INPUT_FIELDS.links, {
            type: "custom",
            message: t("errors.somethingWentWrong"),
          });
          setEnteredLink("");
      }
    }
  }, [error]);

  useEffect(() => {
    if (
      selectedDate &&
      projectEndDate &&
      isAfter(new Date(selectedDate), new Date(projectEndDate))
    ) {
      setShowInvalidDateModal(true);
      setRequestError(t("errors.selectedDateIsAfterProjectEndDate"));
      setSelectedInvalidDate(true);
    } else {
      setShowInvalidDateModal(false);
      setRequestError("");
      setSelectedInvalidDate(false);
    }
  }, [projectEndDate, selectedDate]);

  useEffect(() => {
    setRequestError("");
    setSelectedInvalidDate(false);
  }, [isSchedule]);

  const { setCreatedResourceId } = useCreatedResourceCardContext();

  const handleUpdateProjectEndDate = () => {
    if (selectedDate) {
      updateProjectEndDate({
        projectId,
        eventDate: format(new Date(selectedDate), "yyyy-LL-dd"),
      });
      setShowInvalidDateModal(false);
      setSelectedInvalidDate(false);
      setRequestError("");
    }
  };

  const onSubmit: SubmitHandler<CreateAnnouncementFormInput> = async ({
    title,
    description,
    time,
    date,
  }: CreateAnnouncementFormInput) => {
    const selectedDateandTime = new Date(`${selectedDate} ${selectedTime}`);

    try {
      const result = isSchedule
        ? await mutateAsync({
            announcement: {
              title,
              description,
              shouldPostToSlack,
              isSchedule: isSchedule,
              scheduleTime: new Date(
                selectedDateandTime?.toUTCString() || "",
              ).toISOString(),
              links: embeddedLinks,
              projectId,
              isHighlighted,
            },
          })
        : await mutateAsync({
            announcement: {
              title,
              description,
              shouldPostToSlack,
              isSchedule: isSchedule,
              links: embeddedLinks,
              projectId,
              isHighlighted,
            },
          });

      if (result.createAnnouncement) {
        reset(DEFAULT_VALUES);
        setRequestError("");
        onClose();
        const id: string = result.createAnnouncement.id;
        const scheduledAt =
          selectedDateandTime?.toUTCString() || new Date().toUTCString();
        const projectId: string = result.createAnnouncement.project.id;
        setCreatedResourceId(`announcement-${id}`);
        navigate(
          `/projects/${projectId}?level=Timeline&card=announcement-${id}&scheduledAt=${scheduledAt}`,
        );
      }
    } catch {
      setRequestError(t("errors.somethingWentWrong"));
    }
  };

  const handleEmbedLink = (event: React.FormEvent) => {
    event.preventDefault();
    const url = String(getValues("links"));

    if (validateUrl(url)) {
      setEnteredLink(url);
    } else {
      addFormError(
        INPUT_FIELDS.links,
        {
          type: "pattern",
          message: t("errors.invalidLink"),
        },
        true,
      );
    }
  };

  const removeEmbeddedLinks = (event: React.FormEvent, url: string) => {
    event.preventDefault();
    const newEmbeddedLinks = [...embeddedLinks];
    const linkIndex = embeddedLinks.findIndex((links) => links.url === url);
    if (linkIndex > -1) {
      newEmbeddedLinks.splice(linkIndex, 1);
      setEmbeddedLinks(newEmbeddedLinks);
      setEnteredLink("");
    }
  };

  const renderEmbeddedLinks = (list: EmbeddedLinks[]) => {
    return (
      <>
        <Base2>Links</Base2>
        <div className="align-center flex flex-wrap gap-4">
          {list.map((link) => (
            <Link
              metadata={link}
              key={link.url}
              isRemovable={true}
              onRemove={(event, url = link.url) =>
                removeEmbeddedLinks(event, url)
              }
            />
          ))}
        </div>
      </>
    );
  };

  return (
    <InteractionForm
      onClose={onClose}
      onSubmit={
        selectedInvalidDate ? noop : (handleSubmit(onSubmit) as () => void)
      }
      submitTitle={!isSchedule ? t("component.createAnnouncement.postNow") : ""}
    >
      <>
        <Input
          id={INPUT_FIELDS.title}
          label={t("component.createAnnouncement.titleLabel")}
          placeholder={t("component.createAnnouncement.titlePlaceholder")}
          maxLength={MAX_TITLE_CHARS}
          value={watch(INPUT_FIELDS.title)}
          {...register(INPUT_FIELDS.title, {
            required: t("errors.titlePresence"),
          })}
          error={errors?.[INPUT_FIELDS.title]?.message}
          resizable
          showCounter
          longCountDescription
        />
        <Input
          id={INPUT_FIELDS.description}
          label={t("component.createAnnouncement.descriptionLabel")}
          placeholder={t("component.createAnnouncement.descriptionPlaceholder")}
          maxLength={MAX_UPDATE_CHARS}
          value={watch(INPUT_FIELDS.description)}
          {...register(INPUT_FIELDS.description, {
            required: t("errors.presence"),
          })}
          error={errors?.[INPUT_FIELDS.description]?.message}
          multiline
          expandable
          resizable
          showCounter
          longCountDescription
        />

        <SlackControl
          onToggle={setShouldPostToSlack}
          interactionType="announcements"
        />
      </>
      <div className="flex justify-between align-middle">
        <ToggleControl
          className="mr-0 w-3/6 pr-6"
          title={t("component.createAnnouncement.scheduleHeading")}
          description={t("component.createAnnouncement.scheduleDescription")}
          onToggle={(state) => setIsSchedule(state)}
          startsOn={isSchedule}
        ></ToggleControl>

        {isSchedule && (
          <HStack align="center" space={4}>
            <VStack space={4}>
              <Base2 as="label" className="text-neutral-90">
                {t("component.createAnnouncement.date")}
              </Base2>

              <DatePicker.withRef
                className="max-w-sm"
                format="dd/MM/yyyy"
                {...register(INPUT_FIELDS.date)}
                onChange={(selectedDate) => {
                  if (selectedDate) {
                    setSelectedDate(
                      formatISO(selectedDate, { representation: "date" }),
                    );
                  }
                }}
                defaultValue={now}
                error={errors.date?.message}
              />
            </VStack>
            <VStack space={4}>
              <Base2 as="label" className="text-neutral-90">
                {t("component.createAnnouncement.time")}
              </Base2>
              <TimePicker.withRef
                {...register(INPUT_FIELDS.time)}
                error={errors.time?.message}
                onSelect={(selectedTime) => {
                  const formattedTime = `${
                    selectedTime.getHours() > 9 ? "" : "0"
                  }${selectedTime.getHours()}${
                    selectedTime.getMinutes() > 9 ? ":" : ":0"
                  }${selectedTime.getMinutes()}`;

                  if (formattedTime) {
                    setSelectedTime(formattedTime);
                  }
                }}
                defaultTime={now}
              />
            </VStack>
          </HStack>
        )}
      </div>

      <>
        <Input
          id={INPUT_FIELDS.links}
          label={t("shared.linksLabel")}
          placeholder={t("shared.linksPlaceholder")}
          value={watch(INPUT_FIELDS.links)}
          {...register(INPUT_FIELDS.links)}
          error={errors?.[INPUT_FIELDS.links]?.message}
        />

        <Button onClick={handleEmbedLink}>
          {isLoading ? (
            <CircleNotch size={20} color="#fcfcfc" className="animate-spin" />
          ) : (
            t("shared.embedLink")
          )}
        </Button>

        <Caption1 className="text-center leading-5 text-neutral-60">
          {t("shared.workWithLinks1")}
          <br />
          {t("shared.workWithLinks2")}
        </Caption1>
        {embeddedLinks.length > 0 && renderEmbeddedLinks(embeddedLinks)}
      </>
      <ConfirmDialog
        open={showInvalidDateModal}
        onCancel={() => setShowInvalidDateModal(false)}
        onConfirm={handleUpdateProjectEndDate}
        title={t("component.invalidDateModal.title")}
        subtitle={
          userHasAdminAccess
            ? t("component.invalidDateModal.subtitle")
            : t("component.invalidDateModal.subtitleAlt")
        }
        cancelButtonText={t("component.invalidDateModal.cancel")}
        confirmButtonText={
          userHasAdminAccess ? t("component.invalidDateModal.confirm") : ""
        }
        confirmButtonVariant="outline"
        zIndex={Z_INDEX_LEVELS.MODAL_CONTROL}
      />
      <FormInputErrors error={requestError} />
    </InteractionForm>
  );
}

export default CreateAnnouncementForm;
