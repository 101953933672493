import clsx from "clsx";
import { Button, Input } from "components/base";
import { t } from "i18n-js";
import noop from "lodash/noop";
import { ArrowLeft, ArrowRight } from "phosphor-react";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";

import {
  CreateProjectPhasesFormInput,
  DefaultFieldsType,
  StepFormProps,
} from "./types";
import { getDefaultFields, mergeWithDefaultFields } from "./utils";

const MAX_PHASE_GOAL_CHARS = 160;

const PHASE_GOAL_FIELD_OPTIONS = {
  maxLength: {
    value: MAX_PHASE_GOAL_CHARS,
    message: t("errors.maxLengthExceeded", {
      size: MAX_PHASE_GOAL_CHARS,
    }),
  },
};

type PhaseGoalsFormProps = {
  previousStep?: (args?: DefaultFieldsType) => void;
  direction?: "create" | "edit";
} & StepFormProps;

function PhaseGoalsForm({
  className,
  fields = {},
  previousStep = noop,
  onSubmitSuccess = noop,
  direction,
}: PhaseGoalsFormProps) {
  const {
    control,
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
    getValues,
  } = useForm<CreateProjectPhasesFormInput>({
    defaultValues: mergeWithDefaultFields<CreateProjectPhasesFormInput>(
      getDefaultFields<DefaultFieldsType>(),
      fields as CreateProjectPhasesFormInput,
    ),
    mode: "onTouched",
  });

  const { fields: phaseGoalFields, update } = useFieldArray({
    control,
    name: "phases",
  });

  return (
    <form
      className={clsx(
        "flex h-full w-full flex-col justify-between px-14 pb-8 pt-20 text-left",
        className,
      )}
      onSubmit={handleSubmit(onSubmitSuccess) as () => void}
    >
      <div className="my-16 p-1 pr-4">
        {phaseGoalFields?.map((field, index) => {
          return (
            <div className="mb-8 flex items-end" key={field.id}>
              <Input
                multiline
                maxLength={MAX_PHASE_GOAL_CHARS}
                error={errors?.phases?.[index]?.goal?.message || ""}
                contentClassName="grow pr-4"
                initialCount={field?.goal?.length}
                showCounter
                label={`${t(
                  "component.createProjectForm.phaseTitleInputLabel",
                  {
                    number: index + 1,
                  },
                )}: ${getValues(`phases.${index}.name`) || ""}`}
                placeholder={t(
                  "component.createProjectForm.phaseGoalsTitleInputPlaceholder",
                )}
                {...register(`phases.${index}.goal`, {
                  ...PHASE_GOAL_FIELD_OPTIONS,
                })}
                getValues={getValues}
              />
            </div>
          );
        })}
      </div>

      <div className="fixed bottom-0 w-1/2 -translate-x-14 bg-white py-4 shadow-phasesGoals">
        <div className="flex w-full items-center justify-between pr-24 pl-14">
          <Button
            type="button"
            onClick={() => {
              previousStep(getValues());
            }}
            variant="outline"
            LeftIcon={ArrowLeft}
          >
            {t("shared.back")}
          </Button>

          <Button
            type="submit"
            variant="secondary"
            disabled={isSubmitting}
            RightIcon={ArrowRight}
          >
            {t("shared.next")}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default PhaseGoalsForm;
