import React from "react";

function HighlightsLargeIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5514 21.8416L20.8558 25.8358C21.6617 26.3464 22.6622 25.587 22.4231 24.6463L20.6016 17.481C20.5503 17.2815 20.5564 17.0715 20.6191 16.8752C20.6819 16.6789 20.7987 16.5044 20.9563 16.3715L26.6097 11.6661C27.3525 11.0478 26.9691 9.81502 26.0148 9.75308L18.6318 9.27392C18.4329 9.25972 18.2422 9.18931 18.0818 9.0709C17.9214 8.95249 17.7979 8.79095 17.7258 8.6051L14.9722 1.67097C14.8974 1.4737 14.7643 1.30387 14.5906 1.18403C14.417 1.06418 14.211 1 14 1C13.789 1 13.583 1.06418 13.4094 1.18403C13.2357 1.30387 13.1026 1.4737 13.0278 1.67097L10.2742 8.6051C10.2021 8.79095 10.0786 8.95249 9.91823 9.0709C9.75784 9.18931 9.5671 9.25972 9.36824 9.27392L1.98525 9.75308C1.03087 9.81502 0.647463 11.0478 1.3903 11.6661L7.04371 16.3715C7.20126 16.5044 7.31813 16.6789 7.38088 16.8752C7.44362 17.0715 7.4497 17.2815 7.39841 17.481L5.70918 24.126C5.42222 25.2549 6.62287 26.1661 7.58991 25.5534L13.4486 21.8416C13.6134 21.7367 13.8047 21.681 14 21.681C14.1953 21.681 14.3866 21.7367 14.5514 21.8416Z"
        fill="#FBE07A"
        stroke="#ECC73A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default HighlightsLargeIcon;
