import { Button } from "components/base";
import { HStack, VStack } from "components/layout/Stack";
import { Heading1Strong, Paragraph3 } from "components/Typography";
import { useGetEnvsContext } from "contexts";
import { useUserUpdatePasswordMutation } from "graphql/generated";
import { t } from "i18n-js";
import { noop } from "lodash";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { PASSWORD_REGEX } from "utils/helpers/validations";

import { SettingsField } from "../../SettingsField";

type PasswordValidation = {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
  samePassword: boolean;
};

const DEFAULT_PASSWORD_VALIDATION = {
  currentPassword: "",
  password: "",
  passwordConfirmation: "",
  samePassword: false,
};

export function UserSettingsProfileAccount() {
  const queryClient = useQueryClient();

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [passwordValidation, setPasswordValidation] =
    useState<PasswordValidation>(DEFAULT_PASSWORD_VALIDATION);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);

  const { currentUser } = useGetEnvsContext();
  const { mutate: updateUserPassword } = useUserUpdatePasswordMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries(["CurrentUser"]);
      setPasswordValidation(DEFAULT_PASSWORD_VALIDATION);
      setIsEditable(false);
    },
    onError: (error: Error) => {
      setIsConfirmEnabled(true);
      setIsEditable(true);
      if (
        error?.message ===
        "New password must not be the same as the current password."
      ) {
        setPasswordError(error?.message);
      } else if (error?.message === "invalid") {
        setPasswordError(
          t(
            `component.userSettings.tabs.profile.sections.account.errors.incorrectCurrentPassword`,
          ),
        );
      }
    },
  });

  const saveChangesHandler = () => {
    const {
      password,
      samePassword,
      passwordConfirmation,
      currentPassword,
    }: PasswordValidation = passwordValidation;

    if (!currentPassword && (password || passwordConfirmation)) {
      setPasswordError(
        t(
          `component.userSettings.tabs.profile.sections.account.errors.enterCurrentPassword`,
        ),
      );
    } else if (currentPassword && !password) {
      setPasswordError("Please enter new Password");
    } else if (currentPassword && password && !passwordConfirmation) {
      setPasswordError("Please confirm new Password");
    } else if (!isPasswordValid) {
      return;
    } else if (
      currentPassword &&
      password &&
      passwordConfirmation &&
      samePassword
    ) {
      updateUserPassword({
        input: { currentPassword, password, passwordConfirmation },
      });
    }
  };

  const handleChange = (fieldName: string, value: string) => {
    if (
      fieldName === "currentPassword" ||
      fieldName === "password" ||
      fieldName === "passwordConfirmation"
    ) {
      setPasswordError("");
    }
    const isSamePassword =
      (fieldName === "password" &&
        value === passwordValidation.passwordConfirmation) ||
      (fieldName == "passwordConfirmation" &&
        value === passwordValidation.password) ||
      (fieldName !== "password" &&
        fieldName !== "passwordConfirmation" &&
        passwordValidation.passwordConfirmation ===
          passwordValidation.password);
    if (fieldName === "password" && !isPasswordValid) {
      setIsPasswordValid(PASSWORD_REGEX.test(value));
    }
    setPasswordValidation({
      ...passwordValidation,
      samePassword: isSamePassword,
      [fieldName]: value,
    });
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (event?.target?.name === "password") {
      setIsPasswordValid(PASSWORD_REGEX.test(event?.target?.value));
    }
  };

  useEffect(() => {
    setIsConfirmEnabled(isEditable);
  }, [isEditable]);

  const ableToSave =
    !isConfirmEnabled || (passwordValidation.samePassword && isPasswordValid);

  return (
    <VStack className="gap-8">
      <HStack align="center" className="gap-8">
        <Heading1Strong>
          {t(`component.userSettings.tabs.profile.sections.account.title`)}
        </Heading1Strong>
        {isEditable === false ? (
          <Button variant="outline" onClick={() => setIsEditable(true)}>
            {t(
              `component.userSettings.tabs.profile.sections.account.editbutton`,
            )}
          </Button>
        ) : (
          <Button
            type="submit"
            variant="secondary"
            onClick={saveChangesHandler}
          >
            {t(
              `component.userSettings.tabs.profile.sections.personalInfo.savechangesbutton`,
            )}
          </Button>
        )}
      </HStack>

      <SettingsField
        label={t(
          `component.userSettings.tabs.profile.sections.personalInfo.fields.email`,
        )}
        editFlag={isEditable}
        name="email"
        type="email"
        defaultValue={currentUser?.email || ""}
        onSubmit={noop}
      />

      <SettingsField
        label={`${isEditable ? "Current Password" : "Password"}`}
        name="currentPassword"
        editFlag={isEditable}
        type="password"
        defaultValue={passwordValidation.currentPassword}
        placeholder="••••••••"
        ableToDoAction={ableToSave}
        onSubmit={noop}
        onAction={(isFieldEnabled) => {
          setIsConfirmEnabled(isFieldEnabled);
        }}
        onChange={(value) => {
          handleChange("currentPassword", value);
        }}
      />

      {isConfirmEnabled && (
        <>
          <SettingsField
            label="New Password"
            name="password"
            type="password"
            defaultValue={passwordValidation.password}
            placeholder="••••••••"
            hasAction={false}
            onSubmit={noop}
            onChange={(value) => handleChange("password", value)}
            onBlur={handleBlur}
          />
          <SettingsField
            label="Confirm New Password"
            name="passwordConfirmation"
            type="password"
            defaultValue={passwordValidation.passwordConfirmation}
            placeholder="••••••••"
            hasAction={false}
            onSubmit={noop}
            onChange={(value) => handleChange("passwordConfirmation", value)}
          />
        </>
      )}

      {passwordError && (
        <Paragraph3 className="text-red-400">{passwordError}</Paragraph3>
      )}

      {!isPasswordValid && passwordValidation.password !== "" && (
        <Paragraph3 className="text-red-400">
          {t(
            `component.userSettings.tabs.profile.sections.account.errors.passwordInvalid`,
          )}
        </Paragraph3>
      )}
      {!passwordValidation.samePassword &&
        passwordValidation.password !== "" &&
        passwordValidation.passwordConfirmation !== "" && (
          <Paragraph3 className="text-red-400">
            {t(
              `component.userSettings.tabs.profile.sections.account.errors.passwordNotMatching`,
            )}
          </Paragraph3>
        )}
    </VStack>
  );
}
