import {
  LanguageCohesionEntriesQuery,
  LanguageCohesionEntriesQueryVariables,
  useLanguageCohesionEntriesQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query LanguageCohesionEntries(
    $languageCohesionInput: LanguageCohesionInput!
  ) {
    getLanguageCohesionEntries(languageCohesionInput: $languageCohesionInput) {
      cohesionLevel
      entries {
        averageRating
        numberOfUserWhoAttendedWorkshop
        workshop {
          title
          createdAt
          startTime
          id
        }
      }
    }
  }
`;

function useLanguageCohesionEntries(
  variables: LanguageCohesionEntriesQueryVariables,
): {
  getLanguageCohesionEntries: LanguageCohesionEntriesQuery["getLanguageCohesionEntries"];
} & UseQueryResult<LanguageCohesionEntriesQuery, unknown> {
  const response = useLanguageCohesionEntriesQuery(variables);

  return {
    getLanguageCohesionEntries: response?.data?.getLanguageCohesionEntries,
    ...response,
  };
}

export { useLanguageCohesionEntries };
