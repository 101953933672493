import clsx from "clsx";
import { t } from "i18n-js";
import { Star } from "phosphor-react";
import React, { useEffect, useState } from "react";

import { Base2 } from "../../Typography";
import {
  ButtonClasses,
  HighlightButtonProps,
  Variants,
} from "./HighlightButton.types";

const variantClasses: Record<
  Variants,
  {
    active: ButtonClasses;
    inactive: ButtonClasses;
  }
> = {
  light: {
    active: {
      text: "text-neutral-90",
      background: "bg-tint-light-20 hover:bg-tint-dark-30",
      border: "border-tint-light-50 hover:border-tint-dark-10",
    },
    inactive: {
      text: "text-neutral-90",
      background: "hover:bg-tint-dark-10",
      border: "border-tint-dark-10",
    },
  },
  dark: {
    active: {
      text: "text-white",
      background: "bg-tint-light-20 hover:bg-tint-dark-5",
      border: "border-tint-light-50 hover:border-tint-dark-10",
    },
    inactive: {
      text: "text-neutral-5",
      background: "hover:bg-tint-dark-10",
      border: "border-neutral-30 hover:border-tint-dark-10",
    },
  },
};

function HighlightButton({
  defaultActive = false,
  onChange,
  variant = "light",
}: HighlightButtonProps) {
  const [isActive, setIsActive] = useState(defaultActive);
  const classes = isActive
    ? variantClasses[variant].active
    : variantClasses[variant].inactive;

  const handleChange = () => {
    setIsActive((prevState) => !prevState);
  };

  useEffect(() => {
    onChange(isActive);
  }, [isActive, onChange]);

  return (
    <button
      onClick={handleChange}
      className={clsx(
        "flex h-8 items-center justify-center rounded border px-2 py-1.5 transition-all",
        classes.background,
        classes.border,
      )}
    >
      <div className="relative">
        <Star
          size={24}
          className={clsx(
            "mr-2",
            isActive ? "text-secondary-yellow-10" : classes.text,
          )}
          weight={isActive ? "fill" : "regular"}
        />
        {isActive && (
          <Star
            size={24}
            className="absolute top-0 mr-2 text-secondary-orange-50"
          />
        )}
      </div>
      <Base2 className={classes.text}>
        {isActive ? t("shared.highlighted") : t("shared.highlight")}
      </Base2>
    </button>
  );
}

export default HighlightButton;
