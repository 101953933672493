import clsx from "clsx";
import { Avatar } from "components/base";
import { AvatarProps } from "components/base/Avatar/Avatar";
import { Caption1 } from "components/Typography";
import { User } from "graphql/generated";
import React from "react";

const MAX_DISPLAYED_USERS = 3;

type AvatarGroupProps = {
  users: User[];
  maxDisplayedUsers?: number;
  dataTestIdContainer?: string;
  dataTestIdItem?: string;
  extraUserCountBgColor?:
    | "bg-primary-turquoise-30"
    | "bg-white"
    | "bg-black"
    | "bg-neutral-70";
  avatarsSize?: AvatarProps["size"];
  showExtraCount?: boolean;
};

export default function UpdatedAvatarGroup({
  users,
  maxDisplayedUsers = MAX_DISPLAYED_USERS,
  dataTestIdContainer,
  dataTestIdItem = "avatar-user-collapsed",
  extraUserCountBgColor = "bg-white",
  avatarsSize,
  showExtraCount = true,
}: AvatarGroupProps) {
  const extraUsersCount = users.length - MAX_DISPLAYED_USERS;

  return (
    <div className="flex flex-row-reverse" data-testid={dataTestIdContainer}>
      {extraUsersCount > 0 && showExtraCount && (
        <div
          className={`z-0 -ml-2 flex h-8 w-8 items-center justify-center rounded-full ${extraUserCountBgColor}`}
        >
          <Caption1
            className={clsx("mb-[-1px]", {
              "text-neutral-90":
                extraUserCountBgColor === "bg-white" ||
                extraUserCountBgColor === "bg-primary-turquoise-30",
              "text-white":
                extraUserCountBgColor === "bg-neutral-70" ||
                extraUserCountBgColor === "bg-black",
            })}
          >
            +{extraUsersCount}
          </Caption1>
        </div>
      )}
      {users
        .slice(0, maxDisplayedUsers)
        .map(
          (user) =>
            user && (
              <Avatar
                key={user.id}
                size={avatarsSize || "small"}
                className=" -ml-2 border border-tint-dark-15 last:ml-0"
                textClassName="!text-xs"
                user={user}
                data-testid={`${dataTestIdItem}-${user.id}`}
              />
            ),
        )}
    </div>
  );
}
