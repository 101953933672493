import { GraphQLError } from "graphql";
import {
  AccessLevel,
  useCreateProjectInvitationsMutation,
  User,
} from "graphql/generated";
import { useDebounce } from "hooks";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const InviteUsersContext = React.createContext<
  | {
      isInInviteMode: boolean;
      setIsInInviteMode: React.Dispatch<React.SetStateAction<boolean>>;
      searchTerm: string;
      debouncedSearchTerm: string;
      setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
      selectUser: (user: User) => void;
      deselectUser: (user: User) => void;
      selectedUsers: User[];
      selectEmail: (email: string) => void;
      deselectEmail: (email: string) => void;
      selectedEmails: string[];
      inviteAccessLevel: AccessLevel;
      setInviteAccessLevel: (accessLevel: AccessLevel) => void;
      cancelInviteModeHandler: () => void;
      sendInvitations: () => Promise<void>;
      errorResponse: string;
    }
  | undefined
>(undefined);

export function InviteUsersProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { mutateAsync: createProjectInvitationsMutateAsync } =
    useCreateProjectInvitationsMutation();
  const { projectId = "" } = useParams();
  const [isInInviteMode, setIsInInviteMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [errorResponse, setErrorResponse] = useState<string>("");

  const [inviteAccessLevel, setInviteAccessLevel] = useState<AccessLevel>(
    AccessLevel.MemberAccess,
  );

  const sendInvitations = async () => {
    try {
      [...selectedUsers, ...selectedEmails].length > 0 &&
        (await createProjectInvitationsMutateAsync({
          projectId,
          input: {
            inviteeIds: selectedUsers.map(({ id }) => id),
            inviteeEmails: selectedEmails,
            accessLevel: inviteAccessLevel,
          },
        }));
      setIsInInviteMode(false);
      setSelectedUsers([]);
      setSelectedEmails([]);
    } catch (error) {
      const graphQLError = error as GraphQLError;
      setErrorResponse(graphQLError.message);
    }
  };

  const cancelInviteModeHandler = () => {
    setIsInInviteMode(false);
    setSearchTerm("");
    setSelectedEmails([]);
  };

  const selectUser = (user: User) => {
    setSelectedUsers((users) => [...users, user]);
  };
  const deselectUser = (user: User) => {
    setSelectedUsers((users) => users.filter(({ id }) => id !== user.id));
  };
  const selectEmail = (email: string) => {
    setSelectedEmails((emails) => [...emails, email]);
  };
  const deselectEmail = (deselectedEmail: string) => {
    setSelectedEmails((emails) =>
      emails.filter((email) => email !== deselectedEmail),
    );
  };

  useEffect(() => {
    setSearchTerm("");
    setErrorResponse("");
  }, [selectedUsers, selectedEmails]);

  const value = {
    isInInviteMode,
    setIsInInviteMode,
    searchTerm,
    debouncedSearchTerm,
    setSearchTerm,
    selectUser,
    deselectUser,
    selectedUsers,
    selectEmail,
    deselectEmail,
    selectedEmails,
    inviteAccessLevel,
    setInviteAccessLevel,
    sendInvitations,
    cancelInviteModeHandler,
    errorResponse,
  };

  return (
    <InviteUsersContext.Provider value={value}>
      {children}
    </InviteUsersContext.Provider>
  );
}

export function useInviteUsers() {
  const context = React.useContext(InviteUsersContext);
  if (context === undefined)
    throw new Error(
      "useInviteUsers must be used withing an InviteUsersProvider",
    );
  return context;
}
