import clsx from "clsx";
import { Base2, HStack } from "components";
import { useGetEnvsContext } from "contexts";
import { t } from "i18n-js";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

type ProjectLevel = "project" | "Timeline" | "team" | "phase";

function ProjectLevelTab({
  active,
  level,
}: {
  active?: boolean;
  level: ProjectLevel;
}) {
  const [, setSearchParams] = useSearchParams();

  return (
    <button
      className={clsx(
        "group box-border h-12 flex-1 border-b-2 border-transparent outline-none transition-colors",
        Z_INDEX_LEVELS.BASE_CONTROL,
        !active && "focus:border-primary-turquoise-10",
      )}
      // disabled={level == "team" ? true : false}
      onClick={() => setSearchParams({ level })}
      aria-label={`${level} view`}
      aria-current={active ? "page" : "false"}
    >
      <div className="flex h-full w-full items-center justify-center">
        <Base2
          className={clsx(
            "text-neutral-90 transition-colors",
            !active && "group-hover:text-neutral-70",
          )}
          as="span"
        >
          {t(`shared.${level}`)}
        </Base2>
      </div>
    </button>
  );
}

function ProjectLevelControl() {
  const { envs } = useGetEnvsContext();
  const [searchParams] = useSearchParams();
  const level = searchParams.get("level") || "project";
  const [DISPLAY_TEAM_VIEW, SET_DISPLAY_TEAM_VIEW] = useState(
    process.env.SHOW_TEAM_VIEW === "true",
  );

  useEffect(() => {
    if (envs) {
      const { SHOW_TEAM_VIEW } = envs;
      SET_DISPLAY_TEAM_VIEW(SHOW_TEAM_VIEW === "true");
    }
  }, [envs]);

  return DISPLAY_TEAM_VIEW ? (
    <HStack className="relative flex min-w-[430px]">
      <div
        className={clsx(
          "pointer-events-none absolute inset-y-0 w-1/3 transform rounded-full bg-white shadow-lg transition-transform ease-in-out",
          level === "project" && "translate-x-[0]",
          // level === "phase" && "translate-x-full",
          level === "Timeline" && "translate-x-[100%]",
          level === "team" && "translate-x-[200%]",
        )}
      />
      <ProjectLevelTab level="project" active={level === "project"} />
      {/* <ProjectLevelTab level="phase" active={level === "phase"} /> */}

      <ProjectLevelTab level="Timeline" active={level === "Timeline"} />
      <ProjectLevelTab level="team" active={level === "team"} />
    </HStack>
  ) : (
    <HStack className="relative flex min-w-[230px]">
      <div
        className={clsx(
          "pointer-events-none absolute inset-y-0 w-1/2 transform rounded-full bg-white shadow-lg transition-transform ease-in-out",
          level === "project" && "translate-x-0",
          // level === "phase" && "translate-x-full",
          level === "Timeline" && "translate-x-full",
          level === "team" && "translate-x-[200%]",
        )}
      />
      <ProjectLevelTab level="project" active={level === "project"} />
      {/* <ProjectLevelTab level="phase" active={level === "phase"} /> */}

      <ProjectLevelTab level="Timeline" active={level === "Timeline"} />
      {/* <ProjectLevelTab level="team" active={level === "team"} /> */}
    </HStack>
  );
}

export default ProjectLevelControl;
