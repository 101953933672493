/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  GetPersonalityProfileQuery,
  GetPersonalityProfileQueryVariables,
  useGetPersonalityProfileQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query GetPersonalityProfile(
    $personalityProfileInput: PersonalityProfileInput!
  ) {
    getPersonalityProfile(personalityProfileInput: $personalityProfileInput) {
      dominance {
        value
        percentage
        anonymousUserCount
        users {
          id
          fullName
          firstName
          lastName
          avatarUrl
        }
      }
      conscientiousness {
        value
        percentage
        anonymousUserCount
        users {
          id
          fullName
          firstName
          lastName
          avatarUrl
        }
      }
      influence {
        value
        percentage
        anonymousUserCount
        users {
          id
          fullName
          firstName
          lastName
          avatarUrl
        }
      }
      steadiness {
        value
        percentage
        anonymousUserCount
        users {
          id
          fullName
          firstName
          lastName
          avatarUrl
        }
      }
    }
  }
`;

function useGetTeamPersonalityProfile(
  variables?: GetPersonalityProfileQueryVariables,
): {
  getUserPersonalityProfile: GetPersonalityProfileQuery["getPersonalityProfile"];
} & UseQueryResult<GetPersonalityProfileQuery, unknown> {
  const response = useGetPersonalityProfileQuery(
    variables as GetPersonalityProfileQueryVariables,
    {
      suspense: true,
      enabled: !!variables?.personalityProfileInput,
      // refetchInterval: 1000,
    },
  );
  return {
    getPersonalityProfile: response?.data?.getPersonalityProfile || [],
    ...response,
  };
}

export { useGetTeamPersonalityProfile };
