import clsx from "clsx";
import React from "react";

export enum ScrollFadeVariant {
  Top,
  Bottom,
}

/**
 * This component is meant to be used with the `useScrollFades` hook to
 * facilitate easy encorporation of scroll faders into scrollable divs.
 */
export const ScrollFade = ({
  variant,
  heightClass = "h-[20px]",
  fromBgColor = "from-white",
}: {
  variant: ScrollFadeVariant;
  /**
   * Use a tailwind `h-` class for overriding the height here if the
   * default (20px) doesn't work for your case.
   */
  heightClass?: string;
  fromBgColor?: string;
}) => (
  <div
    className={clsx(
      `${heightClass} absolute inset-x-0 z-[1] ${fromBgColor} to-transparent`,
      {
        "top-0 bg-gradient-to-b": variant === ScrollFadeVariant.Top,
        "bottom-0 bg-gradient-to-t": variant === ScrollFadeVariant.Bottom,
      },
    )}
  />
);
