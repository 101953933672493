import {
  Agenda,
  WorkshopActivityState,
  WorkshopByIdQuery,
} from "graphql/generated";
import {
  getActivityInAgendaByIndex,
  getCurrentWorkshopActivity,
  getIndexOfActivityInAgenda,
} from "utils";

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export enum PreviewWorkshopActions {
  SET_WORKSHOP,
  GO_NEXT,
  GO_PREV,
}

export interface PreviewWorkshopReducerAction {
  type: PreviewWorkshopActions;
  payload: Partial<WorkshopByIdQuery["workshopById"]>;
}

export const previewWorkshopReducer = (
  state: Partial<WorkshopByIdQuery["workshopById"]>,
  action: PreviewWorkshopReducerAction,
): Partial<WorkshopByIdQuery["workshopById"]> => {
  switch (action.type) {
    case PreviewWorkshopActions.SET_WORKSHOP:
      return action.payload;

    case PreviewWorkshopActions.GO_NEXT: {
      const current = getCurrentWorkshopActivity(state.agenda);
      const next = current
        ? getActivityInAgendaByIndex(
            state.agenda as Agenda[],
            getIndexOfActivityInAgenda(current, state.agenda as Agenda[]) + 1,
          )
        : getActivityInAgendaByIndex(state.agenda as Agenda[], 0);

      const replacementAgenda = state.agenda?.map(
        getReplaceAgendaActivities([
          [current, WorkshopActivityState.Completed],
          [next, WorkshopActivityState.InProgress],
        ]),
      );

      return {
        ...state,
        agenda: replacementAgenda,
      };
    }

    case PreviewWorkshopActions.GO_PREV: {
      const current = getCurrentWorkshopActivity(state.agenda);
      const prev = current
        ? getActivityInAgendaByIndex(
            state.agenda as Agenda[],
            getIndexOfActivityInAgenda(current, state.agenda as Agenda[]) - 1,
          )
        : getActivityInAgendaByIndex(state.agenda as Agenda[], 0);

      const replacementAgenda = state.agenda?.map(
        getReplaceAgendaActivities([
          [current, WorkshopActivityState.Ready],
          [prev, WorkshopActivityState.InProgress],
        ]),
      );

      return {
        ...state,
        agenda: replacementAgenda,
      };
    }

    default:
      return state;
  }
};

export const getReplaceAgendaActivities = (
  replacementActivities: Array<
    [
      (
        | DeepPartial<WorkshopByIdQuery["workshopById"]["agenda"][number]>
        | undefined
      ),
      WorkshopActivityState,
    ]
  >,
) => {
  // Returns a callback which is then used by the `map()` function on the array.
  return (
    agendaItem: WorkshopByIdQuery["workshopById"]["agenda"][number],
    // index: number,
  ) => {
    if (agendaItem.__typename === "Section") {
      return {
        ...agendaItem,
        activities: agendaItem.workshopActivities?.map((sectionActivity) => {
          const replacement = replacementActivities.find(
            (rep) => rep[0]?.id === sectionActivity.id,
          );

          return replacement
            ? { ...sectionActivity, state: replacement[1] }
            : sectionActivity;
        }),
      };
    } else {
      const replacement = replacementActivities.find(
        (rep) => rep[0]?.id === agendaItem.id,
      );

      return replacement
        ? { ...agendaItem, state: replacement[1] }
        : agendaItem;
    }
  };
};

/**
 * This `map` callback converts the true Workshop Agenda state to a version of
 * the state which is more appropriate to the
 */
export const cleanStateAgendaMap = (
  agendaItem: WorkshopByIdQuery["workshopById"]["agenda"][number],
  index: number,
) => {
  return agendaItem.__typename === "Section"
    ? {
        ...agendaItem,
        workshopActivities: agendaItem?.workshopActivities?.map(
          (activity, sectionIndex) => ({
            ...activity,
            state:
              index === 0 && sectionIndex === 0
                ? WorkshopActivityState.InProgress
                : WorkshopActivityState.Ready,
          }),
        ),
      }
    : {
        ...agendaItem,
        state:
          index === 0
            ? WorkshopActivityState.InProgress
            : WorkshopActivityState.Ready,
      };
};
