import React from "react";

function EditWorkshopIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.1667 5.66663H4.83333C4.3731 5.66663 4 6.03972 4 6.49996V19C4 19.4602 4.3731 19.8333 4.83333 19.8333H23.1667C23.6269 19.8333 24 19.4602 24 19V6.49996C24 6.03972 23.6269 5.66663 23.1667 5.66663Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3335 19.8333L20.6668 23.9999"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6668 19.8333L7.3335 23.9999"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 5.66663V3.16663"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EditWorkshopIcon;
