/* eslint-disable unicorn/no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable simple-import-sort/imports */
import * as React from "react";
import * as Sentry from "@sentry/react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { initializeI18n } from "utils/helpers/i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import { QueryErrorResetBoundaryWithSuspense } from "components/utils";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  Project,
  ProjectInvitationRole,
  ProjectUnauthorized,
  RequestPasswordReset,
  UpdatePassword,
  YourTeam,
  AboutYou,
  UploadPhoto,
  CreateProject,
  Organization,
  EmailConfirmation,
  Home,
  NotFound,
  WorkshopEdit,
  Workshop,
  WorkshopSummary,
  WorkshopSummaryEdit,
  WorkshopDraftsFeed,
  WorkshopPreview,
} from "components/pages";
import { ToastProvider } from "./provider";
import WorkshopEmailDocuments from "./pages/WorkshopEmailDocuments";
import PersonalityViewBody from "./PersonalityViewBody";
import WorkshopRejoin from "./pages/WorkshopRejoin/WorkshopRejoin";
import { GetEnvsProvider, useGetEnvsContext } from "contexts";
import AuthPage from "./pages/Login/AuthPage";
import WorkshopUnauthorized from "./pages/Project/WorkshopUnauthorized";

initializeI18n();

const queryClient = new QueryClient();

const SENTRY_DSN = window?.env?.vars?.SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/localhost:\/api/,
    /^https:\/\/coeuraj-develop.herokuapp.com\/api/,
    /^https:\/\/coeuraj-staging.herokuapp.com\/api/,
    /^https:\/\/coeuraj-production.herokuapp.com\/api/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function RequireAuth() {
  const location = useLocation();
  const { currentUser } = useGetEnvsContext();

  if (!currentUser) {
    const currentFullPath = `${location.pathname}${location.search}`;
    const locationState = {
      originUrl: currentFullPath,
      originPathname: location.pathname,
      originSearch: location.search,
    };

    const navigateUrl = `/login${
      currentFullPath ? "/?redirect=" + encodeURI(currentFullPath) : ""
    }`;
    return <Navigate to={navigateUrl} state={locationState} />;
  }
  return <Outlet />;
}

function eventListener(event: Event) {
  event.preventDefault();
  event.stopPropagation();
  // Suppressing errors by doing nothing
}

function App() {
  React.useEffect(() => {
    const validEnvironments = new Set(["staging", "production"]);
    const env = window.env?.vars.RAILS_ENV;

    if (!env || validEnvironments.has(env)) {
      window.addEventListener("error", eventListener);
    }

    return () => {
      window.removeEventListener("error", eventListener);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <QueryErrorResetBoundaryWithSuspense>
        <ToastProvider>
          <GetEnvsProvider>
            <BrowserRouter>
              <SentryRoutes>
                <Route path="/" element={undefined}>
                  <Route element={<RequireAuth />}>
                    <Route index element={<Home />} />

                    <Route
                      path="organizations/:organizationId"
                      element={undefined}
                    >
                      <Route index element={<Organization />} />

                      <Route path="project" element={undefined}>
                        <Route path="new" element={<CreateProject />} />
                      </Route>
                    </Route>

                    <Route path="projects" element={undefined}>
                      <Route path=":projectId">
                        <Route index element={<Project />} />
                        <Route
                          path="workshop_drafts"
                          element={<WorkshopDraftsFeed />}
                        />
                      </Route>
                      <Route
                        path="unauthorized"
                        element={<ProjectUnauthorized />}
                      />
                    </Route>

                    <Route path="project-invitations" element={undefined}>
                      <Route path="role" element={<ProjectInvitationRole />} />
                    </Route>

                    <Route path="onboarding" element={undefined}>
                      <Route index element={<YourTeam />} />
                      <Route path="about_you" element={<AboutYou />} />
                      <Route path="upload_photo" element={<UploadPhoto />} />
                    </Route>
                  </Route>

                  <Route path="login" element={<AuthPage />} />
                  <Route
                    path="request_password_reset"
                    element={<RequestPasswordReset />}
                  />
                  <Route
                    path="users/confirmation"
                    element={<EmailConfirmation />}
                  />
                  <Route
                    path="users/password/edit"
                    element={<UpdatePassword />}
                  />
                  <Route
                    path="workshop/unauthorized"
                    element={<WorkshopUnauthorized />}
                  />
                  <Route path="*" element={<NotFound />} />
                  <Route path="workshop/:id">
                    <Route index element={<Workshop />} />
                    <Route path="documents/:docId">
                      <Route index element={<WorkshopEmailDocuments />} />
                    </Route>
                    <Route path="edit" element={<WorkshopEdit />} />
                    <Route path="preview" element={<WorkshopPreview />} />
                    <Route path="rejoin" element={<WorkshopRejoin />} />
                    <Route path="summary">
                      <Route index element={<WorkshopSummary />} />
                      <Route path="edit" element={<WorkshopSummaryEdit />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="details" element={<PersonalityViewBody />} />
              </SentryRoutes>
            </BrowserRouter>
          </GetEnvsProvider>
        </ToastProvider>
      </QueryErrorResetBoundaryWithSuspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
