import UpdatedAvatar from "components/base/UpdatedAvatar";
import { useGetEnvsContext } from "contexts";
import { User } from "graphql/generated";
import { User as CustomIcon } from "phosphor-react";
import React, { useEffect, useState } from "react";

const MAX_DISPLAYED_USERS = 3;

type UpdatedAvatarGroupProps = {
  users: User[];
  maxDisplayedUsers?: number;
  dataTestIdContainer?: string;
  dataTestIdItem?: string;
};

export default function AvatarGroupPendingUser({
  users,
  maxDisplayedUsers = MAX_DISPLAYED_USERS,
  dataTestIdContainer,
  dataTestIdItem = "avatar-user-collapsed",
}: UpdatedAvatarGroupProps) {
  const { currentUser } = useGetEnvsContext();
  const [sortedUsers, setSortedUsers] = useState(users);

  function moveToFront(x: string) {
    for (let i = 0; i < users.length; i++) {
      if (users[i] == undefined) return;
      if (users[i].id === x) {
        const user = [...users.splice(i, 1), ...users];
        setSortedUsers(user);
        break;
      }
    }
  }

  useEffect(() => {
    setSortedUsers(users);
  }, [users]);

  useEffect(() => {
    moveToFront(currentUser?.id || "");
  }, [currentUser, users, sortedUsers]);
  return (
    <div className="flex" data-testid={dataTestIdContainer}>
      {sortedUsers
        .slice(0, maxDisplayedUsers)
        .reverse()
        .map((user) =>
          user?.id ? (
            <UpdatedAvatar
              key={user.id}
              size="medium"
              className="-ml-2 border border-white"
              textClassName="!text-xs"
              user={user}
              data-testid={`${dataTestIdItem}-${user.id}`}
            />
          ) : (
            <CustomIcon
              className=" -ml-2 h-10 w-10 rounded-full border border-white bg-[#F2F3F4]"
              size={32}
            />
          ),
        )}
      {sortedUsers.length > MAX_DISPLAYED_USERS && (
        <p className="-ml-2 h-10 w-10 rounded-full border border-white bg-[#F2F3F4] p-2">
          {" "}
          +{sortedUsers.length - MAX_DISPLAYED_USERS}
        </p>
      )}
    </div>
  );
}
