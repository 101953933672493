import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { Avatar, Button, Dropdown } from "components/base";
import { HStack, VStack } from "components/layout";
import {
  Base1,
  Base2,
  Caption1,
  Caption2,
  Display3,
  Display4,
  Heading1Strong,
  Overline,
  Paragraph1,
  Paragraph3,
} from "components/Typography";
import { useGetEnvsContext } from "contexts";
import { TalkTimeMetrics, User } from "graphql/generated";
import { t } from "i18n-js";
import { TeamViewLanguageCohesionIllustration } from "icons/illustrations";
import { truncate } from "lodash";
import { CaretDown, Check, Info, X } from "phosphor-react";
import {
  GetUserPersonalityProfile,
  usePersonalTalkTimeBargraph,
  usePersonalTalkTimeMetrics,
  useUserPersonalCollabMetrics,
} from "queries";
import React, { useEffect, useRef, useState } from "react";
import { Z_INDEX_LEVELS } from "utils";

import {
  LanguageCohesionInfoModal,
  PersonalityProfileInfoModal,
} from "./components";
import ParticipationDialog from "./components/ParticipationDialog";
import CollabMetric from "./components/UserSettingsInsightQuiz/CollabMetric";
import InsufficientWords from "./components/UserSettingsInsightQuiz/InsufficientWords";
import Quizgraph from "./components/UserSettingsInsightQuiz/Quizgraph";
import { formatTime } from "./util";

export interface UserSettingsProfileContentProps {
  onClose?: () => void;
}

const MIN_BARGRAPH_RANGE = -3;
const MAX_BARGRAPH_RANGE = 3;

export const getPositionPercentage = (value: number) => {
  const positionPercentage =
    ((value - MIN_BARGRAPH_RANGE) / (MAX_BARGRAPH_RANGE - MIN_BARGRAPH_RANGE)) *
    100;
  const clampedPositionPercentage = Math.min(
    Math.max(positionPercentage, 0),
    97,
  );

  return clampedPositionPercentage;
};

export const getPositionBgColor = (position: number) => {
  if (position <= 37) {
    return "bg-secondary-yellow-500";
  } else if (position <= 57) {
    return "bg-secondary-green-500";
  } else {
    return "bg-secondary-indigo-700";
  }
};

export const UserSettingsInsightsContent = ({
  onClose,
}: UserSettingsProfileContentProps) => {
  const highlightBarRef = useRef<HTMLDivElement | null>(null);
  const avatarRef = useRef<HTMLDivElement | null>(null);
  const [modalsVisibility, setModalsVisibility] = useState({
    personalityProfile: false,
    languageCohesion: false,
    participation: false,
  });
  const [participatationCounts, setParticipationCounts] = useState({
    sessionsAttended: 0,
    wordsSpoken: 0,
    averageSpeakingTime: 0,
  });
  const [participationFilter, setParticipationFilter] = useState("allProjects");
  const i18nRoot = "component.userSettings.tabs.insights";
  const collaborationMetrics =
    "component.userSettings.tabs.insights.sections.collaborationMetrics";
  const togglePersonaliltyProfileModal = (shouldShow: boolean) => {
    setModalsVisibility({
      ...modalsVisibility,
      personalityProfile: shouldShow,
    });
  };

  const { currentUser } = useGetEnvsContext();
  const id = currentUser?.id;

  const { personalTalkTimeBargraph } = usePersonalTalkTimeBargraph();
  const { personalCollabMetrics } = useUserPersonalCollabMetrics();
  const { personalTalkTimeMetrics } = usePersonalTalkTimeMetrics();
  const { getUserPersonalityProfile } = GetUserPersonalityProfile({
    userId: id as string,
  });

  const toggleLanguageCohesionModal = (shouldShow: boolean) => {
    // close others
    setModalsVisibility({
      ...modalsVisibility,
      languageCohesion: shouldShow,
    });
  };

  const toggleParticipationModal = () => {
    setModalsVisibility({
      ...modalsVisibility,
      participation: !modalsVisibility.participation,
    });
  };

  const getParticipationFilterName = () => {
    if (participationFilter === "allProjects") {
      return t(`${i18nRoot}.sections.participation.allProjects`);
    } else if (participationFilter === "activeProjects") {
      return t(`${i18nRoot}.sections.participation.activeProjects`);
    } else {
      return participationFilter;
    }
  };

  const handleUpdateParticipationFilters = (record: TalkTimeMetrics) => {
    setParticipationCounts({
      averageSpeakingTime: record.averageTalkTime || 0,
      sessionsAttended: record.totalWorkshops || 0,
      wordsSpoken: record.totalWords || 0,
    });
  };

  useEffect(() => {
    if (personalTalkTimeMetrics?.overallProjectData) {
      setParticipationCounts({
        averageSpeakingTime:
          personalTalkTimeMetrics?.overallProjectData?.averageTalkTime || 0,
        sessionsAttended:
          personalTalkTimeMetrics?.overallProjectData?.totalWorkshops || 0,
        wordsSpoken:
          personalTalkTimeMetrics?.overallProjectData?.totalWords || 0,
      });
    }
  }, [personalTalkTimeMetrics]);

  useEffect(() => {
    const avatarEl = avatarRef.current;
    const highlightBarEl = highlightBarRef.current;

    if (avatarEl && personalTalkTimeBargraph) {
      const { averageParticipationScore } = personalTalkTimeBargraph;

      const positionPercentage = getPositionPercentage(
        averageParticipationScore || 0,
      );

      avatarEl.style.left = `${positionPercentage}%`;
    }

    if (highlightBarEl && personalTalkTimeBargraph) {
      const maxAverageScore = Math.min(
        Math.max(
          personalTalkTimeBargraph.highestAverageParticipationScore || 0,
          MIN_BARGRAPH_RANGE,
        ),
        MAX_BARGRAPH_RANGE,
      );

      const minAverageScore = Math.min(
        Math.max(
          personalTalkTimeBargraph.lowestAverageParticipationScore || 0,
          MIN_BARGRAPH_RANGE,
        ),
        MAX_BARGRAPH_RANGE,
      );

      const leftPositionPercentage = getPositionPercentage(minAverageScore);
      if (maxAverageScore === minAverageScore) {
        highlightBarEl.style.left = `${leftPositionPercentage}%`;
      } else {
        const absoluteDifference = Math.abs(maxAverageScore - minAverageScore);
        const widthPercentage =
          (absoluteDifference / (MAX_BARGRAPH_RANGE - MIN_BARGRAPH_RANGE)) *
          100;
        highlightBarEl.style.left = `${leftPositionPercentage}%`;
        highlightBarEl.style.width = `${Math.min(widthPercentage, 100)}%`;
      }
    }
  }, [personalTalkTimeBargraph, avatarRef]);

  return (
    <div className="flex w-2/3 flex-col overflow-y-auto p-10 px-14">
      <HStack justify="between">
        <Display3 className="text-neutral-90">
          {t(`${i18nRoot}.title`)}
        </Display3>

        <Button
          data-testid="settings-menu-close-button"
          onClick={onClose}
          variant="tertiary"
          LeftIcon={X}
        />
      </HStack>

      <Paragraph1 className="mt-8 text-neutral-70">
        {t(`${i18nRoot}.description`)}
      </Paragraph1>

      {/* Personality Profile Content */}
      <div className="mt-[80px] flex flex-col">
        <div className="flex items-center gap-4">
          <Heading1Strong>
            {t(`${i18nRoot}.sections.personalityProfile.title`)}
          </Heading1Strong>

          {/** TODO: tooltip */}
          <Button
            variant="link"
            onClick={() => togglePersonaliltyProfileModal(true)}
            RightIcon={() => <Info size={24} className="text-neutral-70" />}
          />
        </div>

        <Paragraph1 className="mt-4 mb-6 text-neutral-70">
          {t(`${i18nRoot}.sections.personalityProfile.description`)}
        </Paragraph1>

        {getUserPersonalityProfile?.discType &&
          getUserPersonalityProfile?.discType !== "Unknown" && (
            <Quizgraph
              props={{
                imagename:
                  getUserPersonalityProfile?.filledDiskSegments
                    ?.segmentIdentifier,
                discType: getUserPersonalityProfile?.discType,
                visibleToTeam: getUserPersonalityProfile?.visibleToTeam,
              }}
            />
          )}

        {getUserPersonalityProfile?.discType === "Unknown" && (
          <>
            <InsufficientWords />
          </>
        )}
      </div>

      {/* Language Cohesion */}
      <div className="mt-[80px] flex flex-col">
        <div className="flex items-center gap-4">
          <Heading1Strong>
            {t(`${i18nRoot}.sections.languageCohesion.title`)}
          </Heading1Strong>

          {/** TODO: tooltip */}
          <Button
            variant="link"
            onClick={() => toggleLanguageCohesionModal(true)}
            RightIcon={() => <Info size={24} className="text-neutral-70" />}
          />
        </div>

        <Paragraph1 className="mt-4 mb-6 text-neutral-70">
          {t(`${i18nRoot}.sections.languageCohesion.description`)}
        </Paragraph1>
      </div>

      {/* Personal Talk-time Metrics */}
      <div className="mt-[44px] flex flex-col">
        <HStack justify="between" className="mb-6">
          <HStack space={2} align="center">
            <Heading1Strong className="text-neutral-900">
              {t(`${i18nRoot}.sections.participation.title`)}
            </Heading1Strong>
            <Button
              variant="link"
              onClick={toggleParticipationModal}
              RightIcon={() => <Info size={24} className="text-neutral-70" />}
            />
          </HStack>
          {personalTalkTimeMetrics?.overallProjectData && (
            <Dropdown
              button={
                <Menu.Button
                  as="button"
                  className="flex items-center rounded-full border border-solid border-gray-400 py-3 px-5"
                >
                  <HStack align="center" space={2}>
                    <Base2 className="text-neutral-900">
                      {getParticipationFilterName()}
                    </Base2>
                    <CaretDown color="#292D30" size={18} />
                  </HStack>
                </Menu.Button>
              }
              items={
                <Menu.Items
                  className={clsx(
                    "absolute right-0 top-12 flex max-h-[409px] w-64 origin-top-right flex-col gap-3 overflow-hidden rounded-xl bg-white p-4 shadow-membersOnline focus:outline-none",
                    Z_INDEX_LEVELS.BASE_CONTROL,
                  )}
                >
                  {/* All Projects option */}
                  <Menu.Item>
                    <HStack
                      space={2}
                      align="center"
                      className="cursor-pointer"
                      onClick={() => {
                        setParticipationFilter("allProjects");
                        handleUpdateParticipationFilters(
                          personalTalkTimeMetrics?.overallProjectData as TalkTimeMetrics,
                        );
                      }}
                    >
                      <Check
                        size={18}
                        className={
                          participationFilter === "allProjects"
                            ? "opacity-100"
                            : "opacity-0"
                        }
                      />
                      <Base2 className="text-neutral-900">
                        {t(`${i18nRoot}.sections.participation.allProjects`)}
                      </Base2>
                    </HStack>
                  </Menu.Item>
                  {/* Active Projects option */}
                  <Menu.Item>
                    <HStack
                      space={2}
                      align="center"
                      className="cursor-pointer"
                      onClick={() => {
                        setParticipationFilter("activeProjects");
                        handleUpdateParticipationFilters(
                          personalTalkTimeMetrics?.activeProjectsData as TalkTimeMetrics,
                        );
                      }}
                    >
                      <Check
                        size={18}
                        className={
                          participationFilter === "activeProjects"
                            ? "opacity-100"
                            : "opacity-0"
                        }
                      />
                      <Base2 className="text-neutral-900">
                        {t(`${i18nRoot}.sections.participation.activeProjects`)}
                      </Base2>
                    </HStack>
                  </Menu.Item>
                  {/* Active projects */}
                  {personalTalkTimeMetrics?.activeProjectsList &&
                    personalTalkTimeMetrics.activeProjectsList
                      .sort((a, b) => {
                        const proj1 = a.project?.name || "";
                        const proj2 = b.project?.name || "";

                        return proj1.localeCompare(proj2);
                      })
                      .map((record) => (
                        <Menu.Item key={record.project?.id}>
                          <HStack
                            space={2}
                            align="center"
                            className="cursor-pointer"
                            onClick={() => {
                              setParticipationFilter(
                                record.project?.name as string,
                              );
                              handleUpdateParticipationFilters(
                                record as TalkTimeMetrics,
                              );
                            }}
                          >
                            <Check
                              size={18}
                              className={
                                participationFilter === record.project?.name
                                  ? "opacity-100"
                                  : "opacity-0"
                              }
                            />
                            <Base2 className="text-neutral-900">
                              {truncate(record.project?.name, {
                                length: 25,
                                separator: ".",
                              })}
                            </Base2>
                          </HStack>
                        </Menu.Item>
                      ))}
                  {/* Archived projects */}
                  {personalTalkTimeMetrics?.archivedProjectsList &&
                    personalTalkTimeMetrics.archivedProjectsList
                      ?.sort((a, b) => {
                        const proj1 = a.project?.name || "";
                        const proj2 = b.project?.name || "";

                        return proj1.localeCompare(proj2);
                      })
                      .map((record) => (
                        <Menu.Item key={record.project?.id}>
                          <HStack
                            space={2}
                            align="center"
                            className="cursor-pointer"
                            onClick={() => {
                              setParticipationFilter(
                                record.project?.name as string,
                              );
                              handleUpdateParticipationFilters(
                                record as TalkTimeMetrics,
                              );
                            }}
                          >
                            <Check
                              size={18}
                              className={
                                participationFilter === record.project?.name
                                  ? "opacity-100"
                                  : "opacity-0"
                              }
                            />
                            <Base2 className="text-neutral-900">
                              {record.project?.name}
                            </Base2>
                          </HStack>
                        </Menu.Item>
                      ))}
                </Menu.Items>
              }
            />
          )}
        </HStack>
        {personalTalkTimeMetrics?.overallProjectData ? (
          <>
            <HStack className="gap-28">
              <VStack space={2}>
                <Display4 className="text-neutral-900">
                  {participatationCounts.sessionsAttended}
                </Display4>
                <Paragraph3 className="text-neutral-700">
                  {t(`${i18nRoot}.sections.participation.sessionsAttended`)}
                </Paragraph3>
              </VStack>
              <VStack space={2}>
                <Display4 className="text-neutral-900">
                  {participatationCounts.wordsSpoken}
                </Display4>
                <Paragraph3 className="text-neutral-700">
                  {t(`${i18nRoot}.sections.participation.wordsSpoken`)}
                </Paragraph3>
              </VStack>
              <VStack space={2}>
                <Display4 className="text-neutral-900">
                  {formatTime(participatationCounts.averageSpeakingTime)}
                </Display4>
                <Paragraph3 className="text-neutral-700">
                  {t(
                    `${i18nRoot}.sections.participation.averageSpeakingDuration`,
                  )}
                </Paragraph3>
              </VStack>
            </HStack>
            <div className="mt-6 h-[150px] w-full rounded-lg bg-[#F2F3F4] p-4">
              <Overline className="mb-8 text-neutral-700">
                {t(`${i18nRoot}.sections.participation.talkTimeGraph.title`)}
              </Overline>
              <HStack className="relative w-full gap-1">
                <div className="h-2 flex-1 rounded-tl-md rounded-bl-md bg-secondary-yellow-500" />
                <div className="h-2 flex-1 bg-secondary-yellow-500 opacity-25" />
                <div className="h-2 flex-1 bg-secondary-green-500 opacity-25" />
                <div className="h-2 flex-1 bg-secondary-indigo-700 opacity-25" />
                <div className="h-2 flex-1 rounded-br-md rounded-tr-md bg-secondary-indigo-700" />
                <div className="absolute w-full overflow-x-clip">
                  <div
                    className="absolute -top-[3px] h-[15px] rounded-lg bg-gradient-to-r from-[#30CD7466] to-[#4999C666]"
                    ref={highlightBarRef}
                  />
                  <div
                    className="absolute -top-[12px] h-[32px] w-[32px] rounded-full"
                    ref={avatarRef}
                  >
                    <Avatar
                      className="border-[2px] border-white shadow-md"
                      size="small"
                      user={currentUser as User}
                    />
                  </div>
                </div>
              </HStack>
              <HStack justify="between" className="mt-3">
                <VStack className="max-w-[168px] gap-1">
                  <Base1 className="text-neutral-600">
                    {t(
                      `${i18nRoot}.sections.participation.talkTimeGraph.listener.title`,
                    )}
                  </Base1>
                  <Caption2 className="text-neutral-600">
                    {t(
                      `${i18nRoot}.sections.participation.talkTimeGraph.listener.subtext`,
                    )}
                  </Caption2>
                </VStack>
                <VStack className="max-w-[168px] gap-1" align="end">
                  <Base1 className="text-neutral-600">
                    {t(
                      `${i18nRoot}.sections.participation.talkTimeGraph.sharer.title`,
                    )}
                  </Base1>
                  <Caption2 className="text-right text-neutral-600">
                    {t(
                      `${i18nRoot}.sections.participation.talkTimeGraph.sharer.subtext`,
                    )}
                  </Caption2>
                </VStack>
              </HStack>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center gap-2">
            <TeamViewLanguageCohesionIllustration />
            <Caption1>
              {t(`${i18nRoot}.sections.participation.emptyDescriptionTitle`)}
            </Caption1>
            <Caption2 className="max-w-[250px] text-center text-neutral-70">
              {t(`${i18nRoot}.sections.participation.emptyDescriptionSubtitle`)}
            </Caption2>
          </div>
        )}
      </div>

      {/* Collaboration Metrics */}
      <div className="mt-[64px] flex flex-col">
        <Heading1Strong className="text-neutral-900">
          {t(`${i18nRoot}.sections.collaborationMetrics.title`)}
        </Heading1Strong>
        <Paragraph1 className="mt-[8px] text-neutral-800">
          {t(`${i18nRoot}.sections.collaborationMetrics.description`)}
        </Paragraph1>
        <HStack className="mt-[48px] gap-26">
          <CollabMetric
            title={t(`${collaborationMetrics}.authenticity.title`)}
            leftLabel={t(`${collaborationMetrics}.authenticity.leftLabel`)}
            rightLabel={t(`${collaborationMetrics}.authenticity.rightLabel`)}
            infoText={t(`${collaborationMetrics}.authenticity.description`)}
            userAverage={personalCollabMetrics?.genuine || 0}
          />
          <CollabMetric
            title={t(`${collaborationMetrics}.opennessToChange.title`)}
            leftLabel={t(`${collaborationMetrics}.opennessToChange.leftLabel`)}
            rightLabel={t(
              `${collaborationMetrics}.opennessToChange.rightLabel`,
            )}
            infoText={t(`${collaborationMetrics}.opennessToChange.description`)}
            userAverage={personalCollabMetrics?.openToChange || 0}
          />
        </HStack>
        <HStack className="mt-[48px] gap-26">
          <CollabMetric
            title={t(`${collaborationMetrics}.affiliation.title`)}
            leftLabel={t(`${collaborationMetrics}.affiliation.leftLabel`)}
            rightLabel={t(`${collaborationMetrics}.affiliation.rightLabel`)}
            infoText={t(`${collaborationMetrics}.affiliation.description`)}
            userAverage={personalCollabMetrics?.affiliation || 0}
          />
          <CollabMetric
            title={t(`${collaborationMetrics}.empathy.title`)}
            leftLabel={t(`${collaborationMetrics}.empathy.leftLabel`)}
            rightLabel={t(`${collaborationMetrics}.empathy.rightLabel`)}
            infoText={t(`${collaborationMetrics}.empathy.description`)}
            userAverage={personalCollabMetrics?.empathetic || 0}
          />
        </HStack>
      </div>

      {modalsVisibility.languageCohesion && (
        <LanguageCohesionInfoModal
          isOpen={modalsVisibility.languageCohesion}
          setIsOpen={(shouldShow) => toggleLanguageCohesionModal(shouldShow)}
        />
      )}

      {modalsVisibility.personalityProfile && (
        <PersonalityProfileInfoModal
          isOpen={modalsVisibility.personalityProfile}
          setIsOpen={(shouldShow) => togglePersonaliltyProfileModal(shouldShow)}
        />
      )}

      {modalsVisibility.participation && (
        <ParticipationDialog
          open={modalsVisibility.participation}
          onClose={toggleParticipationModal}
        />
      )}
    </div>
  );
};
