import { Activity } from "graphql/generated";

import { ActivityType, MimeTypes } from "./LiveWorkshopActivity.types";

/**
 * useActivityType encapsulates the logic for determining which of the various
 * WorkshopActivity types has been passed in.
 */
export const useActivityType = (activity: Activity | undefined) => {
  if (!activity) {
    return;
  }

  if (
    activity.__typename === "Resource" ||
    activity.__typename === "Breakout"
  ) {
    const { link, documents, __typename } = activity;

    if (!!link) {
      // These are link resources.
      // TODO use GOOGLE_DOCS_REGEX, MIRO_REGEX .test() instead
      if (link.url.includes("miro.com")) {
        return ActivityType.Miro;
      } else if (link.url.includes("docs.google.com")) {
        return ActivityType.GoogleDoc;
      }
    } else if (documents && documents.length > 0) {
      // These are embedded file resources.
      switch (documents[0].contentType as MimeTypes) {
        case MimeTypes.PDF:
          return ActivityType.PDF;
        case MimeTypes.JPEG:
        case MimeTypes.PNG:
          return ActivityType.Image;
      }
    } else if (__typename === "Breakout") {
      return ActivityType.Discussion;
    }
  } else {
    return activity.__typename as ActivityType;
  }
};
