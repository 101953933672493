import { Avatar, Button, Loader } from "components/base";
import Center from "components/layout/Center";
import { HStack, VStack } from "components/layout/Stack";
import { Heading1Strong, Paragraph3 } from "components/Typography";
import Compressor from "compressorjs";
import { useGetEnvsContext } from "contexts";
import {
  useRemoveProfilePictureMutation,
  useUploadUserProfilePictureMutation,
} from "graphql/generated";
import useUpdateUser from "hooks/mutations/useUpdateUser";
import { t } from "i18n-js";
import React, { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import uploadToActiveStorage from "utils/helpers/upload-to-active-storage";

export function UserSettingsProfilePhoto() {
  const queryClient = useQueryClient();
  const { currentUser } = useGetEnvsContext();
  const [image, setImage] = useState<File>();
  const [errorOnUploadPhoto, setErrorOnUploadPhoto] = useState(false);
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
  const { mutateAsync: removeProfilePIcture } =
    useRemoveProfilePictureMutation();
  const { mutateAsync: uploadUserProfilePicture } =
    useUploadUserProfilePictureMutation();
  const { mutateAsync: updateUser } = useUpdateUser();
  const fileRef = useRef<HTMLInputElement>(null);
  const onButtonClick = () => {
    fileRef?.current?.click();
  };
  useEffect(() => {
    fileRef?.current?.focus();
  }, []);

  const isQueryCalled = (queryKey: string) => {
    const queryData = queryClient.getQueryData(queryKey);
    return !!queryData;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.length) {
      setIsLoadingPhoto(true);
      const file = event.target.files[0];
      new Compressor(file, {
        maxHeight: 300,
        maxWidth: 300,
        quality: 0.8,
        resize: "cover",
        success: (compressedResult: File) => {
          submitPhoto(compressedResult)
            .then(() => {
              setImage(compressedResult);
            })
            .catch(() => {
              setErrorOnUploadPhoto(true);
            })
            .finally(() => {
              isQueryCalled("CurrentUser") &&
                void queryClient.refetchQueries(["CurrentUser"]);
              isQueryCalled("ProjectAssignments") &&
                void queryClient.refetchQueries(["ProjectAssignments"]);
              isQueryCalled("ProjectById") &&
                void queryClient.refetchQueries(["ProjectById"]);
              setIsLoadingPhoto(false);
            });
        },
      });
    }
  };
  const submitPhoto = async (file: File) => {
    const blob = await uploadToActiveStorage(file);
    await uploadUserProfilePicture({ signedBlobId: blob.signed_id });
    void queryClient.invalidateQueries(["CurrentUser"]);
    void queryClient.invalidateQueries(["ProjectAssignments"]);
    void queryClient.invalidateQueries(["ProjectById"]);
  };

  const handleDelete = async () => {
    await removeProfilePIcture({});
    setImage(undefined);
    isQueryCalled("CurrentUser") &&
      void queryClient.refetchQueries(["CurrentUser"]);
    isQueryCalled("ProjectAssignments") &&
      void queryClient.refetchQueries(["ProjectAssignments"]);
    isQueryCalled("ProjectById") &&
      void queryClient.refetchQueries(["ProjectById"]);
  };
  return (
    <VStack className="!mt-12 gap-8">
      <Heading1Strong>
        {t(`component.userSettings.tabs.profile.sections.photo.title`)}
      </Heading1Strong>
      <HStack align="center" className="gap-8">
        <input
          type="file"
          ref={fileRef}
          accept="image/*"
          className="hidden"
          onChange={handleFileChange}
          data-testid="photo-uploader"
        />
        <Center className="h-[120px] w-[120px]">
          {isLoadingPhoto && <Loader />}
          {image && !isLoadingPhoto && (
            <img
              className="rounded-full"
              src={
                typeof image === "string" ? image : URL.createObjectURL(image)
              }
            />
          )}
          {!image && !isLoadingPhoto && (
            <Avatar user={currentUser || undefined} size="large" />
          )}
        </Center>
        <Button variant="outline" onClick={onButtonClick}>
          {t(`component.userSettings.tabs.profile.sections.photo.uploadPhoto`)}
        </Button>

        <Button variant="outline" onClick={() => void handleDelete()}>
          {t(`component.userSettings.tabs.profile.sections.photo.deletePhoto`)}
        </Button>

        {errorOnUploadPhoto && (
          <Paragraph3 className="text-red-400">
            {t(
              `component.userSettings.tabs.profile.sections.photo.failedToUpload`,
            )}
          </Paragraph3>
        )}
      </HStack>
    </VStack>
  );
}
