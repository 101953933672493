/* eslint-disable graphql/template-strings */
import { gql } from "@apollo/client";
import { BreakoutActivitySubscription } from "graphql/generated";
import { useQueryClient } from "react-query";

import { useSubscription } from "./use-subscription";

export const BREAKOUT_ACTIVITY_SUBSCRIPTION = gql`
  subscription BreakoutActivity($workshopId: ID!) {
    getBreakoutActivity(workshopId: $workshopId) {
      breakoutActivity {
        id
        activity {
          ... on Breakout {
            isActive
            dailyRooms {
              title
              id
              isActive
              name
              roomId
              url
              workshopParticipants {
                id
                user {
                  fullName
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface UseBreakoutActivitySubscriptionProps {
  workshopId: string;
}

export const useBreakoutActivitySubscription = ({
  workshopId,
}: UseBreakoutActivitySubscriptionProps) => {
  const queryClient = useQueryClient();

  const output = useSubscription<BreakoutActivitySubscription>(
    BREAKOUT_ACTIVITY_SUBSCRIPTION,
    {
      variables: {
        workshopId: workshopId,
      },
      async onData() {
        await queryClient.invalidateQueries("WorkshopById");
      },
    },
  );

  return output;
};
