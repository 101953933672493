import { RecordingType, TopMoments, VideoPlayer } from "components/pages";
import { Caption2 } from "components/Typography";
import { BreakoutActivityFragment } from "graphql/generated";
import { t } from "i18n-js";
import moment from "moment";
import { useTopMoment, useWorkshop } from "queries";
import React, { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
/**
 * WorkshopSummaryRecording displays the workshop recording and emotional reactions component
 */

type dailyType = {
  id?: string;
  title?: string;
};

type recordingType = {
  id: string;
  title: string;
  recordingId?: string | null;
  type?: string;
  dailyRoomId?: object | null;
  url?: string | null;
};

interface Timestamp {
  recordId: string;
  currentTime: number;
}

export const WorkshopSummaryRecording = () => {
  const { id = "" } = useParams();
  const { workshop } = useWorkshop({ id });
  const recordingid =
    workshop?.recordings[0] && workshop?.recordings[0].recordingId;

  const [currentrecordingId, setcurrentrecordingId] = useState(recordingid);
  const [currentdailyId, setcurrentDailyId] = useState<dailyType>({});
  const [parsedRecording, setParsedRecording] = useState<
    RecordingType | undefined
  >(undefined);
  const queryClient = useQueryClient();

  /* eslint-disable react-hooks/rules-of-hooks */
  const data =
    currentrecordingId &&
    useTopMoment({
      workshopId: id,
      recordingId: currentrecordingId,
      dailyRoomId: currentdailyId?.id ?? "null",
    });
  void queryClient.refetchQueries(["topMomentsOfWorkshop"]);

  const moments = data && data.TopMoment;

  const recordings = useMemo(() => {
    if (!workshop) {
      return [];
    }

    const recordings: recordingType[] = [];

    workshop.recordings
      .sort((a, b) => moment(a.startTime).diff(b.startTime))
      .map((recording, index) => {
        recordings.push({
          id: recording.id,
          url: recording?.url,
          title: `${t("workshop.summary.section.recordings.mainRoom")}-${
            index + 1
          }`,
          recordingId: recording.recordingId,
          type: recording.recordable?.__typename,
          dailyRoomId: recording?.recordable,
        });
      });

    const getRecordingsFromBreakout = (
      breakout: BreakoutActivityFragment,
      activityTitle: string | null | undefined,
    ) => {
      breakout.dailyRooms.map((room, index) => {
        room.recordings.map((recording) => {
          if (room.title) {
            recordings.push({
              id: recording?.id,
              url: recording?.url,
              title: `${activityTitle ? `${activityTitle} - ` : ""}${t(
                "breakoutActivityEditor.room",
              )} ${index + 1}`,
              recordingId: recording.recordingId || "",
              type: recording.recordable?.__typename as string,
              dailyRoomId: recording?.recordable,
            });
          }
        });
      });
    };

    workshop.agenda.map((activity) => {
      if (
        activity.__typename === "WorkshopActivity" &&
        activity.activity.__typename === "Breakout"
      ) {
        getRecordingsFromBreakout(activity.activity, activity.title);
      } else if (
        activity.__typename === "Section" &&
        activity?.workshopActivities
      ) {
        activity.workshopActivities.map((sectionActivity) => {
          if (
            sectionActivity.__typename === "WorkshopActivity" &&
            sectionActivity.activity.__typename === "Breakout"
          ) {
            getRecordingsFromBreakout(
              sectionActivity.activity,
              sectionActivity.title,
            );
          }
        });
      }
    });

    return recordings;
  }, [workshop]);

  useEffect(() => {
    const lastTimestamp = localStorage.getItem("timestamp");
    let parsedLastTimestamp: Timestamp | undefined;

    if (lastTimestamp) {
      parsedLastTimestamp = JSON.parse(lastTimestamp) as Timestamp;
    }

    if (parsedLastTimestamp?.recordId) {
      const record = recordings.find(
        (recording) => recording.id === parsedLastTimestamp?.recordId,
      );
      if (record?.id) {
        setParsedRecording(record);
      }
    }
  }, [recordings]);

  if (recordings.length === 0) {
    return (
      <div className="flex h-30 w-full items-center justify-center">
        <Caption2 className="rounded-lg bg-neutral-50 bg-opacity-15 px-3 py-2 text-neutral-800">
          {t("workshop.summary.section.recordings.processing")}
        </Caption2>
      </div>
    );
  }
  const changeMomentfunction = (
    id?: string | null,
    dailyId?: object | null,
  ) => {
    setcurrentrecordingId(id);
    dailyId && setcurrentDailyId(dailyId);
  };

  return (
    <div className="flex items-start justify-center">
      <VideoPlayer
        recordings={recordings}
        changeMomentfunction={changeMomentfunction}
        parsedRecording={parsedRecording}
      />

      {moments && (
        <div className="relative mx-auto my-auto ml-4 block h-[304px]">
          <p className="font-Steradian mb-2 h-[15px] text-[12px]  font-normal  tracking-[0.3px] text-slate-500 text-[#7F8489]">
            {" "}
            TOP MOMENTS
          </p>
          {moments.map((item, index) => (
            <TopMoments key={index} moment={item} />
          ))}
        </div>
      )}
    </div>
  );
};
