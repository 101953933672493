import { OrganizationMenuButton } from "components";
import { Avatar } from "components/base";
import NotificationButton from "components/CreateInteractions/NotificationButton";
import { UserSettings } from "components/pages";
import { MembersOnline, ProjectSwitcher } from "components/partial";
import { useGetEnvsContext } from "contexts";
import { ActionCableProvider } from "contexts/ActionCableContext";
import { ProjectPresenceChannelProvider } from "contexts/ProjectPresenceContext";
import { useModal, useProjectAccessLevel } from "hooks";
import { PencilSimple } from "phosphor-react";
import React, { Suspense, useRef } from "react";
import { useParams } from "react-router-dom";

import { HStack } from "../../layout/Stack";
// import { ShareButton } from "../ShareModal";
import ProjectLevelControl from "./ProjectLevelControl";

type EditButtonProps = {
  onClick: () => void;
  EditResourcePencil?: boolean;
};
export function EditButton({ onClick, EditResourcePencil }: EditButtonProps) {
  return (
    <button
      data-testid="edit-project"
      className={
        EditResourcePencil
          ? "flex h-10 w-10 items-center justify-center rounded-full border border-white hover:bg-tint-light-200"
          : "flex h-12 w-12 items-center justify-center rounded-full border border-white hover:bg-tint-dark-10"
      }
      type="button"
      onClick={onClick}
    >
      <PencilSimple height={24} width={24} weight="light" color="white" />
    </button>
  );
}
export function OpenProfileSection({ onClick }: EditButtonProps) {
  const { currentUser } = useGetEnvsContext();
  return (
    <button
      className="flex h-12 w-12 items-center justify-center rounded-full border border-tint-dark-30 hover:bg-tint-dark-10 focus:outline-none focus:ring focus:ring-primary-turquoise-10 focus:ring-offset-1"
      type="button"
      onClick={onClick}
    >
      <Avatar user={currentUser} size="medium" textClassName="!text-xs" />
    </button>
  );
}

export default function ProjectViewHeader({
  isProjectOver = false,
}: {
  isProjectOver?: boolean;
}) {
  const { projectId = "" } = useParams();
  const { isOpen, openModal, closeModal } = useModal();
  const {
    isOpen: userModalIsOpen,
    openModal: openUserModal,
    closeModal: closeUserModal,
    Modal: UserSettingsModal,
  } = useModal();
  const topAnchorRef = useRef<HTMLDivElement>(null);
  const { hasMemberAccess, hasAdminAccess } = useProjectAccessLevel({
    projectId,
  });

  return (
    <HStack justify="between" className="px-8 pt-3 pb-9">
      <OrganizationMenuButton data-testid="org-menu-button">
        <ProjectSwitcher />
      </OrganizationMenuButton>
      <ProjectLevelControl />
      <HStack space={4}>
        <div className="pt-[3.8px]">
          <ActionCableProvider>
            <ProjectPresenceChannelProvider>
              <MembersOnline data-testid="members-online" />
            </ProjectPresenceChannelProvider>
          </ActionCableProvider>
        </div>

        {/* {hasMemberAccess ? <ShareButton /> : undefined} */}

        {/* {hasAdminAccess && !isProjectOver ? (
          <EditButton onClick={openModal} />
        ) : undefined} */}
        <div className="pt-1">
          <NotificationButton />
        </div>
        <OpenProfileSection onClick={openUserModal} />
      </HStack>

      <UserSettingsModal
        className="h-full w-full"
        open={userModalIsOpen}
        isFullScreen
        onClose={closeUserModal}
        initialFocus={topAnchorRef}
      >
        <Suspense fallback="">
          <UserSettings onClose={closeUserModal} />
        </Suspense>
      </UserSettingsModal>
    </HStack>
  );
}
