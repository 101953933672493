import { useRemoveOrganizationPictureMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation RemoveOrganizationPicture($organizationId: ID!) {
    removeOrganizationPicture(organizationId: $organizationId) {
      organizationImageUrl
    }
  }
`;

function useRemoveOrganizationPicture() {
  const queryClient = useQueryClient();
  return useRemoveOrganizationPictureMutation({
    onSuccess: () => {
      void queryClient.refetchQueries(["OrganizationById"]);
      void queryClient.refetchQueries(["UserOrganizations"]);
      void queryClient.refetchQueries(["UserProjects"]);
    },
  });
}

export default useRemoveOrganizationPicture;
