import { Button, ReadOnlyRichTextEditor } from "components/base";
import { WorkshopMeta } from "components/partial";
import { useToast } from "components/provider";
import { Base1Strong, Paragraph2 } from "components/Typography";
import { t } from "i18n-js";
import { useDeleteWorkshop } from "mutations/use-delete-workshop";
import { NotePencil } from "phosphor-react";
import React from "react";
import { useQueryClient } from "react-query";

import { CardDraftWorkshop } from "../../WorkshopDraftsFeed";

export const WorkshopDraftCard = ({ draft }: { draft: CardDraftWorkshop }) => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const { mutate: deleteDraft } = useDeleteWorkshop();

  const handleContinueEditing = () => {
    window.open(`/workshop/${draft.id}/edit`, "_blank");
  };

  const handleDelete = () => {
    deleteDraft(
      { workshopId: draft.id },
      {
        onSuccess: () => {
          addToast({
            message: t("workshop.draftsFeed.draftDeleted"),
            variant: "warning",
            hasCloseOption: true,
          });
          void queryClient.refetchQueries(["DraftWorkshops"]);
        },
        onError: () => {
          addToast({
            message: t("workshop.draftsFeed.failedToDelete"),
            variant: "error",
            hasCloseOption: true,
          });
        },
      },
    );
  };

  return (
    <div className="flex flex-col gap-4 rounded-xl border border-dashed border-tint-dark-400 p-8">
      <div className="flex justify-between gap-4">
        <div className="flex flex-col gap-4">
          <Base1Strong className="text-tertiary-blue-700">
            {t("workshop.draftsFeed.workshopDraft")}
          </Base1Strong>

          <WorkshopMeta workshop={draft} hideStatusBadge hideIcon />
        </div>

        <div className="flex h-[72px] w-[72px] items-center justify-center rounded-full bg-neutral-500">
          <NotePencil color="white" size={40} />
        </div>
      </div>

      {draft.description ? (
        <ReadOnlyRichTextEditor
          className="flex text-neutral-800"
          // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-unsafe-assignment
          readOnlyContent={draft.description}
        />
      ) : (
        <Paragraph2 className="text-neutral-600">
          {t("workshop.draftsFeed.noDescription")}
        </Paragraph2>
      )}

      <div className="mt-8 flex gap-4">
        <Button variant="outline" role="link" onClick={handleContinueEditing}>
          {t("workshop.draftsFeed.continueEditing")}
        </Button>

        <Button variant="destructive" onClick={handleDelete}>
          {t("workshop.draftsFeed.deleteDraft")}
        </Button>
      </div>
    </div>
  );
};
