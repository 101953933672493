function getCookie(key: string): string {
  const cookie2 = document?.cookie || "";
  const cookieValue = cookie2
    .split("; ")
    .find((row) => row.startsWith(`${key}=`))
    ?.split("=")[1];
  return cookieValue || "";
}

function getCsrfToken(): string {
  const token = getCookie("CSRF-TOKEN");
  return token;
}

export default getCsrfToken;
