import {
  TemperatureResponseByIdQuery,
  TemperatureResponseByIdQueryVariables,
  useTemperatureResponseByIdQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query TemperatureResponseById($id: ID!) {
    temperatureResponseById(id: $id) {
      csvUrl
      id
    }
  }
`;

function useTemperatureResponseById(
  variables: TemperatureResponseByIdQueryVariables,
  hasAdminAccess: boolean,
): {
  csvUrl: TemperatureResponseByIdQuery["temperatureResponseById"]["csvUrl"];
} & UseQueryResult<TemperatureResponseByIdQuery, unknown> {
  const response = useTemperatureResponseByIdQuery(variables, {
    enabled: !!variables.id && hasAdminAccess,
    suspense: true,
  });

  return {
    csvUrl: response.data?.temperatureResponseById?.csvUrl || "",
    ...response,
  };
}

export { useTemperatureResponseById };
