import {
  TaskCommentsQuery,
  TaskCommentsQueryVariables,
  useTaskCommentsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query TaskComments($taskId: ID!, $count: Int!) {
    taskComments(taskId: $taskId, count: $count) {
      totalCount
      comments {
        content
        id
        isNew
        author {
          avatarUrl
          fullName
          firstName
          lastName
        }
        createdAt
      }
    }
  }
`;

function useTaskComments(variables: TaskCommentsQueryVariables): {
  comments: TaskCommentsQuery["taskComments"]["comments"];
  totalCount: TaskCommentsQuery["taskComments"]["totalCount"];
} & UseQueryResult<TaskCommentsQuery, unknown> {
  const response = useTaskCommentsQuery(variables);
  const { taskComments } = response.data || {};
  return {
    comments: taskComments?.comments || [],
    totalCount: taskComments?.totalCount || 10,
    ...response,
  };
}

export { useTaskComments };
