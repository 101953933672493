import { Check, CloudArrowUp, WarningCircle } from "phosphor-react";

import { SectionStatus } from "./SummarySectionItem.types";

/**
 * This helper function packages the logic for which icon to show depending on the Section's status.
 */
export const getIconByStatus = (status?: SectionStatus) => {
  switch (status) {
    case SectionStatus.ActionRequired:
      return WarningCircle;
    case SectionStatus.Processing:
      return CloudArrowUp;
    case SectionStatus.Ready:
      return Check;
    default:
      return;
  }
};
