import clsx from "clsx";
import { ReadOnlyRichTextEditor } from "components/base";
import { MemoizeWordCloud } from "components/base/WordCloud";
import {
  PollAnswerBar,
  PollResponseCard,
  PreviewResource,
} from "components/partial";
import { Base2, Display3 } from "components/Typography";
import {
  PollPollType,
  WorkshopActivity,
  WorkshopParticipantRole,
} from "graphql/generated";
import { t } from "i18n-js";
import React from "react";

import {
  ActivityMetaDetails,
  EmptyResponsesChip,
  PostSessionBreakout,
} from "./components";

export interface PostSessionActivityProps {
  workshopActivity: WorkshopActivity;
  userRole?: WorkshopParticipantRole;
}

export const PostSessionActivity = ({
  workshopActivity,
  userRole = WorkshopParticipantRole.Participant,
}: PostSessionActivityProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { activity, facilitatorNote } = workshopActivity;

  return (
    <div className="flex h-full w-full flex-col justify-between overflow-y-scroll py-12 px-14">
      <div
        className={clsx("flex flex-col", {
          "mx-auto w-2/3": activity.__typename === "Poll",
          "w-full": activity.__typename !== "Poll",
        })}
      >
        <ActivityMetaDetails
          className={clsx("mb-6", {
            "flex-col": activity.__typename !== "Poll",
            "justify-between": activity.__typename === "Poll",
          })}
          titleSize={activity.__typename !== "Poll" ? "large" : "small"}
          activityTypeName={activity.__typename}
          shouldShowTimer={activity.__typename !== "Poll"}
          shouldShowAnonymityFlag={activity.__typename === "Poll"}
          isAnonymous={
            activity.__typename === "Poll" && (activity.anonymous ?? false)
          }
          {...workshopActivity}
        />

        {activity.__typename === "Discussion" && (
          <ReadOnlyRichTextEditor
            className="flex w-full flex-1 rounded-lg border border-neutral-200 p-6 text-neutral-70"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            readOnlyContent={activity.description}
          />
        )}

        {activity.__typename === "Resource" && (
          <>
            {activity.documents || activity.link ? (
              <PreviewResource activityData={activity} />
            ) : (
              <Base2>{t("workshop.summary.agenda.resource.empty")}</Base2>
            )}
          </>
        )}

        {activity.__typename === "Breakout" && (
          <PostSessionBreakout activity={activity} />
        )}

        {activity.__typename === "Poll" && (
          <>
            <Display3 className="mb-6">{activity.question}</Display3>

            {(activity.pollType === PollPollType.SingleChoice ||
              activity.pollType === PollPollType.MultipleChoice) && (
              <PollAnswerBar
                pollType={activity.pollType}
                pollOptions={
                  activity.options === null ? undefined : activity.options
                }
                shouldShowControls={false}
                showResultAttributes
                setPollAnswer={() => []}
                anonymous={activity.anonymous}
              />
            )}

            {activity.pollType === PollPollType.Paragraph && (
              <div className="flex w-full flex-row flex-wrap gap-3">
                {(activity.answers?.length || 0) > 0 ? (
                  activity.answers?.map((answer) =>
                    answer.answers?.map((subAnswer, index) => (
                      <PollResponseCard
                        key={`${answer.id}${index}`}
                        className="w-[calc(50%-8px)]"
                        responseContent={subAnswer}
                        user={
                          !activity.anonymous
                            ? answer.workshopParticipant.user
                            : undefined
                        }
                        userIsAnonymous={activity?.anonymous as boolean}
                      />
                    )),
                  )
                ) : (
                  <EmptyResponsesChip className="w-full" />
                )}
              </div>
            )}

            {activity.pollType === PollPollType.WordCloud && (
              <>
                {/* TODO: use real data, https://coeurajtech.atlassian.net/browse/R10-400 */}
                {activity.answers &&
                activity.answers.some(
                  (answer) =>
                    (answer.answers?.length || 0) > 0 &&
                    answer.answers?.some((w) => w.length > 0),
                ) ? (
                  <MemoizeWordCloud
                    // use real data - https://coeurajtech.atlassian.net/browse/R10-400
                    words={
                      activity.answers?.flatMap(
                        (answer) =>
                          answer.answers?.flatMap((subAnswer) => {
                            return subAnswer
                              .split(" ")
                              .map((word) => ({ text: word, value: 1 }));
                          }) || [],
                      ) || []
                    }
                  />
                ) : (
                  <EmptyResponsesChip className="w-full" />
                )}
              </>
            )}
          </>
        )}
      </div>

      {userRole !== WorkshopParticipantRole.Participant && facilitatorNote && (
        <div className="flex flex-col justify-self-end">
          <Base2 className="text-neutral-90">
            {t("workshop.facilitationNotes")}
          </Base2>

          <ReadOnlyRichTextEditor
            className="mt-2 flex rounded-lg bg-tint-dark-10 py-3 px-4 text-neutral-70"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            readOnlyContent={facilitatorNote}
          />
        </div>
      )}
    </div>
  );
};
