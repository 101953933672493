import { Tab } from "@headlessui/react";
import { WorkshopAgenda, WorkshopOverview } from "components/pages";
import {
  EditWorkshopHeader,
  MissingDataErrorDisplay,
  PageLoader,
  TabNavigator,
} from "components/partial";
import { WorkshopState } from "graphql/generated";
import { t } from "i18n-js";
import { useWorkshop } from "queries";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import PublishWorkshopContent from "./PublishWorkshopContent";

interface WorkshopEditProps {
  isBuilderModal?: boolean;
  joinLiveHandler?: () => void;
  activeTabIndex?: number;
}

const handleClickStar = () => void 0;

function WorkshopEdit({
  joinLiveHandler,
  activeTabIndex = 0,
}: WorkshopEditProps) {
  const { id = "" } = useParams();
  const { workshop, isLoading, isError } = useWorkshop({ id });

  const [isChangeSaving, setIsChangeSaving] = useState(false);

  const startTime = workshop?.startTime
    ? new Date(workshop?.startTime)
    : undefined;
  const endTime = workshop?.endTime ? new Date(workshop?.endTime) : undefined;

  // New workshops are created with default start and end times.
  // If the workshop has just been created we need to clear those
  // default times so the user can enter their own.
  const isNewWorkshop = workshop?.createdAt === workshop?.updatedAt;
  const isReady = !!workshop?.title && !isNewWorkshop;

  return workshop ? (
    <div className="flex h-screen flex-col">
      <EditWorkshopHeader
        title={workshop?.title}
        startTime={startTime?.toISOString()}
        endTime={endTime?.toISOString()}
        isNewWorkshop={isNewWorkshop}
        onStarClick={handleClickStar}
      />

      {!workshop || isLoading || isError ? (
        <>
          {isLoading && <PageLoader isShowing />}
          {isError && <MissingDataErrorDisplay />}
        </>
      ) : (
        <TabNavigator
          tabs={[
            { title: t("workshopEditor.tabs.overview") },
            { title: t("workshopEditor.tabs.agenda"), disabled: !isReady },
          ]}
          rightContent={
            <PublishWorkshopContent
              workshopId={id}
              workshop={workshop}
              workshopIsReady={isReady}
              isSaving={isChangeSaving}
            />
          }
          defaultTabIndex={activeTabIndex}
        >
          <Tab.Panel className="flex flex-1">
            <WorkshopOverview
              workshopId={id}
              workshopIsReady={isReady}
              onMutationStateChange={({ isLoading }) => {
                setIsChangeSaving(isLoading);
              }}
            />
          </Tab.Panel>
          <Tab.Panel className="flex flex-1">
            {startTime && endTime && (
              <WorkshopAgenda
                startTime={startTime}
                endTime={endTime}
                workshopIsReady={isReady}
                onMutationStateChange={({ isLoading }) => {
                  setIsChangeSaving(isLoading);
                }}
                joinLiveHandler={joinLiveHandler}
                isWorkshopLive={workshop?.state === WorkshopState.LiveSession}
              />
            )}
          </Tab.Panel>
        </TabNavigator>
      )}
    </div>
  ) : (
    <PageLoader isShowing />
  );
}

export default WorkshopEdit;
