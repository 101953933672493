import { Agenda, WorkshopActivity } from "graphql/generated";

import { getFlattenedWorkshopActivities } from "./getFlattenedWorkshopActivities";

/**
 * This util fn gets the activity the agenda at an index by flattening the agenda
 * before searching.
 * The flattening is necessary because otherwise the 2-dimensionality of the agenda
 * would make the indices inaccurate.
 */
export const getActivityInAgendaByIndex = (
  agenda: Agenda[] | undefined,
  index: number,
): WorkshopActivity | undefined => {
  const allActivities = getFlattenedWorkshopActivities(agenda);

  const indexedActivity =
    allActivities && index < allActivities.length
      ? allActivities[index]
      : undefined;

  return indexedActivity as WorkshopActivity | undefined;
};
