import clsx from "clsx";
import { WhiteCard } from "components/base";
import { t } from "i18n-js";
import { useDiscDetailsView } from "queries/use-disc-details";
import React from "react";
import { useParams } from "react-router-dom";

import PieChart from "./base/TeamPieChart/PieChart";
import SmallPieChartBlue from "./base/TeamPieChart/SmallPieChartBlue";
import SmallPieChart from "./base/TeamPieChart/SmallPieChartGreen";
import SmallPieChartOrange from "./base/TeamPieChart/SmallPieChartOrange";
import SmallPieChartRed from "./base/TeamPieChart/SmallPieChartRed";
import { HStack, VStack } from "./layout";
import { PersonalityProfileType } from "./PersonalityProfileType";
import TeamViewSubheader from "./TeamViewSubheader";
import { Base2, Display3 } from "./Typography";

export function PersonalityViewBody() {
  const Priorities = {
    D: ["Taking action", "Overcoming opposition", "Results oriented"],
    Di: ["Vocal and enthusiastic", "Inquisitive and persuasive"],
    DC: ["Diligent and creative", "Perfection", "Independence"],
    i: ["Social recognition", "Collaboration", "Warm and trusting"],
    iD: ["High-energy", "Adventurous", "Exciting breakthroughs"],
    IS: ["Warm and friendly", "Friendship", "Agreeable and empathetic"],
    S: ["Cooperation", "Maintaining stability", "Calm and patient"],
    Si: ["Generous and well-liked", "Team-spirited", "Close relationships"],
    SC: [
      "Patient and reliable",
      "Stable and consIStent",
      "Thoughtful and soft-spoken",
    ],
    C: [
      "Quality and accuracy",
      "Maintaining stability",
      "Challenging assumptions",
    ],
    CD: [
      "Skeptical and questioning",
      "Critical thinker and systematic",
      "High standards",
    ],
    CS: ["Orderly and precise", "Even-tempered", "Well-prepared"],
  };
  const { projectId = "" } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { discDetails } = useDiscDetailsView({ projectId });

  return (
    <div className="bg-[#E5E8EA]">
      <HStack className="mb-8">
        <TeamViewSubheader />
      </HStack>
      <div className="flex w-full justify-center">
        <WhiteCard
          className={clsx(
            "relative !m-auto !mb-16 h-[757px] w-[1216px] !bg-white",
          )}
        >
          <HStack className="absolute left-2 top-4" align="start">
            <SmallPieChart />
          </HStack>
          <VStack className="absolute left-20 top-4 w-[500px]">
            <Display3 className={clsx("!text-base !font-medium")}>
              {t(
                "component.userSettings.tabs.insights.modal.personalityProfile.acronym.dominance",
              )}
            </Display3>
            <HStack align="end" className={clsx("pb-1 !text-base italic")}>
              {t(
                "component.userSettings.tabs.insights.modal.personalityProfile.acronym.boldAndTaskFocused",
              )}
            </HStack>
            <PersonalityProfileType type={"D"} points={Priorities["D"]} />
            <PersonalityProfileType type={"Di"} points={Priorities["Di"]} />
            <PersonalityProfileType type={"DC"} points={Priorities["DC"]} />
          </VStack>

          <HStack className="absolute left-2 bottom-52" align="start">
            <SmallPieChartOrange />
          </HStack>
          <VStack className="absolute left-20 -bottom-5 w-[500px]">
            <Display3 className={clsx("!text-base !font-medium")}>
              {t(
                `component.userSettings.tabs.insights.modal.personalityProfile.acronym.conscientiousness`,
              )}
            </Display3>
            <Base2 className="pb-1 !italic">
              {t(
                "component.userSettings.tabs.insights.modal.personalityProfile.acronym.calmAndTaskFocused",
              )}
            </Base2>
            <VStack>
              <PersonalityProfileType type={"C"} points={Priorities["C"]} />
              <PersonalityProfileType type={"CD"} points={Priorities["CD"]} />
              <PersonalityProfileType type={"CS"} points={Priorities["CS"]} />
            </VStack>
          </VStack>

          <HStack className="absolute right-2 top-4" align="start">
            <SmallPieChartRed />
          </HStack>
          <VStack className="absolute right-0 top-4 w-[300px]">
            <Display3 className={clsx("!text-base !font-medium")}>
              {t(
                "component.userSettings.tabs.insights.modal.personalityProfile.acronym.influence",
              )}
            </Display3>
            <Base2 className="pb-1 !italic">
              {" "}
              {t(
                "component.userSettings.tabs.insights.modal.personalityProfile.acronym.boldAndPeopleFocused",
              )}
            </Base2>
            <PersonalityProfileType type={"i"} points={Priorities["i"]} />
            <PersonalityProfileType type={"iD"} points={Priorities["iD"]} />
            <PersonalityProfileType type={"IS"} points={Priorities["IS"]} />
          </VStack>

          <HStack className="absolute right-2 bottom-52" align="start">
            <SmallPieChartBlue />
          </HStack>
          <VStack className="absolute right-0 -bottom-5 w-[300px]">
            <Display3 className={clsx("!text-base !font-medium")}>
              {t(
                `component.userSettings.tabs.insights.modal.personalityProfile.acronym.steadiness`,
              )}
            </Display3>
            <Base2 className="pb-1 !italic">
              {t(
                `component.userSettings.tabs.insights.modal.personalityProfile.acronym.calmAndPeopleFocused`,
              )}
            </Base2>
            <PersonalityProfileType type={"S"} points={Priorities["S"]} />
            <PersonalityProfileType type={"Si"} points={Priorities["Si"]} />
            <PersonalityProfileType type={"SC"} points={Priorities["SC"]} />
          </VStack>
          <VStack className="absolute top-1/2 left-1/2 -translate-x-[23rem] -translate-y-[28rem]  transform">
            <PieChart />
          </VStack>
          {discDetails?.visibleToTeam && (
            <VStack className="absolute bottom-10 right-8 w-full items-center text-neutral-700">
              <HStack>
                {t(
                  `component.userSettings.tabs.insights.modal.personalityProfile.acronym.hoverForInfo`,
                )}
              </HStack>
              <HStack className="py-4">
                {t(
                  "component.userSettings.tabs.insights.modal.personalityProfile.acronym.clickToSeeMore",
                )}
              </HStack>
            </VStack>
          )}
        </WhiteCard>
      </div>
    </div>
  );
}

export default PersonalityViewBody;
