import { noop } from "lodash";
import { X } from "phosphor-react";
import React from "react";

import { EmbeddedLinks } from "../../CreateInteractions/CreateAnnouncementForm";
import { HStack } from "../../layout/Stack";
import { Base2 } from "../../Typography";

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  isRemovable?: boolean;
  onClick?: () => void;
  onRemove?: (event: React.FormEvent, url: string) => void;
  metadata: EmbeddedLinks;
  testId?: string;
  ResourceTitleColor?: boolean;
}

export const sliceString = (string: string, charLimit = 16) => {
  return string.length > charLimit
    ? [...string.slice(0, Math.max(0, charLimit)).trim(), "..."]
    : string;
};

const Link = (props: LinkProps) => {
  const {
    isRemovable = false,
    onClick = noop,
    onRemove = noop,
    metadata,
    testId,
    ResourceTitleColor,
  } = props;
  const { url, title } = metadata;
  const originUrl = new URL(url);
  const FAVICON_URL = `https://icon.horse/icon/${originUrl?.host}`;

  return (
    <HStack
      space={2}
      align="center"
      data-testid={testId ?? "custom-link-component"}
      className="mr-2 mb-2 min-w-[90px] rounded-lg border-2 border-tint-light-200 bg-tint-dark-100 px-4 py-2"
      onClick={onClick}
    >
      <img
        className="h-6 w-6 rounded object-cover"
        src={FAVICON_URL}
        alt={title}
      />
      {isRemovable ? (
        <Base2
          className={
            ResourceTitleColor ? "text-xs text-white" : "text-neutral-90"
          }
        >
          {sliceString(title)}
        </Base2>
      ) : (
        <a
          className={
            ResourceTitleColor ? "text-xs text-white" : "text-neutral-90"
          }
          href={url}
          target="_blank"
        >
          {sliceString(title)}
        </a>
      )}
      {isRemovable && (
        <X
          className="cursor-pointer text-neutral-90"
          onClick={(event) => onRemove(event, url)}
        />
      )}
    </HStack>
  );
};

export default Link;
