import { Tooltip } from "components/base";
import React, { ReactNode, useEffect, useState } from "react";

import { IconToggleComponent } from "./IconToggle.types";

/**
 * This component is similar to an iconButton in that it has an action,
 * however it differs in that it is intended for directly togggling between two states.
 *
 * Future consideration: handling `variant` and `size` similarly to the base Button component.
 */
export const IconToggle: IconToggleComponent = ({
  isActive: propIsActive = false,
  onToggle,
  onClick,
  ariaLabel,
  activeIcon: ActiveIcon,
  inactiveIcon,
  className,
  TooltipContent,
}) => {
  const [isActive, setInternalIsActive] = useState(propIsActive);

  const UsableInactiveIcon = inactiveIcon || ActiveIcon;

  const handleToggleActive = () => {
    setInternalIsActive(!isActive);
    onToggle?.();
  };

  useEffect(() => {
    // Ensures the internal state stays up-to-date with prop value changes from parent
    setInternalIsActive(propIsActive);
  }, [propIsActive]);

  const Wrapper = ({ children }: { children: ReactNode }) => {
    if (TooltipContent) {
      return (
        <Tooltip content={TooltipContent} offset={12} placement="right">
          {children}
        </Tooltip>
      );
    }

    return <>{children}</>;
  };

  return (
    <Wrapper>
      <button
        aria-label={ariaLabel}
        className={`group flex w-min rounded-[50px] py-[11px] px-1 transition ease-in-out ${
          isActive
            ? "bg-neutral-90 text-white hover:bg-neutral-70"
            : "text-neutral-900 hover:bg-neutral-5"
        } ${className || ""}`}
        onClick={onClick ? onClick : handleToggleActive}
      >
        {isActive ? (
          <ActiveIcon size={24} className="text-inherit" />
        ) : (
          <UsableInactiveIcon size={24} className="text-inherit" />
        )}
      </button>
    </Wrapper>
  );
};
