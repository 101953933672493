import { EyeClosed, Heart, Lightbulb, Question } from "phosphor-react";
import React from "react";
export const EmotionalSymbols = ({
  symboldata,
}: {
  symboldata: {
    __typename?: "TopMoment" | undefined;
    eyeCount?: number | null | undefined;
    heartCount?: number | null | undefined;
    questionCount: number;
    signalCount?: number | null | undefined;
    starCount: number;
    timeStamp: string;
  };
}) => {
  return (
    <div className="ml-06 flex  justify-between">
      <div className="ml-8 flex-col items-center justify-center text-[#ECC73A]">
        <div className="flex h-[24px] w-[24px] items-center justify-center rounded-[999px] bg-white">
          <Lightbulb size={14} className="center mb-0" />
        </div>
        <p className="h-[12px] text-center text-[10px] font-normal  leading-[124%] tracking-[0.3px] text-[#959A9F]">
          {symboldata?.starCount || 0}
        </p>
      </div>
      <div className="ml-8 flex-col items-center justify-center text-[#E34B4B] ">
        <div className="flex h-[24px] w-[24px]  items-center justify-center rounded-[999px] bg-white">
          <Heart size={15} className="!mb-0" />
        </div>
        <p className=" h-[12px] text-center text-[10px] font-normal  leading-[124%] tracking-[0.3px] text-[#959A9F]">
          {symboldata?.heartCount || 0}
        </p>
      </div>
      <div className="ml-8 flex-col items-center justify-center text-[#3A61EB] ">
        <div className="mb-0 flex h-[24px] w-[24px] items-center justify-center rounded-[999px] bg-white">
          <Question size={16} className="!mb-0" />
        </div>
        <p className=" h-[12px] text-center text-[10px] font-normal  leading-[124%] tracking-[0.3px] text-[#959A9F]">
          {symboldata?.questionCount || 0}
        </p>
      </div>
      <div className="ml-8 flex-col items-center justify-center text-[#8B4BBF]">
        <div className="flex h-[24px] w-[24px] items-center justify-center rounded-[999px] bg-white">
          <EyeClosed size={16} className="!mb-0" />
        </div>
        <p className=" h-[12px] text-center text-[10px] font-normal  leading-[124%] tracking-[0.3px] text-[#959A9F]">
          {symboldata?.eyeCount || 0}
        </p>
      </div>
    </div>
  );
};
