import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createConsumer } from "@rails/actioncable";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";

/**
 * The websocket's connection string needs to use whichever websocket protocol
 * is appropriate to the protocol used for connecting to the server.
 * ie, `https://...`=> `wss://...` but `http://...` => `ws://...`
 *
 */
const envVars = window?.env?.vars;
const websocketProtocol =
  envVars?.USES_SECURE_CONNECTION !== "FALSE" ? "wss" : "ws";
const SOCKET_HOST = window.location.host;
// if (window.location.host === "www.recoder.com") {
//   SOCKET_HOST = "www.recoder.com";
// }
const cableConnectionString = `${websocketProtocol}://${SOCKET_HOST}/subscriptions`;

const cable = createConsumer(cableConnectionString);
const actionCableLink = new ActionCableLink({ cable });

/**
 * This is only for subscriptions.
 * Queries and mutations are run manually via react query.
 *
 * https://medium.com/@jerridan/implementing-graphql-subscriptions-in-rails-and-react-9e05ca8d6b20
 */
export const apolloClient = new ApolloClient({
  link: actionCableLink,
  cache: new InMemoryCache(),
});
