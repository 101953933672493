import { Menu } from "@headlessui/react";
import { Button } from "components/base";
import { Base2, Paragraph3 } from "components/Typography";
import { Check, DownloadSimple } from "phosphor-react";
import { useAccessLink } from "queries/use-recording-link";
import React, { useEffect, useMemo, useRef, useState } from "react";

export interface RecordingType {
  id: string;
  title: string;
  recordingId?: string | null;
  type?: string;
  dailyRoomId?: object | null;
}

interface Timestamp {
  recordId: string;
  currentTime: number;
}

export const VideoPlayer = ({
  recordings,
  changeMomentfunction,
  parsedRecording,
}: {
  recordings: RecordingType[];
  changeMomentfunction: (id?: string | null, dailyId?: object | null) => void;
  parsedRecording?: RecordingType;
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [currentRecording, setCurrentRecording] = useState<RecordingType>(
    recordings[0],
  );

  const { refetch, url: getAccessLink } = useAccessLink({
    id: currentRecording.id,
  });

  const clickEvent = (recording: RecordingType) => {
    setCurrentRecording(recording);
    changeMomentfunction(recording?.recordingId, recording?.dailyRoomId);
  };

  const currentRecordingLink: string = useMemo(() => {
    if (!getAccessLink) return "";
    return getAccessLink;
  }, [getAccessLink]);

  useEffect(() => {
    void refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRecording.id]);

  useEffect(() => {
    if (parsedRecording?.id) {
      setCurrentRecording(parsedRecording);
    }
  }, [parsedRecording]);

  useEffect(() => {
    const videoElement = videoRef.current;
    const lastTimestamp = localStorage.getItem("timestamp");
    let parsedLastTimestamp: Timestamp | undefined;

    if (lastTimestamp) {
      parsedLastTimestamp = JSON.parse(lastTimestamp) as Timestamp;
    }

    if (parsedLastTimestamp?.recordId === currentRecording.id) {
      videoElement?.addEventListener("loadeddata", () => {
        videoElement.currentTime = parsedLastTimestamp?.currentTime || 0;
      });
    } else {
      videoElement?.addEventListener("loadeddata", () => {
        videoElement.currentTime = 0;
      });
    }
  }, [currentRecording]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("timeupdate", () => {
        if (videoElement.currentTime !== 0) {
          const timestamp: Timestamp = {
            recordId: currentRecording?.id,
            currentTime: Math.floor(videoElement.currentTime),
          };
          localStorage.setItem("timestamp", JSON.stringify(timestamp));
        }
      });
    }
  }, [currentRecording?.id]);

  return (
    <div className="relative block h-[304px] w-[542px]">
      <video
        controls
        className="h-full w-full"
        src={currentRecordingLink}
        ref={videoRef}
        key={currentRecording?.id}
      />

      <Menu>
        <Menu.Button className="absolute top-6 left-2 h-8 select-none rounded-full bg-tint-dark-15 px-5 backdrop-blur-md">
          <Paragraph3 className="text-white">
            {currentRecording?.title}
          </Paragraph3>
        </Menu.Button>
        <Menu.Items className="!no-scrollbar absolute left-2 top-12 flex h-[220px] flex-col items-start overflow-scroll rounded-xl bg-white p-5">
          {recordings.map((recording: RecordingType) => {
            const selected = currentRecording?.id === recording.id;

            return (
              <Menu.Item key={recording?.id}>
                <Button
                  variant="link"
                  className="my-1 select-none focus:border-none"
                  onClick={() => {
                    void clickEvent(recording);
                  }}
                  LeftIcon={selected ? Check : undefined}
                >
                  <Base2
                    className={selected ? "font-bold" : "ml-9 text-neutral-800"}
                  >
                    {selected}
                    {recording.title}
                  </Base2>
                </Button>
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Menu>

      <a
        className="absolute top-6 right-2 flex h-8 w-8 items-center justify-center rounded-full bg-tint-dark-15 backdrop-blur-md"
        href={currentRecordingLink}
        target="_self"
        download
      >
        <DownloadSimple className="text-white" />
      </a>
    </div>
  );
};
