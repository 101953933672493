/* eslint-disable unicorn/no-nested-ternary */
import clsx from "clsx";
import {
  Base1,
  Base1Strong,
  Base2,
  Caption1,
  Heading3Strong,
  HStack,
  TaskDetailsModal,
  VStack,
} from "components";
import { Avatar, Button, ButtonVariant, Link } from "components/base";
import { EmbeddedLinks } from "components/CreateInteractions/CreateAnnouncementForm";
import { UpdatedAvatarGroup, UpdateTaskModal } from "components/partial";
import { useGetEnvsContext } from "contexts";
import { format, parseISO } from "date-fns";
import { useModal, useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { noop, pick } from "lodash";
import useUpdateTasksAssignee from "mutations/use-update-tasks-assignee";
import { Check, Checks } from "phosphor-react";
import React, {
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";

import { WeekFeedTaskType } from ".";
import { dateToday, getTaskFlag } from "./utils";

type TaskCardProps = {
  task: WeekFeedTaskType;
};

function formatTaskCreatedAt(date: string) {
  return format(parseISO(date), "MMM dd, y");
}

export function formatTaskDueDate(date: string) {
  return format(parseISO(date || ""), "EEEE MMM, dd");
}

export default function TaskCard({ task }: TaskCardProps) {
  const { isOpen, openModal, closeModal } = useModal();
  const [embeddedLink, setEmbeddedLink] = useState<EmbeddedLinks[]>([]);
  const search = useLocation().search;
  const taskCardId = new URLSearchParams(search).get("card");
  const [cardRefId, setCardRefId] = useState<string>("");
  const [retryCount, setRetryCount] = useState(4);
  const { currentUser } = useGetEnvsContext();
  const { projectId = "" } = useParams();
  const { hasViewAccess } = useProjectAccessLevel({
    projectId,
  });

  const {
    id = "",
    tasksAssignees = [],
    taskTitle: title,
    isCompleted,
    createdBy,
    createdAt = "",
    dueDate = "",
    links = [],
    isHighlighted,
    isNewComment,
  } = task;

  const assignees = tasksAssignees.map((assignee) => assignee.assignee);

  useEffect(() => {
    if (links?.length) {
      const preEmbeddedLinks = links.map((link) =>
        pick(link, ["preview", "title", "url"]),
      );
      setEmbeddedLink(preEmbeddedLinks);
    }
  }, [links]);

  const { mutate: updateTaskAssignee } = useUpdateTasksAssignee();

  const [isUpdateModalOpen, toggleIsOpen] = useReducer((state) => {
    return !state;
  }, false);

  const contextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!taskCardId) setCardRefId(taskCardId);
    if (cardRefId !== taskCardId) {
      setRetryCount(4);
    }
  }, [taskCardId]);

  useLayoutEffect(() => {
    let intervalId: NodeJS.Timer;

    if (retryCount > 0 && cardRefId === `task-${id}` && !!contextRef.current) {
      intervalId = setInterval(() => {
        setRetryCount(0);
        if (!!contextRef.current && cardRefId === `task-${id}`) {
          contextRef?.current?.scrollIntoView({
            behavior: "smooth",
          });
        } else {
          clearInterval(intervalId);
          setRetryCount((retryCount) => retryCount - 1);
        }
      }, 1500);
    }
    return () => clearInterval(intervalId);
  }, [retryCount, cardRefId, contextRef, task]);

  const hasMultiAssignees = assignees?.length > 1 || false;
  const currentUsersTaskAssignment = tasksAssignees?.find(
    (assignee) => assignee.assigneeId === currentUser?.id,
  );
  const hasAllAssigneesCompletedTask = tasksAssignees
    ?.map((assignee) => assignee.isCompleted)
    .every((value) => value === true);

  const handleCompleteTask = (isCompleted: boolean) => {
    updateTaskAssignee({
      tasksAssignee: {
        id: currentUsersTaskAssignment?.id as string,
        isCompleted,
      },
    });
  };

  const actionButton = (variant?: ButtonVariant) => {
    return (
      <>
        {currentUsersTaskAssignment?.isCompleted ? (
          <Button
            variant={variant || "outline"}
            onClick={() =>
              currentUsersTaskAssignment?.id ? handleCompleteTask(false) : noop
            }
            className={clsx(
              "!mr-4",
              !currentUsersTaskAssignment?.id && "cursor-not-allowed",
              isHighlighted && "!bg-transparent",
              variant && "!bg-neutral-900 !text-white",
            )}
          >
            {hasMultiAssignees
              ? t("component.taskCard.markIncompleteForMe")
              : t("component.taskCard.markIncomplete")}
          </Button>
        ) : currentUsersTaskAssignment?.id ? (
          <Button
            variant={variant || "outline"}
            onClick={() =>
              currentUsersTaskAssignment?.id ? handleCompleteTask(true) : noop
            }
            className={clsx(
              "!mr-4",
              // !isCompleted && isHighlighted ? "!bg-white" : "",
              !currentUsersTaskAssignment?.id && "cursor-not-allowed",
              isHighlighted && "!bg-transparent",
              variant && "!bg-neutral-900 !text-white",
            )}
          >
            {hasMultiAssignees
              ? t("component.taskCard.markCompleteForMe")
              : t("component.taskCard.markComplete")}
          </Button>
        ) : (
          <></>
        )}
      </>
    );
  };

  const handleEditTask = () => {
    closeModal();
    toggleIsOpen();
  };

  const getTaskContent = () => {
    const IS_TASK_ASSIGNEE_SPECTATING =
      currentUsersTaskAssignment?.assigneeId === currentUser?.id;
    return (
      <HStack space={2} align="center" className="w-[100%] justify-between">
        <HStack className="my-1 items-center">
          <UpdatedAvatarGroup
            users={tasksAssignees?.map((user) => user.assignee)}
            showExtraCount={false}
          />
          <Base1Strong
            className={clsx("text-neutral-700", "!ml-2")}
            data-testid="task-assignment-firstName"
          >
            {!!hasMultiAssignees
              ? `${
                  currentUsersTaskAssignment?.assignee?.firstName
                    ? currentUsersTaskAssignment?.assignee?.firstName
                    : assignees[0]?.firstName
                } ${IS_TASK_ASSIGNEE_SPECTATING ? "(You)" : ""} + ${
                  assignees.length - 1
                } more`
              : `${assignees[0]?.firstName || ""} ${
                  IS_TASK_ASSIGNEE_SPECTATING ? "(You)" : ""
                }`}
          </Base1Strong>

          <Base1 className={clsx("text-neutral-700", "!ml-2")}>
            {t(`shared.due`, { dueDate: formatTaskDueDate(dueDate) })}
          </Base1>
        </HStack>

        {currentUsersTaskAssignment?.isCompleted === false &&
          !!getTaskFlag(dueDate, dateToday) && (
            <HStack className="justify-end">
              <Caption1
                className={clsx(
                  "justify-end rounded px-2 py-1 text-neutral-90",
                  getTaskFlag(dueDate, dateToday) === "Overdue"
                    ? "bg-secondary-red-100"
                    : "bg-secondary-orange-10",
                )}
              >
                {getTaskFlag(dueDate, dateToday)}
              </Caption1>
            </HStack>
          )}
      </HStack>
    );
  };

  const getTaskIcon = () => {
    if (isCompleted || hasAllAssigneesCompletedTask) {
      return (
        <div className="absolute flex h-18 w-18 items-center justify-center rounded-full bg-secondary-green-700">
          <Checks size={40} weight="light" className="text-white" />
        </div>
      );
    } else if (
      !currentUsersTaskAssignment?.id ||
      currentUsersTaskAssignment.isCompleted === false
    ) {
      return (
        <div className="absolute flex h-18 w-18 items-center justify-center rounded-full bg-tint-dark-200">
          <Check size={40} weight="light" className="text-white" />
        </div>
      );
    } else if (currentUsersTaskAssignment.isCompleted) {
      return (
        <div className="absolute flex h-18 w-18 items-center justify-center rounded-full bg-secondary-green-700">
          <Check size={40} weight="light" className="text-white" />
        </div>
      );
    }
  };

  return (
    <VStack space={4} id={`task-${id || ""}`} className="mb-4">
      <div
        ref={cardRefId === `task-${id}` ? contextRef : undefined}
        className="flex items-center gap-2"
      >
        <Avatar user={createdBy} size="mini" textClassName="!text-xs" />
        <Caption1 className="self-center text-neutral-70">
          {t(`component.taskCard.createdThisTask`, {
            createdBy: createdBy?.fullName,
          })}
        </Caption1>

        <Caption1 className="text-neutral-40">
          {formatTaskCreatedAt(createdAt)}
        </Caption1>
      </div>
      {!!isNewComment && !hasViewAccess && (
        <div className="relative -mt-[0.15rem] flex justify-end">
          <div
            className={clsx(
              `b-12 group absolute -right-6 z-[1] box-border flex h-9 w-40 flex-row items-center justify-center rounded-xl !bg-primary-turquoise-10 !px-2 py-2 !text-neutral-90 focus:outline-none`,
            )}
          >
            <Base2>{t("constant.comments.newComment")}</Base2>
          </div>
        </div>
      )}
      <div
        className={clsx([
          "flex w-[680px] flex-col gap-4 overflow-hidden rounded-2xl",
          isCompleted && isHighlighted
            ? "bg-tints-dark-300 border border-tint-dark-15"
            : "bg-white shadow-feedCard",
        ])}
      >
        {isHighlighted && (
          <HStack className="bg-secondary-green-50 p-3"></HStack>
        )}
        <HStack className="relative justify-between p-6">
          <VStack space={4} align="start" className="ml-2 mt-2 w-[494px]">
            <Base1Strong className="text-secondary-green-70">
              {t("component.taskCard.title")}
            </Base1Strong>
            <Heading3Strong className="text-neutral-90">{title}</Heading3Strong>
            <HStack className="w-[616px]">{getTaskContent()}</HStack>
            <HStack>
              {actionButton()}
              <Button
                variant="outline"
                className={` min-w-min self-start px-8 text-neutral-90 ${
                  !isCompleted && isHighlighted ? ` !bg-white` : ""
                }`}
                onClick={openModal}
              >
                {t("component.taskCard.readMore")}
              </Button>
            </HStack>
          </VStack>
          <HStack className="justify-end">{getTaskIcon()}</HStack>
        </HStack>
        {links?.length ? (
          <VStack
            className={clsx(
              "px-7 pb-6",
              isHighlighted && !isCompleted && "bg-white pt-6",
            )}
            space={4}
          >
            {(isCompleted || !isHighlighted) && (
              <div className="border-t border-tint-dark-15" />
            )}
            <Caption1 className="text-neutral-60">
              {t("shared.attachedLinks")}
            </Caption1>
            <HStack align="center" className="flex-wrap gap-2">
              {links.map((link) => (
                <Link
                  key={link.url}
                  metadata={link as EmbeddedLinks}
                  isRemovable={false}
                />
              ))}
            </HStack>
          </VStack>
        ) : (
          <></>
        )}
      </div>
      {!!isOpen && (
        <TaskDetailsModal
          show={isOpen}
          onClose={closeModal}
          task={task}
          getTasks={getTaskContent}
          actionButton={actionButton}
          onEdit={handleEditTask}
        />
      )}
      <UpdateTaskModal
        isOpen={isUpdateModalOpen}
        closeModal={toggleIsOpen}
        task={task}
      />
    </VStack>
  );
}
