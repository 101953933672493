import { useChangeProjectStatusMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation changeProjectStatus($project: ChangeProjectStatusInput!) {
    changeProjectStatus(project: $project) {
      status
    }
  }
`;

function useChangeProjectStatus() {
  const queryClient = useQueryClient();
  return useChangeProjectStatusMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["ProjectById"]);
      await queryClient.refetchQueries(["UserProjects"]);
    },
  });
}

export default useChangeProjectStatus;
