import React from "react";
import { AWS_S3_REGEX, GOOGLE_DOCS_REGEX } from "utils/helpers/validations";

import { GoogleDocsEmbedComponent } from ".";

const GoogleDocsEmbed: GoogleDocsEmbedComponent = ({ link }) => {
  const isGoogleDocLink = GOOGLE_DOCS_REGEX.test(link);
  const isAWSS3Link = AWS_S3_REGEX.test(link);

  return isGoogleDocLink || isAWSS3Link ? (
    <iframe
      src={link + "?embedded=true"}
      className="h-full w-full max-w-[850px]"
    />
  ) : (
    <div>check your link</div>
  );
};

export default GoogleDocsEmbed;
