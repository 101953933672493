import { useDeleteTaskMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id) {
      id
      description
      createdBy {
        id
        firstName
      }
      assignees {
        id
        firstName
      }
      dueDate
      isCompleted
      project {
        id
        name
      }
    }
  }
`;

function useDeleteTask() {
  const queryClient = useQueryClient();
  return useDeleteTaskMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries(["subFilters"]);
      void queryClient.refetchQueries(["subFilters"]);
      void queryClient.invalidateQueries(["Notifications"]);
    },
  });
}

export default useDeleteTask;
