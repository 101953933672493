import clsx from "clsx";
import { Button } from "components/base";
import { Base2, Caption1, Caption2 } from "components/Typography";
import { t } from "i18n-js";
import { ChevronDownIcon, DragHandle, XIcon } from "icons";
import React, { useMemo, useState } from "react";

import { DraggableItemComponent } from "./DraggableItem.types";

const DraggableItem: DraggableItemComponent = ({
  content,
  handleProps,
  isActive,
  isExpanded,
  toggleExpanded,
  children,
  getTime,
  id,
  isDragging,
  onDelete,
  isOpen,
  isEditable = true,
  onClick,
}) => {
  const { type, title, time, errorMessage = "" } = content;
  const isSection = type === "section";
  const [isConfirming, setIsConfirming] = useState(false);

  const sectionTime = useMemo(() => {
    if (isSection && children?.length) {
      return getTime(id);
    }
    return false;
  }, [children, isSection, getTime, id]);

  const handleXClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsConfirming(true);
  };

  return (
    <div
      className={clsx(
        "group flex w-full items-center rounded-2xl p-4 pl-0 text-left outline-neutral-40 transition-all hover:bg-neutral-5",
        {
          "bg-neutral-5": isDragging || isConfirming || isOpen || isActive,
        },
        {
          "outline outline-1 ": isActive,
        },
        {
          "opacity-50": isDragging || !isEditable,
        },
        {
          "pointer-events-none cursor-not-allowed": !isEditable,
        },
        errorMessage ? "h-24" : "h-18",
      )}
      onClick={isConfirming || isSection ? undefined : onClick}
    >
      {isConfirming ? (
        <>
          <Caption1 className="ml-4 flex-1">
            {t("workshopEditor.draggableItem.confirm")}
          </Caption1>
          <Button
            variant="outline"
            size="small"
            onClick={() => setIsConfirming(false)}
          >
            {t("shared.cancel")}
          </Button>
          <Button
            variant="destructive"
            size="small"
            className="ml-2"
            onClick={onDelete}
          >
            {t("shared.delete")}
          </Button>
        </>
      ) : (
        <>
          <div {...handleProps} className="py-1 pl-3">
            {isEditable && <DragHandle className="mr-4" />}
          </div>
          {isSection && (
            <button onClick={toggleExpanded}>
              <ChevronDownIcon
                className={clsx("mr-4 ml-1", {
                  "rotate-180": isExpanded,
                })}
              />
            </button>
          )}
          <div className="relative h-full flex-1">
            <div className="absolute flex h-full w-full flex-col justify-center">
              <Base2 className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap pr-2 text-neutral-90">
                {title}
              </Base2>
              {!isSection && type && (
                <Caption1 className="mt-1 text-neutral-50">
                  {t(`workshopEditor.types.${type}`)}
                </Caption1>
              )}
              {errorMessage && (
                <Caption2 className="text-secondary-red-700">
                  {errorMessage}
                </Caption2>
              )}
            </div>
          </div>
          {(time || sectionTime) && (
            <Caption1 className="text-neutral-40">
              {sectionTime || time} {t("workshopEditor.min")}
              {time !== 1 && "s"}
            </Caption1>
          )}
          <button
            className={clsx("ml-3", {
              "hidden group-hover:block": !isDragging,
            })}
            onClick={handleXClick}
          >
            <XIcon />
          </button>
        </>
      )}
    </div>
  );
};

export default DraggableItem;
