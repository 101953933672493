/**
 * This could be replaced with the `i18n-js`#toSentence -- but that'd required upgrading
 * to v4.x.x, which would be a big refactor to handle the breaking change in the interface
 * where `t` is no longer exported.
 */
export const toSentence = (segments: string[]) => {
  const realSegments = segments.filter((segment) => !!segment);

  if (realSegments.length <= 1) {
    return realSegments;
  } else if (realSegments.length === 2) {
    return realSegments.join(", and ");
  } else {
    const preJoin = realSegments.slice(0, -1).join(", ");
    const finalJoin = [preJoin, realSegments[-1]].join(", and");
    return finalJoin;
  }
};
