import clsx from "clsx";
import { ReadOnlyRichTextEditor } from "components/base";
import { Modal } from "components/partial";
import WorkshopDetailsModal from "components/WorkshopDetailsModal";
import { Announcement, Highlights, Maybe } from "graphql/generated";
import { t } from "i18n-js";
import { HighlightsLargeIcon } from "icons";
import moment from "moment";
import { CalendarBlank, Megaphone, Presentation, X } from "phosphor-react";
import { useWorkshop } from "queries";
import React, { useState } from "react";

import { HStack, VStack } from "../../layout/Stack";
import { Caption1, Caption2, Display4 } from "../../Typography";
import {
  AnnouncementDetailsModal,
  EditAnnouncementModal,
} from "../Announcement";

type HeaderVariants = "announcement" | "meeting" | "workshop";

const variantClasses: Record<
  HeaderVariants,
  {
    background: string;
    iconBackground: string;
    iconColor?: string;
    titleColor: string;
    descriptionColor: string;
    isDark: boolean;
    icon: JSX.Element;
  }
> = {
  announcement: {
    background: "bg-secondary-yellow-50",
    iconBackground: "bg-secondary-yellow-10",
    titleColor: "text-secondary-yellow-700",
    descriptionColor: "text-neutral-70",
    isDark: false,
    icon: <Megaphone />,
  },
  meeting: {
    background: "bg-secondary-indigo-70",
    iconBackground: "bg-secondary-indigo-30",
    titleColor: "text-secondary-indigo-700",
    descriptionColor: "text-neutral-5",
    isDark: true,
    icon: <CalendarBlank />,
  },
  workshop: {
    background: "bg-tertiary-blue-50",
    iconBackground: "bg-tertiary-blue-50",
    titleColor: "text-tertiary-blue-700",
    descriptionColor: "text-neutral-5",
    isDark: true,
    icon: <Presentation />,
  },
};

type HighlightsCardsProps = {
  variant?: HeaderVariants;
  title?: string;
  time?: string;
  description?: Maybe<string> | Maybe<JSON>;
};

function HighlightsCards({
  title = "Announcement",
  variant = "announcement",
  time,
  description,
}: HighlightsCardsProps) {
  const { iconBackground, titleColor, icon } = variantClasses[variant];
  return (
    <HStack className="flex items-center gap-6 p-4">
      <HStack>
        <div
          className={clsx(
            "flex h-10 w-10 items-center justify-center rounded-full",
            iconBackground,
          )}
        >
          {React.cloneElement(icon, {
            size: 24,
            className: "text-white",
            weight: "light",
          })}
        </div>
      </HStack>
      <VStack>
        <HStack className="mb-2 flex items-center gap-2">
          <Caption1 className={titleColor}>{title}</Caption1>
          <div className="h-[3px] w-[3px] rounded-md bg-neutral-300"></div>
          <Caption2 className="text-neutral-600">
            {moment(time).format("ddd MMM DD, hh a ")}
          </Caption2>
        </HStack>

        {variant !== "workshop" ? (
          <Caption1
            style={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
            className="mt-2 "
          >
            {description}
          </Caption1>
        ) : (
          <ReadOnlyRichTextEditor readOnlyContent={description as JSON} />
        )}
      </VStack>
    </HStack>
  );
}

type HighlightsDialogProps = {
  titleHeader: string;
  onCancel: () => void;
  highlights: Partial<Highlights>;
  isOpen: boolean;
  setIsOpen: (shouldShow: boolean) => void;
};

export const HighlightsModal = ({
  titleHeader,
  onCancel,
  highlights,
  isOpen,
  setIsOpen,
}: HighlightsDialogProps) => {
  const [showEditAnnouncementModal, setShowEditAnnouncementModal] =
    useState(false);
  const [selectedAnnouncement, setSelectedAccouncement] = useState<
    Announcement | undefined
  >(undefined);
  const [detailsModal, setDetailsModal] = useState({
    visible: false,
    eventType: "",
    eventId: "",
  });
  const { visible: isModalVisible, eventId, eventType } = detailsModal;

  const closeDetailsModal = () => {
    setDetailsModal({
      visible: false,
      eventType: "",
      eventId: "",
    });
    selectedAnnouncement && setSelectedAccouncement(undefined);
  };

  const editAnnouncement = () => {
    setShowEditAnnouncementModal(true);
  };

  const { workshop } = useWorkshop({
    id: eventType === "workshop" ? eventId : "",
  });

  return (
    <>
      <Modal
        className={clsx(
          "flex w-[568px] flex-col items-start rounded-xl p-6",
          isModalVisible ? "opacity-0" : "opacity-100",
        )}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        children={
          <>
            <HStack className="w-full justify-between px-5 py-4">
              <HStack className="mt-2 mr-8">
                <HighlightsLargeIcon />
              </HStack>

              <VStack className="w-[80%]">
                <Display4> {titleHeader}</Display4>
                <Caption1 className="text-neutral-600">{`${
                  highlights?.totalHighlights as number
                } Highlights`}</Caption1>
              </VStack>

              <button
                onClick={onCancel}
                data-testid="close"
                className="isolate flex h-12 w-12 cursor-pointer items-center justify-center self-end rounded-full bg-tint-dark-300 "
              >
                <X className="text-neutral-90" size={24} />
              </button>
            </HStack>

            <VStack className="no-scrollbar h-[248px] w-full overflow-scroll ">
              {highlights?.announcements?.map((announcement) => (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedAccouncement(announcement);
                    setDetailsModal({
                      eventId: announcement.id,
                      eventType: "announcement",
                      visible: true,
                    });
                  }}
                >
                  <HighlightsCards
                    key={announcement?.id}
                    variant="announcement"
                    title={announcement?.announcementTitle}
                    time={announcement?.createdAt}
                    description={announcement?.description}
                  />
                </div>
              ))}
              {highlights?.meetings?.map((meeting) => (
                <HighlightsCards
                  key={meeting?.id}
                  variant="meeting"
                  time={meeting?.createdAt}
                  title={t("component.meetingCard.title")}
                  description={meeting?.description}
                />
              ))}
              {highlights?.workshops?.map((workshop) => (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setDetailsModal({
                      eventId: workshop.id,
                      eventType: "workshop",
                      visible: true,
                    });
                  }}
                >
                  <HighlightsCards
                    key={workshop?.id}
                    variant="workshop"
                    time={workshop?.createdAt || ""}
                    title={workshop?.title || t("component.workshopCard.title")}
                    description={workshop?.description as JSON}
                  />
                </div>
              ))}
            </VStack>
          </>
        }
      />
      {isModalVisible && eventType === "workshop" && workshop?.id && (
        <WorkshopDetailsModal
          show={isModalVisible}
          onClose={closeDetailsModal}
          workshop={workshop}
          isParticipant={false}
        />
      )}
      {isModalVisible &&
        eventType === "announcement" &&
        selectedAnnouncement?.id && (
          <AnnouncementDetailsModal
            show={isModalVisible}
            onEdit={editAnnouncement}
            onClose={closeDetailsModal}
            announcement={selectedAnnouncement}
          />
        )}
      {showEditAnnouncementModal &&
        eventType === "announcement" &&
        selectedAnnouncement?.id && (
          <EditAnnouncementModal
            show={showEditAnnouncementModal}
            onClose={() => setShowEditAnnouncementModal(false)}
            announcement={selectedAnnouncement}
          />
        )}
    </>
  );
};
