import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { Avatar, Dropdown } from "components/base";
import { VStack } from "components/layout/Stack";
import { AvatarGroup } from "components/partial";
import { Base2, Caption1 } from "components/Typography";
import { useActiveProjectMembers } from "contexts/ProjectPresenceContext";
import { CaretDown, CaretUp } from "phosphor-react";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetProjectAssignments } from "subscriptions";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";
export default function MembersOnline({ ...props }) {
  const [Arrowicon, setArrowicon] = useState(false);
  const members = useActiveProjectMembers();
  let width_of_online_button = "w-[137px]";
  let width_of_text = "w-[61px]";
  switch (true) {
    case members.length <= 1:
      width_of_online_button = "w-[133px]";
      width_of_text = "w-[57px]";
      break;
    case members.length > 0 && members.length <= 2:
      width_of_online_button = "w-[153px]";
      width_of_text = "w-[61px]";
      break;
    case members.length >= 3 && members.length <= 9:
      width_of_online_button = "w-[179px]";
      width_of_text = "w-[61px]";
      break;
    case members.length >= 10 && members.length < 100:
      width_of_online_button = "w-[189px]";
      width_of_text = "w-[81px]";
      break;
    case members.length >= 100:
      width_of_online_button = "w-[199px]";
      width_of_text = "w-[81px]";
      break;
  }

  const { projectId = "" } = useParams();
  const { users } = useGetProjectAssignments({ projectId });
  const onlineMembers = useMemo(() => {
    return users?.filter((user) =>
      members.find((member) => member.id === user.id),
    );
  }, [users, members]);

  return (
    <Dropdown
      button={
        <Menu.Button
          as="button"
          className={clsx(
            "rounded-full border border-solid border-gray-400 p-1",
            width_of_online_button,
          )}
        >
          <div
            className="flex justify-between"
            onClick={() => {
              setArrowicon(!Arrowicon);
            }}
          >
            <div className="mr-1">
              <AvatarGroup
                users={onlineMembers.sort((u1, u2) =>
                  (u1.currentSignInAt || "") < (u2.currentSignInAt || "")
                    ? -1
                    : 1,
                )}
                dataTestIdContainer="members-online-collapsed-button"
                dataTestIdItem="avatar-member-online-collapsed"
              />
            </div>
            <div
              data-testid="MemberOnline"
              className={clsx(
                "font-Steradian mt-1 mr-1 flex h-6  items-center text-center text-xs text-base font-normal not-italic",
                width_of_text,
              )}
            >
              {onlineMembers.length} online
            </div>
            <div className="mt-2 mr-2 w-[9.58px]">
              {!Arrowicon ? (
                <CaretDown className="text-neutral-60" />
              ) : (
                <CaretUp className="text-neutral-60" />
              )}
            </div>
          </div>
        </Menu.Button>
      }
      items={
        <Menu.Items
          className={clsx(
            "absolute right-0 top-12 flex max-h-[409px] w-64 origin-top-right flex-col overflow-hidden rounded-xl bg-white pt-6 text-left shadow-membersOnline focus:outline-none",
            Z_INDEX_LEVELS.BASE_CONTROL,
          )}
        >
          <VStack
            space={5}
            className="Memberscrollbar overflow-y-scroll px-4 pb-6"
          >
            {[...onlineMembers]
              .sort((u1, u2) =>
                (u1.currentSignInAt || "") < (u2.currentSignInAt || "")
                  ? -1
                  : 1,
              )
              .map((member) => (
                <div key={member.id} className="flex">
                  <Avatar
                    user={member}
                    data-testid={`avatar-member-online-expanded-${member.id}`}
                  />
                  <div className="scrollbar ml-2 flex flex-col justify-center">
                    <Base2 className="text-neutral-90">
                      {member.firstName} {member.lastName}
                    </Base2>
                    <Caption1 className="mt-1 text-neutral-50">
                      {member.pronouns ? `(${member.pronouns})` : ""}
                    </Caption1>

                    <Caption1 className="mt-1 text-neutral-50">
                      {member.defaultProjectRole
                        ? member.defaultProjectRole
                        : ""}
                    </Caption1>
                  </div>
                </div>
              ))}
          </VStack>
        </Menu.Items>
      }
      {...props}
    />
  );
}
