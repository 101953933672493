import { useTaskCommentFlagResetMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation TaskCommentFlagReset($taskId: ID!) {
    taskCommentFlagReset(taskId: $taskId) {
      id
    }
  }
`;

function useTaskCommentFlagReset() {
  const queryClient = useQueryClient();
  return useTaskCommentFlagResetMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries(["subFilters"]);
    },
  });
}

export default useTaskCommentFlagReset;
