import { Base2, HStack, VStack } from "components";
import { DatePicker, TimePicker } from "components/base";
import { FormInputErrors } from "components/partial";
import { add, addMinutes, compareAsc, differenceInMinutes } from "date-fns";
import { t } from "i18n-js";
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";

const DEFAULT_TIME_INCREMENT = 15;

type DateAndTimePickerProps = {
  onChange: (date: Date, durationInMinutes: number) => void;
  timeIncrement?: number;
  dateLabel?: string;
  datePlaceholder?: string;
  timeLabel?: string;
  startTimePlaceholder?: string;
  endTimePlaceholder?: string;
  innerRef?: ForwardedRef<HTMLInputElement>;
  error?: string;
  defaultDateTime?: Date;
  defaultDurationInMinutes?: number;
};

function DateAndTimePicker({
  onChange,
  timeIncrement = DEFAULT_TIME_INCREMENT,
  dateLabel = t("shared.dateFieldLabel"),
  datePlaceholder = t("shared.dateFieldPlaceholder"),
  timeLabel = t("shared.timeFieldLabel"),
  startTimePlaceholder = t("shared.timeFieldPlaceholder"),
  endTimePlaceholder = t("shared.timeFieldPlaceholder"),
  error,
  defaultDateTime,
  defaultDurationInMinutes,
}: DateAndTimePickerProps) {
  const [date, setDate] = useState<Date | null>();
  const [startTime, setStartTime] = useState<Date | null>();
  const [endTime, setEndTime] = useState<Date | null>();

  useEffect(() => {
    if (date && startTime && endTime) {
      const newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        startTime?.getHours(),
        startTime?.getMinutes(),
      );
      onChange(newDate, differenceInMinutes(endTime, startTime));
    }
  }, [date, startTime, endTime, onChange]);

  useEffect(() => {
    if (!endTime || !startTime) return;
    if (compareAsc(startTime, endTime) === 1) setEndTime(undefined);
  }, [startTime, endTime]);

  useEffect(() => {
    if (!date) setDate(defaultDateTime);
  }, [defaultDateTime, setDate, date]);

  return (
    <VStack space={4}>
      <DatePicker
        label={dateLabel}
        placeholder={datePlaceholder}
        onChange={setDate}
        value={date}
        format="E, MMM d"
        className="max-w-sm"
        data-testid="startDate"
        error={date ? "" : error}
        defaultValue={defaultDateTime}
      />

      <Base2 as="label" className="text-neutral-90">
        {timeLabel}
      </Base2>
      <HStack align="center" space={4}>
        <TimePicker
          aria-label={timeLabel}
          placeholder={startTimePlaceholder}
          onSelect={setStartTime}
          increment={timeIncrement}
          data-testid="startTime"
          error={startTime ? "" : error}
          defaultTime={defaultDateTime}
        />
        <Base2 className="text-neutral-60">–</Base2>
        <TimePicker
          aria-label={endTimePlaceholder}
          placeholder={endTimePlaceholder}
          onSelect={setEndTime}
          startTime={
            startTime ? add(startTime, { minutes: timeIncrement }) : undefined
          }
          increment={timeIncrement}
          data-testid="endTime"
          error={endTime ? "" : error}
          defaultTime={
            defaultDateTime && defaultDurationInMinutes
              ? addMinutes(defaultDateTime, defaultDurationInMinutes)
              : undefined
          }
        />
      </HStack>
      <FormInputErrors error={startTime && endTime ? "" : error} />
    </VStack>
  );
}
const DateAndTimePickerWithRef = forwardRef<
  HTMLInputElement,
  DateAndTimePickerProps
>((props, ref) => <DateAndTimePicker {...props} innerRef={ref} />);
export default DateAndTimePickerWithRef;
