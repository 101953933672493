import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Button, Tooltip } from "components/base";
import { HStack, VStack } from "components/layout/Stack";
import { useToast } from "components/provider";
import { Base2, Base2Strong, Caption2, Heading2 } from "components/Typography";
import { WorkshopState } from "graphql/generated";
import { t } from "i18n-js";
import { noop } from "lodash";
import { useUpdateWorkshop } from "mutations/workshop";
import { Files } from "phosphor-react";
import React, { Fragment, useEffect } from "react";
import { Z_INDEX_LEVELS } from "utils";

import { PublishWorkshopContentComponent } from "./PublishWorkshopContent.types";
import { copyToClipboard } from "./utils";

const PublishWorkshopContent: PublishWorkshopContentComponent = ({
  workshop,
  workshopId,
  workshopIsReady,
  isSaving,
}) => {
  const [publishMessage, setPublishMessage] = React.useState("");
  const [openCopyLinkDialog, setOpenCopyLinkDialog] = React.useState(false);
  const { mutate: updateWorkshop } = useUpdateWorkshop();
  const { addToast } = useToast();

  const workshopLink = `${window.location.origin}/workshop/${workshopId}`;
  const isWorkshopPublished =
    workshop?.state === "published" || workshop?.state === "live_session";

  useEffect(() => {
    if (isSaving) {
      setPublishMessage(t("workshopEditor.saving"));
    } else if (!workshopIsReady) {
      setPublishMessage(t("workshopEditor.addDateToWorkshop"));
    } else if (workshop?.state === WorkshopState.Draft) {
      setPublishMessage(t("workshopEditor.draftWorkshop"));
    } else if (workshop?.state === WorkshopState.Published) {
      setPublishMessage(t("workshopEditor.publishedWorkshop"));
    } else {
      setPublishMessage("");
    }
  }, [workshop, workshopIsReady, isSaving]);

  const publishWorkshop = () => {
    workshop && workshop?.agenda.length >= 1
      ? updateWorkshop({
          workshop: {
            id: workshopId,
            state: WorkshopState.Published,
          },
        })
      : addToast({
          message: t("workshopEditor.error"),
          variant: "error",
          hasCloseOption: true,
        });
  };

  const handleCopyLink = async () => {
    try {
      await copyToClipboard(workshopLink);
      setOpenCopyLinkDialog(false);
      addToast({
        message: t("workshop.copyWorkshopLinkDialog.linkCopied"),
        variant: "success",
        hasCloseOption: true,
      });
    } catch {
      setOpenCopyLinkDialog(false);
      addToast({
        message: t("workshop.copyWorkshopLinkDialog.failedToCopy"),
        variant: "error",
        hasCloseOption: true,
      });
    }
  };

  const TooltipContent = () => (
    <Caption2>{t("shared.copyWorkshopLink")}</Caption2>
  );

  return (
    <HStack align="center">
      <Base2
        className={clsx("mr-4 text-neutral-500", {
          "text-green-700":
            isSaving || workshop?.state === WorkshopState.Published,
        })}
      >
        {publishMessage}
      </Base2>

      <Button
        className=""
        variant="primary"
        onClick={publishWorkshop}
        disabled={!workshopIsReady || workshop?.state !== WorkshopState.Draft}
      >
        {workshop?.state === WorkshopState.Published
          ? t("workshopEditor.publishedButton")
          : t("workshopEditor.unPublishedButton")}
      </Button>
      <div className="!ml-4 cursor-pointer">
        <Tooltip content={TooltipContent} placement="top" offset={12}>
          <Files
            size={30}
            onClick={() =>
              isWorkshopPublished
                ? setOpenCopyLinkDialog(!openCopyLinkDialog)
                : noop()
            }
            className={clsx(
              isWorkshopPublished ? "cursor-pointer" : "cursor-not-allowed",
            )}
          />
        </Tooltip>
      </div>
      <Transition show={openCopyLinkDialog} as={Fragment}>
        <Dialog
          as="div"
          onClose={noop}
          className={clsx(
            "fixed inset-0 flex h-screen items-center justify-center bg-neutral-900 bg-opacity-30",
            Z_INDEX_LEVELS.MODAL,
          )}
        >
          <VStack className="fixed top-1/2 left-1/2 w-[634px] -translate-x-1/2 -translate-y-1/2 transform bg-neutral-white p-10 shadow-milestoneCard">
            <Heading2 className="!mb-[24px] text-neutral-900">
              {t("workshop.copyWorkshopLinkDialog.title")}
            </Heading2>
            <VStack className="!mb-[32px]">
              <Base2 className="!mb-[8px]">
                {t("workshop.copyWorkshopLinkDialog.link")}
              </Base2>
              <div className="!mb-[12px] rounded-md bg-tint-dark-10 px-[16px] py-[13px] text-neutral-800">
                <Base2>{workshopLink}</Base2>
              </div>
              <Base2 className="text-neutral-800">
                <Base2Strong className="inline">
                  {t("workshop.copyWorkshopLinkDialog.whoCanAccessLink1")}
                </Base2Strong>
                {t("workshop.copyWorkshopLinkDialog.whoCanAccessLink2")}
              </Base2>
            </VStack>
            <HStack align="center" justify="between" space={3}>
              <Button
                variant="destructive"
                className="w-[50%]"
                onClick={() => setOpenCopyLinkDialog(false)}
              >
                {t("workshop.copyWorkshopLinkDialog.cancel")}
              </Button>
              <Button className="w-[50%]" onClick={() => void handleCopyLink()}>
                {t("workshop.copyWorkshopLinkDialog.copyLink")}
              </Button>
            </HStack>
          </VStack>
        </Dialog>
      </Transition>
    </HStack>
  );
};

export default PublishWorkshopContent;
