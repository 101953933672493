import { Button } from "components/base";
import { HStack } from "components/layout";
import { Base2, Caption1, Display5 } from "components/Typography";
import { WorkshopParticipantRole } from "graphql/generated";
import { t } from "i18n-js";
import React, { useState } from "react";

import ShareSummaryDialog from "../ShareSummaryDialog";
import { SummarySectionItem, SummarySectionType } from "./components";

export interface SummarySidebarProps {
  sections: SummarySectionType[];
  activeSection?: SummarySectionType;
  userRole?: WorkshopParticipantRole;
}

export const SummarySidebar = ({
  sections,
  activeSection,
  userRole = WorkshopParticipantRole.Participant,
}: SummarySidebarProps) => {
  const [isSummaryDialogOpen, setIsSummaryDialogOpen] = useState(false);
  const isParticipant = userRole === WorkshopParticipantRole.Participant;
  const isUserOwnerOrFacilitator =
    userRole === WorkshopParticipantRole.Owner ||
    userRole === WorkshopParticipantRole.Facilitator;

  const toggleSummaryDialog = () => setIsSummaryDialogOpen((prev) => !prev);

  return (
    <menu
      // designs have bg color listed as neutral-50 but the hexcode matches neutral-0
      className="flex w-108 flex-col overflow-scroll bg-neutral-0 px-6 py-10"
    >
      <div className="mb-8">
        <HStack align="center" space={4} className="mb-3">
          <Display5>{t("workshop.summary.sidebar.title")}</Display5>
          {isUserOwnerOrFacilitator && (
            <Button
              variant="outline"
              size="small"
              onClick={toggleSummaryDialog}
            >
              <Base2>{t("shared.share")}</Base2>
            </Button>
          )}
        </HStack>
        {!isParticipant && (
          <Caption1 className="mt-1 text-neutral-70">
            {t("workshop.summary.sidebar.curateMessage")}
          </Caption1>
        )}
      </div>

      <div className="flex flex-col gap-4">
        {sections.map((section) => (
          <SummarySectionItem
            key={section.title}
            isActive={activeSection?.id === section.id}
            {...section}
          />
        ))}
      </div>
      <ShareSummaryDialog
        open={isSummaryDialogOpen}
        onClose={toggleSummaryDialog}
      />
    </menu>
  );
};
