import clsx from "clsx";
import { Display4 } from "components";
import { Button, Input, WeekLengthPicker } from "components/base";
import { Phase } from "graphql/generated";
import { useModal } from "hooks";
import { t } from "i18n-js";
import noop from "lodash/noop";
import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Plus,
  XCircle,
} from "phosphor-react";
import React, { useState } from "react";
import { FieldArrayWithId, useFieldArray, useForm } from "react-hook-form";
import { VALIDATE_STRING_REGEX } from "utils/helpers/validations";

import { MAX_PROJECT_NAME_CHARS } from "./constants";
import {
  CreateProjectPhasesFormInput,
  DefaultFieldsType,
  StepFormProps,
} from "./types";
import {
  getDefaultFields,
  getPhaseField,
  mergeWithDefaultFields,
} from "./utils";

const MAX_PHASE_TITLE_CHARS = 40;

const PROJECT_PHASE_NAME_FIELD_PATTERN = {
  pattern: {
    value: VALIDATE_STRING_REGEX,
    message: t("errors.noLeadingSpaces"),
  },
};

const PROJECT_PHASE_NAME_FIELD_OPTIONS = {
  required: t("errors.presence"),
  maxLength: {
    value: MAX_PROJECT_NAME_CHARS,
    message: t("errors.maxLengthExceeded", {
      size: MAX_PROJECT_NAME_CHARS,
    }),
  },
  ...PROJECT_PHASE_NAME_FIELD_PATTERN,
};

const PROJECT_PHASE_WEEKS_FIELD_OPTIONS = {
  required: t("errors.presence"),
};

type PhasesFormProps = {
  previousStep?: (args?: DefaultFieldsType) => void;
  isEditing?: boolean;
  onDeletePhase?: (id: string) => void;
  direction?: "create" | "edit";
} & StepFormProps;

function PhasesForm({
  className,
  fields = {},
  previousStep = noop,
  onSubmitSuccess = noop,
  isEditing = false,
  onDeletePhase = noop,
  direction,
}: PhasesFormProps) {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedPhaseIndex, setSelectedPhaseIndex] = useState<number>();
  const {
    control,
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
    getValues,
    trigger,
  } = useForm<CreateProjectPhasesFormInput>({
    defaultValues: mergeWithDefaultFields<CreateProjectPhasesFormInput>(
      getDefaultFields<DefaultFieldsType>(),
      copyIds(fields) as CreateProjectPhasesFormInput,
    ),
    mode: "onTouched",
  });

  const {
    fields: phasesFields,
    update,
    remove,
    append,
  } = useFieldArray({
    control,
    name: "phases",
  });

  function copyIds(fieldValues: DefaultFieldsType) {
    const { phases } = fieldValues;
    const processPhases = phases?.map((phase) => {
      const { id } = phase;
      return {
        ...phase,
        phaseId: id,
      };
    });

    const newFields = { ...fieldValues, phases: processPhases };

    return newFields;
  }
  function removeIds(formValues: DefaultFieldsType) {
    const { phases } = formValues;
    const processPhases = phases
      ?.filter((phase, index) => {
        const { name } = phase;
        return !!name;
      })
      ?.map((phase, index) => {
        const { phaseId } = phase;

        return {
          ...phase,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          id: phaseId,
          position: index,
        };
      });

    const newFormValues = { ...formValues, phases: processPhases };
    onSubmitSuccess(newFormValues);
  }

  const isFieldRequired = (index: number) => {
    if (index === 0) return true;

    const middlePhases = getValues("phases").slice(index);

    if (middlePhases?.length === 0) return false;

    return middlePhases.some(
      ({ name = "", weeksLength = 0 }: Partial<Phase>) =>
        name.length > 0 || weeksLength > 0,
    );
  };

  const removePhase = () => {
    const { id } = getValues(`phases.${selectedPhaseIndex || 0}`);
    id && onDeletePhase(id);
    remove(selectedPhaseIndex);

    setSelectedPhaseIndex(undefined);
    setShowConfirmDeleteModal(false);
  };

  const isActiveIndex = (
    phase: FieldArrayWithId<CreateProjectPhasesFormInput, "phases", "id">,
  ) => {
    const differenceInTimeForIndex =
      Date.now() -
      new Date(phase?.startDate || Number.POSITIVE_INFINITY).getTime();

    const isActiveIndex = differenceInTimeForIndex > 0;

    return isActiveIndex;
  };

  function moveUpOrDown(index: number, direction: "up" | "down") {
    const phases = [...phasesFields];
    const prevIndexElement = phases[index - 1];
    const currentIndexElement = phases[index];
    const nextIndexElement = phases[index + 1];

    const isActivePrevIndex = isActiveIndex(prevIndexElement);
    const isActiveCurrentIndex = isActiveIndex(currentIndexElement);

    if (isActiveCurrentIndex) return;

    if (direction === "down") {
      update(index + 1, currentIndexElement);
      update(index, nextIndexElement);
    } else if (direction === "up" && !isActivePrevIndex) {
      update(index - 1, currentIndexElement);
      update(index, prevIndexElement);
    }
  }

  return (
    <form
      className={clsx(
        "flex h-full w-full flex-col justify-between px-14 pb-8 pt-20 text-left",
        className,
      )}
      onSubmit={handleSubmit(removeIds) as () => void}
    >
      <div className="my-16 p-1 pr-4">
        {phasesFields.map((field, index) => {
          let upArrowClassName = "",
            downArrowClassName = "";
          const isFirstIndex = index === 0;
          const isLastIndex = index === phasesFields.length - 1;
          const prevPhase = index ? phasesFields[index - 1] : phasesFields[0];
          const isPrevPhaseActive = isActiveIndex(prevPhase);
          const differenceInTimeForCurrentIndex =
            Date.now() - new Date(field.startDate).getTime();

          const differenceInDaysForCurrentIndex =
            differenceInTimeForCurrentIndex / (1000 * 3600 * 24);

          let differenceInTimeForPreviousIndex = 0,
            differenceInDaysForPreviousIndex = 0;

          if (index > 0) {
            differenceInTimeForPreviousIndex =
              Date.now() -
              new Date(phasesFields[index - 1].startDate).getTime();

            differenceInDaysForPreviousIndex =
              differenceInTimeForPreviousIndex / (1000 * 3600 * 24);
          }

          if (direction === "create") {
            if (isFirstIndex) upArrowClassName = "hidden";
            else if (isLastIndex) {
              downArrowClassName = "hidden";
            }
          } else if (direction === "edit") {
            if (isFirstIndex) upArrowClassName = "hidden";
            if (differenceInDaysForCurrentIndex >= 0) {
              upArrowClassName = "hidden";
              downArrowClassName = "hidden";
            }
            if (
              differenceInDaysForPreviousIndex > 0 &&
              differenceInTimeForCurrentIndex < 0 &&
              !isLastIndex
            ) {
              upArrowClassName = "hidden";
              downArrowClassName = "block";
            }

            if (
              differenceInDaysForPreviousIndex < 0 &&
              Number.isNaN(differenceInDaysForCurrentIndex)
            ) {
              upArrowClassName = "block";
              downArrowClassName = "block";
            }
            if (
              Number.isNaN(differenceInDaysForCurrentIndex) &&
              differenceInDaysForPreviousIndex > 0
            ) {
              upArrowClassName = "hidden";
              downArrowClassName = "block";
            }
            if (differenceInDaysForCurrentIndex === Number.NaN || isLastIndex) {
              upArrowClassName = "block";
              downArrowClassName = "hidden";
            }
            if (isPrevPhaseActive) {
              upArrowClassName = "hidden";
            }
          }
          return (
            <div className="mb-8 flex items-end" key={field.id}>
              <Input
                maxLength={MAX_PHASE_TITLE_CHARS}
                contentClassName="grow pr-4"
                error={errors?.phases?.[index]?.name?.message || ""}
                label={t("component.createProjectForm.phaseTitleInputLabel", {
                  number: index + 1,
                })}
                placeholder={t(
                  "component.createProjectForm.phaseTitleInputPlaceholder",
                )}
                {...register(
                  `phases.${index}.name`,
                  isFieldRequired(index)
                    ? { ...PROJECT_PHASE_NAME_FIELD_OPTIONS }
                    : { ...PROJECT_PHASE_NAME_FIELD_PATTERN },
                )}
                onBlur={(e) => {
                  update(index, {
                    ...getValues(`phases.${index}`),
                    name: e.currentTarget.value,
                  });
                }}
              />

              <WeekLengthPicker
                className={clsx(
                  "h-12 w-40",
                  errors?.phases?.[index] &&
                    errors?.phases?.[index]?.name &&
                    "mb-6",
                )}
                placeholder={t(
                  "component.createProjectForm.phaseWeeksInputPlaceholder",
                )}
                error={errors?.phases?.[index]?.weeksLength?.message || ""}
                value={String(getValues(`phases.${index}.weeksLength`) || "")}
                {...register(
                  `phases.${index}.weeksLength`,
                  isFieldRequired(index)
                    ? { ...PROJECT_PHASE_WEEKS_FIELD_OPTIONS }
                    : {},
                )}
                onChange={
                  (async (weeksLength: string) => {
                    update(index, {
                      ...getValues(`phases.${index}`),
                      weeksLength: Number.parseInt(weeksLength),
                    });
                    await trigger(`phases.${index}.weeksLength`);
                  }) as (weeksLength: string) => void
                }
              />

              <div
                className={clsx(
                  "flex w-[32px] items-center self-center pt-8 pl-2",
                  errors?.phases?.[index]?.name && "pt-1",
                )}
              >
                {phasesFields.length === 1 ||
                isActiveIndex(field) ? undefined : (
                  <button
                    type="button"
                    aria-label={t("component.createProjectForm.removePhase", {
                      index,
                    })}
                    onClick={() => {
                      if (isEditing) {
                        setSelectedPhaseIndex(index);
                        setShowConfirmDeleteModal(true);
                      } else {
                        remove(index);
                      }
                    }}
                  >
                    <XCircle size="1.5em" className="text-neutral-50" />
                  </button>
                )}
                {phasesFields.length === 1 ||
                isActiveIndex(field) ? undefined : (
                  <>
                    <Button
                      type="button"
                      className={upArrowClassName}
                      size="small"
                      variant="link"
                      onClick={() => {
                        moveUpOrDown(index, "up");
                      }}
                      LeftIcon={ArrowUp}
                    ></Button>
                    <Button
                      type="button"
                      className={downArrowClassName}
                      size="small"
                      variant="link"
                      onClick={() => {
                        moveUpOrDown(index, "down");
                      }}
                      LeftIcon={ArrowDown}
                    ></Button>
                  </>
                )}
              </div>
            </div>
          );
        })}

        <div>
          <Button
            type="button"
            onClick={() => {
              append(getPhaseField());
            }}
            variant="outline"
            aria-label={t("component.createProjectForm.addPhase")}
            LeftIcon={Plus}
          >
            {t("component.createProjectForm.addPhase")}
          </Button>
        </div>
      </div>

      <div className="fixed bottom-0 w-1/2 -translate-x-14 bg-white py-4 shadow-phasesGoals">
        <div className="flex w-full items-center justify-between pr-24 pl-14">
          <Button
            type="button"
            onClick={() => {
              previousStep(getValues());
            }}
            variant="outline"
            LeftIcon={ArrowLeft}
          >
            {t("shared.back")}
          </Button>

          <Button
            type="submit"
            variant="secondary"
            disabled={isSubmitting}
            RightIcon={ArrowRight}
          >
            {t("shared.next")}
          </Button>
        </div>
      </div>
      <ConfirmDeletePhaseModal
        isOpen={showConfirmDeleteModal}
        closeModal={() => setShowConfirmDeleteModal(false)}
        onSubmitSuccess={removePhase}
      />
    </form>
  );
}
type ConfirmDeletePhaseModalProps = {
  onSubmitSuccess?: (args?: DefaultFieldsType | undefined) => void;
  isOpen: boolean;
  closeModal: () => void;
};
function ConfirmDeletePhaseModal({
  onSubmitSuccess = noop,
  isOpen,
  closeModal,
}: ConfirmDeletePhaseModalProps) {
  const { Modal: ConfirmationModal } = useModal();

  return (
    <ConfirmationModal open={isOpen} onClose={closeModal} className="w-108">
      <div className="flex flex-col gap-4 p-8">
        <Display4 className="text-neutral-90">
          {t("component.editProjectForm.deletePhase.title")}
        </Display4>
        <div className="mt-6 flex w-full flex-col gap-4">
          <Button onClick={closeModal}>
            {t("component.editProjectForm.deletePhase.cancel")}
          </Button>
          <Button variant="destructive" onClick={() => onSubmitSuccess()}>
            {t("component.editProjectForm.deletePhase.confirm")}
          </Button>
        </div>
      </div>
    </ConfirmationModal>
  );
}

export default PhasesForm;
