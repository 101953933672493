import { HStack } from "components/layout/Stack";
import { Base2 } from "components/Typography";
import { MilestoneTypeEnum } from "graphql/generated";
import { t } from "i18n-js";
import {
  AhaSmallActiveIcon,
  AhaSmallIcon,
  CheckinSmallActiveIcon,
  CheckinSmallIcon,
  DecisionSmallActiveIcon,
  DecisionSmallIcon,
  KickoffSmallActiveIcon,
  KickoffSmallIcon,
  PresentationSmallActiveIcon,
  PresentationSmallIcon,
  ShareoutSmallActiveIcon,
  ShareoutSmallIcon,
  WorkshopSmallActiveIcon,
  WorkshopSmallIcon,
} from "icons";
import { startCase } from "lodash";
import React, { useCallback, useEffect } from "react";

import MilestonesFormMilestoneType from "./MilestonesFormMilestoneType";

type MilestonesFormMilestoneTypeListProps = {
  onSelect: (milestoneType: MilestoneTypeEnum) => void;
  selectedType?: string;
};

const sanitizedMilestoneTypes = (milestoneType: string) => {
  return startCase(milestoneType);
};

export const milestoneTypes: {
  [key in MilestoneTypeEnum]: {
    title: string;
    unselectedIcon: React.ReactNode;
    selectedIcon: React.ReactNode;
  };
} = {
  [MilestoneTypeEnum.Kickoff]: {
    title: sanitizedMilestoneTypes(MilestoneTypeEnum.Kickoff),
    unselectedIcon: <KickoffSmallIcon />,
    selectedIcon: <KickoffSmallActiveIcon />,
  },
  [MilestoneTypeEnum.Decision]: {
    title: sanitizedMilestoneTypes(MilestoneTypeEnum.Decision),
    unselectedIcon: <DecisionSmallIcon />,
    selectedIcon: <DecisionSmallActiveIcon />,
  },
  [MilestoneTypeEnum.ShareOut]: {
    title: sanitizedMilestoneTypes(MilestoneTypeEnum.ShareOut),
    unselectedIcon: <ShareoutSmallIcon />,
    selectedIcon: <ShareoutSmallActiveIcon />,
  },
  [MilestoneTypeEnum.CheckIn]: {
    title: sanitizedMilestoneTypes(MilestoneTypeEnum.CheckIn),
    unselectedIcon: <CheckinSmallIcon />,
    selectedIcon: <CheckinSmallActiveIcon />,
  },
  [MilestoneTypeEnum.Presentation]: {
    title: sanitizedMilestoneTypes(MilestoneTypeEnum.Presentation),
    unselectedIcon: <PresentationSmallIcon />,
    selectedIcon: <PresentationSmallActiveIcon />,
  },
  [MilestoneTypeEnum.Workshop]: {
    title: sanitizedMilestoneTypes(MilestoneTypeEnum.Workshop),
    unselectedIcon: <WorkshopSmallIcon />,
    selectedIcon: <WorkshopSmallActiveIcon />,
  },
  [MilestoneTypeEnum.Aha]: {
    title: sanitizedMilestoneTypes(MilestoneTypeEnum.Aha),
    unselectedIcon: <AhaSmallIcon />,
    selectedIcon: <AhaSmallActiveIcon />,
  },
};

export default function MilestonesFormMilestoneTypeList({
  onSelect,
  selectedType,
}: MilestonesFormMilestoneTypeListProps) {
  const [milestoneTypeSelected, setMilestoneTypeSelected] = React.useState("");

  const handleSelect = useCallback(
    (milestoneType: MilestoneTypeEnum) => {
      setMilestoneTypeSelected(milestoneType);
      onSelect(milestoneType);
    },
    [onSelect],
  );

  useEffect(() => {
    if (
      selectedType &&
      !milestoneTypeSelected &&
      selectedType !== milestoneTypeSelected &&
      Object.values(MilestoneTypeEnum).includes(
        selectedType as MilestoneTypeEnum,
      )
    ) {
      handleSelect(selectedType as MilestoneTypeEnum);
    }
  }, [selectedType, milestoneTypeSelected, handleSelect]);

  return (
    <>
      <Base2>
        {t(`page.createProject.step.milestones.sidebar.form.milestonesType`)}
      </Base2>
      <HStack className="flex-wrap gap-x-16 gap-y-8">
        {Object.entries(milestoneTypes).map(
          ([milestoneTypeIndex, milestoneType]) => (
            <MilestonesFormMilestoneType
              key={`${milestoneType.title}-${milestoneTypeIndex}`}
              unselectedIcon={milestoneType.unselectedIcon}
              selectedIcon={milestoneType.selectedIcon}
              title={milestoneType.title}
              value={milestoneTypeIndex as MilestoneTypeEnum}
              isSelected={milestoneTypeSelected === milestoneTypeIndex}
              onSelect={handleSelect}
            />
          ),
        )}
      </HStack>
    </>
  );
}
