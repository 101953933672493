/* eslint-disable unicorn/no-nested-ternary */
import clsx from "clsx";
import { PhaseGoalModal } from "components";
import {
  Avatar,
  Button,
  MilestoneIcon,
  MiniTimeline,
  WhiteCard,
} from "components/base";
import { PhaseType, useProjectNavigator } from "contexts";
import { add, addDays, endOfDay, format, isSameWeek, parseISO } from "date-fns";
import {
  Highlights,
  Milestone,
  MilestoneTypeEnum,
  Phase,
  ProjectByIdQuery,
} from "graphql/generated";
import { t } from "i18n-js";
import { DropIcon } from "icons";
import moment from "moment";
import { BracketsCurly } from "phosphor-react";
import { usePulseCheckWeekSummary } from "queries/use-pulse-check-week-summary";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { DeepPartial } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import AddPhaseSummaryModal from "./AddPhaseSummaryModal";
import HighlightsBar from "./base/Highlights/HighlightsBar";
import PulseCheckAverage from "./base/PulseCheckAverage";
import EditPhaseSummaryModal from "./EditPhaseSummaryModal";
import { HighlightsModal } from "./pages/Project/HighlightsModal";
import {
  Base2,
  Display4,
  Display5,
  Heading3Strong,
  Overline,
  Paragraph3,
} from "./Typography";

function addWeeks(date: Date, weeks: number) {
  return add(date, { weeks });
}

type pulseCheckWeekSummaryType = {
  average: number;
  highestAverage: number;
  lowestAverage: number;
  weekDate?: string | null | undefined;
  phaseId: number;
};

function formatDate(startDate: Date, endDate: Date) {
  const startDateFormatted = format(startDate, "MMM d");
  const endDateFormatted = format(endDate, "MMM d");
  return `${startDateFormatted} - ${
    endDateFormatted.slice(0, 3) === startDateFormatted.slice(0, 3)
      ? endDateFormatted.slice(4, 6)
      : endDateFormatted
  }`;
}

function filterHighlightsByWeeks(
  phaseHighlights: Partial<Highlights>,
  weekNumber: number,
) {
  let totalHighlights = 0;
  const announcementsList = phaseHighlights?.announcements || [];
  const meetingsList = phaseHighlights?.meetings || [];
  const workshopsList = phaseHighlights?.workshops || [];

  const filteredAnnouncements = announcementsList?.filter(
    (announcement) =>
      weekNumber ===
      moment(announcement?.scheduleTime || announcement?.createdAt).week(),
  );
  const filteredMeetings = meetingsList?.filter(
    (meeting) =>
      weekNumber === moment(meeting?.startTime || meeting?.createdAt).week(),
  );
  const filteredWorkshops = workshopsList?.filter(
    (workshop) =>
      weekNumber === moment(workshop?.startTime || workshop?.createdAt).week(),
  );

  totalHighlights =
    filteredAnnouncements.length +
    filteredMeetings.length +
    filteredWorkshops.length;

  return {
    __typename: "Highlights",
    totalHighlights: totalHighlights,
    announcements: filteredAnnouncements,
    meetings: filteredMeetings,
    workshops: filteredWorkshops,
  };
}

function filterPulseCheckWeekSummary(
  pulseCheckWeekSummary: Array<pulseCheckWeekSummaryType>,
  startDate: Date,
) {
  const friday = moment(startDate).add(5, "days").format("YYYY-MM-DD");
  const pulseCheckWeekAverage = pulseCheckWeekSummary.find(
    (entry: pulseCheckWeekSummaryType) => entry?.weekDate === friday,
  );

  return pulseCheckWeekAverage
    ? {
        ...pulseCheckWeekAverage,
        validPulseCheckAverage: !!pulseCheckWeekAverage,
      }
    : {
        validPulseCheckAverage: false,
        average: 0,
        highestAverage: 0,
        lowestAverage: 0,
      };
}

function PhaseCard({
  week,
  startDate,
  phaseId,
  highlights,
  openHighlightsModal,
  milestone,
  onClick,
  setActiveWeek,
}: {
  week: number;
  startDate: Date;
  phaseId: string;
  highlights: Partial<Highlights>;
  milestone?: Partial<Milestone>;
  onClick?: () => void;
  openHighlightsModal: (
    event: React.SyntheticEvent,
    highlights: Partial<Highlights>,
  ) => void;
  setActiveWeek: React.Dispatch<React.SetStateAction<number>>;
}) {
  const isCurrentPhase = useMemo(
    () => isSameWeek(new Date(), startDate),
    [startDate],
  );

  const { validPulseCheckAverage, average, highestAverage, lowestAverage } =
    filterPulseCheckWeekSummary(
      usePulseCheckWeekSummary({
        phaseId: `${phaseId}`,
      })?.pulseCheckWeekSummary || [],
      startDate,
    );

  useEffect(() => {
    isCurrentPhase && setActiveWeek(week);
  }, [isCurrentPhase]);

  return (
    <WhiteCard
      className={clsx(
        "relative h-[592px] w-[275px]",
        isCurrentPhase && "!bg-white shadow-projectPhaseCard",
      )}
      onClick={onClick}
    >
      <div className="flex h-full flex-col justify-between p-[24px]">
        <div>
          <Paragraph3 className="text-neutral-70 transition-colors group-hover:text-neutral-90">
            {t("shared.WeekAbbreviation")}
            {week}
          </Paragraph3>
          <Display5 className="mt-[16px] text-black transition-colors group-hover:text-neutral-90">
            {formatDate(startDate, addDays(endOfDay(startDate), 6))}
          </Display5>
          {milestone && (
            <>
              <MilestoneIcon
                className="mt-[64px]"
                active
                size="small"
                variant={milestone.milestoneType || MilestoneTypeEnum.Aha}
              />
              <Overline className="mt-[16px]">
                {t(
                  `milestone.${
                    milestone.milestoneType || MilestoneTypeEnum.Aha
                  }`,
                )}
              </Overline>
              <Display4 className="mt-[16px] break-words">
                {milestone.name}
              </Display4>
            </>
          )}
        </div>
        <div className="flex flex-col gap-4">
          {highlights?.totalHighlights ? (
            <HighlightsBar
              handleClick={(event: React.SyntheticEvent) =>
                openHighlightsModal(event, highlights)
              }
              fullwidth={false}
              highlights={highlights as Highlights}
            />
          ) : undefined}
          {validPulseCheckAverage ? (
            <PulseCheckAverage
              start={+lowestAverage?.toFixed(2)}
              end={+highestAverage?.toFixed(2)}
              average={+average?.toFixed(2)}
              multiplier={10}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {isCurrentPhase ? (
        <div className="absolute left-1/2 -bottom-11 -translate-x-4">
          <DropIcon className="h-8 w-6 stroke-0 text-primary-turquoise-30 drop-shadow-md" />
          <Base2 className="mt-2 -translate-x-6 text-neutral-90">
            {t("shared.thisWeek")}
          </Base2>
        </div>
      ) : (
        <></>
      )}
    </WhiteCard>
  );
}

function PhaseSummaryCard({ phase }: { phase: DeepPartial<Phase> }) {
  const [addPhaseSummaryModalOpen, setAddPhaseSummaryModalOpen] =
    useState(false);
  const [editPhaseSummaryModalOpen, setEditPhaseSummaryModalOpen] =
    useState(false);
  const [fromEdit, setFromEdit] = useState(false);

  return (
    <>
      <button>
        <div
          className={clsx(
            "group relative mr-4 h-[436px] w-[275px] rounded-lg bg-tint-dark-10 px-6 text-left",
          )}
        >
          <div className="flex flex-col">
            <BracketsCurly
              size={44}
              color="#EB8424"
              weight="thin"
              className="absolute right-4 m-4 mt-8 mb-11 text-secondary-orange-70 "
            />
            <div className="mt-32 w-full truncate rounded-2xl p-4">
              <Avatar
                className="mb-2 min-w-[32px]"
                size="small"
                user={phase?.phaseSummary?.createdBy}
              />
              <div className="flex flex-col overflow-hidden">
                <Base2 className="mb-6 truncate">
                  {t("shared.phaseSummaryTitle", {
                    firstName: phase?.phaseSummary?.createdBy?.firstName,
                  })}
                </Base2>
                <Paragraph3 className="h-[100px] truncate text-neutral-70">
                  {phase?.phaseSummary?.description}
                </Paragraph3>
              </div>
            </div>
          </div>
          <Button
            variant="outline"
            onClick={() => setEditPhaseSummaryModalOpen(true)}
            className="bottom-5 focus:!outline-0 focus:!ring-0 focus:!ring-offset-0"
          >
            {t("shared.readMore")}
          </Button>
        </div>
      </button>
      <AddPhaseSummaryModal
        isOpen={addPhaseSummaryModalOpen}
        closeModal={() => setAddPhaseSummaryModalOpen(false)}
        phase={phase}
        toggleAddModal={() => setAddPhaseSummaryModalOpen(true)}
        fromEdit={fromEdit}
        toggleEditPhaseSummaryModal={() => setEditPhaseSummaryModalOpen(false)}
      />
      <EditPhaseSummaryModal
        isOpen={editPhaseSummaryModalOpen}
        closeModal={() => setEditPhaseSummaryModalOpen(false)}
        phase={phase}
        toggleEditModal={() => {
          setFromEdit(true);
          setAddPhaseSummaryModalOpen(true);
        }}
      />
    </>
  );
}

export default function PhaseViewBody({
  project,
}: {
  project: ProjectByIdQuery["projectById"];
}) {
  const [phasePosition, setPhasePosition] = useState(0);
  const [isPhaseGoalModalOpen, setIsPhaseGoalModalOpen] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState<Date>(
    parseISO(new Date().toISOString()),
  );

  const { currentPhase: phase, updatePhaseAndWeek } = useProjectNavigator();
  const [highlightsDialog, setHighlightsDialog] = useState<Partial<Highlights>>(
    {},
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!phase) return;

    setPhasePosition(phase.position);
  }, [phase]);

  const phases = project.phases;
  const startDate = parseISO(phases[phasePosition].startDate || "");
  const endDate = endOfDay(parseISO(phases[phasePosition].endDate || ""));
  const handleOnClickBackButton = () => {
    navigate({ search: "?level=project" });
  };
  const [activeWeek, setActiveWeek] = useState<number>(0);

  const handleOnClickPhaseWeek = (weekNumber: number) => {
    const phase = phases[phasePosition] as PhaseType;

    if (phase) {
      updatePhaseAndWeek({ phase, weekNumber });
      navigate({ search: "?level=Timeline" });
    }
  };

  const handleOpenPhaseGoalModal = () => setIsPhaseGoalModalOpen(true);

  const highlightsClickHandler = (
    startDate: Date,
    highlights: Partial<Highlights>,
  ) => {
    setSelectedWeek(startDate);
    setHighlightsDialog(highlights);
  };

  useEffect(() => {
    const tooltipEl = document.querySelector("#chartjs-tooltip");

    if (tooltipEl) {
      tooltipEl.remove();
    }
  }, []);

  return (
    <>
      <Fragment>
        <div className="flex flex-1 items-center overflow-x-auto">
          <div className="flex">
            <div className="relative ml-26 mr-4 flex h-[490px] w-[326px] flex-col justify-center pr-12">
              <Heading3Strong className="text-neutral-90">
                {t("shared.phaseGoal")}
                <span className="ml-2 font-normal">
                  {formatDate(startDate, endDate)}
                </span>
              </Heading3Strong>
              <Display4 className="mt-4 text-neutral-90 line-clamp-4">
                {phases[phasePosition]?.goal}
              </Display4>
              <div className="mt-8">
                <Button
                  onClick={handleOpenPhaseGoalModal}
                  variant="outline"
                  className="text-neutral-90 focus:!outline-0 focus:!ring-0 focus:!ring-offset-0"
                >
                  {t("shared.readMore")}
                </Button>
              </div>
              <div className="mt-12 flex text-neutral-70">
                <div>
                  <Overline className="py-2">
                    {t("shared.phaseLength")}
                  </Overline>
                  <Base2>
                    {phases[phasePosition].weeksLength}{" "}
                    {phases[phasePosition].weeksLength > 1
                      ? t("shared.weeks")
                      : t("shared.week")}
                  </Base2>
                </div>
              </div>
            </div>
            {[
              ...Array.from({
                length: phases[phasePosition].weeksLength,
              }).keys(),
            ].map((index) => {
              return phases[phasePosition]?.phaseSummary &&
                index === phases[phasePosition].weeksLength - 1 ? (
                <>
                  <PhaseCard
                    key={index}
                    week={index + 1}
                    phaseId={phases[phasePosition]?.id}
                    startDate={addWeeks(
                      parseISO(phases[phasePosition]?.startDate || ""),
                      index,
                    )}
                    highlights={filterHighlightsByWeeks(
                      phases[phasePosition]?.phaseHighlights as Highlights,
                      moment(phases[phasePosition]?.startDate)
                        .add(index, "w")
                        .week(),
                    )}
                    openHighlightsModal={(
                      event: React.SyntheticEvent,
                      hightlights: Partial<Highlights>,
                    ) => {
                      // event.stopPropagation();
                      highlightsClickHandler(
                        parseISO(
                          moment(phases[phasePosition]?.startDate)
                            .add(index, "w")
                            .toISOString(),
                        ),
                        hightlights,
                      );
                    }}
                    milestone={phases[phasePosition]?.milestones?.find(
                      (milestone) => milestone.weekNumber === index,
                    )}
                    onClick={() => handleOnClickPhaseWeek(index)}
                    setActiveWeek={setActiveWeek}
                  />
                  <PhaseSummaryCard phase={phases[phasePosition]} />
                </>
              ) : (
                <PhaseCard
                  key={index}
                  week={index + 1}
                  phaseId={phases[phasePosition]?.id}
                  highlights={filterHighlightsByWeeks(
                    phases[phasePosition]?.phaseHighlights as Highlights,
                    moment(phases[phasePosition]?.startDate)
                      .add(index, "w")
                      .week(),
                  )}
                  openHighlightsModal={(
                    event: React.SyntheticEvent,
                    hightlights: Partial<Highlights>,
                  ) => {
                    // event.stopPropagation();

                    highlightsClickHandler(
                      parseISO(
                        moment(phases[phasePosition]?.startDate)
                          .add(index, "w")
                          .toISOString(),
                      ),
                      hightlights,
                    );
                  }}
                  startDate={addWeeks(
                    parseISO(phases[phasePosition]?.startDate || ""),
                    index,
                  )}
                  milestone={phases[phasePosition]?.milestones?.find(
                    (milestone) => milestone.weekNumber === index,
                  )}
                  onClick={() => handleOnClickPhaseWeek(index)}
                  setActiveWeek={setActiveWeek}
                />
              );
            })}
            {isPhaseGoalModalOpen && (
              <PhaseGoalModal
                isOpen={isPhaseGoalModalOpen}
                closeModal={() => setIsPhaseGoalModalOpen(false)}
                phase={phase}
                activeWeek={activeWeek}
              />
            )}
          </div>
        </div>
        <div className="absolute left-8 bottom-16">
          <MiniTimeline phases={phases} totalWidth={345} />
        </div>
      </Fragment>

      {!!highlightsDialog?.totalHighlights && (
        <HighlightsModal
          titleHeader={formatDate(
            selectedWeek,
            addDays(endOfDay(selectedWeek), 6),
          )}
          onCancel={() => setHighlightsDialog({})}
          highlights={highlightsDialog}
          isOpen={!!highlightsDialog?.totalHighlights}
          setIsOpen={() => setHighlightsDialog({})}
        />
      )}
    </>
  );
}
