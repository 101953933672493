import { noop } from "lodash";
import React from "react";

import {
  UserSettingsAccountContent,
  UserSettingsInsightsContent,
  UserSettingsProfileContent,
} from "./components";
import { UserSettingsMenuTabs } from "./UserSettingsMenu";

type SettingsMenuUserSettingsMenuContentProps = {
  tab: UserSettingsMenuTabs;
  onClose?: () => void;
};

function UserSettingsMenuContent({
  tab,
  onClose = noop,
}: SettingsMenuUserSettingsMenuContentProps) {
  switch (tab) {
    case UserSettingsMenuTabs.insights:
      return <UserSettingsInsightsContent onClose={onClose} />;
    case UserSettingsMenuTabs.profile:
      return <UserSettingsProfileContent onClose={onClose} />;
    case UserSettingsMenuTabs.AccountSettings:
      return <UserSettingsAccountContent onClose={onClose} />;
    default:
      return <></>;
  }
}

export default UserSettingsMenuContent;
