import { ToggleControl } from "components/partial";
import { SlackPostSettings } from "graphql/generated";
import { t } from "i18n-js";
import { noop } from "lodash";
import { useProject } from "queries";
import React from "react";
import { useParams } from "react-router-dom";

type SlackControlProps = {
  onToggle?: (shouldPost: boolean) => void;
  interactionType: Exclude<keyof SlackPostSettings, "__typename">;
};

function SlackControl({ onToggle = noop, interactionType }: SlackControlProps) {
  const { projectId = "" } = useParams();
  const { project } = useProject({ id: projectId });
  const slackAuthorization = project?.slackAuthorization;

  const slackChannelMissing = !slackAuthorization?.channel?.id;
  const eventPostingDisabled =
    !project?.slackAuthorization?.postSettings?.[interactionType];

  if (slackChannelMissing || eventPostingDisabled) return <></>;

  return (
    <ToggleControl
      data-testid="slack-control"
      title={t(`integrations.slack.post.${interactionType}`)}
      onToggle={onToggle}
      description={t("integrations.slack.postDescription", {
        channel: `${slackAuthorization.workspaceName}/${
          slackAuthorization.channel?.name || ""
        }`,
      })}
    />
  );
}

export default SlackControl;
