import React from "react";

function NotesIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.875 10.0008C6.875 9.65563 7.15482 9.37581 7.5 9.37581H12.5C12.8452 9.37581 13.125 9.65563 13.125 10.0008C13.125 10.346 12.8452 10.6258 12.5 10.6258H7.5C7.15482 10.6258 6.875 10.346 6.875 10.0008Z"
        fill={props.fill || "#35363E"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.875 12.5008C6.875 12.1556 7.15482 11.8758 7.5 11.8758H12.5C12.8452 11.8758 13.125 12.1556 13.125 12.5008C13.125 12.846 12.8452 13.1258 12.5 13.1258H7.5C7.15482 13.1258 6.875 12.846 6.875 12.5008Z"
        fill={props.fill || "#35363E"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.49112 2.86693C3.72554 2.63251 4.04348 2.50081 4.375 2.50081H15.625C15.9565 2.50081 16.2745 2.6325 16.5089 2.86693C16.7433 3.10135 16.875 3.41929 16.875 3.75081V15.6258C16.875 16.2888 16.6116 16.9247 16.1428 17.3936C15.6739 17.8624 15.038 18.1258 14.375 18.1258H5.625C4.96196 18.1258 4.32607 17.8624 3.85723 17.3936C3.38839 16.9247 3.125 16.2888 3.125 15.6258V3.75081C3.125 3.41929 3.2567 3.10135 3.49112 2.86693ZM15.625 3.75081H4.375L4.375 15.6258C4.375 15.9573 4.5067 16.2753 4.74112 16.5097C4.97554 16.7441 5.29348 16.8758 5.625 16.8758H14.375C14.7065 16.8758 15.0245 16.7441 15.2589 16.5097C15.4933 16.2753 15.625 15.9573 15.625 15.6258L15.625 3.75081Z"
        fill={props.fill || "#35363E"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 1.25081C6.59518 1.25081 6.875 1.53063 6.875 1.87581V4.37581C6.875 4.72099 6.59518 5.00081 6.25 5.00081C5.90482 5.00081 5.625 4.72099 5.625 4.37581V1.87581C5.625 1.53063 5.90482 1.25081 6.25 1.25081Z"
        fill={props.fill || "#35363E"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.25081C10.3452 1.25081 10.625 1.53063 10.625 1.87581V4.37581C10.625 4.72099 10.3452 5.00081 10 5.00081C9.65482 5.00081 9.375 4.72099 9.375 4.37581V1.87581C9.375 1.53063 9.65482 1.25081 10 1.25081Z"
        fill={props.fill || "#35363E"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 1.25081C14.0952 1.25081 14.375 1.53063 14.375 1.87581V4.37581C14.375 4.72099 14.0952 5.00081 13.75 5.00081C13.4048 5.00081 13.125 4.72099 13.125 4.37581V1.87581C13.125 1.53063 13.4048 1.25081 13.75 1.25081Z"
        fill={props.fill || "#35363E"}
      />
    </svg>
  );
}
export default NotesIcon;
