import { Display4, Overline } from "components";
import { MilestoneIcon } from "components/base";
import { FilterButton } from "components/partial";
import { useProjectNavigator } from "contexts";
import { addWeeks, parseISO } from "date-fns";
import { Milestone, Project } from "graphql/generated";
import useProjectAccessLevel from "hooks/use-project-access-level";
import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { DraftsButton } from "../Project/components";
import { defaultFilters, IDictionary } from "./utils";
import WeekFeed from "./WeekFeed";

export interface NewFilters {
  [Key: string]: boolean | IDictionary<boolean>;
}

export default function WeekViewBody({ project }: { project?: Project }) {
  const [milestone, setMilestone] = useState<Partial<Milestone>>();
  const [appliedFilters, setAppliedFilters] = useState(defaultFilters);

  const { currentPhase: phase, currentWeek } = useProjectNavigator();
  const { projectId = "" } = useParams();
  const { hasAdminAccess, hasMemberAccess } = useProjectAccessLevel({
    projectId,
  });

  useEffect(() => {
    if (phase?.milestones) {
      const newMilestone = phase.milestones.find(
        (milestone) => milestone.weekNumber === currentWeek,
      );
      setMilestone(newMilestone);
    }
  }, [phase, currentWeek, setMilestone]);

  useEffect(() => {
    const tooltipEl = document.querySelector("#chartjs-tooltip");

    if (tooltipEl) {
      tooltipEl.remove();
    }
  }, []);

  return (
    <div className="mt-40 flex h-[calc(100vh-160px)] justify-between overflow-x-hidden bg-week-view-content-gradient py-7 px-16">
      <div className="mt-14 w-[300px]">
        {milestone?.milestoneType && (
          <>
            <MilestoneIcon
              className="my-2"
              variant={milestone?.milestoneType}
              active={true}
              size="medium"
            />
            <Overline className="my-2 mt-6 text-neutral-70">
              This week's milestone
            </Overline>
            <Display4>{milestone.name}</Display4>
          </>
        )}
      </div>
      <div className="flex-1">
        <Suspense fallback={<></>}>
          {phase?.startDate && (
            <WeekFeed
              startDate={addWeeks(parseISO(phase.startDate), currentWeek)}
              appliedFilters={appliedFilters}
              project={project}
            />
          )}
        </Suspense>
      </div>
      <div className="w-[300px]" />

      {(hasAdminAccess || hasMemberAccess) && <DraftsButton />}
      <FilterButton
        setAppliedFilters={setAppliedFilters}
        appliedFilters={appliedFilters}
      />
    </div>
  );
}
