import { useAddCommentToTaskMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation AddCommentToTask($taskId: ID!, $comment: String!) {
    createTaskComment(taskId: $taskId, comment: $comment) {
      id
    }
  }
`;

function useAddCommentToTask() {
  const queryClient = useQueryClient();
  return useAddCommentToTaskMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries(["TaskComments"]);
      void queryClient.invalidateQueries(["subFilters"]);
    },
  });
}

export default useAddCommentToTask;
