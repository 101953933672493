import "./Orange.css";

import React from "react";
function SmallPieChartOrange() {
  return (
    <>
      <div className="absolute left-3 bottom-2 text-base font-semibold text-white">
        C
      </div>
      <div className="test orangepie-4">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
}
export default SmallPieChartOrange;
