import Tree, {
  ItemId,
  moveItemOnTree,
  mutateTree,
  RenderItemParams,
  TreeData,
  TreeDestinationPosition,
  TreeItem,
  TreeSourcePosition,
} from "@atlaskit/tree";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";

import DraggableItem from "./DraggableItem";
import { ActivityProps } from "./DraggableItem/DraggableItem.types";
import { DraggableListComponent } from "./DraggableList.types";

const createTree = (
  items: Record<ItemId, TreeItem & { sectionId: string }>,
  rootItems: ItemId[],
) => ({
  rootId: "root",
  items: {
    root: {
      id: "root",
      children: rootItems,
      hasChildren: true,
      isExpanded: true,
      isChildrenLoading: false,
      data: {
        title: "root",
      },
    },
    ...items,
  },
});

const DraggableList: DraggableListComponent = ({
  items,
  rootItems,
  onItemDelete,
  onItemSelect,
  selectedItemId,
  onItemMove,
  onAddToSection,
}) => {
  const [tree, setTree] = useState<TreeData>(createTree(items, rootItems));
  const [combine, setCombine] = useState(true);
  const [draggingType, setDraggingType] = useState<
    "section" | "Item" | undefined
  >(undefined);

  useEffect(() => {
    const newItems = { ...items };

    // maintains the client side expanded state of the sections
    for (const key of Object.keys(items)) {
      newItems[key].isExpanded =
        tree.items[key]?.isExpanded === undefined
          ? true
          : tree.items[key]?.isExpanded;
    }

    setTree(
      mutateTree(createTree(newItems, rootItems), "root", { isExpanded: true }),
    );
  }, [items]);

  const getSectionTime = (sectionId: ItemId) => {
    const sectionChildren = tree.items[sectionId].children;
    let sectionTime = 0;

    for (const childId of sectionChildren) {
      const childData = tree.items[childId].data as ActivityProps;
      sectionTime += childData.time || 0;
    }

    return sectionTime;
  };

  const renderItem = ({
    item,
    onExpand: itemOnExpand,
    onCollapse: itemOnCollapse,
    provided,
    depth,
    snapshot,
  }: RenderItemParams) => {
    const toggleExpanded = () => {
      if (item?.isExpanded) {
        itemOnCollapse(item.id);
      } else {
        itemOnExpand(item.id);
      }
    };

    const data = item.data as ActivityProps;

    // sets isNestingEnabled to false if the item is a section so that you can only combine items with other items not in a section
    const onHover = () => {
      if (draggingType !== undefined) {
        const newCombine = data.type === "section" && draggingType === "Item";
        combine !== newCombine && setCombine(newCombine);
      }
    };

    const handleItemDelete = () => {
      const newItems = { ...items };
      const newRootItems = [...rootItems].filter((id) => id !== item.id);

      const itemSectionId = (item as TreeItem & { sectionId: string })
        .sectionId;
      const sectionId = itemSectionId && `Section-${itemSectionId}`;

      delete newItems[item.id];

      if (sectionId) {
        const newChildren = newItems[sectionId].children.filter(
          (id) => id !== item.id,
        );

        newItems[sectionId].children = newChildren;
        newItems[sectionId].hasChildren = newChildren.length > 0;
      }

      if (item.children.length > 0) {
        for (const childId of item.children) {
          delete newItems[childId];
        }
      }

      setTree(
        mutateTree(createTree(newItems, newRootItems), "root", {
          isExpanded: true,
        }),
      );

      onItemDelete(item.id);
    };

    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        onMouseEnter={onHover}
      >
        <div
          className={clsx(
            {
              "border-l-2 border-transparent !pl-5 transition-[border-color]":
                depth > 0,
            },
            {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              "border-neutral-5": !snapshot.isDragging && depth > 0,
            },
          )}
        >
          <DraggableItem
            content={data}
            children={item.children}
            getTime={getSectionTime}
            id={item.id}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            handleProps={provided.dragHandleProps}
            isExpanded={item.isExpanded}
            toggleExpanded={toggleExpanded}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            isDragging={snapshot.isDragging}
            onDelete={handleItemDelete}
            onClick={() => onItemSelect(item.id)}
            isActive={selectedItemId === item.id}
          />
        </div>
      </div>
    );
  };

  const onExpand = (itemId: ItemId) => {
    setTree(mutateTree(tree, itemId, { isExpanded: true }));
  };

  const onCollapse = (itemId: ItemId) => {
    setTree(mutateTree(tree, itemId, { isExpanded: false }));
  };

  const onDragEnd = (
    source: TreeSourcePosition,
    destination?: TreeDestinationPosition,
  ) => {
    setDraggingType(undefined);

    if (!destination) {
      return;
    }

    const sourceId = tree.items[source.parentId].children[source.index];

    if (destination.parentId !== tree.rootId) {
      const sourceData = tree.items[sourceId].data as ActivityProps;

      // this cancels the drop if you try to drop a section into a section
      if (sourceData.type === "section") {
        return;
      }

      // The user is dropping one activity on another so we create a new section
      // Disabled for now because the API does not support it.

      /*const parentData = tree.items[destination.parentId].data as ActivityProps;

      if (parentData.type !== "section") {
        const newTree = tree;
        const parentIndex = newTree.items[tree.rootId].children.indexOf(
          destination.parentId,
        );

        newTree.items[source.parentId].children.splice(parentIndex, 1);
        newTree.items[source.parentId].children.splice(source.index, 1);

        setTree(newTree);
        setTree(tree);

        onSectionCreate([sourceId, destination.parentId], "New Section");

        const newSectionId = `section-${destination.parentId}`;
        newTree.items[newSectionId] = {
          id: newSectionId,
          children: [destination.parentId, sourceId],
          hasChildren: true,
          isExpanded: true,
          isChildrenLoading: false,
          data: {
            title: "New Section", // TODO: make this editable
            type: "section",
          },
        };

        const parentIndex = newTree.items[tree.rootId].children.indexOf(
          destination.parentId,
        );
        newTree.items[tree.rootId].children[parentIndex] = newSectionId;

        return;
      }*/

      // moving items inside a section
      if (
        source.parentId === destination.parentId &&
        _.isNumber(destination.index)
      ) {
        const newChildren = [...tree.items[source.parentId].children];

        newChildren.splice(source.index, 1);
        newChildren.splice(destination.index, 0, sourceId);

        onItemMove(newChildren, destination.index);
      }
      // moving items into a section
      else {
        onAddToSection(sourceId, destination.parentId);
      }
    } else if (_.isNumber(destination.index)) {
      // if an item is dragged into root
      const newChildren = [...tree.items[tree.rootId].children];

      if (source.parentId === tree.rootId) {
        newChildren.splice(source.index, 1);
      }
      newChildren.splice(destination.index, 0, sourceId);

      onItemMove(
        newChildren,
        source.parentId !== tree.rootId ? sourceId : undefined,
      );
    }

    setTree(moveItemOnTree(tree, source, destination));
  };

  const onDragStart = (id: ItemId) => {
    const itemData = tree.items[id].data as ActivityProps;

    setDraggingType(itemData.type === "section" ? "section" : "Item");
  };

  return (
    <div className="child:h-full h-full">
      <Tree
        tree={tree}
        renderItem={renderItem}
        onExpand={onExpand}
        onCollapse={onCollapse}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        isNestingEnabled={combine}
        isDragEnabled
        offsetPerLevel={11}
      />
    </div>
  );
};

export default DraggableList;
