import { Button } from "components/base";
import { HStack, VStack } from "components/layout";
import { Modal } from "components/partial";
import { Heading1Strong, Paragraph1 } from "components/Typography";
import { t } from "i18n-js";
import { X } from "phosphor-react";
import React from "react";

interface CollabMetricsInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CollaborationMetricsInfoModal = ({
  isOpen,
  onClose,
}: CollabMetricsInfoModalProps) => {
  const collaborationMatricsInfoModal =
    "component.userSettings.tabs.insights.sections.collaborationMetrics.infoModal";
  return (
    <Modal
      className="no-scrollbar w-[940px] p-10"
      open={isOpen}
      onClose={onClose}
    >
      <HStack align="center" justify="between" className="mb-[25px]">
        <VStack space={2}>
          <Heading1Strong className="text-neutral-900">
            {t(`${collaborationMatricsInfoModal}.title`)}
          </Heading1Strong>
        </VStack>
        <Button
          variant="tertiary"
          color="#35363E"
          RightIcon={() => <X size={22} />}
          className="h-[48px] w-[48px]"
          onClick={onClose}
        />
      </HStack>
      <div>
        <Paragraph1 className="mb-4 text-neutral-900">
          {t(`${collaborationMatricsInfoModal}.description`)}
        </Paragraph1>
      </div>
    </Modal>
  );
};

export default CollaborationMetricsInfoModal;
