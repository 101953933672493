import { useUpdateAnnouncementMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation UpdateAnnouncement($input: UpdateAnnouncementInput!) {
    updateAnnouncement(input: $input) {
      id
      announcementTitle
      description
    }
  }
`;

function useUpdateAnnouncement() {
  const queryClient = useQueryClient();
  return useUpdateAnnouncementMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries(["subFilters"]);
      void queryClient.refetchQueries(["subFilters"]);
      void queryClient.refetchQueries(["Notifications"]);
      void queryClient.refetchQueries(["ProjectById"]);
    },
  });
}

export default useUpdateAnnouncement;
