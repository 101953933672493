import { Avatar } from "components/base";
import { Base2, Caption1 } from "components/Typography";
import {
  WorkshopParticipant,
  WorkshopParticipantRole,
} from "graphql/generated";
import { t } from "i18n-js";
import React from "react";

export interface ParticipantCardProps {
  participant: WorkshopParticipant;
}
export const ParticipantCard = ({ participant }: ParticipantCardProps) => (
  <div className="flex gap-2 rounded-md bg-tint-dark-100 px-2 py-3">
    <Avatar user={participant.user} size="small" />
    <div className="flex flex-col">
      <Base2>{participant.user.fullName}</Base2>
      <Caption1>
        {t(
          `workshopEditor.managePeople.${
            participant.role || WorkshopParticipantRole.Participant
          }`,
        )}
      </Caption1>
    </div>
  </div>
);
