/* eslint-disable graphql/template-strings */
import { gql } from "@apollo/client";
import { WorkshopStateSubscription } from "graphql/generated";
import { useQueryClient } from "react-query";

import { useSubscription } from "./use-subscription";

export const WORKSHOP_STATE_SUBSCRIPTION = gql`
  subscription WorkshopState($workshopId: ID!) {
    workshopStateUpdated(workshopId: $workshopId) {
      workshop {
        id
        state
        liveNow
      }
    }
  }
`;

export interface UseWorkshopStateSubscriptionProps {
  workshopId: string;
}

export const useWorkshopStateSubscription = ({
  workshopId,
}: UseWorkshopStateSubscriptionProps) => {
  const queryClient = useQueryClient();

  const output = useSubscription<WorkshopStateSubscription>(
    WORKSHOP_STATE_SUBSCRIPTION,
    {
      variables: {
        workshopId: workshopId,
      },
      async onData() {
        await queryClient.invalidateQueries("WorkshopById");
      },
    },
  );

  return output;
};
