import { Workshop } from "graphql/generated";
import { getCurrentWorkshopActivity } from "utils";

import { badgeVariant } from "./SignalDropdown.types";

export const mapEmotionalSignals = (signals: Workshop["emotionalSignals"]) => {
  return signals.map(({ emojiName, timeStamp }) => {
    let signal: badgeVariant | undefined;

    switch (emojiName) {
      case "star":
        signal = "lightbulb";
        break;
      case "heart":
        signal = "love";
        break;
      case "question":
        signal = "question";
        break;
      case "eye":
        signal = "break";
        break;
    }

    const time = timeStamp ? new Date(timeStamp).getTime() : undefined;

    return {
      signal: signal as badgeVariant,
      time,
    };
  });
};

export const getTimeSinceString = (time: number) => {
  const now = Date.now();
  const diff = now - time;

  let minutes = Math.floor(diff / 1000 / 60);

  const hours = Math.floor(minutes / 60);

  if (hours > 0) {
    minutes = minutes - hours * 60;
  }

  return minutes === 0 && hours === 0
    ? "now"
    : `${hours > 0 ? `${hours}hr ` : ""}${minutes}min`;
};

export const getRaisedHands = (
  participants: Workshop["workshopParticipants"],
  workshop: Workshop,
) => {
  const raisedHands = participants.filter((p) => p.raiseHand);
  const currentActivity = getCurrentWorkshopActivity(workshop?.agenda);
  const dailyRoomIds =
    currentActivity?.activity.__typename === "Breakout"
      ? currentActivity.activity.dailyRooms.map((item) => item.id)
      : [];

  raisedHands.sort((a, b) => {
    const aTime = new Date(a.updatedAt).getTime();
    const bTime = new Date(b.updatedAt).getTime();

    return aTime - bTime;
  });

  return raisedHands.map((p) => ({
    name: p.user.fullName,
    breakoutRoom: dailyRoomIds.find((id) =>
      p.breakoutRoomTokens?.some((token) => token.dailyRoom.id === id),
    ),
  }));
};
