import { EmbeddedLinks } from "components/CreateInteractions/CreateAnnouncementForm";

export const EMAIL_REGEX =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;

// Minimum 6 characters, at least one uppercase letter, one lowercase letter, one number, and one special character:
// https://stackoverflow.com/a/21456918
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&'()*+,.:;<=>?@^|-])[\w!#$%&'()*+,.:;<=>?@^|-]{10,}$/;

// STANDARD URL REGEX format
export const URL_REGEX =
  /[\w#%()+./:=?@~]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)/g;

// URL REGEX beginning with hypertext protocol
export const URL_REGEX_WITH_PROTOCOL = /^https?:\/\/.+$/i;

export const URL_REGEX_EXTENDED =
  /^https?:\/\/(?:www\.)?[\w#%+.:=@~-]{1,256}\.[\d()A-Za-z]{1,6}\b[\w#%&()+./:=?@~-]*$/;

export const validateUrl = (url: string) =>
  Boolean(url.match(URL_REGEX)?.length);

export const validateUrlWithProtocol = (url: string) =>
  Boolean(url.match(URL_REGEX_WITH_PROTOCOL)?.length);

export const whetherLinkExists = (list: EmbeddedLinks[], url: string) => {
  const newList = list.map((item) => item.url);
  return newList.indexOf(url);
};

export const VALIDATE_STRING_REGEX = /^(?!\s).*$/;

export const GOOGLE_DOCS_REGEX =
  /https:\/\/docs\.google\.com\/document\/d\/(.*?)\/pub/;

export const UNPUBLISHED_GOOGLE_DOCS_REGEX =
  /https:\/\/docs\.google\.com\/document\/d\/(.*?)/;

export const MIRO_REGEX = /https:\/\/miro\.com\/app\/board\/(.*?)/;

export const GOOGLE_DOCS_MIRO_REGEX =
  /(https:\/\/docs\.google\.com\/document\/d\/(.*?)\/pub)|(https:\/\/miro\.com\/app\/board\/(.*?))/;

export const AWS_S3_REGEX = /coeuraj-staging-files.s3.amazonaws.com/g;

export const FILE_TRIM = /\.[^./]+$/;

export const VALIDATE_PARAGRAPH_REGEX = /[^\n\r]+((\r|\n|\r\n)[^\n\r]+)*/;
