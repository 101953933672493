import { Avatar } from "components/base";
import { HStack, VStack } from "components/layout/Stack";
import DropdownSelector from "components/partial/DropdownSelector";
import { Base2, Caption1 } from "components/Typography";
import { User, WorkshopParticipantRole } from "graphql/generated";
import { t } from "i18n-js";
import { useUpdateWorkshopParticipant } from "mutations/workshop";
import useDeleteWorkshopParticipant from "mutations/workshop/use-delete-workshop-participant";
import { CaretDown } from "phosphor-react";
import React from "react";

interface ParticipantRoleProps {
  user: User;
  role: string;
  participantId: string;
}

const ParticipantRole = ({
  user,
  role,
  participantId,
}: ParticipantRoleProps) => {
  const { mutateAsync: updateParticipantRole } = useUpdateWorkshopParticipant();
  const { mutateAsync: deleteParticipant } = useDeleteWorkshopParticipant();

  const deleteWorkshopMember = async () => {
    try {
      await deleteParticipant({ id: participantId });
    } catch {
      throw new Error(t("errors.somethingWentWrong"));
    }
  };

  const updateWorkshopMemberRole = async (
    updatedRole: WorkshopParticipantRole,
  ) => {
    try {
      await updateParticipantRole({
        updateWorkshopParticipantInput: {
          role: updatedRole,
          id: participantId,
        },
      });
    } catch {
      throw new Error(t("errors.somethingWentWrong"));
    }
  };

  return (
    <HStack justify="between" align="center" className="mb-2 py-4">
      <HStack space={2}>
        <Avatar user={user} />
        <VStack align="start">
          <Base2>{user.fullName}</Base2>
          <Caption1 className="pt-1 text-neutral-50">
            {user.defaultProjectRole}
          </Caption1>
        </VStack>
      </HStack>
      <DropdownSelector
        dropdownType="WORKSHOP_LEVELS"
        onDelete={() => void deleteWorkshopMember()}
        currentLevel={role as WorkshopParticipantRole}
        onSelect={(level) =>
          void updateWorkshopMemberRole(level as WorkshopParticipantRole)
        }
        key={user.id}
        canAssignAdmin={false}
        buttonContent={
          <HStack align="center" space={2}>
            <Base2>{t(`workshopEditor.managePeople.${role}`)}</Base2>
            <CaretDown />
          </HStack>
        }
      />
    </HStack>
  );
};

export default ParticipantRole;
