import { useCreateWorkshopMutation } from "graphql/generated";
import { gql } from "graphql-request";

gql`
  mutation CreateWorkshop($workshop: CreateWorkshopInput!) {
    createWorkshop(workshop: $workshop) {
      id
    }
  }
`;

export function useCreateWorkshop() {
  return useCreateWorkshopMutation();
}
