import {
  QuestionResponseForUserQuery,
  QuestionResponseForUserQueryVariables,
  useQuestionResponseForUserQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query QuestionResponseForUser($projectId: ID!, $weekEndDate: ISO8601Date!) {
    questionResponseForUser(projectId: $projectId, weekEndDate: $weekEndDate) {
      id
      note
      author {
        id
      }
      answerChoice {
        id
        text
      }
      question {
        id
        title
      }
    }
  }
`;

function useQuestionResponseForUser(
  variables: QuestionResponseForUserQueryVariables,
): {
  questionResponseForUser: QuestionResponseForUserQuery["questionResponseForUser"];
} & UseQueryResult<QuestionResponseForUserQuery, unknown> {
  const response = useQuestionResponseForUserQuery(variables);

  return {
    questionResponseForUser: response?.data?.questionResponseForUser,
    ...response,
  };
}

export { useQuestionResponseForUser };
