import { useUpdateProjectMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation UpdateProject($project: UpdateProjectInput!) {
    updateProject(project: $project) {
      id
      goal
      name
      links {
        url
        title
        extension
        preview
      }
      startDate
      phases {
        id
        name
        goal
        position
        weeksLength
        milestones {
          id
          milestoneType
          name
          weekNumber
        }
      }
    }
  }
`;

function useUpdateProject() {
  const queryClient = useQueryClient();
  return useUpdateProjectMutation({
    onSuccess: () => queryClient.invalidateQueries(["ProjectById"]),
  });
}

export default useUpdateProject;
