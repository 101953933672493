import clsx from "clsx";
import { Avatar, Tooltip } from "components/base";
import { HStack, VStack } from "components/layout";
import { Caption2, Overline } from "components/Typography";
import { User } from "graphql/generated";
import { Info, UsersFour } from "phosphor-react";
import { useCurrentUser } from "queries";
import React, { useEffect, useRef } from "react";
import { Z_INDEX_LEVELS } from "utils";

interface CollabMetricProps {
  title: string;
  infoText: string;
  rightLabel: string;
  leftLabel: string;
  userAverage: number;
  teamAverage?: number;
  minAverage?: number;
  maxAverage?: number;
  className?: string;
}

const CollabMetric = ({
  title,
  infoText,
  rightLabel,
  leftLabel,
  userAverage,
  teamAverage = 0,
  minAverage = 0,
  maxAverage = 0,
  className = "",
}: CollabMetricProps) => {
  const groupIconRef = useRef<HTMLDivElement | null>(null);
  const avatarRef = useRef<HTMLDivElement | null>(null);
  const innerBarRef = useRef<HTMLDivElement | null>(null);
  const { currentUser } = useCurrentUser();

  const TooltipContent = () => (
    <div className="w-[300px] rounded-lg bg-neutral-900 text-white">
      <Caption2 className="mb-2">{title}</Caption2>
      <Caption2>{infoText}</Caption2>
    </div>
  );

  useEffect(() => {
    const avatarEl = avatarRef.current;
    const groupIconEl = groupIconRef.current;
    const innerBarEl = innerBarRef.current;

    if (avatarEl) {
      avatarEl.style.left = `${Math.floor(userAverage)}%`;
    }

    if (groupIconEl && teamAverage > 0) {
      groupIconEl.style.left = `${Math.floor(teamAverage)}%`;
    }

    if (minAverage > 0 && maxAverage > 0 && innerBarEl) {
      const clampedMinPosition = Math.min(
        100 - Math.floor(maxAverage),
        Math.max(0, Math.floor(minAverage)),
      );
      const clampedWidth = Math.min(380, Math.floor(maxAverage));

      if (minAverage === maxAverage) {
        innerBarEl.style.left = `${Math.floor(minAverage)}%`;
      } else {
        innerBarEl.style.left = `${Math.floor(clampedMinPosition)}%`;
        innerBarEl.style.width = `${Math.floor(clampedWidth)}%`;
      }
    }
  }, [userAverage, teamAverage, minAverage, maxAverage]);

  return (
    <VStack className={className}>
      <HStack space={1} align="center" className="z-20 mb-8">
        <Overline className="text-neutral-600">{title}</Overline>
        <Tooltip content={TooltipContent} placement="right" offset={4}>
          <Info size={14} color="#959A9F" className="-translate-y-[1px]" />
        </Tooltip>
      </HStack>
      <div className="w-[380px]">
        <div className="relative h-[4px] rounded-lg bg-neutral-200">
          {/* Inner metric bar */}
          {minAverage > 0 && maxAverage > 0 && (
            <div
              className="absolute -top-[3px] h-[10px] rounded-lg bg-neutral-900"
              ref={innerBarRef}
            />
          )}
          <div
            className={clsx(
              "absolute -top-[14px] flex h-[40px] w-[40px] justify-center align-middle",
              Z_INDEX_LEVELS.BASE,
            )}
            ref={avatarRef}
          >
            <Avatar
              className="border-[2px] border-white shadow-md"
              size="small"
              user={currentUser as User}
            />
          </div>
          {teamAverage > 0 ? (
            <div
              className={clsx(
                "absolute -top-[14px] flex h-[34px] w-[34px] justify-center rounded-3xl border-[3px] border-white bg-neutral-900 shadow-md",
                Z_INDEX_LEVELS.BASE_CONTROL,
              )}
              ref={groupIconRef}
            >
              <UsersFour
                size={16}
                color="white"
                className="translate-y-[5px]"
              />
            </div>
          ) : undefined}
        </div>
        <HStack justify="between" align="center" className="mt-4">
          <Caption2 className="text-tint-dark-900">{leftLabel}</Caption2>
          <Caption2 className="text-tint-dark-900">{rightLabel}</Caption2>
        </HStack>
      </div>
    </VStack>
  );
};

export default CollabMetric;
