import Button from "components/base/Button/Button";
import { HStack, VStack } from "components/layout";
import UserSettings from "components/pages/UserSettings/UserSettings";
import { Display4 } from "components/Typography";
import { useModal } from "hooks";
import { useWorkshop } from "queries";
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

type WorkshopSummaryDataCardTemplateProps = {
  title: string;
  description: string;
  btnText: string;
  image?: string;
};

function WorkshopSummaryDataCardTemplate({
  title,
  description,
  btnText,
  image,
}: WorkshopSummaryDataCardTemplateProps) {
  const navigate = useNavigate();
  const { id = "" } = useParams();
  const { isLoading, isError, workshop } = useWorkshop({ id });
  const {
    isOpen: userModalIsOpen,
    openModal: openUserModal,
    closeModal: closeUserModal,
    Modal: UserSettingsModal,
  } = useModal();

  const topAnchorRef = useRef<HTMLDivElement>(null);
  const [workshopSummaryDataCardFlag] = useState<boolean>(true);

  function handleNavigation() {
    if (btnText === "See Team Insights" && workshop?.projectId) {
      navigate(`/projects/${workshop?.projectId}?level=team`);
    } else if (btnText === "See Personal Insights") {
      openUserModal();
    }
  }
  return (
    <VStack className="relative grid h-[194px] grid-cols-2 items-center gap-2 rounded-lg border border-neutral-50">
      <VStack className="w-[269px] p-2 pl-4" align="start">
        <Display4 className="!text-lg">{title}</Display4>
        <HStack className="text-sm text-neutral-700">{description}</HStack>
        <Button
          variant="outline"
          className="!mt-4 w-auto !whitespace-nowrap !text-xs"
          onClick={handleNavigation}
        >
          {btnText}
        </Button>
      </VStack>
      <VStack className="w-[calc(100% - 269px)] absolute right-0 m-auto">
        <img className="h-32 w-32" src={image}></img>
      </VStack>
      <UserSettingsModal
        className="h-full w-full"
        open={userModalIsOpen}
        isFullScreen
        onClose={closeUserModal}
        initialFocus={topAnchorRef}
      >
        <div ref={topAnchorRef}></div>
        <UserSettings
          tabSelect={workshopSummaryDataCardFlag}
          onClose={closeUserModal}
        />
      </UserSettingsModal>
    </VStack>
  );
}

export default WorkshopSummaryDataCardTemplate;
