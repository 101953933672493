import { useEffect } from "react";

import { VideoSize } from "../../Workshop.types";
import {
  ActivityType,
  LiveWorkshopActivityProps,
  WorkshopActivityType,
} from "./LiveWorkshopActivity.types";

/**
 * useManagedActivityVideoRatio takes the distilled ActivityType
 * and uses it to update the VideoSize so the screen's component size
 * ratio fits the given activity.
 */
export const useManagedActivityVideoRatio = (
  activityType: WorkshopActivityType,
  handleVideoCallSizeChange: LiveWorkshopActivityProps["handleVideoCallSizeChange"],
) => {
  useEffect(() => {
    switch (activityType) {
      case ActivityType.GoogleDoc:
      case ActivityType.Miro:
      case ActivityType.PDF:
        handleVideoCallSizeChange(VideoSize.Small);
        break;

      case ActivityType.Image:
      case ActivityType.Breakout:
      case ActivityType.Discussion:
      case ActivityType.Poll:
      default:
        handleVideoCallSizeChange(VideoSize.Large);
    }
  }, [activityType, handleVideoCallSizeChange]);
};
