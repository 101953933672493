import { Caption1 } from "components";
import React, { Fragment } from "react";

interface FormInputErrorsProps {
  className?: string;
  error?: string;
}

function FormInputErrors({
  className = "",
  error = "",
}: FormInputErrorsProps): JSX.Element {
  return error?.length > 0 ? (
    <Caption1 className={`my-4 text-secondary-red-70 ${className}`}>
      {error}
    </Caption1>
  ) : (
    <Fragment />
  );
}

export default FormInputErrors;
