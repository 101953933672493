import clsx from "clsx";
import { Base2Strong, Caption1, HStack, VStack } from "components";
import { noop } from "lodash";
import React from "react";

import ToggleButton from "./ToggleButton";

type ToggleControlProps = {
  title: string;
  description: string;
  onToggle?: (position: boolean) => void;
  startsOn?: boolean;
  className?: string;
};

function ToggleControl({
  title,
  description,
  onToggle = noop,
  startsOn = true,
  className = "",
  ...props
}: ToggleControlProps) {
  return (
    <HStack space={4} className={clsx("mt-4 mr-18", className)} {...props}>
      <ToggleButton onToggle={onToggle} startsOn={startsOn} />
      <VStack space={2}>
        <Base2Strong className="text-neutral-90">{title}</Base2Strong>
        <Caption1 className="text-neutral-70">{description}</Caption1>
      </VStack>
    </HStack>
  );
}

export default ToggleControl;
