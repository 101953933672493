import clsx from "clsx";
import { HStack } from "components";
import { User } from "graphql/generated";
import React, { useEffect, useState } from "react";

import { Base1Strong } from "../../Typography";

type UpdatedAvatarProps = {
  /**
   * A user
   */
  user?: Partial<User>;
  /**
   * Custom className
   */
  className?: string;
  /**
   * Custom className for text
   */
  textClassName?: string;
  /**
   * Avatar image size
   */
  size?: "mini" | "small" | "medium" | "large";
};

const getAvatarSize = (size?: UpdatedAvatarProps["size"]) => {
  switch (size) {
    case "mini":
      return "!h-6 !w-6";
    case "small":
      return "!h-8 !w-8";
    case "medium":
      return "h-10 w-10";
    case "large":
      return "h-[120px] w-[120px]";
    default:
      return "";
  }
};

export default function UpdatedAvatar({
  user,
  className,
  textClassName,
  size = "medium",
  ...props
}: UpdatedAvatarProps) {
  const [isUserImageValid, setIsUserImageValid] = useState<boolean>(true);

  const handleImageError = () => {
    setIsUserImageValid(false);
  };

  const handleImageLoad = () => {
    setIsUserImageValid(true);
  };

  const checkImageUrl = (imageUrl: string) => {
    const img = new Image();
    img.src = imageUrl;

    img.addEventListener("error", handleImageError);
    img.addEventListener("load", handleImageLoad);
  };

  useEffect(() => {
    if (user?.avatarUrl) checkImageUrl(user?.avatarUrl);
    return () => {
      if (user?.avatarUrl) {
        const img = new Image();
        img.src = user?.avatarUrl;

        img.removeEventListener("error", handleImageError);
        img.removeEventListener("load", handleImageLoad);
      }
    };
  }, [user?.avatarUrl]);

  return (
    <HStack
      className={clsx(
        "h-10 w-10 rounded-full bg-neutral-40 text-white",
        getAvatarSize(size),
        className,
      )}
      justify="center"
      align="center"
      {...props}
    >
      {user?.avatarUrl && isUserImageValid ? (
        <img
          aria-label={`${user?.fullName || ""}'s avatar`}
          className="h-10 w-10 rounded-full"
          src={user?.avatarUrl}
          onError={() => setIsUserImageValid(false)}
        />
      ) : (
        <Base1Strong className={clsx(textClassName)}>
          {user?.firstName?.[0] || ""}
          {user?.lastName?.[0] || ""}
        </Base1Strong>
      )}
    </HStack>
  );
}
