import "./Red.css";

import React from "react";
function SmallPieChartRed() {
  return (
    <>
      <div className="absolute right-4 top-1 text-base font-semibold text-white">
        I
      </div>
      <div className="test redpie-4">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
}
export default SmallPieChartRed;
