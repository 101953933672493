import React from "react";

function CheckinMediumActiveIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_46_1598)">
        <path
          d="M42.834 7.75073V32.2485C45.657 31.6719 48.1942 30.1378 50.0164 27.9058C51.8385 25.6738 52.8338 22.881 52.8338 19.9996C52.8338 17.1183 51.8385 14.3254 50.0164 12.0934C48.1942 9.86145 45.657 8.32738 42.834 7.75073Z"
          fill="#66BCD8"
        />
        <path
          d="M27.834 19.9997C27.8343 22.881 28.8297 25.6738 30.6519 27.9057C32.4741 30.1377 35.0112 31.6718 37.8342 32.2487V7.75073C35.0112 8.32761 32.4741 9.86174 30.6519 12.0937C28.8297 14.3256 27.8343 17.1184 27.834 19.9997Z"
          fill="#66BCD8"
        />
        <path
          d="M68.1631 10C68.4914 10 68.8165 9.93538 69.1198 9.80975C69.4232 9.68412 69.6988 9.49997 69.9309 9.26782C70.1631 9.03567 70.3472 8.76006 70.4728 8.45674C70.5985 8.15341 70.6631 7.82831 70.6631 7.5V2.5C70.6631 1.83696 70.3997 1.20107 69.9309 0.732233C69.462 0.263392 68.8261 0 68.1631 0C67.5 0 66.8642 0.263392 66.3953 0.732233C65.9265 1.20107 65.6631 1.83696 65.6631 2.5V7.5C65.6631 7.82831 65.7277 8.15341 65.8533 8.45674C65.979 8.76006 66.1631 9.03567 66.3953 9.26782C66.6274 9.49997 66.903 9.68412 67.2063 9.80975C67.5097 9.93538 67.8348 10 68.1631 10Z"
          fill="#35363E"
        />
        <path
          d="M12.4951 20.8569C12.8234 20.857 13.1485 20.7923 13.4519 20.6667C13.7552 20.5411 14.0308 20.3569 14.2629 20.1248C14.4951 19.8926 14.6792 19.617 14.8049 19.3137C14.9305 19.0103 14.9951 18.6852 14.9951 18.3569V2.5C14.9951 1.83696 14.7317 1.20107 14.2629 0.732233C13.794 0.263392 13.1582 0 12.4951 0C11.8321 0 11.1962 0.263392 10.7274 0.732233C10.2585 1.20107 9.99512 1.83696 9.99512 2.5V18.3569C9.99509 18.6852 10.0597 19.0103 10.1854 19.3137C10.311 19.617 10.4951 19.8926 10.7273 20.1248C10.9594 20.3569 11.2351 20.5411 11.5384 20.6667C11.8417 20.7923 12.1668 20.857 12.4951 20.8569Z"
          fill="#35363E"
        />
        <path
          d="M40.334 41.8569C40.6623 41.857 40.9874 41.7923 41.2907 41.6667C41.5941 41.541 41.8697 41.3569 42.1018 41.1248C42.334 40.8926 42.5181 40.617 42.6437 40.3137C42.7694 40.0103 42.834 39.6852 42.834 39.3569V2.5C42.834 1.83696 42.5706 1.20107 42.1018 0.732233C41.6329 0.263392 40.997 0 40.334 0C39.6709 0 39.0351 0.263392 38.5662 0.732233C38.0974 1.20107 37.834 1.83696 37.834 2.5V39.3569C37.834 39.6852 37.8986 40.0103 38.0242 40.3137C38.1499 40.617 38.334 40.8926 38.5662 41.1248C38.7983 41.3569 39.0739 41.541 39.3772 41.6667C39.6806 41.7923 40.0057 41.857 40.334 41.8569Z"
          fill="#35363E"
        />
        <path
          d="M4.3633 29.7256C2.90366 31.1799 1.86237 33.0004 1.34872 34.9958C0.835072 36.9913 0.867992 39.0882 1.44403 41.0666C2.02006 43.0449 3.11797 44.8318 4.62255 46.2396C6.12713 47.6474 7.98291 48.6243 9.99513 49.0677V77.3569C9.99513 78.02 10.2585 78.6559 10.7274 79.1247C11.1962 79.5935 11.8321 79.8569 12.4951 79.8569C13.1582 79.8569 13.7941 79.5935 14.2629 79.1247C14.7317 78.6559 14.9951 78.02 14.9951 77.3569V49.0677C16.7363 48.6797 18.3633 47.8917 19.7473 46.7661C21.1313 45.6405 22.2342 44.2082 22.9689 42.5826C23.7036 40.957 24.0498 39.1827 23.9801 37.4001C23.9104 35.6175 23.4268 33.8757 22.5675 32.3123C21.7082 30.749 20.4968 29.4072 19.0292 28.393C17.5616 27.3789 15.8781 26.7203 14.1119 26.4693C12.3457 26.2183 10.5454 26.3819 8.85335 26.9471C7.16133 27.5123 5.62409 28.4635 4.3633 29.7256ZM14.9468 43.877C13.3742 44.513 11.616 44.513 10.0435 43.877C8.64739 43.3085 7.49225 42.2726 6.77569 40.9464C6.05913 39.6202 5.82569 38.0863 6.11532 36.607C6.40495 35.1277 7.19965 33.795 8.36343 32.837C9.52722 31.879 10.9878 31.3551 12.4951 31.3551C14.0025 31.3551 15.463 31.879 16.6268 32.837C17.7906 33.795 18.5853 35.1277 18.8749 36.607C19.1646 38.0863 18.9311 39.6202 18.2146 40.9464C17.498 42.2726 16.3429 43.3085 14.9468 43.877Z"
          fill="#35363E"
        />
        <path
          d="M32.2024 50.7255C30.743 52.18 29.7019 54.0005 29.1884 55.9959C28.6749 57.9913 28.7079 60.0882 29.2839 62.0665C29.8599 64.0448 30.9577 65.8316 32.4621 67.2395C33.9666 68.6474 35.8222 69.6245 37.8343 70.0682V77.3569C37.8343 78.0199 38.0977 78.6558 38.5665 79.1246C39.0353 79.5935 39.6712 79.8569 40.3343 79.8569C40.9973 79.8569 41.6332 79.5935 42.102 79.1246C42.5709 78.6558 42.8343 78.0199 42.8343 77.3569V70.0681C44.5755 69.6801 46.2025 68.892 47.5865 67.7664C48.9705 66.6408 50.0735 65.2085 50.8082 63.5829C51.5429 61.9573 51.8891 60.1829 51.8194 58.4004C51.7498 56.6178 51.2662 54.8759 50.4069 53.3125C49.5475 51.7492 48.3361 50.4073 46.8685 49.3931C45.4009 48.379 43.7174 47.7203 41.9512 47.4693C40.185 47.2183 38.3846 47.3819 36.6926 47.947C35.0005 48.5122 33.4633 49.4635 32.2024 50.7255ZM42.7859 64.8771C41.2134 65.5133 39.4551 65.5133 37.8826 64.8771C36.4867 64.3087 35.3317 63.2728 34.6152 61.9467C33.8988 60.6207 33.6654 59.0868 33.955 57.6077C34.2447 56.1286 35.0393 54.796 36.203 53.8381C37.3667 52.8802 38.827 52.3564 40.3343 52.3564C41.8415 52.3564 43.3019 52.8802 44.4656 53.8381C45.6293 54.796 46.4239 56.1286 46.7135 57.6077C47.0031 59.0868 46.7698 60.6207 46.0533 61.9467C45.3369 63.2728 44.1819 64.3087 42.7859 64.8771Z"
          fill="#35363E"
        />
        <path
          d="M76.2947 18.8682V18.8677C75.0343 17.6016 73.4962 16.6466 71.8023 16.0784C70.1085 15.5101 68.3056 15.3444 66.5366 15.5942C64.7676 15.844 63.0811 16.5026 61.6109 17.5176C60.1407 18.5326 58.9272 19.8763 58.0666 21.4419C57.206 23.0076 56.7221 24.7522 56.6531 26.5374C56.5841 28.3227 56.9321 30.0994 57.6693 31.7268C58.4065 33.3542 59.5127 34.7874 60.9002 35.9129C62.2878 37.0383 63.9184 37.825 65.6629 38.2106V77.357C65.6629 78.02 65.9263 78.6559 66.3951 79.1248C66.864 79.5936 67.4999 79.857 68.1629 79.857C68.8259 79.857 69.4618 79.5936 69.9307 79.1248C70.3995 78.6559 70.6629 78.02 70.6629 77.357V38.2103C72.675 37.7667 74.5307 36.7898 76.0352 35.382C77.5397 33.9742 78.6376 32.1874 79.2136 30.2091C79.7896 28.2308 79.8226 26.1339 79.309 24.1385C78.7954 22.1431 77.7543 20.3227 76.2947 18.8683V18.8682ZM70.6144 33.0191C69.0419 33.6549 67.2839 33.6549 65.7114 33.0193C64.3146 32.4509 63.1587 31.4147 62.4416 30.0881C61.7245 28.7615 61.4908 27.2269 61.7803 25.747C62.0699 24.267 62.8648 22.9337 64.0289 21.9752C65.1931 21.0166 66.6542 20.4925 68.1623 20.4924C69.6703 20.4923 71.1315 21.0163 72.2957 21.9747C73.46 22.9332 74.255 24.2664 74.5447 25.7463C74.8344 27.2263 74.6008 28.7609 73.8839 30.0876C73.1669 31.4142 72.0112 32.4505 70.6144 33.0191Z"
          fill="#35363E"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_1598">
          <rect
            width="78.6578"
            height="79.8569"
            fill="white"
            transform="translate(1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CheckinMediumActiveIcon;
