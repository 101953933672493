/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { CenteredPage } from "components";
import { Button, Input, Loader } from "components/base";
import { useGetEnvsContext } from "contexts";
import { useAcceptProjectInvitationMutation } from "graphql/generated";
import { useLogout } from "hooks/mutations";
import { t } from "i18n-js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

type RoleFormData = {
  role: string;
};

function ProjectInvitationRole() {
  const location = useLocation();
  const currentUrl = window.location.href;
  const queryString = currentUrl.split("?")[1];
  const encodedString = window.atob(window.decodeURIComponent(queryString));
  const params = new URLSearchParams(encodedString);
  const [isLoading, setIsLoading] = useState(true);
  const inviteeEmail = params.get("inviteeEmail");
  const token = params.get("token");
  const projectName = params.get("projectName");
  const projectInvitationId = params.get("projectInvitationId");
  const navigate = useNavigate();
  const { currentUser } = useGetEnvsContext();
  const { mutateAsync: acceptProjectInvitationMutateAsync } =
    useAcceptProjectInvitationMutation();
  const { mutateAsync: logout } = useLogout();

  useEffect(() => {
    /* eslint-disable no-console */
    if (currentUser?.email && inviteeEmail !== currentUser?.email) {
      const currentFullPath = `${location.pathname}${location.search}`;

      const navigateUrl = `/login${
        currentFullPath ? "/?redirect=" + encodeURI(currentFullPath) : ""
      }`;
      void logout({}).then(() => {
        navigate(navigateUrl);
      });
    } else {
      setIsLoading(false);
    }
  }, [inviteeEmail, currentUser?.email]);

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<RoleFormData>({
    mode: "onTouched",
    defaultValues: {
      role: currentUser?.defaultProjectRole,
    },
  });
  const [submitError, setSubmitError] = useState<string | undefined>();

  const onSubmit = async (formData: RoleFormData) => {
    const { role } = formData;
    if (!role || !projectInvitationId || !token) {
      setSubmitError(t("errors.genericError"));
      return;
    }
    try {
      const result = await acceptProjectInvitationMutateAsync({
        input: {
          projectInvitationId,
          token,
          role,
        },
      });
      const projectId = result.acceptProjectInvitation?.project.id;
      const redirectUrl = projectId && `/projects/${projectId}`;
      navigate(redirectUrl || "/");
    } catch (error: any) {
      setSubmitError(error.message || t("errors.genericError"));
    }
  };

  return !isLoading ? (
    <CenteredPage>
      <h1 className="mb-8 text-[40px] font-bold">
        {t("pages.projectInvitationRole.title")}
      </h1>
      <form
        className="flex min-w-[469px] flex-col"
        onSubmit={handleSubmit(onSubmit) as () => void}
      >
        {submitError && (
          <span data-testid="errorText" className="text-red-500">
            {submitError}
          </span>
        )}
        <Input
          type="text"
          aria-label={t("pages.projectInvitationRole.yourRole")}
          placeholder={t("pages.projectInvitationRole.yourRolePlaceholder")}
          error={errors.role?.message}
          {...register("role", {
            required: t("errors.presence"),
          })}
          className="mb-4"
        />
        <Button variant="secondary" type="submit" disabled={isSubmitting}>
          {t("shared.join")} {projectName}
        </Button>
      </form>
    </CenteredPage>
  ) : (
    <CenteredPage>
      <Loader />
    </CenteredPage>
  );
}

export default ProjectInvitationRole;
