import clsx from "clsx";
import { Avatar } from "components/base";
import { HStack, VStack } from "components/layout/Stack";
import { Base2, Caption1 } from "components/Typography";
import { format, parseISO } from "date-fns";
import { Comment } from "graphql/generated";
import { t } from "i18n-js";
import { Circle } from "phosphor-react";
import React from "react";

type ShowCommentsProps = {
  comments: Comment[];
  totalCommentCount: number;
  loadMoreComments: () => void;
};

export default function ShowComments({
  comments,
  totalCommentCount,
  loadMoreComments,
}: ShowCommentsProps) {
  return (
    <div className="my-4 flex" data-testid="comments-listing">
      <VStack className="primary-turquoise-scrollbar comments-scrollbar max-h-[522px] w-[100%] overflow-y-scroll pr-[49px]">
        {comments?.map(({ content, author, createdAt, isNew }) => {
          return (
            <>
              <HStack className="items-center">
                <Avatar user={author} size="mini" textClassName="!text-xs" />
                <HStack className="w-full">
                  <div className="relative w-full">
                    <HStack
                      align="center"
                      className="min-h-[66px] rounded-md !border-primary-turquoise-10"
                    >
                      <div className="flex min-h-[66px] w-full flex-wrap items-center pl-4 text-neutral-70">
                        <Caption1>
                          <HStack>
                            {t(`component.comments.commentHeader`, {
                              createdBy: author.fullName,
                              createdAt: format(
                                parseISO(createdAt),
                                "hh:mmaa MMMM d, yyyy",
                              ),
                            })}
                            {!!isNew && (
                              <HStack className="mb-0.5 items-center justify-center">
                                <Circle
                                  className={clsx("ml-2")}
                                  size={9}
                                  color="#37D9BD"
                                  weight="fill"
                                />
                              </HStack>
                            )}
                          </HStack>
                        </Caption1>
                      </div>
                    </HStack>
                  </div>
                </HStack>
              </HStack>
              <Base2 className="text-neutral-90">{content}</Base2>
            </>
          );
        })}
        {!!comments?.length && comments?.length < totalCommentCount && (
          <HStack className="-mr-6 justify-end">
            <Base2
              className="border-b-2 border-neutral-800 font-medium text-neutral-800 hover:cursor-pointer"
              onClick={() => loadMoreComments()}
            >
              {t("constant.comments.loadMore")}
            </Base2>
          </HStack>
        )}
      </VStack>
    </div>
  );
}
