import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { Base2, Heading3 } from "components";
import { Dropdown } from "components/base";
import { useToast } from "components/provider";
import { useGetEnvsContext } from "contexts";
import { HighlightProvider } from "contexts/HighlightContext";
import { t } from "i18n-js";
import {
  CreateAnnouncementIcon,
  CreateTaskIcon,
  CreateWorkshopIcon,
} from "icons";
import { useCreateWorkshop } from "mutations/workshop";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import { CreateAnnouncementModal, CreateButtonItem, CreateTaskModal } from ".";

type CallbackFunction = () => void;

const openModal =
  (setCallback: Dispatch<SetStateAction<boolean>>): CallbackFunction =>
  (): void =>
    setCallback(true);

const closeModal =
  (setCallback: Dispatch<SetStateAction<boolean>>): CallbackFunction =>
  (): void =>
    setCallback(false);

export interface CreateButtonProps {
  onLoadingStateChange: (isLoading: boolean) => void;
}

export default function CreateButton({
  onLoadingStateChange,
}: CreateButtonProps) {
  const [DISPLAY_WORKSHOP_BUTTON, SET_DISPLAY_WORKSHOP_BUTTON] = useState(
    process.env.SHOW_WORKSHOP === "true",
  );
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState<boolean>(false);
  const [isCreateAnnouncementOpen, setIsCreateAnnouncementOpen] =
    useState<boolean>(false);

  const { mutate: createWorkshop } = useCreateWorkshop();
  const { projectId = "" } = useParams();
  const { addToast } = useToast();
  const { envs } = useGetEnvsContext();

  useEffect(() => {
    if (envs) {
      const { SHOW_WORKSHOP } = envs;
      SET_DISPLAY_WORKSHOP_BUTTON(SHOW_WORKSHOP === "true");
    }
  }, [envs]);

  const handleCreateWorkshop = () => {
    onLoadingStateChange(true);
    createWorkshop(
      {
        workshop: {
          title: t("workshopEditor.untitledWorkshop"),
          projectId,
          startTime: new Date().toISOString(),
          endTime: new Date().toISOString(),
          duration: 0,
        },
      },
      {
        onSuccess: ({ createWorkshop }) => {
          const id = createWorkshop?.id;
          onLoadingStateChange(false);

          if (id) {
            const win = window.open(`/workshop/${id}/edit`, "_blank");
            if (win) {
              win.focus();
            }
          } else {
            console.error(t("errors.somethingWentWrong"));
          }
        },
        onError: () => {
          onLoadingStateChange(false);
          addToast({
            message: t("errors.somethingWentWrong"),
            variant: "error",
            hasCloseOption: true,
          });
          console.error(t("errors.somethingWentWrong"));
        },
      },
    );
  };

  return (
    <div>
      <Dropdown
        className="!block"
        button={
          <Menu.Button
            as="button"
            className="fixed bottom-10 right-8 h-[104px] w-[104px] rounded-full bg-neutral-90 text-base font-semibold text-[#222222] shadow-createButton hover:scale-105 hover:bg-neutral-70 focus:outline-none focus:ring focus:ring-primary-turquoise-10 focus:ring-offset-1"
          >
            <Base2 className="text-white">{t("shared.create")}</Base2>
          </Menu.Button>
        }
        items={
          <Menu.Items
            className={clsx(
              "fixed bottom-10 right-8 w-72 origin-bottom-right overflow-hidden rounded-lg border border-black bg-neutral-90 p-6 text-left shadow-createButton focus:outline-none",
              Z_INDEX_LEVELS.BASE_CONTROL,
            )}
          >
            <div className="flex justify-center border-b border-neutral-50 pb-4">
              <Heading3 className="text-white">Create</Heading3>
            </div>

            {DISPLAY_WORKSHOP_BUTTON && (
              <Menu.Item>
                <CreateButtonItem
                  text={t("component.createButtonItem.workshop")}
                  icon={<CreateWorkshopIcon />}
                  onClick={handleCreateWorkshop}
                />
              </Menu.Item>
            )}
            {/* Hide workshop feature behind environment variable flag */}
            <Menu.Item>
              <CreateButtonItem
                text={t("component.createButtonItem.announcement")}
                icon={<CreateAnnouncementIcon />}
                onClick={openModal(setIsCreateAnnouncementOpen)}
              />
            </Menu.Item>
            <Menu.Item>
              <CreateButtonItem
                text={t("component.createButtonItem.task")}
                icon={<CreateTaskIcon />}
                onClick={openModal(setIsCreateTaskOpen)}
              />
            </Menu.Item>
          </Menu.Items>
        }
      />
      <HighlightProvider>
        <CreateAnnouncementModal
          isOpen={isCreateAnnouncementOpen}
          closeModal={closeModal(setIsCreateAnnouncementOpen)}
        />
        <CreateTaskModal
          isOpen={isCreateTaskOpen}
          closeModal={closeModal(setIsCreateTaskOpen)}
        />
      </HighlightProvider>
    </div>
  );
}
