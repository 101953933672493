import React from "react";

export interface TeamViewLanguageCohesionIllustrationProps {
  width?: number;
  height?: number;
  className?: string;
}

export const TeamViewLanguageCohesionIllustration = ({
  width = 249,
  height = 56,
  className,
}: TeamViewLanguageCohesionIllustrationProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 79 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.2583 63.09H64.4753"
      stroke="#5F6368"
      stroke-opacity="0.3"
      stroke-width="1.78"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M49.0781 36.7461C49.0781 46.8717 40.8699 55.0801 30.7441 55.0801C20.6186 55.0801 12.4102 46.8717 12.4102 36.7461C12.4102 26.6206 20.6186 18.4121 30.7441 18.4121C40.8697 18.4121 49.0781 26.6203 49.0781 36.7461Z"
      stroke="#5F6368"
      stroke-opacity="0.3"
      stroke-width="1.78"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M67.5903 36.746C67.5903 46.8715 59.3819 55.08 49.2563 55.08C39.1308 55.08 30.9224 46.8715 30.9224 36.746C30.9224 26.6205 39.1305 18.412 49.2563 18.412C59.3819 18.412 67.5903 26.6202 67.5903 36.746Z"
      stroke="#5F6368"
      stroke-opacity="0.3"
      stroke-width="1.78"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M40.5342 28.2911L48.9892 36.6572"
      stroke="#5F6368"
      stroke-opacity="0.3"
      stroke-width="0.89"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.1731 23.8409L38.6652 26.422"
      stroke="#5F6368"
      stroke-opacity="0.3"
      stroke-width="0.89"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.9224 37.2799L43.7383 50.0959"
      stroke="#5F6368"
      stroke-opacity="0.3"
      stroke-width="0.89"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.7913 30.071L47.2982 44.578"
      stroke="#5F6368"
      stroke-opacity="0.3"
      stroke-width="0.89"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
