import clsx from "clsx";
import { Avatar } from "components/base";
import { Caption1, Paragraph3 } from "components/Typography";
import React from "react";

import { PollResponseCardComponent } from "./PollResponseCard.types";

const BORDER_CLASSES = [
  "border-primary-turquoise-50",
  "border-secondary-indigo-70",
  "border-secondary-green-70",
  "border-secondary-orange-70",
  "border-secondary-red-70",
  "border-secondary-yellow-50",
  "border-tertiary-blue-50",
  "border-tertiary-brick-70",
  "border-tertiary-casal-30",
  "border-tertiary-purple-30",
];

export const PollResponseCard: PollResponseCardComponent = ({
  responseContent,
  className,
  user,
  userIsAnonymous = false,
}) => {
  const borderClass =
    BORDER_CLASSES[Math.floor(Math.random() * BORDER_CLASSES.length)];

  return (
    <div
      className={clsx(
        `${borderClass} flex flex-col gap-4 rounded-md border-t-8 bg-[#F2F3F4] p-4`,
        className,
      )}
    >
      {user && !userIsAnonymous && (
        <div className="flex flex-row items-center gap-2">
          <Avatar user={user} />
          <Caption1>
            {user.firstName} {user.lastName}
          </Caption1>
        </div>
      )}
      <Paragraph3>{responseContent}</Paragraph3>
    </div>
  );
};
