import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { Button, Dropdown } from "components/base";
import { HStack } from "components/layout/Stack";
import { defaultFilters, IDictionary } from "components/pages/WeekView/utils";
import { Base2, Caption1, Paragraph3 } from "components/Typography";
import { t } from "i18n-js";
import { XIcon } from "icons";
import { noop } from "lodash";
import { CaretDown, CaretUp, CheckSquare, Square } from "phosphor-react";
import React, { useMemo, useState } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

export type ObjectKey = keyof typeof defaultFilters;

const areAllFiltersDisabled = (nextValues: IDictionary<boolean>): boolean => {
  return Object.values(nextValues).every((filter) => !filter);
};

function FilterItem({
  name,
  children,
  onClick,
  filters,
}: {
  name: ObjectKey;
  filters: IDictionary<boolean>;
  children: React.ReactNode;
  onClick: (key: ObjectKey, option?: ObjectKey) => void;
}) {
  const checked = filters[name];

  return (
    <button
      className="mb-3 flex cursor-pointer items-center"
      onClick={() => onClick(name)}
    >
      <p
        className={clsx(
          "text-lg font-normal",
          checked
            ? "font-medium text-neutral-900"
            : "font-normal text-neutral-700",
        )}
      >
        {children}
      </p>
    </button>
  );
}

export default function FilterButton({
  appliedFilters,
  setAppliedFilters,
}: {
  appliedFilters: IDictionary<boolean>;
  setAppliedFilters: (filters: IDictionary<boolean>) => void;
}) {
  const [filters, setFilters] = useState<IDictionary<boolean>>(
    defaultFilters || appliedFilters,
  );
  const activeEventFilter = Object.keys(filters).find(
    (property) => filters[property],
  );

  // ENABLES HIDE_COMPLETED_TASK OPTION IF TASK OR ALL EVENTS IS SELECTED
  const disableHideCompletedTaskFilter = (filterName: string) => {
    return (
      (activeEventFilter === "allEvents" || activeEventFilter === "task") &&
      filterName === "hideCompletedTask"
    );
  };

  const filtersCount: number = useMemo(() => {
    return Object.entries(filters).filter(
      ([key, value]) => key !== "allEvents" && value === true,
    ).length;
  }, [filters]);

  const handleNewFiltersChange = (key: ObjectKey) => {
    if (key === "allEvents") {
      setFilters(defaultFilters);
      setAppliedFilters(defaultFilters);
    } else {
      const keys = ["hideCompletedTask", "onlyCreatedByMe", "onlyMyEvents"];
      const nextFilters = keys.includes(key)
        ? {
            ...filters,
            [key]: !filters[key],
          }
        : {
            ...defaultFilters,
            allEvents: false,
            [key]: !filters[key],
          };
      const allDisabled = areAllFiltersDisabled(nextFilters);
      setFilters(allDisabled ? defaultFilters : nextFilters);
      setAppliedFilters(allDisabled ? defaultFilters : nextFilters);
    }
  };

  const handleOnClickCancel = () => {
    setFilters(defaultFilters);
    setAppliedFilters(defaultFilters);
  };
  const areFiltersApplied = filtersCount > 0;

  return (
    <div>
      <Dropdown
        className="!block"
        button={
          <Menu.Button
            as="button"
            className={clsx(
              "fixed top-[188px] right-8 h-[44px] min-w-[147px] rounded-[32px] px-3 text-base font-semibold text-[#222222] focus:outline-none focus:ring focus:ring-primary-turquoise-10 focus:ring-offset-1",
              areFiltersApplied
                ? "bg-[#35363E]"
                : "border border-tint-dark-30 bg-transparent hover:bg-tint-dark-10 disabled:bg-transparent",
            )}
          >
            {areFiltersApplied ? (
              <Base2 className="font-medium text-white">
                <HStack align="center" justify="center">
                  {t(`component.filterButtonItem.${activeEventFilter || ""}`)}
                  <div className="mx-2 h-1 w-1 rounded-full bg-neutral-500" />
                  <Base2 data-testid="active-filter-count">
                    {filtersCount}
                  </Base2>
                  <CaretDown size={12} color="#FFFFFF" className="!ml-2" />
                </HStack>
              </Base2>
            ) : (
              <HStack className="flex items-center justify-evenly">
                <Base2 className="text-neutral-900">
                  {t("component.filterButtonItem.allEvents")}
                </Base2>
                <CaretUp size={12} className="text-[#292D30]" />
              </HStack>
            )}
          </Menu.Button>
        }
        items={
          <Menu.Items
            className={clsx(
              "fixed bottom-10 right-8 h-[595px] w-[296px] origin-bottom-right overflow-hidden rounded-lg bg-white p-4 text-left shadow-createButton focus:outline-none",
              Z_INDEX_LEVELS.BASE_CONTROL,
            )}
          >
            <Menu.Button
              className="absolute top-3 right-3"
              onClick={noop}
              data-testid="close-filters"
            >
              <HStack justify="end">
                <Button
                  data-testid="settings-menu-close-button"
                  variant="tertiary"
                  LeftIcon={XIcon}
                  size="small"
                />
              </HStack>
            </Menu.Button>
            <div className="px-2 py-4">
              {Object.keys(filters)
                .slice(0, 4)
                .map((filterName, index) => (
                  <FilterItem
                    key={index}
                    filters={filters}
                    name={filterName}
                    onClick={handleNewFiltersChange}
                  >
                    {t(`component.filterButtonItem.${filterName}`)}
                  </FilterItem>
                ))}
            </div>
            <div>
              <Caption1 className="p-2 uppercase text-neutral-600">
                Filters
                <div className="px-2 py-4">
                  {Object.keys(filters)
                    .slice(4)
                    .map((filterName, index) => (
                      <FilterItem
                        key={index}
                        filters={filters}
                        name={filterName}
                        onClick={
                          filterName === "hideCompletedTask" &&
                          !disableHideCompletedTaskFilter(filterName)
                            ? noop
                            : handleNewFiltersChange
                        }
                      >
                        <HStack
                          align="center"
                          space={2}
                          className="-translate-x-2.5"
                        >
                          {filters[filterName] === true ? (
                            <CheckSquare
                              size={24}
                              color="#16C7A7"
                              weight="fill"
                            />
                          ) : (
                            <Square
                              size={24}
                              color={
                                filterName === "hideCompletedTask" &&
                                !disableHideCompletedTaskFilter(filterName)
                                  ? "#5F636814"
                                  : "#5F636826"
                              }
                              weight="fill"
                            />
                          )}
                          <Paragraph3
                            className={clsx(
                              disableHideCompletedTaskFilter(filterName)
                                ? "text-neutral-900"
                                : "text-neutral-300",
                              (filterName === "onlyMyEvents" ||
                                filterName === "onlyCreatedByMe") &&
                                "!text-neutral-900",
                            )}
                          >
                            {t(`component.filterButtonItem.${filterName}`)}
                          </Paragraph3>
                        </HStack>
                      </FilterItem>
                    ))}
                </div>
              </Caption1>
            </div>

            <div>
              <Menu.Button
                className="mb-2 h-12 rounded-full bg-neutral-90 px-8 hover:bg-neutral-70 focus:!outline-0 focus:!ring-0 focus:!ring-offset-0"
                onClick={handleOnClickCancel}
                as={Button}
                variant="outline"
              >
                {t("shared.resetFilters")}
              </Menu.Button>
            </div>
          </Menu.Items>
        }
      />
    </div>
  );
}
