import { Heading2, Heading3, VStack } from "components";
import { EmbeddedLinks } from "components/CreateInteractions/CreateAnnouncementForm";
import { AnnouncementCard } from "components/pages";
import { useDayCardContext } from "contexts/DayCardContext";
import { HighlightProvider } from "contexts/HighlightContext";
import { useRefContext } from "contexts/RefContext";
import { useWeekFeedContext } from "contexts/WeekFeedContext";
import { addDays, format, formatISO, parseISO, startOfDay } from "date-fns";
import {
  Announcement,
  Link,
  Meeting,
  Project,
  SubFiltersEnums,
  Task,
  TemperatureResponse,
  User,
  Workshop,
} from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { groupBy, isEmpty } from "lodash";
import { useSubFilters } from "queries";
import React, { Fragment, useEffect, useMemo } from "react";
import { DeepPartial } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import { scroller } from "react-scroll";

import { IDictionary, TaskCard } from ".";
import TemperatureCard from "./TemperatureCard";
import WorkshopCard from "./WorkshopCard";

export type WeekFeedHuddleType = DeepPartial<Meeting> & {
  createdAt: string;
  invitees: Partial<User>[];
  meetingDescription?: string;
};
export type WeekFeedMeetingType = DeepPartial<Meeting> & {
  startTime: string;
  invitees: Partial<User>[];
  meetingDescription?: string;
  links?: EmbeddedLinks[];
  meetingSource?: string;
};
export type WeekFeedAnnouncementType = DeepPartial<Announcement> & {
  author: Partial<User>;
  links: Partial<Link>;
  createdAt: string;
};

export type WeekFeedTaskType = Partial<Task> & {
  taskTitle: string;
  dueDate: string;
};

export type WeekFeedTemperatureCheck = Partial<TemperatureResponse> & {
  isVisible: boolean;
  weekDate: string;
};

export type WeekFeedWorkshopType = Partial<Workshop> & {
  workshopTitle: string;
  workshopDescription: JSON;
  workshopParticipants: Partial<User>[];
  startTime: string;
  endTime: string;
};

type ResourceType =
  | WeekFeedAnnouncementType
  | WeekFeedTaskType
  | WeekFeedMeetingType
  | WeekFeedHuddleType
  | WeekFeedTemperatureCheck
  | WeekFeedWorkshopType;

export const isTemperatureCheckResource = (
  item: Partial<ResourceType>,
): item is WeekFeedTemperatureCheck =>
  item.__typename === "TemperatureResponse" && !!item.isVisible;
export const isAnnouncementResource = (
  item: Partial<ResourceType>,
): item is WeekFeedAnnouncementType =>
  item.__typename === "Announcement" && !!item.author;
export const isTaskResource = (
  item: Partial<ResourceType>,
): item is WeekFeedTaskType => item.__typename === "Task" && !!item.taskTitle;
export const isMeetingResource = (
  item: Partial<ResourceType>,
): item is WeekFeedMeetingType =>
  item.__typename === "Meeting" && !!item.startTime;
export const isHuddleResource = (
  item: Partial<ResourceType>,
): item is WeekFeedHuddleType =>
  item.__typename === "Meeting" && !item.startTime && !!item.createdAt;
export const isWorkshopResource = (
  item: Partial<ResourceType>,
): item is WeekFeedWorkshopType =>
  item.__typename === "Workshop" && !!item.workshopTitle;

export type ResourceProps = {
  item: ResourceType;
  project?: Project;
};

export function Resource({ item, project }: ResourceProps) {
  if (isTemperatureCheckResource(item)) {
    return <TemperatureCard temperatureInfo={item} project={project} />;
  }

  if (isAnnouncementResource(item)) {
    return <AnnouncementCard announcement={item} />;
  }

  if (isTaskResource(item)) {
    return <TaskCard task={item} />;
  }
  if (isWorkshopResource(item)) {
    return <WorkshopCard workshop={item} />;
  }
  return <></>;
}
const groupByDay = (items: ResourceType[], hasValidAccess?: boolean) => {
  return groupBy(items, (item) => {
    let date = "";
    if (hasValidAccess && isTemperatureCheckResource(item)) {
      date = formatISO(startOfDay(parseISO(item.weekDate)));
    } else if (isAnnouncementResource(item)) {
      date = item.createdAt;
    } else if (isMeetingResource(item)) {
      date = item.startTime;
    } else if (isHuddleResource(item)) {
      date = item.createdAt;
    } else if (isTaskResource(item)) {
      date = item.dueDate;
    } else if (isWorkshopResource(item)) {
      date = item.startTime;
    } else {
      return;
    }
    return formatISO(startOfDay(parseISO(date)));
  });
};
const filterFeedItems = (
  filters: Set<string>,
  items: ResourceType[],
): ResourceType[] => {
  // DELETE HUDDLE FROM FILTERS AS __TYPENAME FOR HUDDLE IS MEETING
  if (filters.has("huddle")) {
    filters.delete("huddle");
    filters.add("meeting");
  }

  if (filters.has("allEvents")) {
    return items.sort((item) =>
      item.__typename === "TemperatureResponse" ? -1 : 1,
    );
  }
  return items.filter((item) =>
    filters.has(item.__typename?.toLowerCase() || ""),
  );
};
export const formatWeekFeedDay = (day: string) =>
  format(parseISO(day), "EEEE, MMM d");
const WEEK_DAYS = 7;
export type GroupedWeekFeedType = Record<string, ResourceType[]>;
function WeekFeed({
  startDate,
  appliedFilters,
  project,
}: {
  startDate: Date;
  appliedFilters: IDictionary<boolean>;
  project?: Project;
}) {
  const { projectId = "" } = useParams();
  const [params] = useSearchParams();
  const card = params.get("card");
  const {
    allEvents,
    onlyCreatedByMe,
    onlyMyEvents,
    announcement,
    huddle,
    workshop,
    task,
    hideCompletedTask,
  } = appliedFilters;

  const getActiveEvent = () => {
    const events = {
      AllEvents: allEvents,
      Announcements: announcement,
      Huddles: huddle,
      MeetingsWorkshops: workshop,
      Tasks: task,
    };
    for (const [key, value] of Object.entries(events)) {
      if (value === true) {
        return SubFiltersEnums[key as keyof typeof SubFiltersEnums];
      }
    }

    return SubFiltersEnums.AllEvents;
  };

  const { subFilters } = useSubFilters({
    projectId,
    eventsFilters: {
      completedTask: hideCompletedTask,
      iCreated: onlyCreatedByMe,
      myEvent: onlyMyEvents,
      selectEvent: getActiveEvent(),
      dateInWeek: formatISO(startDate),
    },
  });

  const hasAdminAccess = useProjectAccessLevel({ projectId }).hasAdminAccess;
  const hasMemberAccess = useProjectAccessLevel({ projectId }).hasMemberAccess;
  const { handleFeedData } = useWeekFeedContext();
  const activeFilters: Set<string> = useMemo(() => {
    const filters = new Set(
      Object.entries(appliedFilters)
        .filter(([, active]) => active)
        .map(([key]) => key),
    );
    return filters;
  }, [appliedFilters]);
  const groupedWeekFeed: GroupedWeekFeedType = useMemo(() => {
    const weekStart = startOfDay(startDate);
    const weekFeedItems: GroupedWeekFeedType = {};
    const groupedFeed = groupByDay(
      subFilters as ResourceType[],
      hasAdminAccess || hasMemberAccess,
    );
    for (let i = 0; i < WEEK_DAYS; i++) {
      const day = formatISO(addDays(weekStart, i));
      weekFeedItems[day] = filterFeedItems(
        activeFilters,
        groupedFeed[day] || [],
      );
    }
    handleFeedData(weekFeedItems);
    return weekFeedItems;
  }, [startDate, activeFilters, subFilters, startDate]);
  useEffect(() => {
    if (card) {
      scroller.scrollTo(card, {
        smooth: true,
      });
    }
  }, [card]);
  const { contextRef } = useRefContext();
  const { selectedDay } = useDayCardContext();
  useEffect(() => {
    contextRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [selectedDay]);
  return (
    <VStack align="center" space={10}>
      {Object.keys(groupedWeekFeed).map((day) => (
        <Fragment key={day}>
          <div ref={selectedDay === day ? contextRef : undefined} />
          <Heading2 className="text-neutral-90">
            {formatWeekFeedDay(day)}
          </Heading2>
          <HighlightProvider>
            <VStack className="w-7/12" align="center" space={6}>
              {isEmpty(groupedWeekFeed[day]) ? (
                <Heading3 className="text-neutral-70">
                  {t("component.weekFeed.emptyDay")}
                </Heading3>
              ) : (
                groupedWeekFeed[day].map((item, index) => (
                  <Resource
                    item={item}
                    project={project}
                    key={`${day}-${index}`}
                  />
                ))
              )}
            </VStack>
          </HighlightProvider>
        </Fragment>
      ))}
    </VStack>
  );
}
export default WeekFeed;
