import { Base1, Caption1 } from "components/Typography";
import React from "react";

const RaisedHandUser = ({
  user,
  index,
  room,
}: {
  user: string;
  index: number;
  room?: number;
}) => {
  return (
    <div
      className="my-4 flex items-center justify-between first:mt-2"
      key={`${user}_${index}`}
    >
      <div className="flex items-center">
        <span className="rounded bg-neutral-100 px-2 py-1 text-xs text-neutral-800">
          {index + 1}
        </span>
        <Base1 className="ml-2 whitespace-nowrap text-neutral-800">
          {user}
        </Base1>
      </div>

      {room && (
        <Caption1 className="ml-2 whitespace-nowrap rounded bg-neutral-100 px-2 py-1 text-neutral-800">
          Room {room}
        </Caption1>
      )}
    </div>
  );
};

export default RaisedHandUser;
