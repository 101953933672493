import clsx from "clsx";
import { AnonymityBadge } from "components/partial/AnonymityBadge";
import { Caption1, Display3, Display5 } from "components/Typography";
import { Activity, WorkshopActivity } from "graphql/generated";
import { Timer } from "phosphor-react";
import React from "react";

import {
  formatMinutes,
  formatTimeAssigned,
} from "../../PostSessionActivity.util";
import { ActivityTypeTag } from "../ActivityTypeTag";

export type ActivityMetaDetailsProps = {
  activityTypeName: Activity["__typename"];
  className?: string;
  titleSize?: "large" | "small";
  shouldShowTimer?: boolean;
  shouldShowAnonymityFlag?: boolean;
  isAnonymous?: boolean;
} & Pick<WorkshopActivity, "timeElapsed" | "duration" | "title">;

export const ActivityMetaDetails = ({
  timeElapsed,
  title,
  titleSize,
  duration,
  activityTypeName,
  className,
  shouldShowTimer = true,
  shouldShowAnonymityFlag = false,
  isAnonymous = false,
}: ActivityMetaDetailsProps) => {
  const TitleComponent = titleSize === "large" ? Display3 : Display5;

  return (
    <div className={clsx("flex gap-1", className)}>
      <TitleComponent>{title}</TitleComponent>

      <div className="flex items-center gap-4">
        {shouldShowAnonymityFlag && <AnonymityBadge anonymous={isAnonymous} />}

        <ActivityTypeTag activityType={activityTypeName} />

        {shouldShowTimer && (
          <div className="flex items-center gap-1">
            <Timer className="text-neutral-50" />

            <Caption1 className="text-neutral-70">
              {formatMinutes(timeElapsed || 0)}/
              {formatTimeAssigned(duration || 0)}
            </Caption1>
          </div>
        )}
      </div>
    </div>
  );
};
