import {
  GetUserPersonalityProfileQuery,
  GetUserPersonalityProfileQueryVariables,
  useGetUserPersonalityProfileQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query GetUserPersonalityProfile($userId: ID!) {
    getUserPersonalityProfile(userId: $userId) {
      filledDiskSegments {
        segmentIdentifier
        fillState
      }
      discType
      visibleToTeam
    }
  }
`;
function GetUserPersonalityProfile(
  variables?: GetUserPersonalityProfileQueryVariables,
): {
  getUserPersonalityProfile: GetUserPersonalityProfileQuery["getUserPersonalityProfile"];
} & UseQueryResult<GetUserPersonalityProfileQuery, unknown> {
  const response = useGetUserPersonalityProfileQuery(variables, {
    // refetchInterval: 1000,
  });
  return {
    getUserPersonalityProfile: response?.data?.getUserPersonalityProfile || [],
    ...response,
  };
}

export { GetUserPersonalityProfile };
