import { Base2, Display3, HStack } from "components";
import { Button, Logo } from "components/base";
import { CreateProjectForm, EditProjectForm } from "components/pages";
import { t } from "i18n-js";
import { ProjectPhasesIcon } from "icons";
import { noop } from "lodash";
import { FlagBanner, Newspaper, Trophy, X } from "phosphor-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export enum ProjectCreationSteps {
  Details = 1,
  Phases = 2,
  PhaseGoals = 3,
  Milestones = 4,
}

type StepInformationType = {
  title: string;
  description: string;
  icon: JSX.Element;
  optional: boolean;
  proportionContent: string;
  proportionInfo: string;
};

const getStepInformation = (step: number): StepInformationType => {
  switch (step) {
    case ProjectCreationSteps.Details:
      return {
        title: t("page.createProject.step.details.title"),
        description: t("page.createProject.step.details.description"),
        icon: (
          <Newspaper
            className="mb-12 text-tertiary-blue-10"
            size={80}
            weight="thin"
          />
        ),
        optional: false,
        proportionContent: "w-1/2",
        proportionInfo: "w-1/2",
      };
    case ProjectCreationSteps.Phases:
      return {
        title: t("page.createProject.step.phases.title"),
        description: t("page.createProject.step.phases.description"),
        icon: <ProjectPhasesIcon className="mb-12 text-tertiary-blue-10" />,
        optional: false,
        proportionContent: "w-1/2",
        proportionInfo: "w-1/2",
      };
    case ProjectCreationSteps.PhaseGoals:
      return {
        title: t("page.createProject.step.phaseGoals.title"),
        description: t("page.createProject.step.phaseGoals.description"),
        icon: (
          <Trophy
            className="mb-12 text-secondary-green-30"
            size={80}
            weight="thin"
          />
        ),
        optional: true,
        proportionContent: "w-1/2",
        proportionInfo: "w-1/2",
      };
    case ProjectCreationSteps.Milestones:
      return {
        title: t("page.createProject.step.milestones.title"),
        description: t("page.createProject.step.milestones.description"),
        icon: (
          <FlagBanner
            className="mb-12 text-secondary-indigo-10"
            size={80}
            weight="thin"
          />
        ),
        optional: true,
        proportionContent: "w-3/5",
        proportionInfo: "w-2/5",
      };
    default:
      return {
        title: "",
        description: "",
        icon: <></>,
        optional: false,
        proportionContent: "w-1/2",
        proportionInfo: "w-1/2",
      };
  }
};

type CreateProjectProps = {
  isEditing?: boolean;
  onClose?: () => void;
  editProjectId?: string;
};
function CreateProject({
  isEditing = false,
  onClose = noop,
  editProjectId = "",
}: CreateProjectProps) {
  const [currentStep, setCurrentStep] = useState<number>(
    ProjectCreationSteps.Details,
  );
  const navigate = useNavigate();

  const { title, icon, optional, proportionContent, proportionInfo } =
    getStepInformation(currentStep);

  const handleSubmit = (createdProjectId: string) => {
    const createdProjectRoute = `/projects/${createdProjectId}`;
    navigate(createdProjectRoute);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="relative flex min-h-screen w-full bg-white">
      <Button
        className="absolute top-4 right-4"
        variant="tertiary"
        RightIcon={X}
        onClick={isEditing ? onClose : goBack}
      >
        {t("page.createProject.cancelAndDiscard")}
      </Button>

      <Logo size="medium" className="absolute top-10 left-10 text-white" />

      <div
        className={`flex ${proportionInfo} flex-col justify-center bg-tertiary-casal-50 px-14`}
      >
        <div>
          {icon}

          <HStack align="center" className="gap-4">
            <Base2 className="text-white">
              {t("page.createProject.stepsCount", {
                actual: currentStep,
                total: 4,
              })}
            </Base2>
            {optional && (
              <Base2 className="rounded-full bg-tertiary-casal-30 px-2 py-1 text-white">
                Optional
              </Base2>
            )}
          </HStack>

          <Display3 className="my-4 max-w-[500px] text-white">{title}</Display3>
        </div>
      </div>

      <div className={proportionContent}>
        {isEditing ? (
          <EditProjectForm
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            onSubmitSuccess={onClose}
            editProjectId={editProjectId}
          />
        ) : (
          <CreateProjectForm
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            onSubmitSuccess={handleSubmit}
          />
        )}
      </div>
    </div>
  );
}

export default CreateProject;
