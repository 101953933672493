import {
  UserOrganizationsQuery,
  useUserOrganizationsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query UserOrganizations {
    organizations {
      id
      name
      organizationImageUrl
    }
  }
`;

function useUserOrganizations(): {
  organizations: UserOrganizationsQuery["organizations"];
} & UseQueryResult<UserOrganizationsQuery, unknown> {
  const response = useUserOrganizationsQuery({}, { suspense: true });

  return { organizations: response.data?.organizations || [], ...response };
}

export { useUserOrganizations };
