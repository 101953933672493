import { useCreateEmotionalSignalMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation CreateEmotionalSignal(
    $emotionalSignal: CreateEmotionalSignalInput!
  ) {
    createEmotionalSignal(emotionalSignal: $emotionalSignal) {
      emojiName
      timeStamp
      dailyRoomId
    }
  }
`;

export function useCreateEmotionalSignal() {
  const queryClient = useQueryClient();
  return useCreateEmotionalSignalMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["WorkshopById"]);
      await queryClient.refetchQueries(["WorkshopById"]);
    },
  });
}
