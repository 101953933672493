import React from "react";

function UnorderedListIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.25 10H24.25"
        stroke="#35363E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.251 16H24.2504"
        stroke="#35363E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.251 22H24.2504"
        stroke="#35363E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 11.125C8.74632 11.125 9.25 10.6213 9.25 10C9.25 9.37868 8.74632 8.875 8.125 8.875C7.50368 8.875 7 9.37868 7 10C7 10.6213 7.50368 11.125 8.125 11.125Z"
        fill="#35363E"
      />
      <path
        d="M8.125 17.125C8.74632 17.125 9.25 16.6213 9.25 16C9.25 15.3787 8.74632 14.875 8.125 14.875C7.50368 14.875 7 15.3787 7 16C7 16.6213 7.50368 17.125 8.125 17.125Z"
        fill="#35363E"
      />
      <path
        d="M8.125 23.125C8.74632 23.125 9.25 22.6213 9.25 22C9.25 21.3787 8.74632 20.875 8.125 20.875C7.50368 20.875 7 21.3787 7 22C7 22.6213 7.50368 23.125 8.125 23.125Z"
        fill="#35363E"
      />
    </svg>
  );
}

export default UnorderedListIcon;
