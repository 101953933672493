import { UserProjectsQuery, useUserProjectsQuery } from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query UserProjects($input: ProjectsQueryInput) {
    projects(input: $input) {
      id
      name
      status
      organization {
        id
        name
        organizationImageUrl
      }
    }
  }
`;

function useUserProjects(): {
  projects: UserProjectsQuery["projects"];
} & UseQueryResult<UserProjectsQuery, unknown> {
  const response = useUserProjectsQuery(
    { input: { membership: true } },
    { suspense: true },
  );

  return { projects: response.data?.projects || [], ...response };
}

export { useUserProjects };
