import { Transition } from "@headlessui/react";
import { Toast } from "components/base";
import { uniqueId } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";

import {
  ToastConfig,
  ToastProviderState,
  ToastProviderStateToast,
} from "./ToastProvider.type";

/** Consts */
const TOAST_EXPIRY = 15_000;
export const initialState: ToastProviderState = {
  toasts: [],
  // eslint-disable-next-line unicorn/no-useless-undefined
  addToast: () => undefined,
};

/** Context */
const ToastContext = React.createContext(initialState);

/**
 * useToast grants access to the app-wide ToastContext, used for tracking Toast notifications.
 * Use method: `addToast` to push onto the current toast stack.
 */
export const useToast = () => {
  return useContext(ToastContext);
};

/**
 * ToastProvider tracks the app-wide toast stack.
 * Use the `useToast` hook to gain access to the context,
 * and use `addToast` to add a toast to the stack.
 */
export const ToastProvider = ({ children }: { children?: React.ReactNode }) => {
  const [toasts, setToasts] = useState<ToastProviderState["toasts"]>([]);
  const toastsRef = useRef(toasts);
  const [currentTimeout, setCurrentTimeout] = React.useState<
    NodeJS.Timeout | undefined
  >();

  useEffect(() => {
    toastsRef.current = toasts;
  }, [toasts]);

  useEffect(() => {
    if (toasts && toasts.length > 0 && !currentTimeout) {
      handleSetToastTimeout(toasts[toasts.length - 1]);
    }
  }, [toasts, currentTimeout]);

  /** Push a toast onto the top of the stack. */
  const addToast = (toast: ToastConfig) => {
    // Pause Previous Top Toast
    let previousToast: ToastProviderStateToast | undefined;
    if (currentTimeout && toasts && toasts.length > 0) {
      previousToast = toasts[toasts.length - 1];
      if (
        !previousToast.remainingDuration ||
        previousToast.remainingDuration ===
          (previousToast.timeout || TOAST_EXPIRY)
      ) {
        previousToast.remainingDuration -= Date.now() - previousToast.startTime;
      }
      clearTimeout(currentTimeout);
    }

    /* UPDATE TOASTS ARRAY STATE */
    const updatedToasts = [
      // All the rest
      ...toasts.filter((toast) => toast.id !== previousToast?.id),
    ];
    // If there was already a toast, add it back in with the paused timeout information
    if (previousToast) {
      updatedToasts.push(previousToast);
    }
    // Add the new toast
    updatedToasts.push({
      ...toast,
      id: uniqueId("toast-"),
      startTime: Date.now(),
      remainingDuration: toast.timeout || TOAST_EXPIRY,
    });
    setToasts(updatedToasts);

    /* SETUP TIMEOUT BEHAVIOUR */
    handleSetToastTimeout(toast);
  };

  const handleSetToastTimeout = (
    toast: ToastProviderStateToast | ToastConfig,
  ) => {
    setCurrentTimeout(
      setTimeout(() => {
        const stateToast = toastsRef.current.find(
          (refToast) => refToast.message === toast.message,
        );

        if (!stateToast) {
          return;
        }

        /**
         * Note, it's important not to just `.pop()` because if more toasts were added
         * since this toast, the wrong one will be removed.
         */
        const tempStack = [...toastsRef.current];
        _removeToast(stateToast, tempStack);
        setToasts(tempStack);
        setCurrentTimeout(undefined);
      }, (toast as ToastProviderStateToast).remainingDuration || toast.timeout || TOAST_EXPIRY),
    );
  };

  /** This will match toasts by 'message' and remove from the stack.*/
  const removeToast = (toast: ToastConfig) => {
    const toastStackIndex = toasts.findIndex(
      (stateToast) => stateToast.message === toast.message,
    );
    if (toastStackIndex !== -1) {
      /**
       * Note, it's important not to just `.pop()` because if more toasts were added
       * since this toast, the wrong one will be removed.
       */
      const tempStack = [...toasts];
      tempStack.splice(toastStackIndex, 1);
      setToasts(tempStack);
      clearTimeout(currentTimeout);
    }
  };

  /** Clear out all the toasts. */
  const emptyToasts = () => {
    setToasts([]);
  };

  return (
    <ToastContext.Provider
      value={{ toasts, addToast, emptyToasts, removeToast }}
    >
      <div id="toast-provider" className="relative h-screen">
        {children}

        {toasts.map((mappedToast) => (
          <Transition
            key={mappedToast.id}
            appear={true}
            show={true}
            enter="transition-all ease-in-out duration-300 transform "
            enterFrom="translate-y-5 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition-all ease-in-out duration-300 transform"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="translate-y-5 opacity-0"
            className="absolute bottom-5 left-1/2 z-[60] -translate-x-1/2"
          >
            <Toast
              {...mappedToast}
              // TODO: add 'hasCloseIcon'  -- https://coeurajtech.atlassian.net/browse/R10-310
            />
          </Transition>
        ))}
      </div>
    </ToastContext.Provider>
  );
};

/**
 * this internal removeToast handles the removal of a toast from a stack
 * tracked out of state by a ref because the timeout's closure doesn't have
 * access to the updated toasts state stack.
 */
const _removeToast = (toast: ToastConfig, toastStack: ToastConfig[]) => {
  const toastIndex = toastStack.findIndex(
    (stateToast) => stateToast.message === toast.message,
  );
  if (toastIndex !== -1) {
    toastStack.splice(toastIndex, 1);
  }
};
