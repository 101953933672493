import { Modal } from "components/partial";
import { Paragraph1 } from "components/Typography";
import { t } from "i18n-js";
import React from "react";

interface ParticipationDialogProps {
  open: boolean;
  onClose: () => void;
}

const ParticipationDialog = ({ open, onClose }: ParticipationDialogProps) => {
  const participation =
    "component.userSettings.tabs.insights.sections.participation";

  return (
    <Modal
      className="no-scrollbar h-min w-235 overflow-scroll rounded-xl px-12 py-10"
      open={open}
      onClose={onClose}
    >
      <Paragraph1 className="text-neutral-900">
        {t(`${participation}.modalDescription`)}
      </Paragraph1>
    </Modal>
  );
};

export default ParticipationDialog;
