import clsx from "clsx";
import { HStack } from "components/layout/Stack";
import { Base1Strong } from "components/Typography";
import React from "react";

import { getAvatarSize } from "../Avatar/Avatar";

type OrganizationAvatarProps = {
  organizationName?: string;
  className?: string;
  organizationImageUrl: string;
  size?: "mini" | "small" | "medium" | "large";
};

export default function OrganizationAvatar({
  organizationName,
  className = "",
  organizationImageUrl = "",
  size = "medium",
}: OrganizationAvatarProps) {
  return (
    <HStack
      className={clsx(
        "h-10 w-10 rounded-full bg-neutral-40 text-white ",
        getAvatarSize(size),
        className,
      )}
      justify="center"
      align="center"
    >
      {!!organizationImageUrl ? (
        <img
          aria-label={`${organizationName?.[0] || ""}'s image`}
          className="h-full w-full rounded-full"
          src={organizationImageUrl}
          alt="OrganizationAvatar"
        />
      ) : (
        <Base1Strong>{organizationName?.[0] || ""}</Base1Strong>
      )}
    </HStack>
  );
}
