import { Avatar, Button } from "components/base";
import { HStack, VStack } from "components/layout";
import { Modal } from "components/partial";
import {
  Base1,
  Base2,
  Caption2,
  Display4,
  Heading2Strong,
  Paragraph1,
} from "components/Typography";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { noop } from "lodash";
import { Circle, LinkedinLogo, X } from "phosphor-react";
import { useProjectAssignments } from "queries";
import React from "react";
import { useParams } from "react-router-dom";

import { colorVariants } from "./MembersList";

type UserDetailsDialogProps = {
  open: boolean;
  onClose?: () => void;
  userId: string;
};

const UserDetailsDialog = ({
  open,
  onClose = noop,
  userId,
}: UserDetailsDialogProps) => {
  const { projectId = "" } = useParams();
  const { hasViewAccess } = useProjectAccessLevel({ projectId });
  const { projectAssignments } = useProjectAssignments({
    projectId,
  });

  const record = projectAssignments.find(
    (assignment) => assignment.user.id === userId,
  );

  return (
    <Modal
      isFullScreen
      open={open}
      onClose={onClose}
      className="no-scrollbar flex max-h-[600px] w-[660px] flex-col overflow-scroll rounded-lg p-10"
    >
      <VStack space={8}>
        <HStack justify="between">
          <HStack space={4} className="relative">
            <VStack align="center" space={2}>
              <Avatar
                user={record?.user}
                size="large"
                className="h-[88px] w-[88px]"
              />
              {record?.user.discType &&
                record.user.visibleToTeam &&
                !hasViewAccess && (
                  <HStack align="center" space={2}>
                    <div
                      className={`${
                        colorVariants[
                          record?.user.discType.charAt(0).toUpperCase()
                        ]
                      } h-4 w-4 text-center !text-[10px] text-white`}
                    >
                      {record?.user.discType.charAt(0).toUpperCase()}
                    </div>
                    <Caption2
                      className={`${
                        colorVariants[
                          record?.user.discType.charAt(0).toUpperCase() + "Text"
                        ]
                      }`}
                    >
                      {t("shared.segmentTypeProfile", {
                        segmentType: record?.user.discType,
                      })}
                    </Caption2>
                  </HStack>
                )}
            </VStack>
            <VStack>
              <Display4 className="mb-2">{record?.user.fullName}</Display4>
              <HStack space={2} align="center" className="flex-wrap">
                {record?.user.pronouns && (
                  <Base1 className="text-neutral-600">
                    ({record.user.pronouns})
                  </Base1>
                )}
                {record?.user.defaultProjectRole && (
                  <>
                    {record?.user.pronouns && (
                      <Circle size={5} weight="fill" color="#C0C5C9" />
                    )}
                    <Base1 className="text-neutral-600">
                      {record?.user.defaultProjectRole}{" "}
                      {record?.user.company ? `, ${record?.user.company}` : ""}
                    </Base1>
                  </>
                )}
              </HStack>
              <HStack space={2} align="center" className="!mt-1 flex-wrap">
                {record?.user.homebase && (
                  <>
                    <Base1 className="text-neutral-600">
                      {record?.user.homebase}
                    </Base1>
                  </>
                )}
                {record?.user.linkdinLink && (
                  <>
                    {record?.user.homebase && (
                      <Circle size={5} weight="fill" color="#C0C5C9" />
                    )}
                    <a href={record.user.linkdinLink} target="_blank">
                      <LinkedinLogo
                        size={24}
                        weight="fill"
                        className="cursor-pointer"
                        color="#0a66c2"
                      />
                    </a>
                  </>
                )}
              </HStack>
            </VStack>
          </HStack>
          <Button
            className="absolute right-[40px] top-[40px]"
            LeftIcon={X}
            variant="tertiary"
            onClick={onClose}
          />
        </HStack>
        {record?.manualOfMe && record.manualOfMe.length > 0 && (
          <VStack space={8}>
            <Heading2Strong className="text-neutral-900">
              {t("shared.manualOfMe")}
            </Heading2Strong>
            {record.manualOfMe.map((item) => (
              <div
                className="border-b-2 border-neutral-200 pb-6"
                key={item.profileQuestion?.question}
              >
                <Base2 className="mb-[16px] text-neutral-900">
                  {item.profileQuestion?.question}
                </Base2>
                <Paragraph1 className="text-neutral-900">
                  {item.answer}
                </Paragraph1>
              </div>
            ))}
          </VStack>
        )}
      </VStack>
    </Modal>
  );
};

export default UserDetailsDialog;
