import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import { User } from "graphql/generated";
import { noop } from "lodash";
import { XCircle } from "phosphor-react";
import React from "react";

import { HStack } from "../../layout/Stack";
import { Base2 } from "../../Typography";
import Avatar from "../Avatar";

type UserPillProps = {
  user: Partial<User>;
  onCancel?: (user: Partial<User>) => void;
  isComboboxOption?: boolean;
  className?: string;
  bgColor?: "bg-secondary-indigo-70" | "bg-neutral-90" | "bg-neutral-0";
  textColor?: string;
  showAvatar?: boolean;
};

export default function UserPill({
  user,
  onCancel = noop,
  isComboboxOption = false,
  className,
  bgColor = "bg-secondary-indigo-70",
  showAvatar = true,
  textColor = "text-white",
}: UserPillProps) {
  return (
    <HStack
      align="center"
      space={2}
      className={clsx(
        "self-center rounded-full py-1 px-2 text-white",
        bgColor,
        className,
      )}
    >
      {showAvatar && <Avatar user={user} size="small" />}
      <Base2 className={clsx("whitespace-nowrap", textColor)}>
        {user.fullName || user.email}
      </Base2>
      {isComboboxOption ? (
        <Combobox.Option as="button" className="cursor-pointe" value={user}>
          <XCircle size={24} color="#95969F" />
        </Combobox.Option>
      ) : (
        <button className="cursor-pointe" onClick={() => onCancel(user)}>
          <XCircle size={24} color="#95969F" />
        </button>
      )}
    </HStack>
    // <HStack
    //   align="center"
    //   space={2}
    //   className="self-center rounded-full bg-secondary-indigo-70 py-1 pl-2 pr-0.5 text-white"
    // >
    //   <Base2 className=" whitespace-nowrap">{user.fullName}</Base2>
    //   {isComboboxOption ? (
    //     <Combobox.Option as="button" className="cursor-pointer" value={user}>
    //       {renderButtonIcon()}
    //     </Combobox.Option>
    //   ) : (
    //     <button className="cursor-pointer" onClick={() => onCancel(user)}>
    //       {renderButtonIcon()}
    //     </button>
    //   )}
    // </HStack>
  );
}
