import { gql } from "@apollo/client";
import { GetProjectAssignemntsSubscription } from "graphql/generated";
import { useQueryClient } from "react-query";

import { useSubscription } from "./use-subscription";

export const PROJECT_ASSIGNMENTS_SUBSCRIPTION = gql`
  subscription GetProjectAssignemnts($projectId: ID!) {
    getProjectAssignments(projectId: $projectId) {
      projectAssignments {
        user {
          id
          avatarUrl
          company
          currentSignInAt
          defaultProjectRole
          discType
          email
          fullName
          firstName
          homebase
          lastName
          linkdinLink
          pronouns
        }
      }
    }
  }
`;

export type UseGetProjectAssignmentsProps = {
  projectId: string;
};

export const useGetProjectAssignments = ({
  projectId,
}: UseGetProjectAssignmentsProps) => {
  const queryClient = useQueryClient();

  const output = useSubscription<GetProjectAssignemntsSubscription>(
    PROJECT_ASSIGNMENTS_SUBSCRIPTION,
    {
      variables: {
        projectId: projectId,
      },

      async onData() {
        await queryClient.invalidateQueries(["ProjectById"]);
      },
    },
  );

  return {
    loading: output?.loading,
    projectAssignments:
      output?.data?.getProjectAssignments?.projectAssignments || [],
    users:
      output?.data?.getProjectAssignments?.projectAssignments?.map(
        (assignment) => assignment.user,
      ) || [],
  };
};
