import Link from "@tiptap/extension-link";
import {
  AnyExtension,
  EditorContent,
  EditorOptions,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { HStack } from "components/layout";
import { Heading3 } from "components/Typography";
import React, { useState } from "react";

import Button from "../Button/Button";
import { ReadOnlyRichTextEditorProp } from "./RichTextEditor.types";

const ReadOnlyRichTextEditor = ({
  readOnlyContent,
  headerEndAdornment,
  label,
  className,
}: ReadOnlyRichTextEditorProp) => {
  // Editor documentation: https://tiptap.dev/api/editor

  const editor = useEditor(
    {
      editable: false,
      content: readOnlyContent,
      extensions: [
        StarterKit.configure({
          listItem: {
            HTMLAttributes: {
              class: "list-disc ml-2",
            },
          },
          heading: {
            levels: [1],
            HTMLAttributes: {
              class: "text-xl",
            },
          },
        }) as AnyExtension,
        Link,
      ],
    } as Partial<EditorOptions>,
    [readOnlyContent],
  );
  const [showTruncatedText, setShowTruncatedText] = useState<boolean>(false);
  const handleTruncatedText = () => {
    setShowTruncatedText(true);
  };
  return (
    <div className={className}>
      {(!!label || !!headerEndAdornment) && (
        <div className="mb-6 flex items-center justify-between">
          <Heading3>{label}</Heading3>

          {headerEndAdornment}
        </div>
      )}

      {label === "Workshop Description" ? (
        (editor && editor?.getText().length < 100) || showTruncatedText ? (
          <EditorContent editor={editor} />
        ) : (
          <>
            <HStack className="line-clamp-2">
              <EditorContent editor={editor} />
            </HStack>
            <Button
              onClick={handleTruncatedText}
              variant="link"
              className="mt-2"
            >
              Read more...
            </Button>
          </>
        )
      ) : (
        <EditorContent editor={editor} />
      )}
    </div>
  );
};

export default ReadOnlyRichTextEditor;
