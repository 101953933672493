import { VStack } from "components/layout/Stack";
import { t } from "i18n-js";
import { noop } from "lodash";
import { ChartBar, Gear, UserCircle } from "phosphor-react";
import React from "react";

import { UserSettingsProfileLogOut } from "./components/UserSettingsAccountContent/components";
import UserSettingsTabItem from "./UserSettingsTabItem";

export enum UserSettingsMenuTabs {
  profile = "profile",
  insights = "insights",
  AccountSettings = "accountSettings",
}

// TODO: This hides the insights tab if workshop feature has not been turned on
// This array can be removed once the workshop is switched on permanently
const ACTIVE_TABS = [
  UserSettingsMenuTabs.profile,
  // process.env.SHOW_WORKSHOP === "true" && UserSettingsMenuTabs.insights,
  UserSettingsMenuTabs.insights,
  UserSettingsMenuTabs.AccountSettings,
].filter(Boolean);

type UserSettingsMenuProps = {
  currentTab: UserSettingsMenuTabs;
  setCurrentTab?: React.Dispatch<React.SetStateAction<UserSettingsMenuTabs>>;
};

const USER_SETTINGS_TAB_ICONS = {
  [UserSettingsMenuTabs.profile]: UserCircle,
  [UserSettingsMenuTabs.insights]: ChartBar,
  [UserSettingsMenuTabs.AccountSettings]: Gear,
};

function UserSettingsMenu({
  currentTab,
  setCurrentTab = noop,
}: UserSettingsMenuProps) {
  return (
    <VStack justify="between" className="h-full">
      <VStack>
        {Object.values(ACTIVE_TABS).map((tab) => (
          <UserSettingsTabItem
            key={tab}
            tabId={tab}
            title={t(`component.userSettings.tabs.${tab}.tabLabel`)}
            isCurrent={currentTab === tab}
            onClick={() => setCurrentTab(tab)}
            Icon={USER_SETTINGS_TAB_ICONS[tab]}
          />
        ))}
      </VStack>
      <UserSettingsProfileLogOut />
    </VStack>
  );
}

export default UserSettingsMenu;
