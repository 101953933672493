import { Caption1 } from "components/Typography";
import { t } from "i18n-js";
import { NotePencil } from "phosphor-react";
import React from "react";
import { Link } from "react-router-dom";

/**
 * Style link which directs to the Workshop Drafts Feed route.
 */
export const DraftsButton = () => {
  // TODO fix route?
  return (
    <Link
      to="./workshop_drafts"
      className="fixed bottom-40 right-8 flex h-16 w-16 flex-col items-center justify-center gap-1 rounded-full bg-white text-black shadow-md hover:scale-105 hover:bg-neutral-5"
    >
      <NotePencil />
      <Caption1>{t("workshop.drafts")}</Caption1>
    </Link>
  );
};
