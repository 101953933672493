/* eslint-disable unicorn/no-null */
import {
  ActivityTypes,
  BreakoutView,
  ContentView,
  PollView,
  ResourceView,
} from "components/partial";
import { WorkshopActivity } from "graphql/generated";
import { useWorkshop } from "queries";
import React, { useMemo } from "react";

const ActivityContent = ({
  type,
  activityId,
  workshopId,
  onMutationStateChange,
}: {
  type: ActivityTypes;
  activityId: string;
  workshopId: string;
  onMutationStateChange: ({ isLoading }: { isLoading: boolean }) => void;
}) => {
  const { workshop } = useWorkshop({ id: workshopId });
  const activity = useMemo(() => {
    const allActivities = workshop?.agenda.flatMap((a) =>
      a.__typename === "Section" ? a.workshopActivities : a,
    );

    const activity = allActivities?.find(
      (a) => a?.id === activityId,
    ) as WorkshopActivity;

    return activity;
  }, [workshop?.agenda, activityId]);

  const Content = useMemo(() => {
    switch (type) {
      case "discussion":
        return activity ? (
          <ContentView
            activity={activity}
            onMutationStateChange={onMutationStateChange}
          />
        ) : null;
      case "resource":
        return activity ? (
          <ResourceView
            activity={activity}
            onMutationStateChange={onMutationStateChange}
          />
        ) : null;
      case "breakout":
        return workshop && activity ? (
          <BreakoutView
            activity={activity}
            workshopId={workshopId}
            onMutationStateChange={onMutationStateChange}
          />
        ) : null;
      case "poll":
        return activity ? (
          <PollView
            activity={activity}
            onMutationStateChange={onMutationStateChange}
          />
        ) : null;
      case "break":
        return <div>Break</div>;
      default:
        return <div>Unknown</div>;
    }
  }, [type, activity]);

  return Content;
};

export default ActivityContent;
