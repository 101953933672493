import React from "react";

function DecisionMediumActiveIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_46_1634)">
        <path
          d="M45.3373 30.6166C44.8574 28.2012 43.6753 25.9812 41.9392 24.2347C40.2031 22.4882 37.9901 21.2929 35.5776 20.7986V21.4999C35.5776 22.163 35.3142 22.7989 34.8454 23.2677C34.3766 23.7365 33.7407 23.9999 33.0776 23.9999C32.4146 23.9999 31.7787 23.7365 31.3099 23.2677C30.841 22.7989 30.5776 22.163 30.5776 21.4999V20.7986C28.16 21.2942 25.943 22.4937 24.2056 24.2464C22.4682 25.999 21.2879 28.2264 20.8135 30.6482C21.4147 30.7212 21.9687 31.0112 22.3713 31.4636C22.774 31.9161 22.9977 32.4999 23.0005 33.1056C23.0032 33.7113 22.7849 34.2971 22.3863 34.7532C21.9878 35.2093 21.4365 35.5043 20.8359 35.5828C21.3333 37.9765 22.5209 40.1721 24.2522 41.8984C25.9835 43.6247 28.1825 44.806 30.5776 45.2964V45.1166C30.5821 41.2723 32.1111 37.5868 34.8295 34.8685C37.5478 32.1501 41.2334 30.621 45.0776 30.6166H45.3373Z"
          fill="#FF8181"
        />
        <path
          d="M35.5771 45.1167V45.2966C37.9664 44.8072 40.1605 43.6303 41.8897 41.9105C43.619 40.1908 44.808 38.0032 45.3106 35.6167H45.0771C42.5585 35.6196 40.1438 36.6214 38.3628 38.4023C36.5818 40.1833 35.58 42.598 35.5771 45.1167Z"
          fill="#FF8181"
        />
        <path
          d="M23 33.1167C22.9989 32.5094 22.776 31.9234 22.3733 31.4689C21.9706 31.0143 21.4157 30.7225 20.813 30.6483C20.7094 30.6311 20.6049 30.6205 20.5 30.6167H2.5C1.83696 30.6167 1.20107 30.8801 0.732233 31.3489C0.263392 31.8178 0 32.4537 0 33.1167C0 33.7797 0.263392 34.4156 0.732233 34.8845C1.20107 35.3533 1.83696 35.6167 2.5 35.6167H20.5C21.1556 35.5935 21.7781 35.3226 22.242 34.8587C22.7059 34.3948 22.9768 33.7723 23 33.1167Z"
          fill="#35363E"
        />
        <path
          d="M78.5118 31.349L68.7501 21.5878C68.5181 21.3549 68.2425 21.1701 67.9391 21.0439C67.6356 20.9177 67.3102 20.8526 66.9815 20.8523C66.6529 20.852 66.3274 20.9165 66.0237 21.0421C65.72 21.1678 65.444 21.3521 65.2116 21.5845C64.9792 21.8169 64.7949 22.0928 64.6693 22.3965C64.5437 22.7002 64.4792 23.0257 64.4795 23.3544C64.4798 23.683 64.5449 24.0084 64.6711 24.3119C64.7973 24.6154 64.9821 24.891 65.2149 25.1229L70.7083 30.6166H45.0773C41.233 30.621 37.5474 32.1501 34.8291 34.8684C32.1108 37.5867 30.5817 41.2723 30.5773 45.1166V57.2587C27.8125 57.8937 25.3794 59.5268 23.7444 61.8449C22.1093 64.163 21.3872 67.003 21.7166 69.8206C22.046 72.6381 23.4037 75.235 25.5294 77.1134C27.6551 78.9919 30.3992 80.0197 33.2359 79.9999C36.0726 79.9802 38.8022 78.9142 40.9015 77.0063C43.0008 75.0984 44.3221 72.4829 44.6122 69.661C44.9023 66.8391 44.1407 64.0095 42.4735 61.7144C40.8062 59.4193 38.3506 57.8203 35.5773 57.2238V45.1166C35.5801 42.5979 36.582 40.1832 38.3629 38.4022C40.1439 36.6212 42.5586 35.6194 45.0773 35.6166H70.7079L65.2149 41.1093C64.746 41.5781 64.4825 42.2139 64.4824 42.877C64.4823 43.5401 64.7456 44.176 65.2144 44.6449C65.6832 45.1138 66.3191 45.3773 66.9822 45.3774C67.6452 45.3775 68.2812 45.1142 68.7501 44.6454L78.5118 34.8846C78.744 34.6525 78.9282 34.3769 79.0539 34.0736C79.1796 33.7703 79.2442 33.4452 79.2442 33.1168C79.2442 32.7885 79.1796 32.4634 79.0539 32.1601C78.9282 31.8567 78.744 31.5811 78.5118 31.349ZM28.5528 73.0487C27.6437 72.1397 27.0246 70.9814 26.7738 69.7205C26.5229 68.4596 26.6516 67.1526 27.1436 65.9648C27.6356 64.7771 28.4687 63.7619 29.5377 63.0476C30.6066 62.3333 31.8634 61.9521 33.149 61.9521C34.4346 61.9521 35.6914 62.3333 36.7603 63.0476C37.8293 63.7619 38.6624 64.7771 39.1544 65.9648C39.6464 67.1526 39.7751 68.4596 39.5243 69.7205C39.2734 70.9814 38.6543 72.1397 37.7452 73.0487C36.5251 74.2657 34.8722 74.9491 33.149 74.9491C31.4258 74.9491 29.7729 74.2657 28.5528 73.0487H28.5528Z"
          fill="#35363E"
        />
        <path
          d="M33.0771 24C33.4055 24 33.7306 23.9354 34.0339 23.8097C34.3372 23.6841 34.6128 23.5 34.845 23.2678C35.0771 23.0357 35.2613 22.7601 35.3869 22.4567C35.5125 22.1534 35.5772 21.8283 35.5771 21.5V3.5C35.5771 2.83696 35.3138 2.20107 34.8449 1.73223C34.3761 1.26339 33.7402 1 33.0771 1C32.4141 1 31.7782 1.26339 31.3094 1.73223C30.8405 2.20107 30.5771 2.83696 30.5771 3.5V21.5C30.5771 21.8283 30.6418 22.1534 30.7674 22.4567C30.893 22.7601 31.0772 23.0357 31.3093 23.2678C31.5415 23.5 31.8171 23.6841 32.1204 23.8097C32.4237 23.9354 32.7488 24 33.0771 24Z"
          fill="#35363E"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_1634">
          <rect
            width="79.2441"
            height="78.9468"
            fill="white"
            transform="translate(0 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DecisionMediumActiveIcon;
