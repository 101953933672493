import { Button } from "components/base";
import { t } from "i18n-js";
import { noop } from "lodash";
import { ArrowLeft, Check } from "phosphor-react";
import React from "react";
import { UseFormGetValues } from "react-hook-form";

import { CreateProjectPhasesFormInput, DefaultFieldsType } from "../types";

type ButtonsProps = {
  previousStep?: (args?: DefaultFieldsType) => void;
  getValues: UseFormGetValues<CreateProjectPhasesFormInput>;
  onSubmit: () => void;
  isEditing?: boolean;
};

function MilestonesFormButtons({
  previousStep = noop,
  getValues,
  onSubmit,
  isEditing = false,
}: ButtonsProps) {
  return (
    <div className="fixed bottom-0 w-3/5 -translate-x-14 bg-white py-4 shadow-phasesGoals">
      <div className="flex w-full items-center justify-between pr-24 pl-14">
        <Button
          type="button"
          onClick={() => {
            previousStep(getValues());
          }}
          variant="outline"
          LeftIcon={ArrowLeft}
        >
          {t("shared.back")}
        </Button>

        <Button
          variant="primary"
          type="button"
          RightIcon={Check}
          onClick={onSubmit}
        >
          {isEditing ? t("shared.saveProject") : t("shared.createProject")}
        </Button>
      </div>
    </div>
  );
}

export default MilestonesFormButtons;
