import { Popover, Transition } from "@headlessui/react";
import { Button } from "components/base";
import { FacilitationNotes } from "components/partial/WorkshopFooter/FacilitationNotes";
import { Base2 } from "components/Typography";
import { WorkshopByIdQuery } from "graphql/generated";
import { t } from "i18n-js";
import { Notepad } from "phosphor-react";
import React, { Fragment } from "react";
import { getCurrentWorkshopActivity } from "utils";

import { PreviewControls } from "../PreviewControls";

export interface WorkshopPreviewFooterProps {
  agenda?: WorkshopByIdQuery["workshopById"]["agenda"] | undefined;
  onNext: () => void;
  onPrev: () => void;
}

/** Akin to the WorkshopFooter component except this is only for the WorkshopPreview */
export const WorkshopPreviewFooter = (props: WorkshopPreviewFooterProps) => {
  const currentActivity = getCurrentWorkshopActivity(props.agenda);

  return (
    <Popover>
      <Transition
        enter="transition transition-all duration-200 ease-in-out"
        enterFrom="max-h-0"
        enterTo="max-h-72"
        leave="transition transition-all duration-200 ease-out"
        leaveFrom="max-h-72"
        leaveTo="max-h-0"
      >
        <Popover.Panel className="w-full">
          {({ close }) => (
            <FacilitationNotes
              notes={currentActivity?.facilitatorNote as object}
              onClose={close}
            />
          )}
        </Popover.Panel>
      </Transition>

      <div className="relative flex items-center justify-between bg-neutral-90 py-5 pl-14 pr-12">
        <PreviewControls {...props} />

        <Popover.Button as={Fragment}>
          <Button
            variant="outlineLight"
            disabled={!currentActivity?.facilitatorNote}
          >
            <Base2 className="inline-block">
              {t("workshop.facilitationNotes")}
            </Base2>
            <Notepad className="ml-4 inline-block" fill="#fff" />
          </Button>
        </Popover.Button>
      </div>
    </Popover>
  );
};
