import { InteractionModal } from "components/partial";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { Megaphone } from "phosphor-react";
import React from "react";
import { useParams } from "react-router-dom";

import { CreateAnnouncementForm } from ".";

type CreateAnnouncementModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function CreateAnnouncementModal({
  isOpen,
  closeModal,
}: CreateAnnouncementModalProps) {
  const { projectId = "" } = useParams();
  const hasAdminAccess = useProjectAccessLevel({ projectId }).hasAdminAccess;

  return (
    <InteractionModal
      isOpen={isOpen}
      closeModal={closeModal}
      header={t("component.createAnnouncement.heading")}
      description={t("component.createAnnouncement.subheading")}
      icon={<Megaphone mirrored />}
      variant="yellow"
      showHighlightFeature={true}
    >
      <CreateAnnouncementForm
        onClose={closeModal}
        userHasAdminAccess={hasAdminAccess}
      />
    </InteractionModal>
  );
}
