import {
  DocumentByIdQuery,
  DocumentByIdQueryVariables,
  useDocumentByIdQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryOptions, UseQueryResult } from "react-query";
gql`
  query DocumentById($workshop_id: ID!, $document_id: ID!) {
    documentById(workshopId: $workshop_id, documentId: $document_id) {
      filename
      id
      url
    }
  }
`;

function useWorkshopDocumentById(
  variables: DocumentByIdQueryVariables,
  options?: UseQueryOptions<DocumentByIdQuery>,
): {
  document?: DocumentByIdQuery["DocumentById"];
} & UseQueryResult<DocumentByIdQuery, unknown> {
  const response = useDocumentByIdQuery(variables, {
    suspense: true,
    enabled: !!variables.document_id,
    ...options,
  });

  return {
    document: response.data?.documentById,
    ...response,
  };
}

export { useWorkshopDocumentById };
