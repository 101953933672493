import { HStack } from "components/layout";
import { Overline } from "components/Typography";
import { t } from "i18n-js";
import { Eye, EyeSlash } from "phosphor-react";
import React from "react";

/**
 * Simple Badge
 */
export const AnonymityBadge = ({
  anonymous = false,
}: {
  anonymous?: boolean;
}) =>
  anonymous ? (
    <HStack align="center" className="gap-1">
      <EyeSlash size={16} />
      <Overline>{t("pollActivityEditor.anonymousPoll")}</Overline>
    </HStack>
  ) : (
    <HStack align="center" className="gap-1">
      <Eye size={16} />
      <Overline>{t("pollActivityEditor.nonAnonymousPoll")}</Overline>
    </HStack>
  );
