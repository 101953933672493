import { useStartStopMusicMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation StartStopMusic($input: StartStopMusicInput!) {
    startStopMusic(input: $input) {
      id
      url
      status
      timeStamp
    }
  }
`;

export function useStartStopMusic() {
  const queryClient = useQueryClient();
  return useStartStopMusicMutation({
    onSuccess: () => {
      void queryClient.refetchQueries(["WorkshopMusic"]);
    },
  });
}
