import { useCreateTaskMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation CreateTask($task: CreateTaskInput!) {
    createTask(task: $task) {
      id
      description
      createdBy {
        id
        firstName
      }
      assignees {
        id
        firstName
      }
      links {
        url
        title
        extension
        preview
      }
      dueDate
      isCompleted
      isHighlighted
      project {
        id
        name
      }
    }
  }
`;

function useCreateTask() {
  const queryClient = useQueryClient();
  return useCreateTaskMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries(["subFilters"]);
      void queryClient.refetchQueries(["subFilters"]);
      void queryClient.refetchQueries(["Notifications"]);
    },
  });
}

export default useCreateTask;
