import "rc-picker/assets/index.css";
import "./DatePicker.css";

import clsx from "clsx";
import { Base2, VStack } from "components";
import { FormInputErrors } from "components/partial";
import { startOfWeek } from "date-fns";
import { t } from "i18n-js";
import { noop, uniqueId } from "lodash";
import { CaretDown } from "phosphor-react";
import Picker, { PickerProps } from "rc-picker";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import enUS from "rc-picker/lib/locale/en_US";
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { WEEK_START_DAY } from "utils/constants/week_start";

type DatePickerProps = {
  className?: string;
  innerRef?: ForwardedRef<HTMLInputElement>;
  contentClassName?: string;
  datePickerContainerClassName?: string;
  label?: string;
  error?: string;
  showErrorOutline?: boolean;
  id?: string;
  disabled?: boolean;
  light?: boolean;
} & Omit<PickerProps<Date>, "locale" | "generateConfig">;

// see https://github.com/react-component/picker/blob/master/src/PickerTrigger.tsx for options
// top-right, bottom-right
const DROPDOWN_ALIGN = { points: ["tr", "br"] };

const localeEnUS = Object.assign({}, enUS, {
  shortWeekDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
});

function DatePicker({
  className = "",
  contentClassName = "",
  datePickerContainerClassName = "",
  disabled = false,
  label = "",
  error = "",
  showErrorOutline = false,
  id = uniqueId("date-picker-input-"),
  defaultValue,
  light,
  ...props
}: DatePickerProps) {
  const hasError = showErrorOutline || error.length > 0;

  return (
    <VStack
      space={2}
      className={clsx(disabled ? "opacity-60" : "", contentClassName)}
    >
      {label ? (
        <Base2 as="label" className="text-neutral-90" htmlFor={id}>
          {label}
        </Base2>
      ) : undefined}
      <div className={clsx("flex items-center", datePickerContainerClassName)}>
        <Picker<Date>
          disabled={disabled}
          dropdownAlign={DROPDOWN_ALIGN}
          id={id}
          {...props}
          className={clsx(
            "date-picker",
            light ? "date-picker-light" : "",
            hasError ? "error" : "",
            className,
            "rounded",
          )}
          generateConfig={dateFnsGenerateConfig}
          locale={localeEnUS}
          defaultValue={defaultValue}
          inputReadOnly={true}
        />

        <CaretDown
          className={clsx(
            "-ml-8",
            hasError ? "text-secondary-red-70" : "",
            light ? "text-white" : "",
          )}
          size={24}
        />
      </div>

      <FormInputErrors error={error} />
    </VStack>
  );
}

export const weekFormat = `'${t("shared.weekOf")}' MMM d, Y`;

function WeekDatePicker({
  onChange = noop,
  defaultValue,
  ...props
}: DatePickerProps) {
  const [selectedDate, setSelectedDate] = useState<Date>();

  useEffect(() => {
    if (selectedDate || !defaultValue) return;
    setSelectedDate(defaultValue);
    handleWeekChange(defaultValue, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, selectedDate]);

  const handleWeekChange = (value: Date | null, dateString: string): void => {
    if (value) {
      const startOfTheWeek = startOfWeek(value, {
        weekStartsOn: WEEK_START_DAY,
      });
      setSelectedDate(startOfTheWeek);
      onChange(startOfTheWeek, dateString);
    }
  };

  return (
    <DatePicker
      {...props}
      format={weekFormat}
      picker="week"
      inputReadOnly
      onChange={handleWeekChange}
      value={selectedDate}
      defaultValue={defaultValue}
    />
  );
}
DatePicker.withRef = forwardRef<HTMLInputElement, DatePickerProps>(
  (props, ref) => <DatePicker {...props} innerRef={ref} />,
);

DatePicker.Week = forwardRef<HTMLInputElement, DatePickerProps>(
  (props, ref) => <WeekDatePicker {...props} innerRef={ref} />,
);

export { DatePicker as default };
