import { WorkshopState } from "graphql/generated";

export interface workshopStatusType {
  __typename?: "Workshop" | undefined;
  id: string;
  state?: WorkshopState | null | undefined;
  liveNow: boolean;
}

export const getWorkshopBadge = (
  workshopStatus: workshopStatusType | undefined,
) => {
  const { liveNow = false, state = WorkshopState.Draft } = workshopStatus ?? {};

  let displayState: WorkshopState | undefined;

  if (!liveNow) {
    switch (state) {
      case WorkshopState.Draft:
        displayState = WorkshopState.Draft;
        break;
      case WorkshopState.Published:
        displayState = WorkshopState.Published;
        break;
      case WorkshopState.PostSession:
        displayState = WorkshopState.PostSession;
        break;
    }
  } else {
    switch (state) {
      case WorkshopState.Draft:
      case WorkshopState.Published:
      case WorkshopState.LiveSession:
        displayState = WorkshopState.LiveSession;
        break;
      case WorkshopState.PostSession:
        displayState = WorkshopState.PostSession;
        break;
    }
  }

  return displayState;
};
