import {
  LiveWorkshopHeader,
  MissingDataErrorDisplay,
  PageLoader,
  WorkshopSidebar,
} from "components/partial";
import { Caption1 } from "components/Typography";
import {
  Agenda,
  WorkshopByIdQuery,
  WorkshopParticipant,
  WorkshopParticipantRole,
} from "graphql/generated";
import { t } from "i18n-js";
import { useWorkshop } from "queries";
import React, { Reducer, useReducer } from "react";
import { useParams } from "react-router-dom";

import { LiveWorkshopActivity } from "../Workshop/components";
import { VideoSize } from "../Workshop/Workshop.types";
import {
  VideoPlaceholder,
  VideoPlaceholderSizeVariant,
  WorkshopPreviewFooter,
} from "./components";
import {
  cleanStateAgendaMap,
  PreviewWorkshopActions,
  previewWorkshopReducer,
  PreviewWorkshopReducerAction,
} from "./WorkshopPreview.state";

export const WorkshopPreview = () => {
  const { id: workshopId = "" } = useParams();
  const [videoCallSize, setVideoCallSize] = React.useState<VideoSize>(
    VideoSize.Large,
  );
  const [previewWorkshop, dispatch] = useReducer<
    Reducer<
      Partial<WorkshopByIdQuery["workshopById"]>,
      PreviewWorkshopReducerAction
    >
  >(previewWorkshopReducer, {});

  const { isLoading, isError } = useWorkshop(
    {
      id: workshopId,
    },
    {
      // Initialize reducer state with the real workshop data
      onSuccess: (data) => {
        /**
         * Check current `state` value, don't override if this conversion of the
         * true Workshop state has already been converted to a preview-able state
         * for the Preview's local reducer state.
         */
        if (Object.keys(previewWorkshop).length === 0) {
          dispatch({
            type: PreviewWorkshopActions.SET_WORKSHOP,
            payload: {
              ...data.workshopById,
              // eslint-disable-next-line unicorn/no-array-callback-reference
              agenda: data.workshopById.agenda.map(cleanStateAgendaMap),
            },
          });
        }
      },
    },
  );

  const onNext = () => {
    dispatch({ type: PreviewWorkshopActions.GO_NEXT, payload: {} });
  };
  const onPrev = () => {
    dispatch({ type: PreviewWorkshopActions.GO_PREV, payload: {} });
  };

  return (
    <div className="flex h-screen flex-col">
      <LiveWorkshopHeader variant="preview" />

      <div className="bg-neutral-900 py-2 text-center text-white">
        <Caption1>{t("workshop.preview.banner")}</Caption1>
      </div>

      {isLoading ? (
        <PageLoader isShowing />
      ) : (
        <div className="flex h-[calc(100vh-119px)] max-h-[calc(100vh-119px)] flex-1  flex-col">
          <div className="max-w-screen relative flex flex-1 overflow-y-auto">
            <WorkshopSidebar
              workshopId={workshopId}
              agenda={previewWorkshop.agenda as Agenda[]}
              showAgenda={previewWorkshop.isAgendaVisibleToParticipants}
              user={
                // Preview isn't interactive so passing a mock user is fine
                {
                  id: "undefined",
                  role: WorkshopParticipantRole.Participant,
                } as WorkshopParticipant
              }
            />

            {isError ? (
              <MissingDataErrorDisplay />
            ) : (
              <LiveWorkshopActivity
                workshop={previewWorkshop as WorkshopByIdQuery["workshopById"]}
                handleVideoCallSizeChange={(size) => setVideoCallSize(size)}
              />
            )}

            <VideoPlaceholder
              size={
                videoCallSize === VideoSize.Small
                  ? VideoPlaceholderSizeVariant.Small
                  : VideoPlaceholderSizeVariant.Large
              }
              className={`min-w-[275px] flex-1 ${videoCallSize}`}
            />

            {/** Overlay - blocks interaction with polls and embeds */}
            <div className="absolute inset-0 z-[3] bg-neutral-300/15" />
          </div>

          <WorkshopPreviewFooter
            agenda={previewWorkshop.agenda}
            onNext={onNext}
            onPrev={onPrev}
          />
        </div>
      )}
    </div>
  );
};
