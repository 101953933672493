import { Listbox } from "@headlessui/react";
import { DropdownTrigger } from "components/partial";
import { Paragraph3 } from "components/Typography";
import { t } from "i18n-js";
import React from "react";

import { ActivityTypeSelectorComponent } from ".";

const types = ["discussion", "resource", "breakout", "poll"];

const ActivityTypeSelector: ActivityTypeSelectorComponent = ({
  selectedType = "discussion",
  onChange,
}) => {
  return (
    <Listbox
      value={selectedType}
      onChange={onChange}
      as="div"
      className="relative w-52"
    >
      {({ open }) => (
        <>
          <DropdownTrigger
            primaryText={`${t("workshopEditor.type")}:`}
            secondaryText={t(`workshopEditor.types.${selectedType}`)}
            open={open}
            as={Listbox.Button}
          />
          <Listbox.Options className="absolute z-10 flex w-full flex-col rounded-b-lg border-2 border-t-0 border-tint-dark-10 bg-white py-2 shadow-projectPhaseCard outline-none">
            {types.map((type) => (
              <Listbox.Option value={type} key={type}>
                {({ active }) => (
                  <div
                    className={`px-4 py-2 text-left hover:bg-tint-dark-10 ${
                      active ? "bg-tint-dark-10" : ""
                    }`}
                  >
                    <Paragraph3>{t(`workshopEditor.types.${type}`)}</Paragraph3>
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
};

export default ActivityTypeSelector;
