import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import { VStack } from "components/layout";
import { Caption2, Overline } from "components/Typography";
import { User } from "graphql/generated";
import { t } from "i18n-js";
import { UsersThree } from "phosphor-react";
import React from "react";
import { Z_INDEX_LEVELS } from "utils";

import { UserItem } from "../EditWorkshopHeader/AddParticipantsModal/UserOptions";

interface TeamMembersProps {
  users: User[];
  query: string;
}

const TeamMembers = ({ query, users }: TeamMembersProps) => {
  const userListIsEmpty =
    (query.length > 0 && users.length === 0) || users.length === 0;

  return (
    <VStack
      justify="between"
      className={clsx(
        "absolute left-8 right-8 mt-2 max-h-[416px] overflow-auto rounded-xl border bg-white shadow-lg",
        Z_INDEX_LEVELS.MODAL_CONTROL,
      )}
    >
      {userListIsEmpty && (
        <VStack className="px-4 py-8" align="center">
          <UsersThree
            className="mb-2 rounded-full bg-red-100 p-1.5"
            size={28}
            color="#ff6060"
          />
          <Caption2 className="text-red-500">
            {t("errors.summaryCanBeSharedWithTeamMembersOnly")}
          </Caption2>
        </VStack>
      )}

      {!userListIsEmpty && (
        <VStack className="px-4 pt-4">
          <Overline className="pb-3 text-neutral-90">
            {t("workshop.shareSummaryDialog.selectTeamMember")}
          </Overline>
          {users.map((user) => (
            <Combobox.Option key={user.id} value={user} className="list-none">
              <UserItem user={user} />
            </Combobox.Option>
          ))}
        </VStack>
      )}
    </VStack>
  );
};

export default TeamMembers;
