import {
  ProjectByIdQuery,
  ProjectByIdQueryVariables,
  useProjectByIdQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query ProjectById($id: ID!) {
    projectById(id: $id) {
      id
      name
      goal
      startDate
      endDate
      links {
        url
        title
        extension
        preview
      }
      status
      phases {
        id
        name
        startDate
        goal
        endDate
        weeksLength
        position
        phaseSummary {
          description
          createdBy {
            firstName
            lastName
            avatarUrl
          }
          links {
            title
            url
            extension
            preview
          }
          id
        }
        phaseHighlights {
          __typename
          totalHighlights
          announcements {
            announcementTitle
            __typename
            id
            description
            createdAt
            scheduleTime
            isHighlighted
            author {
              id
              firstName
              lastName
              fullName
            }
          }
          meetings {
            __typename
            id
            description
            createdAt
            startTime
            isHighlighted
            host {
              id
              firstName
              lastName
              fullName
            }
          }
          workshops {
            id
            title
            description
            createdAt
            startTime
            highlight
            endTime
            hostId
            startTime
            state
            isActive
          }
        }
        milestones {
          id
          milestoneType
          name
          weekNumber
        }
      }
      organization {
        id
        organizationImageUrl
      }
      projectAssignments {
        id
        user {
          id
          fullName
          firstName
          lastName
          email
          defaultProjectRole
          visibleToTeam
          discType
          avatarUrl
          pronouns
        }
        accessLevel
        role
        joinedAt
      }
      pendingInvitations {
        id
        recipient {
          id
          firstName
          lastName
          email
          fullName
          defaultProjectRole
          avatarUrl
        }
        recipientEmail
        acceptedAt
        accessLevel
        createdAt
      }
      slackAuthorization {
        id
        workspaceName
        createdAt
        channel {
          id
          name
        }
        postSettings {
          announcements
          huddles
          meetings
          tasks
          workshops
        }
      }
    }
  }
`;

function useProject(variables: ProjectByIdQueryVariables): {
  project?: ProjectByIdQuery["projectById"];
} & UseQueryResult<ProjectByIdQuery, unknown> {
  const response = useProjectByIdQuery(variables, {
    suspense: true,
    enabled: !!variables.id,
  });

  return {
    project: response.data?.projectById,
    ...response,
  };
}

export { useProject };
