import clsx from "clsx";
import { Base2 } from "components/Typography";
import { IconProps } from "phosphor-react";
import React from "react";

import { UserSettingsMenuTabs } from "./UserSettingsMenu";

type SettingsTabItemProps = {
  tabId: UserSettingsMenuTabs;
  title: string;
  isCurrent?: boolean;
  active?: boolean;
  Icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  onClick: () => void;
};

function UserSettingsTabItem({
  tabId,
  title,
  isCurrent,
  Icon,
  ...props
}: SettingsTabItemProps) {
  return (
    <button
      className={clsx(
        "flex flex-row items-center p-5 pl-14 text-neutral-90 hover:bg-neutral-50",
        isCurrent && "bg-neutral-10",
      )}
      data-testid={`user-settings-${tabId}-tab`}
      {...props}
    >
      <Icon size={24} color="currentColor" className="mr-4" />
      <Base2>{title}</Base2>
    </button>
  );
}

export default UserSettingsTabItem;
