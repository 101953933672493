import React from "react";

import { EmotionalSymbols } from "./EmotionalSymbol";
export const TopMoments = ({
  moment,
}: {
  moment: {
    __typename?: "TopMoment" | undefined;
    eyeCount?: number | null | undefined;
    heartCount?: number | null | undefined;
    questionCount: number;
    signalCount?: number | null | undefined;
    starCount: number;
    timeStamp: string;
  };
}) => {
  return (
    <div className="!mb-2 h-[64px] w-[332px]">
      <div className="  w-full rounded-lg bg-[#5F63680D] py-3">
        <div className="flex">
          <div className="ml-2">
            <p className="mb-2 h-[15px] w-[47px] text-[12px] font-normal  leading-[124%] tracking-[0.3px] text-[#5F6368]">
              {`${moment?.timeStamp}` || ""}
            </p>
            <p className="h-[12px] w-[70px] text-[10px] font-normal  leading-[124%] tracking-[0.3px] text-[#959A9F]">
              {moment?.signalCount || 0} signals
            </p>
          </div>

          <EmotionalSymbols symboldata={moment} />
        </div>
      </div>
    </div>
  );
};
