import I18n from "i18n-js";

import en from "./locales/en.json";

const DEFAULT_LOCALE = "en";

I18n.translations = { en: en };

function getLocaleFromUrl() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get("locale");
}

export function initializeI18n() {
  const locale = getLocaleFromUrl() || DEFAULT_LOCALE;

  const hasSupport = I18n.translations.hasOwnProperty(locale);

  hasSupport ? (I18n.locale = locale) : (I18n.defaultLocale = DEFAULT_LOCALE);
}
