import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment } from "react";

type DropdownProps = React.HTMLAttributes<HTMLDivElement> & {
  button: JSX.Element;
  items: JSX.Element;
  className?: string;
};

function Dropdown({ button, items, className, ...props }: DropdownProps) {
  return (
    <Menu
      as="div"
      className={clsx("relative inline-block text-left", className)}
      {...props}
    >
      {button}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-75"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-200"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-75"
      >
        {items}
      </Transition>
    </Menu>
  );
}
export default Dropdown;
