/* eslint-disable graphql/template-strings */
import { gql } from "@apollo/client";
import { TeamPersonalityProfileSubscription } from "graphql/generated";
import { useQueryClient } from "react-query";

import { useSubscription } from "./use-subscription";

export const PERSONALITY_PROFILE_SUBSCRIPTION = gql`
  subscription TeamPersonalityProfile($projectId: ID!) {
    teamPersonalityProfile(projectId: $projectId) {
      usersPersonalityProfiles {
        dominance {
          users {
            id
            fullName
            avatarUrl
            firstName
            lastName
          }
          percentage
          anonymousUserCount
          value
        }
        steadiness {
          users {
            id
            fullName
            avatarUrl
            firstName
            lastName
          }
          percentage
          anonymousUserCount
          value
        }
        conscientiousness {
          users {
            id
            fullName
            avatarUrl
            firstName
            lastName
          }
          percentage
          anonymousUserCount
          value
        }
        influence {
          users {
            id
            fullName
            avatarUrl
            firstName
            lastName
          }
          percentage
          anonymousUserCount
          value
        }
      }
    }
  }
`;

export interface UsePersonalityProfileProps {
  projectId: string;
}

export const usePersonalityProfileSubscription = ({
  projectId,
}: UsePersonalityProfileProps) => {
  const queryClient = useQueryClient();

  const output = useSubscription<TeamPersonalityProfileSubscription>(
    PERSONALITY_PROFILE_SUBSCRIPTION,
    {
      variables: {
        projectId: projectId,
      },

      async onData() {
        await queryClient.invalidateQueries("WorkshopById");
      },
    },
  );

  return {
    loading: output?.loading,
    getPersonalityProfile:
      output?.data?.teamPersonalityProfile?.usersPersonalityProfiles || {},
  };
};
