import { Link, ReadOnlyRichTextEditor } from "components/base";
import { FileItem } from "components/base/ResourceItem";
import { Caption2, Heading1, Overline } from "components/Typography";
import { format, parseISO } from "date-fns";
import { WorkshopByIdQuery, WorkshopParticipantRole } from "graphql/generated";
import { t } from "i18n-js";
import React from "react";

interface WaitingRoomIntroProps
  extends Pick<
    WorkshopByIdQuery["workshopById"],
    "startTime" | "description" | "links" | "documents"
  > {
  workshopIsToday: boolean;
  role: WorkshopParticipantRole;
}

export const WaitingRoomIntro = ({
  startTime,
  description,
  links,
  documents,
  role,
  workshopIsToday,
}: WaitingRoomIntroProps) => {
  return (
    <div className="my-auto flex flex-col gap-4 py-3 pl-4 pr-6">
      <Heading1>
        {workshopIsToday ? (
          t("workshop.waitingRoom.startingSoon")
        ) : (
          <>
            {t("workshop.waitingRoom.heading")}{" "}
            {format(parseISO(startTime || ""), "EEEE, MMM do")}
          </>
        )}
      </Heading1>
      <Overline className="text-neutral-70">
        {t("workshop.waitingRoom.description")}
      </Overline>
      <ReadOnlyRichTextEditor readOnlyContent={description as JSON} />

      {links && links.length > 0 && (
        <>
          <Overline className="text-neutral-70">
            {t("workshop.waitingRoom.links")}
          </Overline>

          {links
            ?.map((link) => ({
              ...link,
              extension: link.extension ?? undefined,
            }))
            .map((link) => (
              <Link key={link.url} metadata={link} />
            ))}

          {documents?.map((document) => (
            <FileItem key={document.id} resource={document} large />
          ))}
        </>
      )}
      {/* Recording disclaimer */}

      <div className="flex flex-col gap-2 rounded-md bg-neutral-90 px-3 py-2">
        <Caption2 className="text-neutral-white">
          {t("workshop.waitingRoom.recordingDisclaimer")}
        </Caption2>
        <Caption2 className="text-neutral-40">
          {t("workshop.waitingRoom.recordingDisclaimerFacilitatorSubtitle")}
        </Caption2>
        <ul>
          <li className="ml-2 list-disc font-sans text-2xs font-normal leading-ui tracking-wider text-neutral-40">
            {t(
              "workshop.waitingRoom.recordingDisclaimerFacilitatorSubtitleBulletPoint1",
            )}
          </li>
          <li className="ml-2 list-disc font-sans text-2xs font-normal leading-ui tracking-wider text-neutral-40">
            {t(
              "workshop.waitingRoom.recordingDisclaimerFacilitatorSubtitleBulletPoint2",
            )}
          </li>
        </ul>
        <Caption2 className="text-neutral-40">
          {t(
            "workshop.waitingRoom.recordingDisclaimerFacilitatorSubtitleEnding",
          )}
        </Caption2>
      </div>
    </div>
  );
};
