import React from "react";

function ProjectPhasesIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="53"
      viewBox="0 0 90 53"
      fill="none"
      {...props}
    >
      <rect
        x="1"
        y="1"
        width="31"
        height="51"
        rx="3"
        stroke="#F9B270"
        strokeWidth="2"
      />
      <rect
        x="36"
        y="1"
        width="20"
        height="51"
        rx="3"
        stroke="#F9B270"
        strokeWidth="2"
      />
      <path
        d="M90 1H63C61.3431 1 60 2.34315 60 4V49C60 50.6569 61.3431 52 63 52H90"
        stroke="#F9B270"
        strokeWidth="2"
      />
      <path
        d="M9.211 20H10.693V6.643H6.608L6.247 8.011H9.211V20Z"
        fill="#F9B270"
      />
      <path
        d="M40.988 20H50.279V18.632H43.23L47.467 14.604C48.892 13.255 49.918 12.058 49.918 10.196C49.918 7.897 48.113 6.415 45.643 6.415C42.603 6.415 41.007 8.543 40.893 10.861H42.413C42.508 9.341 43.496 7.84 45.567 7.84C47.258 7.84 48.36 8.866 48.36 10.291C48.36 11.583 47.619 12.495 46.574 13.502L40.988 18.841V20Z"
        fill="#F9B270"
      />
      <path
        d="M67.356 16.257H65.855C65.95 18.29 67.584 20.228 70.491 20.228C73.398 20.228 75.013 18.271 75.013 16.314C75.013 15.003 74.424 13.502 72.505 12.894C74.082 12.267 74.557 10.918 74.557 9.835C74.557 8.125 73.151 6.415 70.491 6.415C67.774 6.415 66.273 8.239 66.216 10.082H67.717C67.755 8.847 68.781 7.802 70.434 7.802C72.068 7.802 73.075 8.809 73.075 10.044C73.075 11.412 72.068 12.381 70.586 12.381H69.142V13.502H70.529C72.277 13.502 73.493 14.49 73.493 16.162C73.493 17.606 72.296 18.841 70.491 18.841C68.629 18.841 67.413 17.625 67.356 16.257Z"
        fill="#F9B270"
      />
    </svg>
  );
}

export default ProjectPhasesIcon;
