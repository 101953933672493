/* eslint-disable graphql/template-strings */
import { gql } from "@apollo/client";
import { RaisedHandSubscription } from "graphql/generated";
import { useQueryClient } from "react-query";

import { useSubscription } from "./use-subscription";

export const RAISED_HAND_SUBSCRIPTION = gql`
  subscription RaisedHand($workshopId: ID!) {
    participantRaisedHand(workshopId: $workshopId) {
      workshopParticipants {
        id
        raiseHand
      }
    }
  }
`;

export interface UseRaisedHandSubscriptionProps {
  workshopId: string;
}

export const useRaisedHandSubscription = ({
  workshopId,
}: UseRaisedHandSubscriptionProps) => {
  const queryClient = useQueryClient();

  const output = useSubscription<RaisedHandSubscription>(
    RAISED_HAND_SUBSCRIPTION,
    {
      variables: {
        workshopId: workshopId,
      },

      async onData() {
        await queryClient.invalidateQueries("WorkshopById");
      },
    },
  );

  return output;
};
