import { Modal } from "components/partial";
import { useCallback, useState } from "react";

export default function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  return { openModal, closeModal, isOpen, Modal };
}
