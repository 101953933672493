import { useUpdateWorkshopActivityMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation UpdateWorkshopActivity(
    $updateWorkshopActivity: UpdateWorkshopActivityInput!
  ) {
    updateWorkshopActivity(updateWorkshopActivity: $updateWorkshopActivity) {
      id
    }
  }
`;

export function useUpdateActivity() {
  const queryClient = useQueryClient();
  return useUpdateWorkshopActivityMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["WorkshopById"]);
      await queryClient.refetchQueries(["WorkshopById"]);
    },
  });
}
