/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Avatar } from "components/base";
import { HStack, VStack } from "components/layout";
import { User } from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AnnonymUserImage from "./AnnonymUser.png";
import ExtraUserCount from "./ExtraUserCount.png";

type ShowCardProps = {
  open: boolean;
  pieType: string;
  piePercentage: number | null | undefined;
  pieDescription: string;
  pieUsers: User[];
  AnnonymousUsers: number | null | undefined;
};
function HoverCard({
  open,
  pieType,
  piePercentage,
  pieDescription,
  pieUsers,
  AnnonymousUsers,
}: ShowCardProps) {
  const { projectId = "" } = useParams();
  const [showExtraUser, setShowExtraUser] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { hasViewAccess } = useProjectAccessLevel({ projectId });

  useEffect(() => {
    if (pieUsers != undefined) {
      const EXTRA_USERS = Object.keys(pieUsers).length - 9;
      {
        EXTRA_USERS > 0 ? setShowExtraUser(true) : setShowExtraUser(false);
      }
    }
  }, [pieUsers]);

  return (
    <>
      {open && (
        <div className="left-45 absolute -bottom-44 z-10 w-[343px] rounded-xl bg-neutral-900 p-4 text-white">
          <VStack justify="between">
            <HStack>{pieType} Type</HStack>
            <HStack className="text-xs text-tint-light-600">
              {pieDescription}
            </HStack>
            <VStack
              align="end"
              className="absolute top-4 right-4 flex flex-row text-neutral-700"
            >
              {Math.ceil(piePercentage as number)}%
            </VStack>
            {!hasViewAccess && (
              <div className=" !mt-1 ml-0 flex h-10 w-[300px] flex-row">
                {pieUsers &&
                  pieUsers.slice(0, 7)?.map((user) => {
                    return (
                      <HStack className="mr-3 h-auto flex-row">
                        <Avatar
                          size="small"
                          className="-ml-2 flex h-6 w-6 flex-row items-center border border-tint-dark-15"
                          textClassName="!text-xs !text-black "
                          user={user}
                        />
                      </HStack>
                    );
                  })}
                {AnnonymousUsers ? (
                  <HStack className="-ml-1 mr-1 h-8 w-10">
                    <img src={AnnonymUserImage}></img>
                  </HStack>
                ) : (
                  ""
                )}
                {showExtraUser ? (
                  <HStack className="h-8 w-10">
                    <img src={ExtraUserCount}></img>
                  </HStack>
                ) : (
                  ""
                )}
              </div>
            )}
          </VStack>
        </div>
      )}
    </>
  );
}

export default HoverCard;
