import { Heading3, Paragraph2 } from "components/Typography";
import React from "react";

/**
 * EmptyContentSection is used to indicate a content section for which
 * there is currently no value provided.
 */
export const EmptyContentSection = ({
  label,
  emptyMessage,
  endAdornment,
}: {
  label: string;
  emptyMessage: string;
  endAdornment: React.ReactNode;
}) => {
  return (
    <>
      <div className="mb-6 flex items-center justify-between">
        <Heading3>{label}</Heading3>

        {endAdornment}
      </div>
      <Paragraph2 className="mt-6 text-neutral-50">{emptyMessage}</Paragraph2>
    </>
  );
};
