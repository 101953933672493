import {
  PersonalTalkTimeBargraphQuery,
  usePersonalTalkTimeBargraphQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query PersonalTalkTimeBargraph {
    currentUserMetadatum {
      id
      averageParticipationScore
      lowestAverageParticipationScore
      highestAverageParticipationScore
    }
  }
`;

function usePersonalTalkTimeBargraph(): {
  personalTalkTimeBargraph: PersonalTalkTimeBargraphQuery["currentUserMetadatum"];
} & UseQueryResult<PersonalTalkTimeBargraphQuery, unknown> {
  const response = usePersonalTalkTimeBargraphQuery({}, { suspense: true });

  return {
    personalTalkTimeBargraph: response.data?.currentUserMetadatum,
    ...response,
  };
}

export { usePersonalTalkTimeBargraph };
