import clsx from "clsx";
import { Base2, Caption1, HStack } from "components";
import { Input, Pill } from "components/base";
import { useGetEnvsContext, useInviteUsers } from "contexts";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { CaretDown } from "phosphor-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EMAIL_REGEX } from "utils";

import { ACCESS_LEVEL_TEXT, AccessLevelDropdown } from ".";

export default function InviteProjectMembersHeader() {
  const {
    searchTerm,
    setSearchTerm,
    selectedUsers,
    deselectUser,
    selectedEmails,
    deselectEmail,
    inviteAccessLevel,
    setInviteAccessLevel,
    errorResponse,
    selectEmail,
  } = useInviteUsers();
  const { currentUser } = useGetEnvsContext();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    selectedEmails.includes(currentUser?.email || "")
      ? setErrorMessage(t("errors.userAlreadyExistsInProject"))
      : setErrorMessage("");
  }, [selectedEmails, currentUser]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      selectEmailHandler();
    }
  };

  const selectEmailHandler = () => {
    const email = searchTerm.trim().toLowerCase();
    if (!EMAIL_REGEX.test(email) || selectedEmails.includes(email))
      return false;
    else selectEmail(email);
  };

  const { projectId = "" } = useParams();
  const projectAccessLevel = useProjectAccessLevel({ projectId });

  const pillClasses = clsx(
    "my-2 mr-3 text-center max-w-[347px] word-break-word",
  );

  return (
    <div className="relative">
      <HStack
        align="center"
        justify="between"
        className="min-h-[48px] rounded-md bg-tint-dark-10"
      >
        <div className="min-h-12 flex w-full flex-wrap pl-4">
          {selectedUsers.map((user) => (
            <Pill
              key={user.id}
              fullName={user.fullName}
              onCancel={() => deselectUser(user)}
              className={pillClasses}
              classForTextContent="max-w-[317px] overflow-hidden"
            />
          ))}
          {selectedEmails.map((email) => (
            <Pill
              key={email}
              fullName={email}
              onCancel={() => deselectEmail(email)}
              className={pillClasses}
              classForTextContent="max-w-[317px] overflow-hidden"
            />
          ))}
          <Input
            placeholder={
              [...selectedUsers, ...selectedEmails].length === 0
                ? t("component.shareModal.inputPlaceholder")
                : ""
            }
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            value={searchTerm}
            className="w-full flex-1 overflow-visible border-none blur-none sm:bg-transparent md:bg-transparent xl:bg-transparent"
            contentClassName="flex-1"
          />
        </div>
        <HStack align="center" space={5} className={clsx("pr-2 pl-6")}>
          <AccessLevelDropdown
            currentLevel={inviteAccessLevel}
            onSelect={setInviteAccessLevel}
            canAssignAdmin={projectAccessLevel.hasAdminAccess}
            buttonContent={
              <HStack className="text-neutral-90" align="center" space={2}>
                <Base2 className="whitespace-nowrap">
                  {ACCESS_LEVEL_TEXT[inviteAccessLevel].label}
                </Base2>
                <CaretDown size={24} />
              </HStack>
            }
          />
        </HStack>
      </HStack>
      {errorMessage ? (
        <Caption1 className="mt-3 text-secondary-red-50">
          {errorMessage}
        </Caption1>
      ) : undefined}
      {!!errorResponse ? (
        <Caption1 className="mt-3 text-secondary-red-50">
          {errorResponse}
        </Caption1>
      ) : undefined}
    </div>
  );
}
