import { useAddParticipantsToBreakoutRoomMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation addParticipantsToBreakoutRoom(
    $input: [AddParticipantsToBreakoutRoomInput!]!
  ) {
    addParticipantsToBreakoutRoom(input: $input) {
      workshopParticipants {
        breakoutRoomTokens {
          token
          userAssignedToRoom
          dailyRoom {
            id
          }
        }
        id
        role
        roomToken
        user {
          avatarUrl
          currentSignInAt
          defaultProjectRole
          email
          fullName
          firstName
          lastName
          id
        }
      }
    }
  }
`;

export function useAddParticipantsToBreakoutRoom() {
  const queryClient = useQueryClient();
  return useAddParticipantsToBreakoutRoomMutation({
    onSuccess: async () => {
      await queryClient.refetchQueries(["WorkshopById"]);
      await queryClient.refetchQueries(["ParticipantsBreakoutsRooms"]);
    },
  });
}
