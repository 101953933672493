import React from "react";

function SChartIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3898_100932)">
        <path
          d="M55.6055 28C56.1578 28 56.6073 28.4481 56.5876 29C56.3355 36.0629 53.4193 42.7841 48.4045 47.799C43.3896 52.8138 36.6684 55.73 29.6055 55.9822C29.0535 56.0019 28.6055 55.5523 28.6055 55L28.6055 29C28.6055 28.4477 29.0532 28 29.6055 28H55.6055Z"
          fill="#2C8BC0"
        />
        <path
          d="M0.105469 5C0.105469 1.96243 2.5679 -0.5 5.60547 -0.5H28.6055H51.6055C54.643 -0.5 57.1055 1.96243 57.1055 5V28V51C57.1055 54.0376 54.643 56.5 51.6055 56.5H28.6055H5.60547C2.5679 56.5 0.105469 54.0376 0.105469 51V28V5Z"
          stroke="#5F6368"
          stroke-opacity="0.1"
        />
        <path
          d="M41.0655 36.06C40.9655 34.57 39.9755 33.85 38.5855 33.85C37.2955 33.85 36.2455 34.58 36.2455 35.77C36.2455 37.01 37.1155 37.4 38.0755 37.68L38.9055 37.92C39.6155 38.12 40.2255 38.34 40.2255 39.06C40.2255 39.87 39.6155 40.27 38.7155 40.27C37.7855 40.27 37.0655 39.83 37.0155 38.72H36.0455C36.1055 40.4 37.2355 41.12 38.7355 41.12C40.1455 41.12 41.1955 40.4 41.1955 39C41.1955 37.8 40.3255 37.38 39.3155 37.09L38.3055 36.8C37.6955 36.62 37.2055 36.43 37.2055 35.72C37.2055 35.06 37.8155 34.69 38.5755 34.69C39.3955 34.69 40.0055 35.08 40.0855 36.06H41.0655Z"
          fill="white"
        />
      </g>
      <rect
        x="1.10547"
        y="0.5"
        width="55"
        height="55"
        rx="27.5"
        stroke="#5F6368"
        stroke-opacity="0.1"
      />
      <defs>
        <clipPath id="clip0_3898_100932">
          <rect x="0.605469" width="56" height="56" rx="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SChartIcon;
