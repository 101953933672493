import React from "react";

function PulseCheckAverageIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 5H3.375L4.125 3.875L5.625 6.125L6.375 5H7.5M1.31786 3.4997C1.31431 3.43753 1.31252 3.37513 1.3125 3.3125C1.3126 2.74913 1.50781 2.20318 1.86494 1.76746C2.22208 1.33175 2.7191 1.03318 3.27149 0.922507C3.82389 0.811835 4.39757 0.895894 4.89499 1.16039C5.39242 1.42489 5.78288 1.85351 6 2.37336C6.21712 1.85351 6.60758 1.4249 7.105 1.1604C7.60243 0.895895 8.17611 0.811835 8.7285 0.922507C9.2809 1.03318 9.77792 1.33175 10.1351 1.76746C10.4922 2.20317 10.6874 2.74913 10.6875 3.3125C10.6875 6.5 6 9.125 6 9.125C6 9.125 4.12759 8.07646 2.74957 6.50005"
        stroke="#7F8489"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default PulseCheckAverageIcon;
