import clsx from "clsx";
import { useToast } from "components/provider";
import { Base2 } from "components/Typography";
import { noop } from "lodash";
import {
  CheckCircle,
  IconProps,
  Info,
  Warning,
  WarningOctagon,
  X,
} from "phosphor-react";
import React from "react";

export type ToastVariant = "info" | "success" | "error" | "warning";
export interface ToastProps {
  message: string;
  variant?: ToastVariant;
  className?: string;
  hasCloseOption?: boolean;
}

const variantIcons: Record<
  ToastVariant,
  React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >
> = {
  info: Info,
  success: CheckCircle,
  warning: Warning,
  error: WarningOctagon,
};

export type ToastComponent = React.FC<ToastProps>;
/**
 * Toast is a component used for notifications to the user.
 * Toasts generally shouldn't be rendered in-line, there's a Provider
 * which handles a ToastStack across the app so they can persist across
 * navigation and such.
 *
 * For 99% of cases, use `const { addToast } = useToast()` for pushing toasts.
 */
export const Toast: ToastComponent = ({
  message,
  variant = "info",
  className,
  hasCloseOption = false,
}) => {
  const { removeToast = noop } = useToast();
  const Icon = variantIcons[variant];
  return (
    <div
      className={clsx(
        "flex w-auto flex-row items-center justify-center overflow-hidden rounded-xl bg-neutral-900 py-3 px-4 shadow-lg",
        className,
      )}
    >
      <Icon
        size={24}
        // Someday it could be good to support different colors on a per-variant basis.
        // TODO: try config color again with some other props?
        color="#C0C5C9"
        className="mr-3 min-w-fit"
      />
      <Base2 className="overflow-hidden text-ellipsis whitespace-nowrap text-white">
        {message}
      </Base2>
      {hasCloseOption && (
        <X
          size={24}
          color="#C0C5C9"
          className="ml-4 cursor-pointer"
          onClick={() => removeToast({ message, variant })}
        />
      )}
    </div>
  );
};
