import { ReadOnlyRichTextEditor } from "components/base";
import { Heading1, Paragraph2 } from "components/Typography";
import React from "react";

export const DiscussionActivity = ({
  description,
  title,
}: {
  description: any;
  title?: string;
}) => (
  <div
    id="scroll-container"
    // note: `items-start` is to resolve issue with scroll fix for IE11 https://stackoverflow.com/a/33455342/8479303
    className="flex items-start overflow-y-auto py-16 pl-12"
  >
    <div
      id="discussion-activity"
      // note: don't use `justify-center` here (https://stackoverflow.com/a/33455342/8479303)
      className="my-auto flex flex-col gap-6 pl-4 pr-6"
    >
      <Heading1>{title}</Heading1>
      <Paragraph2 className="text-neutral-60">
        <ReadOnlyRichTextEditor
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
          readOnlyContent={description}
        />
      </Paragraph2>
    </div>
  </div>
);
