import { InteractionModal } from "components/partial";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { Check } from "phosphor-react";
import React from "react";
import { useParams } from "react-router-dom";

import CreateTaskForm from "./CreateTaskForm";

interface CreateTaskModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

function CreateTaskModal({
  isOpen,
  closeModal,
}: CreateTaskModalProps): JSX.Element {
  const { projectId = "" } = useParams();
  const hasAdminAccess = useProjectAccessLevel({ projectId }).hasAdminAccess;
  return (
    <InteractionModal
      isOpen={isOpen}
      closeModal={closeModal}
      header={t("component.createTask.heading")}
      description={t("component.createTask.subheading")}
      icon={<Check color="white" />}
      variant="gray"
      showHighlightFeature={true}
    >
      <CreateTaskForm
        onClose={closeModal}
        userHasAdminAccess={hasAdminAccess}
      />
    </InteractionModal>
  );
}

export default CreateTaskModal;
