import { Heading3 } from "components";
import React, { ForwardedRef, forwardRef } from "react";

type CreateButtonItemProps = {
  onClick?: () => void;
  text: string;
  icon?: JSX.Element;
  buttonRef?: ForwardedRef<HTMLButtonElement>;
};

function CreateButtonItem({
  onClick,
  text,
  icon,
  buttonRef,
}: CreateButtonItemProps): JSX.Element {
  return (
    <button
      className="flex w-full flex-row px-0 py-[13px] text-left"
      onClick={onClick}
      type="button"
      ref={buttonRef}
    >
      <div className="mr-2">{icon}</div>
      <Heading3 className="mb-1 block text-base leading-8 text-white">
        {text}
      </Heading3>
    </button>
  );
}

const CreateButtonItemWithRef = forwardRef<
  HTMLButtonElement,
  CreateButtonItemProps
>((props, ref) => <CreateButtonItem {...props} buttonRef={ref} />);

export default CreateButtonItemWithRef;
