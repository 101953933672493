import {
  FetchWorkshopTalkTimeQuery,
  FetchWorkshopTalkTimeQueryVariables,
  useFetchWorkshopTalkTimeQuery,
  WorkshopTalkTime,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query FetchWorkshopTalkTime($workshopId: ID!) {
    fetchWorkshopTalkTime(workshopId: $workshopId) {
      user {
        id
        firstName
        lastName
        fullName
        avatarUrl
      }
      talkTimeScore
      totalTalkTime
    }
  }
`;

function useFetchWorkshopTalkTime(
  variables: FetchWorkshopTalkTimeQueryVariables,
): {
  workshopTalkTime: WorkshopTalkTime[];
} & UseQueryResult<FetchWorkshopTalkTimeQuery, unknown> {
  const response = useFetchWorkshopTalkTimeQuery(variables);

  return {
    workshopTalkTime:
      (response.data?.fetchWorkshopTalkTime as WorkshopTalkTime[]) || [],
    ...response,
  };
}

export { useFetchWorkshopTalkTime };
