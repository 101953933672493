import { Agenda, WorkshopActivity } from "graphql/generated";

import { getFlattenedWorkshopActivities } from "./getFlattenedWorkshopActivities";

/**
 * This util fn gets the index of an activity in a flattened agenda.
 * We need to flatten the agenda before searching for the index because otherwise
 * the two dimensions of the agenda will create inaccurate indices.
 */
export const getIndexOfActivityInAgenda = (
  activity: WorkshopActivity,
  agenda: Agenda[] | undefined,
) => {
  const allActivities = getFlattenedWorkshopActivities(agenda);

  const index = allActivities?.findIndex((a) => a?.id === activity.id) || 0;
  return index;
};
