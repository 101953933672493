import clsx from "clsx";
import {
  GoogleDocsEmbed,
  ImageEmbed,
  MiroBoardEmbed,
} from "components/partial";
import { PDFEmbed } from "components/partial/PDFEmbed";
import { Heading1 } from "components/Typography";
import { Link, Resource } from "graphql/generated";
import React from "react";

import { ActivityType } from "../LiveWorkshopActivity/LiveWorkshopActivity.types";
import { ResourceActivityProps } from "./ResourceActivity.types";

export const ResourceActivity = ({
  activity,
  className,
  activityType,
  activityTitle = "",
}: ResourceActivityProps) => {
  const { link, documents } = activity as Resource;
  const fileUrl = documents?.[0]?.url;

  if (
    !activityType ||
    ((!link || link === null) && (!fileUrl || fileUrl === null))
  ) {
    // TODO: properly handle invalid resource input
    return (
      <div className="ml-[65px] flex h-full w-full items-center justify-center">
        <Heading1>{activityTitle || activity.__typename}</Heading1>
      </div>
    );
  }

  // TODO: prevent PDF from re-fetching & re-rendering every time.
  // TODO: add an error boundary here to catch and resource components' failed fetches (blocked by 403s or other reasons).
  return (
    <div id="resourceActivity" className={clsx("flex w-full", className)}>
      {activityType === ActivityType.Image && (
        <ImageEmbed
          src={fileUrl}
          // If there's ever a `description` field on the Resource it should be used in `alt` here.
          alt="Embedded Image"
          className="m-auto h-fit max-h-full w-fit max-w-full self-center"
        />
      )}

      {activityType === ActivityType.PDF && fileUrl && (
        <PDFEmbed fileUrl={fileUrl} />
      )}

      {activityType === ActivityType.Miro && (
        // TODO: what determines if `showMiroUi` should be true/false?
        <MiroBoardEmbed link={(link as Link).url} showMiroUi />
      )}

      {activityType === ActivityType.GoogleDoc && !!link && (
        <GoogleDocsEmbed link={link?.url} />
      )}
    </div>
  );
};
