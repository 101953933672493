import React, { createContext, MutableRefObject, useRef } from "react";

type RefContextType = {
  contextRef: MutableRefObject<HTMLDivElement | undefined>;
};

const RefContext = createContext<RefContextType | undefined>(undefined);

export function RefContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const contextRef = useRef<HTMLDivElement>();

  return (
    <RefContext.Provider value={{ contextRef }}>{children}</RefContext.Provider>
  );
}

export function useRefContext() {
  const context = React.useContext(RefContext);
  if (context === undefined)
    throw new Error("useRefContext must be used withing an ContextProvider");
  return context;
}
