export const Z_INDEX_LEVELS = {
  // For setting a neutral z-index level
  BASE: "z-0",
  // For control elements on top of the Base level
  BASE_CONTROL: "z-10",
  // For elements which absolutely must sit on top of any other Base level element
  BASE_OVERRIDE: "z-20",
  // For the Modal layer
  MODAL: "z-30",
  // For control elements on top of a Modal
  MODAL_CONTROL: "z-40",
  // For elements which absolutely must sit on top of any other Modal level element
  MODAL_OVERRIDE: "z-50",
};
