/* eslint-disable graphql/template-strings */
import { gql } from "@apollo/client";
import { BreakoutsRemainingTimeNotificationSubscription } from "graphql/generated";
import { useState } from "react";

import { useSubscription } from "./use-subscription";

export const BREAKOUT_TIME_REMAINING_SUBSCRIPTION = gql`
  subscription BreakoutsRemainingTimeNotification($workshopId: ID!) {
    breakoutsRemainingTimeNotification(workshopId: $workshopId) {
      breakoutActivity {
        id
        remainingTime
      }
    }
  }
`;

export interface UseBreakoutTimeRemainingSubscriptionProps {
  workshopId: string;
}

export const useBreakoutTimeRemainingSubscription = (
  { workshopId }: UseBreakoutTimeRemainingSubscriptionProps,
  onData: (timeRemaining: number) => void,
) => {
  const [first, setFirst] = useState(false);

  const output =
    useSubscription<BreakoutsRemainingTimeNotificationSubscription>(
      BREAKOUT_TIME_REMAINING_SUBSCRIPTION,
      {
        variables: {
          workshopId: workshopId,
        },
        onData(options) {
          const notification = options.data.data
            ?.breakoutsRemainingTimeNotification as {
            breakoutActivity: { id: string; remainingTime: number };
          };

          // We don't want to trigger the first time we get data as it runs on mount
          if (!first) {
            setFirst(true);
          }

          first && onData(notification.breakoutActivity.remainingTime);
        },
      },
    );

  return output;
};
