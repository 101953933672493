import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import { Base2, Caption1, HStack, Overline, VStack } from "components";
import { Avatar, Button, UserPill } from "components/base";
import { User } from "graphql/generated";
import { t } from "i18n-js";
import { noop } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import {
  ComboboxTriggerProps,
  MultiUserInputProps,
  UserItemProps,
  UserOptionsProps,
  UserSelectDropdownProps,
  UserWithProjectRole,
} from "./MultiUserInput.types";

const getDefaultSelectedUsers = (
  userIds: string[],
  usersWithProjectRole: UserWithProjectRole[],
) => usersWithProjectRole.filter((u) => userIds.includes(u.id || ""));

const MultiUserInput = React.forwardRef<HTMLInputElement, MultiUserInputProps>(
  (
    {
      label,
      placeholder,
      selectedSectionLabel,
      users,
      onSelectUserIds,
      error,
      clearError = noop,
      defaultSelectedUsers = [],
      userPillColor = "bg-secondary-indigo-70",
      userPillTextColor = "text-white",
    },
    ref,
  ) => {
    const [selectedUsers, setSelectedUsers] = useState<Partial<User>[]>(
      getDefaultSelectedUsers(defaultSelectedUsers, users),
    );

    const unselectedUsers = useMemo(() => {
      return users.filter((u) => !selectedUsers.includes(u));
    }, [users, selectedUsers]);

    useEffect(() => {
      const userIds = selectedUsers.map((u) => u.id || "");
      onSelectUserIds(userIds);
    }, [selectedUsers, onSelectUserIds]);

    const handleSelectUsers = (selectedUsers: Partial<User>[]) => {
      setSelectedUsers((users) => [...users, ...selectedUsers]);
    };

    const handleDeselectUser = (user: Partial<User>) => {
      setSelectedUsers((users) => users.filter((u) => u !== user));
    };

    useEffect(() => {
      error && selectedUsers.length > 0 && clearError();
    }, [selectedUsers, clearError, error]);

    return (
      <VStack space={4} className="relative">
        <UserSelectDropdown
          users={unselectedUsers}
          placeholder={placeholder}
          onSelect={handleSelectUsers}
          hasError={!!error}
          label={label}
          inputRef={ref}
          userPillColor={userPillColor}
          userPillTextColor={userPillTextColor}
        />
        {error ? (
          <Caption1 className="text-secondary-red-50">{error}</Caption1>
        ) : undefined}

        {selectedUsers.length > 0 ? (
          <VStack space={3}>
            {/* <Base2>{selectedSectionLabel}</Base2> */}
            <HStack
              space={2}
              data-testid="selected-users"
              className="overflow-x-auto"
            >
              {selectedUsers.map((user) => (
                <UserPill
                  key={user.id}
                  user={user}
                  onCancel={handleDeselectUser}
                  bgColor={userPillColor}
                  textColor={userPillTextColor}
                />
              ))}
            </HStack>
            {selectedUsers.length > 1 && (
              <Caption1 className="text-neutral-70">
                {t("shared.completeTaskIndividually")}
              </Caption1>
            )}
          </VStack>
        ) : undefined}
      </VStack>
    );
  },
);

function UserSelectDropdown({
  users,
  placeholder,
  onSelect,
  hasError = false,
  label,
  userPillColor = "bg-secondary-indigo-70",
  userPillTextColor = "text-white",
}: UserSelectDropdownProps) {
  const [selectedUsers, setSelectedUsers] = useState<Partial<User>[]>([]);
  const [query, setQuery] = useState<string | undefined>();
  const triggerRef = useRef<HTMLDivElement>(null);

  const unselectedUsers = useMemo(() => {
    const selectedIds = new Set(selectedUsers.map((user) => user.id));
    const filteredUsers = users.filter(({ id }) => !selectedIds.has(id));
    return filteredUsers;
  }, [users, selectedUsers]);

  const selectableUsers = useMemo(() => {
    return query === ""
      ? unselectedUsers
      : unselectedUsers.filter((user) => {
          if (user.fullName)
            return user?.fullName
              .toLowerCase()
              .includes(query?.toLowerCase() || "");
        });
  }, [query, unselectedUsers]);

  const handleSelect = () => {
    onSelect(selectedUsers);
    setSelectedUsers([]);
  };

  return (
    <Combobox value={selectedUsers} onChange={setSelectedUsers} multiple>
      {({ open }) => {
        return (
          <VStack
            space={2}
            className={clsx("relative", Z_INDEX_LEVELS.MODAL_CONTROL)}
          >
            <Combobox.Label>
              <Base2 as="label">{label}</Base2>
            </Combobox.Label>
            <div className={clsx("relative h-12", Z_INDEX_LEVELS.MODAL)}>
              <ComboboxTrigger innerRef={triggerRef} />
              <Combobox.Input
                placeholder={selectedUsers.length === 0 ? placeholder : ""}
                className={clsx(
                  "box-border h-full w-full rounded-md border-2 border-transparent bg-tint-dark-10 px-4 text-neutral-90 caret-primary-turquoise-70 transition-colors placeholder:text-base placeholder:text-tint-dark-50 focus:border-primary-turquoise-30 focus:outline-none disabled:cursor-not-allowed disabled:text-tint-dark-50",
                  hasError && "border-secondary-red-50",
                )}
                onChange={({ target }) =>
                  setQuery((target as { value: string }).value)
                }
                onFocus={() => triggerRef.current?.click()}
                displayValue={(person: User) => person.fullName}
              />
              {open ? (
                <Combobox.Options static>
                  <HStack
                    space={4}
                    className="absolute top-0 h-full w-full overflow-x-auto pl-3"
                    data-testid="pre-selected-users"
                    onClick={() => triggerRef.current?.click()}
                  >
                    {selectedUsers.map((user) => (
                      <UserPill
                        user={user}
                        key={user.id}
                        isComboboxOption
                        bgColor={userPillColor}
                        textColor={userPillTextColor}
                      />
                    ))}
                  </HStack>
                  <UserOptions
                    users={selectableUsers}
                    onSelect={handleSelect}
                  />
                </Combobox.Options>
              ) : undefined}
            </div>
          </VStack>
        );
      }}
    </Combobox>
  );
}

// This invisible trigger component enables us to instruct the dropdown to open when it is focused
function ComboboxTrigger({ innerRef }: ComboboxTriggerProps) {
  return (
    <Combobox.Button
      as="div"
      ref={innerRef}
      className="absolute top-0 h-0 w-0 cursor-text"
      data-testid="combobox-trigger"
    >
      &nbsp;
    </Combobox.Button>
  );
}

function UserOptions({ users, onSelect }: UserOptionsProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    buttonRef.current?.scrollIntoView();
  }, []);

  return (
    <VStack
      justify="between"
      className={clsx(
        "mb-4 mt-1.5 h-108 w-full overflow-auto rounded-xl bg-white shadow-lg",
        Z_INDEX_LEVELS.MODAL_CONTROL,
      )}
    >
      <VStack className="py-6 px-4">
        <Overline className="text-neutral-90">{t("shared.select")}</Overline>
        {users.map((user) => (
          <Combobox.Option key={user.id} value={user}>
            <UserItem user={user} />
          </Combobox.Option>
        ))}
      </VStack>

      <HStack
        className="w-full self-end pt-4 pb-6 pr-4 shadow-t-lg"
        justify="end"
      >
        <Combobox.Button as="div">
          <Button variant="secondary" onClick={onSelect} ref={buttonRef}>
            {t("shared.done")}
          </Button>
        </Combobox.Button>
      </HStack>
    </VStack>
  );
}

function UserItem({ user }: UserItemProps) {
  return (
    <HStack className="cursor-pointer py-5" justify="between" align="center">
      <HStack space={2}>
        <Avatar user={user} />
        <VStack space={1}>
          <Base2>{user.fullName}</Base2>
          <Caption1>{user.role}</Caption1>
        </VStack>
      </HStack>
      <Button variant="outline">{t("shared.addMember")}</Button>
    </HStack>
  );
}

export default MultiUserInput;
