import React from "react";

function LoadingSpinner(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="animate-spin"
      {...props}
    >
      <rect
        x="1.5"
        y="1.5"
        width="26"
        height="26"
        rx="13"
        stroke="#E5E8EA"
        strokeWidth="3"
      />
      <path
        d="M27.5 14.5C27.5 11.9288 26.7376 9.41543 25.3091 7.27759C23.8807 5.13975 21.8503 3.47351 19.4749 2.48957C17.0995 1.50563 14.4856 1.24819 11.9638 1.7498C9.44208 2.25141 7.1257 3.48954 5.30762 5.30762C3.48953 7.1257 2.25141 9.44208 1.7498 11.9638C1.24819 14.4856 1.50563 17.0995 2.48957 19.4749C3.47351 21.8503 5.13975 23.8807 7.27759 25.3091"
        stroke="#16C7A7"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default LoadingSpinner;
