import React from "react";

function DecisionSmallIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_46_1547)">
        <path
          d="M18.2266 22.0777V25.7255C18.3524 25.7318 18.4767 25.7445 18.6042 25.7445C20.5939 25.7445 22.5021 24.9541 23.909 23.5472C25.316 22.1402 26.1064 20.232 26.1064 18.2423C26.1064 18.0175 26.0921 17.7962 26.0728 17.5764H22.7279C21.5345 17.5777 20.3903 18.0524 19.5464 18.8963C18.7025 19.7401 18.2279 20.8843 18.2266 22.0777Z"
          fill="#F2F4F4"
        />
        <path
          d="M22.7284 14.5756H25.1463C24.5738 13.5524 23.7693 12.6777 22.7974 12.0217C21.8256 11.3657 20.7134 10.9468 19.5503 10.7985C18.3872 10.6502 17.2056 10.7767 16.1002 11.1678C14.9948 11.559 13.9966 12.2038 13.1856 13.0507C12.3747 13.8975 11.7735 14.9227 11.4306 16.0439C11.0876 17.1652 11.0123 18.3512 11.2107 19.5068C11.4092 20.6624 11.8758 21.7554 12.5732 22.698C13.2705 23.6405 14.1792 24.4065 15.2262 24.9343V22.0778C15.2284 20.0888 16.0195 18.1818 17.426 16.7754C18.8324 15.3689 20.7394 14.5778 22.7284 14.5756Z"
          fill="#F2F4F4"
        />
        <path
          d="M7.5022 14.5754H1.50044C1.1025 14.5754 0.720856 14.7335 0.439469 15.0149C0.158082 15.2963 0 15.6779 0 16.0759C0 16.4738 0.158082 16.8555 0.439469 17.1369C0.720856 17.4182 1.1025 17.5763 1.50044 17.5763H7.5022C7.90014 17.5763 8.28178 17.4182 8.56317 17.1369C8.84456 16.8555 9.00264 16.4738 9.00264 16.0759C9.00264 15.6779 8.84456 15.2963 8.56317 15.0149C8.28178 14.7335 7.90014 14.5754 7.5022 14.5754Z"
          fill="#A7A8B0"
        />
        <path
          d="M34.6692 10.1328C34.3878 9.85142 34.0062 9.69336 33.6083 9.69336C33.2104 9.69336 32.8288 9.85143 32.5475 10.1328C32.2661 10.4141 32.108 10.7957 32.108 11.1936C32.108 11.5915 32.2661 11.9731 32.5475 12.2545L34.8678 14.5755H22.7282C20.7391 14.5777 18.8322 15.3688 17.4257 16.7752C16.0192 18.1817 15.2281 20.0886 15.226 22.0777V27.1886C13.6767 27.5659 12.32 28.499 11.4135 29.8107C10.5069 31.1224 10.1137 32.7214 10.3085 34.304C10.5033 35.8866 11.2726 37.3425 12.4702 38.3952C13.6678 39.4479 15.2103 40.0241 16.8048 40.0144C18.3993 40.0047 19.9346 39.4096 21.1193 38.3424C22.304 37.2751 23.0554 35.8099 23.2308 34.2251C23.4063 32.6403 22.9936 31.0462 22.0711 29.7456C21.1486 28.4451 19.7806 27.5286 18.2269 27.1703V22.0777C18.2282 20.8843 18.7028 19.7401 19.5467 18.8962C20.3906 18.0523 21.5347 17.5777 22.7282 17.5764H34.8675L32.5475 19.8964C32.2661 20.1777 32.108 20.5593 32.108 20.9572C32.108 21.3551 32.2661 21.7367 32.5475 22.0181C32.8288 22.2995 33.2104 22.4575 33.6083 22.4575C34.0062 22.4575 34.3878 22.2995 34.6692 22.0181L39.5505 17.1368C39.6898 16.9975 39.8004 16.8321 39.8758 16.6501C39.9512 16.4681 39.9901 16.273 39.9901 16.0759C39.9901 15.8789 39.9512 15.6838 39.8758 15.5018C39.8004 15.3197 39.6898 15.1544 39.5505 15.0151L34.6692 10.1328ZM20.2636 33.501C20.2596 34.2995 19.9794 35.0721 19.4705 35.6876C18.9616 36.303 18.2554 36.7234 17.4718 36.8773C16.6882 37.0312 15.8754 36.9091 15.1716 36.5319C14.4677 36.1546 13.9161 35.5454 13.6104 34.8076C13.3047 34.0699 13.2637 33.249 13.4945 32.4845C13.7252 31.72 14.2135 31.0589 14.8763 30.6134C15.5391 30.168 16.3356 29.9657 17.1307 30.0408C17.9257 30.1159 18.6703 30.4639 19.2379 31.0256C19.564 31.3499 19.8226 31.7357 19.9986 32.1606C20.1747 32.5855 20.2647 33.041 20.2636 33.501Z"
          fill="#A7A8B0"
        />
        <path
          d="M16.727 9.00264C17.1249 9.00253 17.5065 8.84442 17.7879 8.56305C18.0692 8.28169 18.2273 7.90011 18.2274 7.5022V1.50044C18.2274 1.1025 18.0694 0.720856 17.788 0.439469C17.5066 0.158082 17.1249 0 16.727 0C16.3291 0 15.9474 0.158082 15.666 0.439469C15.3846 0.720856 15.2266 1.1025 15.2266 1.50044V7.5022C15.2267 7.90011 15.3848 8.28169 15.6661 8.56305C15.9475 8.84442 16.3291 9.00253 16.727 9.00264Z"
          fill="#A7A8B0"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_1547">
          <rect width="39.9902" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DecisionSmallIcon;
