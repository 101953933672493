import { HStack } from "components/layout";
import ToolTip from "components/partial/ToolTip/ToolTip";
import { Base2 } from "components/Typography";
import { ArrowDownRight, ArrowUpRight } from "phosphor-react";
import React, { useState } from "react";

type EmotionalExpressionDataProps = {
  timestamp: string | null | undefined;
  sentiment: string | null | undefined;
};

function EmotionalExpressionData({
  timestamp,
  sentiment,
}: EmotionalExpressionDataProps) {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  function MouseEnter() {
    setShowTooltip(true);
  }
  function MouseLeave() {
    setShowTooltip(false);
  }
  return (
    <HStack className="relative mt-6 h-11 w-1/3 items-center rounded-lg bg-tint-dark-100 p-4 hover:cursor-pointer">
      <HStack className="h-5 w-[105px]">{timestamp}</HStack>
      {sentiment === "Positive" ? (
        <HStack
          onMouseLeave={MouseLeave}
          onMouseEnter={MouseEnter}
          className="h-5 w-[auto] items-center space-x-1 bg-tint-light-900 text-secondary-green-700"
        >
          <ArrowUpRight size={10} />
          <Base2 className="!whitespace-nowrap !text-xs ">
            {sentiment} sentiment
          </Base2>
          {showTooltip && (
            <HStack className="absolute top-12 right-10">
              <ToolTip
                message="Positive sentiment is a summary metric that combines the expression of positive emotion words (e.g. love, joy, amusement, calm, gratitude, and excitement).   Positive emotions can signal topics of shared interest, and
                  the moments after conflict when groups experience as
                  collective a-ha moment."
              />
            </HStack>
          )}
        </HStack>
      ) : (
        <HStack
          onMouseLeave={MouseLeave}
          onMouseEnter={MouseEnter}
          className="h-5 w-[auto] items-center space-x-1 bg-tint-light-900 text-secondary-red-700"
        >
          <ArrowDownRight size={10} />
          <Base2 className="!whitespace-nowrap !text-xs">
            {sentiment} sentiment
          </Base2>
          {showTooltip && (
            <HStack className="absolute top-12 right-10">
              <ToolTip
                message="Negative sentiment is a summary metric that combines the expression of negative emotion words (e.g. anger, fear, disgust, sadness, boredom).
                Negative emotions can signal areas of concern that unless addressed might lead to future conflict as well as critical thinking."
              />
            </HStack>
          )}
        </HStack>
      )}
    </HStack>
  );
}
export default EmotionalExpressionData;
