import {
  ProjectInvitationsQuery,
  ProjectInvitationsQueryVariables,
  useProjectInvitationsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query ProjectInvitations($projectId: ID!, $scope: ProjectInvitationsScope!) {
    projectInvitations(projectId: $projectId, scope: $scope) {
      id
      recipient {
        id
        firstName
        lastName
        email
        fullName
        avatarUrl
        pronouns
        company
        defaultProjectRole
      }
      recipientEmail
      project {
        id
        name
      }
      accessLevel
      acceptedAt
      isActive
    }
  }
`;

function useProjectInvitations(variables: ProjectInvitationsQueryVariables): {
  invitations: ProjectInvitationsQuery["projectInvitations"];
} & UseQueryResult<ProjectInvitationsQuery, unknown> {
  const response = useProjectInvitationsQuery(variables, { suspense: true });

  return {
    invitations: response.data?.projectInvitations || [],
    ...response,
  };
}

export { useProjectInvitations };
