import { Caption2 } from "components/Typography";
import React from "react";

import { badgeVariant } from "../SignalDropdown.types";
import { getTimeSinceString } from "../utils";
import SignalBadge from "./SignalBadge";

const EmotionalSignal = ({
  signal,
  time,
}: {
  signal?: badgeVariant;
  time?: number;
}) => {
  if (signal && time) {
    const timeSince = getTimeSinceString(time);

    return (
      <div
        className="mr-2 flex flex-col items-center p-2 first:ml-4"
        key={`${signal}_${time}`}
      >
        <SignalBadge variant={signal} />
        <Caption2 className="mt-2 whitespace-nowrap text-neutral-600">
          {timeSince}
        </Caption2>
      </div>
    );
  }

  // eslint-disable-next-line unicorn/no-null
  return null;
};

export default EmotionalSignal;
