import clsx from "clsx";
import { HStack, VStack } from "components/layout/Stack";
import { Base2, Caption1, Caption2, Heading3 } from "components/Typography";
import { Milestone, Phase } from "graphql/generated";
import { useModal } from "hooks";
import { t } from "i18n-js";
import { noop } from "lodash";
import { PencilSimple, Plus } from "phosphor-react";
import React, { useRef, useState } from "react";
import getWeeksFromProjectPerPhases, {
  WeeksFromProjectPerPhasesReturn,
} from "utils/helpers/get-weeks-from-project-per-phases";

import { DefaultFieldsType } from "../types";
import { milestoneTypes } from "./MilestonesFormSidebar/MilestonesFormMilestoneTypeList";
import MilestonesFormSidebar from "./MilestonesFormSidebar/MilestonesFormSidebar";

type MilestonesFormPhasesProps = {
  fields: DefaultFieldsType;
  weeksPerPhase: WeeksFromProjectPerPhasesReturn[][];
  setWeeksPerPhase: React.Dispatch<
    React.SetStateAction<WeeksFromProjectPerPhasesReturn[][]>
  >;
  isEditing?: boolean;
};

type HandleSelectPhase = {
  phase: Partial<Phase>;
  phaseIndex: number;
  week: string;
  weekIndex: number;
};

type EditButtonProps = {
  onClick: () => void;
};

export default function MilestonesFormPhases({
  fields: s,
  weeksPerPhase,
  setWeeksPerPhase,
  isEditing = false,
}: MilestonesFormPhasesProps) {
  const [fields, setFields] = useState(s);
  const { phases } = fields;

  const [isFilled, setIsFilled] = useState(false);
  const { isOpen, openModal, closeModal } = useModal();
  const dragItem = useRef<number>(Number.NaN);
  const dragOverItem = useRef<number>(Number.NaN);
  const [dataSelected, setDataSelected] = useState<HandleSelectPhase>();

  const handleSetWeekPerPhase = (
    newWeeksPerPhase: WeeksFromProjectPerPhasesReturn[][],
  ) => {
    setWeeksPerPhase(newWeeksPerPhase);

    const newFields = { ...fields };

    for (const [phaseId, phase] of weeksPerPhase.entries()) {
      let newMilestones: Milestone[] = [];
      phase.map((week, weekId) => {
        const { milestoneType, name } = week;
        if (milestoneType && name) {
          newMilestones.push({ milestoneType, name, weekNumber: weekId });
        }
      });
      newFields.phases![phaseId].milestones = newMilestones;
      newMilestones = [];
    }

    setFields(newFields);
  };

  const handleSelect = (
    isFilled: boolean,
    { phase, phaseIndex, week, weekIndex }: HandleSelectPhase,
  ) => {
    setIsFilled(isFilled);
    setDataSelected({ phase, phaseIndex, week, weekIndex });
    openModal();
  };

  function EditButton({ onClick }: EditButtonProps) {
    return (
      <button
        data-testid="edit-project"
        className="hover:bg-tint-light-10 absolute -top-2 -right-1 flex h-5 w-5 items-center justify-center rounded-full border border-tint-dark-30 bg-black focus:outline-none focus:ring focus:ring-primary-turquoise-10 focus:ring-offset-1"
        type="button"
        onClick={onClick}
      >
        <PencilSimple height={15} width={15} weight="light" color="white" />
      </button>
    );
  }

  const onDragSart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    dragItem.current = index;
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    dragOverItem.current = index;
  };

  const drop = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    const copyListItems = [...weeksPerPhase[index]];

    const dragItemContent = copyListItems[dragItem.current];
    const dragItemWeekText = copyListItems[dragItem.current].weekText;
    const droppedItemContent = copyListItems[dragOverItem.current];
    const droppedItemWeekText = copyListItems[dragOverItem.current].weekText;

    copyListItems[dragItem.current] = droppedItemContent;
    copyListItems[dragOverItem.current] = dragItemContent;

    copyListItems[dragItem.current].weekText = dragItemWeekText;
    copyListItems[dragOverItem.current].weekText = droppedItemWeekText;

    const newFields = { ...fields };
    const newMilestones: Milestone[] = [];

    const { phases } = fields;

    const { milestones: originalMilestones } = phases![index];

    for (const [i, copyItem] of copyListItems.entries()) {
      for (const originalMilestone of originalMilestones!) {
        if (copyItem.milestoneType === originalMilestone.milestoneType) {
          const { id, milestoneType, name } = originalMilestone;
          newMilestones.push({
            id,
            milestoneType,
            name,
            weekNumber: i,
          });
          break;
        }
      }
    }
    newFields.phases![index].milestones = newMilestones;

    setWeeksPerPhase(getWeeksFromProjectPerPhases(newFields));
    setFields(newFields);
    dragItem.current = Number.NaN;
    dragOverItem.current = Number.NaN;
  };

  return (
    <div className="flex  w-full gap-4 overflow-x-auto px-14 pb-12 text-left">
      {phases?.map((phase, phaseIndex) => {
        const differenceInTimeForCurrentIndex =
          Date.now() - new Date(`${phase?.endDate || ""} 23:59:59`).getTime();
        const isActiveIndex = differenceInTimeForCurrentIndex > 0;

        let width = 330;
        if (phase.weeksLength && phase.weeksLength > 4)
          width = phase.weeksLength * 80 + phase.weeksLength * 8;
        else if (phase.weeksLength && phase.weeksLength <= 4) width = 394;
        return (
          <div>
            <div
              className="table flex-col gap-4 rounded-lg border border-tint-dark-30 p-6"
              key={phase.name}
              style={{ width, minWidth: 330 }}
            >
              <HStack justify="between" className="w-full">
                <p className="mt-4 ml-2 -translate-y-8 -rotate-90 text-neutral-40 transition-colors group-hover:text-neutral-90">
                  Phase
                  <p className="h2 -mr-8 -translate-x-3 rotate-90 font-sans text-7xl font-extralight">
                    {phaseIndex + 1}
                  </p>
                </p>
                <Caption1 className="mt-4 text-neutral-90">
                  {`${phase?.weeksLength || 0} ${
                    phase.weeksLength && phase?.weeksLength > 1
                      ? t("shared.weeks")
                      : t("shared.week")
                  }`}
                </Caption1>
              </HStack>
              <VStack
                className="min-h-[160px]"
                space={4}
                style={{ maxWidth: width <= 330 ? 300 : 640 }}
              >
                <Heading3 className="max-h-[80px] min-h-[80px] w-full text-neutral-90">
                  {phase.name}
                </Heading3>
                <Base2
                  className="text-neutral-70 line-clamp-4 "
                  style={{ height: "80px" }}
                >
                  {phase.goal}
                </Base2>
              </VStack>
              <div
                className={clsx(
                  "mt-12 flex w-full gap-2",
                  isEditing && "cursor-pointer",
                )}
              >
                {weeksPerPhase[phaseIndex]?.map((week, weekIndex) => (
                  <VStack
                    space={4}
                    key={weekIndex}
                    className="group"
                    align="center"
                    onDragStart={(e) => onDragSart(e, weekIndex)}
                    onDragEnter={(e) => onDragEnter(e, weekIndex)}
                    onDragEnd={(e) => drop(e, phaseIndex)}
                    draggable={isActiveIndex ? false : true}
                    onClick={
                      isActiveIndex
                        ? noop
                        : () => {
                            handleSelect(week.milestoneType !== undefined, {
                              phase,
                              week: week.weekText,
                              phaseIndex,
                              weekIndex,
                            });
                          }
                    }
                  >
                    <Caption1
                      className="invisible text-neutral-60 group-hover:visible "
                      style={{ fontSize: "9px" }}
                    >
                      {week.weekText}
                    </Caption1>

                    {week.milestoneType !== undefined && (
                      <HStack
                        align="center"
                        justify="center"
                        className={`relative mr-2 flex h-[96px]  w-[72px] flex-col  rounded-lg bg-neutral-0 pt-2 transition duration-500 ease-out ${
                          isActiveIndex
                            ? ""
                            : "hover:scale-110 hover:shadow-milestoneCard"
                        }`}
                      >
                        {milestoneTypes[week.milestoneType].selectedIcon}
                        <Caption2 className="mt-4 mb-2 text-center text-neutral-90">
                          {t(`milestone.${week.milestoneType}`)}
                        </Caption2>
                        {!isActiveIndex ? (
                          <EditButton onClick={() => noop} />
                        ) : undefined}
                      </HStack>
                    )}
                    {week.milestoneType === undefined && (
                      <HStack
                        align="center"
                        justify="center"
                        className="flex h-[96px] w-[72px] cursor-pointer rounded-lg border border-dashed border-primary-turquoise-30 transition duration-500 ease-out group-hover:border-primary-turquoise-10 group-hover:bg-primary-turquoise-10"
                      >
                        <Plus
                          size={32}
                          className="text-primary-turquoise-70 group-hover:text-neutral-90"
                        />
                      </HStack>
                    )}
                  </VStack>
                ))}
              </div>
            </div>
          </div>
        );
      })}
      <MilestonesFormSidebar
        isOpen={isOpen}
        closeModal={closeModal}
        phaseIndexSelected={dataSelected?.phaseIndex}
        phaseDataSelected={dataSelected?.phase}
        weekSelected={dataSelected?.week || ""}
        weekIndexSelected={dataSelected?.weekIndex || 0}
        weeksPerPhase={weeksPerPhase}
        setWeeksPerPhase={handleSetWeekPerPhase}
        isEditing={isFilled}
      />
    </div>
  );
}
