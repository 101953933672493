import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Center, HStack, VStack } from "components";
import { Button, Link as Linking, OrganizationAvatar } from "components/base";
import { SettingsMenu, UserSettings } from "components/pages";
import { useGetEnvsContext } from "contexts";
import { isPast, parseISO } from "date-fns";
import {
  AccessLevel,
  CurrentUserQuery,
  ProjectStatus,
  Scalars,
  UserOrganizationsQuery,
  UserProjectsQuery,
} from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import useModal from "hooks/use-modal";
import { t } from "i18n-js";
import { ProjectViewIcon } from "icons";
import { groupBy, sortBy, uniqBy } from "lodash";
import { ArrowsDownUp, Plus, X } from "phosphor-react";
import {
  useProject,
  useProjectAssignments,
  useUserOrganizations,
  useUserProjects,
} from "queries";
import React, { Fragment, ReactNode, Suspense, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import { EmbeddedLinks } from "./CreateInteractions/CreateAnnouncementForm";
import { EditButton } from "./pages/Project/ProjectViewHeader";
import { EditProjectModal } from "./partial";
import { Base2, Caption1, Overline, Paragraph1 } from "./Typography";
import { updateDocumentTitle } from "./utils/updateDocumentTitle/updateDocumentTitle";

const menuItemFocusStyles =
  "outline-none focus:ring focus:ring-primary-turquoise-10 focus:ring-offset-1";

type SettingsMenuButtonProps = {
  onClick: () => void;
  isSelected?: boolean;
  testId: string;
  selectOnGroupFocus?: boolean;
  containerClassName?: string;
};
function SettingsMenuButton({
  onClick,
  isSelected = false,
  testId,
  selectOnGroupFocus = true,
  containerClassName = "",
}: SettingsMenuButtonProps) {
  return (
    <div
      className={clsx(
        containerClassName,
        `opacity-0 transition-opacity focus:opacity-100 group-hover:opacity-100 ${
          selectOnGroupFocus ? "group-focus:opacity-100" : ""
        }`,
      )}
    >
      <Button
        onClick={onClick}
        data-testid={testId}
        variant={isSelected ? "contextualContrast" : "contextual"}
      >
        {t("shared.manage")}
      </Button>
    </div>
  );
}

type OrganizationMenuItemProps = {
  organizationId: string;
  onClick: () => void;
  organizationName: string;
  organizationImageUrl: string;
};

function OrganizationMenuItem({
  organizationId,
  organizationName,
  organizationImageUrl = "",
  onClick,
  ...props
}: OrganizationMenuItemProps) {
  return (
    <div
      tabIndex={1}
      className={clsx(
        menuItemFocusStyles,
        "group relative flex flex-row items-center justify-between p-4",
      )}
      {...props}
    >
      <div className="flex flex-row items-center space-x-4">
        <OrganizationAvatar
          organizationImageUrl={organizationImageUrl}
          organizationName={organizationName}
        />
        <Overline className="truncate text-neutral-5">
          {organizationName?.length > 28
            ? organizationName?.slice(0, 25) + "..."
            : organizationName}
        </Overline>
      </div>
      <SettingsMenuButton
        onClick={onClick}
        testId={`org-settings-button-${organizationId}`}
        containerClassName="absolute right-4 pl-16 from-neutral-90 via-neutral-90 bg-gradient-to-l"
      />
    </div>
  );
}

type UserMenuItemProps = {
  currentUser: CurrentUserQuery["currentUser"];
  onClick: () => void;
};

export function UserMenuItem({
  currentUser,
  onClick,
  ...props
}: UserMenuItemProps) {
  const [oldsidebarIsOpen, setOldSidebarIsOpen] = useState(false);
  const { projectId = "" } = useParams();
  const { project } = useProject({ id: projectId });
  const { projects } = useUserProjects();
  const { organizations } = useUserOrganizations();
  const projectsByOrganization = groupProjectsByOrganization(projects);

  const title = project
    ? project?.name
    : t("component.projectSwitcher.yourProjects");
  if (!currentUser) return <></>;
  const { id, email } = currentUser;

  if (!id || !email) {
    return <></>;
  }

  const orgNames = getSortedOrganizations(organizations, projects);
  const currentOrgname = orgNames.find(
    (item) => item.id === project?.organization.id,
  );

  let trimTitle;
  if (title.length >= 15) {
    trimTitle = title.slice(0, 15) + "...";
  }

  return (
    <div
      tabIndex={2}
      className="group flex h-16 w-[340px] cursor-pointer flex-row items-center justify-between gap-3 rounded-lg bg-tint-light-200 py-2 px-3"
      {...props}
      onClick={() => setOldSidebarIsOpen(true)}
      data-testid="user-menu-clickable"
    >
      {!!currentOrgname?.organizationImageUrl ? (
        <img
          className="!h-[31px] !w-[34px] rounded-full"
          src={currentOrgname?.organizationImageUrl}
        />
      ) : (
        <ProjectViewIcon />
      )}

      <div className="!w-[270px] ">
        <Caption1 className="text-xs font-normal text-neutral-5 ">
          {currentOrgname?.name?.length > 28
            ? [...currentOrgname?.name.slice(0, 25), "..."]
            : currentOrgname?.name}
        </Caption1>
        <div className="!h-6 text-lg font-medium text-white">
          {trimTitle ? trimTitle : title}
        </div>
      </div>

      <ArrowsDownUp className="!ml-6" size={32} color="#ffffff" />

      <SidebarModal
        open={oldsidebarIsOpen}
        onClose={() => setOldSidebarIsOpen(false)}
        setSidebarAsOpen={() => setOldSidebarIsOpen(true)}
      />
    </div>
  );
}

type ProjectMenuItemProps = {
  projectId: string;
  projectName: string;
  projectStatus: string;
  current?: boolean;
  closeSidebar: () => void;
  onClick: () => void;
};

function ProjectMenuItem({
  projectId,
  projectName,
  projectStatus,
  current,
  closeSidebar,
  onClick,
  ...props
}: ProjectMenuItemProps) {
  const navigate = useNavigate();
  const { currentUser } = useGetEnvsContext();
  const { projectAssignments } = useProjectAssignments({ projectId });

  const handleClick = () => {
    navigate(`/projects/${projectId}`);
    closeSidebar();
  };

  const hasViewOnlyAccess = projectAssignments.some(
    (assignment) =>
      assignment?.user?.id === currentUser?.id &&
      assignment?.accessLevel === AccessLevel.ViewAccess,
  );

  return (
    <>
      {projectStatus !== ProjectStatus.Archived ? (
        <button
          className={clsx(
            menuItemFocusStyles,
            "group relative flex h-12 w-full flex-row items-center justify-between p-3.5 pl-16",
            current
              ? "bg-neutral-5"
              : "duration-250 transition focus-within:bg-neutral-70 hover:bg-neutral-70",
          )}
          data-testid={`project-menu-item-${projectId}`}
          onClick={handleClick}
          {...props}
        >
          <Base2
            className={clsx(
              "truncate",
              current ? "text-black" : "text-gray-100",
            )}
          >
            {projectName}
          </Base2>
          {!hasViewOnlyAccess && (
            <SettingsMenuButton
              onClick={onClick}
              isSelected={current}
              testId={`project-settings-button-${projectId}`}
              containerClassName={clsx(
                "absolute right-4 pl-16",
                current
                  ? "bg-gradient-to-l from-neutral-5 via-neutral-5"
                  : "bg-gradient-to-l from-neutral-70 via-neutral-70",
              )}
            />
          )}
        </button>
      ) : (
        <></>
      )}
    </>
  );
}

type NewProjectMenuItemProps = {
  organizationId: string;
};

function NewProjectMenuItem({
  organizationId,
  ...props
}: NewProjectMenuItemProps) {
  return (
    <Link
      to={`/organizations/${organizationId}/project/new`}
      className={clsx(
        menuItemFocusStyles,
        "flex flex-row items-center p-3.5 pl-5 text-primary-turquoise-10 hover:bg-neutral-70",
      )}
      {...props}
    >
      <Plus size={24} />
      <Base2 className="ml-5">
        {t("component.organizationMenu.newProject")}
      </Base2>
    </Link>
  );
}

function groupProjectsByOrganization(projects: UserProjectsQuery["projects"]) {
  return groupBy(
    sortBy(projects, ({ name }) => name),
    ({ organization: { id } }) => id,
  );
}

export function getSortedOrganizations(
  organizations: UserOrganizationsQuery["organizations"],
  projects: UserProjectsQuery["projects"],
) {
  const projectOrganizations = projects.map((project) => project.organization);

  return sortBy(
    uniqBy([...projectOrganizations, ...organizations], ({ id }) => id),
    ({ name }) => name,
  );
}
// TODO: Duplicate of the Overlay partial component
function Overlay() {
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog.Overlay
        className={clsx(
          "fixed inset-0 bg-[#222222] bg-opacity-80",
          Z_INDEX_LEVELS.MODAL,
        )}
      />
    </Transition.Child>
  );
}

type SidebarModalProps = {
  open: boolean;
  onClose: () => void;
  setSidebarAsOpen: () => void;
};

export function SidebarModal({
  open,
  onClose,
  setSidebarAsOpen,
}: SidebarModalProps) {
  const { projects } = useUserProjects();
  const { organizations } = useUserOrganizations();
  const projectsByOrganization = groupProjectsByOrganization(projects);
  const { projectId: projectIdUrlParam } = useParams();
  const { project } = useProject({ id: projectIdUrlParam || "" });
  const allOrganizations = getSortedOrganizations(organizations, projects);
  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState<Scalars["ID"]>("");
  const [selectedProjectId, setSelectedProjectId] = useState<Scalars["ID"]>("");
  const { isOpen, openModal, closeModal, Modal: SettingsModal } = useModal();
  const {
    isOpen: userModalIsOpen,
    openModal: openUserModal,
    closeModal: closeUserModal,
    Modal: UserSettingsModal,
  } = useModal();
  const topAnchorRef = useRef<HTMLDivElement>(null);
  const handleClose = () => {
    if (project) {
      updateDocumentTitle(project.name);
    }
    closeModal();
    setSidebarAsOpen();
  };

  const handleCloseUserModal = () => {
    if (project) {
      updateDocumentTitle(project.name);
    }
    closeUserModal();
    setSidebarAsOpen();
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog as="div" onClose={onClose}>
          <Overlay />
          <Transition.Child
            as={Fragment}
            enter="translate-y-20"
            enterTo="translate-x-50"
            leave="ease-in duration-500"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div
              className={clsx(
                "!max-h-158 absolute top-10 mx-[16px] -my-3 h-[592px] w-[370px] rounded-2xl bg-neutral-90",
                Z_INDEX_LEVELS.MODAL,
              )}
              data-testid="menu"
            >
              <div className="flex items-center border-b border-neutral-70 p-4">
                <HStack>
                  <Base2 className="w-[300px] text-base font-normal text-tint-light-50">
                    Switch to Project ...
                  </Base2>
                  <X
                    size={20}
                    className="cursor-pointer hover:bg-slate-400"
                    color="#ffffff"
                    onClick={onClose}
                  />
                </HStack>
              </div>
              <VStack className="h-[90%] overflow-y-scroll">
                {allOrganizations.map(
                  ({
                    id: organizationId,
                    name: organizationName,
                    organizationImageUrl,
                  }) => (
                    <div
                      key={organizationId}
                      data-testid={`menu-org-section-${organizationId}`}
                    >
                      <div tabIndex={0} />
                      <OrganizationMenuItem
                        organizationName={organizationName}
                        organizationId={organizationId}
                        organizationImageUrl={organizationImageUrl ?? ""}
                        onClick={() => {
                          selectedProjectId && setSelectedProjectId("");
                          setSelectedOrganizationId(organizationId);
                          openModal();
                          onClose();
                        }}
                      />

                      {projectsByOrganization[organizationId]?.map(
                        ({
                          id: projectId,
                          name: projectName,
                          status: projectStatus,
                        }) => (
                          <div key={projectId}>
                            <ProjectMenuItem
                              projectId={projectId}
                              projectName={projectName}
                              projectStatus={projectStatus}
                              current={projectId === projectIdUrlParam}
                              closeSidebar={onClose}
                              onClick={() => {
                                setSelectedOrganizationId(organizationId);
                                setSelectedProjectId(projectId);
                                openModal();
                              }}
                            />
                          </div>
                        ),
                      )}
                      <div>
                        <NewProjectMenuItem organizationId={organizationId} />
                      </div>
                    </div>
                  ),
                )}

                <div className="mx-4 border-b border-neutral-70 " />
                <div className="h-14"></div>
              </VStack>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
      <SettingsModal
        className="h-[100%] w-full"
        open={isOpen}
        onClose={handleClose}
        isFullScreen
      >
        <Suspense fallback="">
          <SettingsMenu
            organizations={allOrganizations}
            initialOrganizationId={selectedOrganizationId}
            onClose={handleClose}
            selectedWorkspaceProject={selectedProjectId}
          />
        </Suspense>
      </SettingsModal>
      <UserSettingsModal
        className="h-[50%] w-full"
        open={userModalIsOpen}
        onClose={handleCloseUserModal}
        isFullScreen
        initialFocus={topAnchorRef}
      >
        <Suspense fallback="">
          <div ref={topAnchorRef}></div>
          <UserSettings onClose={handleCloseUserModal} />
        </Suspense>
      </UserSettingsModal>
    </>
  );
}

type NewSidebarModalProps = {
  open: boolean;
  onClose: () => void;
  setSidebarAsOpen: () => void;
};

export function NewSidebarModal({
  open,
  onClose,
  setSidebarAsOpen,
}: NewSidebarModalProps) {
  const { currentUser } = useGetEnvsContext();
  const { projectId = "" } = useParams();
  const { project } = useProject({ id: projectId });
  const isProjectOver = isPast(parseISO(project?.endDate as string));
  const { hasAdminAccess } = useProjectAccessLevel({
    projectId,
  });
  const { isOpen, openModal, closeModal } = useModal();
  const {
    isOpen: userModalIsOpen,
    openModal: openUserModal,
    closeModal: closeUserModal,
    Modal: UserSettingsModal,
  } = useModal();
  const topAnchorRef = useRef<HTMLDivElement>(null);

  const handleCloseUserModal = () => {
    closeUserModal();
    setSidebarAsOpen();
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog as="div" onClose={onClose}>
          <Overlay />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-500"
            enterFrom="-translate-x-full"
            enterTo="translate-x--0"
            leave="ease-in duration-500"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div
              className={clsx(
                "absolute top-0 flex h-[90%] min-h-screen w-[450px] flex-col items-start bg-neutral-900 px-0 pt-2 pb-8",
                Z_INDEX_LEVELS.MODAL,
              )}
              data-testid="menu-new"
            >
              <div
                className="flex items-center border-neutral-70 p-4 "
                data-testId="project-goal"
              >
                <HStack>
                  <div className="bg-tint-light-15 h-[60px] w-[346px] rounded-lg">
                    <UserMenuItem
                      currentUser={currentUser}
                      onClick={openUserModal}
                    />
                  </div>
                </HStack>
                <HStack className="m-2">
                  {hasAdminAccess && !isProjectOver ? (
                    <EditButton onClick={openModal} />
                  ) : undefined}

                  <EditProjectModal show={isOpen} onClose={closeModal} />
                </HStack>
              </div>
              <VStack className="m-8 max-w-full">
                {!!projectId && (
                  <h5 className="text-xs font-normal uppercase tracking-wide text-neutral-600">
                    {t("shared.projectGoal")}
                  </h5>
                )}

                <div className="max-h-[200px]  max-w-full text-3xl font-light text-white">
                  <Paragraph1 className="font-Steradian Sidebarscrollbar mb-2 h-[200px] w-[386px] flex-wrap overflow-y-scroll break-words text-[32px] font-light ">
                    {project?.goal}
                  </Paragraph1>
                </div>
                <div data-testId="goal-resources" className="max-h-[300px]">
                  {project?.links?.length ? (
                    <VStack className="mb-10" space={4}>
                      <HStack align="baseline">
                        {" "}
                        <Caption1 className="mt-10 mb-4 w-[346px] text-xs font-normal uppercase tracking-wide text-neutral-600">
                          {" Project goal Resources"}
                        </Caption1>
                        {hasAdminAccess && !isProjectOver ? (
                          <EditButton
                            onClick={openModal}
                            EditResourcePencil={true}
                          />
                        ) : undefined}
                      </HStack>

                      <VStack
                        align="start"
                        className="Sidebarscrollbar max-h-[230px] flex-wrap gap-2 overflow-y-auto text-xs"
                      >
                        <div className="grid grid-cols-2 gap-2">
                          {project.links.map((link) => (
                            <Linking
                              key={link?.url}
                              metadata={link as EmbeddedLinks}
                              ResourceTitleColor={true}
                            />
                          ))}
                        </div>
                      </VStack>
                    </VStack>
                  ) : (
                    <></>
                  )}
                </div>
              </VStack>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>

      <UserSettingsModal
        className="h-full w-full"
        open={userModalIsOpen}
        onClose={handleCloseUserModal}
        isFullScreen
        initialFocus={topAnchorRef}
      >
        <Suspense fallback="">
          <div ref={topAnchorRef}></div>
          <UserSettings onClose={handleCloseUserModal} />
        </Suspense>
      </UserSettingsModal>
    </>
  );
}
function SuspenseFallback() {
  return (
    <Center className="h-[49px] w-[49px] rounded-full border border-black">
      ...
    </Center>
  );
}
export function OrganizationMenuButton({
  children,
  onClick,
  ...props
}: {
  children: ReactNode;
  onClick?: () => void;
}) {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  return (
    <div {...props}>
      {/* <Suspense fallback={<SuspenseFallback />}> */}
      <button
        onClick={() => (onClick ? onClick() : setSidebarIsOpen(true))}
        data-testid="menu-trigger"
      >
        {children}
      </button>
      <NewSidebarModal
        open={sidebarIsOpen}
        onClose={() => setSidebarIsOpen(false)}
        setSidebarAsOpen={() => setSidebarIsOpen(true)}
      />
      {/* </Suspense> */}
    </div>
  );
}

export default OrganizationMenuButton;
