import { HStack, VStack } from "components/layout";
import { AnonymityBadge } from "components/partial";
import { useToast } from "components/provider";
import { Heading2, Overline } from "components/Typography";
import { Poll, PollPollType, WorkshopParticipantRole } from "graphql/generated";
import { t } from "i18n-js";
import { useCreatePollAnswer, useUpdatePollAnswer } from "mutations/workshop";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { usePollAnswerSubscription } from "subscriptions";

import { WorkshopActivityType } from "../LiveWorkshopActivity/LiveWorkshopActivity.types";
import { useWorkshopContext } from "../WorkshopProvider";
import PollActivityButtons from "./PollActivityButtons";
import PollUIDisplay from "./PollUIDisplay";

interface PollActivityProps {
  activity: Omit<WorkshopActivityType, "Discussion" | "Breakout" | "Resource">;
  role: WorkshopParticipantRole;
  workshopActivityId: string;
}

const PollActivity = ({
  activity,
  role,
  workshopActivityId,
}: PollActivityProps) => {
  const [pollAnswer, setPollAnswer] = useState([""]);
  const [showResults, setShowResults] = useState(false);
  const { addToast } = useToast();
  const { currentWorkshopParticipant } = useWorkshopContext();
  const { id: currentWorkshopUserId } = currentWorkshopParticipant || {};

  const {
    allowSkip,
    anonymous,
    answers,
    id: pollId,
    multipleAnswers,
    pollType,
    question,
  } = activity as Poll;

  const { id: workshopId = "" } = useParams();
  const { mutateAsync: createPollAnswer } = useCreatePollAnswer();
  const { mutateAsync: updatePollAnswer } = useUpdatePollAnswer();

  usePollAnswerSubscription({
    workshopId: workshopId,
  });

  const participantAnswers = answers?.filter(
    (answer) => answer.workshopParticipant.id === currentWorkshopUserId,
  );

  const participantHasAnswered =
    participantAnswers && participantAnswers?.length > 0;

  useEffect(() => {
    participantHasAnswered ? setShowResults(true) : setShowResults(false);
  }, [pollId, participantHasAnswered]);

  const createPollResponse = async () => {
    if (!currentWorkshopUserId) {
      addToast({
        message: t("errors.somethingWentWrong"),
        hasCloseOption: true,
      });
      return;
    }

    try {
      await createPollAnswer({
        pollAnswer: {
          answers: pollAnswer,
          workshopActivityId: workshopActivityId,
          workshopParticipantId: currentWorkshopUserId,
        },
      });
    } catch {
      addToast({
        message: t("errors.somethingWentWrong"),
        hasCloseOption: true,
      });
      throw new Error(t("errors.somethingWentWrong"));
    }
  };

  const updatePollResponse = async () => {
    if (
      !participantAnswers ||
      participantAnswers.length === 0 ||
      !participantAnswers[0].id
    ) {
      addToast({
        message: t("errors.somethingWentWrong"),
        hasCloseOption: true,
      });
      return;
    }

    try {
      await updatePollAnswer({
        inputs: {
          answers: pollAnswer,
          id: participantAnswers[0].id,
          workshopActivityId: workshopActivityId,
        },
      });
    } catch {
      addToast({
        message: t("errors.somethingWentWrong"),
        hasCloseOption: true,
      });
      throw new Error(t("errors.somethingWentWrong"));
    }
  };

  return (
    <VStack className="ml-18 mr-12 h-full w-full" justify="center">
      <HStack justify="between" className="mb-6">
        <Overline>
          {t(`pollActivityEditor.pollTypesSugar.${pollType as PollPollType}`)}
        </Overline>
        {pollType !== PollPollType.WordCloud && (
          <AnonymityBadge anonymous={anonymous ?? false} />
        )}
        {pollType === PollPollType.WordCloud && (
          <AnonymityBadge anonymous={true} />
        )}
      </HStack>
      <Heading2 className="pb-6">{question}</Heading2>
      <PollUIDisplay
        participantAnswers={participantAnswers}
        setPollAnswer={setPollAnswer}
        showResults={showResults}
        activity={activity}
      />

      <HStack space={2} className="p-6">
        <PollActivityButtons
          isDisabled={
            pollAnswer[0] === undefined ||
            pollAnswer.length === 0 ||
            pollAnswer[0] === ""
          }
          allowSkip={allowSkip as boolean}
          answerCanBeUpdated={participantHasAnswered as boolean}
          createPollResponse={createPollResponse}
          multipleAnswers={multipleAnswers as boolean}
          pollType={pollType as PollPollType}
          setShowResults={setShowResults}
          showResults={showResults}
          updatePollResponse={updatePollResponse}
        />
      </HStack>
    </VStack>
  );
};

export default PollActivity;
