import { Button } from "components/base";
import { Modal } from "components/partial";
import { Heading1Strong, Paragraph1 } from "components/Typography";
import { t } from "i18n-js";
import { X } from "phosphor-react";
import React from "react";

export interface ModalProps {
  isOpen: boolean;
  setIsOpen: (shouldShow: boolean) => void;
}

export const LanguageCohesionInfoModal = ({
  isOpen,
  setIsOpen,
}: ModalProps) => {
  return (
    <Modal
      className="no-scrollbar h-min max-h-112 w-235 flex-col gap-4 overflow-scroll rounded-xl"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      children={
        <div className="p-10">
          <div className="mb-6 flex items-center justify-between">
            <Heading1Strong>
              {t(
                "component.userSettings.tabs.insights.modal.languageCohesion.title",
              )}
            </Heading1Strong>
            <Button
              variant="tertiary"
              RightIcon={X}
              onClick={() => setIsOpen(false)}
            />
          </div>

          <Paragraph1 className="text-neutral-90">
            {t(
              "component.userSettings.tabs.insights.modal.languageCohesion.description",
            )}
          </Paragraph1>
        </div>
      }
    />
  );
};
