import Tree, {
  ItemId,
  moveItemOnTree,
  mutateTree,
  RenderItemParams,
  TreeData,
  TreeDestinationPosition,
  TreeItem,
  TreeSourcePosition,
} from "@atlaskit/tree";
import { Display5 } from "components/Typography";
import { PollOption } from "graphql/generated";
import { t } from "i18n-js";
import { DragHandle, PlusIcon, XIcon } from "icons";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Item, PollListComponent } from "./PollList.types";

const createTree = (items: PollOption[]) => {
  // eslint-disable-next-line unicorn/no-array-reduce
  const newItems = items.reduce((acc, item) => {
    acc[item.id] = {
      id: item.id,
      children: [],
      data: {
        title: item.title,
        id: item.id,
      },
    };
    return acc;
  }, {} as Record<ItemId, TreeItem>);

  return {
    rootId: "root",
    items: {
      root: {
        id: "root",
        children: items.map((item) => item.id),
        hasChildren: true,
        isExpanded: true,
        isChildrenLoading: false,
        data: {
          title: "root",
        },
      },
      ...newItems,
    },
  };
};

type FormData = {
  content: string;
};

export const PollList: PollListComponent = ({
  items,
  onAddItem,
  onRemoveItem,
  onMoveItem,
  title,
}) => {
  const { handleSubmit, register, reset } = useForm<FormData>();
  const [tree, setTree] = useState<TreeData>(createTree(items));

  useEffect(() => {
    const newTree = createTree(items);

    // check to see if we actually have to update the tree
    if (!_.isEqual(newTree, tree)) {
      setTree(mutateTree(createTree(items), "root", { isExpanded: true }));
    }
  }, [items]);

  const renderItem = ({ item, provided }: RenderItemParams) => {
    const { title } = item.data as Item;

    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        className="flex items-center py-3"
      >
        <div
          {...provided.dragHandleProps}
          className="flex flex-1 items-center gap-3.5 pl-1"
        >
          <DragHandle />
          {title}
        </div>
        <button onClick={() => onRemoveItem(item.id.toString())}>
          <XIcon />
        </button>
      </div>
    );
  };

  const onSubmit = ({ content }: FormData) => {
    onAddItem(content);
    reset();
  };

  const onDragEnd = (
    source: TreeSourcePosition,
    destination?: TreeDestinationPosition,
  ) => {
    if (destination) {
      const newTree = moveItemOnTree(tree, source, destination);

      setTree(newTree);

      const treeItems = newTree.items[tree.rootId].children;
      const itemsArray = treeItems.map((id) => newTree.items[id].data as Item);

      onMoveItem(itemsArray);
    }
  };

  return (
    <div>
      {title && <Display5 className="pb-3">{title}</Display5>}
      <Tree
        tree={tree}
        renderItem={renderItem}
        isNestingEnabled={false}
        isDragEnabled
        onDragEnd={onDragEnd}
      />
      <form
        onSubmit={handleSubmit(onSubmit) as () => void}
        className="flex items-center py-3"
      >
        <button type="submit">
          <PlusIcon width={18} height={18} className="mr-2 text-neutral-90" />
        </button>
        <input
          placeholder={t("workshopEditor.addOption")}
          className="text-neutral-90 outline-none placeholder:text-sm placeholder:text-neutral-40"
          {...register("content", { minLength: 1, required: true })}
        />
      </form>
    </div>
  );
};
