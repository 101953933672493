import { DirectUpload } from "@rails/activestorage";

const DIRECT_UPLOAD_URL = "/rails/active_storage/direct_uploads";

export default async function uploadToActiveStorage(
  file: File,
): Promise<ActiveStorage.Blob> {
  const upload = new DirectUpload(file, DIRECT_UPLOAD_URL, "amazon", file.name);

  return new Promise(function (resolve, reject) {
    upload.create((error: Error, blob: ActiveStorage.Blob) => {
      if (error) {
        reject(error);
      } else {
        resolve(blob);
      }
    });
  });
}
