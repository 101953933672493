/* eslint-disable unicorn/require-array-join-separator */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import DailyIframe, { DailyCall, DailyCallOptions } from "@daily-co/daily-js";
import { useToast } from "components/provider";
import { Caption1, Display4 } from "components/Typography";
import { useGetEnvsContext } from "contexts";
import {
  MusicStatus,
  Recording,
  WorkshopByIdQuery,
  WorkshopParticipant,
  WorkshopParticipantRole,
} from "graphql/generated";
import { useWorkshopParticipant } from "hooks";
import { t } from "i18n-js";
import { noop } from "lodash";
import {
  useStartForceCloudRecording,
  useStartStopMusic,
  useStopForceCloudRecording,
  useUpdateWorkshopParticipant,
} from "mutations/workshop";
import { useParticipantsBreakoutsRooms } from "queries";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMusicStreamSubscription } from "subscriptions";
import { getCurrentWorkshopActivity, Z_INDEX_LEVELS } from "utils";
import { v4 } from "uuid";

import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { MusicPlayer } from "../MusicPlayer";

const CALL_OPTIONS: DailyCallOptions = {
  iframeStyle: {
    width: "100%",
    height: "100%",
  },
  // If Active Speaker is false, the layout switches to Grid View.
  // https://docs.daily.co/guides/configurations-and-settings/setting-up-calls#building-calls-with-daily-prebuilt
  activeSpeakerMode: false,
  showLeaveButton: false,
  layoutConfig: {
    grid: {
      minTilesPerPage: 25,
      maxTilesPerPage: 26,
    },
  },
};

const musicButton = {
  iconPath: "https://i.ibb.co/nsR5Xzn/music-notes-2.png",
  iconPathDarkMode: "https://i.ibb.co/1sgNfvV/music-notes.png",
  label: "Music",
  tooltip: "Play music",
};

const leaveButton = {
  iconPath: "https://i.ibb.co/w7MQ3Jq/sign-out-bold-2.png",
  iconPathDarkMode: "https://i.ibb.co/w7MQ3Jq/sign-out-bold-2.png",
  label: "Leave",
  tooltip: "Leave workshop",
};

const startRecordButton = {
  iconPath: "https://i.ibb.co/qDtwYGy/record-fill-1.png",
  iconPathDarkMode: "https://i.ibb.co/fNrw9YY/record-fill.png",
  label: "Start recording",
  tooltip: "Start recording",
};

const stopRecordButton = {
  iconPath: "https://i.ibb.co/5kpkWH0/stop-fill-1.png",
  iconPathDarkMode: "https://i.ibb.co/37kHKL1/stop-fill.png",
  label: "Stop recording",
  tooltip: "Stop recording",
};

const VideoCall = ({
  roomName,
  account = "coeuraj",
  className = "",
  userAuthToken,
  currentWorkshopUser,
  workshop,
  raisedHand,
  setIsMeetingBeingRecorded = noop,
}: {
  roomName: string;
  account?: string;
  className?: string;
  userAuthToken: string;
  currentWorkshopUser: WorkshopParticipant;
  workshop: WorkshopByIdQuery["workshopById"];
  raisedHand?: boolean;
  setIsMeetingBeingRecorded?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [callFrame, setCallFrame] = useState<DailyCall | undefined>(undefined);
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  const [isUserJoined, setIsUserJoined] = useState(false);
  const [newRoomToken, setNewRoomToken] = useState<string>(userAuthToken);
  const [showConfirmLeaveWorkshopModal, setShowConfirmLeaveWorkshopModal] =
    useState(false);
  const [showMusicPlayer, setShowMusicPlayer] = useState(false);
  const [SHOW_MUSIC, SET_SHOW_MUSIC] = useState(
    process.env.SHOW_MUSIC === "true",
  );
  const [attendees, setAttendees] = useState({
    totalParticipants: 0,
    totalFacilitators: 0,
  });
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  const [instanceId] = useState(v4());
  const { id: workshopId = "" } = useParams();
  const { addToast } = useToast();
  const { currentUser, envs } = useGetEnvsContext();
  const { role, breakoutRoomTokens } = useWorkshopParticipant(
    workshop,
    currentUser,
  );
  const isCurrentUserOwnerOrFacilitator =
    role === WorkshopParticipantRole.Owner ||
    role === WorkshopParticipantRole.Facilitator;

  const { mutate: startStopMusic } = useStartStopMusic();
  const { data: streamData } = useMusicStreamSubscription({ workshopId });
  const { mutate: startForceCloudRecording } = useStartForceCloudRecording();
  const { mutate: stopForceCloudRecording } = useStopForceCloudRecording();
  const { participantsBreakoutsRooms } = useParticipantsBreakoutsRooms({
    participantsIds: [currentWorkshopUser?.id || ""],
    workshopId,
  });
  const didCurrentUserAttendWorkshop = currentWorkshopUser.didAttend;
  const { mutate: updateWorkshopParticipant } = useUpdateWorkshopParticipant();

  const currentToken = callFrame?.properties.token;
  const currentUrl = callFrame?.properties.url;

  const currentActivity = React.useMemo(() => {
    return getCurrentWorkshopActivity(workshop?.agenda);
  }, [workshop]);

  const isBreakoutRoomActivity =
    currentActivity?.activity.__typename === "Breakout";
  const isBreakoutRoomActive = currentActivity?.activity.breakoutRoomIsActive;
  const IS_USER_IN_BREAKOUT_ROOM =
    isBreakoutRoomActive && breakoutRoomTokens !== null;

  const startRecordings = () => {
    callFrame?.startRecording({ instanceId });
    startForceCloudRecording({
      input: {
        id: workshopId,
        type: Recording.Workshop,
      },
    });
    setIsRecordingStarted(true);
  };

  const handleLeaveWorkshop = () => {
    if (!videoRef || !videoRef?.current || !callFrame) return;

    // pauses the music when last user leaves the workshop
    if (
      callFrame.participantCounts().present === 1 &&
      streamData?.musicStream?.musicData?.status === MusicStatus.Play &&
      SHOW_MUSIC
    ) {
      startStopMusic({
        input: {
          musicId: streamData?.musicStream?.musicData?.id || "",
          status: MusicStatus.Pause,
          workshopId,
        },
      });
    }

    void callFrame.leave();
    navigate(`/workshop/${workshopId}/rejoin`);
  };

  const syncParticipantAttendance = () => {
    if (currentWorkshopUser.id && !didCurrentUserAttendWorkshop) {
      updateWorkshopParticipant({
        updateWorkshopParticipantInput: {
          id: currentWorkshopUser.id,
          didAttend: true,
        },
      });
    } else return;
  };

  useEffect(() => {
    if (envs) {
      const { SHOW_MUSIC } = envs;
      SET_SHOW_MUSIC(SHOW_MUSIC === "true");
    }
  }, [envs]);

  useEffect(() => {
    if (!videoRef || !videoRef?.current || !callFrame) return;

    // removes start and adds stop button for main room
    if (
      isRecordingStarted &&
      isCurrentUserOwnerOrFacilitator &&
      (currentToken === newRoomToken || currentToken === userAuthToken)
    ) {
      callFrame.updateCustomTrayButtons(
        SHOW_MUSIC
          ? {
              stopRecordButton,
              musicButton,
              leaveButton,
            }
          : {
              stopRecordButton,
              leaveButton,
            },
      );
    }
    // removes stop and adds start button for main room
    if (
      !isRecordingStarted &&
      isCurrentUserOwnerOrFacilitator &&
      (currentToken === newRoomToken || currentToken === userAuthToken)
    ) {
      callFrame.updateCustomTrayButtons(
        SHOW_MUSIC
          ? {
              recordButton: startRecordButton,
              musicButton,
              leaveButton,
            }
          : {
              recordButton: startRecordButton,
              musicButton,
            },
      );
    }
  }, [
    callFrame,
    isCurrentUserOwnerOrFacilitator,
    isRecordingStarted,
    newRoomToken,
    currentToken,
    userAuthToken,
  ]);

  useEffect(() => {
    if (!videoRef || !videoRef?.current || !callFrame) return;

    // removes start and adds stop button for breakout room
    if (
      isRecordingStarted &&
      isBreakoutRoomActive &&
      isCurrentUserOwnerOrFacilitator &&
      currentToken !== userAuthToken
    ) {
      callFrame.updateCustomTrayButtons({
        breakoutStopRecord: stopRecordButton,
        leaveButton,
      });
    }

    // removes stop and adds start button for breakout room
    if (
      !isRecordingStarted &&
      isBreakoutRoomActive &&
      isCurrentUserOwnerOrFacilitator &&
      currentToken !== userAuthToken
    ) {
      callFrame.updateCustomTrayButtons({
        breakoutStartRecord: startRecordButton,
        leaveButton,
      });
    }
  }, [
    callFrame,
    isBreakoutRoomActive,
    isCurrentUserOwnerOrFacilitator,
    isRecordingStarted,
    currentToken,
    userAuthToken,
  ]);

  useEffect(() => {
    if (!videoRef || !videoRef?.current || !callFrame) return;

    setTimeout(() => {
      const participantCount = Object.keys(callFrame.participants())?.length;
      const facilitatorCount = Object.values(callFrame.participants())?.filter(
        (item) => item.owner === true,
      )?.length;

      setAttendees({
        totalParticipants: participantCount,
        totalFacilitators: facilitatorCount,
      });
    }, 400);
  }, [callFrame]);

  useEffect(() => {
    if (
      isUserJoined &&
      isCurrentUserOwnerOrFacilitator &&
      currentToken === userAuthToken
    ) {
      const { totalFacilitators } = attendees;
      totalFacilitators === 1 ? startRecordings() : noop;
    }
  }, [isUserJoined, isCurrentUserOwnerOrFacilitator, attendees]);

  useEffect(() => {
    const defaultUrl = `https://${account}.daily.co/${roomName}`;

    if (
      currentActivity?.activity.__typename === "Breakout" &&
      currentActivity.activity.breakoutRoomIsActive
    ) {
      const newRoom = { id: undefined, url: undefined, token: undefined } as {
        id: string | undefined;
        url: string | undefined;
        token: string | undefined;
      };

      const roomIndex = currentActivity.activity.dailyRooms.findIndex(
        (room) => {
          const user = room.workshopParticipants?.find(
            (user) => user.id === currentWorkshopUser.id,
          );
          const newRoomToken = participantsBreakoutsRooms?.find(
            (item) => item.dailyRoomId === room.id,
          )?.token;

          if (user) {
            newRoom.id = room.id;
            newRoom.url = room.url as string;
            newRoom.token = newRoomToken as string;

            setNewRoomToken(newRoom.token);

            return true;
          }
        },
      );

      if (
        newRoom?.url &&
        newRoom.url !== currentUrl &&
        newRoom.token &&
        newRoom.token !== currentToken
      ) {
        addToast({
          message: `${t("workshop.inSessionBreakout.movingToBreakout")} ${
            roomIndex + 1
          }`,
          variant: "info",
          hasCloseOption: true,
        });

        setTimeout(() => {
          callFrame
            ?.leave()
            .then(() => {
              setIsUserJoined(false);
              setIsRecordingStarted(false);
              setShowMusicPlayer(false);
              callFrame
                ?.join({
                  url: newRoom.url,
                  token: newRoom.token,
                  customTrayButtons: {
                    leaveButton,
                  },
                  ...(isCurrentUserOwnerOrFacilitator && {
                    customTrayButtons: {
                      breakoutStartRecord: startRecordButton,
                    },
                  }),
                })
                .then(() => {
                  setIsUserJoined(true);
                  const { present } = callFrame.participantCounts();
                  if (present <= 1) {
                    callFrame.startRecording({ instanceId });
                    startForceCloudRecording({
                      input: {
                        id: newRoom.id || "",
                        type: Recording.DailyRoom,
                      },
                    });
                    setIsRecordingStarted(true);
                  }
                  callFrame.on("recording-started", () => {
                    setIsMeetingBeingRecorded(true);
                    setIsRecordingStarted(true);
                  });
                  callFrame.on("recording-stopped", () => {
                    setIsMeetingBeingRecorded(false);
                    setIsRecordingStarted(false);
                  });
                  callFrame.on("custom-button-click", (ev) => {
                    if (ev?.button_id === "breakoutStartRecord") {
                      callFrame.startRecording({ instanceId });
                      startForceCloudRecording({
                        input: {
                          id: newRoom.id || "",
                          type: Recording.DailyRoom,
                        },
                      });
                      setIsRecordingStarted(true);
                    }
                    if (ev?.button_id === "breakoutStopRecord") {
                      callFrame.stopRecording({ instanceId });
                      stopForceCloudRecording({
                        input: {
                          id: newRoom.id || "",
                          type: Recording.DailyRoom,
                        },
                      });
                      setIsRecordingStarted(false);
                    }
                    if (ev?.button_id === "leaveButton") {
                      setShowConfirmLeaveWorkshopModal(true);
                    }
                  });
                })
                .catch((error) => {
                  console.error(error);
                });
            })
            .catch((error) => {
              console.error(error);
            });
        }, 3000);
      }

      // if a user is in a room and the breakout rooms are active don't move back
      if (newRoom.url && newRoom.token) {
        return;
      }
    }
    if (
      currentUrl &&
      currentToken &&
      currentUrl !== defaultUrl &&
      currentToken !== userAuthToken
    ) {
      addToast({
        message: t("workshop.inSessionBreakout.movingToMain"),
        variant: "info",
        hasCloseOption: true,
      });

      setTimeout(() => {
        callFrame
          ?.leave()
          .then(() => {
            setIsUserJoined(false);
            setIsRecordingStarted(false);
            callFrame
              ?.join({
                url: defaultUrl,
                token: userAuthToken,
                customTrayButtons: {
                  leaveButton,
                },
              })
              .then(() => {
                // starts recording when user (Participant, Owner, Facilitator) moves from breakout to main room
                callFrame?.startRecording({ instanceId });
                startForceCloudRecording({
                  input: {
                    id: workshopId,
                    type: Recording.Workshop,
                  },
                });
                setIsUserJoined(true);
                setIsRecordingStarted(true);
                callFrame.on("custom-button-click", (ev) => {
                  if (ev?.button_id === "leaveButton") {
                    setShowConfirmLeaveWorkshopModal(true);
                  }
                });
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((error) => {
            console.error(error);
          });
      }, 3000);
    }
  }, [currentActivity, callFrame, attendees, participantsBreakoutsRooms]);

  useEffect(() => {
    if (!videoRef || !videoRef?.current || callFrame) return;

    const newCallframe = DailyIframe.createFrame(
      videoRef.current,
      CALL_OPTIONS,
    );

    newCallframe
      .join({
        url: `https://${account}.daily.co/${roomName}`,
        token: userAuthToken,
        customTrayButtons: {
          leaveButton,
        },
        ...(isCurrentUserOwnerOrFacilitator && {
          customTrayButtons: {
            recordButton: startRecordButton,
          },
        }),
      })
      .then(() => {
        setCallFrame(newCallframe);
        setIsUserJoined(true);
        syncParticipantAttendance();
        newCallframe.on("recording-started", () => {
          setIsRecordingStarted(true);
          setIsMeetingBeingRecorded(true);
        });
        newCallframe.on("recording-stopped", () => {
          setIsRecordingStarted(false);
          setIsMeetingBeingRecorded(false);
        });
        newCallframe.on("custom-button-click", (ev) => {
          if (ev?.button_id === "recordButton") {
            newCallframe.startRecording({ instanceId });
            startForceCloudRecording({
              input: {
                id: workshopId,
                type: Recording.Workshop,
              },
            });
            setIsRecordingStarted(true);
          }
          if (ev?.button_id === "stopRecordButton") {
            newCallframe.stopRecording({ instanceId });
            stopForceCloudRecording({
              input: {
                id: workshopId,
                type: Recording.Workshop,
              },
            });
            setIsRecordingStarted(false);
          }
          if (ev?.button_id === "leaveButton") {
            setShowConfirmLeaveWorkshopModal(true);
          }
          if (ev?.button_id === "musicButton") {
            setShowMusicPlayer((prev) => !prev);
          }
        });
      })
      .catch((error) => {
        // TODO: handle possible daily errors
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [callFrame, roomName, videoRef, isBreakoutRoomActivity]);

  useEffect(() => {
    if (!callFrame) return;

    const userName = callFrame?.participants().local.user_name;
    const handAlreadyRaised = userName?.startsWith("✋");

    if (raisedHand && !handAlreadyRaised) {
      void callFrame?.setUserName(`✋ ${userName}`);
    } else if (!raisedHand && handAlreadyRaised) {
      void callFrame?.setUserName(userName?.replace("✋ ", ""));
    }
  }, [callFrame, raisedHand]);

  return (
    <>
      <div
        className={`h-full w-full flex-1 ${Z_INDEX_LEVELS.BASE_OVERRIDE} ${className}`}
        ref={videoRef}
      />
      <ConfirmDialog
        open={showConfirmLeaveWorkshopModal}
        onCancel={() => setShowConfirmLeaveWorkshopModal(false)}
        onConfirm={handleLeaveWorkshop}
        title={
          <Display4>
            {`${t("component.leaveWorkshop.doYouWantToLeave")} `}
            <span className="font-bold">"{workshop?.title}"</span>
            {` ${t("component.leaveWorkshop.workshop")}`}
          </Display4>
        }
        subtitle={t("component.leaveWorkshopConfirmDialog.subtitle")}
        warning={
          isCurrentUserOwnerOrFacilitator ? (
            <Caption1>
              {t("component.leaveWorkshopConfirmDialog.warningText1")}
              <span className="underline underline-offset-[2px]">
                {t("component.leaveWorkshopConfirmDialog.warningText2")}
              </span>
              {t("component.leaveWorkshopConfirmDialog.warningText3")}
            </Caption1>
          ) : (
            ""
          )
        }
        confirmButtonText={t("component.leaveWorkshopConfirmDialog.confirm")}
        cancelButtonText={t("component.leaveWorkshopConfirmDialog.cancel")}
      />
      {!IS_USER_IN_BREAKOUT_ROOM && (
        <MusicPlayer
          open={showMusicPlayer && isUserJoined}
          isUserJoined={isUserJoined}
        />
      )}
    </>
  );
};

export default VideoCall;
