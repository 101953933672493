import React from "react";

function EditIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2411 2.4999C12.4755 2.26548 12.7935 2.13379 13.125 2.13379C13.4565 2.13379 13.7745 2.26548 14.0089 2.4999L17.5 5.99102C17.7344 6.22544 17.8661 6.54339 17.8661 6.87491C17.8661 7.20642 17.7344 7.52437 17.5 7.75879L7.94194 17.3168C7.82473 17.4341 7.66576 17.4999 7.5 17.4999H3.75C3.41848 17.4999 3.10054 17.3682 2.86612 17.1338C2.6317 16.8994 2.5 16.5814 2.5 16.2499V12.7588C2.5 12.5946 2.53233 12.4321 2.59515 12.2804C2.65797 12.1288 2.75004 11.991 2.86612 11.8749L12.2411 2.4999ZM16.6161 6.87491L13.125 3.38379L3.75 12.7588L3.75 16.2499H7.24112L16.6161 6.87491Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1831 4.55806C10.4271 4.31398 10.8229 4.31398 11.0669 4.55806L15.4419 8.93306C15.686 9.17714 15.686 9.57286 15.4419 9.81694C15.1979 10.061 14.8021 10.061 14.5581 9.81694L10.1831 5.44194C9.93898 5.19786 9.93898 4.80214 10.1831 4.55806Z"
        fill="white"
      />
    </svg>
  );
}
export default EditIcon;
