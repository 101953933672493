import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { Button } from "components/base";
import { Base1, Overline } from "components/Typography";
import { t } from "i18n-js";
import { BellSimple } from "phosphor-react";
import React from "react";

const TimeRemainingDropdown = ({
  onSubmit,
}: {
  onSubmit: (timeRemaining: number) => void;
}) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const [dropdownPlacementClass, setDropdownPlacementClass] =
    React.useState("bottom-full -mb-3");

  const TimeRemainingOption = ({
    timeRemaining,
    onClick,
  }: {
    timeRemaining: number;
    onClick: (timeRemaining: number) => void;
  }) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      onClick(timeRemaining);
    };

    return (
      <Menu.Item>
        <button onClick={handleClick}>
          <Base1 className="my-4 text-white last-of-type:mb-2">
            {t("workshop.inSessionBreakout.timeRemainingDropdown", {
              count: timeRemaining,
            })}
          </Base1>
        </button>
      </Menu.Item>
    );
  };

  const handleButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const buttonTop = ref?.current?.getBoundingClientRect().top;

    if (buttonTop) {
      setDropdownPlacementClass(
        buttonTop < 290 ? "top-full -mt-3" : "bottom-full -mb-3",
      );
    }
  };

  return (
    <Menu>
      <Menu.Button onClick={handleButton}>
        <Button
          size="small"
          variant="outline"
          LeftIcon={BellSimple}
          className="mr-2"
          ref={ref}
        >
          {t("workshop.inSessionBreakout.time")}
        </Button>
      </Menu.Button>
      <Menu.Items
        className={clsx(
          "absolute right-0 z-10 flex flex-col items-start rounded-lg bg-neutral-900 p-4",
          dropdownPlacementClass,
        )}
      >
        <Overline className="whitespace-nowrap text-tint-light-600">
          {t("workshop.inSessionBreakout.dropdownTitle")}
        </Overline>
        <TimeRemainingOption timeRemaining={10} onClick={onSubmit} />
        <TimeRemainingOption timeRemaining={5} onClick={onSubmit} />
        <TimeRemainingOption timeRemaining={2} onClick={onSubmit} />
      </Menu.Items>
    </Menu>
  );
};

export default TimeRemainingDropdown;
