import { HStack, VStack } from "components";
import { Logo } from "components/base";
import { updateDocumentTitle } from "components/utils/updateDocumentTitle/updateDocumentTitle";
import { t } from "i18n-js";
import { noop } from "lodash";
import React, { useEffect, useState } from "react";

import UserSettingsMenu, { UserSettingsMenuTabs } from "./UserSettingsMenu";
import UserSettingsMenuContent from "./UserSettingsMenuContent";
import UserSettingsUserInfo from "./UserSettingsUserInfo";

type SettingsMenuProps = {
  onClose?: () => void;
  tabSelect?: boolean;
};

function UserSettings({ onClose = noop, tabSelect }: SettingsMenuProps) {
  const [currentTab, setCurrentTab] = useState<UserSettingsMenuTabs>(
    tabSelect ? UserSettingsMenuTabs.insights : UserSettingsMenuTabs.profile,
  );

  useEffect(() => {
    updateDocumentTitle(t("component.documentTitle"));
  }, [currentTab]);

  return (
    <HStack className="h-full">
      <VStack className="h-full w-1/3 bg-gray-200" space={12}>
        <Logo size="medium" className="mt-10 ml-10 text-neutral-70" />

        <UserSettingsUserInfo />

        <UserSettingsMenu
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </VStack>
      <UserSettingsMenuContent tab={currentTab} onClose={onClose} />
    </HStack>
  );
}

export default UserSettings;
