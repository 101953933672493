import ConfirmDialog from "components/partial/ConfirmDialog";
import { useToast } from "components/provider";
import { Base2, Caption1 } from "components/Typography";
import {
  BreakoutsState,
  WorkshopActivity,
  WorkshopActivityState,
  WorkshopState,
} from "graphql/generated";
import { t } from "i18n-js";
import {
  useAddActivityLogs,
  useStartEndBreakouts,
  useUpdateActivity,
  useUpdateWorkshop,
} from "mutations/workshop";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { FooterNavControl } from "../FooterNavControl";

/**
 * AgendaPagination encapsulates the logic around the various states of
 * FooterNavControl button combinations to show based on the current state
 * of the Workshop.
 */
export const AgendaPagination = ({
  prev,
  current,
  next,
  activityLength,
}: {
  prev?: WorkshopActivity | undefined;
  current?: WorkshopActivity | undefined;
  next?: WorkshopActivity | undefined;
  activityLength: number;
}) => {
  const { id: workshopId = "" } = useParams();
  // Note, for now, the API doesn't use a real `timeElapsed` value,
  // so every time the stale query gets refetched, the `currentTime` gets
  // reset to the "0" value from the server.
  // Once the server starts tracking "timeElapsed", this shouldn't be a problem anymore.
  const [currentTime, setCurrentTime] = useState(
    (current?.timeElapsed || 0) * 60,
  );
  const [isConfirmEndModalOpen, setIsConfirmEndModalOpen] = useState(false);
  const { addToast } = useToast();

  const { mutate: updateActivity } = useUpdateActivity();
  const { mutate: updateWorkshop } = useUpdateWorkshop();
  const { mutate: addActivityLogs } = useAddActivityLogs();

  /** CURRENT ACTIVITY TIME ELAPSED TRACKING */
  const { currentMin, currentSec, progress, inOvertime } = useMemo<{
    currentMin: number;
    currentSec: string;
    progress: number;
    inOvertime: boolean;
  }>(() => {
    const progress = Math.min((currentTime / (activityLength * 60)) * 100, 100);

    return {
      currentMin: Math.floor(currentTime / 60),
      currentSec: (currentTime % 60).toString().padStart(2, "0"),
      progress,
      inOvertime: progress === 100,
    };
  }, [currentTime, activityLength]);

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(currentTime + 1), 1000);

    return () => clearInterval(interval);
  }, [currentTime]);

  /** EFFECT TO ENSURE THE 'currentTime' VALUE ALWAYS SYNCS TO NEW ACTIVITY */
  useEffect(() => {
    setCurrentTime(current?.timeElapsed || 0);
  }, [current?.id]);

  const { mutate: startEndBreakouts } = useStartEndBreakouts();

  const handleBreakoutInProgress = (activityId: string) => {
    if (
      current?.activity.__typename === "Breakout" &&
      current?.activity.breakoutRoomIsActive
    ) {
      startEndBreakouts({
        workshopActivityId: activityId,
        state: BreakoutsState.End,
      });
    }
  };

  /** BUTTON CLICK HANDLERS */
  const handlePrev = () => {
    if (!prev) {
      return;
    }

    current && handleBreakoutInProgress(current.id);

    addActivityLogs({ workshopActivityId: prev.id });

    updateActivity(
      {
        updateWorkshopActivity: {
          id: prev.id,
          state: WorkshopActivityState.InProgress,
        },
      },
      {
        onSuccess: () => {
          if (current) {
            // make ticket for API so we don't need to manually update "current"'s state?
            updateActivity({
              updateWorkshopActivity: {
                id: current?.id,
                state: WorkshopActivityState.Ready,
              },
            });
          }
        },
      },
    );
  };

  /**
   * With the 'next' button, we need to track whether or not there's a next activity available,
   * and whether or not there's a current activity -- as well as managing the appropriate side-effects
   * if these edge conditions are true.
   *
   * Important conditions:
   * - if !current -- the workshop isn't started yet and "next" should bring us into the first activity
   * - if current && next -- navigating from one activity to the next, we need to update the previous
   *    activity's state to "completed".
   */
  const handleNext = () => {
    if (!next) {
      return;
    }

    current && handleBreakoutInProgress(current.id);

    addActivityLogs({ workshopActivityId: next.id });

    updateActivity(
      {
        updateWorkshopActivity: {
          id: next.id,
          state: WorkshopActivityState.InProgress,
        },
      },
      {
        onSuccess: () => {
          if (current) {
            updateActivity({
              updateWorkshopActivity: {
                id: current?.id,
                state: WorkshopActivityState.Completed,
              },
            });
          }
        },
      },
    );
  };

  // due to a recent API update the workshopId prop in activities was replaced with workshop
  const nextWorkshop = next?.workshop;
  const currentWorkshop = current?.workshop;

  /**
   * When the workshop state is still "published" and the 1st activity is only "ready",
   * if the user clicks the start/1st-activity button, it should trigger the workshop to
   * start for all of the participants, so the first activity must become active.
   */
  const handleStartFirstActivity = () => {
    if (!next || !workshopId) {
      addToast({
        message: t("workshop.missingInfoError"),
        variant: "error",
        hasCloseOption: true,
      });
    } else {
      addActivityLogs({ workshopActivityId: next.id });

      updateActivity(
        {
          updateWorkshopActivity: {
            id: `${next.id}`,
            state: WorkshopActivityState.InProgress,
          },
        },
        {
          onSuccess: () => {
            updateWorkshop({
              workshop: {
                // Why is workshop id input a string, but the workshopActivity.workshopId is a number?
                id: `${nextWorkshop?.id || workshopId}`,
                state: WorkshopState.LiveSession,
              },
            });
          },
        },
      );
    }
  };

  const confirmEndWorkshop = () => {
    setIsConfirmEndModalOpen(true);
  };

  /**
   * When on the final activity of the workshop, there's no "next" activity to
   * navigate to. Instead, we present the Facilitator the option to "End Workshop".
   * Ending the workshop will update the state of the workshop to "post_session",
   * which should trigger the users to all redirect to the "WorkshopComplete" component.
   */
  const handleEndWorkshop = () => {
    if (!current) {
      addToast({
        message: t("workshop.missingInfoError"),
        variant: "error",
        hasCloseOption: true,
      });
    } else {
      // reverse nesting here?
      updateWorkshop(
        {
          workshop: {
            id: `${currentWorkshop?.id || workshopId}`,
            state: WorkshopState.PostSession,
          },
        },
        {
          onSuccess: () => {
            if (!current) {
              return;
            }
            updateActivity({
              updateWorkshopActivity: {
                id: `${current.id}`,
                state: WorkshopActivityState.Completed,
              },
            });
          },
          onSettled() {
            setIsConfirmEndModalOpen(false);
          },
        },
      );
    }
  };

  return (
    <div className="flex flex-row text-white">
      <ConfirmDialog
        open={isConfirmEndModalOpen}
        onCancel={() => setIsConfirmEndModalOpen(false)}
        onConfirm={handleEndWorkshop}
        title={t("workshop.endConfirmation.title")}
        subtitle={t("workshop.endConfirmation.subtitle")}
        confirmButtonText={t("workshop.endConfirmation.confirm")}
        cancelButtonText={t("workshop.endConfirmation.cancel")}
      />

      {/* Workshop Not Yet Started */}
      {!current && (
        <FooterNavControl
          variant="solo"
          label={t("workshop.startSession")}
          title={next?.title || t("workshop.firstActivity")}
          isHighlighted
          onClick={handleStartFirstActivity}
        />
      )}

      {/* On First Activity */}
      {!prev && current && (
        <>
          <FooterNavControl
            variant="left"
            label={`${currentMin}:${currentSec}/${activityLength}:00`}
            title={current.title}
            progress={progress}
            isDisabled
          />

          <FooterNavControl
            variant="right"
            label={t("shared.next")}
            title={next?.title ?? t("workshop.endWorkshop")}
            onClick={next ? handleNext : confirmEndWorkshop}
            isHighlighted={inOvertime}
          />
        </>
      )}

      {/* Past First Activity */}
      {!!prev && !!current && (
        <>
          <FooterNavControl
            variant="left"
            label={t("shared.previous")}
            title={prev?.title}
            onClick={handlePrev}
            isDisabled={!prev}
          />

          <div
            className={`relative flex flex-1 flex-col items-center border py-[6.5px] px-5 text-center ${
              !inOvertime ? "border-white" : "border-neutral-70"
            }`}
          >
            <div
              className="absolute left-0 top-0 h-full bg-tint-light-20"
              style={{ width: `${progress}%` }}
            />
            <Caption1 className="text-tint-light-50">
              {currentMin}:{currentSec}/{activityLength}:00
            </Caption1>
            <Base2 className="whitespace-nowrap">{current?.title}</Base2>
          </div>

          {/* Next / End Workshop Button */}
          <FooterNavControl
            variant="right"
            label={t("shared.next")}
            title={next?.title ?? t("workshop.endWorkshop")}
            onClick={next ? handleNext : confirmEndWorkshop}
            isHighlighted={inOvertime}
          />
        </>
      )}
    </div>
  );
};
