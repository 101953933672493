import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { Avatar, Dropdown } from "components/base";
import { VStack } from "components/layout/Stack";
import { UpdatedAvatarGroup } from "components/partial";
import { Base2, Caption1 } from "components/Typography";
import { useActiveWorkshopMembers } from "contexts/WorkshopPresenceContext";
import { User } from "graphql/generated";
import React, { useEffect, useState } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

type AvatarGroupOnlineProps = {
  users: User[];
  extraUserCountBgColor?:
    | "bg-primary-turquoise-30"
    | "bg-white"
    | "bg-black"
    | "bg-neutral-70";
  projectId: string;
  liveMembers?: boolean;
  className?: string;
};

export default function AvatarGroupOnline({
  users,
  extraUserCountBgColor = "bg-white",
  projectId = "",
  liveMembers = false,
  className = "",
}: AvatarGroupOnlineProps) {
  const [onlineUsers, setOnlineUsers] = useState(liveMembers ? [] : users);
  const members = useActiveWorkshopMembers();

  useEffect(() => {
    liveMembers ? setOnlineUsers(members || []) : setOnlineUsers(users);
  }, [users, members, liveMembers]);

  return (
    <>
      <Dropdown
        className={clsx("2 w-42 flex flex-row-reverse items-center", className)}
        button={
          <Menu.Button as="button">
            {onlineUsers && (
              <UpdatedAvatarGroup
                users={onlineUsers}
                extraUserCountBgColor={extraUserCountBgColor}
              />
            )}
          </Menu.Button>
        }
        items={
          <Menu.Items
            className={clsx(
              "absolute right-0 top-12 flex max-h-[409px] w-64 origin-top-right flex-col overflow-hidden rounded-xl bg-white pt-6 text-left shadow-membersOnline focus:outline-none",
              Z_INDEX_LEVELS.BASE_CONTROL,
            )}
          >
            <VStack
              space={10}
              className="Memberscrollbar overflow-y-scroll px-4 pb-6"
            >
              {[...onlineUsers]
                .sort((u1, u2) =>
                  u1.currentSignInAt > u2.currentSignInAt ? -1 : 1,
                )
                .map((member) => (
                  <div key={member.id} className="flex">
                    <Avatar
                      user={member}
                      data-testid={`avatar-member-online-expanded-${member.id}`}
                    />
                    <div className="scrollbar ml-2 flex flex-col justify-center">
                      <Base2 className="text-neutral-90">
                        {member.firstName} {member.lastName}
                      </Base2>
                      <Caption1 className="mt-1 text-neutral-50">
                        {member.pronouns ? `(${member.pronouns})` : ""}
                      </Caption1>

                      <Caption1 className="mt-1 text-neutral-50">
                        {member.defaultProjectRole
                          ? member.defaultProjectRole
                          : ""}
                      </Caption1>
                    </div>
                  </div>
                ))}
            </VStack>
          </Menu.Items>
        }
      />
    </>
  );
}
