import "channels";
/* eslint-disable unicorn/prefer-module */
import "styles/application.css";

import { start as ActiveStorageStart } from "@rails/activestorage";
import App from "components/App";
import * as React from "react";
import { render } from "react-dom";
import globalErrorHandler from "utils/helpers/logger/GlobalErrorHandler";

globalErrorHandler;

ActiveStorageStart();

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.createElement("div");
  reactRoot.setAttribute("id", "react-root");
  document.body.append(reactRoot);
  render(<App />, document.querySelector("#react-root"));
});

if (module.hot) {
  module.hot.accept("components/App", () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-var-requires
    const NextRootContainer = require("components/App").default as React.FC;
    render(<NextRootContainer />, document.querySelector("#react-root"));
  });
}
