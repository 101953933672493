import {
  QuestionResponseByIdQuery,
  QuestionResponseByIdQueryVariables,
  useQuestionResponseByIdQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query QuestionResponseById($id: ID!, $weekEndDate: ISO8601Date!) {
    questionResponseById(id: $id, weekEndDate: $weekEndDate) {
      id
      project {
        id
      }
      pulseCheckSubmitted(weekEndDate: $weekEndDate)
      questionResponses(weekEndDate: $weekEndDate) {
        id
        note
        author {
          id
        }
        answerChoice {
          id
          text
        }
        question {
          id
          title
        }
      }
    }
  }
`;

function useQuestionResponseById(
  variables: QuestionResponseByIdQueryVariables,
): {
  questionResponseById: QuestionResponseByIdQuery["questionResponseById"];
} & UseQueryResult<QuestionResponseByIdQuery, unknown> {
  const response = useQuestionResponseByIdQuery(variables);

  return {
    questionResponseById: response?.data?.questionResponseById,
    ...response,
  };
}

export { useQuestionResponseById };
