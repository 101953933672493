import { useDeleteWorkshopMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation DeleteWorkshop($workshopId: ID!) {
    deleteWorkshop(id: $workshopId) {
      id
    }
  }
`;

export function useDeleteWorkshop() {
  const queryClient = useQueryClient();
  return useDeleteWorkshopMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries(["Notifications"]);
      void queryClient.invalidateQueries(["subFilters"]);
      void queryClient.invalidateQueries(["subFilters"]);
    },
  });
}
