import React from "react";

function CreateWorkshopIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="16" cy="16" r="16" fill="#2B4FCF" />
      <path
        d="M22.875 9.75H9.125C8.77982 9.75 8.5 10.0298 8.5 10.375V19.75C8.5 20.0952 8.77982 20.375 9.125 20.375H22.875C23.2202 20.375 23.5 20.0952 23.5 19.75V10.375C23.5 10.0298 23.2202 9.75 22.875 9.75Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 20.375L21 23.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 20.375L11 23.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 9.75V7.875"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CreateWorkshopIcon;
