import clsx from "clsx";
import { Avatar } from "components/base";
import { Caption1, Paragraph3 } from "components/Typography";
import { User } from "graphql/generated";
import { t } from "i18n-js";
import { ArrowsHorizontal } from "phosphor-react";
import React from "react";

export enum CohesionLevel {
  High = "high",
  AboveAverage = "aboveAverage",
  Average = "average",
  BelowAverage = "belowAverage",
  Low = "low",
}

export type CohesionCardUser = Pick<
  User,
  "firstName" | "lastName" | "fullName"
>;

export interface CohesionIndicatorCardProps {
  users: [CohesionCardUser, CohesionCardUser];
  cohesionLevel: CohesionLevel;
  className?: string;
}

export const getColorByCohesionLevel = (cohesionLevel: CohesionLevel) => {
  switch (cohesionLevel) {
    case CohesionLevel.BelowAverage:
    case CohesionLevel.Low:
      return "text-red-500";

    case CohesionLevel.AboveAverage:
    case CohesionLevel.Average:
      return "text-secondary-orange-700";

    case CohesionLevel.High:
    default:
      return "text-primary-turquoise-700";
  }
};

/**
 * This card indicates the level of Language Cohesion between a primary user
 * and a secondary peer. The secondary peer's name is shown in the card, whereas
 * the primary user's identity is assumed to be implied.
 *
 * There are only two levels of cohesion in the designs at the time of implementation:
 * "high" and "above average", but it sounds like there's some ongoing discussion around
 * what the appropriate values are.
 *
 * [Note]: For now, I've just hedged my bets and given a full spectrum, but it should be
 * quite easy to come back later and update.
 *
 * [Another Note]: It might make sense to have this component just fetch `currentUser`
 * and use that as the left avatar, and then only take one 'peer' user as a prop.
 * For now, I don't have the confidence to assert that it'll be used with the `currentUser`
 * being the leftmost user 100% of the time, so I'll leave it a bit more flexible.
 */
export const CohesionIndicatorCard = ({
  users,
  cohesionLevel,
  className,
}: CohesionIndicatorCardProps) => {
  const mainUser = users[0];
  const peer = users[1];

  return (
    <div
      className={clsx(
        "flex w-[320px] flex-row gap-4 rounded-lg bg-tint-dark-100/5 py-4 px-3",
        className,
      )}
    >
      <div className="flex items-center justify-center gap-1.5">
        <Avatar size="small" user={mainUser} />

        <ArrowsHorizontal className="text-neutral-600" />

        <Avatar size="small" user={peer} />
      </div>

      <div className="flex flex-col gap-1">
        <Caption1
          className={clsx("uppercase", getColorByCohesionLevel(cohesionLevel))}
        >
          {t(`component.cohesionIndicatorCard.cohesionLevel.${cohesionLevel}`)}
        </Caption1>

        <Paragraph3 className="text-neutral-900">{peer.fullName}</Paragraph3>
      </div>
    </div>
  );
};
