import { Button, Loader } from "components/base";
import { Caption1, Caption2 } from "components/Typography";
import { t } from "i18n-js";
import { Check, UploadSimple, Warning } from "phosphor-react";
import React from "react";

const UploadStatus = ({ uploadState, filename, setUploadState }) => {
  const Idle = (
    <>
      <UploadSimple className="mb-4" color="#7F8089" size={20} />
      <Caption1 className="mb-2 text-neutral-70">
        {t("workshopOverview.uploadDetails")}
      </Caption1>
      <Caption2 className="text-neutral-50">
        {t("workshopOverview.uploadFormats")}
      </Caption2>
    </>
  );

  switch (uploadState) {
    case "idle":
      return Idle;
    case "uploading":
      return (
        <>
          <Loader variant="circle" />
          <Caption1 className="mt-2 text-neutral-70">
            {t("workshop.uploader.uploading")} "{filename}"...
          </Caption1>
        </>
      );
    case "success":
      return (
        <>
          <div className="flex h-[26px] w-[26px] items-center justify-center rounded-full bg-primary-turquoise-50">
            <Check color="#fff" width={15} />
          </div>
          <Caption1 className="mt-2 text-neutral-70">
            {t("workshop.uploader.uploadSuccess")}
          </Caption1>
        </>
      );
    case "error":
      return (
        <>
          <div className="flex h-[26px] w-[26px] items-center justify-center rounded-full bg-secondary-red-70">
            <Warning color="#fff" width={20} height="15" />
          </div>
          <Caption1 className="mt-2 text-neutral-70">
            {t("errors.somethingWentWrong")}
          </Caption1>
          <Button
            variant="link"
            className="mt-2 underline"
            onClick={() => setUploadState("idle")}
          >
            <Caption1 className="text-neutral-70">
              {t("workshop.uploader.tryAgain")}
            </Caption1>
          </Button>
        </>
      );
    default:
      return Idle;
  }
};

export default UploadStatus;
