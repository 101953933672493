import { useDeleteWorkshopActivityMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation DeleteWorkshopActivity($id: ID!) {
    deleteWorkshopActivity(id: $id) {
      id
      title
    }
  }
`;

export function useDeleteActivity() {
  const queryClient = useQueryClient();
  return useDeleteWorkshopActivityMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["WorkshopById"]);
      await queryClient.refetchQueries(["WorkshopById"]);
    },
  });
}
