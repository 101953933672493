import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { Heading3 } from "components/Typography";
import React from "react";

import { TabNavigatorComponent } from "./TabNavigator.types";

const TabNavigator: TabNavigatorComponent = ({
  tabs,
  children,
  defaultTabIndex = 0,
  rightContent,
  className,
}) => {
  return (
    <Tab.Group defaultIndex={defaultTabIndex}>
      <Tab.List className="flex h-16 min-h-[64px] justify-between border-b border-neutral-5 px-14">
        <div className="flex flex-row items-center gap-12">
          {tabs.map(({ title, disabled }, index) => (
            <Tab
              key={`${title}-${index}`}
              className={clsx("h-full outline-none", {
                "cursor-not-allowed opacity-50": disabled,
              })}
              disabled={disabled}
            >
              {({ selected }) => (
                <div
                  className={clsx(
                    "flex h-full flex-col justify-center border-b-2 border-transparent",
                    {
                      "border-tertiary-blue-50": selected,
                    },
                  )}
                >
                  <Heading3
                    className={clsx(
                      "text-neutral-70",
                      {
                        "font-medium text-neutral-90": selected,
                      },
                      {
                        "hover:text-neutral-90": !disabled,
                      },
                    )}
                  >
                    {title}
                  </Heading3>
                  <Heading3 as="span" className="h-0 font-medium opacity-0">
                    {title}
                  </Heading3>
                </div>
              )}
            </Tab>
          ))}
        </div>
        {rightContent}
      </Tab.List>
      <Tab.Panels className={clsx("flex w-full flex-1", className)}>
        {children}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default TabNavigator;
