import {
  Center,
  CenteredPage,
  Display3,
  OrganizationMenuButton,
  Paragraph2,
} from "components";
import { Button } from "components/base";
import { UserSettings } from "components/pages";
import { ProjectSwitcher } from "components/partial";
import { useToast } from "components/provider";
import { useGetEnvsContext } from "contexts";
import { useModal } from "hooks";
import { t } from "i18n-js";
import EmptyStateExamplePhases from "icons/EmptyStateExamplePhases";
import { Plus } from "phosphor-react";
import { useUserOrganizations, useUserProjects } from "queries";
import * as React from "react";
import { Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { getLastVisitedProjectPage } from "utils/helpers/last-visited-project-page";

import { OpenProfileSection } from "../Project/ProjectViewHeader";

function Home() {
  const { currentUser } = useGetEnvsContext();
  const { projects } = useUserProjects();
  const { organizations } = useUserOrganizations();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const handleClick = () =>
    navigate(`/organizations/${organizations[0]?.id}/project/new`);

  React.useEffect(() => {
    if (currentUser && projects.length > 0) {
      const lastVisitedProjectPage = getLastVisitedProjectPage(currentUser?.id);
      lastVisitedProjectPage && navigate(lastVisitedProjectPage);
    }
  }, [currentUser, navigate, projects.length]);

  React.useEffect(() => {
    if (
      !currentUser?.firstName &&
      !currentUser?.lastName &&
      !currentUser?.defaultProjectRole
    ) {
      navigate("/onboarding/about_you");
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (projects.length === 0 || organizations.length === 0) {
      addToast({
        message: t("errors.revisitProjectInviteLink"),
        variant: "error",
        hasCloseOption: true,
      });
    }
  }, [projects, organizations]);

  const {
    isOpen: userModalIsOpen,
    openModal: openUserModal,
    closeModal: closeUserModal,
    Modal: UserSettingsModal,
  } = useModal();

  const topAnchorRef = React.useRef<HTMLDivElement>(null);

  return (
    <CenteredPage className="bg-neutral-0 bg-home-radial-gradient">
      <div className="absolute top-4 left-6 flex w-[96%] justify-between ">
        <Suspense
          fallback={
            <Center className="h-[49px] w-[49px] rounded-full border border-black">
              ...
            </Center>
          }
        >
          <OrganizationMenuButton>
            <ProjectSwitcher />
          </OrganizationMenuButton>
          <OpenProfileSection onClick={openUserModal} />
        </Suspense>
        <UserSettingsModal
          className="h-full w-full"
          open={userModalIsOpen}
          isFullScreen
          onClose={closeUserModal}
          initialFocus={topAnchorRef}
        >
          <Suspense fallback="">
            <UserSettings onClose={closeUserModal} />
          </Suspense>
        </UserSettingsModal>
      </div>
      <div className="no-scrollbar flex w-full pt-6">
        <div className="inline-flex w-full justify-between md:pl-[13rem]">
          <div className="w-[32rem] text-left">
            <Display3 className="mb-8 text-neutral-90">
              {t("pages.home.createAProject")}
            </Display3>
            <Paragraph2 className="mb-8 text-neutral-70">
              {t("pages.home.paragraph")}
            </Paragraph2>
            <OrganizationMenuButton
              onClick={organizations.length === 1 ? handleClick : undefined}
            >
              <Button LeftIcon={Plus}>{t("pages.home.createAProject")}</Button>
            </OrganizationMenuButton>
          </div>
          <div className="-mt-20 w-full max-w-4xl text-left">
            <EmptyStateExamplePhases className="w-full" />
          </div>
        </div>
      </div>
    </CenteredPage>
  );
}

export default Home;
