import { useUpdateHighlightOfWorkshopMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation UpdateHighlightOfWorkshop($input: UpdateHighlightOfWorkshopInput!) {
    updateHighlightOfWorkshop(input: $input) {
      id
      highlight
    }
  }
`;

function useUpdateHighlightOfWorkshop() {
  const queryClient = useQueryClient();
  return useUpdateHighlightOfWorkshopMutation({
    onSuccess: () => {
      void queryClient.refetchQueries(["WorkshopById"]);
      void queryClient.refetchQueries(["ProjectById"]);
      void queryClient.refetchQueries(["subFilters"]);
      void queryClient.refetchQueries(["subFilters"]);
    },
  });
}

export default useUpdateHighlightOfWorkshop;
