import React from "react";

function MinusIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="13"
      height="2"
      viewBox="0 0 13 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.700195 1C0.700195 0.723858 0.924053 0.5 1.2002 0.5H12.2002C12.4763 0.5 12.7002 0.723858 12.7002 1C12.7002 1.27614 12.4763 1.5 12.2002 1.5H1.2002C0.924053 1.5 0.700195 1.27614 0.700195 1Z"
        fill="#35363E"
      />
    </svg>
  );
}
export default MinusIcon;
