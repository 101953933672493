import {
  QuestionsQuery,
  QuestionsQueryVariables,
  useQuestionsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query Questions {
    questions {
      id
      title
      answerChoices {
        id
        text
      }
    }
  }
`;

function useQuestions(variables: QuestionsQueryVariables): {
  questions: QuestionsQuery["questions"];
} & UseQueryResult<QuestionsQuery, unknown> {
  const response = useQuestionsQuery(variables);

  return {
    questions: response?.data?.questions || [],
    ...response,
  };
}

export { useQuestions };
