import { Popover, RadioGroup } from "@headlessui/react";
import { Button, Input } from "components/base";
import { DropdownTrigger } from "components/partial";
import { Paragraph3 } from "components/Typography";
import { t } from "i18n-js";
import { useCreateSection, useUpdateActivity } from "mutations/workshop";
import { useWorkshop } from "queries";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { WorkshopSectionSelectorComponent } from ".";

const WorkshopSectionSelector: WorkshopSectionSelectorComponent = ({
  selectedItem,
}) => {
  const [addingSection, setAddingSection] = useState(false);

  const { id: workshopId = "" } = useParams();
  const { workshop: { agenda = [] } = {} } = useWorkshop({ id: workshopId });
  const { mutate: addSection } = useCreateSection();
  const { mutate: updateActivity } = useUpdateActivity();

  const sections = agenda.filter((item) => item.__typename === "Section");

  const selectedSection = useMemo(
    () =>
      sections.find(
        (section) =>
          section.id.toString() === selectedItem.sectionId?.toString(),
      ),
    [sections, selectedItem],
  );

  const Sections = () => (
    <>
      {sections.length === 0 ? (
        <>
          <Paragraph3 className="text-neutral-60">
            {t("workshopEditor.sectionSelector.empty")}
          </Paragraph3>
        </>
      ) : (
        <RadioGroup
          value={selectedItem.sectionId?.toString()}
          onChange={handleAddToSection}
          className="flex flex-col gap-4"
        >
          {sections.map(({ title: section, id }, index) => (
            <RadioGroup.Option value={id} key={`${section || ""}-${index}`}>
              {({ checked }) => (
                <button className="flex items-center gap-3">
                  <div
                    className={`relative flex h-6 w-6 items-center justify-center rounded-full transition-colors ${
                      checked
                        ? "bg-primary-turquoise-50"
                        : "bg-[rgb(221,221,223)]"
                    }`}
                  >
                    <div
                      className={`rounded-full transition-all ${
                        checked
                          ? "h-[10px] w-[10px] bg-white shadow shadow-[rgba(0,0,0,0.25)]"
                          : "h-1 w-1 bg-[rgb(221,221,223)]"
                      }`}
                    />
                  </div>
                  <Paragraph3>{section}</Paragraph3>
                </button>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      )}
      <Button
        variant="tertiary"
        size="small"
        className="mt-4 w-full"
        onClick={() => setAddingSection(true)}
      >
        {t("workshopEditor.sectionSelector.add")}
      </Button>
    </>
  );

  const handleAddToSection = (sectionId: string) => {
    updateActivity({
      updateWorkshopActivity: {
        id: selectedItem.id.toString().split("-")[1],
        sectionId,
      },
    });
  };

  const AddSection = ({ close }: { close: () => void }) => {
    const handleSubmit = (e: React.SyntheticEvent) => {
      e.preventDefault();
      const target = e.target as typeof e.target & {
        section: { value: string };
      };

      const newSection = target.section.value;

      if (newSection.length > 0) {
        addSection(
          { section: { title: newSection, workshopId } },
          {
            onSuccess: ({ createSection }) => {
              setAddingSection(false);

              createSection && handleAddToSection(createSection.id);
            },
            onError: () => {
              console.error(t("errors.somethingWentWrong"));
            },
          },
        );

        close();
      }
    };

    return (
      <>
        <form onSubmit={handleSubmit}>
          <Input autoFocus id="section" />
          <Button size="small" className="mt-4 mb-2 w-full">
            {t("workshopEditor.sectionSelector.create")}
          </Button>
        </form>

        <Button
          variant="destructive"
          size="small"
          className="w-full"
          onClick={() => setAddingSection(false)}
        >
          {t("shared.cancel")}
        </Button>
      </>
    );
  };

  return (
    <Popover className="relative w-60">
      {({ open, close }) => (
        <>
          <DropdownTrigger
            primaryText={
              selectedSection
                ? t("workshopEditor.sectionSelector.sectionSelected")
                : t("workshopEditor.sectionSelector.moveToSection")
            }
            secondaryText={
              !!selectedSection?.title ? selectedSection?.title : undefined
            }
            open={open}
            as={Popover.Button}
          />
          <Popover.Panel className="absolute z-10 w-full rounded-b-lg border-2 border-t-0 border-tint-dark-10 bg-white p-4 shadow-projectPhaseCard">
            {addingSection ? <AddSection close={close} /> : <Sections />}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default WorkshopSectionSelector;
