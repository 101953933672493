import { Button } from "components/base";
import { useProjectInvitationOrigin } from "hooks";
import { t } from "i18n-js";
import { GoogleIcon } from "icons";
import React from "react";
import getCsrfToken from "utils/helpers/get-csrf-token";

import { Paragraph3 } from "../../Typography";

interface GoogleLoginButtonPropsType {
  isSignUp?: boolean;
}

function GoogleLoginButton(props: GoogleLoginButtonPropsType) {
  const { isSignUp = false } = props;
  const { originUrl, hasProjectInvitationOrigin } =
    useProjectInvitationOrigin();

  let actionUrl = "/users/auth/google_oauth2";
  actionUrl =
    originUrl && hasProjectInvitationOrigin
      ? `${actionUrl}?originUrl=${encodeURIComponent(originUrl)}${
          isSignUp ? "&isSignup=true" : ""
        }`
      : actionUrl;

  return (
    <form method="post" action={actionUrl}>
      <Button
        className="relative w-full border-neutral-70"
        variant="outline"
        type="submit"
      >
        <GoogleIcon className="absolute left-4 h-4.5 w-4.5" />
        <Paragraph3 as="span">
          {isSignUp
            ? t("component.googleSignupButton")
            : t("component.googleLoginButton")}
        </Paragraph3>
      </Button>
      <input type="hidden" name="authenticity_token" value={getCsrfToken()} />
    </form>
  );
}

export default GoogleLoginButton;
