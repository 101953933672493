import React from "react";

function MiroIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1425_21714)">
        <path
          d="M3.96611 0H13.0339C14.9469 0 16.5 1.55312 16.5 3.46611V12.5339C16.5 14.4469 14.9469 16 13.0339 16H3.96611C2.05312 16 0.5 14.4469 0.5 12.5339V3.46611C0.5 1.55312 2.05312 0 3.96611 0Z"
          fill="#FFD02F"
        />
        <path
          d="M10.4478 13.5016L11.8183 4.93708L11.2178 4.7846L11.7592 4.32192L10.7212 2.49805H11.4756L14.2424 4.21507L11.2538 13.5016H10.4478ZM3.45614 13.5016L5.07958 6.10644L5.1145 5.94735L5.04902 5.79822L3.60007 2.49805H4.39404L7.44992 5.35192L4.25035 13.5016H3.45614ZM7.92953 2.49805L10.8144 4.80165L7.75065 13.5016H6.95054L8.4493 5.51854L7.83579 5.34222L8.40293 4.91429L7.1657 2.49805H7.92953Z"
          fill="#050038"
          stroke="#35363E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1425_21714">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default MiroIcon;
