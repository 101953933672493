import { useCreateWorkshopParticipantsMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation CreateWorkshopParticipants(
    $role: WorkshopParticipantRole!
    $userIds: [ID!]
    $workshopId: ID
  ) {
    createWorkshopParticipants(
      workshopParticipants: {
        role: $role
        userIds: $userIds
        workshopId: $workshopId
      }
    ) {
      role
      isActive
      user {
        id
      }
    }
  }
`;

function useCreateWorkshopParticipants() {
  const queryClient = useQueryClient();
  return useCreateWorkshopParticipantsMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["WorkshopById"]);
      await queryClient.refetchQueries(["WorkshopById"]);
    },
  });
}

export default useCreateWorkshopParticipants;
