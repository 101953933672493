import clsx from "clsx";
import { Base2 } from "components";
import { t } from "i18n-js";
import { ArrowLeft, MapPin } from "phosphor-react";
import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

interface IStringDictionary {
  [Key: string]: string;
}

const nextLevelFromCurrent: IStringDictionary = {
  Schedule: "project",
  phase: "project",
};

export default function ViewSubheader({
  openProjectGoalModal,
  children,
  className,
  goToToday,
}: {
  openProjectGoalModal: () => void;
  goToToday?: () => void;
  children?: React.ReactNode;
  className?: string;
}) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const previousPathRef = useRef(null);
  const currentLevel = searchParams.get("level") || "project";
  const shouldShowBackAndTodayButtons = currentLevel !== "project";
  useEffect(() => {
    previousPathRef.current = currentLevel;
  });
  let previousPath: string | null;

  if (currentLevel != "Schedule") {
    previousPath = previousPathRef.current;
  }

  const handleOnClickBackButton = () => {
    navigate(-1);
    // const nextLevel = nextLevelFromCurrent[currentLevel];
    // console.log("previous",previousPath)
    // if (nextLevel) navigate({ search: `level=${nextLevel}` });
  };

  return (
    <div
      className={clsx("mb-8 flex items-center justify-between px-8", className)}
    >
      <div className="flex items-center justify-center">
        {shouldShowBackAndTodayButtons && (
          <React.Fragment>
            <button
              onClick={handleOnClickBackButton}
              data-testid="subheader-back-btn"
            >
              <ArrowLeft className="mr-6 h-6 w-6" />
            </button>
            <button
              onClick={goToToday}
              className="flex items-center justify-center"
              data-testid="subheader-today-btn"
            >
              <MapPin className="mr-1.5 h-6 w-6 text-neutral-90" />
              <Base2 className="text-neutral-90">{t("shared.today")}</Base2>
            </button>
          </React.Fragment>
        )}
      </div>
      {children}

      <div style={{ backgroundColor: "transparent", width: "9rem" }}></div>

      {/* <Button
        className="text-neutral-90 focus:!outline-0 focus:!ring-0 focus:!ring-offset-0"
        variant="secondary"
        RightIcon={Trophy}
        onClick={openProjectGoalModal}
      >
        {t("shared.projectGoal")}
      </Button> */}
    </div>
  );
}
