import { Consumer, createConsumer } from "@rails/actioncable";
import React, { useEffect, useState } from "react";

export const ActionCableContext = React.createContext<Consumer | undefined>(
  undefined,
);

export function ActionCableProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [consumer] = useState(() => createConsumer());

  useEffect(
    () => () => {
      consumer.disconnect();
    },
    [consumer],
  );

  return (
    <ActionCableContext.Provider value={consumer}>
      {children}
    </ActionCableContext.Provider>
  );
}
