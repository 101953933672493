import { Button } from "components/base";
import { ButtonVariant } from "components/base/Button";
import { Modal } from "components/partial";
import { t } from "i18n-js";
import React, { ReactNode } from "react";

import { Base2, Caption1, Display4 } from "../../Typography";

type ConfirmDialogProps = {
  title?: string | ReactNode;
  subtitle?: string;
  warning?: string | ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  loading?: boolean;
  cancelButtonVariant?: ButtonVariant;
  confirmButtonVariant?: ButtonVariant;
  zIndex?: string;
};

export default function ConfirmDialog({
  title = t("component.editAnnouncement.deleteAnnouncementConfirmDialog.title"),
  subtitle = t(
    "component.editAnnouncement.deleteAnnouncementConfirmDialog.subtitle",
  ),
  warning = "",
  confirmButtonText = t(
    "component.editAnnouncement.deleteAnnouncementConfirmDialog.confirm",
  ),
  cancelButtonText = t(
    "component.editAnnouncement.deleteAnnouncementConfirmDialog.cancel",
  ),
  open,
  onCancel,
  onConfirm,
  loading,
  cancelButtonVariant = "primary",
  confirmButtonVariant = "destructive",
  zIndex = "",
}: ConfirmDialogProps) {
  return (
    <Modal
      isFullScreen
      open={open}
      onClose={onCancel}
      className="flex w-[440px] flex-col rounded-none p-10"
      zIndex={zIndex}
    >
      <Display4>{title}</Display4>
      <Base2 className="mt-4">{subtitle}</Base2>
      {warning && (
        <Caption1 className="mt-4 text-secondary-red-50">{warning}</Caption1>
      )}
      <Button className="mt-8" onClick={onCancel} variant={cancelButtonVariant}>
        {cancelButtonText}
      </Button>
      {loading ? (
        <>
          <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-gray-700 opacity-75">
            <div className="loader mb-4 h-12 w-12 rounded-full border-4 border-t-4 border-gray-200 ease-linear"></div>
            <h2 className="text-center text-xl font-semibold text-white">
              Loading...
            </h2>
            <p className="w-1/3 text-center text-white">
              This may take a few seconds, please don't close this page.
            </p>
          </div>
        </>
      ) : (
        <>
          {" "}
          {confirmButtonText && (
            <Button
              variant={confirmButtonVariant}
              className="mt-4"
              onClick={onConfirm}
            >
              {confirmButtonText}
            </Button>
          )}
        </>
      )}
    </Modal>
  );
}
