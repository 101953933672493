import React, { ReactNode } from "react";

const DashedBorder = ({
  children,
  className = "",
  onClick,
}: {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}) => (
  <div
    className={`rounded-lg border border-dashed border-neutral-40 p-2 ${className}`}
    onClick={onClick}
  >
    {children}
  </div>
);

export default DashedBorder;
