import useModal from "hooks/use-modal";
import noop from "lodash/noop";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import getWeeksFromProjectPerPhases from "utils/helpers/get-weeks-from-project-per-phases";

import {
  CreateProjectPhasesFormInput,
  DefaultFieldsType,
  StepFormProps,
} from "../types";
import {
  addMilestonesToPhases,
  getDefaultFields,
  mergeWithDefaultFields,
} from "../utils";
import MilestonesFormButtons from "./MilestonesFormButtons";
import MilestonesFormModal from "./MilestonesFormModal";
import MilestonesFormPhases from "./MilestonesFormPhases";

type MilestonesFormProps = {
  previousStep?: (args?: DefaultFieldsType) => void;
  isEditing?: boolean;
} & StepFormProps;

function MilestonesForm({
  fields = {},
  onSubmitSuccess,
  previousStep = noop,
  isEditing = false,
}: MilestonesFormProps) {
  const { isOpen, openModal, closeModal } = useModal();

  const { getValues } = useForm<CreateProjectPhasesFormInput>({
    defaultValues: mergeWithDefaultFields<CreateProjectPhasesFormInput>(
      getDefaultFields<DefaultFieldsType>(),
      fields as CreateProjectPhasesFormInput,
    ),
    mode: "onTouched",
  });
  const [weeksPerPhase, setWeeksPerPhase] = useState(
    getWeeksFromProjectPerPhases(fields),
  );

  const handleSubmit = () => {
    const shouldOpenModal = !weeksPerPhase.some((phase) =>
      phase.some((week) => week.milestoneType !== undefined),
    );

    if (shouldOpenModal) {
      openModal();
      return;
    }

    handleSubmitSuccess();
  };

  const handleSubmitSuccess = () => {
    if (!onSubmitSuccess) return;
    onSubmitSuccess({
      ...fields,
      phases: addMilestonesToPhases(fields.phases || [], weeksPerPhase),
    });
  };

  const handlePrevious = () => {
    previousStep({
      ...fields,
      phases: addMilestonesToPhases(fields.phases || [], weeksPerPhase),
    });
  };

  return (
    <div className="flex h-full flex-col justify-center px-14 pb-8 pt-20">
      <MilestonesFormPhases
        fields={fields}
        weeksPerPhase={weeksPerPhase}
        setWeeksPerPhase={setWeeksPerPhase}
        isEditing={isEditing}
      />

      <MilestonesFormButtons
        previousStep={handlePrevious}
        getValues={getValues}
        onSubmit={handleSubmit}
        isEditing={isEditing}
      />

      <MilestonesFormModal
        onSubmitSuccess={handleSubmitSuccess}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </div>
  );
}

export default MilestonesForm;
