import clsx from "clsx";
import React, { CSSProperties } from "react";

export default function WhiteCard({
  isSelected = false,
  className,
  children,
  onClick,
  style = {},
}: {
  children: JSX.Element | JSX.Element[];
  isSelected?: boolean;
  className?: string;
  role?: string;
  onClick?: () => void;
  style?: CSSProperties;
}) {
  return (
    <button onClick={onClick}>
      <div
        className={clsx(
          "group mr-4 rounded-lg text-left transition-all duration-300 hover:bg-white hover:shadow-projectPhaseCard",
          isSelected ? "bg-white shadow-projectPhaseCard" : "bg-tint-light-50",
          className,
        )}
        style={style}
      >
        {children}
      </div>
    </button>
  );
}
