import { AxiosResponse } from "axios";
import clsx from "clsx";
import { Button, FileUploader, Input } from "components/base";
import SettingsMenuCloseButton from "components/pages/SettingsMenu/SettingsMenuCloseButton";
import { Base1, Display4 } from "components/Typography";
import { useGetEnvsContext } from "contexts";
import { DocumentInput } from "graphql/generated";
import { useModal } from "hooks";
import { fetchMetadata, Metadata } from "hooks/use-parse-metadata";
import { t } from "i18n-js";
import React from "react";
import { useForm } from "react-hook-form";

import Modal from "../Modal";

const getLinkTitle = async (url: string, apiKey: string) =>
  await fetchMetadata(url, apiKey);

type ResourceFormData = {
  link: string;
};

export type AddResourceInput = {
  link?: {
    url: string;
    title: string;
    preview: string;
    extension: string;
  };
  file?: {
    base64File?: string;
    contentType?: string;
    filename?: string;
  };
};

export const AddResource = ({
  onAddResource,
  dropContainerClassName,
  isLoading,
  isSuccess,
  isError,
  isDisabled,
  afterSuccessShow,
  linkValidator,
}: {
  onAddResource: (content: AddResourceInput) => void;
  dropContainerClassName?: string;
  isLoading: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
  afterSuccessShow?: () => void;
  linkValidator: (link: string) => boolean | string;
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
  } = useForm<ResourceFormData>({ mode: "onChange" });

  const { isOpen, openModal, closeModal } = useModal();
  const { envs } = useGetEnvsContext();
  const apiKey = envs?.JSON_LINK_API_KEY || process.env.JSON_LINK_API_KEY || "";

  const handleLinkSubmit = async ({ link }: ResourceFormData) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const linkData: AxiosResponse<Metadata> = await getLinkTitle(link, apiKey);

    onAddResource({
      link: {
        url: link,
        title: linkData.data.title || linkData.data.domain || "",
        preview: "",
        extension: "",
      },
    });

    reset();
  };

  const handleUpload = (file: DocumentInput) => {
    if (file.base64File && file.contentType && file.filename) {
      onAddResource({
        file: {
          base64File: file.base64File,
          contentType: file.contentType,
          filename: file.filename,
        },
      });
    }
  };

  const hasUnpublishedGoogleDoc =
    errors.link?.message === t("resourceActivityEditor.unpublishedLinkError");

  return (
    <div className={clsx("flex flex-1 flex-col gap-6", dropContainerClassName)}>
      <FileUploader
        handleAddResource={handleUpload}
        isSuccess={isSuccess}
        isError={isError}
        isDisabled={isDisabled}
        afterSuccessShow={afterSuccessShow}
      />
      <form
        className="flex items-center gap-2 whitespace-nowrap"
        onSubmit={handleSubmit(handleLinkSubmit) as () => void}
      >
        <div className="h-12 flex-1">
          <Input
            expandable
            disabled={isDisabled}
            placeholder={t("resourceActivityEditor.linkPlaceholder")}
            error={errors.link?.message}
            errorOnClick={hasUnpublishedGoogleDoc ? openModal : undefined}
            errorLinkContent={t("resourceActivityEditor.learnMore")}
            onInfoClick={!isValid ? openModal : undefined}
            {...register("link", {
              required: true,
              validate: linkValidator,
            })}
          />
        </div>
        <Button
          variant="tertiary"
          disabled={!isValid || isLoading || isDisabled}
          size="small"
          className="h-11"
          type="submit"
        >
          {t("resourceActivityEditor.addLink")}
        </Button>
      </form>
      <Modal
        className="w-112 rounded-xl p-10"
        open={isOpen}
        onClose={closeModal}
      >
        <div className="flex flex-col gap-4 text-neutral-900">
          <div className="mb-8 flex justify-between">
            <Display4>{t("workshop.linkValidationModal.title")}</Display4>
            <SettingsMenuCloseButton onClose={closeModal} />
          </div>
          <Base1>{t("workshop.linkValidationModal.description1")}</Base1>
          <Base1>{t("workshop.linkValidationModal.description2")}</Base1>
          <ul className="ml-2">
            <li className="flex">
              <Base1>&bull;</Base1>
              <Base1 className="ml-2">
                {t("workshop.linkValidationModal.miro")}
                <a
                  href="https://help.miro.com/hc/en-us/articles/360016335640-How-to-embed-Miro-boards-"
                  target="_blank"
                  className="ml-1 text-primary-turquoise-700"
                >
                  {t("resourceActivityEditor.learnMore")}
                </a>
              </Base1>
            </li>
            <li className="flex">
              <Base1>&bull;</Base1>
              <Base1 className="ml-2">
                {t("workshop.linkValidationModal.docs")}
                <a
                  href="https://support.google.com/docs/answer/183965?hl=en&co=GENIE.Platform%3DDesktop"
                  target="_blank"
                  className="ml-1 text-primary-turquoise-700"
                >
                  {t("resourceActivityEditor.learnMore")}
                </a>
              </Base1>
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default AddResource;
