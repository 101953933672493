import clsx from "clsx";
import {
  Button,
  DatePicker,
  Input,
  Link,
  MultiUserInput,
} from "components/base";
import {
  ConfirmDialog,
  FormInputErrors,
  InteractionForm,
} from "components/partial";
import { Base2, Caption1 } from "components/Typography";
import { useCreatedResourceCardContext } from "contexts/CreatedResourceCardContext";
import { useHighlightContext } from "contexts/HighlightContext";
import { format, formatISO, isAfter, isBefore, startOfDay } from "date-fns";
import { useFetchMetadata } from "hooks";
import { t } from "i18n-js";
import { noop } from "lodash";
import useCreateTask from "mutations/use-create-task";
import useUpdateProjectEndDate from "mutations/use-update-project-end-date";
import { CircleNotch } from "phosphor-react";
import { useProject, useProjectAssignments } from "queries";
import React, { useEffect, useMemo, useState } from "react";
import { FieldError, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getZonedDate } from "utils/constants/time_helpers";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";
import { validateUrl, whetherLinkExists } from "utils/helpers/validations";

import { EmbeddedLinks, ErrorType } from "./CreateAnnouncementForm";
import SlackControl from "./SlackControl";

const MAX_TITLE_CHARS = 90;
const MAX_DESCRIPTION_CHARS = 280;

enum INPUT_FIELDS {
  title = "title",
  description = "description",
  assigneeIds = "assigneeIds",
  dueDate = "dueDate",
  links = "links",
}

type CreateTaskFormInput = {
  [INPUT_FIELDS.title]: string;
  [INPUT_FIELDS.description]: string;
  [INPUT_FIELDS.assigneeIds]: string[];
  [INPUT_FIELDS.dueDate]: string;
  [INPUT_FIELDS.links]: string;
};

const DEFAULT_VALUES: CreateTaskFormInput = {
  [INPUT_FIELDS.title]: "",
  [INPUT_FIELDS.description]: "",
  [INPUT_FIELDS.assigneeIds]: [],
  [INPUT_FIELDS.dueDate]: "",
  [INPUT_FIELDS.links]: "",
};

type CreateTaskFormProps = {
  onClose?: () => void;
  userHasAdminAccess?: boolean;
  testProps?: EmbeddedLinks[];
};

function CreateTaskForm({
  onClose = noop,
  testProps = [],
  userHasAdminAccess = false,
}: CreateTaskFormProps) {
  const [requestError, setRequestError] = useState("");
  const { projectId = "" } = useParams();
  const { project } = useProject({ id: projectId });
  const { projectAssignments } = useProjectAssignments({ projectId });
  const [shouldPostToSlack, setShouldPostToSlack] = useState(true);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    resetField,
    setValue,
    clearErrors,
    setError,
    getValues,
  } = useForm<CreateTaskFormInput>({
    defaultValues: DEFAULT_VALUES,
    mode: "onSubmit",
  });
  const [assigneeIds, setAssigneeIds] = useState<string[]>([]);
  const [enteredLink, setEnteredLink] = useState("");
  const [embeddedLinks, setEmbeddedLinks] = useState<EmbeddedLinks[]>(
    testProps.length > 0 ? testProps : [],
  );
  const [selectedInvalidDate, setSelectedInvalidDate] = useState(false);
  const [showInvalidDateModal, setShowInvalidDateModal] = useState(false);
  const { isHighlighted } = useHighlightContext();

  const { mutateAsync } = useCreateTask();
  const { data, isLoading, error } = useFetchMetadata(enteredLink);
  const { setCreatedResourceId } = useCreatedResourceCardContext();
  const { mutate: updateProjectEndDate } = useUpdateProjectEndDate();

  const projectMembers = useMemo(() => {
    const filteredAssignments = projectAssignments.filter(
      ({ user }) => !assigneeIds.includes(user.id),
    );
    return filteredAssignments.map(({ user, role }) => ({ ...user, role }));
  }, [projectAssignments, assigneeIds]);

  const navigate = useNavigate();
  const projectEndDate = project?.endDate;
  const dueDate = getValues("dueDate");

  const addFormError = (
    fieldName: INPUT_FIELDS,
    error: ErrorType,
    focusField = false,
  ) => {
    setError(
      fieldName,
      {
        type: error.type,
        message: error.message,
      },
      { shouldFocus: focusField },
    );
  };

  useEffect(() => {
    if (data?.url && whetherLinkExists(embeddedLinks, data?.url) === -1) {
      setEmbeddedLinks((prev) => [
        ...prev,
        {
          url: data.url,
          title: data?.title || data?.domain,
          preview: data?.images[0] || "",
        },
      ]);
      resetField("links");
      setEnteredLink("");
    } else {
      data?.url &&
        addFormError(
          INPUT_FIELDS.links,
          {
            type: "custom",
            message: t("errors.linkAlreadyExists"),
          },
          true,
        );
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      switch (error?.message) {
        case "Request failed with status code 404":
          addFormError(INPUT_FIELDS.links, {
            type: "pattern",
            message: t("errors.invalidLink"),
          });
          setEnteredLink("");
          break;
        default:
          addFormError(INPUT_FIELDS.links, {
            type: "custom",
            message: t("errors.somethingWentWrong"),
          });
          setEnteredLink("");
      }
    }
  }, [error]);

  useEffect(() => {
    if (
      dueDate &&
      projectEndDate &&
      isAfter(new Date(dueDate), new Date(projectEndDate))
    ) {
      setShowInvalidDateModal(true);
      setSelectedInvalidDate(true);
      setRequestError(t("errors.selectedDateIsAfterProjectEndDate"));
    } else {
      setShowInvalidDateModal(false);
      setSelectedInvalidDate(false);
      setRequestError("");
    }
  }, [dueDate, projectEndDate]);

  const onSubmit: SubmitHandler<CreateTaskFormInput> = async ({
    title,
    description,
    assigneeIds,
    dueDate,
  }) => {
    if (isBefore(getZonedDate(dueDate), startOfDay(new Date())))
      return setRequestError(t("shared.invalidDate"));

    try {
      const result = await mutateAsync({
        task: {
          projectId,
          title,
          description,
          links: embeddedLinks,
          assigneeIds,
          dueDate: dueDate,
          shouldPostToSlack,
          isHighlighted,
        },
      });

      if (result.createTask) {
        reset(DEFAULT_VALUES);
        setRequestError("");
        onClose();
        const id: string = result.createTask.id;
        const scheduledAt = new Date(dueDate).toISOString();
        const projectId: string = result.createTask.project.id;
        setCreatedResourceId(`task-${id}`);
        navigate(
          `/projects/${projectId}?level=Timeline&card=task-${id}&scheduledAt=${scheduledAt}`,
        );
      }
    } catch {
      setRequestError(t("errors.somethingWentWrong"));
    }
  };

  const handleEmbedLink = (event: React.FormEvent) => {
    event.preventDefault();
    const url: string = getValues("links");

    if (validateUrl(url)) {
      setEnteredLink(getValues("links"));
    } else {
      addFormError(
        INPUT_FIELDS.links,
        {
          type: "pattern",
          message: t("errors.invalidLink"),
        },
        true,
      );
    }
  };

  const removeEmbeddedLinks = (event: React.FormEvent, url: string) => {
    event.preventDefault();
    const newEmbeddedLinks = [...embeddedLinks];
    const linkIndex = embeddedLinks.findIndex((links) => links.url === url);
    if (linkIndex > -1) {
      newEmbeddedLinks.splice(linkIndex, 1);
      setEmbeddedLinks(newEmbeddedLinks);
      setEnteredLink("");
    }
  };

  const renderEmbeddedLinks = (list: EmbeddedLinks[]) => {
    return (
      <>
        <Base2>Links</Base2>
        <div className="align-center flex flex-wrap gap-4">
          {list.map((link) => (
            <Link
              metadata={link}
              key={link.url}
              isRemovable={true}
              onRemove={(event, url = link.url) =>
                removeEmbeddedLinks(event, url)
              }
            />
          ))}
        </div>
      </>
    );
  };

  const handleUpdateProjectEndDate = () => {
    if (dueDate) {
      updateProjectEndDate({
        projectId,
        eventDate: format(new Date(dueDate), "yyyy-LL-dd"),
      });
      setShowInvalidDateModal(false);
      setSelectedInvalidDate(false);
      setRequestError("");
    }
  };

  useEffect(() => {
    setValue(INPUT_FIELDS.assigneeIds, assigneeIds);
  }, [assigneeIds, setValue]);

  return (
    <InteractionForm
      onClose={onClose}
      onSubmit={
        selectedInvalidDate ? noop : (handleSubmit(onSubmit) as () => void)
      }
    >
      <Input
        id={INPUT_FIELDS.title}
        label={t("component.createTask.titleLabel")}
        placeholder={t("component.createTask.titlePlaceholder")}
        maxLength={MAX_TITLE_CHARS}
        showCounter
        longCountDescription
        {...register(INPUT_FIELDS.title, {
          required: t("errors.presence"),
        })}
        error={errors?.[INPUT_FIELDS.title]?.message}
      />
      <Input
        id={INPUT_FIELDS.description}
        label={t("component.createTask.descriptionLabel")}
        placeholder={t("component.createTask.descriptionPlaceholder")}
        maxLength={MAX_DESCRIPTION_CHARS}
        {...register(INPUT_FIELDS.description, {
          required: t("errors.presence"),
        })}
        error={errors?.[INPUT_FIELDS.description]?.message}
        multiline
        expandable
        resizable
        showCounter
        longCountDescription
      />

      <DatePicker.withRef
        className="max-w-sm"
        label={t("shared.dueDate")}
        format="MM/d/YY"
        {...register(INPUT_FIELDS.dueDate, {
          required: t("errors.presence"),
        })}
        onChange={(selectedDate) => {
          if (selectedDate) {
            setValue(
              INPUT_FIELDS.dueDate,
              formatISO(selectedDate, { representation: "date" }),
              {
                shouldValidate: true,
              },
            );
          }
        }}
        error={errors.dueDate?.message}
      />
      <SlackControl onToggle={setShouldPostToSlack} interactionType="tasks" />

      <MultiUserInput
        label={t("component.createTask.assignTo")}
        placeholder={t("component.createTask.assignToFull")}
        selectedSectionLabel={t("component.createTask.assignees", {
          number: assigneeIds.length,
        })}
        users={projectMembers}
        {...register(INPUT_FIELDS.assigneeIds, {
          required: t("errors.presence"),
        })}
        onSelectUserIds={setAssigneeIds}
        error={
          //https://github.com/react-hook-form/react-hook-form/issues/8036#issuecomment-1070966732
          (errors?.assigneeIds as unknown as FieldError)?.message
        }
        clearError={() => clearErrors(INPUT_FIELDS.assigneeIds)}
        userPillColor="bg-neutral-0"
        userPillTextColor="text-neutral-700"
      />

      <>
        <Input
          contentClassName={clsx(Z_INDEX_LEVELS.MODAL)}
          id={INPUT_FIELDS.links}
          label={t("shared.linksLabel")}
          placeholder={t("shared.linksPlaceholder")}
          {...register(INPUT_FIELDS.links)}
          error={errors?.[INPUT_FIELDS.links]?.message}
        />

        <Button onClick={handleEmbedLink}>
          {isLoading ? (
            <CircleNotch size={20} color="#fcfcfc" className="animate-spin" />
          ) : (
            t("shared.embedLink")
          )}
        </Button>

        <Caption1 className="text-center leading-5 text-neutral-60">
          {t("shared.workWithLinks1")}
          <br />
          {t("shared.workWithLinks2")}
        </Caption1>
        {embeddedLinks.length > 0 && renderEmbeddedLinks(embeddedLinks)}
      </>
      <ConfirmDialog
        open={showInvalidDateModal}
        onCancel={() => setShowInvalidDateModal(false)}
        onConfirm={handleUpdateProjectEndDate}
        title={t("component.invalidDateModal.title")}
        subtitle={
          userHasAdminAccess
            ? t("component.invalidDateModal.subtitle")
            : t("component.invalidDateModal.subtitleAlt")
        }
        cancelButtonText={t("component.invalidDateModal.cancel")}
        confirmButtonText={
          userHasAdminAccess ? t("component.invalidDateModal.confirm") : ""
        }
        confirmButtonVariant="outline"
        zIndex={Z_INDEX_LEVELS.MODAL_CONTROL}
      />
      <FormInputErrors error={requestError} />
    </InteractionForm>
  );
}

export default CreateTaskForm;
