import React from "react";

function BoldActiveIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#F2F4F4" />
      <path
        d="M9.99957 15.25H18.2496C19.2441 15.25 20.198 15.6451 20.9012 16.3483C21.6045 17.0516 21.9996 18.0054 21.9996 19C21.9996 19.9945 21.6045 20.9484 20.9012 21.6516C20.198 22.3549 19.2441 22.75 18.2496 22.75L9.99902 22.7495V8.49951L17.1246 8.49997C18.0197 8.49997 18.8781 8.85555 19.5111 9.48848C20.144 10.1214 20.4996 10.9799 20.4996 11.875C20.4996 12.7701 20.144 13.6285 19.5111 14.2615C18.8781 14.8944 18.0197 15.25 17.1246 15.25"
        stroke="#00AE8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#00AE8F" />
    </svg>
  );
}

export default BoldActiveIcon;
