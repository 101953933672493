import { HStack, VStack } from "components";
import { Button } from "components/base";
import { t } from "i18n-js";
import { noop } from "lodash";
import React, { FormEvent, ReactNode } from "react";

type InteractionFormProps = {
  onClose?: () => void;
  onSubmit?: () => void;
  submitTitle?: string;
  children?: ReactNode;
};

export default function InteractionForm({
  onClose = noop,
  onSubmit = noop,
  submitTitle = "",
  children,
}: InteractionFormProps) {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <VStack justify="between" className="flex-grow overflow-auto">
      <form
        className="flex flex-grow flex-col"
        onSubmit={handleSubmit}
        id="create-interaction"
      >
        <VStack className="flex-grow px-14 py-8" space={6}>
          {children}
        </VStack>
      </form>
      <HStack
        className="h-22 px-14 py-4 shadow-t-lg"
        justify="between"
        align="center"
      >
        <Button variant="outline" onClick={onClose}>
          {t("shared.cancel")}
        </Button>
        <Button form="create-interaction" variant="secondary">
          {submitTitle || t("shared.saveChanges")}
        </Button>
      </HStack>
    </VStack>
  );
}
