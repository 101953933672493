import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Overlay } from "components/partial";
import React, { Fragment, ReactNode } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

type SliderModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  children: ReactNode;
  slideFromLeft: boolean;
};

export default function SliderModal({
  isOpen,
  closeModal,
  children,
  slideFromLeft,
}: SliderModalProps) {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        onClose={closeModal}
        className={clsx("fixed inset-0", Z_INDEX_LEVELS.MODAL)}
        open={isOpen}
      >
        <Overlay />
        <SliderModalSidebar slideFromLeft={slideFromLeft}>
          {children}
        </SliderModalSidebar>
      </Dialog>
    </Transition>
  );
}

function SliderModalSidebar({
  children,
  slideFromLeft,
}: {
  children: ReactNode;
  slideFromLeft: boolean;
}) {
  return (
    <Transition.Child
      as="div"
      className={clsx(
        "absolute top-0 flex h-screen flex-col overflow-y-auto bg-white shadow-2xl",
        slideFromLeft ? "left-0 w-[720px]" : "right-0 w-1/2",
      )}
      enter="ease-out duration-500"
      enterFrom={slideFromLeft ? "-left-full" : "-right-full"}
      leave="ease-in duration-500"
      leaveTo={slideFromLeft ? "-left-full" : "-right-full"}
    >
      <div tabIndex={0} />
      {children}
    </Transition.Child>
  );
}
