import { Avatar, Button } from "components/base";
import { useWorkshopContext } from "components/pages/Workshop/components";
import { Base2, Caption1, Overline } from "components/Typography";
import {
  DailyRoom,
  WorkshopParticipant,
  WorkshopParticipantRole,
} from "graphql/generated";
import { t } from "i18n-js";
import { ChevronDownIcon, DragHandle, XIcon } from "icons";
import {
  useAddParticipantsToBreakoutRoom,
  useCreateBreakoutRoom,
  useDeleteBreakoutRoom,
  useRemoveParticipantFromBreakoutRoom,
} from "mutations/workshop";
import { Plus, Sparkle } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useQueryClient } from "react-query";

import {
  autoSort,
  DisplayRoom,
  getFilteredUnassigned,
  mapRooms,
} from "./utils";

const ParticipantContent = ({
  participant,
  provided,
}: {
  participant: WorkshopParticipant;
  provided: DraggableProvided;
}) => (
  <div
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    className="mb-2 flex items-center rounded bg-tint-dark-5 py-[10px]"
  >
    <div className="py-1 pl-3">
      <DragHandle className="mr-3" />
    </div>
    <Avatar
      key={participant.id}
      size="small"
      className="mr-2 border border-tint-dark-15"
      textClassName="!text-xs"
      user={participant.user}
    />
    <div>
      <Base2 className="text-neutral-900">{participant.user.fullName}</Base2>
      <Caption1 className="text-neutral-600">{participant.role}</Caption1>
    </div>
  </div>
);

const DraggableParticipant = ({
  participant,
  index,
}: {
  participant: WorkshopParticipant;
  index: number;
}) => {
  return (
    <Draggable draggableId={participant.id} index={index}>
      {(provided) => (
        <ParticipantContent participant={participant} provided={provided} />
      )}
    </Draggable>
  );
};

const Room = ({
  room,
  onDeleted,
  onJoinRoom,
  currentUserId,
}: {
  room: DisplayRoom;
  onDeleted?: (room: DisplayRoom) => void;
  onJoinRoom?: (roomId: string) => void;
  currentUserId?: string;
}) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const participants = room.participants;

  const { mutate: deleteBreakoutRoom } = useDeleteBreakoutRoom();

  const handleDelete = () => {
    deleteBreakoutRoom(
      { id: room.id },
      { onSuccess: () => onDeleted && onDeleted(room) },
    );
  };

  const userIsInRoom = useMemo(() => {
    return room.participants.some((p) => p.id === currentUserId);
  }, [room.participants.length, currentUserId]);

  return (
    <Droppable
      droppableId={room.id}
      key={room.id}
      renderClone={(provided, snapshot, rubric) => {
        const participant = participants[rubric.source.index];

        return (
          <ParticipantContent participant={participant} provided={provided} />
        );
      }}
    >
      {(provided, snapshot) => {
        return (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`group relative mb-2 rounded-lg border border-neutral-300 pt-14 pr-4 pl-5 transition-colors ${
              snapshot.isDraggingOver ? "bg-neutral-100" : ""
            }`}
          >
            <div className="absolute left-0 top-0 flex h-14 w-full items-center justify-center pr-4 pl-5">
              {isConfirming ? (
                <>
                  <Caption1 className="ml-2 flex-1">
                    {t("workshopEditor.deleteRoom")}
                  </Caption1>
                  <Button
                    variant="outline"
                    size="small"
                    onClick={() => setIsConfirming(false)}
                  >
                    {t("shared.cancel")}
                  </Button>
                  <Button
                    variant="destructive"
                    size="small"
                    className="ml-2"
                    onClick={onDeleted && handleDelete}
                  >
                    {t("shared.delete")}
                  </Button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className={isOpen ? "rotate-180" : ""}
                  >
                    <ChevronDownIcon />
                  </button>
                  <Base2 className="ml-3 text-neutral-900">{room.title}</Base2>
                  {onJoinRoom ? (
                    <div className="ml-auto">
                      {!userIsInRoom && (
                        <Button
                          size="small"
                          onClick={() => onJoinRoom(room.id)}
                        >
                          {t("breakoutActivityEditor.joinRoom")}
                        </Button>
                      )}
                    </div>
                  ) : (
                    <>
                      <Caption1 className="ml-auto text-neutral-500">
                        {`${participants.length} ${
                          participants.length === 1
                            ? t("breakoutActivityEditor.participant")
                            : t("breakoutActivityEditor.participants")
                        }`}
                      </Caption1>
                      <button
                        className={
                          "ml-3 hidden justify-center group-hover:flex"
                        }
                        onClick={() => setIsConfirming(true)}
                      >
                        <XIcon className="h-5" />
                      </button>
                    </>
                  )}
                </>
              )}
            </div>

            {!isOpen && (
              <div
                className={`w-0 overflow-x-hidden transition-all ${
                  snapshot.isUsingPlaceholder
                    ? "h-18 opacity-100"
                    : "h-0 opacity-0"
                }`}
              >
                {provided.placeholder}
              </div>
            )}

            {isOpen && (
              <div
                className={
                  participants.length > 0 || snapshot.isUsingPlaceholder
                    ? "pb-2"
                    : ""
                }
              >
                {participants.map((participant, index) => (
                  <DraggableParticipant
                    key={participant.user.id}
                    participant={participant}
                    index={index}
                  />
                ))}
                <div className="w-0 overflow-x-hidden">
                  {provided.placeholder}
                </div>
              </div>
            )}
          </div>
        );
      }}
    </Droppable>
  );
};

export const BreakoutRoomSorter = ({
  participants,
  dailyRooms,
  activityId,
  roomsActive = false,
  onJoinRoom,
}: {
  participants: WorkshopParticipant[];
  dailyRooms: DailyRoom[];
  activityId: string;
  roomsActive?: boolean;
  onJoinRoom?: () => void;
}) => {
  const { mutate: createBreakoutRoom, isLoading: isCreatingBreakoutRoom } =
    useCreateBreakoutRoom();
  const { mutate: addParticipantsToBreakoutRoom } =
    useAddParticipantsToBreakoutRoom();
  const { mutateAsync: removeParticipantFromBreakoutRoomAsync } =
    useRemoveParticipantFromBreakoutRoom();
  const queryClient = useQueryClient();

  const [unassigned, setUnassigned] = useState(
    getFilteredUnassigned(participants, dailyRooms),
  );

  useEffect(() => {
    setUnassigned(getFilteredUnassigned(participants, dailyRooms));
  }, [participants, dailyRooms]);

  const { currentWorkshopParticipant } = useWorkshopContext();
  const [rooms, setRooms] = useState<DisplayRoom[]>(mapRooms(dailyRooms));

  const noParticipants = useMemo(
    () =>
      !participants.some((p) => p.role === WorkshopParticipantRole.Participant),
    [participants],
  );

  const hasUnAssignedParticipants = useMemo(
    () =>
      unassigned.some((p) => p.role === WorkshopParticipantRole.Participant),
    [unassigned],
  );

  useEffect(() => {
    setUnassigned(getFilteredUnassigned(participants, dailyRooms));
    setRooms(mapRooms(dailyRooms));
  }, [participants, dailyRooms]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    } else {
      const newRooms = [...rooms];
      const newUnassigned = [...unassigned];

      const oldRooms = rooms;
      const oldUnassigned = unassigned;

      // reset the move if the mutation fails
      const handleError = () => {
        setRooms(oldRooms);
        setUnassigned(oldUnassigned);
        void queryClient.refetchQueries(["WorkshopById"]);
      };

      if (result.source.droppableId !== "unassigned") {
        if (result.destination.droppableId === "unassigned") {
          void removeParticipantFromBreakoutRoomAsync(
            {
              breakoutDailyRoomId: result.source.droppableId,
              workshopParticipantId: result.draggableId,
            },
            {
              onError: handleError,
            },
          );
          onJoinRoom && onJoinRoom();
        }

        if (result.destination.droppableId !== "unassigned") {
          addParticipantsToBreakoutRoom(
            {
              input: {
                breakoutDailyRoomId: result.destination.droppableId,
                workshopParticipantIds: [result.draggableId],
              },
            },
            {
              onError: handleError,
            },
          );
        }
      }

      if (
        result.source.droppableId === "unassigned" &&
        result.destination.droppableId !== "unassigned"
      ) {
        addParticipantsToBreakoutRoom(
          {
            input: {
              breakoutDailyRoomId: result.destination.droppableId,
              workshopParticipantIds: [result.draggableId],
            },
          },
          {
            onError: handleError,
          },
        );
      }

      const source =
        result.source.droppableId === "unassigned"
          ? newUnassigned
          : newRooms[
              newRooms.findIndex(
                (room) => room.id === result.source.droppableId,
              )
            ].participants;

      const destination =
        result.destination.droppableId === "unassigned"
          ? newUnassigned
          : newRooms[
              newRooms.findIndex(
                (room) => room.id === result.destination?.droppableId,
              )
            ].participants;

      const [removed] = source.splice(result.source.index, 1);
      destination.splice(result.destination.index, 0, removed);

      setRooms(newRooms);
      setUnassigned(newUnassigned);
    }
  };

  const handleAddRoom = () => {
    const title = `${t("breakoutActivityEditor.room")} ${rooms.length + 1}`;

    createBreakoutRoom(
      {
        dailyRoom: {
          workshopActivityId: activityId,
          title,
        },
      },
      {
        onSuccess: ({ createDailyRoom }) => {
          if (createDailyRoom?.id) {
            setRooms([
              ...rooms,
              {
                id: createDailyRoom.id,
                title: createDailyRoom?.title || title,
                participants: [],
              },
            ]);
          }
        },
      },
    );
  };

  const handleDeleteRoom = (room: DisplayRoom) => {
    setRooms(rooms.filter((r) => r.id !== room.id));
    setUnassigned([...unassigned, ...room.participants]);
  };

  const handleAutoSort = () => {
    const { rooms: newRooms, unassigned: newUnassigned } = autoSort(
      unassigned,
      rooms,
    );

    setRooms(newRooms);
    setUnassigned(newUnassigned);

    for (const room of rooms) {
      addParticipantsToBreakoutRoom({
        input: {
          breakoutDailyRoomId: room.id,
          workshopParticipantIds: room.participants.map((p) => p.id),
        },
      });
    }
  };

  const handleJoinRoom = (roomId: string) => {
    const oldRoomId =
      rooms.find((room) =>
        room.participants.some((p) => p.id === currentWorkshopParticipant?.id),
      )?.id || "unassigned";

    if (roomId === "unassigned") {
      void removeParticipantFromBreakoutRoomAsync({
        breakoutDailyRoomId: oldRoomId,
        workshopParticipantId: currentWorkshopParticipant?.id || "",
      });
    } else if (roomId !== "unassigned") {
      addParticipantsToBreakoutRoom({
        input: {
          breakoutDailyRoomId: roomId,
          workshopParticipantIds: [currentWorkshopParticipant?.id || ""],
        },
      });
    }

    onJoinRoom && onJoinRoom();
  };

  const getAutoSortButton = () => {
    if (noParticipants) {
      return t("breakoutActivityEditor.noParticipants");
    } else {
      return hasUnAssignedParticipants
        ? t("breakoutActivityEditor.autoAssign")
        : t("breakoutActivityEditor.allAssigned");
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="mb-4 flex items-center justify-between">
        <Base2>{t("breakoutActivityEditor.rooms")}</Base2>
        <Button
          variant="tertiary"
          size="small"
          onClick={handleAddRoom}
          className="flex items-center"
          LeftIcon={Plus}
          disabled={isCreatingBreakoutRoom}
        >
          {t("breakoutActivityEditor.addRoom")}
        </Button>
      </div>

      {roomsActive && (
        <Room
          room={{
            id: "unassigned",
            title: t("breakoutActivityEditor.mainRoom"),
            participants: unassigned,
          }}
          onJoinRoom={handleJoinRoom}
          currentUserId={currentWorkshopParticipant?.id}
        />
      )}

      {rooms.length > 0 ? (
        rooms.map((room) => (
          <Room
            room={room}
            onDeleted={handleDeleteRoom}
            key={`room_${room.id}`}
            onJoinRoom={roomsActive ? handleJoinRoom : undefined}
            currentUserId={currentWorkshopParticipant?.id}
          />
        ))
      ) : (
        <div className="flex h-14 items-center justify-center">
          <Caption1 className="text-tint-dark-50">
            {t("breakoutActivityEditor.noRooms")}
          </Caption1>
        </div>
      )}
      {!roomsActive && (
        <>
          <div className="mt-4">
            <Overline className="mb-2 text-neutral-90">
              {t("breakoutActivityEditor.unassigned")}
            </Overline>
            <Caption1 className="text-tint-dark-50">
              {t("breakoutActivityEditor.unassignedDescription")}
            </Caption1>
            <Button
              size="small"
              variant="outline"
              className="my-2 w-full"
              LeftIcon={hasUnAssignedParticipants ? Sparkle : undefined}
              onClick={() => {
                handleAutoSort();
              }}
              disabled={
                rooms.length === 0 ||
                isCreatingBreakoutRoom ||
                !hasUnAssignedParticipants
              }
            >
              {getAutoSortButton()}
            </Button>
          </div>
          <Droppable
            droppableId="unassigned"
            renderClone={(provided, snapshot, rubric) => {
              const participant = unassigned[rubric.source.index];

              return (
                <ParticipantContent
                  participant={participant}
                  provided={provided}
                />
              );
            }}
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="min-h-[40px]"
              >
                {unassigned.map((participant, index) => (
                  <DraggableParticipant
                    participant={participant}
                    index={index}
                    key={participant.user.id}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </>
      )}
    </DragDropContext>
  );
};

export default BreakoutRoomSorter;
