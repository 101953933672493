import { Base2 } from "components/Typography";
import { ChevronDownIcon } from "icons";
import React from "react";

import { DropdownTriggerComponent } from "./DropdownTrigger.types";

const DropdownTrigger: DropdownTriggerComponent = ({
  open,
  primaryText,
  secondaryText,
  prefix,
  as,
  children,
}) => {
  const Component = as || "button";

  return (
    <Component
      className={`relative flex h-12 w-full items-center justify-between bg-tint-dark-10 py-[14px] px-3 outline-none ${
        open ? "rounded-t-md" : "rounded-md"
      }`}
    >
      {children || (
        <div className="flex flex-1 items-center gap-1 whitespace-nowrap">
          {prefix}
          {primaryText && (
            <Base2 className="!mt-[1px] text-neutral-60">{primaryText}</Base2>
          )}
          {secondaryText && (
            <div className="relative h-5 flex-1">
              <Base2 className="absolute !mt-[1px] mr-4 w-full overflow-hidden text-ellipsis whitespace-nowrap text-left text-neutral-90">
                {secondaryText}
              </Base2>
            </div>
          )}
        </div>
      )}
      {/* NOTE: the margins on these text components will probably have to change if/ when we fix the random text margins */}
      <ChevronDownIcon
        className={`flex-shrink-0 ${open ? "rotate-180" : ""}`}
      />
    </Component>
  );
};

export default DropdownTrigger;
