import React from "react";

const RecoderIcon: React.FC<
  JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { size?: number }
> = ({ size = 24, fill, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 197 196"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M62.4348 122.001L66.7752 117.66C67.1748 117.261 67.1748 116.613 66.7752 116.213L58.8117 108.25C52.4182 101.856 52.4183 91.4907 58.8117 85.0973L74.8468 69.0623C74.4854 62.3387 76.8395 55.4948 81.9641 50.3701L53.387 78.9472C48.8894 83.4448 46.0686 89.4354 45.8474 95.7921C45.6078 102.678 48.1786 109.192 53.0243 114.037L60.9878 122.001C61.3874 122.4 62.0352 122.4 62.4348 122.001H62.4348Z"
        fill={fill || "#2A5B72"}
      />
      <path
        d="M123.844 124.25C123.451 117.027 119.948 110.386 113.969 105.887C110.668 103.404 106.747 101.808 102.65 101.274C94.8766 100.259 87.3793 102.835 81.9643 108.25L73.4181 116.795L73.4231 116.8L58.0165 132.207L58.0115 132.202L53.7469 136.466C53.3474 136.866 53.3474 137.514 53.7469 137.913L58.0874 142.254C58.4869 142.653 59.1347 142.653 59.5343 142.254L70.3876 131.401L70.4008 131.415L76.1894 125.626L76.1759 125.613L87.7517 114.037C91.8102 109.98 97.6224 108.313 103.479 109.75C104.952 110.112 106.37 110.702 107.664 111.493C114.026 115.382 116.979 122.572 115.176 129.821C114.821 131.248 114.251 132.623 113.49 133.881C110.134 139.425 104.283 142.404 98.0548 141.936C94.0462 141.635 90.318 139.756 87.4757 136.914L79.8063 129.244C79.4068 128.844 78.7589 128.844 78.3593 129.244L74.0181 133.585C73.6185 133.984 73.6185 134.632 74.0181 135.032L81.6037 142.617C86.1024 147.115 92.0948 149.935 98.4529 150.154C105.338 150.392 111.848 147.821 116.692 142.977C121.33 138.339 123.884 132.173 123.884 125.613C123.884 125.156 123.869 124.702 123.844 124.25Z"
        fill={fill || "#2A5B72"}
      />
      <path
        d="M128.265 73.5217L128.278 73.5347L134.066 67.7463L134.053 67.7333L144.906 56.8811C145.305 56.4815 145.305 55.8337 144.906 55.4341L140.565 51.0937C140.166 50.6941 139.518 50.6941 139.118 51.0936L133.16 57.0513L133.165 57.0569L117.758 72.4626L117.753 72.4575L111.235 78.9773C104.916 85.297 94.5891 85.7787 88.0917 79.6428C80.1005 72.096 81.4463 58.4866 92.1291 52.9819C92.8028 52.6347 93.5091 52.3481 94.2346 52.1288C100.404 50.2639 106.626 51.8832 110.902 56.1576L118.865 64.1216C119.265 64.5212 119.913 64.5212 120.312 64.1217L124.653 59.7807C125.053 59.3812 125.053 58.7333 124.653 58.3337L117.05 50.7308C112.551 46.2325 106.559 43.4124 100.201 43.1931C93.316 42.9556 86.8059 45.5263 81.962 50.3702C76.8373 55.4949 74.4832 62.3388 74.8447 69.0624C75.1584 74.9003 77.5123 80.6478 81.962 85.0975C91.5373 94.6728 107.116 94.6718 116.689 85.0975L128.265 73.5217V73.5217Z"
        fill={fill || "#00A889"}
      />
      <path
        d="M145.269 78.9495L137.684 71.3637C137.284 70.9641 136.636 70.9641 136.237 71.3636L131.895 75.7047C131.496 76.1043 131.496 76.7522 131.895 77.1518L139.842 85.0974C146.236 91.4904 146.236 101.856 139.843 108.249L123.842 124.25C123.866 124.702 123.882 125.156 123.882 125.613C123.882 132.173 121.328 138.339 116.689 142.977L145.629 114.037C150.473 109.193 153.044 102.683 152.806 95.7986C152.587 89.4406 149.767 83.4482 145.269 78.9495Z"
        fill={fill || "#00A889"}
      />
      <path
        d="M48.9011 152.216C51.7265 152.216 54.017 149.925 54.017 147.1C54.017 144.274 51.7265 141.984 48.9011 141.984C46.0756 141.984 43.7852 144.274 43.7852 147.1C43.7852 149.925 46.0756 152.216 48.9011 152.216Z"
        fill={fill || "#2A5B72"}
      />
    </svg>
  );
};

export default RecoderIcon;
