class GlobalErrorHandler {
  private originalConsoleError: (...args: any[]) => void;

  constructor() {
    this.originalConsoleError = console.error;
    console.error = this.suppressErrorLogging;
  }

  private suppressErrorLogging = (...args: any[]): void => {
    const errorMessage = args.join(" ");
    const validEnvironments = new Set(["staging", "production"]);
    const env = window.env?.vars.RAILS_ENV;

    if (
      !env ||
      (validEnvironments.has(env) &&
        errorMessage.includes("Resource not found."))
    ) {
      return;
    }
  };

  public restoreConsoleError(): void {
    console.error = this.originalConsoleError;
  }
}

const globalErrorHandler = new GlobalErrorHandler();

export default globalErrorHandler;
