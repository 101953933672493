import clsx from "clsx";
import {
  Base1,
  Caption1,
  Heading3,
  HStack,
  Paragraph1,
  VStack,
} from "components";
import { Avatar, Button, Link } from "components/base";
import Comments from "components/Comments/Comments";
import ShowComments from "components/Comments/ShowComments";
import { EmbeddedLinks } from "components/CreateInteractions/CreateAnnouncementForm";
import { InteractionModal } from "components/partial";
import { useGetEnvsContext } from "contexts";
import { format, parseISO } from "date-fns";
import { Announcement, Comment } from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import useAnnouncementCommentFlagReset from "mutations/use-announcement-comment-flag-reset";
import { Megaphone } from "phosphor-react";
import { useAnnouncementComments } from "queries";
import React, { useEffect, useState } from "react";
import { DeepPartial } from "react-hook-form";
import { useParams } from "react-router-dom";

type AnnouncementDetailsModalProps = {
  show: boolean;
  onClose: () => void;
  onEdit: () => void;
  announcement: DeepPartial<Announcement>;
};

export const MAX_DESCRIPTION_LENGTH = 75;

export default function AnnouncementDetailsModal({
  show,
  onClose,
  onEdit,
  announcement,
}: AnnouncementDetailsModalProps) {
  const {
    project,
    description,
    author,
    isHighlighted,
    isSchedule,
    links,
    announcementTitle,
    id,
    createdAt,
  } = announcement;

  const { projectId = "" } = useParams();

  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [commentCount, setCommentCount] = useState<number>(10);

  const { mutateAsync: AnnouncementCommentFlagReset } =
    useAnnouncementCommentFlagReset();

  const { hasMemberAccess } = useProjectAccessLevel({
    projectId,
  });
  const { currentUser } = useGetEnvsContext();
  const title =
    ((description?.length || 0) > 75
      ? `${description?.slice(0, MAX_DESCRIPTION_LENGTH) || ""}...`
      : description) || "";

  useEffect(() => {
    return () => {
      if (!!id) {
        void AnnouncementCommentFlagReset({
          announcementId: id,
        });
      }
    };
  }, []);

  const { comments, totalCount } = useAnnouncementComments({
    announcementId: id as string,
    count: commentCount,
  });

  const loadMoreComments = () => {
    setCommentCount(commentCount + 10);
  };

  return (
    <InteractionModal
      isOpen={show}
      closeModal={onClose}
      header={announcementTitle || ""}
      icon={<Megaphone mirrored />}
      variant="yellow"
      slideFromLeft={true}
      disableHighlightClick={true}
      isHighlighted={!!isHighlighted}
      showHighlightFeature={true}
    >
      <div className="flex-grow overflow-y-auto px-[50px] py-12">
        <div className="mb-4 flex h-6">
          <Avatar
            user={author}
            className="mb-4"
            size="mini"
            textClassName="!text-xs"
          />
          <Caption1 className=" mx-2 self-center text-neutral-70">
            {t("component.announcementCard.createdThisAnnouncement", {
              createdBy: author?.fullName,
            })}
            <span className="ml-1 text-neutral-70">
              {format(parseISO(createdAt as string), "MMM d, yyyy")}
            </span>
          </Caption1>
        </div>
        <Paragraph1 className="text-neutral-70">{description}</Paragraph1>

        {hasMemberAccess ? (
          <>
            <Heading3 className={clsx("mt-6")}>
              {t("constant.comments.comments")}
            </Heading3>
            <ShowComments
              comments={comments as Comment[]}
              loadMoreComments={loadMoreComments}
              totalCommentCount={totalCount}
            />
            <div className="flex items-center">
              <Avatar
                user={currentUser}
                size="mini"
                textClassName="!text-xs"
                className={!hasErrors ? "-mb-6" : ""}
              />
              <Comments
                eventId={id || ""}
                eventType="announcement"
                hasErrors={setHasErrors}
              />
            </div>
          </>
        ) : undefined}

        {links?.length ? (
          <VStack className="mt-6" space={5}>
            <Base1 className="text-neutral-90">{t("shared.links")}</Base1>
            <HStack align="center" className="flex-wrap gap-2">
              {links.map((link) => (
                <Link key={link?.url} metadata={link as EmbeddedLinks} />
              ))}
            </HStack>
          </VStack>
        ) : (
          <></>
        )}
      </div>
      <HStack
        className="px-14 pt-4 pb-6 shadow-t-lg"
        justify="between"
        align="center"
      >
        {hasMemberAccess && (
          <Button
            onClick={onEdit}
            variant="outline"
            className="mr-4 !inline-block !px-8 py-3.5"
            aria-label={t("shared.edit")}
          >
            {t("shared.edit")}
          </Button>
        )}
        <Button
          onClick={onClose}
          variant="secondary"
          aria-label={t("shared.done")}
        >
          {t("shared.done")}
        </Button>
      </HStack>
    </InteractionModal>
  );
}
