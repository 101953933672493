import clsx from "clsx";
import { useToast } from "components/provider";
import { Base2, Caption1 } from "components/Typography";
import { t } from "i18n-js";
import { File } from "phosphor-react";
import React from "react";

interface VttItemProps {
  resource: {
    __typename: "VttLink";
    contentType?: string | null;
    fileName?: string | null;
    roomType?: string | null;
    url?: string | null;
  };
  large?: boolean;
}

const VttItem = ({ resource, large }: VttItemProps) => {
  const { addToast } = useToast();

  const resourceName = () => {
    if (!!resource && !!resource.fileName) {
      const lastCharIndex = resource.fileName.lastIndexOf(".");
      const fileNameWithoutExtension = resource.fileName.slice(
        0,
        lastCharIndex,
      );
      const extension = resource.fileName.slice(lastCharIndex);
      return fileNameWithoutExtension.length > 120
        ? fileNameWithoutExtension.slice(0, 120) + "..." + extension
        : fileNameWithoutExtension + extension;
    }
    return "";
  };

  const handleClick = () => {
    if (!resource || !resource?.url) {
      addToast({
        message: t("component.vttItem.missingUrl"),
        variant: "error",
        hasCloseOption: true,
      });
      return;
    }

    addToast({
      message: t("component.vttItem.fileOpened"),
      hasCloseOption: true,
    });
  };

  return (
    <a
      href={resource?.url || ""}
      target="_blank"
      className={clsx(
        "mb-2 mr-4 flex flex-row items-center gap-2 rounded-lg border-neutral-70 bg-tint-dark-5 p-2",
        {
          "hover:cursor-pointer hover:bg-tint-dark-10":
            !!resource && !!resource.url,
        },
        {
          "mr-2 min-w-[90px] px-4 py-2": large,
        },
      )}
      onClick={handleClick}
    >
      <div
        className={clsx(
          "flex h-5 w-5 items-center justify-center rounded bg-tint-dark-100",
          { "!h-6 !w-6": large },
        )}
      >
        <File />
      </div>
      {large ? (
        <Base2 className="text-neutral-90">{resourceName()}</Base2>
      ) : (
        <Caption1 className="text-neutral-90">{resourceName()}</Caption1>
      )}
    </a>
  );
};

export default VttItem;
