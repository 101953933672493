import { useUpdateWorkshopMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation UpdateWorkshop($workshop: UpdateWorkshopInput!) {
    updateWorkshop(workshop: $workshop) {
      id
    }
  }
`;

export function useUpdateWorkshop() {
  const queryClient = useQueryClient();
  return useUpdateWorkshopMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["WorkshopById"]);
      await queryClient.refetchQueries(["WorkshopById"]);
    },
  });
}
