import React from "react";

import { HStack } from "./layout";
import { Base2 } from "./Typography";

type PersonalityProfileTypeProps = {
  type: string;
  points: string[];
};

export function PersonalityProfileType({
  type,
  points,
}: PersonalityProfileTypeProps) {
  return (
    <div>
      <HStack>
        <Base2>
          The priorities for <strong>{type} profile </strong> :
        </Base2>
      </HStack>
      <div className="!h-[82px] !w-[300px]">
        {points.map((point) => {
          return (
            <>
              <ul>
                <li
                  style={{
                    listStyleType: "disc",
                    fontSize: "12px",
                    marginLeft: "16px",
                  }}
                >
                  {point}
                </li>
              </ul>
            </>
          );
        })}
      </div>
    </div>
  );
}
