import clsx from "clsx";
import { useToast } from "components/provider";
import { Caption1 } from "components/Typography";
import { Link } from "graphql/generated";
import { t } from "i18n-js";
import { XIcon } from "icons";
import React from "react";

const getFavicon = (url: string) => {
  const originUrl = new URL(url);
  const linkFavicon = `https://icon.horse/icon/${originUrl.host}`;
  return linkFavicon;
};

interface LinkItemProps {
  link: Pick<Link, "id" | "title" | "url">;
  handleRemoveLink?: (linkId: string) => void;
}

const LinkItem = ({ link, handleRemoveLink }: LinkItemProps) => {
  const { addToast } = useToast();
  const FAVICON = getFavicon(link.url);

  const handleClick = () => {
    if (!link || !link.url) {
      addToast({
        message: t("component.linkItem.missingUrl"),
        variant: "error",
        hasCloseOption: true,
      });
      return;
    }

    addToast({
      message: t("component.linkItem.fileOpened"),
      hasCloseOption: true,
    });
  };

  return (
    <a
      href={link.url}
      target="_blank"
      className={clsx(
        "mb-2 mr-4 flex w-max flex-row items-center gap-2 rounded-lg border-neutral-70 bg-tint-dark-5 p-2",
        {
          "hover:cursor-pointer hover:bg-tint-dark-10": !!link && !!link.url,
        },
      )}
      onClick={handleClick}
    >
      <img className="h-6 w-6 rounded object-cover" src={FAVICON} />
      <Caption1 className="text-neutral-90">{link.title}</Caption1>
      {handleRemoveLink && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleRemoveLink(link.id);
          }}
        >
          <XIcon className="h-4 w-4" />
        </button>
      )}
    </a>
  );
};

export default LinkItem;
