import React from "react";

function Files() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 8H13.4999"
        stroke="#16C7A7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 4H13.4999"
        stroke="#16C7A7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 12H13.5"
        stroke="#16C7A7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 3.75L3.5 3.25V6.74962"
        stroke="#16C7A7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.56876 9.53445C2.61627 9.42203 2.68677 9.3208 2.77573 9.23725C2.86468 9.15369 2.97013 9.08967 3.0853 9.04929C3.20047 9.00891 3.32281 8.99307 3.44447 9.00277C3.56612 9.01248 3.6844 9.04752 3.79171 9.10565C3.89902 9.16378 3.99298 9.24372 4.06756 9.34032C4.14215 9.43692 4.1957 9.54805 4.22479 9.66657C4.25387 9.7851 4.25785 9.9084 4.23645 10.0286C4.21506 10.1487 4.16877 10.2631 4.10056 10.3643L2.5 12.5H4.25"
        stroke="#16C7A7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Files;
