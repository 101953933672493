/* eslint-disable unicorn/no-null */
import CharacterCount from "@tiptap/extension-character-count";
import Focus from "@tiptap/extension-focus";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import { AnyExtension, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import clsx from "clsx";
import { Base2, Caption1, Caption2 } from "components/Typography";
import { t } from "i18n-js";
import React, { useState } from "react";

import { RichTextEditorProps } from "./RichTextEditor.types";
import ToolBar from "./ToolBar";

export const getEditorExtensions = ({
  maxCharCount,
  placeholder,
}: {
  maxCharCount?: number;
  placeholder?: string;
}) => [
  StarterKit.configure({
    bulletList: {
      HTMLAttributes: {
        class: "list-disc ml-2",
      },
    },
    orderedList: {
      HTMLAttributes: {
        class: "list-decimal ml-2",
      },
    },
    heading: {
      levels: [1],
      HTMLAttributes: {
        class: "text-xl",
      },
    },
  }) as AnyExtension,
  CharacterCount.configure({
    limit: maxCharCount,
  }),
  Placeholder.configure({
    placeholder: placeholder,
    emptyEditorClass:
      "text-slate-300 before:content-[attr(data-placeholder)] before:float-left before:h-0 before:pointer-events-none",
  }),
  Focus.configure({
    className: "has-focus",
  }),
  Link.configure({
    openOnClick: true,
    linkOnPaste: true,
    HTMLAttributes: {
      class:
        "text-tertiary-blue-700 underline decoration-1 cursor-pointer decoration-tertiary-blue-700 border-none",
      target: "_blank",
      rel: "noopener noreferrer",
    },
  }),
];

const RichTextEditor = ({
  bgColor = "bg-tint-dark-10",
  content,
  error = false,
  isDisabled = false,
  label = "Label",
  maxCharCount = 280,
  maxHeight = "max-h-40",
  minHeight,
  onBlur,
  placeholder = "Value…",
  showCharLimit = true,
  showFocusedRing = true,
  showLabel = true,
  onChange,
  showToolBar = true,
  focusState = false,
}: RichTextEditorProps) => {
  // Editor documentation: https://tiptap.dev/api/editor

  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const editor = useEditor(
    {
      autofocus: focusState,
      content,
      extensions: getEditorExtensions({ maxCharCount, placeholder }),
      // disable Markdown when typing
      editable: !isDisabled,
      editorProps: {
        attributes: {
          class: clsx(
            `rounded-md ${bgColor} mt-3.5 py-3 px-4 focus:outline-none focus:caret-primary-turquoise-10 overflow-y-auto break-words ${
              minHeight ? minHeight : ""
            } ${maxHeight}`,
            {
              "outline outline-offset-2 outline-red-500": error,
              "focus:outline-primary-turquoise-10": !error && showFocusedRing,
              "cursor-not-allowed": isDisabled,
            },
          ),
        },
      },
      // If state function is passed as prop, return output
      // data in JSON format when user clicks out of Editor.
      onBlur({ editor }) {
        onBlur && onBlur(editor.getJSON());
      },
      onUpdate: ({ editor }) => {
        setCursorPosition(editor.state.selection.anchor);
        onChange && onChange(editor.getJSON());
      },
      onFocus: ({ editor }) => {
        if (!!cursorPosition && editor?.isFocused) {
          editor?.commands?.focus(cursorPosition);
        }
      },
      onTransaction: ({ editor }) => {
        if (!editor?.isFocused) {
          setTimeout(() => {
            setCursorPosition(null);
          }, 200);
        }
      },
    },
    [
      content,
      maxCharCount,
      placeholder,
      isDisabled,
      bgColor,
      minHeight,
      maxHeight,
      error,
      showFocusedRing,
      onBlur,
    ],
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const charCount = editor?.storage.characterCount.characters();

  return (
    <section>
      <div
        className={clsx("flex", {
          "justify-end": !showLabel,
          "justify-between": showLabel,
        })}
      >
        {showLabel && (
          <Base2 as="label" className="text-neutral-90">
            {label}
          </Base2>
        )}
        <div
          className={clsx({
            "flex items-center justify-between": showCharLimit,
          })}
        >
          {editor && showToolBar && (
            <ToolBar editor={editor} isDisabled={isDisabled} />
          )}
          {showCharLimit && (
            <Caption1
              className={clsx({
                "border-l border-tint-dark-50 pl-1": showToolBar,
                "text-red-500": error,
                "opacity-100": error,
                "opacity-20": !error,
              })}
            >
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              {`${charCount}/${maxCharCount}${
                true ? " " + t("shared.characters") : ""
              }`}
            </Caption1>
          )}
        </div>
      </div>
      <EditorContent editor={editor} key={JSON.stringify(content)} />
      {error && (
        <Caption2 className="mt-2 text-red-500 opacity-100">
          {t("errors.somethingWentWrong")}
        </Caption2>
      )}
    </section>
  );
};

export default RichTextEditor;
