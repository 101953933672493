import clsx from "clsx";
import { Base2 } from "components/Typography";
import React from "react";

import { ButtonIcon, ButtonProps, ButtonVariantClasses } from "./Button.types";

const variantClasses: ButtonVariantClasses = {
  primary: {
    button:
      "bg-neutral-90 disabled:bg-neutral-90 hover:bg-neutral-70 border border-transparent",
    text: "text-white",
    icon: "text-white",
  },
  secondary: {
    button:
      "bg-primary-turquoise-10 disabled:bg-primary-turquoise-10 hover:bg-primary-turquoise-30 border border-transparent",
    text: "text-neutral-90",
    icon: "text-neutral-90",
  },
  primaryDark: {
    button:
      "bg-white disabled:bg-white hover:bg-neutral-5 border border-transparent",
    text: "text-neutral-90",
    icon: "text-neutral-90",
  },
  tertiary: {
    button:
      "bg-tint-dark-15 disabled:bg-tint-dark-15 hover:bg-tint-dark-30 border border-transparent",
    text: "text-neutral-90",
    icon: "text-neutral-90",
  },
  outline: {
    button:
      "bg-transparent disabled:bg-transparent border border-tint-dark-30 hover:bg-tint-dark-10",
    text: "text-neutral-90",
    icon: "text-neutral-90",
  },
  outlineLight: {
    button:
      "bg-transparent disabled:bg-transparent border border-tint-light-20 hover:bg-tint-light-20",
    text: "text-white",
    icon: "text-white",
  },
  link: {
    button:
      "bg-transparent disabled:bg-transparent border-b-2 border-transparent",
    text: "text-neutral-90 group-hover:text-neutral-70 group-disabled:text-neutral-90 transition-colors",
    icon: "text-neutral-90 group-hover:text-neutral-70 group-disabled:text-neutral-90 transition-colors",
  },
  secondaryDark: {
    button:
      "bg-tint-dark-50 disabled:bg-tint-dark-50 hover:bg-tint-dark-90 border border-transparent",
    text: "text-white",
    icon: "text-white",
  },
  destructive: {
    button:
      "bg-transparent disabled:bg-transparent border border-secondary-red-70 hover:bg-tint-dark-10",
    text: "text-secondary-red-70",
    icon: "text-secondary-red-70",
  },
  contextual: {
    button:
      "bg-transparent border border-primary-turquoise-10 hover:bg-tint-light-20",
    text: "text-primary-turquoise-10",
    icon: "",
  },
  contextualContrast: {
    button:
      "bg-transparent border border-primary-turquoise-70 hover:bg-tint-light-20",
    text: "text-primary-turquoise-70",
    icon: "",
  },
  primaryTurquoise: {
    button:
      "bg-transparent border border-primary-turquoise-30 hover:bg-tint-light-20",
    text: "text-primary-turquoise-10",
    icon: "",
  },
};
function getPadding(
  LeftIcon: ButtonIcon | undefined,
  RightIcon: ButtonIcon | undefined,
  isSmall: boolean,
  isIconButton: boolean,
) {
  if (isIconButton) return isSmall ? "px-2" : "px-3";
  if (LeftIcon && RightIcon) return "px-4";
  if (!LeftIcon && !RightIcon) return isSmall ? "px-6" : "px-8";

  if (LeftIcon) {
    return `pl-4 ${isSmall ? "pr-6" : "pr-7"}`;
  }
  // With RightIcon
  return `pr-4 ${isSmall ? "pl-6" : "pl-7"}`;
}

function getHeight(isSmall: boolean) {
  return isSmall ? "h-10" : "h-12";
}

function getWidth(isSmall: boolean, isIconButton: boolean) {
  if (!isIconButton) return "";
  return isSmall ? "w-10" : "w-12";
}

function getFocusedRing(hasFocusedRing: boolean, isLink: boolean) {
  if (!hasFocusedRing) return "";

  return isLink
    ? "focus:border-b-2 focus:border-primary-turquoise-10 focus:rounded-none"
    : "focus:ring focus:ring-primary-turquoise-10 focus:ring-offset-1";
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      variant = "primary",
      size = "regular",
      disabled,
      LeftIcon,
      RightIcon,
      hasFocusedRing = true,
      ...props
    },
    ref,
  ): JSX.Element => {
    const variantClassNames = variantClasses[variant];
    const isLink = variant === "link";
    const isContextual =
      variant === "contextual" || variant === "contextualContrast";
    const isSmall = size === "small";
    const isIconButton = !children;

    let spacing = isLink
      ? ""
      : [
          getHeight(isSmall),
          getWidth(isSmall, isIconButton),
          getPadding(LeftIcon, RightIcon, isSmall, isIconButton),
        ];
    if (isContextual) spacing = "px-2 py-1";
    const focusedRing = getFocusedRing(hasFocusedRing, isLink);
    const rounded = isLink ? "" : "rounded-full";
    const transition = isLink ? "transition-colors" : "transition-all";

    return (
      <button
        className={clsx([
          "group box-border flex flex-row items-center justify-center space-x-3 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50",
          spacing,
          transition,
          rounded,
          focusedRing,
          variantClassNames.button,
          className,
        ])}
        disabled={disabled}
        ref={ref}
        {...props}
      >
        {LeftIcon && (
          <LeftIcon
            size={isSmall ? "1rem" : "2em"}
            color="currentColor"
            className={variantClassNames.icon}
          />
        )}
        {children && (
          <Base2
            className={`${variantClassNames.text} ${
              isSmall && isIconButton ? "!ml-2" : ""
            }`}
          >
            {children}
          </Base2>
        )}
        {RightIcon && (
          <RightIcon
            size={isSmall ? "1rem" : "1.5em"}
            color="currentColor"
            className={variantClassNames.icon}
          />
        )}
      </button>
    );
  },
);

export default Button;
