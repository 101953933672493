import React, { useState } from "react";
import { usePopper } from "react-popper";

import { positions, TooltipComponent } from "./Tooltip.types";

const getArrowTransform = (placement: positions) => {
  switch (placement) {
    case "top":
      return "translate-y-3";
    case "bottom":
      return "-translate-y-3";
    case "left":
      return "translate-x-3";
    case "right":
      return "-translate-x-3";
  }
};

export const Tooltip: TooltipComponent = ({
  children,
  content,
  placement,
  offset,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const popperReferenceElement = React.useRef(null);
  const popperElement = React.useRef(null);
  const popperArrowElement = React.useRef(null);

  const {
    styles,
    attributes,
    update: popperUpdate,
  } = usePopper(popperReferenceElement.current, popperElement.current, {
    placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, offset],
        },
      },
      {
        name: "arrow",
        options: {
          element: popperArrowElement.current,
          padding: 8,
        },
      },
    ],
  });

  const handleHover = () => {
    setShowTooltip(true);
    popperUpdate && popperUpdate();
  };

  const handleBlur = () => {
    setShowTooltip(false);
  };

  const arrowTransform = getArrowTransform(
    (attributes.popper?.["data-popper-placement"] as unknown as positions) ||
      "right",
  );

  return (
    <>
      <div
        ref={popperReferenceElement}
        onFocus={handleHover}
        onBlur={handleBlur}
        onMouseEnter={handleHover}
        onMouseLeave={handleBlur}
      >
        {children}
      </div>

      <div
        ref={popperElement}
        className={`z-10 !mt-0 rounded-lg bg-neutral-900 p-2 transition-[opacity] ${
          showTooltip
            ? "pointer-events-auto opacity-100"
            : "pointer-events-none opacity-0"
        }`}
        style={styles.popper}
        {...attributes.popper}
      >
        <div ref={popperArrowElement} style={styles.arrow}>
          <div
            className={`h-3 w-3 rotate-45 select-none rounded-sm bg-neutral-900 ${arrowTransform}`}
          />
        </div>
        <div className="relative text-white">{content()}</div>
      </div>
    </>
  );
};
