import axios, { AxiosResponse } from "axios";
import { GraphQLError } from "graphql";
import { useEffect, useState } from "react";

export interface Metadata {
  title: string;
  description: string;
  images: string[];
  duration: number;
  domain: string;
  url: string;
}

export const fetchMetadata = async (url: string, apiKey: string) => {
  try {
    return await axios.get<AxiosResponse>(
      `https://jsonlink.io/api/extract?url=${url}&api_key=${apiKey}`,
    );
  } catch (error) {
    throw error;
  }
};

const useFetchMetadata = (url: string) => {
  const [data, setData] = useState<Metadata>({
    title: "",
    description: "",
    images: [],
    duration: 0,
    domain: "",
    url: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<GraphQLError>();
  const apiKey = process.env.JSON_LINK_API_KEY || "";

  const getMetadata = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://jsonlink.io/api/extract?url=${url}&api_key=${apiKey}`,
      );
      setData(response.data as Metadata);
      setIsLoading(false);
    } catch (error) {
      const err = error as GraphQLError;
      setError(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (url != "") {
      void getMetadata();
    }
  }, [url]);

  return { data, isLoading, error };
};

export default useFetchMetadata;
