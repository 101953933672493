import {
  MusicListsQuery,
  MusicListsQueryVariables,
  useMusicListsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query MusicLists {
    fetchMusic {
      id
      url
      status
      timeStamp
      title
      isActive
    }
  }
`;

export function useMusicLists(variables: MusicListsQueryVariables): {
  musicList: MusicListsQuery["fetchMusic"];
} & UseQueryResult<MusicListsQuery, unknown> {
  const response = useMusicListsQuery(variables, {
    suspense: true,
  });

  return {
    musicList: response.data?.fetchMusic || [],
    ...response,
  };
}
