import { useUpdateQuestionResponseMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation UpdateQuestionResponse($input: [UpdateQuestionResponseInput!]!) {
    updateQuestionResponse(input: $input) {
      id
      author {
        id
      }
      question {
        id
        title
      }
    }
  }
`;

function useUpdateQuestionResponse() {
  const queryClient = useQueryClient();
  return useUpdateQuestionResponseMutation({
    onSuccess: () => {
      void queryClient.refetchQueries(["questions"]);
    },
  });
}

export default useUpdateQuestionResponse;
