import { JSONContent } from "@tiptap/core";
import { RichTextEditor } from "components/base";
import { PreviewResource } from "components/partial";
import { Caption1, Overline } from "components/Typography";
import {
  Breakout,
  UpdateWorkshopActivityInput,
  WorkshopActivity,
} from "graphql/generated";
import { t } from "i18n-js";
import _ from "lodash";
import { useUpdateActivity } from "mutations/workshop";
import { useWorkshop } from "queries";
import React, { useCallback, useEffect } from "react";

import AddResource, { AddResourceInput } from "../AddResource";
import DashedBorder from "../dashedBorder";
import { linkValidator } from "../utils";
import BreakoutRoomSorter from "./BreakoutRoomSorter";

interface BreakoutViewProps {
  activity: WorkshopActivity;
  workshopId: string;
  onMutationStateChange: ({ isLoading }: { isLoading: boolean }) => void;
}

const BreakoutView = ({
  activity,
  workshopId,
  onMutationStateChange,
}: BreakoutViewProps) => {
  const { workshop } = useWorkshop({ id: workshopId });
  const { mutate: updateActivity, isLoading } = useUpdateActivity();

  const activityData = activity?.activity as Breakout;

  const debouncedUpdateActivity = useCallback(
    _.debounce(updateActivity, 2000),
    [],
  );

  useEffect(() => {
    onMutationStateChange({ isLoading });
  }, [isLoading, onMutationStateChange]);

  const handleUpdateActivity = (
    content: Omit<UpdateWorkshopActivityInput, "id">,
  ) => {
    const updateWorkshopActivity = {
      id: activity.id,
      ...content,
    } as UpdateWorkshopActivityInput;

    debouncedUpdateActivity({
      updateWorkshopActivity,
    });
  };

  const handleUpdateResource = (content: AddResourceInput) => {
    let updateWorkshopActivity = {
      id: activity.id,
    } as UpdateWorkshopActivityInput;

    if (content.file) {
      updateWorkshopActivity = {
        ...updateWorkshopActivity,
        ...content.file,
      } as UpdateWorkshopActivityInput;
    }

    if (content.link) {
      updateWorkshopActivity = {
        ...updateWorkshopActivity,
        link: content.link,
      };
    }

    debouncedUpdateActivity({
      updateWorkshopActivity,
    });
  };

  const handleUpdateFacilitatorNotes = (value: JSONContent) => {
    const updateWorkshopActivity = {
      id: activity.id,
      facilitatorNote: value,
    } as UpdateWorkshopActivityInput;

    debouncedUpdateActivity({ updateWorkshopActivity });
  };

  const participants = workshop?.workshopParticipants;

  const handleInstructionChange = (content: JSONContent) => {
    handleUpdateActivity({ instruction: content });
  };

  const handleDeleteResource = (id: string, type: "link" | "document") => {
    const updateWorkshopActivity = {
      id: activity.id,
    } as UpdateWorkshopActivityInput;

    if (type === "link") {
      updateWorkshopActivity.removeLinkId = id;
    } else if (type === "document") {
      updateWorkshopActivity.removeBreakoutDocumentsId = id;
    }
    debouncedUpdateActivity({
      updateWorkshopActivity,
    });
  };

  if (!activity) {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }

  return (
    <div className="relative flex flex-col gap-4">
      <DashedBorder className="flex px-6 py-7">
        <div className="flex-1">
          <RichTextEditor
            label="Instructions"
            showCharLimit={false}
            placeholder={t("workshop.descriptionPlaceholder")}
            minHeight="min-h-[150px]"
            maxHeight="max-h-72"
            showFocusedRing={false}
            maxCharCount={1000}
            onChange={handleInstructionChange}
            content={activityData.instruction as JSONContent}
            focusState={
              activity?.title !== t("workshopEditor.untitled") && "end"
            }
          />
          <Overline className="mb-2 mt-4 text-neutral-900">
            {t("breakoutActivityEditor.resource")}
          </Overline>
          <Caption1 className="mb-4 text-tint-dark-50">
            {t("breakoutActivityEditor.resourceDescription")}
          </Caption1>
          {activityData?.link ||
          (activityData?.documents && activityData?.documents.length > 0) ? (
            <PreviewResource
              activityData={activityData}
              onDeleteLink={handleDeleteResource}
            />
          ) : (
            <AddResource
              onAddResource={handleUpdateResource}
              isLoading={isLoading}
              linkValidator={linkValidator}
            />
          )}
        </div>
        <div className="mx-6 border-r border-neutral-100" />
        <div className="flex-1">
          {participants && (
            <BreakoutRoomSorter
              participants={participants}
              dailyRooms={activityData?.dailyRooms || []}
              activityId={activity.id}
            />
          )}
        </div>
      </DashedBorder>
      <RichTextEditor
        label={t("workshop.facilitationNotes")}
        showCharLimit={false}
        placeholder={t("workshop.notesPlaceholder")}
        minHeight="min-h-[75px]"
        maxHeight="max-h-40"
        showFocusedRing={false}
        maxCharCount={1000}
        onChange={handleUpdateFacilitatorNotes}
        content={activity?.facilitatorNote as JSONContent}
      />
    </div>
  );
};

export default BreakoutView;
