import { Popover } from "@headlessui/react";
import clsx from "clsx";
import { Button } from "components/base";
import { HStack } from "components/layout";
import { Base2, Caption2, Overline } from "components/Typography";
import {
  Emojis,
  Maybe,
  Workshop,
  WorkshopParticipantRole,
} from "graphql/generated";
import { t } from "i18n-js";
import { ChevronDownIcon } from "icons";
import { HandPalm } from "phosphor-react";
import { useWorkshop } from "queries";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useEmotionalSignalsSubscription,
  useRaisedHandSubscription,
} from "subscriptions";
import { getCurrentWorkshopActivity, Z_INDEX_LEVELS } from "utils";

import { EmotionalSignal, RaisedHandUser, SignalBadge } from "./components";
import { emotionalBadgeType } from "./SignalDropdown.types";
import { getRaisedHands, mapEmotionalSignals } from "./utils";

export default function SignalDropdown({
  role,
  isDisabled = false,
}: {
  role: WorkshopParticipantRole;
  isDisabled?: boolean;
}) {
  const { id: workshopId = "" } = useParams();
  const { workshop } = useWorkshop({
    id: workshopId,
  });

  const { breakoutRoomIdMapping, breakoutRoomIsActive } = useMemo(() => {
    const currentActivity = getCurrentWorkshopActivity(workshop?.agenda);

    if (currentActivity?.activity.__typename === "Breakout") {
      const breakoutRoomIdMapping = {} as Record<number, number>;

      for (const [
        index,
        room,
      ] of currentActivity.activity.dailyRooms.entries()) {
        breakoutRoomIdMapping[Number(room.id)] = index + 1;
      }

      return {
        breakoutRoomIdMapping,
        breakoutRoomIsActive: currentActivity.activity.breakoutRoomIsActive,
      };
    }

    return {
      breakoutRoomIdMapping: [],
      breakoutRoomIsActive: false,
    };
  }, [workshop]);

  const { emotionalReaction: workshopEmotionalSignals } =
    useEmotionalSignalsSubscription({
      workshopId: workshopId,
    });

  useRaisedHandSubscription({
    workshopId: workshopId,
  });

  const [raisedHands, setRaisedHands] = useState<
    { name: string; breakoutRoom: Maybe<number> | undefined }[]
  >(
    workshop &&
      getRaisedHands(
        workshop?.workshopParticipants as Workshop["workshopParticipants"],
        workshop as Workshop,
      ),
  );

  const [emotionalSignals, setEmotionalSignals] = useState<
    emotionalBadgeType[]
  >(mapEmotionalSignals((workshop?.emotionalSignals as Emojis[]) || []));
  const [newEmotionalSignalQueue, setNewEmotionalSignalQueue] = useState<
    emotionalBadgeType[]
  >([]);

  useEffect(() => {
    if (workshop) {
      const { workshopParticipants } = workshop;

      if (
        workshopEmotionalSignals &&
        workshopEmotionalSignals?.length > emotionalSignals?.length
      ) {
        const newEmotionalSignals = mapEmotionalSignals(
          workshopEmotionalSignals,
        );

        const numberOfNewSignals =
          newEmotionalSignals?.length - emotionalSignals?.length;

        setEmotionalSignals(newEmotionalSignals);

        const newlyAddedSignals = newEmotionalSignals.slice(
          0,
          numberOfNewSignals,
        );

        setNewEmotionalSignalQueue([
          ...newEmotionalSignalQueue,
          ...newlyAddedSignals,
        ]);
      }

      const newRaisedHands = getRaisedHands(
        workshopParticipants as Workshop["workshopParticipants"],
        workshop as Workshop,
      );

      if (newRaisedHands?.length !== raisedHands?.length) {
        setRaisedHands(newRaisedHands);
      }
    }
  }, [emotionalSignals?.length, raisedHands?.length, workshop]);

  const hasRaisedHands = raisedHands?.length > 0;

  const isParticipant = role === WorkshopParticipantRole.Participant;

  const handleAnimationEnd = () => {
    const newQueue = [...newEmotionalSignalQueue].slice(1);

    setNewEmotionalSignalQueue(newQueue);
  };

  return (
    <>
      <Popover className={clsx("relative", Z_INDEX_LEVELS.MODAL)}>
        {({ open }) => {
          const Chevron = () => (
            <ChevronDownIcon
              className={`flex-shrink-0 ${open ? "rotate-180" : ""}`}
              fill={hasRaisedHands ? "white" : "#292D30"}
            />
          );

          return (
            <>
              <Popover.Button as={Fragment}>
                <div className="relative outline-none">
                  {newEmotionalSignalQueue.map(
                    (signal, index) =>
                      index === 0 &&
                      signal.signal &&
                      signal.time && (
                        <SignalBadge
                          key={`${signal.signal}_${signal.time}`}
                          variant={signal.signal}
                          className={`absolute left-1 top-0 bottom-0 mt-auto mb-auto animate-emotional-signal`}
                          onAnimationEnd={handleAnimationEnd}
                        />
                      ),
                  )}

                  <Button
                    variant="outline"
                    RightIcon={Chevron}
                    disabled={isDisabled}
                    className={`relative z-10 ${
                      hasRaisedHands
                        ? "!bg-neutral-900 !pl-5 hover:!bg-neutral-800"
                        : "bg-[#F2F3F4] hover:bg-[#dedfe0]"
                    }`}
                  >
                    <HStack align="center">
                      <div
                        className={clsx("h-4 w-0 opacity-0 transition-all", {
                          "w-7 opacity-100": hasRaisedHands,
                        })}
                      >
                        <HandPalm
                          className="text-white"
                          weight="duotone"
                          fill="white"
                        />
                      </div>
                      <Base2
                        className={`transition-color ${
                          hasRaisedHands ? "text-white" : "text-neutral-900"
                        }`}
                      >
                        {t("signaling.signals")}
                      </Base2>
                    </HStack>
                  </Button>
                </div>
              </Popover.Button>
              <Popover.Panel className="absolute right-0 top-full z-10 mt-2 min-w-[288px] rounded-lg bg-white py-4 shadow-signals">
                {!isParticipant &&
                  (raisedHands?.length > 0 || emotionalSignals?.length > 0) && (
                    <div className="mx-4">
                      {raisedHands?.length > 0 ? (
                        <div>
                          <Overline>{t("signaling.hands")}</Overline>
                          <div className="relative">
                            <div className="absolute top-0 h-4 w-full bg-gradient-to-b from-white to-transparent" />
                            <div className="no-scrollbar mt-2 max-h-48 overflow-auto">
                              {raisedHands.map((user, index) => (
                                <RaisedHandUser
                                  user={user.name}
                                  index={index}
                                  key={`${user.name}_${index}`}
                                  room={
                                    breakoutRoomIsActive && user.breakoutRoom
                                      ? breakoutRoomIdMapping[user.breakoutRoom]
                                      : undefined
                                  }
                                />
                              ))}
                            </div>

                            <div className="absolute bottom-0 h-6 w-full bg-gradient-to-t from-white to-transparent" />
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center">
                          <SignalBadge variant="hand" />
                          <Caption2 className="mt-2 text-neutral-600">
                            {t("signaling.handsEmpty")}
                          </Caption2>
                        </div>
                      )}
                      <div className="mb-4 mt-2 h-px w-full bg-neutral-50 bg-opacity-20" />
                    </div>
                  )}
                {emotionalSignals?.length > 0 ? (
                  <div>
                    <Overline className="ml-4">
                      {t("signaling.emotional")}
                    </Overline>
                    <div className="no-scrollbar mt-4 flex max-w-xs overflow-auto">
                      {emotionalSignals?.map(({ signal, time }, index) => (
                        <EmotionalSignal
                          signal={signal}
                          time={time}
                          key={`${signal || "break"}_${index}`}
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <SignalBadge variant="lightbulb_secondary" />
                    <Caption2 className="mt-2 text-neutral-600">
                      {t("signaling.emotionalEmpty")}
                    </Caption2>
                  </div>
                )}
              </Popover.Panel>
            </>
          );
        }}
      </Popover>
    </>
  );
}
