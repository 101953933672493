import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Button, Logo } from "components/base";
import { Phase } from "graphql/generated";
import { t } from "i18n-js";
import { X } from "phosphor-react";
import React, { Fragment } from "react";
import { DeepPartial } from "react-hook-form";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import AddPhaseSummaryForm from "./AddPhaseSummaryForm";
import { HStack } from "./layout/Stack";
import { Base2, Display3, Paragraph2 } from "./Typography";

interface AddPhraseSummaryModal {
  isOpen: boolean;
  closeModal: () => void;
  phase?: DeepPartial<Phase>;
  toggleAddModal: () => void;
  fromEdit: boolean;
  toggleEditPhaseSummaryModal?: () => void;
}

export default function AddPhaseSummaryModal({
  isOpen,
  closeModal,
  phase,
  fromEdit,
  toggleEditPhaseSummaryModal,
}: AddPhraseSummaryModal): JSX.Element {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={clsx("fixed inset-0 overflow-y-auto", Z_INDEX_LEVELS.MODAL)}
        onClose={closeModal}
      >
        <div className="flex min-h-screen items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative flex min-h-screen w-full bg-white">
              <Button
                className="absolute top-4 right-4"
                variant="tertiary"
                RightIcon={X}
                onClick={() => closeModal()}
              ></Button>
              <Logo
                size="medium"
                className="absolute top-8 left-10 text-white"
              />
              <div className="flex w-1/2 flex-col justify-center bg-tertiary-casal-50 px-14">
                <div>
                  <HStack align="center" className="gap-4">
                    {fromEdit && (
                      <Base2 className="text-white">{`${t("shared.phase")} ${
                        phase?.position || ""
                      }: ${phase?.name || ""}`}</Base2>
                    )}
                  </HStack>

                  <Display3 className="my-4 max-w-[500px] text-white">
                    {fromEdit
                      ? t("pages.editPhaseSummary.title")
                      : t("pages.addPhaseSummary.title")}
                  </Display3>

                  <Paragraph2 className="max-w-[500px] text-gray-200">
                    {t("pages.addPhaseSummary.subtitle")}
                  </Paragraph2>
                  <img
                    src={"/supporting-graphic.png"}
                    className="m-auto mt-18"
                  />
                </div>
              </div>
              <div className="w-1/2">
                <AddPhaseSummaryForm
                  onSubmitSuccess={closeModal}
                  onFormCancel={closeModal}
                  phase={phase}
                  toggleEditPhaseSummaryModal={toggleEditPhaseSummaryModal}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
