import {
  SendWorkshopNotificationQuery,
  SendWorkshopNotificationQueryVariables,
  useSendWorkshopNotificationQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query sendWorkshopNotification($id: ID!, $mailType: MailTypeEnum!) {
    sendWorkshopNotification(id: $id, mailType: $mailType)
  }
`;

function SendWorkshopNotification(
  variables: SendWorkshopNotificationQueryVariables,
): {
  sendWorkshopNotification: SendWorkshopNotificationQuery["sendWorkshopNotification"];
} & UseQueryResult<SendWorkshopNotificationQuery, unknown> {
  const response = useSendWorkshopNotificationQuery(variables, {
    enabled: false,
  });

  return { sendWorkshopNotification: response.data, ...response };
}

export { SendWorkshopNotification };
