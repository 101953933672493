import { useStopForceCloudRecordingMutation } from "graphql/generated";
import { gql } from "graphql-request";

gql`
  mutation StopForceCloudRecording($input: StartForceCloudRecordingInput!) {
    stopForceCloudRecording(input: $input) {
      recordingId
      status
    }
  }
`;

export function useStopForceCloudRecording() {
  return useStopForceCloudRecordingMutation();
}
