import {
  UserEmailQuery,
  UserEmailQueryVariables,
  useUserEmailQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query UserEmail($id: ID!) {
    userById(id: $id) {
      email
    }
  }
`;

function useUserEmail(
  variables: UserEmailQueryVariables,
): { email: UserEmailQuery["userById"]["email"] } & UseQueryResult<
  UserEmailQuery,
  unknown
> {
  const response = useUserEmailQuery(variables, { suspense: true });

  return { email: response.data?.userById.email || "", ...response };
}

export { useUserEmail };
