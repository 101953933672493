import { Editor } from "@tiptap/core";
import clsx from "clsx";
import { Modal } from "components/partial";
import { Base2, Heading3 } from "components/Typography";
import { t } from "i18n-js";
import React, { useEffect, useState } from "react";
import { validateUrlWithProtocol } from "utils";

import Button from "../Button";

interface LinkModalProps {
  isOpen: boolean;
  onClose: () => void;
  editor: Editor;
}

const LinkModal = ({ isOpen, onClose, editor }: LinkModalProps) => {
  const [url, setUrl] = useState("");
  const [linkedText, setLinkedText] = useState("");

  // Get linked text b/w the indices of from and to from the editor attributes.
  const selectedText = editor.state.doc.cut(
    editor.state.selection.from,
    editor.state.selection.to,
  ).textContent;

  // Sets initial link text value to selected text between
  useEffect(() => setLinkedText(selectedText), [selectedText]);

  /**
   * Retrieve the schema mark for links defined in the editor.
   * Retrieve the marks at the beginning of the current selection in the editor.
   * Searche for a mark with the name "link" in the selected marks.
   * If "link" mark is found, retrieve the value of its href attribute
   * and set it to initialUrl. Otherwise, initialUrl is set to an empty string.
   */
  const mark = editor.view.state.schema.marks.link;
  const selectedMarks = editor.state.selection.$to.marks();
  const linkMark = selectedMarks.find((mark) => mark.type.name === "link");
  const initialUrl = linkMark ? (linkMark.attrs.href as string) : "";

  // Sets current url when modal opens
  useEffect(() => setUrl(initialUrl), [initialUrl]);

  const addLink = () => {
    const isValidUrl = validateUrlWithProtocol(url);
    if (url === "") {
      return;
    }

    // Update the selected text to link to url in editor
    if (isValidUrl) {
      editor.chain().focus().extendMarkRange(mark).setLink({ href: url }).run();
      onClose();
    } else {
      editor
        .chain()
        .focus()
        .extendMarkRange(mark)
        .setLink({ href: `http://` + url })
        .run();
      onClose();
    }
    // Update the linked text in the editor
    editor
      .chain()
      .focus()
      .extendMarkRange(mark)
      .command(({ tr }) => {
        tr.insertText(linkedText);
        return true;
      })
      .run();
  };

  const removeLink = () => {
    editor.commands.unsetLink();
    onClose();
  };

  return (
    <Modal
      isFullScreen
      open={isOpen}
      onClose={onClose}
      className="flex w-[560px] flex-col rounded-lg bg-white px-6 pt-6 shadow-lg"
    >
      <div className="mb-6">
        <Heading3 className="mb-6">
          {t("component.richTextEditor.addLink")}
        </Heading3>
        <Base2 className="mb-2 ">{t("component.richTextEditor.text")}</Base2>
        <input
          className={clsx(
            ` h-12 w-full rounded-md bg-tint-dark-200 py-3 pl-[18px] focus:caret-primary-turquoise-10 focus:outline-none `,
            {
              "outline outline-offset-2 outline-red-500": false,
              "focus:outline-primary-turquoise-10": true,
            },
          )}
          value={linkedText}
          onChange={(e) => setLinkedText(e.target.value)}
        />
      </div>
      <div className="mb-6">
        <Base2 htmlFor="url" className="mb-2 ">
          {t("component.richTextEditor.link")}
        </Base2>
        <input
          className={clsx(
            ` h-12 w-full rounded-md bg-tint-dark-200 py-3 pl-[18px] focus:caret-primary-turquoise-10 focus:outline-none `,
            {
              "outline outline-offset-2 outline-red-500": false,
              "focus:outline-primary-turquoise-10": true,
            },
          )}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </div>
      <div className="mb-6 flex justify-end space-x-2">
        {linkMark && (
          <Button variant="tertiary" onClick={removeLink}>
            {t("component.richTextEditor.removeLink")}
          </Button>
        )}
        {!linkMark && (
          <Button variant="tertiary" onClick={onClose}>
            {t("component.richTextEditor.cancel")}
          </Button>
        )}
        <Button variant="secondary" onClick={addLink} disabled={url === ""}>
          {linkMark ? "Update Link" : "Done"}
        </Button>
      </div>
    </Modal>
  );
};

export default LinkModal;
