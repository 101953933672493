import { Listbox } from "@headlessui/react";
import { JSONContent } from "@tiptap/core";
import clsx from "clsx";
import { Input, RichTextEditor, ToggleSwitch } from "components/base";
import { VStack } from "components/layout";
import { DropdownTrigger, PollList } from "components/partial";
import { Item } from "components/partial/Polls/PollList.types";
import { Base2, Display5, Paragraph3 } from "components/Typography";
import {
  Poll,
  PollInput,
  PollOption,
  PollPollType,
  UpdateWorkshopActivityInput,
  WorkshopActivity,
} from "graphql/generated";
import { t } from "i18n-js";
import _ from "lodash";
import { useDeletePollOption, useUpdateActivity } from "mutations/workshop";
import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";

import DashedBorder from "../dashedBorder";

type HandleUpdateActivityProps = {
  removeOptionsIds?: Pick<UpdateWorkshopActivityInput, "removeOptionsIds">;
  poll: Partial<PollInput>;
};

interface PollViewProps {
  activity: WorkshopActivity;
  onMutationStateChange: ({ isLoading }: { isLoading: boolean }) => void;
}

export const PollView = ({
  activity,
  onMutationStateChange,
}: PollViewProps) => {
  const { mutate: updateActivity, isLoading } = useUpdateActivity();
  const { mutate: deletePollOption } = useDeletePollOption();
  const { pollType, question, anonymous, allowSkip, multipleAnswers, options } =
    activity.activity as Poll;

  const { register, reset, setFocus } = useForm({
    defaultValues: { pollQuestion: "" },
  });

  const debouncedUpdateActivity = useCallback(
    _.debounce(updateActivity, 2000),
    [],
  );

  useEffect(() => {
    onMutationStateChange({ isLoading });
  }, [isLoading, onMutationStateChange]);

  const handleUpdateActivity = ({
    poll,
    removeOptionsIds,
  }: HandleUpdateActivityProps) => {
    const updateWorkshopActivity = {
      id: activity.id,
      removeOptionsIds: removeOptionsIds,
      poll: {
        pollType: pollType,
        ...poll,
      },
    } as UpdateWorkshopActivityInput;

    updateActivity({ updateWorkshopActivity });
  };

  const handleUpdateFacilitatorNotes = (value: JSONContent) => {
    const updateWorkshopActivity = {
      id: activity.id,
      facilitatorNote: value,
    } as UpdateWorkshopActivityInput;

    debouncedUpdateActivity({ updateWorkshopActivity });
  };

  /**
   * updatePollListOrder grabs new order of poll items, stores them in updatedItemsId by id.
   * Sort options to match the order of the updatedItemsId order and store it in sortedUpdatedItems.
   * Store options but their id.
   * Trigger mutation to remove current options and update the DB w/ sortedUpdatedItems.
   */
  const updatePollListOrder = (newItems: Item[]) => {
    const updatedItemsId = newItems.map((item) => item.id);
    const sortedUpdatedItems = options
      ?.sort(
        (a, b) => updatedItemsId.indexOf(a.id) - updatedItemsId.indexOf(b.id),
      )
      .map((item) => item.title);

    const currOptionsId = options?.map((option) => option.id);

    handleUpdateActivity({
      removeOptionsIds: currOptionsId as Pick<
        UpdateWorkshopActivityInput,
        "removeOptionsIds"
      >,
      poll: { options: sortedUpdatedItems as string[] },
    });
  };

  const handlePollQuestionChange = (value: string) => {
    handleUpdateActivity({
      poll: { question: value },
    });
  };
  useEffect(() => {
    reset({ pollQuestion: question ?? "" });

    return () => {
      if (activity.title !== t("workshopEditor.untitled")) {
        setFocus("pollQuestion");
      }
    };
  }, [activity.id]);

  return (
    <div className="flex flex-col gap-4">
      <DashedBorder className="flex gap-6 !p-6">
        <div className="flex w-full justify-between gap-6">
          <div className="flex w-1/2 flex-col gap-6">
            {/* Left Pane */}
            <div className="relative">
              <Base2 className="mb-2 text-neutral-900">
                {t("pollActivityEditor.typeSelectorLabel")}
              </Base2>
              <Listbox
                onChange={(value: PollPollType) => {
                  handleUpdateActivity({ poll: { pollType: value } });
                }}
                value={pollType}
              >
                {({ open }) => (
                  <>
                    <DropdownTrigger
                      secondaryText={t(
                        pollType
                          ? `pollActivityEditor.types.${
                              pollType as PollPollType
                            }`
                          : "pollActivityEditor.types.no_type_fallback",
                      )}
                      open={open}
                      as={Listbox.Button}
                    />
                    <Listbox.Options className="absolute z-10 flex w-full flex-col rounded-b-lg border-2 border-t-0 border-tint-dark-10 bg-white py-2 shadow-projectPhaseCard outline-none">
                      {Object.values(PollPollType).map((type) => (
                        <Listbox.Option value={type} key={type}>
                          {({ active }) => (
                            <div
                              className={clsx(
                                "px-4 py-2 text-left hover:bg-tint-dark-10",
                                active ? "bg-tint-dark-10" : "",
                              )}
                            >
                              <Paragraph3>
                                {t(`pollActivityEditor.types.${type}`)}
                              </Paragraph3>
                            </div>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </>
                )}
              </Listbox>
            </div>
            <div>
              <Input
                maxLength={250}
                longCountDescription
                multiline
                showCounter
                label={t("pollActivityEditor.questionLabel")}
                placeholder={t("pollActivityEditor.questionPlaceholder")}
                {...register("pollQuestion", {
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                    handlePollQuestionChange(event.target.value),
                })}
              />
            </div>
            <div className="flex flex-col gap-4">
              {pollType !== PollPollType.WordCloud && (
                <div className="flex items-center justify-between">
                  <Base2 className="text-neutral-900">
                    {t("pollActivityEditor.toggleAnonymous")}
                  </Base2>
                  <ToggleSwitch
                    enabled={anonymous as boolean}
                    onToggle={(event) =>
                      handleUpdateActivity({ poll: { anonymous: event } })
                    }
                  />
                </div>
              )}
              <div className="flex items-center justify-between">
                <Base2 className="text-neutral-900">
                  {t("pollActivityEditor.toggleSkip")}
                </Base2>
                <ToggleSwitch
                  enabled={allowSkip as boolean}
                  onToggle={(event) =>
                    handleUpdateActivity({ poll: { allowSkip: event } })
                  }
                />
              </div>
              {(pollType === PollPollType.WordCloud ||
                pollType === PollPollType.Paragraph) && (
                <div className="flex items-center justify-between">
                  <Base2>
                    {pollType === PollPollType.WordCloud &&
                      t("pollActivityEditor.toggleUnlimitedAnswers")}
                    {pollType === PollPollType.Paragraph &&
                      t("pollActivityEditor.toggleMultipleAnswers")}
                  </Base2>
                  <ToggleSwitch
                    enabled={multipleAnswers as boolean}
                    onToggle={(event) =>
                      handleUpdateActivity({ poll: { multipleAnswers: event } })
                    }
                  />
                </div>
              )}
            </div>
          </div>
          {/* Right Pane */}
          {(pollType === PollPollType.SingleChoice ||
            pollType === PollPollType.MultipleChoice) && (
            <div className="w-1/2 rounded-lg border border-neutral-5 p-6">
              <PollList
                title={question || t("workshopEditor.addOption")}
                items={options as PollOption[]}
                onAddItem={(title) =>
                  handleUpdateActivity({ poll: { options: [title] } })
                }
                onRemoveItem={(id) => deletePollOption({ id: id })}
                onMoveItem={(newItems) => updatePollListOrder(newItems)}
              />
            </div>
          )}
          {pollType === PollPollType.Paragraph && (
            <div className="w-1/2 rounded-lg border-2 border-neutral-5 p-6">
              <Display5 className="mb-4">
                {question || t("pollActivityEditor.answerLabel")}
              </Display5>
              <Input
                label=""
                placeholder={t("pollActivityEditor.answerPlaceholder")}
                multiline
                disabled
                className="h-[260px]"
              />
            </div>
          )}
          {pollType === PollPollType.WordCloud && (
            <div className="w-1/2 rounded-lg border-2 border-neutral-5 p-6">
              <Display5 className="mb-4">
                {question || t("pollActivityEditor.answerLabel")}
              </Display5>
              <VStack space={3}>
                <Input
                  label=""
                  placeholder={t("pollActivityEditor.answerPlaceholder")}
                  disabled
                  className="h-[60px]"
                />
                <Input
                  label=""
                  placeholder={t(
                    "pollActivityEditor.answerOptionalPlaceholder",
                  )}
                  disabled
                  className="h-[60px]"
                />
                <Input
                  label=""
                  placeholder={t(
                    "pollActivityEditor.answerOptionalPlaceholder",
                  )}
                  disabled
                  className="h-[60px]"
                />
              </VStack>
            </div>
          )}
        </div>
      </DashedBorder>
      {/* Footer */}
      <RichTextEditor
        label={t("workshop.facilitationNotes")}
        showCharLimit={false}
        placeholder={t("workshop.notesPlaceholder")}
        minHeight="min-h-[75px]"
        maxHeight="max-h-40"
        showFocusedRing={false}
        maxCharCount={1000}
        onChange={(value) => handleUpdateFacilitatorNotes(value)}
        content={activity?.facilitatorNote as JSONContent}
      />
    </div>
  );
};

export default PollView;
