import clsx from "clsx";
import { HStack } from "components/layout";
import { ArrowUpRight, Check, X } from "phosphor-react";
import React from "react";

export type AttendanceCategories =
  | "projectMember"
  | "sharedWith"
  | "didAttend"
  | "didNotAttend";

const AttendanceBadge = ({ type }: { type: AttendanceCategories }) => {
  const getClassName = () => {
    switch (type) {
      case "didAttend":
        return "bg-secondary-green-700";
      case "didNotAttend":
        return "bg-secondary-red-700";
      case "sharedWith":
        return "bg-tertiary-blue-700";
      default:
        return "";
    }
  };

  const getIcon = () => {
    switch (type) {
      case "didAttend":
        return <Check size={12} color="#FFFFFF" />;
      case "didNotAttend":
        return <X size={12} color="#FFFFFF" />;
      case "sharedWith":
        return <ArrowUpRight size={12} color="#FFFFFF" />;
      default:
        return;
    }
  };

  return (
    <HStack
      justify="center"
      align="center"
      className={clsx(
        "absolute right-0 bottom-0 h-[16px] w-[16px] rounded-full",
        getClassName(),
      )}
    >
      {getIcon()}
    </HStack>
  );
};

export default AttendanceBadge;
