import { Agenda, WorkshopActivity } from "graphql/generated";
import { t } from "i18n-js";
import {
  GOOGLE_DOCS_MIRO_REGEX,
  UNPUBLISHED_GOOGLE_DOCS_REGEX,
} from "utils/helpers/validations";

export const findActivity = (agenda: Array<Agenda>, activityId: string) => {
  const allActivities = agenda.flatMap((a) =>
    a.__typename === "Section" ? a.workshopActivities : a,
  );

  const activity = allActivities.find((a) => a?.id === activityId);

  return activity as WorkshopActivity;
};

export const linkValidator = (value: string) => {
  if (GOOGLE_DOCS_MIRO_REGEX.test(value)) {
    return true;
  } else if (UNPUBLISHED_GOOGLE_DOCS_REGEX.test(value)) {
    return t("resourceActivityEditor.unpublishedLinkError");
  } else {
    return t("resourceActivityEditor.linkError");
  }
};
