import React from "react";

function IChartIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3898_100914)">
        <path
          d="M28 1C28 0.447715 28.4481 -0.00186258 29 0.0178435C36.0629 0.270016 42.7841 3.18616 47.799 8.20101C52.8138 13.2159 55.73 19.9371 55.9822 27C56.0019 27.5519 55.5523 28 55 28L29 28C28.4477 28 28 27.5523 28 27V1Z"
          fill="#E34B4B"
        />
        <path
          d="M-0.5 5C-0.5 1.96243 1.96243 -0.5 5 -0.5H28H51C54.0376 -0.5 56.5 1.96243 56.5 5V28V51C56.5 54.0376 54.0376 56.5 51 56.5H28H5C1.96243 56.5 -0.5 54.0376 -0.5 51V28V5Z"
          stroke="#5F6368"
          stroke-opacity="0.1"
        />
        <path
          d="M40.29 13.71C39.89 13.71 39.64 14 39.64 14.34C39.64 14.67 39.89 14.97 40.29 14.97C40.69 14.97 40.94 14.67 40.94 14.34C40.94 14 40.69 13.71 40.29 13.71ZM39.83 21H40.75V15.95H39.83V21Z"
          fill="white"
        />
      </g>
      <rect
        x="0.5"
        y="0.5"
        width="55"
        height="55"
        rx="27.5"
        stroke="#5F6368"
        stroke-opacity="0.1"
      />
      <defs>
        <clipPath id="clip0_3898_100914">
          <rect width="56" height="56" rx="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IChartIcon;
