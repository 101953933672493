import { useCreateProfileAnswerMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation createProfileAnswer($answer: String!, $profile_question_id: ID!) {
    createProfileAnswer(
      answer: $answer
      profileQuestionId: $profile_question_id
    ) {
      id
      answer
    }
  }
`;

function useCreateProfileAnswer() {
  const queryClient = useQueryClient();
  return useCreateProfileAnswerMutation({
    onSuccess: () => {
      void queryClient.refetchQueries(["getAnswersForUser"]);
      void queryClient.refetchQueries(["getUserPersonalityProfile"]);
      void queryClient.refetchQueries(["getProfileAnswerOfUser"]);
      void queryClient.refetchQueries(["currentUser"]);
    },
  });
}

export default useCreateProfileAnswer;
