import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Center } from "components";
import { Overlay } from "components/partial";
import React, { Fragment } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import { ModalProps } from "./Modal.types";

function Modal({
  open,
  onClose,
  children,
  isFullScreen = false,
  className = "",
  initialFocus,
  zIndex = "",
}: ModalProps) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className={clsx(
          "fixed inset-0 overflow-y-auto",
          Z_INDEX_LEVELS.MODAL,
          zIndex,
        )}
        onClose={onClose}
        initialFocus={initialFocus}
      >
        <Center
          className={clsx("h-full min-h-screen", !isFullScreen && "p-16")}
        >
          <Overlay />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={clsx(
                "transform overflow-hidden bg-white shadow-xl",
                !isFullScreen && "rounded-2xl",
                className,
              )}
            >
              {children}
            </div>
          </Transition.Child>
        </Center>
      </Dialog>
    </Transition>
  );
}

export default Modal;
