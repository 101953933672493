import { useUpdateWorkshopParticipantMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation UpdateWorkshopParticipant(
    $updateWorkshopParticipantInput: UpdateWorkshopParticipantInput!
  ) {
    updateWorkshopParticipant(updateInput: $updateWorkshopParticipantInput) {
      id
      didAttend
      role
    }
  }
`;

export function useUpdateWorkshopParticipant() {
  const queryClient = useQueryClient();
  return useUpdateWorkshopParticipantMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["WorkshopById"]);
    },
  });
}
