import { Tooltip } from "components/base";
import { Caption2 } from "components/Typography";
import { t } from "i18n-js";
import RecoderIcon from "icons/RecoderLogo";
import React from "react";

const Icon = ({ fill }: { fill?: string }) => (
  <RecoderIcon size={30} fill={fill} />
);
const BackToRecoderTooltipContent = () => (
  <Caption2>{t("workshop.header.backToRecoder")}</Caption2>
);

export default function BackToRecoder({
  onClick,
  className = "",
  fill,
}: {
  onClick: () => void;
  className?: string;
  fill?: string;
}) {
  return (
    <Tooltip content={BackToRecoderTooltipContent} placement="top" offset={6}>
      <div
        className={`flex h-12 w-12 cursor-pointer items-center justify-center rounded-full border border-tint-dark-30 hover:bg-tint-dark-10 ${className}`}
        onClick={onClick}
        data-testid="backToRecoder"
      >
        <Icon fill={fill} />
      </div>
    </Tooltip>
  );
}
