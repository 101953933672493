import { useDeleteWorkshopParticipantMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation DeleteWorkshopParticipant($id: ID!) {
    deleteWorkshopParticipant(id: $id) {
      id
    }
  }
`;

function useDeleteWorkshopParticipant() {
  const queryClient = useQueryClient();
  return useDeleteWorkshopParticipantMutation({
    onSuccess: () => queryClient.invalidateQueries(["WorkshopById"]),
  });
}

export default useDeleteWorkshopParticipant;
