import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { HStack, VStack } from "components";
import { Logo, OrganizationAvatar } from "components/base";
import { Base1Strong, Base2 } from "components/Typography";
import { updateDocumentTitle } from "components/utils/updateDocumentTitle/updateDocumentTitle";
import { Scalars } from "graphql/generated";
import { t } from "i18n-js";
import {
  CaretCircleDown,
  Check,
  FolderNotch,
  GearSix,
  IconProps,
  Users,
} from "phosphor-react";
import { useOrganizationById } from "queries/use-organization";
import React, { Fragment, useEffect, useState } from "react";

import WorkspaceMembersContent from "./WorkspaceMembersContent";
import WorkspaceProjectsContent from "./WorkspaceProjectsContent";
import WorkspaceSettingsContent from "./WorkspaceSettingsContent";

enum SettingsMenuTabs {
  members = "members",
  projects = "projects",
  settings = "settings",
}

type SettingsTabItemProps = {
  tabId: SettingsMenuTabs;
  title: string;
  isCurrent?: boolean;
  active?: boolean;
  Icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  onClick: () => void;
};

type SettingsMenuProps = {
  organizations: {
    name: string;
    id: Scalars["ID"];
    organizationImageUrl: string;
  }[];
  initialOrganizationId: Scalars["ID"];
  onClose: () => void;
  selectedWorkspaceProject?: Scalars["ID"];
};

const SETTINGS_TAB_ICONS = {
  [SettingsMenuTabs.members]: Users,
  [SettingsMenuTabs.projects]: FolderNotch,
  [SettingsMenuTabs.settings]: GearSix,
};

function SettingsTabItem({
  tabId,
  title,
  isCurrent,
  Icon,
  ...props
}: SettingsTabItemProps) {
  return (
    <button
      className={clsx(
        "flex flex-row items-center p-5 pl-14 text-neutral-90 hover:bg-neutral-50",
        { "bg-neutral-10": isCurrent },
      )}
      data-testid={`settings-${tabId}-tab`}
      {...props}
    >
      <Icon size={24} color="currentColor" className="mr-4" />
      <Base2>{title}</Base2>
    </button>
  );
}

function SettingsMenuContent({
  tab,
  organizationId,
  onClose,
  selectedWorkspaceProject,
}: {
  tab: SettingsMenuTabs;
  organizationId: Scalars["ID"];
  onClose: () => void;
  selectedWorkspaceProject?: Scalars["ID"];
}) {
  switch (tab) {
    case SettingsMenuTabs.members:
      return (
        <WorkspaceMembersContent
          organizationId={organizationId}
          onClose={onClose}
        />
      );
    case SettingsMenuTabs.projects:
      return (
        <WorkspaceProjectsContent
          organizationId={organizationId}
          onClose={onClose}
          projectId={selectedWorkspaceProject}
        />
      );
    case SettingsMenuTabs.settings:
      return (
        <WorkspaceSettingsContent
          organizationId={organizationId}
          onClose={onClose}
        />
      );
    default:
      return <></>;
  }
}

function OrganizationSelect({
  organizations,
  currentOrganizationId,
  setCurrentOrganizationId,
}: {
  organizations: SettingsMenuProps["organizations"];
  currentOrganizationId: Scalars["ID"];
  setCurrentOrganizationId: (value: Scalars["ID"]) => void;
}) {
  const { organizationById: currentOrganization } = useOrganizationById({
    id: currentOrganizationId,
  });
  return (
    <Listbox value={currentOrganizationId} onChange={setCurrentOrganizationId}>
      <div className="relative">
        <Listbox.Button className="relative ml-14 flex flex-row items-center py-2 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
          <OrganizationAvatar
            organizationName={currentOrganization?.name}
            className="mr-3"
            organizationImageUrl={currentOrganization?.organizationImageUrl}
          />
          <VStack className="group" align="start">
            <Base2 className="text-left">
              <Base1Strong
                data-testId="organisation-name"
                className="mr-4 block truncate text-neutral-90"
              >
                {currentOrganization?.name.length > 28
                  ? currentOrganization?.name.slice(0, 25) + "..."
                  : currentOrganization?.name}
              </Base1Strong>

              <span
                className={
                  currentOrganization?.name.length < 28
                    ? "hidden"
                    : "absolute top-[2px] hidden w-fit -translate-y-full rounded-lg bg-gray-700 px-2 py-1 text-center text-sm text-white after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700 after:content-[''] group-hover:flex"
                }
              >
                {currentOrganization?.name}
              </span>
            </Base2>
          </VStack>
          <span className="pointer-events-none flex items-center">
            <CaretCircleDown size={24} className="text-neutral-60" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {organizations?.map((organization) => (
              <Listbox.Option
                key={organization?.id}
                className={({ active }) =>
                  clsx(
                    "relative cursor-default select-none py-2 pl-10 pr-4",
                    active ? "bg-amber-100 text-amber-900" : "text-gray-900",
                  )
                }
                value={organization?.id}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={clsx(
                        "block truncate",
                        selected ? "font-medium" : "font-normal",
                      )}
                    >
                      {organization?.name}
                    </span>
                    {selected ? (
                      <span
                        className={clsx(
                          "absolute inset-y-0 left-0 flex items-center pl-3",
                          active ? "text-amber-600" : "text-amber-600",
                        )}
                      >
                        <Check />
                      </span>
                    ) : undefined}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

function SettingsMenu({
  organizations,
  initialOrganizationId,
  onClose,
  selectedWorkspaceProject,
}: SettingsMenuProps) {
  const [currentOrganizationId, setCurrentOrganizationId] = useState(
    initialOrganizationId,
  );

  const [currentTab, setCurrentTab] = useState<SettingsMenuTabs>(
    selectedWorkspaceProject
      ? SettingsMenuTabs.projects
      : SettingsMenuTabs.members,
  );

  useEffect(() => {
    document.title = "Recoder";
    updateDocumentTitle(t("component.documentTitle"));
  }, [currentTab]);

  return (
    <HStack className="h-full">
      <VStack className="h-full w-1/3 bg-gray-200" space={12}>
        <Logo size="medium" className="mt-10 ml-10 text-neutral-70" />
        <OrganizationSelect
          currentOrganizationId={currentOrganizationId}
          organizations={organizations}
          setCurrentOrganizationId={setCurrentOrganizationId}
        />
        <VStack>
          {Object.values(SettingsMenuTabs).map((tab) => (
            <SettingsTabItem
              key={tab}
              tabId={tab}
              title={t(`component.settings.tabs.${tab}.tabLabel`)}
              isCurrent={currentTab === tab}
              onClick={() => setCurrentTab(tab)}
              Icon={SETTINGS_TAB_ICONS[tab]}
            />
          ))}
        </VStack>
      </VStack>
      <SettingsMenuContent
        tab={currentTab}
        organizationId={currentOrganizationId}
        onClose={onClose}
        selectedWorkspaceProject={selectedWorkspaceProject}
      />
    </HStack>
  );
}
export default SettingsMenu;
