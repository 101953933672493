import { Button } from "components/base";
import { X as XIcon } from "phosphor-react";
import * as React from "react";

export default function SettingsMenuCloseButton({
  onClose,
}: {
  onClose: () => void;
}) {
  return (
    <Button
      data-testid="settings-menu-close-button"
      onClick={onClose}
      variant="tertiary"
      LeftIcon={XIcon}
    />
  );
}
