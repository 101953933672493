import { isAfter, isBefore, parseISO } from "date-fns";
import { Phase, ProjectByIdQuery } from "graphql/generated";
import { useProject } from "queries/use-project";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { findPhaseWeekIndex } from "utils/helpers/find-phase-week-index";

type PhaseType = Partial<Phase> &
  Pick<Phase, "name" | "position" | "weeksLength" | "startDate" | "endDate">;

type UpdatePhaseAndWeekProps = {
  phase: PhaseType;
  weekNumber: number;
};

type ProjectNavigatorType = {
  currentWeek: number;
  currentPhase?: PhaseType;
  project?: ProjectByIdQuery["projectById"];
  setCurrentPhase: React.Dispatch<React.SetStateAction<PhaseType | undefined>>;
  setCurrentWeek: React.Dispatch<React.SetStateAction<number>>;
  updatePhaseAndWeek: ({ phase, weekNumber }: UpdatePhaseAndWeekProps) => void;
  goToToday: (date: Date) => void;
};

const ProjectNavigatorContext = React.createContext<
  ProjectNavigatorType | undefined
>(undefined);

const filterCurrentPhaseByDate = (
  project: ProjectByIdQuery["projectById"],
  date: Date,
): Phase | undefined => {
  if (isAfter(parseISO(project.startDate), date)) {
    return project.phases.find((phase) => phase?.position === 0) as Phase;
  }

  const phase = project.phases.find(
    (phase) =>
      isBefore(parseISO(phase?.startDate || ""), date) &&
      isAfter(parseISO(phase?.endDate || ""), date),
  ) as Phase;

  return phase;
};

function ProjectNavigatorProvider({ children }: { children: React.ReactNode }) {
  const [currentPhase, setCurrentPhase] = useState<PhaseType>();
  const [currentWeek, setCurrentWeek] = useState(0);
  const { projectId = "" } = useParams();
  const { project } = useProject({ id: projectId });
  const lastProjectPhase = project?.phases[project?.phases.length - 1];
  const isProjectOver = isAfter(
    new Date(),
    new Date(project?.endDate as string),
  );

  const goToToday = useCallback(
    (date: Date = new Date()) => {
      if (!project) return;

      const foundPhase = filterCurrentPhaseByDate(project, date);
      if (foundPhase) {
        const { startDate, weeksLength } = foundPhase;
        const currentWeek = findPhaseWeekIndex({
          startDate,
          weeksLength,
          searchedDate: date.toISOString(),
        });
        setCurrentPhase(foundPhase);
        setCurrentWeek(currentWeek);
      }

      // if project is over, navigate user to last project phase
      if (!foundPhase && isProjectOver && lastProjectPhase?.id) {
        const { startDate, weeksLength, endDate } = lastProjectPhase;
        const currentWeek = findPhaseWeekIndex({
          startDate,
          weeksLength,
          searchedDate: endDate as string,
        });
        setCurrentPhase(lastProjectPhase as Phase);
        setCurrentWeek(currentWeek);
      }
    },
    [project],
  );

  const updatePhaseAndWeek = useCallback(
    ({ phase, weekNumber }: UpdatePhaseAndWeekProps) => {
      setCurrentPhase(phase);
      setCurrentWeek(weekNumber);
    },
    [setCurrentPhase, setCurrentWeek],
  );

  const value = {
    currentPhase,
    currentWeek,
    project,
    setCurrentPhase,
    setCurrentWeek,
    goToToday,
    updatePhaseAndWeek,
  };

  return (
    <ProjectNavigatorContext.Provider value={value}>
      {children}
    </ProjectNavigatorContext.Provider>
  );
}

function useProjectNavigator() {
  const context = React.useContext(ProjectNavigatorContext);
  if (context === undefined)
    throw new Error(
      "useProjectNavigator must be used within an ProjectNavigatorProvider",
    );
  return context;
}

export {
  PhaseType,
  ProjectNavigatorContext,
  ProjectNavigatorProvider,
  ProjectNavigatorType,
  UpdatePhaseAndWeekProps,
  useProjectNavigator,
};
