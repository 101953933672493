import React from "react";

function DChartIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3586_96009)">
        <path
          d="M28 55.5C28 56.0523 27.5519 56.5019 27 56.4822C19.9371 56.23 13.2159 53.3138 8.20101 48.299C3.18617 43.2841 0.270017 36.5629 0.0178436 29.5C-0.00186253 28.9481 0.447715 28.5 1 28.5L27 28.5C27.5523 28.5 28 28.9477 28 29.5L28 55.5Z"
          fill="#EB8424"
        />
        <path
          d="M28.5 57V29H56.5V51.5C56.5 54.5376 54.0376 57 51 57H28.5ZM27.5 29V57H5C1.96243 57 -0.5 54.5376 -0.5 51.5V29H27.5ZM56.5 28H28.5V0H51C54.0376 0 56.5 2.46243 56.5 5.5V28ZM27.5 0V28H-0.5V5.5C-0.5 2.46243 1.96243 0 5 0H27.5Z"
          stroke="#5F6368"
          stroke-opacity="0.1"
        />
        <path
          d="M20.6324 38.74H19.6324C19.3224 39.88 18.3424 40.71 17.0624 40.71C15.5624 40.71 14.4424 39.49 14.4424 37.98C14.4424 36.49 15.5624 35.26 17.0624 35.26C18.2824 35.26 19.2324 36.04 19.5824 37.12H20.6124C20.2424 35.53 18.8724 34.35 17.0724 34.35C14.9724 34.35 13.4424 35.99 13.4424 37.98C13.4424 39.98 14.9724 41.62 17.0724 41.62C18.9224 41.62 20.3024 40.37 20.6324 38.74Z"
          fill="white"
        />
      </g>
      <rect
        x="0.5"
        y="1"
        width="55"
        height="55"
        rx="27.5"
        stroke="#5F6368"
        stroke-opacity="0.1"
      />
      <defs>
        <clipPath id="clip0_3586_96009">
          <rect y="0.5" width="56" height="56" rx="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DChartIcon;
