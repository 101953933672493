import clsx from "clsx";
import { Button } from "components/base";
import BreakoutRoomSorter from "components/partial/WorkshopEditorActivityContent/BreakoutView/BreakoutRoomSorter";
import { Caption1 } from "components/Typography";
import {
  Breakout,
  BreakoutsState,
  Workshop,
  WorkshopActivity,
} from "graphql/generated";
import { t } from "i18n-js";
import {
  useSendBreakoutsRemainingTime,
  useStartEndBreakouts,
} from "mutations/workshop";
import { ArrowRight, ArrowUDownRight, CaretDown } from "phosphor-react";
import React from "react";

import TimeRemainingDropdown from "./TimeRemainingDropdown";

const BreakoutSlide = ({
  workshop,
  activity,
}: {
  activity: WorkshopActivity;
  workshop: Workshop;
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isContentScrolled, setIsContentScrolled] = React.useState(false);

  const { mutate: startEndBreakouts } = useStartEndBreakouts();
  const { mutate: sendRemainingTime } = useSendBreakoutsRemainingTime();

  const handleStart: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    startEndBreakouts({
      workshopActivityId: activity.id,
      state: BreakoutsState.Start,
    });
  };

  const handleEnd: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    startEndBreakouts({
      workshopActivityId: activity.id,
      state: BreakoutsState.End,
    });
  };

  const handleTimeRemaining = (remainingTime: number) => {
    sendRemainingTime({
      workshopActivityId: activity.id,
      remainingTime,
    });
  };

  const activityData = activity.activity as Breakout;
  const rooms = activityData.dailyRooms || [];

  const participants = workshop.workshopParticipants || [];

  const handleContentScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.currentTarget;

    if (scrollTop > 0 && isContentScrolled === false) {
      setIsContentScrolled(true);
    } else if (scrollTop === 0 && isContentScrolled === true) {
      setIsContentScrolled(false);
    }
  };

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
    setIsContentScrolled(false);
  };

  const breakoutActive = activityData?.breakoutRoomIsActive || false;

  const handleJoinRoom = () => {
    startEndBreakouts({
      workshopActivityId: activity.id,
      state: BreakoutsState.Start,
    });
  };

  return (
    <div
      className={clsx(
        "absolute bottom-18 left-0 right-0 z-10 mx-4 flex max-h-[98%] w-auto max-w-[369px] translate-y-full flex-col rounded-t-[20px] border border-tint-dark-300 bg-white pb-0 drop-shadow-feedCard transition-transform",
        { "translate-y-18": isExpanded },
      )}
    >
      <div
        className={clsx("relative flex items-center p-4 transition-colors", {
          "bg-neutral-100": isContentScrolled,
        })}
        onClick={handleExpandToggle}
      >
        <CaretDown
          className={clsx("cursor-pointer transition-transform", {
            "rotate-180": !isExpanded,
          })}
        />
        <Caption1 className="mr-auto mt-1 ml-3 flex select-none items-center text-neutral-800">
          {breakoutActive && (
            <div className="mr-2 h-[5px] w-[5px] rounded-full bg-secondary-green-500" />
          )}
          {breakoutActive
            ? t("workshop.inSessionBreakout.live")
            : t("workshop.inSessionBreakout.rooms", { count: rooms.length })}
        </Caption1>
        {breakoutActive && (
          <TimeRemainingDropdown onSubmit={handleTimeRemaining} />
        )}
        <Button
          LeftIcon={breakoutActive ? ArrowUDownRight : ArrowRight}
          variant={breakoutActive ? "destructive" : "secondary"}
          size="small"
          className="select-none text-sm"
          onClick={breakoutActive ? handleEnd : handleStart}
        >
          {breakoutActive
            ? t("workshop.inSessionBreakout.end")
            : t("workshop.inSessionBreakout.start")}
        </Button>
      </div>
      <div
        className="no-scrollbar min-h-[200px] flex-1 overflow-y-auto p-4"
        onScroll={handleContentScroll}
      >
        <BreakoutRoomSorter
          participants={participants}
          dailyRooms={rooms}
          activityId={activity.id}
          roomsActive={breakoutActive}
          onJoinRoom={breakoutActive ? handleJoinRoom : undefined}
        />
      </div>
    </div>
  );
};

export default BreakoutSlide;
