import { Milestone, Phase, ProjectPhaseInput } from "graphql/generated";
import { WeeksFromProjectPerPhasesReturn } from "utils/helpers/get-weeks-from-project-per-phases";

import { DefaultFieldsType } from "./types";

export const PHASE_FIELD = { name: "", weeksLength: undefined, goal: "" };

export const getPhaseField = (): Partial<Phase> => ({ ...PHASE_FIELD });

export function mergeWithDefaultFields<T>(
  defaultFields: DefaultFieldsType,
  fields: T,
): T {
  return {
    ...defaultFields,
    ...fields,
  } as T;
}

export const normalizePhases = (
  phases: Partial<Phase>[],
): ProjectPhaseInput[] =>
  removeEmptyPhases(phases).map(
    (phase: Partial<Phase>, index: number): ProjectPhaseInput => {
      const { id, goal, name, weeksLength, milestones } = phase;
      const newPhase = {
        id,
        goal,
        name,
        weeksLength: Number(weeksLength) || 0,
        position: index,
        milestonesAttributes: milestones || [],
      };
      return newPhase as ProjectPhaseInput;
    },
  );

export const addMilestonesToPhases = (
  phases: Partial<Phase>[],
  milestones: WeeksFromProjectPerPhasesReturn[][],
): Partial<Phase>[] =>
  removeEmptyPhases(phases).map(
    (phase: Partial<Phase>, index: number): Partial<Phase> => {
      const newPhase = {
        ...phase,
        weeksLength: Number(phase.weeksLength) || 0,
        position: index,
      } as Partial<Phase>;

      if (milestones && milestones[index]) {
        const phaseMilestones = milestones[index]
          .filter(
            (currentMilestone) => currentMilestone.milestoneType !== undefined,
          )
          .map((currentMilestone) => ({
            milestoneType: currentMilestone.milestoneType,
            name: currentMilestone.name,
            weekNumber: currentMilestone.weekNumber,
          }));

        newPhase["milestones"] = phaseMilestones as unknown as Milestone[];
      }

      return newPhase;
    },
  );

export function getDefaultFields<T>(): T {
  return {
    name: "",
    startDate: "",
    goal: "",
    url: "",
    links: [],
    phases: [getPhaseField(), getPhaseField(), getPhaseField()],
  } as unknown as T;
}

export const removeEmptyPhases = (phases: Partial<Phase>[]): Partial<Phase>[] =>
  phases.filter(
    (phase: Partial<Phase>) => phase?.name && phase?.name.length > 0,
  );
