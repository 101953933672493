import clsx from "clsx";
import { Loader } from "components/base";
import React from "react";

export interface PageLoaderProps {
  isShowing: boolean;
}

/**
 * Wrapper for the `Loader` component which throws it centered into a
 * page-wide overlay.
 */
export function PageLoader({ isShowing }: PageLoaderProps) {
  return (
    <div
      className={clsx(
        "absolute top-0 bottom-0 left-0 right-0 z-20 flex flex-1 items-center justify-center bg-neutral-700 bg-opacity-40",
        { hidden: !isShowing },
      )}
    >
      <Loader />
    </div>
  );
}
