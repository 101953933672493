import { generateText, JSONContent } from "@tiptap/core";
import { getEditorExtensions } from "components/base/RichTextEditor/RichTextEditor";
import { useToast } from "components/provider";
import { WorkshopByIdQuery } from "graphql/generated";
import { t } from "i18n-js";
import { useUpdateWorkshop } from "mutations/workshop";
import { useEffect, useState } from "react";

export const MAX_DESC_LENGTH = 1000;
// Don't include empty "content": [], because it'll break.
export const EMPTY_JSON_CONTENT: JSONContent = {
  type: "doc",
};

const validateJSON = (
  jsonContent: JSONContent,
  rules?: JSONValidationRules,
) => {
  if (!rules) {
    return true;
  }

  if (!jsonContent || !jsonContent.content) {
    return false;
  }

  const textContent = generateText(
    jsonContent,
    getEditorExtensions({ maxCharCount: 1000 }),
    {},
  );

  let valid = true;

  for (const key of Object.keys(rules)) {
    switch (key) {
      // TODO: use enum or some other stricter typing for the keys.
      case "notEmpty":
        valid = textContent.length > 0;
        break;
      case "maxLength":
        valid = textContent.length < MAX_DESC_LENGTH;
        break;
    }
  }
  return valid;
};

export interface JSONValidationRules {
  notEmpty?: boolean;
  maxLength?: number;
}
export interface SummaryEditValidation {
  description?: boolean;
  summary?: boolean;
}
export interface UseSummaryEditEffectsProps {
  workshop?: WorkshopByIdQuery["workshopById"];
  id?: string;
}
export interface UseSummaryEditEffectsReturn {
  description: JSONContent;
  summary: JSONContent;
  handleDescriptionChange: (jsonContent: JSONContent) => void;
  handleSummaryChange: (jsonContent: JSONContent) => void;
  isValid: boolean;
  validation: SummaryEditValidation;
  isLoading: boolean;
}

/**
 * This hook handles all the form-like logic in the WorkshopSummaryEdit component.
 */
export const useSummaryEditEffects = ({
  workshop,
  id,
}: UseSummaryEditEffectsProps): UseSummaryEditEffectsReturn => {
  const { isLoading, mutate: updateWorkshop } = useUpdateWorkshop();
  const { addToast } = useToast();

  /* State Variables */
  const [description, setDescription] = useState<JSONContent>(
    (workshop?.description as JSONContent | null) || EMPTY_JSON_CONTENT,
  );

  const [summary, setSummary] = useState<JSONContent>(
    (workshop?.summary as JSONContent | null) || EMPTY_JSON_CONTENT,
  );

  const [validation, setValidation] = useState<SummaryEditValidation>({});

  /** Handlers **/
  const handleDescriptionChange = (jsonContent: JSONContent) => {
    setDescription(jsonContent);
  };

  const handleSummaryChange = (jsonContent: JSONContent) => {
    setSummary(jsonContent);
  };

  /** Effects **/
  useEffect(() => {
    setValidation({
      description: validateJSON(description, { notEmpty: true }),
      summary: validateJSON(summary, { notEmpty: true }),
    });
  }, [description, summary, setValidation]);

  useEffect(() => {
    if (
      id &&
      description &&
      description !== workshop?.description &&
      validation.description === true
    ) {
      updateWorkshop(
        { workshop: { id, description } },
        { onError: handleEditError },
      );
    }
    // Consciously not including workshop in deps because of bug with api emptying `summary` value when desc is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, description, updateWorkshop, validation]);

  useEffect(() => {
    if (
      id &&
      summary &&
      summary !== workshop?.summary &&
      validation.summary === true
    ) {
      updateWorkshop(
        { workshop: { id, summary } },
        { onError: handleEditError },
      );
    }
    // Consciously not including workshop in deps because of bug with api emptying `description` value when summary is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, summary, updateWorkshop, validation]);

  const handleEditError = () => {
    addToast({
      message: t("workshop.summary.editActions.error"),
      variant: "error",
      hasCloseOption: true,
    });
  };

  return {
    description,
    summary,
    handleDescriptionChange,
    handleSummaryChange,
    isValid: Object.values(validation).every((v) => v === true),
    validation,
    isLoading,
  };
};
