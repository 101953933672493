import { Base2, Caption1 } from "components";
import {
  Button,
  DatePicker,
  Input,
  Link,
  MultiUserInput,
} from "components/base";
import {
  ConfirmDialog,
  FormInputErrors,
  InteractionForm,
} from "components/partial";
import { useHighlightContext } from "contexts/HighlightContext";
import {
  format,
  formatISO,
  isAfter,
  isBefore,
  parseISO,
  startOfDay,
} from "date-fns";
import { LinkInput } from "graphql/generated";
import { useFetchMetadata } from "hooks";
import { t } from "i18n-js";
import { noop, pick } from "lodash";
import useDeleteTask from "mutations/use-delete-task";
import useUpdateProjectEndDate from "mutations/use-update-project-end-date";
import useUpdateTask from "mutations/use-update-task";
import { CircleNotch, X } from "phosphor-react";
import { useProject, useProjectAssignments } from "queries";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { FieldError, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Z_INDEX_LEVELS } from "utils";
import { getZonedDate } from "utils/constants/time_helpers";
import { validateUrl, whetherLinkExists } from "utils/helpers/validations";

import {
  EmbeddedLinks,
  ErrorType,
} from "../../CreateInteractions/CreateAnnouncementForm";

const MAX_TITLE_CHARS = 90;
const MAX_DESCRIPTION_CHARS = 280;

export enum INPUT_FIELDS {
  id = "id",
  title = "title",
  description = "description",
  assigneeIds = "assigneeIds",
  dueDate = "dueDate",
  links = "links",
  newLink = "newLink",
}

export type UpdateTaskFormInput = {
  [INPUT_FIELDS.id]: string;
  [INPUT_FIELDS.title]: string;
  [INPUT_FIELDS.description]: string;
  [INPUT_FIELDS.assigneeIds]: string[];
  [INPUT_FIELDS.dueDate]: string;
  [INPUT_FIELDS.links]: LinkInput[];
  [INPUT_FIELDS.newLink]?: string;
};

type UpdateTaskFormProps = {
  task: UpdateTaskFormInput;
  onClose?: () => void;
  testProps?: EmbeddedLinks[];
  userHasAdminAccess?: boolean;
};

function UpdateTaskForm({
  onClose = noop,
  task,
  testProps = [],
  userHasAdminAccess = false,
}: UpdateTaskFormProps) {
  const [requestError, setRequestError] = useState("");
  const { projectId = "" } = useParams();
  const { project } = useProject({ id: projectId });
  const { projectAssignments } = useProjectAssignments({ projectId });
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    setError,
    getValues,
    resetField,
  } = useForm<UpdateTaskFormInput>({
    defaultValues: task,
    mode: "onSubmit",
  });

  const [assigneeIds, setAssigneeIds] = useState<string[]>([]);
  const [newlyAddedLinks, setNewlyAddedLinks] = useState<EmbeddedLinks[]>(
    testProps?.length > 0 ? testProps : [],
  );
  const [enteredLink, setEnteredLink] = useState("");
  const [discardedLink, setDiscardedLink] = useState<EmbeddedLinks>({
    preview: "",
    url: "",
    title: "",
  });

  const [isConfirmModalOpen, toggleConfirmModal] = useReducer((state) => {
    return !state;
  }, false);
  const { isHighlighted } = useHighlightContext();
  const [showConfirmDeleteLinkModal, setShowConfirmDeleteLinkModal] =
    useState(false);
  const [selectedInvalidDate, setSelectedInvalidDate] = useState(false);
  const [showInvalidDateModal, setShowInvalidDateModal] = useState(false);

  const { mutateAsync: updateTask } = useUpdateTask();
  const { mutate: deleteTask } = useDeleteTask();
  const { data, isLoading, error } = useFetchMetadata(enteredLink);
  const { mutate: updateProjectEndDate } = useUpdateProjectEndDate();

  const projectMembers = useMemo(() => {
    return projectAssignments.map(({ user, role }) => ({ ...user, role }));
  }, [projectAssignments]);

  const navigate = useNavigate();
  const projectEndDate = project?.endDate;
  const dueDate = getValues("dueDate");

  const addFormError = (
    fieldName: INPUT_FIELDS,
    error: ErrorType,
    focusField = false,
  ) => {
    setError(
      fieldName,
      {
        type: error.type,
        message: error.message,
      },
      { shouldFocus: focusField },
    );
  };

  useEffect(() => {
    const preEmbeddedLinks = task?.links?.map((link) =>
      pick(link, ["preview", "title", "url"]),
    );
    setNewlyAddedLinks(preEmbeddedLinks as EmbeddedLinks[]);
  }, []);

  useEffect(() => {
    if (data?.url && whetherLinkExists(newlyAddedLinks, data?.url) === -1) {
      setNewlyAddedLinks((prev) => [
        ...prev,
        {
          url: data.url,
          title: data?.title || data?.domain,
          extension: data.domain,
          preview: data?.images[0] || "",
        },
      ]);
      resetField("newLink");
      setEnteredLink("");
    } else {
      data?.url &&
        addFormError(
          INPUT_FIELDS.newLink,
          {
            type: "custom",
            message: t("errors.linkAlreadyExists"),
          },
          true,
        );
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      switch (error.message) {
        case "Request failed with status code 404":
          addFormError(INPUT_FIELDS.newLink, {
            type: "pattern",
            message: t("errors.invalidLink"),
          });
          setEnteredLink("");
          break;
        default:
          addFormError(INPUT_FIELDS.newLink, {
            type: "custom",
            message: t("errors.somethingWentWrong"),
          });
          setEnteredLink("");
      }
    }
  }, [error]);

  useEffect(() => {
    if (
      dueDate &&
      projectEndDate &&
      isAfter(new Date(dueDate), new Date(projectEndDate))
    ) {
      setShowInvalidDateModal(true);
      setSelectedInvalidDate(true);
      setRequestError(t("errors.selectedDateIsAfterProjectEndDate"));
    } else {
      setShowInvalidDateModal(false);
      setSelectedInvalidDate(false);
      setRequestError("");
    }
  }, [dueDate, projectEndDate]);

  const handleUpdateProjectEndDate = () => {
    if (dueDate) {
      updateProjectEndDate({
        projectId,
        eventDate: format(new Date(dueDate), "yyyy-LL-dd"),
      });
      setShowInvalidDateModal(false);
      setSelectedInvalidDate(false);
      setRequestError("");
    }
  };

  const handleEmbedLink = (event: React.FormEvent) => {
    event.preventDefault();
    const url: string = getValues("newLink");

    if (validateUrl(url)) {
      setEnteredLink(url);
    } else {
      addFormError(
        INPUT_FIELDS.newLink,
        {
          type: "pattern",
          message: t("errors.invalidLink"),
        },
        true,
      );
    }
  };

  const removeEmbeddedLinks = (event: React.FormEvent, url: string) => {
    event.preventDefault();
    const newEmbeddedLinks = [...newlyAddedLinks];
    const linkIndex = newEmbeddedLinks.findIndex((links) => links.url === url);
    if (linkIndex > -1) {
      newEmbeddedLinks.splice(linkIndex, 1);
      setNewlyAddedLinks(newEmbeddedLinks);
      setShowConfirmDeleteLinkModal(false);
      setEnteredLink("");
    }
  };

  const renderEmbeddedLinks = () => {
    return (
      <>
        <Base2>
          {newlyAddedLinks?.length}{" "}
          {newlyAddedLinks?.length > 1 ? "Links" : "Link"}
        </Base2>
        <div className="align-center flex flex-wrap gap-4">
          {newlyAddedLinks.map((link) => (
            <Link
              metadata={link}
              key={link.url}
              isRemovable={true}
              onClick={() => {
                setDiscardedLink(link);
                setShowConfirmDeleteLinkModal(true);
              }}
            />
          ))}
        </div>
      </>
    );
  };

  const onSubmit: SubmitHandler<UpdateTaskFormInput> = async ({
    id,
    title,
    description,
    assigneeIds: assigneeIdsToSubmit,
    dueDate,
  }) => {
    if (isBefore(getZonedDate(dueDate), startOfDay(new Date())))
      return setRequestError(t("shared.invalidDate"));
    try {
      const result = await updateTask({
        task: {
          id,
          title,
          description,
          assigneeIds: assigneeIdsToSubmit,
          dueDate: dueDate,
          isHighlighted,
          links: newlyAddedLinks,
        },
      });

      if (result.updateTask) {
        setRequestError("");
        const scheduledAt = new Date(dueDate).toISOString();
        onClose();
        navigate(
          `/projects/${result.updateTask.project.id}?level=Timeline&card=task-${result.updateTask.id}&scheduledAt=${scheduledAt}`,
        );
      }
    } catch {
      setRequestError(t("errors.somethingWentWrong"));
    }
  };

  const handleDelete = () => {
    deleteTask({ id: task.id });
    toggleConfirmModal();
    onClose();
  };

  useEffect(() => {
    setValue(INPUT_FIELDS.assigneeIds, assigneeIds);
  }, [assigneeIds, setValue]);

  return (
    <InteractionForm
      onClose={onClose}
      onSubmit={
        selectedInvalidDate ? noop : (handleSubmit(onSubmit) as () => void)
      }
    >
      <Input
        id={INPUT_FIELDS.title}
        label={t("component.updateTask.titleLabel")}
        placeholder={t("component.updateTask.titlePlaceholder")}
        maxLength={MAX_TITLE_CHARS}
        initialCount={task?.title?.length}
        showCounter
        longCountDescription
        {...register(INPUT_FIELDS.title, {
          required: t("errors.presence"),
        })}
        error={errors?.[INPUT_FIELDS.title]?.message}
      />
      <Input
        id={INPUT_FIELDS.description}
        label={t("component.updateTask.descriptionLabel")}
        placeholder={t("component.updateTask.descriptionPlaceholder")}
        maxLength={MAX_DESCRIPTION_CHARS}
        {...register(INPUT_FIELDS.description, {
          required: t("errors.presence"),
        })}
        error={errors?.[INPUT_FIELDS.description]?.message}
        multiline
        initialCount={task?.description?.length}
        expandable
        resizable
        showCounter
        longCountDescription
      />

      <DatePicker.withRef
        className="max-w-sm"
        label={t("shared.dueDate")}
        format="MM/d/YY"
        {...register(INPUT_FIELDS.dueDate, {
          required: t("errors.presence"),
        })}
        onChange={(selectedDate) => {
          if (selectedDate) {
            setValue(
              INPUT_FIELDS.dueDate,
              formatISO(selectedDate, { representation: "date" }),
              {
                shouldValidate: true,
              },
            );
          }
        }}
        defaultValue={parseISO(task[INPUT_FIELDS.dueDate])}
        error={errors.dueDate?.message}
      />

      <MultiUserInput
        label={t("component.updateTask.assignTo")}
        placeholder={t("component.updateTask.assignToFull")}
        selectedSectionLabel={t("component.updateTask.assignees", {
          number: assigneeIds?.length,
        })}
        users={projectMembers}
        {...register(INPUT_FIELDS.assigneeIds, {
          required: t("errors.presence"),
        })}
        onSelectUserIds={setAssigneeIds}
        error={
          //https://github.com/react-hook-form/react-hook-form/issues/8036#issuecomment-1070966732
          (errors?.assigneeIds as unknown as FieldError)?.message
        }
        clearError={() => clearErrors(INPUT_FIELDS.assigneeIds)}
        defaultSelectedUsers={task[INPUT_FIELDS.assigneeIds]}
        userPillColor="bg-neutral-0"
        userPillTextColor="text-neutral-700"
      />

      <>
        <Input
          id={INPUT_FIELDS.newLink}
          label={t("shared.linksLabel")}
          placeholder={t("shared.linksPlaceholder")}
          value={watch(INPUT_FIELDS.newLink)}
          {...register(INPUT_FIELDS.newLink)}
          error={errors?.[INPUT_FIELDS.newLink]?.message}
        />

        <Button onClick={handleEmbedLink}>
          {isLoading ? (
            <CircleNotch size={20} color="#fcfcfc" className="animate-spin" />
          ) : (
            t("shared.embedLink")
          )}
        </Button>

        <Caption1 className="text-center leading-5 text-neutral-60">
          {t("shared.workWithLinks1")}
          <br />
          {t("shared.workWithLinks2")}
        </Caption1>
        {newlyAddedLinks?.length > 0 && renderEmbeddedLinks()}
      </>
      <div>
        <Button
          type="button"
          variant="destructive"
          LeftIcon={X}
          size="small"
          onClick={toggleConfirmModal}
        >
          {t(`component.updateTask.deleteTask`)}
        </Button>
        <ConfirmDialog
          open={isConfirmModalOpen}
          onConfirm={handleDelete}
          onCancel={toggleConfirmModal}
        />
        <ConfirmDialog
          open={showConfirmDeleteLinkModal}
          onCancel={() => setShowConfirmDeleteLinkModal(false)}
          onConfirm={(event: React.FormEvent) =>
            removeEmbeddedLinks(event, discardedLink.url)
          }
          title={t(
            "component.editAnnouncement.deleteEmbeddedLinkConfirmDialog.title",
          )}
          subtitle={t(
            "component.editAnnouncement.deleteEmbeddedLinkConfirmDialog.subtitle",
          )}
          confirmButtonText={t(
            "component.editAnnouncement.deleteEmbeddedLinkConfirmDialog.confirm",
          )}
          cancelButtonText={t(
            "component.editAnnouncement.deleteEmbeddedLinkConfirmDialog.cancel",
          )}
        />
        <ConfirmDialog
          open={showInvalidDateModal}
          onCancel={() => setShowInvalidDateModal(false)}
          onConfirm={handleUpdateProjectEndDate}
          title={t("component.invalidDateModal.title")}
          subtitle={
            userHasAdminAccess
              ? t("component.invalidDateModal.subtitle")
              : t("component.invalidDateModal.subtitleAlt")
          }
          cancelButtonText={t("component.invalidDateModal.cancel")}
          confirmButtonText={
            userHasAdminAccess ? t("component.invalidDateModal.confirm") : ""
          }
          confirmButtonVariant="outline"
          zIndex={Z_INDEX_LEVELS.MODAL_CONTROL}
          key="InvalidDueDateModal"
        />
      </div>
      <FormInputErrors error={requestError} />
    </InteractionForm>
  );
}

export default UpdateTaskForm;
