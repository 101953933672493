import { Heading1Strong, VStack } from "components";
import { GetEnvsProvider } from "contexts";
import { Scalars } from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import React from "react";

import SlackSettings from "./SlackSettings";

type IntegrationsProps = {
  projectId: Scalars["ID"];
};

export default function Integrations({ projectId }: IntegrationsProps) {
  const isProjectAdmin = useProjectAccessLevel({ projectId }).hasAdminAccess;

  if (!isProjectAdmin) return <></>;

  return (
    <VStack space={6}>
      <Heading1Strong className="text-neutral-90">
        {t("integrations.title")}
      </Heading1Strong>
      <GetEnvsProvider>
        <SlackSettings projectId={projectId} />
      </GetEnvsProvider>
    </VStack>
  );
}
