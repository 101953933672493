/* eslint-disable graphql/template-strings */

import {
  useWorkshopByIdQuery,
  WorkshopByIdQuery,
  WorkshopByIdQueryVariables,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryOptions, UseQueryResult } from "react-query";

export const WORKSHOP_DISCUSSION_ACTIVITY_FRAGMENT = gql`
  fragment DiscussionActivity on Discussion {
    __typename
    createdAt
    description
    id
    updatedAt
  }
`;

export const WORKSHOP_RESOURCE_ACTIVITY_FRAGMENT = gql`
  fragment ResourceActivity on Resource {
    __typename
    createdAt
    id
    link {
      id
      url
      title
      preview
      extension
    }
    documents {
      contentType
      filename
      url
      id
      isActive
    }
    updatedAt
  }
`;

export const WORKSHOP_BREAKOUT_DAILY_ROOM_FRAGMENT = gql`
  fragment BreakoutDailyRoom on DailyRoom {
    createdAt
    id
    isActive
    name
    privacy
    roomId
    title
    url
    updatedAt
    workshopParticipants {
      id
      isActive
      role
      roomToken
      updatedAt
      user {
        avatarUrl
        currentSignInAt
        defaultProjectRole
        email
        fullName
        firstName
        lastName
        id
      }
      breakoutRoomTokens {
        dailyRoom {
          id
        }
      }
    }
    recordings {
      id
      url
      recordingId
      startTime
      recordable {
        ... on DailyRoom {
          id
          title
        }
      }
    }
  }
`;

export const WORKSHOP_BREAKOUT_ACTIVITY_FRAGMENT = gql`
  ${WORKSHOP_BREAKOUT_DAILY_ROOM_FRAGMENT}
  fragment BreakoutActivity on Breakout {
    __typename
    breakoutRoomIsActive
    createdAt
    dailyRooms {
      ...BreakoutDailyRoom
    }
    id
    instruction
    link {
      extension
      id
      isActive
      preview
      title
      url
    }
    updatedAt
    documents {
      contentType
      filename
      url
      id
      isActive
    }
  }
`;

export const WORKSHOP_POLL_ACTIVITY_FRAGMENT = gql`
  fragment PollActivity on Poll {
    __typename
    id
    allowSkip
    anonymous
    answers {
      answers
      id
      workshopParticipant {
        id
        user {
          avatarUrl
          firstName
          lastName
          fullName
        }
      }
    }
    paragraphAnswers {
      answer
      workshopParticipant {
        user {
          firstName
          lastName
        }
      }
    }
    wordCloudCount {
      text
      value
    }
    createdAt
    isActive
    multipleAnswers
    options {
      answersCount
      createdAt
      id
      isActive
      participants {
        id
        role
        user {
          avatarUrl
          company
          email
          firstName
          lastName
          fullName
          homebase
          id
          pronouns
          linkdinLink
        }
      }
      pollId
      title
      updatedAt
    }
    pollType
    question
    updatedAt
  }
`;

export const WORKSHOP_ACTIVITY_FRAGMENT = gql`
  ${WORKSHOP_DISCUSSION_ACTIVITY_FRAGMENT}
  ${WORKSHOP_RESOURCE_ACTIVITY_FRAGMENT}
  ${WORKSHOP_BREAKOUT_ACTIVITY_FRAGMENT}
  ${WORKSHOP_POLL_ACTIVITY_FRAGMENT}
  fragment LiveWorkshopActivity on WorkshopActivity {
    __typename
    createdAt
    duration
    facilitatorNote
    id
    isActive
    position
    sectionId
    state
    title
    timeElapsed
    updatedAt
    workshop {
      id
    }
    activity {
      ... on Discussion {
        ...DiscussionActivity
      }
      ... on Resource {
        ...ResourceActivity
      }
      ... on Breakout {
        ...BreakoutActivity
      }
      ... on Poll {
        ...PollActivity
      }
    }
  }
`;

// TODO: add summary
gql`
  query WorkshopById($id: ID!) {
    workshopById(id: $id) {
      id
      title
      startTime
      endTime
      duration
      description
      createdAt
      highlight
      hostId
      isActive
      isAgendaVisibleToParticipants
      pendingInvitations {
        id
        recipient {
          id
          firstName
          lastName
          fullName
          email
          defaultProjectRole
        }
        recipientEmail
        accessLevel
      }
      projectId
      projectEndDate
      recordings {
        id
        url
        recordingId
        createdAt
        startTime
        status
        recordable {
          __typename
        }
      }
      state
      summary
      updatedAt
      documents {
        id
        filename
        url
      }
      room {
        id
        name
        privacy
        url
      }
      links {
        extension
        id
        preview
        title
        url
      }
      emotionalSignals {
        emojiName
        timeStamp
      }
      agenda {
        __typename
        ... on Section {
          id
          title
          createdAt
          isActive
          position
          updatedAt
          workshopActivities {
            ...LiveWorkshopActivity
          }
        }
        ... on WorkshopActivity {
          ...LiveWorkshopActivity
        }
      }
      workshopParticipants {
        id
        role
        createdAt
        role
        updatedAt
        roomToken
        didAttend
        raiseHand
        user {
          avatarUrl
          currentSignInAt
          defaultProjectRole
          email
          firstName
          id
          lastName
          fullName
        }
        breakoutRoomTokens {
          dailyRoom {
            id
          }
        }
      }
    }
  }
`;

function useWorkshop(
  variables: WorkshopByIdQueryVariables,
  options?: UseQueryOptions<WorkshopByIdQuery>,
): {
  workshop?: WorkshopByIdQuery["workshopById"];
} & UseQueryResult<WorkshopByIdQuery, unknown> {
  const response = useWorkshopByIdQuery(variables, {
    enabled: !!variables.id,
    ...options,
  });

  const { isError, data } = response;

  if (isError) {
    localStorage.setItem("lastVisitedProjectPage", window.location.pathname);
  }

  return {
    workshop: data?.workshopById,
    ...response,
  };
}

export { useWorkshop };
