/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable unicorn/no-useless-undefined */
import clsx from "clsx";
import {
  Button,
  DatePicker,
  Input,
  TimePicker,
  WorkshopStatusBadge,
} from "components/base";
import { ConfirmDialog, Modal } from "components/partial";
import { Base2, Caption1, Heading2 } from "components/Typography";
import { compareAsc, format, isAfter, startOfToday } from "date-fns";
import {
  ProjectInvitation,
  WorkshopParticipant,
  WorkshopState,
} from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { ChevronDownIcon, EditIcon, EditWorkshopIcon } from "icons";
import useUpdateHighlightOfWorkshop from "mutations/use-update-highlight-workshop";
import useUpdateProjectEndDate from "mutations/use-update-project-end-date";
import { useUpdateWorkshop } from "mutations/workshop";
import { useWorkshop } from "queries";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useWorkshopStateSubscription } from "subscriptions";
import {
  getWorkshopBadge,
  workshopStatusType,
} from "utils/helpers/workshop-status";

import AvatarGroupOnline from "../AvatarGroupOnline/AvatarGroupOnline";
import BackToRecoder from "../BackToRecoder/BackToRecoder";
import AddParticipantsModal from "./AddParticipantsModal";
import { EditWorkshopHeaderComponent } from "./EditWorkshopHeader.types";

const defaultStartTime = new Date();
defaultStartTime.setHours(8);
defaultStartTime.setMinutes(0);
defaultStartTime.setUTCSeconds(0);
defaultStartTime.setMilliseconds(0);
const placeholderStartTime = format(defaultStartTime, "h:mm aaa");

const defaultEndTime = new Date();
defaultEndTime.setHours(9);
defaultEndTime.setMinutes(0);
defaultEndTime.setUTCSeconds(0);
defaultEndTime.setMilliseconds(0);
const placeholderEndTime = format(defaultEndTime, "h:mm aaa");

const EditWorkshopHeader: EditWorkshopHeaderComponent = ({
  title,
  startTime: startTimeProp,
  endTime: endTimeProp,
  onStarClick,
  isNewWorkshop,
  className,
}) => {
  const navigate = useNavigate();

  const startTime =
    !isNewWorkshop && startTimeProp ? new Date(startTimeProp) : undefined;
  const endTime =
    !isNewWorkshop && endTimeProp ? new Date(endTimeProp) : undefined;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const date = format(startTime || 0, "E, MMM d");
  const formattedStartTime = format(startTime || 0, "h:mm");
  const formattedEndTime = format(endTime || 0, "h:mmaaa");
  const [isEditing, setIsEditing] = useState(!startTime);

  const { id: workshopId = "" } = useParams();
  const { workshop, isLoading } = useWorkshop({ id: workshopId });
  const projectEndDate = workshop?.projectEndDate;

  const [newDate, setNewDate] = useState<Date | undefined>(
    startTime ?? new Date(),
  );
  const [selectedInvalidWorkshopDate, setSelectedInvalidWorkshopDate] =
    useState(false);
  const [showInvalidDateModal, setShowInvalidDateModal] = useState(false);
  const [newStartTime, setNewStartTime] = useState<Date | undefined>(startTime);
  const [newEndTime, setNewEndTime] = useState<Date | undefined>(endTime);
  const [newTitle, setNewTitle] = useState<string>(
    isNewWorkshop ? "" : title || "",
  );

  const { data: workshopData } = useWorkshopStateSubscription({
    workshopId,
  });
  const { mutate: updateProjectEndDate } = useUpdateProjectEndDate();

  //Comment out if next 15 min hand needs to used as default start time and pass to relevant timepicker below
  // const controlledTimePicker = new Date();
  // controlledTimePicker.setHours(new Date().getHours());
  // controlledTimePicker.setMinutes(getControlledTime/**Import from utils */(new Date().getMinutes()));
  // controlledTimePicker.setUTCSeconds(0);
  // controlledTimePicker.setMilliseconds(0);

  const { mutate: updateWorkshop } = useUpdateWorkshop();
  const hasAdminAccess = useProjectAccessLevel({
    projectId: String(workshop?.projectId) || "",
  }).hasAdminAccess;

  const { mutate: updateHighlightOfWorkshop } = useUpdateHighlightOfWorkshop();

  // For date picker's reference on which dates to disable.
  const today = startOfToday();

  useEffect(() => {
    if (
      newDate &&
      projectEndDate &&
      isAfter(newDate, new Date(projectEndDate))
    ) {
      setShowInvalidDateModal(true);
      setSelectedInvalidWorkshopDate(true);
    } else {
      setShowInvalidDateModal(false);
      setSelectedInvalidWorkshopDate(false);
    }
  }, [newDate, projectEndDate]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTitle(e.target.value);
  };

  const handleSaveChanges = () => {
    if (workshop?.projectId && newDate && newStartTime && newEndTime) {
      const finalStartTime = new Date(newDate);
      finalStartTime.setHours(newStartTime.getHours());
      finalStartTime.setMinutes(newStartTime.getMinutes());
      finalStartTime.setSeconds(0);

      const finalEndTime = new Date(newDate);
      finalEndTime.setHours(newEndTime.getHours());
      finalEndTime.setMinutes(newEndTime.getMinutes());
      finalEndTime.setSeconds(0);

      const duration =
        (newEndTime.getTime() - newStartTime.getTime()) / 1000 / 60;

      updateWorkshop(
        {
          workshop: {
            title: newTitle,
            startTime: finalStartTime.toISOString(),
            endTime: finalEndTime.toISOString(),
            duration,
            id: workshopId,
          },
        },
        {
          onSuccess: () => {
            setIsEditing(false);
          },
        },
      );
    }
  };

  const handleModal = () => setModalIsOpen(!modalIsOpen);

  const participants = useMemo(
    () => workshop?.workshopParticipants.map((participant) => participant.user),
    [workshop?.workshopParticipants],
  );

  if (!workshop) {
    return (
      <div className="flex flex-row bg-tertiary-blue-70 px-14 py-5">
        <Heading2 className="text-white">
          {t(isLoading ? "workshop.header.loading" : "workshop.header.error")}
        </Heading2>
      </div>
    );
  }

  const formIncomplete = !newDate || !newStartTime || !newEndTime || !newTitle;

  const isWorkshopLive = workshop?.state === WorkshopState.LiveSession;

  const backToRecoderHandler = () => {
    const cardUrl = `/projects/${workshop?.projectId}?level=Timeline&card=workshop-${workshop?.id}&scheduledAt=${workshop?.startTime}`;
    if (workshop?.state === WorkshopState.LiveSession) window.open(cardUrl);
    else {
      navigate(cardUrl);
    }
  };

  const handleUpdateProjectEndDate = () => {
    if (newDate) {
      updateProjectEndDate({
        projectId: String(workshop?.projectId),
        eventDate: format(newDate, "yyyy-LL-dd"),
      });
      setShowInvalidDateModal(false);
      setSelectedInvalidWorkshopDate(false);
    }
  };

  return (
    <header>
      <div
        className={clsx(
          `flex flex-row items-center justify-between gap-4 ${
            isWorkshopLive ? "bg-[#F2F3F4]" : "bg-tertiary-blue-70"
          } px-14 py-5`,
          className,
        )}
      >
        <div className="flex flex-1 flex-row gap-4">
          <button
            className={`flex h-12 w-12 items-center justify-center rounded-full border-2 border-tint-light-200 ${
              isWorkshopLive ? "bg-[#000000]" : "bg-tertiary-blue-70"
            }`}
            onClick={() => setIsEditing(!isEditing)}
            disabled={isEditing && formIncomplete}
          >
            {!isEditing ? <EditIcon /> : <EditWorkshopIcon />}
          </button>
          <div className="mr-[25%] flex-1">
            {isEditing ? (
              <Input
                className="flex-1 bg-black bg-opacity-15 !text-white placeholder:text-white placeholder:text-opacity-30"
                placeholder={
                  !newTitle ? t("workshopEditor.untitledWorkshop") : undefined
                }
                value={newTitle}
                onChange={handleTitleChange}
                autoFocus
              />
            ) : (
              <div className="flex items-center gap-[10px]">
                <Heading2
                  className={`${
                    isWorkshopLive ? "text-neutral-900" : "text-white"
                  }`}
                >
                  {title}
                </Heading2>

                <WorkshopStatusBadge
                  status={getWorkshopBadge(
                    workshopData?.workshopStateUpdated
                      ?.workshop as workshopStatusType,
                  )}
                />
              </div>
            )}
            {isEditing ? (
              <div className="mt-[14px] flex items-center justify-between gap-8">
                <div className="flex items-center gap-[10px]">
                  <TimePicker
                    onSelect={setNewStartTime}
                    defaultTime={newStartTime || new Date(defaultStartTime)}
                    placeholder={placeholderStartTime}
                    light
                  />
                  <Base2 className="text-white">-</Base2>
                  <TimePicker
                    onSelect={setNewEndTime}
                    startTime={newStartTime}
                    defaultTime={newEndTime}
                    placeholder={placeholderEndTime}
                    light
                  />
                </div>
                <DatePicker
                  onSelect={setNewDate}
                  defaultValue={newDate}
                  format="E, MMM d"
                  contentClassName="flex-1 border-secondary-red-50"
                  light
                  disabledDate={(pickerDate) => {
                    // disable if date is before today;
                    return compareAsc(pickerDate, today) < 0;
                  }}
                  datePickerContainerClassName={
                    selectedInvalidWorkshopDate ? "border-secondary-red-50" : ""
                  }
                  showErrorOutline={selectedInvalidWorkshopDate}
                />
                <Button
                  variant="primaryDark"
                  onClick={handleSaveChanges}
                  disabled={formIncomplete || selectedInvalidWorkshopDate}
                >
                  {t("shared.saveChanges")}
                </Button>
              </div>
            ) : (
              <div className="opacity-80">
                <Caption1
                  className={`mr-4 inline-block ${
                    isWorkshopLive ? "text-neutral-800" : "text-white"
                  }`}
                >
                  {date}
                </Caption1>
                <Caption1
                  className={`inline-block ${
                    isWorkshopLive ? "text-neutral-800" : "text-white"
                  }`}
                >
                  {formattedStartTime}
                </Caption1>
                <ChevronDownIcon
                  fill={`${isWorkshopLive ? "#5f6468" : "white"}`}
                  className="inline-block h-4 -rotate-90"
                />
                <Caption1
                  className={`inline-block ${
                    isWorkshopLive ? "text-neutral-800" : "text-white"
                  }`}
                >
                  {formattedEndTime}
                </Caption1>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center gap-4">
          {participants && (
            <AvatarGroupOnline
              projectId={workshop.projectId.toString()}
              users={participants}
              extraUserCountBgColor="bg-white"
              liveMembers={false}
            />
          )}

          <Button
            variant={`${isWorkshopLive ? "outline" : "outlineLight"}`}
            size="regular"
            children={
              !participants || participants.length < 2
                ? t("workshopEditor.managePeople.addPeople")
                : t("workshopEditor.managePeople.managePeople")
            }
            hasFocusedRing={false}
            onClick={handleModal}
            disabled={formIncomplete}
          />

          <BackToRecoder
            onClick={backToRecoderHandler}
            className={`${
              !isWorkshopLive
                ? "border-tint-light-20 hover:bg-tint-light-20"
                : ""
            }`}
            fill={!isWorkshopLive ? "white" : ""}
          />

          {/* <Button
            variant="outlineLight"
            RightIcon={StarIcon}
            onClick={onStarClick}
            disabled={formIncomplete}
          /> */}
        </div>
      </div>
      <Modal
        className="h-[55vh] w-[41vw] flex-col gap-4 overflow-scroll rounded-xl"
        open={modalIsOpen}
        onClose={handleModal}
        children={
          <AddParticipantsModal
            closeModal={handleModal}
            workshopParticipants={
              workshop.workshopParticipants as WorkshopParticipant[]
            }
            workshopPendingInvitations={
              (workshop.pendingInvitations as ProjectInvitation[]) || []
            }
            projectId={workshop.projectId.toString()}
            workshopId={workshopId}
          />
        }
      />
      <ConfirmDialog
        open={showInvalidDateModal}
        onCancel={() => setShowInvalidDateModal(false)}
        onConfirm={handleUpdateProjectEndDate}
        title={t("component.invalidDateModal.title")}
        subtitle={
          hasAdminAccess
            ? t("component.invalidDateModal.subtitle")
            : t("component.invalidDateModal.subtitleAlt")
        }
        cancelButtonText={t("component.invalidDateModal.cancel")}
        confirmButtonText={
          hasAdminAccess ? t("component.invalidDateModal.confirm") : ""
        }
        confirmButtonVariant="outline"
      />
    </header>
  );
};

export default EditWorkshopHeader;
