import { useUpdateProjectEndDateMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation UpdateProjectEndDate($eventDate: ISO8601Date!, $projectId: ID!) {
    updateProjectEndDate(eventDate: $eventDate, projectId: $projectId) {
      id
      startDate
      position
      project {
        endDate
      }
    }
  }
`;

function useUpdateProjectEndDate() {
  const queryClient = useQueryClient();
  return useUpdateProjectEndDateMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["WorkshopById"]);
      await queryClient.invalidateQueries(["ProjectById"]);
      await queryClient.invalidateQueries(["subFilters"]);
    },
  });
}

export default useUpdateProjectEndDate;
