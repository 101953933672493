import clsx from "clsx";
import { Base2, Caption1 } from "components/Typography";
import { WorkshopParticipantRole } from "graphql/generated";
import { t } from "i18n-js";
import React from "react";

import { AgendaActivityComponent } from "./AgendaActivity.types";

/**
 * This subcomponent of the WorkshopSidebar component simply encapsulates the
 * visuals for rendering an individual Activity item in the Agenda.
 */
export const AgendaActivity: AgendaActivityComponent = (props) => {
  const {
    title,
    duration,
    className,
    userRole = WorkshopParticipantRole.Participant,
    activity,
    isActive = false,
    onClick,
  } = props;

  return (
    <div
      className={clsx(
        "flex w-full flex-row items-center border border-transparent py-[18px] pl-2 pr-4 transition-all duration-300",
        { "cursor-pointer": !!onClick },
        {
          "rounded-lg border border-neutral-40 bg-tint-dark-5":
            isActive === true,
        },
        className,
      )}
      onClick={() => onClick?.(props)}
    >
      <div className="flex w-full flex-col">
        <Base2>{title}</Base2>
        <Caption1 className="text-neutral-40">
          {getContentTypeI18n(activity.__typename)}
        </Caption1>
      </div>

      {(userRole === WorkshopParticipantRole.Facilitator ||
        userRole === WorkshopParticipantRole.Owner) && (
        <Caption1 className="whitespace-nowrap text-neutral-40">
          {duration} mins
        </Caption1>
      )}
    </div>
  );
};

const getContentTypeI18n = (
  activityTypename:
    | "Discussion"
    | "Resource"
    | "Breakout"
    | "Break"
    | "Poll"
    | undefined,
) => {
  let jsonKey = "";
  switch (activityTypename) {
    case "Breakout":
      jsonKey = "workshopEditor.types.breakout";
      break;
    case "Break":
      jsonKey = "workshopEditor.types.breakout";
      break;
    case "Discussion":
      jsonKey = "workshopEditor.types.discussion";
      break;
    case "Poll":
      jsonKey = "workshopEditor.types.poll";
      break;
    case "Resource":
      jsonKey = "workshopEditor.types.resource";
      break;
    default:
      jsonKey = "workshop.generalActivityType";
  }

  return t(jsonKey);
};
