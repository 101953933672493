import { User } from "graphql/generated";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import SubscriptionChannel from "utils/SubscriptionChannel";

import { ActionCableContext } from "./ActionCableContext";
import { useGetEnvsContext } from "./GetEnvsContext";

const ProjectPresenceChannelContext = createContext<User[]>([]);

type ProjectPresenceParams = {
  project_id: string;
};

type ProjectPresenceData = {
  user: User;
};

export function ProjectPresenceChannelProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const consumer = useContext(ActionCableContext);
  const [activeMembers, setActiveMembers] = useState<User[]>([]);
  const { projectId = "" } = useParams();
  const { currentUser } = useGetEnvsContext();

  if (consumer === undefined)
    throw new Error(
      "ProjectPresenceChannelProvider must be used within an ActionCableContextProvider",
    );

  const channel = useMemo(() => {
    if (!currentUser) return;
    return new SubscriptionChannel<ProjectPresenceParams, ProjectPresenceData>(
      consumer,
      "ProjectPresenceChannel",
      {
        project_id: projectId,
      },
    );
  }, [consumer, projectId, currentUser]);

  useEffect(() => {
    activeMembers.map((member) => {
      if (member.id == currentUser?.id) {
        member.firstName = currentUser?.firstName;
        (member.lastName = currentUser?.lastName),
          (member.pronouns = currentUser?.pronouns),
          (member.defaultProjectRole = currentUser?.defaultProjectRole);
      }
    });

    if (!channel || !currentUser) return;

    channel.onConnected(() => channel.perform("who_online"));

    channel.addReceiver("who-online", () => {
      channel.perform("present", currentUser);
    });

    channel.addReceiver("present", ({ user }) => {
      setActiveMembers((members) => {
        if (members.findIndex((member) => member.id === user.id) === -1) {
          return [...members, user];
        }

        return members;
      });
    });

    channel.addReceiver("absent", ({ user }) => {
      setActiveMembers((members) =>
        members.filter((member) => member.id.toString() !== user.id.toString()),
      );
    });
  }, [channel, currentUser, projectId]);

  return (
    <ProjectPresenceChannelContext.Provider value={activeMembers}>
      {children}
    </ProjectPresenceChannelContext.Provider>
  );
}

export function useActiveProjectMembers() {
  const context = useContext(ProjectPresenceChannelContext);
  if (context === undefined)
    throw new Error(
      "useActiveProjectMembers must be used within a ProjectPresenceChannelProvider",
    );

  return context;
}
