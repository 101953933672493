import React from "react";

import { ImageEmbedComponentType } from "./ImageEmbed.types";

/** Literally just the \<img\> component for now. Might grow later on. */
export const ImageEmbed: ImageEmbedComponentType = ({
  className,
  ...imgProps
}) => {
  return <img {...imgProps} className={className} />;
};
