import _ from "lodash";
import React, { useCallback, useMemo } from "react";

import { ScrollFade, ScrollFadeVariant } from "./ScrollFade";

/**
 * This hook encapsulates the logic needed to effectively use the ScrollFade component
 * in a scrollable div.
 * It returns a scroll handler, and two "fade" nodes.
 */
export const useScrollFades = () => {
  const [isScrolledTop, setIsScrollTop] = React.useState(true);
  const [isScrolledBottom, setIsScrollBottom] = React.useState(false);

  const onScrollContainerScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, clientHeight, scrollHeight } =
        e.target as HTMLDivElement;

      if (scrollTop === 0) {
        setIsScrollTop(true);
      } else if (scrollTop + clientHeight === scrollHeight) {
        setIsScrollBottom(true);
      } else {
        if (isScrolledTop) {
          setIsScrollTop(false);
        }
        if (isScrolledBottom) {
          setIsScrollBottom(false);
        }
      }
    },
    [setIsScrollTop, setIsScrollBottom, isScrolledTop, isScrolledBottom],
  );

  const topFade = useMemo(
    () =>
      !isScrolledTop ? (
        <ScrollFade variant={ScrollFadeVariant.Top} />
      ) : undefined,
    [isScrolledTop],
  );

  const bottomFade = useMemo(
    () =>
      !isScrolledBottom ? (
        <ScrollFade variant={ScrollFadeVariant.Bottom} />
      ) : undefined,
    [isScrolledBottom],
  );

  return {
    onScrollContainerScroll: _.throttle(onScrollContainerScroll, 500),
    topFade,
    bottomFade,
  };
};
