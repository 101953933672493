import { HStack, VStack } from "components/layout";
import CollabMetric from "components/pages/UserSettings/components/UserSettingsInsightsContent/components/UserSettingsInsightQuiz/CollabMetric";
import { Heading2, Paragraph1 } from "components/Typography";
import { t } from "i18n-js";
import { Info } from "phosphor-react";
import { useProjectTeamCollabMetrics } from "queries";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import CollaborationMetricsInfoModal from "./CollaborationMetricsInfoModal";

const TeamCollaborationMetrics = () => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const { projectId = "" } = useParams();
  const { teamCollabMetrics } = useProjectTeamCollabMetrics({ projectId });

  const collaborationMetrics =
    "component.userSettings.tabs.insights.sections.collaborationMetrics";
  const toggleInfoModal = () => setIsInfoModalOpen(!isInfoModalOpen);

  return (
    <div>
      <HStack align="center" justify="start" space={2} className="mb-[32px]">
        <VStack space={2}>
          <HStack space={3} align="center">
            <Heading2 className="text-neutral-900">
              {t(`${collaborationMetrics}.title`)}
            </Heading2>
            <Info
              size={20}
              color="#7F8489"
              className="cursor-pointer"
              onClick={toggleInfoModal}
            />
          </HStack>
          <Paragraph1 className="text-neutral-800">
            {t(`${collaborationMetrics}.subText`)}
          </Paragraph1>
        </VStack>
      </HStack>
      <HStack className="mt-[32px] gap-22">
        <CollabMetric
          title={t(`${collaborationMetrics}.authenticity.title`)}
          leftLabel={t(`${collaborationMetrics}.authenticity.leftLabel`)}
          rightLabel={t(`${collaborationMetrics}.authenticity.rightLabel`)}
          infoText={t(`${collaborationMetrics}.authenticity.description`)}
          userAverage={teamCollabMetrics?.genuine?.userValue || 0}
          teamAverage={teamCollabMetrics?.genuine?.teamValue || 0}
          maxAverage={teamCollabMetrics?.genuine?.highest || 0}
          minAverage={teamCollabMetrics?.genuine?.lowest || 0}
        />
        <CollabMetric
          title={t(`${collaborationMetrics}.opennessToChange.title`)}
          leftLabel={t(`${collaborationMetrics}.opennessToChange.leftLabel`)}
          rightLabel={t(`${collaborationMetrics}.opennessToChange.rightLabel`)}
          infoText={t(`${collaborationMetrics}.opennessToChange.description`)}
          userAverage={teamCollabMetrics?.openToChange?.userValue || 0}
          teamAverage={teamCollabMetrics?.openToChange?.teamValue || 0}
          maxAverage={teamCollabMetrics?.openToChange?.highest || 0}
          minAverage={teamCollabMetrics?.openToChange?.lowest || 0}
        />
      </HStack>
      <HStack className="mt-[32px] gap-22">
        <CollabMetric
          title={t(`${collaborationMetrics}.affiliation.title`)}
          leftLabel={t(`${collaborationMetrics}.affiliation.leftLabel`)}
          rightLabel={t(`${collaborationMetrics}.affiliation.rightLabel`)}
          infoText={t(`${collaborationMetrics}.affiliation.description`)}
          userAverage={teamCollabMetrics?.affiliation?.userValue || 0}
          teamAverage={teamCollabMetrics?.affiliation?.teamValue || 0}
          maxAverage={teamCollabMetrics?.affiliation?.highest || 0}
          minAverage={teamCollabMetrics?.affiliation?.lowest || 0}
        />
        <CollabMetric
          title={t(`${collaborationMetrics}.empathy.title`)}
          leftLabel={t(`${collaborationMetrics}.empathy.leftLabel`)}
          rightLabel={t(`${collaborationMetrics}.empathy.rightLabel`)}
          infoText={t(`${collaborationMetrics}.empathy.description`)}
          userAverage={teamCollabMetrics?.empathetic?.userValue || 0}
          teamAverage={teamCollabMetrics?.empathetic?.teamValue || 0}
          maxAverage={teamCollabMetrics?.empathetic?.highest || 0}
          minAverage={teamCollabMetrics?.empathetic?.lowest || 0}
        />
      </HStack>
      <CollaborationMetricsInfoModal
        isOpen={isInfoModalOpen}
        onClose={toggleInfoModal}
      />
    </div>
  );
};

export default TeamCollaborationMetrics;
