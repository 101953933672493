import axios, { AxiosResponse } from "axios";
import { CurrentUserQuery, User } from "graphql/generated";
import { useCurrentUser } from "queries";
import React, { createContext, useEffect } from "react";

type GetEnvsContextType = {
  envs?: EnvVars;
  currentUser?: CurrentUserQuery["currentUser"];
};

export interface EnvVars {
  APP_HOST: string;
  SOCKET_HOST: string;
  SLACK_CLIENT_ID: string;
  SENTRY_DSN: string;
  SHOW_WORKSHOP: string;
  SHOW_TEAM_VIEW: string;
  SHOW_LEAVE_WORKSHOP: string;
  SHOW_MUSIC: string;
  SHOW_MANUAL_OF_ME: string;
  USES_SECURE_CONNECTION: string;
  NODE_ENV: string;
  RAILS_ENV: string;
  JSON_LINK_API_KEY: string;
}

export interface EnvResponse {
  env_vars: EnvVars;
}

declare global {
  interface Window {
    env: { vars: EnvVars } | undefined;
  }
}

export const fetchEnvVars = async (): Promise<EnvVars | undefined> => {
  try {
    const response: AxiosResponse<EnvResponse> = await axios.get(
      "/api/v1/env_vars",
      {
        headers: {
          AllowOrigin: window.location.host,
        },
      },
    );
    window.env = {
      vars: response?.data?.env_vars,
    };
    return response?.data?.env_vars;
  } catch (error) {
    console.error(error);
  }
};

export const GetEnvsContext = createContext<GetEnvsContextType | undefined>(
  undefined,
);

export function GetEnvsProvider({
  children,
  value,
}: {
  children: React.ReactNode;
  value?: Partial<EnvVars> | Partial<User>;
}) {
  const [envs, setEnvs] = React.useState<EnvVars>(
    (value as EnvVars) || undefined,
  );
  const { data: currentUser } = useCurrentUser();

  useEffect(() => {
    const fetchData = async () => {
      const envVars = await fetchEnvVars();

      if (envVars) {
        setEnvs(envVars);
      }
    };

    currentUser?.currentUser?.id && void fetchData();
  }, [currentUser]);

  return (
    <GetEnvsContext.Provider
      value={{
        envs,
        currentUser: currentUser?.currentUser,
      }}
    >
      {children}
    </GetEnvsContext.Provider>
  );
}

export function useGetEnvsContext() {
  const context = React.useContext(GetEnvsContext);
  if (context === undefined) {
    throw new Error(
      "useGetEnvsContext must be used withing an GetEnvsProvider",
    );
  }
  return context;
}
