import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

function Overlay() {
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog.Overlay className="fixed inset-0 bg-[#222222] bg-opacity-80" />
    </Transition.Child>
  );
}

export default Overlay;
