import {
  GetAnswersForUserQuery,
  GetAnswersForUserQueryVariables,
  useGetAnswersForUserQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query getAnswersForUser($userId: ID!, $questionType: ProfileQuestionEnum!) {
    profileAnswersOfUser(questionType: $questionType, userId: $userId) {
      answers {
        id
        answer
        isActive
        profileQuestion {
          id
          question
        }
      }
      unansweredQuestions {
        id
        isActive
        question
      }
    }
  }
`;

function useUserProfileAnswersAndQuestions(
  variables: GetAnswersForUserQueryVariables,
): {
  profileAnswerData: GetAnswersForUserQuery["profileAnswersOfUser"];
} & UseQueryResult<GetAnswersForUserQuery, unknown> {
  const response = useGetAnswersForUserQuery(variables, { suspense: true });

  return {
    profileAnswerData: response.data?.profileAnswersOfUser,
    ...response,
  };
}

export { useUserProfileAnswersAndQuestions };
