import { EmbeddedLinks } from "components/CreateInteractions/CreateAnnouncementForm";
import {
  GeneralInformationForm,
  MilestonesForm,
  PhaseGoalsForm,
  PhasesForm,
  ProjectCreationSteps,
} from "components/pages";
import { Phase, UpdateProjectPhaseInput } from "graphql/generated";
import { t } from "i18n-js";
import { noop, omit } from "lodash";
import useChangeProjectStatus from "mutations/use-archive-project";
import useUpdateProject from "mutations/use-update-project";
import { useProject } from "queries";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { DefaultFieldsType, UpdateProjectFormInput } from "./types";
import { getDefaultFields, normalizePhases, removeEmptyPhases } from "./utils";

type EditProjectFormProps = {
  className?: string;
  currentStep?: number;
  setCurrentStep?: Dispatch<SetStateAction<number>>;
  onSubmitSuccess?: (editedProjectId: string) => void;
  onCancelForm?: () => void;
  editProjectId?: string;
};

const buildUpdatedProject = ({
  phases,
  ...projectInput
}: UpdateProjectFormInput) => ({
  ...projectInput,
  phasesAttributes: normalizePhases(phases) as UpdateProjectPhaseInput[],
});

function EditProjectForm({
  className,
  currentStep = ProjectCreationSteps.Details,
  setCurrentStep = noop,
  onSubmitSuccess = noop,
  editProjectId,
}: EditProjectFormProps) {
  let { projectId = "" } = useParams();
  projectId = editProjectId ? editProjectId : projectId;
  const { project } = useProject({ id: projectId });
  const [fields, setFields] = useState(getDefaultFields<DefaultFieldsType>());

  const [deletedPhaseIds, setDeletedPhaseIds] = useState<string[]>([]);

  const { mutateAsync } = useUpdateProject();
  const { mutateAsync: changeStatusMutateAsync } = useChangeProjectStatus();
  const queryClient = useQueryClient();
  useEffect(() => {
    setFields({
      name: project?.name,
      startDate: project?.startDate,
      goal: project?.goal,
      links: project?.links as EmbeddedLinks[],
      phases: project?.phases as Phase[],
    });
  }, [project]);

  const nextStep = () =>
    setCurrentStep((prevStep: number) =>
      prevStep > 0 && prevStep < Object.values(ProjectCreationSteps).length / 2
        ? prevStep + 1
        : prevStep,
    );

  const previousStep = () =>
    setCurrentStep((prevStep: number) =>
      prevStep > 1 ? prevStep - 1 : prevStep,
    );

  const submitForm = async (projectInput: UpdateProjectFormInput) => {
    try {
      const updatedProject = buildUpdatedProject(projectInput);
      // const deletedPhases = deletedPhaseIds.map((id) => ({
      //   id,
      //   _destroy: true,
      // }));
      // updatedProject.phasesAttributes.push(...deletedPhases);
      const { updateProject: editedProject } = await mutateAsync({
        project: {
          id: projectId,
          ...updatedProject,
        },
      });

      if (editedProject) {
        setFields(getDefaultFields());
        onSubmitSuccess(editedProject.id);
      }
    } catch {
      console.error(t("errors.somethingWentWrong"));
    }
  };

  switch (currentStep) {
    case ProjectCreationSteps.Details: {
      return (
        <GeneralInformationForm
          className={className}
          fields={fields}
          onSubmitSuccess={(newFields = {}) => {
            setFields((prevFields) => ({ ...prevFields, ...newFields }));
            nextStep();
          }}
        />
      );
    }
    case ProjectCreationSteps.Phases: {
      return (
        <PhasesForm
          className={className}
          fields={fields}
          isEditing
          previousStep={(newFields) => {
            setFields((prevFields) => ({ ...prevFields, ...newFields }));
            previousStep();
          }}
          onSubmitSuccess={(newFields) => {
            setFields((prevFields) => ({
              ...prevFields,
              ...newFields,
              phases: removeEmptyPhases(newFields?.phases || []),
            }));
            nextStep();
          }}
          onDeletePhase={(id: string) =>
            setDeletedPhaseIds((ids) => [...ids, id])
          }
          direction="edit"
        />
      );
    }
    case ProjectCreationSteps.PhaseGoals: {
      return (
        <PhaseGoalsForm
          className={className}
          fields={fields}
          previousStep={(newFields) => {
            setFields((prevFields) => ({ ...prevFields, ...newFields }));
            previousStep();
          }}
          onSubmitSuccess={(newFields) => {
            setFields((prevFields) => ({ ...prevFields, ...newFields }));
            nextStep();
          }}
          direction="edit"
        />
      );
    }
    case ProjectCreationSteps.Milestones: {
      return (
        <MilestonesForm
          className={className}
          fields={fields}
          previousStep={(newFields) => {
            setFields((prevFields) => ({ ...prevFields, ...newFields }));
            previousStep();
          }}
          onSubmitSuccess={
            (async (newFields) => {
              const newFilteredFields = omit(newFields, ["url"]);
              const filteredFields = omit(fields, ["url"]);
              await submitForm({
                ...filteredFields,
                ...newFilteredFields,
              } as UpdateProjectFormInput);
              await changeStatusMutateAsync({
                project: {
                  id: projectId,
                  status: 0,
                },
              });
              void queryClient.invalidateQueries(["OrganizationProjects"]);
              setFields((prevFields) => ({ ...prevFields, ...newFields }));
              nextStep();
            }) as (fields?: DefaultFieldsType) => void
          }
          isEditing
        />
      );
    }
    default: {
      return <></>;
    }
  }
}

export default EditProjectForm;
