import "./Blue.css";

import React from "react";
function SmallPieChartBlue() {
  return (
    <>
      <div className="absolute right-3 bottom-2.5 text-base font-semibold text-white">
        S
      </div>
      <div className="test bluepie-4">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
}
export default SmallPieChartBlue;
