/* eslint-disable graphql/template-strings */
import {
  GetProfileAnswerOfUserQuery,
  GetProfileAnswerOfUserQueryVariables,
  useGetProfileAnswerOfUserQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query GetProfileAnswerOfUser($profileQuestionId: ID!) {
    getProfileAnswerOfUser(profileQuestionId: $profileQuestionId) {
      id
      user {
        id
      }
      isActive
      answer
      profileQuestion {
        id
        question
        isActive
      }
    }
  }
`;

function useGetprofileAnswerOfuser(
  variables?: GetProfileAnswerOfUserQueryVariables,
): {
  getProfileAnswerOfUser?: GetProfileAnswerOfUserQuery["getProfileAnswerOfUser"];
} & UseQueryResult<GetProfileAnswerOfUserQuery, unknown> {
  const response = useGetProfileAnswerOfUserQuery(variables, {
    suspense: true,
    // refetchInterval: 1000,
  });
  return {
    getProfileAnswerOfUser: response.data?.getProfileAnswerOfUser || [],
    ...response,
  };
}

export { useGetprofileAnswerOfuser };
