/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Editor } from "@tiptap/core";
import clsx from "clsx";
import { HStack } from "components/layout/Stack";
import {
  BoldActiveIcon,
  BoldIcon,
  HeaderActiveIcon,
  HeaderIcon,
  LinkActiveIcon,
  LinkIcon,
  ListNumbersActiveIcon,
  ListNumbersIcon,
  UnorderedListActiveIcon,
  UnorderedListIcon,
} from "icons";
import React, { useState } from "react";

import Button from "../Button";
import LinkModal from "./LinkModal";

interface ToolBarProps {
  editor: Editor;
  isDisabled: boolean;
}

const ToolBar = ({ editor, isDisabled }: ToolBarProps) => {
  const [showLinkModal, setShowLinkModal] = useState(false);

  /**
   * Get index of beginning and end of selected text.
   * Return if index for start and end of selected text is the same,
   * if not trigger modal to open.
   */
  const openLinkModal = () => {
    const { from, to } = editor.state.selection;

    if (from === to) {
      return;
    }

    setShowLinkModal(true);
  };

  const closeLinkModal = () => setShowLinkModal(false);

  if (!editor) {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }

  const iconStyle =
    "rounded-full border-b-0 px-0.5 py-0.5 hover:bg-gray-50 active:bg-white active:rounded-full active:border-b-0";

  return (
    <HStack space={1}>
      <Button
        variant="link"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        disabled={isDisabled}
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={clsx(`${iconStyle}`, {
          "is-active": editor.isActive("heading"),
        })}
      >
        {editor.isActive("heading") ? <HeaderActiveIcon /> : <HeaderIcon />}
      </Button>

      <Button
        variant="link"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        disabled={isDisabled}
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`${editor.isActive("bold") ? "is-active" : ""} ${iconStyle}`}
      >
        {editor.isActive("bold") ? <BoldActiveIcon /> : <BoldIcon />}
      </Button>
      <Button
        variant="link"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        disabled={isDisabled}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`${
          editor.isActive("orderedList") ? "is-active" : ""
        } ${iconStyle}`}
      >
        {editor.isActive("orderedList") ? (
          <ListNumbersActiveIcon />
        ) : (
          <ListNumbersIcon />
        )}
      </Button>
      <Button
        variant="link"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        disabled={isDisabled}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`${
          editor.isActive("bulletList") ? "is-active" : ""
        } ${iconStyle}`}
      >
        {editor.isActive("bulletList") ? (
          <UnorderedListActiveIcon />
        ) : (
          <UnorderedListIcon />
        )}
      </Button>
      <Button
        variant="link"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        disabled={isDisabled}
        onClick={openLinkModal}
        className={`${editor.isActive("link") ? "is-active" : ""} ${iconStyle}`}
      >
        {editor.isActive("link") ? <LinkActiveIcon /> : <LinkIcon />}
      </Button>
      <LinkModal
        isOpen={showLinkModal}
        onClose={closeLinkModal}
        editor={editor}
      />
    </HStack>
  );
};

export default ToolBar;
