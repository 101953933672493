import { SummarySectionType } from "components/partial";
import { t } from "i18n-js";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { scroller } from "react-scroll";

export const SUMMARY_SCROLL_CONTAINER_ID = "summary-scroll-container";
export const SUMMARY_SCROLL_PROPS = {
  containerId: SUMMARY_SCROLL_CONTAINER_ID,
  duration: 500,
  smooth: "easeInOutQuart",
};
const SCROLL_DEBOUNCE_INTERVAL = 75;
const SCROLL_ACTIVE_OFFSET = 115;

/**
 * This helper function helps in figuring out which of the concrete anchors within
 * the page is closest to the current scroll position.
 */
export const getLeastDifferenceIndex = (
  place: number,
  options: Array<number | undefined>,
) => {
  let leastIndex = 0;
  let leastValue: number | undefined;

  // I want to use the index here and the suggested replacement using
  // `for ... of options.entries()` feels inelegant to me. Hence the eslint-disable.
  // eslint-disable-next-line unicorn/no-array-for-each
  options.forEach((option, index) => {
    if (option === undefined) {
      return;
    }
    const currentDifference = Math.abs(place - option);
    if (leastValue ? currentDifference <= leastValue : true) {
      leastIndex = index;
      leastValue = currentDifference;
    }
  });

  return leastIndex;
};

export type StatelessSection = Omit<SummarySectionType, "status">;

export const DESCRIPTION_SECTION = "workshop-description";
export const NEXT_STEPS_SECTION = "workshop-summary-and-next-steps";
export const RECORDING_SECTION = "workshop-recording-and-transcript";
export const DATA_SECTION = "workshop-data-insights";
export const RESOURCES_SECTION = "workshop-resources";

/**
 * SUMMARY_SECTIONS associates the section ids with the translated section titles.
 */
export const SUMMARY_SECTIONS: StatelessSection[] = [
  DESCRIPTION_SECTION,
  NEXT_STEPS_SECTION,
  RECORDING_SECTION,
  DATA_SECTION,
  RESOURCES_SECTION,
].map((section) => ({
  title: t(`workshop.summary.section.${section}`),
  id: section,
}));

/**
 * This hook encapsulates the logic for keeping the SummarySidebar's anchor links
 * up-to-date with the active div based on the scroll state in the workshop summary.
 */
export const useSummaryScroll = (validatedSections: SummarySectionType[]) => {
  const descRef = useRef<HTMLDivElement>(null);
  const nextStepsRef = useRef<HTMLDivElement>(null);
  const recordingRef = useRef<HTMLDivElement>(null);
  const dataInsightsRef = useRef<HTMLDivElement>(null);
  const resourcesRef = useRef<HTMLDivElement>(null);

  const [activeSection, setActiveSection] = useState<
    SummarySectionType | undefined
  >();

  /* HANDLERS FOR SCROLL PARENT*/
  // Need to debounce so there aren't constant setStates flying around.
  const debouncedSetActiveSection = _.debounce(
    (val: SummarySectionType) => setActiveSection(val),
    SCROLL_DEBOUNCE_INTERVAL,
  );
  const handleMainScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    const leastDifferenceIndex = getLeastDifferenceIndex(
      /**
       * This offset is used to make the determination of which anchor is "active"
       * closer to human expectations, which don't necessarily align with:
       * "which is closest to the exact top of the div".
       */
      e.currentTarget.scrollTop + SCROLL_ACTIVE_OFFSET,
      [
        !!descRef.current ? descRef.current.offsetTop : undefined,
        !!nextStepsRef.current ? nextStepsRef.current.offsetTop : undefined,
        !!recordingRef.current ? recordingRef.current.offsetTop : undefined,
        !!dataInsightsRef.current
          ? dataInsightsRef.current.offsetTop
          : undefined,
        !!resourcesRef.current ? resourcesRef.current.offsetTop : undefined,
      ] as Array<number | undefined>,
    );
    debouncedSetActiveSection(validatedSections[leastDifferenceIndex]);
  };

  /* HANDLERS FOR FOOTER */
  /**
   * General helper function which pack the logic of incrementing / decrementing
   * a position in a finite list of items.
   */
  const handleStepSection = (direction: "INCREMENT" | "DECREMENT") => {
    const currentIndex = validatedSections.findIndex(
      (section) => section.id === activeSection?.id,
    );

    switch (direction) {
      case "DECREMENT":
        if (currentIndex <= 0) {
          return;
        } else {
          scroller.scrollTo(
            validatedSections[currentIndex - 1].id,
            SUMMARY_SCROLL_PROPS,
          );
          setActiveSection(validatedSections[currentIndex - 1]);
        }
        break;

      case "INCREMENT":
        if (currentIndex >= validatedSections.length - 1) {
          return;
        } else {
          scroller.scrollTo(
            validatedSections[currentIndex + 1].id,
            SUMMARY_SCROLL_PROPS,
          );
          setActiveSection(validatedSections[currentIndex + 1]);
        }
        break;
    }
  };
  const handleToNextSection = () => handleStepSection("INCREMENT");
  const handleToPrevSection = () => handleStepSection("DECREMENT");

  return {
    activeSection,
    handleMainScroll,
    handleToNextSection,
    handleToPrevSection,
    descRef,
    nextStepsRef,
    recordingRef,
    dataInsightsRef,
    resourcesRef,
  };
};
