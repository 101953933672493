import { useWeekFeedQuery, WeekFeedQueryVariables } from "graphql/generated";
import { gql } from "graphql-request";
gql`
  query WeekFeed($projectId: ID!, $dateInWeek: ISO8601Date!) {
    weekFeed(projectId: $projectId, dateInWeek: $dateInWeek) {
      __typename
      ... on Announcement {
        id
        announcementTitle
        description
        links {
          id
          title
          url
          preview
        }
        isHighlighted
        isSchedule
        scheduleTime
        createdAt
        author {
          id
          firstName
          lastName
          fullName
          avatarUrl
        }
        isNewComment
      }
      ... on Meeting {
        id
        uid
        title
        meetingSource
        meetingDescription: description
        startTime
        durationInMinutes
        joinUrl
        host {
          id
          firstName
          lastName
          fullName
          avatarUrl
        }
        links {
          id
          title
          url
          preview
        }
        durationInMinutes
        createdAt
        invitees {
          id
          firstName
          lastName
          fullName
          avatarUrl
        }
        isHighlighted
      }
      ... on Task {
        id
        taskTitle: title
        description
        createdAt
        isCompleted
        isHighlighted
        createdBy {
          id
          firstName
          lastName
          fullName
          avatarUrl
        }
        dueDate
        project {
          id
        }
        assignees {
          id
          firstName
          lastName
          fullName
          avatarUrl
        }
        links {
          id
          title
          url
          preview
        }
        isNewComment
        tasksAssignees {
          assigneeId
          assignee {
            avatarUrl
            firstName
            lastName
            fullName
            id
          }
          createdAt
          id
          isCompleted
          updatedAt
        }
      }
      ... on TemperatureResponse {
        isVisible
        weekDate
      }
      ... on Workshop {
        id
        workshopTitle: title
        workshopDescription: description
        startTime
        endTime
        createdAt
        state
        highlight
        links {
          extension
          id
          preview
          title
          url
        }
        documents {
          id
          filename
          url
          contentType
        }
        workshopParticipants {
          role
          user {
            avatarUrl
            id
            firstName
            lastName
            fullName
          }
        }
      }
    }
  }
`;
function useWeekFeed(variables: WeekFeedQueryVariables) {
  const response = useWeekFeedQuery(variables, {
    suspense: true,
  });
  const { weekFeed = [] } = response.data || {};
  return { weekFeed, ...response };
}
export { useWeekFeed };
