import { Button } from "components/base";
import { HStack, VStack } from "components/layout/Stack";
import {
  Display5,
  Heading1Strong,
  Overline,
  Paragraph1,
} from "components/Typography";
import { useGetEnvsContext } from "contexts";
import { ProfileQuestionEnum } from "graphql/generated";
import { t } from "i18n-js";
import { Plus, X } from "phosphor-react";
import { useUserProfileAnswersAndQuestions } from "queries/use-user-profile-answers-and-questions";
import React, { useState } from "react";

import { ManualOfMeAnswer } from "./ManualOfMeAnswer";
import { ManualOfMeQuestion } from "./ManualOfMeQuestion";

export function UserSettingsManualOfMe() {
  const [questionsExpanded, setQuestionsExpanded] = useState(false);
  const { currentUser } = useGetEnvsContext();
  const { profileAnswerData: aboutMeData } = useUserProfileAnswersAndQuestions({
    userId: currentUser?.id,
    questionType: ProfileQuestionEnum.AboutMe,
  });

  const { profileAnswerData: workingStyleData } =
    useUserProfileAnswersAndQuestions({
      userId: currentUser?.id,
      questionType: ProfileQuestionEnum.WorkingStyle,
    });

  const aboutMeAnswers = aboutMeData?.answers || [];
  const aboutMeUnansweredQuestions = aboutMeData?.unansweredQuestions || [];

  const workingStyleAnswers = workingStyleData?.answers || [];
  const workingStyleUnansweredQuestions =
    workingStyleData?.unansweredQuestions || [];

  const answers = [...aboutMeAnswers, ...workingStyleAnswers];

  const toggleQuestionsExpanded = (questionsExpanded: boolean): void =>
    setQuestionsExpanded(!questionsExpanded);

  return (
    <div className="flex flex-col">
      <Heading1Strong className="text-neutral-90">
        {t(`component.userSettings.tabs.profile.sections.manualOfMe.title`)}
      </Heading1Strong>
      <Paragraph1 className="mt-2 mb-8 text-neutral-900">
        {t(
          `component.userSettings.tabs.profile.sections.manualOfMe.description`,
        )}
      </Paragraph1>

      {answers && (
        <div>
          {answers.map((answer) => (
            <ManualOfMeAnswer profileAnswer={answer} />
          ))}
        </div>
      )}

      <VStack space={4} className="rounded-xl bg-neutral-0 p-6">
        <HStack justify="between" align="center">
          <Display5 className="text-neutral-90">
            {t(
              "component.userSettings.tabs.profile.sections.manualOfMe.questionsList.title",
            )}
          </Display5>
          {(aboutMeUnansweredQuestions.length > 0 ||
            workingStyleUnansweredQuestions.length > 0) && (
            <Button
              data-testid="toggle-manual-of-me-questions-expansion"
              onClick={() => toggleQuestionsExpanded(questionsExpanded)}
              variant="outline"
              LeftIcon={questionsExpanded ? X : Plus}
            />
          )}
        </HStack>
        {questionsExpanded && (
          <div>
            {aboutMeUnansweredQuestions.length > 0 && (
              <Overline className="mt-5 text-neutral-600">
                {t(
                  "component.userSettings.tabs.profile.sections.manualOfMe.questionsList.aboutMe",
                )}
              </Overline>
            )}
            {aboutMeUnansweredQuestions.map((profileQuestion) => (
              <ManualOfMeQuestion
                key={profileQuestion.id}
                profileQuestion={profileQuestion}
              />
            ))}
            {workingStyleUnansweredQuestions.length > 0 && (
              <Overline className="mt-10 text-neutral-600">
                {t(
                  "component.userSettings.tabs.profile.sections.manualOfMe.questionsList.workingStyles",
                )}
              </Overline>
            )}
            {workingStyleUnansweredQuestions.map((profileQuestion) => (
              <ManualOfMeQuestion
                key={profileQuestion.id}
                profileQuestion={profileQuestion}
              />
            ))}
          </div>
        )}
      </VStack>
    </div>
  );
}
