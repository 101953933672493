import { Button } from "components/base";
import { VStack } from "components/layout/Stack";
import { Base2, Display4 } from "components/Typography";
import { useModal } from "hooks";
import { useLogout } from "hooks/mutations";
import { t } from "i18n-js";
import { Power } from "phosphor-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export function UserSettingsProfileLogOut() {
  const {
    isOpen,
    openModal,
    closeModal,
    Modal: ConfirmationModal,
  } = useModal();

  const { mutate: logout } = useLogout();
  const navigate = useNavigate();

  const handleLogOut = () => {
    logout({});
    navigate("/login", { state: {} });
  };

  return (
    <VStack>
      <button
        className="flex flex-row items-center bg-secondary-red-700 p-5 pl-14 text-white"
        data-testid={`user-settings-logout-button`}
        onClick={openModal}
      >
        <Power size={24} color="currentColor" className="mr-4" />
        <Base2>
          {t(`component.userSettings.tabs.profile.sections.logOut.button`)}
        </Base2>
      </button>

      <ConfirmationModal open={isOpen} onClose={closeModal}>
        <VStack className="gap-8 p-8">
          <Display4 className="text-neutral-90">
            {t(
              `component.userSettings.tabs.profile.sections.logOut.modal.message`,
            )}
          </Display4>

          <VStack className="gap-4">
            <Button onClick={closeModal}>
              {t(
                `component.userSettings.tabs.profile.sections.logOut.modal.decline`,
              )}
            </Button>
            <Button onClick={handleLogOut} variant="outline">
              {t(
                `component.userSettings.tabs.profile.sections.logOut.modal.accept`,
              )}
            </Button>
          </VStack>
        </VStack>
      </ConfirmationModal>
    </VStack>
  );
}
