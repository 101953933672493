import { Button } from "components/base";
import { Modal } from "components/partial";
import { Heading1Strong, Paragraph1 } from "components/Typography";
import { t } from "i18n-js";
import { X } from "phosphor-react";
import React from "react";

import imageAsset from "./crj-disc-axis.png";
import { ModalProps } from "./LanguageCohesionInfoModal";

export const PersonalityProfileInfoModal = ({
  isOpen,
  setIsOpen,
}: ModalProps) => {
  return (
    <Modal
      className="h-min max-h-112 w-235 overflow-scroll rounded-xl"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      children={
        <div className="flex flex-col gap-6 p-10">
          <div className="flex items-center justify-between">
            <Heading1Strong>
              {t(
                "component.userSettings.tabs.insights.modal.personalityProfile.title",
              )}
            </Heading1Strong>
            <Button
              variant="tertiary"
              RightIcon={X}
              onClick={() => setIsOpen(false)}
            />
          </div>

          <Paragraph1 className="text-neutral-90">
            {t(
              "component.userSettings.tabs.insights.modal.personalityProfile.description",
            )}
          </Paragraph1>

          <div className="flex justify-between">
            <Paragraph1 className="w-1/2 text-neutral-90">
              {t(
                "component.userSettings.tabs.insights.modal.personalityProfile.copy1",
              )}
            </Paragraph1>

            <img
              className="w-1/2"
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              src={imageAsset}
              alt={t(
                "component.userSettings.tabs.insights.modal.personalityProfile.imageAltText",
              )}
            />
          </div>

          <Paragraph1 className="text-neutral-90">
            {t(
              "component.userSettings.tabs.insights.modal.personalityProfile.copy2",
            )}
          </Paragraph1>

          <ul className="list-disc">
            {["d", "i", "s", "c"].map((letter) => (
              <Paragraph1
                className="ml-4 mb-4 text-neutral-90 last:mb-0"
                as="li"
              >
                {t(
                  `component.userSettings.tabs.insights.modal.personalityProfile.acronym.${letter}`,
                )}
              </Paragraph1>
            ))}
          </ul>

          <Paragraph1 className="text-neutral-90">
            {t(
              "component.userSettings.tabs.insights.modal.personalityProfile.copy3",
            )}
          </Paragraph1>

          <Paragraph1 className="text-neutral-90">
            {t(
              "component.userSettings.tabs.insights.modal.personalityProfile.source",
            )}{" "}
            <a
              href="https://www.discprofile.com/what-is-disc/How-DiSC-Works"
              target="_blank"
              rel="noopener noreferrer"
            >
              discprofile.com/what-is-disc/How-DiSC-Works
            </a>
          </Paragraph1>
        </div>
      }
    />
  );
};
