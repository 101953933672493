import { Popover, Transition } from "@headlessui/react";
import { JSONContent } from "@tiptap/core";
import clsx from "clsx";
import { Button } from "components/base";
import { Base2 } from "components/Typography";
import { Agenda, WorkshopByIdQuery } from "graphql/generated";
import { t } from "i18n-js";
import { NotesIcon } from "icons";
import React from "react";
import {
  getActivityInAgendaByIndex,
  getCurrentWorkshopActivity,
  getFlattenedWorkshopActivities,
  getIndexOfActivityInAgenda,
} from "utils";

import { AgendaPagination } from "./AgendaPagination";
import { FacilitationNotes } from "./FacilitationNotes";
import { WorkshopFooterComponent } from "./WorkshopFooter.types";

export const WorkshopFooter: WorkshopFooterComponent = ({ agenda }) => {
  const { prev, current, next } = getFooterActivities(agenda);

  const notes = current?.facilitatorNote as JSONContent | undefined;

  return (
    <Popover>
      <Transition
        enter="transition transition-all duration-200 ease-in-out"
        enterFrom="max-h-0"
        enterTo="max-h-72"
        leave="transition transition-all duration-200 ease-out"
        leaveFrom="max-h-72"
        leaveTo="max-h-0"
      >
        {notes && (
          <Popover.Panel className="w-full">
            {({ close }) => <FacilitationNotes onClose={close} notes={notes} />}
          </Popover.Panel>
        )}
      </Transition>

      <div className="relative flex items-center justify-between bg-neutral-90 py-5 pl-14 pr-12">
        <AgendaPagination
          prev={prev}
          current={current}
          next={next}
          activityLength={current?.duration || 0}
        />
        <Popover.Button as="div" className="outline-none">
          {({ open }) => (
            <Button
              variant="outlineLight"
              className={clsx("select-none", {
                "!bg-white": open,
              })}
              disabled={!notes}
            >
              <Base2
                className={clsx("inline-block", { "text-neutral-900": open })}
              >
                {t("workshop.facilitationNotes")}
              </Base2>
              <NotesIcon
                className="ml-4 inline-block"
                fill={open ? "#292D30" : "#fff"}
              />
            </Button>
          )}
        </Popover.Button>
      </div>
    </Popover>
  );
};

/**
 * This function encapsulates the logic for grabbing the current, previous, and
 * next WorkshopActivities.
 * We have to be careful so that when the current activity is either `undefined`
 * or the first/last activity in the Agenda, that we handle the values of the
 * "prev" and "next" activities appropriately.
 */
export const getFooterActivities = (
  agenda: WorkshopByIdQuery["workshopById"]["agenda"] | undefined,
) => {
  const current = getCurrentWorkshopActivity(agenda);
  let prev, next;

  if (current) {
    const currentIndex = getIndexOfActivityInAgenda(
      current,
      agenda as Agenda[],
    );
    const agendaLen =
      getFlattenedWorkshopActivities(agenda as Agenda[])?.length || 0;

    if (currentIndex > 0) {
      prev = getActivityInAgendaByIndex(agenda as Agenda[], currentIndex - 1);
    }

    if (currentIndex < agendaLen - 1) {
      next = getActivityInAgendaByIndex(agenda as Agenda[], currentIndex + 1);
    }
  } else if (!current && agenda && agenda.length > 0) {
    next = getActivityInAgendaByIndex(agenda as Agenda[], 0);
  }

  return {
    prev,
    current,
    next,
  };
};
