import { useCreatePollAnswerMutation } from "graphql/generated";
import { gql } from "graphql-request";

gql`
  mutation CreatePollAnswer($pollAnswer: CreatePollAnswerInput!) {
    createPollAnswer(pollAnswer: $pollAnswer) {
      id
    }
  }
`;

export function useCreatePollAnswer() {
  return useCreatePollAnswerMutation();
}
