import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { Button, Dropdown } from "components/base";
import { HStack, VStack } from "components/layout";
import { Base1Strong, Caption1, Overline } from "components/Typography";
import { t } from "i18n-js";
import { useUpdateWorkshop } from "mutations/workshop";
import { Check, Gear } from "phosphor-react";
import { useWorkshop } from "queries";
import React from "react";
import { Z_INDEX_LEVELS } from "utils";

interface AgendaVisibilitySelectorProps {
  workshopId: string;
}

type AgendaVisibilitySelectorComponent =
  React.FC<AgendaVisibilitySelectorProps>;

const AgendaVisibilitySelector: AgendaVisibilitySelectorComponent = ({
  workshopId,
}) => {
  const { workshop: { isAgendaVisibleToParticipants } = {} } = useWorkshop({
    id: workshopId,
  });
  const { mutate: updateWorkshop } = useUpdateWorkshop();

  const updateWorkshopVisibility = (isVisible: boolean) => {
    updateWorkshop({
      workshop: {
        id: workshopId,
        isAgendaVisibleToParticipants: isVisible,
      },
    });
  };

  return (
    <>
      <div className="flex flex-col justify-center gap-1">
        <Overline>{t("workshopEditor.agendaVisibility.title")}</Overline>
        <Caption1 className="text-neutral-60">
          {isAgendaVisibleToParticipants
            ? t("workshopEditor.agendaVisibility.isVisible")
            : t("workshopEditor.agendaVisibility.isNotVisible")}
        </Caption1>
      </div>
      <Dropdown
        button={
          <Menu.Button data-testid="access-dropdown-button" className="w-full">
            {({ open }) => {
              return (
                <div className={clsx("rounded-lg", open && "bg-transparent")}>
                  <Button variant="tertiary" className="!p-4">
                    <Gear size="18px" />
                  </Button>
                </div>
              );
            }}
          </Menu.Button>
        }
        items={
          <Menu.Items
            as="span"
            className={clsx(
              "absolute top-full -right-2 rounded-xl bg-white py-9 pl-5 pr-11 shadow-lg",
              Z_INDEX_LEVELS.MODAL_CONTROL,
            )}
            data-testid="access-dropdown"
          >
            <VStack space={7}>
              <Menu.Item
                as="button"
                onClick={() => updateWorkshopVisibility(true)}
              >
                <HStack space={2}>
                  <Check
                    size={24}
                    className={clsx(
                      isAgendaVisibleToParticipants
                        ? "text-neutral-90"
                        : "text-transparent",
                    )}
                  />
                  <VStack align="start" space={2}>
                    <Base1Strong
                      className="whitespace-nowrap text-neutral-90"
                      data-testid="access-option-label"
                    >
                      {t(
                        "workshopEditor.agendaVisibility.showAgendaButtonTitle",
                      )}
                    </Base1Strong>
                    <Caption1 className="whitespace-nowrap text-neutral-60">
                      {t(
                        "workshopEditor.agendaVisibility.showAgendaButtonSubtitle",
                      )}
                    </Caption1>
                  </VStack>
                </HStack>
              </Menu.Item>
              <Menu.Item
                as="button"
                onClick={() => updateWorkshopVisibility(false)}
              >
                <HStack space={2}>
                  <Check
                    size={24}
                    className={clsx(
                      !isAgendaVisibleToParticipants
                        ? "text-neutral-90"
                        : "text-transparent",
                    )}
                  />
                  <VStack align="start" space={2}>
                    <Base1Strong
                      className="whitespace-nowrap text-neutral-90"
                      data-testid="access-option-label"
                    >
                      {t(
                        "workshopEditor.agendaVisibility.hideAgendaButtonTitle",
                      )}
                    </Base1Strong>
                    <Caption1 className="whitespace-nowrap text-neutral-60">
                      {t(
                        "workshopEditor.agendaVisibility.hideAgendaButtonSubtitle",
                      )}
                    </Caption1>
                  </VStack>
                </HStack>
              </Menu.Item>
            </VStack>
          </Menu.Items>
        }
      />
    </>
  );
};

export default AgendaVisibilitySelector;
