import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Display4, HStack, VStack } from "components";
import { Button } from "components/base";
import { InviteUsersProvider, useInviteUsers } from "contexts";
import { t } from "i18n-js";
import React, { Fragment, Suspense, useRef } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import { InviteProjectMembersHeader, MembersList } from ".";

type HasInitialFocusButton = {
  initialFocusRef: React.RefObject<HTMLButtonElement>;
};

interface DefaultHeaderProps extends HasInitialFocusButton {
  canInvite?: boolean;
}

type ShareModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function ShareModal({
  isOpen,
  closeModal,
}: ShareModalProps): JSX.Element {
  const initialFocusRef = useRef<HTMLButtonElement>(null);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        onClose={closeModal}
        className={clsx("fixed inset-0 overflow-y-auto", Z_INDEX_LEVELS.MODAL)}
        initialFocus={initialFocusRef}
      >
        <ShareModalOverlay />
        <ShareModalSidebar
          initialFocusRef={initialFocusRef}
          closeModal={closeModal}
        />
      </Dialog>
    </Transition>
  );
}

export function ShareModalOverlay() {
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog.Overlay className="fixed inset-0 bg-[#222222] bg-opacity-30" />
    </Transition.Child>
  );
}

export function ShareModalSidebar({
  initialFocusRef,
  closeModal,
}: { closeModal: () => void } & HasInitialFocusButton) {
  return (
    <Transition.Child
      as="div"
      className="absolute right-0 top-0 flex h-screen w-1/2 flex-col bg-white shadow-2xl"
      enter="ease-out duration-500"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="ease-in duration-300"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
    >
      <InviteUsersProvider>
        <ShareModalHeader initialFocusRef={initialFocusRef} />
        <ShareModalContent />
        <ShareModalFooter closeModal={closeModal} />
      </InviteUsersProvider>
    </Transition.Child>
  );
}

export function ShareModalHeader({ initialFocusRef }: HasInitialFocusButton) {
  const { isInInviteMode } = useInviteUsers();
  return (
    <Dialog.Title
      as="div"
      className={clsx(
        "min-h-[104px] px-14 pb-6 pt-8",
        isInInviteMode && "shadow-lg",
      )}
    >
      {isInInviteMode ? (
        <InviteProjectMembersHeader />
      ) : (
        <DefaultHeader initialFocusRef={initialFocusRef} />
      )}
    </Dialog.Title>
  );
}

export function DefaultHeader({
  initialFocusRef,
  canInvite = true,
}: DefaultHeaderProps) {
  const { setIsInInviteMode } = useInviteUsers();
  return (
    <HStack justify="between" align="center">
      <Display4 className="text-neutral-90 ">{t(`team.people.title`)}</Display4>
      {canInvite && (
        <Button
          ref={initialFocusRef}
          onClick={() => setIsInInviteMode(true)}
          className="focus:!outline-0 focus:!ring-0 focus:!ring-offset-0"
        >
          {t("component.shareModal.invite")}
        </Button>
      )}
    </HStack>
  );
}

export function ShareModalContent() {
  const { isInInviteMode } = useInviteUsers();

  return (
    <VStack className="flex-1 overflow-auto pb-6">
      <Suspense fallback={<></>}>
        {isInInviteMode ? (
          <MembersList.SuggestedInvitees />
        ) : (
          <>
            <MembersList.PendingInvitations />
            <MembersList.ProjectMembers />
          </>
        )}
      </Suspense>
    </VStack>
  );
}

export function ShareModalFooter({ closeModal }: { closeModal: () => void }) {
  return (
    <HStack justify="end" align="center" className="h-22 pr-14 shadow-t-lg">
      <Button onClick={closeModal} variant="secondary">
        {t("shared.done")}
      </Button>
    </HStack>
  );
}
