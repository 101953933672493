import { Display3, HStack, VStack } from "components";
import { Base1Strong, Base2, Base2Strong } from "components/Typography";
import { OrganizationMembershipAccessLevel, Scalars } from "graphql/generated";
import { t } from "i18n-js";
import { sortBy } from "lodash";
import { CaretRight } from "phosphor-react";
import { useOrganizationMemberships } from "queries";
import React, { useState } from "react";

import MemberDetails from "./MemberDetails";
import SettingsMenuCloseButton from "./SettingsMenuCloseButton";
import { ProjectAssignments } from "./WorkspaceProjectsContent";

const ACCESS_LEVELS_LABELS = {
  [OrganizationMembershipAccessLevel.AdminAccess]: t("shared.admin"),
  [OrganizationMembershipAccessLevel.MemberAccess]: t("shared.member"),
};

export enum WORKSPACE_CONTENT_TYPE {
  MEMBERS = "Members",
  PROJECTS = "Projects",
  SETTINGS = "Settings",
}

export enum MEMBERS_INVITATION_STATE {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
}

function WorkspaceMembersContent({
  organizationId,
  onClose,
}: {
  organizationId: Scalars["ID"];
  onClose: () => void;
}) {
  const { memberships } = useOrganizationMemberships({ organizationId });
  const sortedMemberships = sortBy(
    memberships,
    ({ accessLevel, user: { fullName } }) => [
      accessLevel,
      fullName.toLowerCase(),
    ],
  );

  const [activeMember, setActiveMember] = useState<ProjectAssignments>();

  if (!!activeMember) {
    return (
      <MemberDetails.ActiveMemberDetails
        member={activeMember}
        onClose={() => setActiveMember(undefined)}
        onModalClose={onClose}
        from={WORKSPACE_CONTENT_TYPE.MEMBERS}
      />
    );
  }

  return (
    <VStack
      className="grow overflow-y-auto p-10"
      space={10}
      data-testid="settings-workspace-members"
    >
      <HStack justify="between">
        <Display3>{t("component.settings.tabs.members.title")}</Display3>
        <SettingsMenuCloseButton onClose={onClose} />
      </HStack>
      <VStack className="mt-10 border-b">
        <HStack justify="between" className="mt-10 text-primary-turquoise-70">
          <Base2Strong>{t("shared.user")}</Base2Strong>
          <Base2Strong>{t("shared.role")}</Base2Strong>

          <div className="w-6" />
        </HStack>
        <VStack className="divide-y">
          {sortedMemberships.map(
            ({
              id,
              user: { fullName, email, defaultProjectRole },
              accessLevel,
            }) => (
              <HStack
                key={id}
                justify="between"
                className="pt-6 pb-4"
                data-testid={`workspace-member`}
                onClick={() =>
                  setActiveMember({
                    id,
                    user: { fullName, email, defaultProjectRole },
                    accessLevel,
                  })
                }
              >
                <VStack space={2} className="flex-1">
                  <Base1Strong>{fullName}</Base1Strong>
                  <Base2 className="text-neutral-70">{email}</Base2>
                </VStack>
                <Base1Strong className="flex-1">
                  {ACCESS_LEVELS_LABELS[accessLevel]}
                </Base1Strong>
                <CaretRight size={24} className="self-center" />
              </HStack>
            ),
          )}
        </VStack>
      </VStack>
    </VStack>
  );
}

export default WorkspaceMembersContent;
