import clsx from "clsx";
import { Button } from "components/base";
import { VStack } from "components/layout/Stack";
import { Caption2 } from "components/Typography";
import { MilestoneTypeEnum } from "graphql/generated";
import React from "react";

type MilestonesFormMilestoneTypeProps = {
  unselectedIcon: React.ReactNode;
  selectedIcon: React.ReactNode;
  title: string;
  value: MilestoneTypeEnum;
  isSelected: boolean;
  onSelect: (milestoneType: MilestoneTypeEnum) => void;
};

export default function MilestonesFormMilestoneType({
  unselectedIcon,
  selectedIcon,
  isSelected,
  title,
  value,
  onSelect,
}: MilestonesFormMilestoneTypeProps) {
  const handleClick = () => {
    onSelect(value);
  };

  return (
    <Button
      variant="link"
      onClick={handleClick}
      type="button"
      hasFocusedRing={false}
    >
      <VStack
        space={2}
        align="center"
        justify="center"
        className={clsx(
          "h-[96px] w-[72px] px-2 py-4",
          isSelected &&
            "rounded-lg border-2 border-primary-turquoise-50 bg-neutral-0",
        )}
      >
        {isSelected ? selectedIcon : unselectedIcon}
        <Caption2>{title}</Caption2>
      </VStack>
    </Button>
  );
}
