import { useUpdateUserMetadatumMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";
gql`
  mutation UpdateUserMetadatum($input: UpdateUserMetadatumInput!) {
    updateUserMetadatum(input: $input) {
      id
      userId
      visibleToTeam
    }
  }
`;
function useUpdateUserMetadatum() {
  const queryClient = useQueryClient();
  return useUpdateUserMetadatumMutation({
    onSuccess: () =>
      queryClient.invalidateQueries(["getUserPersonalityProfile"]),
  });
}

export default useUpdateUserMetadatum;
