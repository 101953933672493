import React from "react";

function CreateTaskIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="16" cy="16" r="16" fill="#30CD74" />
      <path
        d="M19.4375 14.125L14.8541 18.5L12.5625 16.3125"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.25 9.125H9.75C9.40482 9.125 9.125 9.40482 9.125 9.75V22.25C9.125 22.5952 9.40482 22.875 9.75 22.875H22.25C22.5952 22.875 22.875 22.5952 22.875 22.25V9.75C22.875 9.40482 22.5952 9.125 22.25 9.125Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CreateTaskIcon;
