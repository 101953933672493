import { Button, Loader } from "components/base";
import { Caption1 } from "components/Typography";
import { MinusIcon, PlusIcon } from "icons";
import React, { useCallback, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

import { PDFEmbedComponent, PDFEmbedProps } from ".";

const BASE_SCALE = 100;
const SCALE_STEP = BASE_SCALE / 10;
const INCREMENT = "increment";
const DECREMENT = "decrement";

const PDFEmbed: PDFEmbedComponent = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState([]);
  const [scale, setScale] = useState(BASE_SCALE);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
  }, [fileUrl]);

  const onDocumentLoadSuccess = ({
    numPages: nextNumPages,
  }: {
    numPages: number;
  }) => {
    setIsLoading(false);

    setNumPages(Array.from({ length: nextNumPages }));
  };

  const handleScaleUpdate = useCallback(
    (operation: typeof INCREMENT | typeof DECREMENT) => {
      if (operation === INCREMENT) {
        setScale(scale + SCALE_STEP);
      } else if (operation === DECREMENT && scale > 0) {
        setScale(scale - SCALE_STEP);
      }
    },
    [scale, setScale],
  );

  return (
    <div className="relative flex h-full w-full overflow-hidden">
      <div className="flex h-full w-full flex-1 flex-col  items-center  overflow-auto bg-neutral-5">
        <div className="absolute bottom-4 right-4 z-10 flex max-w-[400px] items-center gap-2 rounded-lg bg-white p-2 drop-shadow-feedCard">
          <Button
            onClick={() => handleScaleUpdate(DECREMENT)}
            className="inline-block !h-8 !w-8 !p-0"
            variant="tertiary"
            size="small"
          >
            <MinusIcon className="w-3" />
          </Button>

          <Caption1 className=" w-8 text-center text-neutral-70">
            {scale}%
          </Caption1>

          <Button
            onClick={() => handleScaleUpdate(INCREMENT)}
            className="inline-block !h-8 !w-8 !p-0"
            variant="tertiary"
          >
            <PlusIcon className="w-4 text-neutral-90" />
          </Button>
        </div>
        <div className="w-full flex-1 overflow-hidden">
          {isLoading && (
            <div className="absolute flex h-full w-full items-center justify-center">
              <Loader />
            </div>
          )}
          <Document
            file={fileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(e) => console.error(e)}
            onSourceError={(e) => console.error(e)}
            className="absolute flex h-full w-full flex-col items-center overflow-auto text-transparent"
          >
            {numPages &&
              numPages.map((el, index) => (
                <Page
                  scale={scale / 100}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  className="mb-4 shadow-feedCard first:mt-20 [&>div]:hidden"
                  width={638}
                />
              ))}
          </Document>
        </div>
      </div>
    </div>
  );
};

const PDFHasUpdated = (prevProps: PDFEmbedProps, nextProps: PDFEmbedProps) => {
  return prevProps.fileUrl.split("?")[0] === nextProps.fileUrl.split("?")[0];
};

export default React.memo(PDFEmbed, PDFHasUpdated);
