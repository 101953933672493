import { Button } from "components/base";
import { useToast } from "components/provider";
import { Base2 } from "components/Typography";
import { t } from "i18n-js";
import { ArrowDown, ArrowUp } from "phosphor-react";
import { SendWorkshopNotification } from "queries";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { SectionStatus, SummarySectionType } from "../SummarySidebar";
import NotificationConfirmModal from "./NotificationConfirmModal";
export interface SummaryFooterProps {
  activeSection?: SummarySectionType;
  editflag: boolean;
  validatedSections: SummarySectionType[];
  onNextClick?: () => void;
  onPrevClick?: () => void;
}

export const SummaryFooter = ({
  activeSection,
  editflag,
  validatedSections,
  onNextClick,
  onPrevClick,
}: SummaryFooterProps) => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { id = "" } = useParams();
  SendWorkshopNotification({ id: id, mailType: "summary" });
  SendWorkshopNotification({ id: id, mailType: "description" });
  const queryClient = useQueryClient();
  //  SendWorkshopNotification({id: "2", mailType: "summary"})
  const [notifyenable, setnotifyenable] = useState(false);
  const [title, settitle] = useState(activeSection?.title);
  const [showNotificationConfirmModal, setShowNotificationConfirmModal] =
    useState<boolean>(false);
  const currentIndex = activeSection
    ? validatedSections.findIndex((section) => section.id === activeSection.id)
    : -1;

  const nextSection =
    currentIndex >= validatedSections.length - 1
      ? undefined
      : validatedSections[currentIndex + 1];

  const isValidSummary = validatedSections.every(
    (section) => section.status !== SectionStatus.ActionRequired,
  );

  useEffect(() => {
    if (activeSection?.title == "Workshop Description") {
      if (validatedSections[0].status == "ready") {
        setnotifyenable(true);
      }
      if (validatedSections[0].status == "action_required") {
        setnotifyenable(false);
      }
    }
    if (activeSection?.title == "Summary & Next Steps") {
      if (validatedSections[1].status == "ready") {
        setnotifyenable(true);
      }
      if (validatedSections[1].status == "action_required") {
        setnotifyenable(false);
      }
    }
    /* eslint-disable @typescript-eslint/no-unsafe-argument */
  }, [editflag][title]);

  const handleNotifyParticipants = () => {
    if (activeSection?.title == "Workshop Description") {
      void queryClient.refetchQueries([
        "sendWorkshopNotification",
        { id: id, mailType: "description" },
      ]);
    }
    if (activeSection?.title == "Summary & Next Steps") {
      void queryClient.refetchQueries([
        "sendWorkshopNotification",
        { id: id, mailType: "summary" },
      ]);
    }
    setShowNotificationConfirmModal(false);
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    addToast({
      message: `Participants notified of ${activeSection?.title} updates`,
      variant: "success",
      hasCloseOption: true,
    });
  };

  const handleViewSummary = () => {
    navigate("../");
  };

  return (
    <footer
      // TODO: fix white background -- might need changes from https://coeurajtech.atlassian.net/browse/R10-186 first
      className="h-19 absolute bottom-0 left-0 right-0 bg-white py-4 px-6 shadow-footer"
    >
      <div className="flex flex-row justify-between">
        <div className="flex gap-4">
          <Button
            variant="primary"
            disabled={!notifyenable}
            onClick={() => setShowNotificationConfirmModal(true)}
          >
            {t("workshop.summary.footer.notifyParticipants")}
          </Button>
          <Button variant="outline" onClick={handleViewSummary}>
            {t("workshop.summary.footer.viewSummary")}
          </Button>
        </div>

        <div className="flex">
          {nextSection && (
            <Base2 className="mr-6 self-center text-neutral-70">
              {t("workshop.summary.footer.next")}: {nextSection.title}
            </Base2>
          )}

          <Button
            variant="tertiary"
            RightIcon={ArrowUp}
            aria-label={`${t("workshop.summary.footer.previousSection")}: ${
              nextSection?.title || ""
            }`}
            className="mr-2"
            disabled={currentIndex <= 0}
            onClick={onPrevClick}
          />
          <Button
            variant="tertiary"
            RightIcon={ArrowDown}
            aria-label={`${t("workshop.summary.footer.nextSection")}: ${
              nextSection?.title || ""
            }`}
            disabled={!nextSection}
            onClick={onNextClick}
          />
        </div>
      </div>
      <NotificationConfirmModal
        open={showNotificationConfirmModal}
        onCancel={() => setShowNotificationConfirmModal(false)}
        onConfirm={handleNotifyParticipants}
      />
    </footer>
  );
};
