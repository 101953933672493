import { format } from "date-fns";

import { getIsSameAMPM } from "./getIsSameAMPM";

/**
 * @returns object: { startTime: "h:mm[aaa]", endTime: "h:mmaaa", date: "EEEE, MMM do"}
 * ex/ { startTime: "9:00", endTime: "11:30am", date: "Friday, Dec 9th"}
 *
 * Note that the startTime only includes the "am"/"pm" if the the startTime
 * and endTime aren't in the same half of the day.
 */
export const getFormattedWorkshopTimes = (
  startTime: string | null | undefined,
  endTime: string | null | undefined,
) => {
  const startDateTime = startTime ? new Date(startTime) : undefined;
  const endDateTime = endTime ? new Date(endTime) : undefined;
  const isSameAMPM = getIsSameAMPM(startDateTime, endDateTime);

  return {
    startTime: startDateTime
      ? format(startDateTime, isSameAMPM ? "h:mm" : "h:mmaaa")
      : undefined,
    endTime: endDateTime ? format(endDateTime, "h:mmaaa") : undefined,
    date: startDateTime ? format(startDateTime, "EEEE, MMM do") : undefined,
  };
};
