import { InteractionModal } from "components/partial";
import { useHighlightContext } from "contexts/HighlightContext";
import { Task } from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { Check } from "phosphor-react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { UpdateTaskForm } from ".";
import { INPUT_FIELDS } from "./UpdateTaskForm";

type UpdateTaskModalProps = {
  task: Partial<Task> & { taskTitle: string };
  isOpen: boolean;
  closeModal: () => void;
};

function UpdateTaskModal({
  task,
  isOpen,
  closeModal,
}: UpdateTaskModalProps): JSX.Element {
  const { isHighlighted, handleToggleHighlight } = useHighlightContext();
  const { projectId = "" } = useParams();
  const hasAdminAccess = useProjectAccessLevel({ projectId }).hasAdminAccess;

  useEffect(() => {
    handleToggleHighlight(!!task?.isHighlighted);
  }, []);

  const defaultTask = {
    [INPUT_FIELDS.id]: task?.id || "",
    [INPUT_FIELDS.title]: task?.taskTitle || "",
    [INPUT_FIELDS.description]: task?.description || "",
    [INPUT_FIELDS.assigneeIds]:
      task?.assignees?.map((assignee) => assignee.id) || [],
    [INPUT_FIELDS.dueDate]: task?.dueDate || "",
    [INPUT_FIELDS.links]: task?.links || [],
  };

  return (
    <InteractionModal
      isOpen={isOpen}
      closeModal={closeModal}
      header={t("component.updateTask.heading")}
      icon={<Check color="white" />}
      variant={"gray"}
      slideFromLeft
      showHighlightFeature={true}
      fromEditModal={true}
      isHighlighted={isHighlighted}
    >
      <UpdateTaskForm
        onClose={closeModal}
        task={defaultTask}
        userHasAdminAccess={hasAdminAccess}
      />
    </InteractionModal>
  );
}

export default UpdateTaskModal;
