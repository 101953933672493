import axios from "axios";
import { GraphQLFormattedError } from "graphql";
import getCsrfToken from "utils/helpers/get-csrf-token";

interface QueryResult<T> {
  data: T;
  errors: [GraphQLFormattedError];
}

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables,
): (() => Promise<TData>) => {
  return async () => {
    const response = await axios.post<QueryResult<TData>>(
      "/api/v1/graphql",
      {
        query: query,
        variables: variables,
      },
      {
        headers: {
          "X-CSRF-TOKEN": getCsrfToken(),
        },
      },
    );

    // graphql error
    if (response?.data?.errors) {
      throw response?.data?.errors[0];
    }

    // graphql success
    return response?.data?.data;
  };
};
