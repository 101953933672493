import { JSONContent } from "@tiptap/core";
import clsx from "clsx";
import { useToast } from "components/provider";
import { Breakout } from "graphql/generated";
import { t } from "i18n-js";
import React from "react";
import { useParams } from "react-router-dom";
import {
  useBreakoutActivitySubscription,
  useBreakoutRoomSubscription,
  useBreakoutTimeRemainingSubscription,
} from "subscriptions";

import { DiscussionActivity } from "../DisscussionActivity";
import { ResourceActivity } from "../ResourceActivity";
import { BreakoutActivityProps } from "./BreakoutActivity.types";
import BreakoutSlide from "./BreakoutSlide";

export const BreakoutActivity = ({
  activity,
  workshop,
  className,
  activityType,
  role,
}: BreakoutActivityProps) => {
  const activityData = activity.activity as Breakout;
  const instruction = activityData.instruction as JSONContent;
  const hasInstruction = !!(instruction && instruction?.content?.[0]?.content);
  const { addToast } = useToast();

  const handleTimeRemaining = (timeRemaining: number) => {
    timeRemaining &&
      addToast({
        message:
          role === "participant"
            ? t("workshop.inSessionBreakout.timeRemaining", {
                count: timeRemaining,
              })
            : t("workshop.inSessionBreakout.timeRemainingConfirm"),
        variant: role === "participant" ? "info" : "success",
        hasCloseOption: true,
      });
  };

  useBreakoutTimeRemainingSubscription(
    {
      workshopId: workshop.id,
    },
    handleTimeRemaining,
  );

  const { id: workshopId = "" } = useParams();

  useBreakoutRoomSubscription({ workshopId });
  useBreakoutActivitySubscription({ workshopId });

  return (
    <div
      id="BreakoutActivity"
      className={clsx("relative flex w-full overflow-hidden", className)}
    >
      {!activityData.breakoutRoomIsActive &&
      hasInstruction &&
      activityData.documents?.length === 0 ? (
        <DiscussionActivity
          title={activity.title as string | undefined}
          description={instruction}
        />
      ) : (
        <ResourceActivity
          {...activity}
          activityType={activityType}
          className="ml-14"
          activityTitle={(activity.title as string) || ""}
        />
      )}

      {role !== "participant" && (
        <BreakoutSlide workshop={workshop} activity={activity} />
      )}
    </div>
  );
};
