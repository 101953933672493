import {
  GetWorkshopVttQuery,
  GetWorkshopVttQueryVariables,
  useGetWorkshopVttQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query GetWorkshopVTT($workshopId: ID!, $timeZone: String!) {
    getWorkshopVtt(workshopId: $workshopId, timeZone: $timeZone) {
      __typename
      contentType
      fileName
      roomType
      url
    }
  }
`;

function useWorkshopVTT(
  variables: GetWorkshopVttQueryVariables,
): { getWorkshopVtt?: GetWorkshopVttQuery["getWorkshopVtt"] } & UseQueryResult<
  GetWorkshopVttQuery,
  unknown
> {
  const response = useGetWorkshopVttQuery(variables);

  return { getWorkshopVtt: response.data?.getWorkshopVtt, ...response };
}

export { useWorkshopVTT };
