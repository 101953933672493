import {
  SearchUserQuery,
  SearchUserQueryVariables,
  useSearchUserQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query searchUser($input: UsersSearchInput!) {
    searchUser(input: $input) {
      fullName
      email
      id
      firstName
      lastName
      defaultProjectRole
      avatarUrl
      pronouns
      company
    }
  }
`;

function useUsersSearch(variables: SearchUserQueryVariables): {
  usersList: Array<{
    __typename?: "User";
    fullName: string;
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    defaultProjectRole: string;
  }>;
} & UseQueryResult<SearchUserQuery, unknown> {
  const response = useSearchUserQuery(variables, { suspense: true });
  return { usersList: response.data?.searchUser };
}

export { useUsersSearch };
