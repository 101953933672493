import { useDeleteSectionMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation DeleteSection($id: ID!) {
    deleteSection(id: $id) {
      id
      title
    }
  }
`;

export function useDeleteSection() {
  const queryClient = useQueryClient();
  return useDeleteSectionMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["WorkshopById"]);
      await queryClient.refetchQueries(["WorkshopById"]);
    },
  });
}
