import clsx from "clsx";
import { useGetEnvsContext } from "contexts";
import { format } from "date-fns";
import { LanguageCohesionEntry } from "graphql/generated";
import { t } from "i18n-js";
import { TeamViewLanguageCohesionIllustration } from "icons/illustrations";
import { truncate } from "lodash";
import { Info } from "phosphor-react";
import { useProject } from "queries";
import { useLanguageCohesionEntries } from "queries/use-language-cohesion-entries";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import LineChart from "../../base/LineChart";
import { HStack, VStack } from "../../layout";
import { Base2, Caption1, Caption2, Heading2 } from "../../Typography";
import { LanguageCohesionInfoModal } from "../UserSettings/components/UserSettingsInsightsContent/components";

interface Label {
  title: string;
  date: string;
  value: string;
  numberOfAttendees: string;
}
const LanguageCohision = () => {
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [averageScores, setAverageScores] = useState<(number | null)[]>([]);
  const [attendeeCountList, setAttendeeCountList] = useState<number[]>([]);
  const [labels, setLabels] = useState<Label[]>([]);
  const { currentUser } = useGetEnvsContext();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const MIN_ATTENDEE_COUNT = 2;
  const { project } = useProject({ id: projectId as string });
  const queryClient = useQueryClient();
  const { getLanguageCohesionEntries } = useLanguageCohesionEntries({
    languageCohesionInput: {
      userId: currentUser?.id,
      projectId: projectId as string,
      startDate: project?.startDate as string,
      endDate: project?.endDate as string,
    },
  });

  const navigateToWorkshopCard = (workshopIndex?: number) => {
    void queryClient.refetchQueries(["subFilters"]);
    const workshopRecord = (
      getLanguageCohesionEntries?.entries as LanguageCohesionEntry[]
    )[workshopIndex as number]?.workshop;

    if (!workshopRecord?.id) {
      return;
    } else {
      navigate(
        `/projects/${projectId as string}?level=Timeline&card=workshop-${
          workshopRecord?.id
        }&scheduledAt=${workshopRecord?.startTime as string}`,
      );
    }
  };

  useEffect(() => {
    if (
      (getLanguageCohesionEntries?.entries as LanguageCohesionEntry[])?.length >
      0
    ) {
      setAverageScores(() => {
        return (
          getLanguageCohesionEntries?.entries as LanguageCohesionEntry[]
        )?.map((item) => {
          const averageRating =
            item?.averageRating > 0.6 ? item?.averageRating : 0.6;
          return item?.numberOfUserWhoAttendedWorkshop >= MIN_ATTENDEE_COUNT &&
            item.averageRating !== 0
            ? averageRating
            : // eslint-disable-next-line unicorn/no-null
              null;
        });
      });
      setAttendeeCountList(
        (getLanguageCohesionEntries?.entries as LanguageCohesionEntry[])?.map(
          (item) => item?.numberOfUserWhoAttendedWorkshop,
        ),
      );
      // eslint-disable-next-line unicorn/no-array-for-each
      getLanguageCohesionEntries?.entries?.forEach((item) => {
        const { workshop, averageRating, numberOfUserWhoAttendedWorkshop } =
          item;
        const label: Label = {
          title: "",
          value: "",
          date: "",
          numberOfAttendees: "0 attendees",
        };
        label.title = truncate(workshop?.title as string, {
          length: 12,
          separator: ".",
        });
        label.value =
          numberOfUserWhoAttendedWorkshop >= MIN_ATTENDEE_COUNT &&
          averageRating > 0
            ? `${averageRating.toFixed(2)} Avg`
            : "N/A";
        label.date = format(new Date(workshop?.startTime as string), "MMM dd");
        label.numberOfAttendees = `${numberOfUserWhoAttendedWorkshop} attendees`;
        setLabels((prev) => [...prev, label]);
      });
    }
  }, [getLanguageCohesionEntries]);

  return (
    <>
      <HStack space={2} align="center" className="mb-5">
        <Heading2 className="block text-neutral-900">
          {t("team.languageCohesion.title")}
        </Heading2>
        <Info
          size={22}
          color="#7f8489"
          onClick={() => setOpenInfoDialog(true)}
          className="cursor-pointer"
        />
      </HStack>
      {(getLanguageCohesionEntries?.entries as LanguageCohesionEntry[])
        ?.length > 0 ? (
        <HStack space={6} className="h-[190px]" align="center">
          <VStack className="h-full w-[200px]">
            <Base2 className="text-neutral-900">
              {t("component.languageCohesion.label")}
              <span
                className={clsx(
                  "inline",
                  getLanguageCohesionEntries?.cohesionLevel === "High"
                    ? "text-secondary-green-700"
                    : "text-secondary-orange-700",
                )}
              >
                {getLanguageCohesionEntries?.cohesionLevel?.toLowerCase() || ""}
              </span>
            </Base2>
            <Caption2 className="!mt-22 text-neutral-600">
              {t("component.languageCohesion.description")}
            </Caption2>
          </VStack>
          {averageScores?.length &&
            labels?.length &&
            attendeeCountList?.length && (
              <LineChart
                data={averageScores as number[]}
                labels={labels}
                max={1.1}
                min={0.5}
                stepSize={0.1}
                attendeeCountList={attendeeCountList}
                onClick={navigateToWorkshopCard}
                hoverable
                breakevenPoint={0.8}
                displayPoints={5}
              />
            )}
        </HStack>
      ) : (
        <div className="flex flex-col items-center gap-2">
          <TeamViewLanguageCohesionIllustration />
          <Caption1>
            {t("team.languageCohesion.emptyDescriptionTitle")}
          </Caption1>
          <Caption2 className="max-w-[250px] text-center text-neutral-70">
            {t("team.languageCohesion.emptyDescriptionSubtitle")}
          </Caption2>
        </div>
      )}
      <LanguageCohesionInfoModal
        isOpen={openInfoDialog}
        setIsOpen={() => setOpenInfoDialog(false)}
      />
    </>
  );
};

export default LanguageCohision;
