import { useAddCommentToAnnouncementMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation AddCommentToAnnouncement($announcementId: ID!, $comment: String!) {
    createAnnouncementComment(
      announcementId: $announcementId
      comment: $comment
    ) {
      id
    }
  }
`;

function useAddCommentToAnnouncement() {
  const queryClient = useQueryClient();
  return useAddCommentToAnnouncementMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries(["AnnouncementComments"]);
      void queryClient.invalidateQueries(["subFilters"]);
    },
  });
}

export default useAddCommentToAnnouncement;
