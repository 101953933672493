import { WorkshopByIdQuery } from "graphql/generated";
import React, { useContext, useState } from "react";

type CurrentWorkshopParticipant =
  WorkshopByIdQuery["workshopById"]["workshopParticipants"][number];

export interface WorkshopProviderState {
  currentWorkshopParticipant?: CurrentWorkshopParticipant;
  // This could be another candidate of derived state that could be useful here
  // currentActivity: WorkshopByIdQuery["workshopById"]["agenda"][number];
  updateParticipant: (participant: CurrentWorkshopParticipant) => void;
}

const initialState: WorkshopProviderState = {
  currentWorkshopParticipant: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateParticipant: () => {},
};

const WorkshopContext = React.createContext(initialState);

/**
 * This Provider/Context is just intended for tracking derived state,
 * like the "currentWorkshopParticipant" which there isn't a query for.
 * For anything that exists in the react-query cache, you should use the
 * existing react-query -based hook for accessing that data in your component.
 */
export const WorkshopProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [currentWorkshopParticipant, setCurrentParticipant] = useState<
    CurrentWorkshopParticipant | undefined
  >();

  const updateParticipant = (participant: CurrentWorkshopParticipant) => {
    setCurrentParticipant(participant);
  };

  return (
    <WorkshopContext.Provider
      value={{
        currentWorkshopParticipant,
        updateParticipant,
      }}
    >
      {children}
    </WorkshopContext.Provider>
  );
};

/**
 * Use this hook to grab top-level workshop information and avoid prop-drilling.
 */
export const useWorkshopContext = () => {
  return useContext(WorkshopContext);
};
