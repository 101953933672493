import { Disclosure, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Base2, Caption1 } from "components/Typography";
import { WorkshopParticipantRole } from "graphql/generated";
import { CaretDown } from "phosphor-react";
import React from "react";

import { AgendaActivity } from "../AgendaActivity";
import { AgendaSectionComponent } from "./AgendaSection.types";

export const AgendaSection: AgendaSectionComponent = ({
  title,
  workshopActivities,
  userRole,
  className,
  onClickItem,
  currentActivity,
  defaultOpen = false,
}) => {
  const isParticipant = userRole === WorkshopParticipantRole.Participant;

  const summedDuration = workshopActivities?.reduce((prev, curr) => {
    return prev + (curr.duration || 0);
  }, 0);

  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div className={clsx("flex w-full flex-col", className)}>
          <Disclosure.Button
            className="flex w-full flex-row justify-between py-[26px] pr-4 pl-2"
            disabled={isParticipant}
          >
            <div className="flex flex-row space-x-2">
              {!isParticipant && (
                <CaretDown
                  size={24}
                  className={`transition duration-500 ease-in-out ${
                    open ? "rotate-180 transform" : ""
                  }`}
                />
              )}
              <Base2>{title}</Base2>
            </div>

            {!isParticipant && (
              <Caption1 className="whitespace-nowrap text-neutral-40">
                {summedDuration} mins
              </Caption1>
            )}
          </Disclosure.Button>

          {!isParticipant && (
            <Transition
              as={Disclosure.Panel}
              enter="transition-all ease-in-out duration-500 transform"
              enterFrom="-translate-y-10 opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="transition-all ease-in-out duration-500 transform"
              leaveFrom="translate-y-0 opacity-100"
              leaveTo="-translate-y-10 opacity-0"
            >
              {workshopActivities?.map((activity) => (
                <div key={activity.id} className="flex flex-row items-stretch">
                  <div className="mx-5 w-0.5 bg-neutral-5" />
                  <AgendaActivity
                    {...activity}
                    userRole={userRole}
                    onClick={onClickItem}
                    isActive={currentActivity?.id === activity.id}
                  />
                </div>
              ))}
            </Transition>
          )}
        </div>
      )}
    </Disclosure>
  );
};
