import { useLogOutMutation } from "graphql/generated";
import { useQueryClient } from "react-query";

function useLogout() {
  const queryClient = useQueryClient();

  return useLogOutMutation({
    onSuccess: () => queryClient.invalidateQueries(["CurrentUser"]),
  });
}

export default useLogout;
