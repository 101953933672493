import { SlackChannelsQuery, useSlackChannelsQuery } from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query SlackChannels($authorizationId: ID!) {
    slackChannels(slackAuthorizationId: $authorizationId) {
      id
      name
    }
  }
`;

function useSlackChannels(authorizationId: string): {
  slackChannels: SlackChannelsQuery["slackChannels"];
} & UseQueryResult<SlackChannelsQuery, unknown> {
  const response = useSlackChannelsQuery(
    { authorizationId },
    { suspense: true, enabled: !!authorizationId },
  );

  return {
    slackChannels: response.data?.slackChannels || [],
    ...response,
  };
}

export { useSlackChannels };
