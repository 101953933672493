import React from "react";

function CreateAnnouncementIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="16" cy="16" r="16" fill="#ECC73A" />
      <path
        d="M14.75 12.25V21.9155C14.75 22.0184 14.7246 22.1197 14.676 22.2104C14.6275 22.3011 14.5573 22.3785 14.4717 22.4355L13.6129 23.008C13.5296 23.0636 13.4341 23.0983 13.3346 23.1093C13.235 23.1202 13.1343 23.1071 13.0409 23.071C12.9474 23.0349 12.8641 22.9768 12.7978 22.9017C12.7315 22.8267 12.6842 22.7368 12.6599 22.6396L11.625 18.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.625 18.5001C10.7962 18.5001 10.0013 18.1709 9.41529 17.5848C8.82924 16.9988 8.5 16.2039 8.5 15.3751C8.5 14.5463 8.82924 13.7515 9.41529 13.1654C10.0013 12.5794 10.7962 12.2501 11.625 12.2501H14.75C14.75 12.2501 19.004 12.2501 23.0977 8.81672C23.1888 8.74009 23.2998 8.69105 23.4178 8.67537C23.5357 8.65969 23.6557 8.67802 23.7636 8.7282C23.8715 8.77838 23.9629 8.85833 24.0269 8.95865C24.0909 9.05896 24.1249 9.17547 24.125 9.29447V21.4558C24.1249 21.5748 24.0909 21.6913 24.0269 21.7916C23.9629 21.8919 23.8715 21.9719 23.7636 22.0221C23.6557 22.0722 23.5357 22.0906 23.4178 22.0749C23.2998 22.0592 23.1888 22.0102 23.0977 21.9335C19.004 18.5001 14.75 18.5001 14.75 18.5001H11.625Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CreateAnnouncementIcon;
