import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { CreateProject } from "components/pages";
import React, { Fragment } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

type EditProjectModalProps = {
  show: boolean;
  onClose: () => void;
  editProjectId?: string;
};
function EditProjectModal({
  show,
  onClose,
  editProjectId,
}: EditProjectModalProps) {
  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        as="div"
        onClose={onClose}
        className={clsx(
          "fixed inset-0 overflow-y-scroll",
          Z_INDEX_LEVELS.MODAL,
        )}
      >
        <CreateProject
          editProjectId={editProjectId}
          isEditing
          onClose={onClose}
        />
      </Dialog>
    </Transition>
  );
}

export default EditProjectModal;
