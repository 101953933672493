import React from "react";

export interface WorkshopSummaryIllustrationProps {
  width?: number;
  fill?: string;
  gradientEndColor?: string;
  className?: string;
}

export const WorkshopSummaryIllustration = ({
  width = 320,
  // TODO: replace these with tailwind config color values at some point and update the typing to be stricter
  fill = "#3A61EB",
  gradientEndColor = "#2B4FCF",
  className,
}: WorkshopSummaryIllustrationProps) => (
  <svg
    width={width}
    height={width * (262 / 320)}
    viewBox="0 0 320 262"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g opacity="0.75">
      <rect
        x="0.25"
        y="-61.5"
        width="334.5"
        height="334.5"
        rx="167.25"
        fill={fill}
      />
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92.0052 53.4857C92.0052 47.0711 97.2053 41.8711 103.62 41.8711H231.38C237.795 41.8711 242.995 47.0711 242.995 53.4857V140.595C242.995 147.01 237.795 152.21 231.38 152.21H103.62C97.2053 152.21 92.0052 147.01 92.0052 140.595V53.4857ZM231.38 53.4857H103.62V140.595H231.38V53.4857Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M187.102 141.868C189.606 139.864 193.261 140.27 195.264 142.775L218.493 171.811C220.497 174.316 220.091 177.97 217.586 179.974C215.082 181.977 211.427 181.571 209.424 179.067L186.195 150.03C184.191 147.526 184.597 143.871 187.102 141.868Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M147.899 141.868C150.403 143.871 150.809 147.526 148.806 150.03L125.577 179.067C123.573 181.571 119.919 181.977 117.414 179.974C114.91 177.97 114.504 174.316 116.507 171.811L139.736 142.775C141.74 140.27 145.394 139.864 147.899 141.868Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M167.5 24.4492C170.707 24.4492 173.307 27.0492 173.307 30.2565V47.6784C173.307 50.8857 170.707 53.4857 167.5 53.4857C164.293 53.4857 161.693 50.8857 161.693 47.6784V30.2565C161.693 27.0492 164.293 24.4492 167.5 24.4492Z"
          fill="white"
        />
      </g>
    </g>
    <rect
      width="334.5"
      height="334.5"
      transform="translate(0.25 -62.1123)"
      fill="url(#paint0_linear_3935_103914)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3935_103914"
        x1="334.5"
        y1="0"
        x2="0"
        y2="334.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={gradientEndColor} stopOpacity="0" />
        <stop offset="0.895833" stopColor={gradientEndColor} />
      </linearGradient>
    </defs>
  </svg>
);
