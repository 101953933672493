import { t } from "i18n-js";
import React from "react";

function InsufficientWords() {
  return (
    <>
      <div className="mr-14 mb-3 flex content-center justify-center">
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3984_116090)">
            <path
              d="M27.5527 0.757812C27.3784 0.757812 27.2194 0.826008 27.0981 0.947267C26.9769 1.06853 26.9087 1.23519 26.9087 1.40185V23.1444C26.9087 23.5005 27.1966 23.7882 27.5526 23.7882H48.5755C48.9239 23.7882 49.2119 23.5154 49.2194 23.167L49.227 23.0457C49.2347 22.8488 49.2421 22.6518 49.2421 22.4473C49.2421 10.4927 39.5076 0.765592 27.553 0.758092L27.5527 0.757812ZM47.9465 22.5078H28.1965V2.06073C39.1436 2.40171 47.9465 11.417 47.9465 22.447V22.5077V22.5078Z"
              fill="#5F6368"
              fill-opacity="0.3"
            />
            <path
              d="M43.4708 27.6133H23.092V6.51497C23.092 6.15885 22.7966 5.87109 22.4405 5.87109C10.4859 5.87109 0.758789 15.5982 0.758789 27.5603C0.758789 33.5373 3.14516 39.1132 7.47837 43.2419C11.5389 47.1057 16.8496 49.2419 22.4405 49.2419C34.2055 49.2419 43.7284 40.0373 44.1071 28.2798C44.1148 28.1055 44.0466 27.9389 43.9253 27.8101C43.8116 27.6814 43.6449 27.6132 43.4707 27.6132L43.4708 27.6133ZM38.1374 40.6283L26.4099 28.9012H30.8037L39.9553 38.0529C39.4176 38.9619 38.804 39.818 38.1373 40.6286L38.1374 40.6283ZM31.1145 46.0375L18.3495 33.2725L20.5465 31.0755L33.9177 44.4468C33.0313 45.0451 32.0995 45.583 31.1147 46.0376L31.1145 46.0375ZM26.4253 47.5678L15.2432 36.3782L17.4402 34.1812L29.8419 46.5829C28.7434 46.9995 27.6072 47.3329 26.4254 47.5678H26.4253ZM21.8041 7.16609V27.9919L7.92533 41.8632C4.12975 38.0298 2.05408 32.9694 2.05408 27.5603C2.05408 16.5223 10.857 7.50734 21.8041 7.16651V7.16609ZM11.2207 40.3932L18.357 47.5294C14.8419 46.8173 11.5766 45.181 8.857 42.7565L11.2207 40.3932ZM20.5012 47.8553L12.1374 39.484L14.3269 37.2946L24.8419 47.8021C24.054 47.893 23.2585 47.9459 22.4403 47.9459C21.7888 47.9459 21.1447 47.9157 20.5009 47.8551L20.5012 47.8553ZM34.9783 43.6811L21.4557 30.1582L22.7134 28.9005H24.5847L37.2813 41.5901C36.5693 42.3476 35.7965 43.0445 34.9783 43.6809L34.9783 43.6811ZM40.607 36.8782L32.6295 28.9011H42.7962C42.6069 31.7723 41.8341 34.4694 40.6069 36.8782H40.607Z"
              fill="#5F6368"
              fill-opacity="0.3"
            />
          </g>
          <defs>
            <clipPath id="clip0_3984_116090">
              <rect width="50" height="50" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="mr-14 flex content-center justify-center">
        <p>
          {t(
            "component.userSettings.tabs.insights.sections.personalityProfile.insufficientData",
          )}
        </p>
      </div>
    </>
  );
}
export default InsufficientWords;
