import React, { createContext, useState } from "react";

type HighlightContextType = {
  isHighlighted: boolean;
  handleToggleHighlight: (value: boolean) => void;
};

const HighlightContext = createContext<HighlightContextType | undefined>(
  undefined,
);

export function HighlightProvider({ children }: { children: React.ReactNode }) {
  const [isHighlighted, setIsHighlighted] = useState(false);

  const handleToggleHighlight = (value: boolean) => setIsHighlighted(value);

  return (
    <HighlightContext.Provider value={{ isHighlighted, handleToggleHighlight }}>
      {children}
    </HighlightContext.Provider>
  );
}

export function useHighlightContext() {
  const context = React.useContext(HighlightContext);
  if (context === undefined)
    throw new Error(
      "useHighlightContext must be used withing an HighlightProvider",
    );
  return context;
}
