import { Caption1, CenteredPage, Display3 } from "components";
import { Button, Input, Logo } from "components/base";
import { MAX_PROJECT_TEAM_NAME } from "components/pages";
import { GraphQLError } from "graphql";
import { useCreateOrganizationMutation } from "graphql/generated";
import { t } from "i18n-js";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type YourTeamFormData = {
  teamName: string;
};

function YourTeam() {
  const navigate = useNavigate();
  const { mutateAsync } = useCreateOrganizationMutation();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<YourTeamFormData>({ mode: "onTouched" });
  const [submitError, setSubmitError] = useState<string | undefined>();

  const onSubmit = async (formData: YourTeamFormData) => {
    const { teamName: name } = formData;
    try {
      await mutateAsync({ input: { name } });
      navigate("/onboarding/about_you");
    } catch (error) {
      const gqlError = error as GraphQLError;
      if (gqlError.message === t("errors.moreThanOneOrganization")) {
        setSubmitError(t("errors.moreThanOneOrganization"));
      } else {
        setSubmitError(t("errors.genericError"));
      }
    }
  };

  return (
    <CenteredPage className="bg-white">
      <div className="absolute top-10 left-14">
        <Logo size="medium" className="text-neutral-70" />
      </div>
      <div className="flex flex-col items-center justify-center py-10">
        <Display3 className="w-80 text-neutral-90">
          {t("pages.onboarding.yourTeam.title")}
        </Display3>
        <div className="mt-10">
          <form
            className="flex min-w-[432px] flex-col"
            onSubmit={handleSubmit(onSubmit) as () => void}
          >
            {submitError && (
              <Caption1 className="mb-2 text-center text-secondary-red-50">
                {submitError}
              </Caption1>
            )}
            <Input
              type="text"
              aria-label={t("pages.onboarding.yourTeam.inputName")}
              placeholder={t("pages.onboarding.yourTeam.inputName")}
              error={errors.teamName?.message}
              {...register("teamName", {
                required: t("errors.presence"),
                maxLength: {
                  value: MAX_PROJECT_TEAM_NAME,
                  message: t("errors.maxLengthExceeded"),
                },
              })}
            />
            <Button
              variant="secondary"
              type="submit"
              disabled={isSubmitting}
              value="Continue"
              className="mt-4"
            >
              {t("shared.continue")}
            </Button>
          </form>
        </div>
      </div>
    </CenteredPage>
  );
}

export default YourTeam;
