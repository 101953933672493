import clsx from "clsx";
import { Base2, Display3, HStack, VStack } from "components";
import { HighlightButton } from "components/base";
import { SliderModal } from "components/partial";
import { useHighlightContext } from "contexts/HighlightContext";
import { noop } from "lodash";
import { X } from "phosphor-react";
import React from "react";

type HeaderVariants = "yellow" | "green" | "gray" | "blue" | "indigo";

const variantClasses: Record<
  HeaderVariants,
  {
    background: string;
    iconBackground: string;
    iconColor?: string;
    titleColor: string;
    descriptionColor: string;
    isDark: boolean;
  }
> = {
  yellow: {
    background: "bg-secondary-yellow-50",
    iconBackground: "bg-secondary-yellow-10",
    titleColor: "text-neutral-90",
    descriptionColor: "text-neutral-70",
    isDark: false,
  },
  green: {
    background: "bg-neutral-0",
    iconBackground: "bg-secondary-green-700",
    titleColor: "text-neutral-90",
    descriptionColor: "text-neutral-90",
    isDark: false,
  },
  gray: {
    background: "bg-neutral-0",
    iconBackground: "bg-tint-dark-10",
    iconColor: "text-white",
    titleColor: "text-neutral-90",
    descriptionColor: "text-neutral-90",
    isDark: false,
  },
  blue: {
    background: "bg-tertiary-blue-50",
    iconBackground: "bg-tertiary-blue-30",
    titleColor: "text-white",
    descriptionColor: "text-neutral-5",
    isDark: true,
  },
  indigo: {
    background: "bg-secondary-indigo-70",
    iconBackground: "bg-secondary-indigo-30",
    titleColor: "text-white",
    descriptionColor: "text-neutral-5",
    isDark: true,
  },
};

function ModalHeader({
  header,
  description = "",
  icon,
  onClose,
  onToggle,
  variant = "yellow",
  showFullHeader = false,
  isHighlighted,
  disableHighlightClick,
  showHighlightFeature,
  fromEditModal,
}: {
  onClose: () => void;
  onToggle: (isActive: boolean) => void;
  showFullHeader: boolean;
} & Omit<InteractionModalProps, "children" | "closeModal" | "isOpen">) {
  const {
    background,
    iconBackground,
    titleColor,
    descriptionColor,
    isDark,
    iconColor,
  } = variantClasses[variant];

  const { handleToggleHighlight } = useHighlightContext();

  return (
    <VStack
      space={fromEditModal || showFullHeader ? 6 : 2}
      className={clsx(
        "pr-14 pt-8 pb-6 pl-7",
        showFullHeader && "pl-14",
        background,
      )}
    >
      <HStack
        justify={fromEditModal || showFullHeader ? "between" : "end"}
        align="center"
      >
        {fromEditModal || showFullHeader ? (
          <div
            className={clsx(
              "flex h-11 w-11 items-center justify-center rounded-full",
              iconBackground,
            )}
          >
            {React.cloneElement(icon, {
              size: 30,
              className: titleColor,
              weight: "light",
            })}
          </div>
        ) : undefined}
        <button
          onClick={onClose}
          data-testid="close"
          className="flex h-12 w-12 cursor-pointer items-center justify-center self-end rounded-full bg-tint-light-80 focus:outline-none focus:ring focus:ring-primary-turquoise-10 focus:ring-offset-1"
        >
          <X className="text-neutral-90" size={24} />
        </button>
      </HStack>
      {showFullHeader ? (
        <HStack space={4}>
          <div>
            <Display3 className={clsx("mb-0.5", titleColor)}>{header}</Display3>
            <Base2 className={descriptionColor}>{description}</Base2>
          </div>
          {showHighlightFeature ? (
            <div className="mt-7 flex flex-1 justify-end">
              <HighlightButton
                onChange={handleToggleHighlight}
                variant={isDark ? "dark" : "light"}
              />
            </div>
          ) : undefined}
        </HStack>
      ) : (
        <>
          {fromEditModal ? (
            <HStack className="items-center">
              <Display3 className={clsx("mb-0.5", titleColor)}>
                {header}
              </Display3>
              {showHighlightFeature ? (
                <div
                  className={clsx(
                    "flex flex-1 justify-end",
                    disableHighlightClick ? "pointer-events-none" : "",
                  )}
                >
                  <HighlightButton
                    onChange={handleToggleHighlight}
                    variant={isDark ? "dark" : "light"}
                    defaultActive={isHighlighted}
                  />
                </div>
              ) : undefined}
            </HStack>
          ) : (
            <>
              <div
                className={clsx(
                  "ml-3 flex h-18 w-18 items-center justify-center rounded-full",
                  iconBackground,
                )}
              >
                {React.cloneElement(icon, {
                  size: 40,
                  className: iconColor ? iconColor : titleColor,
                  weight: "light",
                })}
              </div>
              <HStack className="items-center">
                <Display3 className={clsx("mb-0.5 ml-3", titleColor)}>
                  {header}
                </Display3>
                {showHighlightFeature ? (
                  <div
                    className={clsx(
                      "flex flex-1 justify-end",
                      disableHighlightClick ? "pointer-events-none" : "",
                    )}
                  >
                    <HighlightButton
                      onChange={
                        !!disableHighlightClick
                          ? (isActive: boolean) =>
                              handleToggleHighlight(isActive)
                          : (isActive: boolean) => onToggle(isActive)
                      }
                      variant={isDark ? "dark" : "light"}
                      defaultActive={isHighlighted}
                    />
                  </div>
                ) : undefined}
              </HStack>
            </>
          )}
        </>
      )}
    </VStack>
  );
}

type InteractionModalProps = {
  isOpen: boolean;
  closeModal?: () => void;
  onToggle?: (isActive: boolean) => void;
  header: string;
  description?: string;
  icon: JSX.Element;
  children?: React.ReactNode;
  variant?: HeaderVariants;
  slideFromLeft?: boolean;
  isHighlighted?: boolean;
  disableHighlightClick?: boolean;
  showHighlightFeature?: boolean;
  fromEditModal?: boolean;
};

export default function InteractionModal({
  children,
  isOpen,
  closeModal = noop,
  slideFromLeft = false,
  onToggle = noop,
  ...rest
}: InteractionModalProps) {
  return (
    <SliderModal
      isOpen={isOpen}
      closeModal={closeModal}
      slideFromLeft={slideFromLeft}
    >
      <ModalHeader
        showFullHeader={!slideFromLeft}
        onClose={closeModal}
        onToggle={onToggle}
        {...rest}
      />
      {children}
    </SliderModal>
  );
}
