import clsx from "clsx";
import { Heading3, Heading3Strong, HStack, VStack } from "components";
import {
  eachMinuteOfInterval,
  endOfDay,
  format,
  isBefore,
  startOfToday,
} from "date-fns";
import { noop, uniqueId } from "lodash";
import { CaretDown, Check } from "phosphor-react";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Z_INDEX_LEVELS } from "utils/constants/z_index_levels";

import {
  TimeOptionProps,
  TimePickerDropdownProps,
  TimePickerProps,
} from "./TimePicker.types";

function TimeOption({ time, isSelected, onClick = noop }: TimeOptionProps) {
  const formattedTime = format(time, "h:mm aaa");
  return (
    <HStack
      className="cursor-pointer py-2 pl-5 text-white hover:bg-neutral-70"
      onClick={onClick}
      aria-label={format(time, "h:mm aaa")}
    >
      <span className="mr-5">
        {isSelected ? <Check size={24} /> : <div className="w-6" />}
      </span>
      {isSelected ? (
        <Heading3Strong>{formattedTime}</Heading3Strong>
      ) : (
        <Heading3>{formattedTime}</Heading3>
      )}
    </HStack>
  );
}
function TimePickerDropdown({
  increment = 15,
  startTime = startOfToday(),
  onSelect = noop,
  selectedTime,
}: TimePickerDropdownProps) {
  const selectedOptionRef = useRef<HTMLDivElement>(null);
  // Prevent rendering too many options
  const incrementInMinutes = increment < 10 ? 10 : increment;
  const times = useMemo(() => {
    return eachMinuteOfInterval(
      { start: startTime, end: endOfDay(startTime) },
      { step: incrementInMinutes },
    );
  }, [startTime, incrementInMinutes]);
  useEffect(() => {
    selectedOptionRef?.current?.scrollIntoView();
  }, []);
  const renderTimes = () => {
    return times.map((time) => {
      const isSelectedTime = selectedTime?.getTime() === time.getTime();
      return (
        <div
          key={uniqueId("option-")}
          ref={isSelectedTime ? selectedOptionRef : undefined}
        >
          <TimeOption
            time={time}
            isSelected={isSelectedTime}
            onClick={() => {
              onSelect(time);
            }}
          />
        </div>
      );
    });
  };
  return (
    <VStack
      className={clsx(
        "absolute top-0 max-h-48 w-44 overflow-auto rounded-lg bg-neutral-90 pt-2",
        Z_INDEX_LEVELS.MODAL_CONTROL,
      )}
    >
      {renderTimes()}
    </VStack>
  );
}
function TimePicker({
  placeholder = "Choose a time",
  increment,
  startTime,
  onSelect = noop,
  error = "",
  defaultTime,
  light = false,
  ...props
}: TimePickerProps) {
  const hasError = error.length > 0;

  const ref = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedTime, setSelectedTime] = useState<Date | undefined>(
    defaultTime,
  );
  useEffect(() => {
    if (selectedTime) onSelect(selectedTime);
  }, [selectedTime, onSelect]);
  useEffect(() => {
    if (!selectedTime || !startTime) return;
    if (isBefore(selectedTime, startTime)) setSelectedTime(undefined);
  }, [startTime, selectedTime]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <div className="relative" ref={ref}>
      <input
        type="text"
        className={clsx(
          "box-border h-12 w-44 rounded-md border-2 border-transparent px-4  caret-primary-turquoise-70 outline-none transition-colors placeholder:text-base focus:border-primary-turquoise-30 focus:outline-none disabled:cursor-not-allowed",
          light
            ? "bg-black bg-opacity-15 text-white placeholder:text-white placeholder:text-opacity-30"
            : "bg-tint-dark-10 text-neutral-90 placeholder:text-tint-dark-50 disabled:text-tint-dark-50",
          error ? "border-2 border-red-500" : "",
        )}
        value={selectedTime ? format(selectedTime, "h:mm aaa") : ""}
        placeholder={placeholder}
        onFocus={() => setShowDropdown(true)}
        onChange={noop}
        {...props}
      />
      <CaretDown
        onClick={() => setShowDropdown(true)}
        className={clsx(
          `absolute right-5 top-1/2 -translate-y-1/2  ${
            light ? "text-white" : "text-neutral-90"
          }`,
          hasError ? "text-secondary-red-70" : "",
        )}
        size={24}
      />
      {showDropdown ? (
        <TimePickerDropdown
          onSelect={(time) => {
            setSelectedTime(time);
            setShowDropdown(false);
          }}
          increment={increment}
          startTime={startTime}
          selectedTime={selectedTime}
        />
      ) : undefined}
    </div>
  );
}

TimePicker.withRef = forwardRef<HTMLInputElement, TimePickerProps>(
  (props, ref) => <TimePicker {...props} innerRef={ref} />,
);

export { TimePicker as default };
