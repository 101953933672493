import { VStack } from "components";
import { Button } from "components/base";
import { HStack } from "components/layout/Stack";
import { noop } from "lodash";
import { X } from "phosphor-react";
import React from "react";

import {
  UserSettingsProfileAccount,
  UserSettingsProfileDeleteAccount,
} from "./components";

type UserSettingsProfileContentProps = {
  onClose?: () => void;
};

export function UserSettingsAccountContent({
  onClose = noop,
}: UserSettingsProfileContentProps) {
  return (
    <VStack className="grow overflow-y-auto p-10 px-14" space={10}>
      <HStack justify="end">
        {/* <Display3>{t("component.userSettings.tabs.profile.title")}</Display3> */}

        <Button
          data-testid="settings-menu-close-button"
          onClick={onClose}
          variant="tertiary"
          LeftIcon={X}
        />
      </HStack>

      <VStack className="flex-col-reverse gap-30">
        <UserSettingsProfileDeleteAccount />

        <UserSettingsProfileAccount />
      </VStack>
    </VStack>
  );
}
