import {
  GetAccessLinkByIdQuery,
  GetAccessLinkByIdQueryVariables,
  useGetAccessLinkByIdQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query GetAccessLinkByID($id: ID!) {
    getAccessLink(id: $id) {
      url
      encoded
    }
  }
`;

function useAccessLink(variables: GetAccessLinkByIdQueryVariables): {
  url?: GetAccessLinkByIdQuery["getAccessLink"]["url"];
  encoded?: GetAccessLinkByIdQuery["getAccessLink"]["encoded"];
} & UseQueryResult<GetAccessLinkByIdQuery, unknown> {
  const response = useGetAccessLinkByIdQuery(variables, {
    suspense: false,
    enabled: false,
  });

  return {
    url: response?.data?.getAccessLink?.url,
    encoded: response?.data?.getAccessLink?.encoded,
    ...response,
  };
}

export { useAccessLink };
