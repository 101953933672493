import React from "react";

interface ToggleSwitchProps {
  enabled: boolean;
  onToggle: (value: boolean) => void;
}

// ToggleSwitch differes from the ToggleButton component in that under the hood this
// compoennt is a checkbox that uses it's own internal state to handle native state.
const ToggleSwitch = ({ enabled, onToggle }: ToggleSwitchProps) => {
  return (
    <div className="flex">
      <label className="relative cursor-pointer">
        <input
          type="checkbox"
          className="peer hidden"
          checked={enabled}
          readOnly
        />
        <div
          onClick={() => onToggle(!enabled)}
          className="peer h-8 w-18 rounded-full bg-tint-dark-10 after:absolute after:top-1 after:left-0.5 after:h-6 after:w-6 after:rounded-full after:bg-white after:shadow-md after:transition-all after:content-[''] peer-checked:bg-primary-turquoise-50 peer-checked:after:translate-x-11"
        />
      </label>
    </div>
  );
};

export default ToggleSwitch;
