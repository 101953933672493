import { useScrollFades } from "components/base/ScrollFade/useScrollFades";
import { Caption1, Caption2, Display5 } from "components/Typography";
import { WorkshopByIdQuery, WorkshopParticipantRole } from "graphql/generated";
import { t } from "i18n-js";
import { capitalize } from "lodash";
import React from "react";
import { toSentence } from "utils";

import { AttendanceAvatar } from "./components";

export const rolesOrder = {
  owner: 1,
  facilitator: 2,
  participant: 3,
  summary_shared: 4,
};

/**
 * WorkshopSummaryParticipants shows a list of the workshop participants for a given workshop
 * and whether or not they were able to attend.
 */
export const WorkshopSummaryParticipants = ({
  workshop,
}: {
  workshop?: WorkshopByIdQuery["workshopById"];
}) => {
  const participants = workshop?.workshopParticipants || [];
  const attendedParticipants = participants.filter((p) => p.didAttend === true);
  const summarySharedParticipants = participants.filter(
    (p) => p.role === WorkshopParticipantRole.SummaryShared,
  );
  const { onScrollContainerScroll, topFade, bottomFade } = useScrollFades();

  const sortedParticipants = participants.sort(
    (user1, user2) =>
      rolesOrder[user1?.role as WorkshopParticipantRole] -
      rolesOrder[user2?.role as WorkshopParticipantRole],
  );

  return (
    <>
      <div className="flex flex-col gap-2">
        <Display5>{t(`workshop.summary.section.people.title`)}</Display5>

        <Caption1 className="text-neutral-70">
          {toSentence([
            t("workshop.summary.section.people.countDidAttend", {
              count: attendedParticipants.length,
            }),
            t("workshop.summary.section.people.countDidNotAttend", {
              count:
                participants.length -
                attendedParticipants.length -
                summarySharedParticipants.length,
            }),
          ])}
        </Caption1>
        <Caption1 className="text-neutral-70">
          {t("workshop.summary.section.people.countCanView", {
            count: summarySharedParticipants.length,
          })}
        </Caption1>
      </div>

      <div className="relative flex flex-col overflow-auto">
        {topFade}
        {bottomFade}
        <div
          className="mt-2 flex flex-1 flex-col gap-3 overflow-y-auto"
          onScroll={onScrollContainerScroll}
        >
          {!!workshop && sortedParticipants.length > 0 ? (
            sortedParticipants.map((participant) => (
              <div
                key={participant.id}
                className="flex flex-row items-center justify-between first:mt-6 last:mb-6"
              >
                <div className="flex items-center gap-3">
                  <AttendanceAvatar
                    size="mini"
                    user={participant.user}
                    didAttend={participant.didAttend ?? false}
                    didSummaryShared={
                      participant.role === WorkshopParticipantRole.SummaryShared
                    }
                    aria-label={`${participant.user.fullName} ${t(
                      `workshop.summary.section.people.${
                        participant.didAttend ? "didAttend" : "didNotAttend"
                      }`,
                    )}`}
                  />
                  <Caption1> {participant.user.fullName}</Caption1>
                </div>

                <Caption2
                  className="text-neutral-60"
                  aria-label={t("workshop.summary.section.people.role")}
                >
                  {participant.role === WorkshopParticipantRole.SummaryShared
                    ? t("shared.canView")
                    : capitalize(participant.role || "")}
                </Caption2>
              </div>
            ))
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </>
  );
};
