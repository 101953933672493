import React from "react";

function ShareoutMediumIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_46_1622)">
        <path
          d="M77.4475 74.936H77.446L26.1325 74.9643H26.1218C21.4528 74.8924 16.9492 73.2237 13.3606 70.236C9.772 67.2482 7.31477 63.1217 6.39788 58.543C5.48099 53.9644 6.15973 49.2098 8.32115 45.0706C10.4826 40.9314 13.9963 37.6572 18.2776 35.7929L46.3403 23.7531L43.3298 31.1851C43.206 31.4894 43.1435 31.8152 43.1457 32.1438C43.1479 32.4723 43.2149 32.7972 43.3428 33.0999C43.4707 33.4025 43.657 33.677 43.8911 33.9076C44.1251 34.1382 44.4024 34.3204 44.7069 34.4437C45.0114 34.5671 45.3373 34.6292 45.6659 34.6265C45.9944 34.6238 46.3192 34.5563 46.6217 34.428C46.9242 34.2997 47.1984 34.113 47.4286 33.8786C47.6589 33.6442 47.8407 33.3667 47.9636 33.062L53.2668 19.9712C53.3902 19.667 53.4523 19.3414 53.4498 19.0131C53.4473 18.6848 53.3801 18.3602 53.2521 18.0579C53.1241 17.7556 52.9378 17.4815 52.7038 17.2512C52.4698 17.0209 52.1927 16.839 51.8884 16.7158L38.7981 11.4131C38.4938 11.2898 38.1682 11.2277 37.84 11.2303C37.5117 11.2329 37.1872 11.3001 36.8849 11.4281C36.5826 11.5561 36.3085 11.7423 36.0782 11.9763C35.8479 12.2102 35.6659 12.4872 35.5427 12.7915C35.4194 13.0957 35.3573 13.4213 35.3599 13.7496C35.3625 14.0778 35.4297 14.4024 35.5577 14.7047C35.6856 15.0069 35.8719 15.2811 36.1059 15.5114C36.3398 15.7417 36.6168 15.9236 36.9211 16.0469L44.4817 19.1098L16.3059 31.1982C10.9666 33.5165 6.5839 37.5959 3.88939 42.7555C1.19488 47.9152 0.351555 53.8429 1.50017 59.5493C2.64879 65.2558 5.71986 70.3956 10.2008 74.1109C14.6817 77.8263 20.3014 79.8924 26.1218 79.9643H26.1355L77.4489 79.936C78.112 79.9358 78.7478 79.6722 79.2165 79.2032C79.6852 78.7343 79.9484 78.0983 79.9482 77.4353C79.948 76.7722 79.6844 76.1364 79.2155 75.6677C78.7465 75.199 78.1105 74.9358 77.4475 74.936Z"
          fill="#A7A8B0"
        />
        <path
          d="M68.4475 23.9893C70.722 23.9908 72.9459 23.3176 74.8376 22.0547C76.7293 20.7918 78.2038 18.996 79.0744 16.8947C79.9451 14.7934 80.1727 12.4811 79.7286 10.2504C79.2844 8.01963 78.1885 5.97082 76.5794 4.36326V4.36279C75.2426 3.02428 73.5962 2.03621 71.7863 1.48615C69.9763 0.936088 68.0586 0.841028 66.203 1.20939C64.3475 1.57776 62.6116 2.39817 61.149 3.59792C59.6864 4.79767 58.5423 6.3397 57.8182 8.08735C57.0941 9.83499 56.8123 11.7343 56.9978 13.6169C57.1833 15.4995 57.8303 17.3073 58.8816 18.88C59.9329 20.4527 61.3559 21.7418 63.0245 22.633C64.6931 23.5243 66.5558 23.9901 68.4475 23.9893ZM63.8513 7.89844C64.7604 6.98941 65.9186 6.37036 67.1795 6.11958C68.4404 5.86879 69.7474 5.99754 70.9351 6.48953C72.1229 6.98152 73.138 7.81467 73.8523 8.88361C74.5665 9.95255 74.9477 11.2093 74.9477 12.4949C74.9477 13.7805 74.5665 15.0372 73.8523 16.1061C73.138 17.1751 72.1229 18.0082 70.9351 18.5002C69.7474 18.9922 68.4404 19.121 67.1795 18.8702C65.9186 18.6194 64.7604 18.0003 63.8513 17.0913C63.2452 16.4891 62.7642 15.773 62.436 14.9841C62.1078 14.1953 61.9388 13.3493 61.9388 12.4949C61.9388 11.6405 62.1078 10.7945 62.436 10.0056C62.7642 9.21679 63.2452 8.50065 63.8513 7.89844Z"
          fill="#A7A8B0"
        />
        <path
          d="M26.9736 67.605C33.8772 67.605 39.4736 62.0085 39.4736 55.105C39.4736 48.2014 33.8772 42.605 26.9736 42.605C20.0701 42.605 14.4736 48.2014 14.4736 55.105C14.4736 62.0085 20.0701 67.605 26.9736 67.605Z"
          fill="#F2F4F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_1622">
          <rect
            width="78.9473"
            height="78.9642"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShareoutMediumIcon;
