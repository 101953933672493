import { CenteredPage, Display3, VStack } from "components";
import { Button, Input, Logo } from "components/base";
import { GraphQLError } from "graphql";
import { useRequestPasswordResetMutation } from "graphql/generated";
import { t } from "i18n-js";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { INVALID_CREDENTIALS } from "utils/constants/graphql_errors";
import { EMAIL_REGEX } from "utils/helpers/validations";

type RequestPasswordResetFormData = {
  email: string;
};

function RequestPasswordReset() {
  const [loading, setLoading] = useState(false);
  const { mutateAsync } = useRequestPasswordResetMutation();
  const {
    handleSubmit,
    register,
    formState: { isSubmitSuccessful, errors },
  } = useForm<RequestPasswordResetFormData>({ mode: "onTouched" });
  const [submitError, setSubmitError] = useState<string | undefined>();
  const navigate = useNavigate();
  const onSubmit = async ({ email }: RequestPasswordResetFormData) => {
    setLoading(true);
    try {
      await mutateAsync({ email: email?.toLowerCase() });
      setSubmitError("");
      // navigate("/");
    } catch (error) {
      const graphQLError = error as GraphQLError;
      switch (graphQLError?.extensions?.code) {
        case INVALID_CREDENTIALS:
          setSubmitError(
            graphQLError.message || t("errors.auth.invalidCredentials"),
          );
          break;
        default:
          setSubmitError(graphQLError.message || t("errors.genericError"));
          break;
      }
      setLoading(false);
    }
  };

  return (
    <CenteredPage className="bg-neutral-10">
      <div className="absolute top-10 left-14">
        <Logo size="medium" className="text-neutral-70" />
      </div>
      <VStack className="w-[27rem]">
        <Display3 className="mb-24">
          {t("pages.requestPasswordReset.title")}
        </Display3>
        {isSubmitSuccessful && !submitError ? (
          <div className="mb-6 flex justify-center gap-1">
            <div>{t("pages.requestPasswordReset.emailSent")}</div>
            <Link
              style={{
                color: "#0000cd",
                textDecoration: "underline",
              }}
              to={"/login"}
            >
              {"Back to login"}
            </Link>
          </div>
        ) : (
          <div className="text-secondary-red-50">{submitError}</div>
        )}
        <form onSubmit={handleSubmit(onSubmit) as () => void}>
          <VStack space={4}>
            <Input
              type="email"
              aria-label="E-mail"
              label={t("shared.emailAddress")}
              placeholder="name@example.com"
              error={errors.email?.message}
              {...register("email", {
                required: t("errors.presence"),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t("errors.invalidEmail"),
                },
              })}
              className="!bg-neutral-5"
            />
            <Button type="submit" value="Reset Password" disabled={loading}>
              {t("shared.resetPassword")}
            </Button>
          </VStack>
        </form>
      </VStack>
    </CenteredPage>
  );
}

export default RequestPasswordReset;
