import {
  OrganizationMembershipsByOrganizationQuery,
  OrganizationMembershipsByOrganizationQueryVariables,
  useOrganizationMembershipsByOrganizationQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query OrganizationMembershipsByOrganization($organizationId: ID!) {
    organizationMembershipsByOrganization(organizationId: $organizationId) {
      id
      user {
        fullName
        email
        id
        defaultProjectRole
      }
      accessLevel
    }
  }
`;

function useOrganizationMemberships(
  variables: OrganizationMembershipsByOrganizationQueryVariables,
): {
  memberships: OrganizationMembershipsByOrganizationQuery["organizationMembershipsByOrganization"];
} & UseQueryResult<OrganizationMembershipsByOrganizationQuery, unknown> {
  const response = useOrganizationMembershipsByOrganizationQuery(variables, {
    suspense: true,
  });

  return {
    memberships: response.data?.organizationMembershipsByOrganization || [],
    ...response,
  };
}

export { useOrganizationMemberships };
