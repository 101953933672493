import { GraphQLFormattedError } from "graphql";
import React, { useEffect } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import {
  FORBIDDEN,
  GraphQLValidationErrorDetail,
  NOT_FOUND,
} from "utils/constants/graphql_errors";

function ErrorFallback({
  error,
}: {
  onTryAgain?: () => void;
  error: GraphQLFormattedError;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    const code = error?.extensions?.code;
    const details = error?.extensions?.details as GraphQLValidationErrorDetail;
    const model = details?.model;
    if (model === "Project" && (code === FORBIDDEN || code === NOT_FOUND)) {
      navigate("/projects/unauthorized");
    }
  }, [error, navigate]);

  // eslint-disable-next-line unicorn/no-null
  return null;
}

export default function withProjectErrorBoundary(
  Component: React.ComponentType<unknown>,
) {
  return withErrorBoundary(Component, {
    FallbackComponent: ErrorFallback,
  });
}
