import React from "react";

export type FullPageProps = {
  className?: string;
};

function FullPage({
  className = "",
  ...props
}: FullPageProps & React.ComponentPropsWithRef<"div">) {
  return <div className={`min-h-screen ${className}`} {...props} />;
}

export default FullPage;
