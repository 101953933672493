import React from "react";

function AhaMediumIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1599_109596)">
        <path
          d="M25.5356 49.0728C25.8521 49.6233 25.9467 50.2737 25.8 50.8915C25.6533 51.5093 25.2765 52.0478 24.7463 52.3972C26.5387 52.9523 28.4329 53.0976 30.2889 52.8222C32.1449 52.5468 33.9153 51.8578 35.4692 50.8062C33.5162 49.8725 31.8673 48.4053 30.713 46.5741C29.5587 44.7429 28.9462 42.6224 28.9462 40.4577C28.9462 38.2931 29.5587 36.1726 30.713 34.3414C31.8673 32.5101 33.5162 31.0429 35.4692 30.1093C33.9154 29.0577 32.145 28.3688 30.2891 28.0934C28.4332 27.818 26.5391 27.9632 24.7468 28.5182C25.2877 28.8762 25.6679 29.4308 25.8068 30.0644C25.9457 30.6979 25.8323 31.3607 25.4907 31.9121C25.1491 32.4635 24.6062 32.8602 23.977 33.018C23.3479 33.1758 22.682 33.0823 22.1206 32.7574L19.8248 31.4319C17.9818 33.1871 16.72 35.4643 16.209 37.9576H20.73C21.393 37.9576 22.0289 38.221 22.4978 38.6898C22.9666 39.1587 23.23 39.7945 23.23 40.4576C23.23 41.1206 22.9666 41.7565 22.4978 42.2254C22.0289 42.6942 21.393 42.9576 20.73 42.9576H16.209C16.7199 45.4509 17.9816 47.7282 19.8247 49.4834L22.1206 48.1578C22.6949 47.8266 23.3772 47.737 24.0175 47.9085C24.6578 48.0801 25.2039 48.4989 25.5356 49.0728Z"
          fill="#F2F4F4"
        />
        <path
          d="M40.457 57.686C40.1287 57.686 39.8036 57.7507 39.5003 57.8763C39.197 58.0019 38.9214 58.1861 38.6892 58.4182C38.4571 58.6504 38.2729 58.926 38.1473 59.2293C38.0217 59.5326 37.957 59.8577 37.957 60.186V77.4156C37.957 78.0786 38.2204 78.7145 38.6893 79.1833C39.1581 79.6522 39.794 79.9156 40.457 79.9156C41.1201 79.9156 41.756 79.6522 42.2248 79.1833C42.6936 78.7145 42.957 78.0786 42.957 77.4156V60.186C42.9571 59.8577 42.8924 59.5326 42.7668 59.2293C42.6412 58.926 42.457 58.6504 42.2249 58.4182C41.9927 58.1861 41.7171 58.0019 41.4138 57.8763C41.1104 57.7507 40.7853 57.686 40.457 57.686Z"
          fill="#A7A8B0"
        />
        <path
          d="M52.487 56.295C52.3236 56.0092 52.1054 55.7584 51.8449 55.5571C51.5844 55.3558 51.2866 55.2079 50.9688 55.122C50.651 55.0361 50.3193 55.0138 49.9928 55.0564C49.6664 55.099 49.3515 55.2057 49.0664 55.3703C48.7813 55.535 48.5315 55.7543 48.3313 56.0157C48.1312 56.2771 47.9847 56.5755 47.9002 56.8937C47.8157 57.2119 47.7948 57.5437 47.8389 57.87C47.883 58.1963 47.991 58.5106 48.1569 58.795L56.7712 73.7145C57.1041 74.2858 57.6499 74.702 58.289 74.8718C58.9281 75.0417 59.6085 74.9514 60.1812 74.6208C60.7539 74.2901 61.1722 73.746 61.3447 73.1076C61.5171 72.4692 61.4296 71.7885 61.1013 71.2145L52.487 56.295Z"
          fill="#A7A8B0"
        />
        <path
          d="M73.714 56.7716L58.795 48.1578C58.221 47.8295 57.5402 47.7419 56.9018 47.9144C56.2634 48.0868 55.7193 48.5052 55.3887 49.0779C55.058 49.6506 54.9677 50.3309 55.1376 50.97C55.3075 51.6092 55.7236 52.1549 56.295 52.4879L71.214 61.1017C71.4984 61.2674 71.8127 61.3753 72.1389 61.4193C72.4651 61.4632 72.7968 61.4423 73.1149 61.3578C73.433 61.2732 73.7313 61.1267 73.9926 60.9266C74.2539 60.7265 74.4732 60.4767 74.6378 60.1917C74.8024 59.9066 74.909 59.5919 74.9517 59.2655C74.9943 58.9391 74.9721 58.6075 74.8862 58.2897C74.8004 57.972 74.6527 57.6743 74.4515 57.4138C74.2503 57.1532 73.9997 56.935 73.714 56.7716Z"
          fill="#A7A8B0"
        />
        <path
          d="M77.415 37.9575H60.1855C59.5225 37.9575 58.8866 38.2209 58.4178 38.6898C57.9489 39.1586 57.6855 39.7945 57.6855 40.4575C57.6855 41.1206 57.9489 41.7564 58.4178 42.2253C58.8866 42.6941 59.5225 42.9575 60.1855 42.9575H77.415C78.078 42.9575 78.7139 42.6941 79.1828 42.2253C79.6516 41.7564 79.915 41.1206 79.915 40.4575C79.915 39.7945 79.6516 39.1586 79.1828 38.6898C78.7139 38.2209 78.078 37.9575 77.415 37.9575Z"
          fill="#A7A8B0"
        />
        <path
          d="M55.3798 31.8422C55.5438 32.1267 55.7623 32.376 56.0227 32.576C56.2832 32.7759 56.5805 32.9226 56.8976 33.0076C57.2148 33.0925 57.5456 33.1142 57.8711 33.0712C58.1967 33.0283 58.5105 32.9216 58.7948 32.7572L73.7138 24.144C73.9995 23.9805 74.2501 23.7623 74.4513 23.5018C74.6525 23.2412 74.8002 22.9436 74.886 22.6258C74.9719 22.308 74.9941 21.9764 74.9515 21.6501C74.9088 21.3237 74.8022 21.0089 74.6376 20.7239C74.473 20.4388 74.2537 20.1891 73.9924 19.989C73.7311 19.7888 73.4328 19.6423 73.1147 19.5578C72.7966 19.4732 72.4649 19.4523 72.1387 19.4963C71.8125 19.5402 71.4982 19.6482 71.2138 19.8139L56.2948 28.4272C55.7206 28.7588 55.3017 29.3048 55.1301 29.9452C54.9585 30.5857 55.0483 31.268 55.3798 31.8422Z"
          fill="#A7A8B0"
        />
        <path
          d="M49.0721 25.5356C49.3564 25.6999 49.6703 25.8066 49.9958 25.8496C50.3213 25.8925 50.6521 25.8709 50.9693 25.7859C51.2865 25.7009 51.5838 25.5543 51.8442 25.3543C52.1046 25.1544 52.3231 24.905 52.4871 24.6206L61.1014 9.70112C61.2672 9.41673 61.3751 9.10242 61.4191 8.77621C61.463 8.45 61.4421 8.11833 61.3576 7.80022C61.273 7.48211 61.1265 7.18383 60.9264 6.92249C60.7263 6.66115 60.4765 6.4419 60.1915 6.27733C59.9064 6.11275 59.5917 6.00608 59.2653 5.96344C58.9389 5.92081 58.6073 5.94304 58.2895 6.02888C57.9718 6.11471 57.6741 6.26244 57.4136 6.46361C57.153 6.66477 56.9348 6.9154 56.7714 7.20112L48.1571 22.1206C47.8257 22.6948 47.7359 23.3771 47.9075 24.0176C48.0791 24.658 48.498 25.204 49.0721 25.5356Z"
          fill="#A7A8B0"
        />
        <path
          d="M40.457 23.2295C40.7853 23.2295 41.1104 23.1649 41.4138 23.0392C41.7171 22.9136 41.9927 22.7295 42.2249 22.4973C42.457 22.2652 42.6412 21.9896 42.7668 21.6862C42.8924 21.3829 42.9571 21.0578 42.957 20.7295V3.5C42.957 2.83696 42.6936 2.20107 42.2248 1.73223C41.756 1.26339 41.1201 1 40.457 1C39.794 1 39.1581 1.26339 38.6893 1.73223C38.2204 2.20107 37.957 2.83696 37.957 3.5V20.7295C37.957 21.0578 38.0217 21.3829 38.1473 21.6862C38.2729 21.9896 38.4571 22.2652 38.6892 22.4973C38.9214 22.7295 39.197 22.9136 39.5003 23.0392C39.8036 23.1649 40.1287 23.2295 40.457 23.2295Z"
          fill="#A7A8B0"
        />
        <path
          d="M28.4274 24.6212C28.7604 25.1925 29.3062 25.6087 29.9453 25.7786C30.5844 25.9484 31.2647 25.8582 31.8374 25.5275C32.4101 25.1969 32.8285 24.6528 33.0009 24.0144C33.1734 23.3759 33.0858 22.6952 32.7575 22.1212L24.1432 7.20127C23.9799 6.91542 23.7617 6.66464 23.5012 6.46334C23.2406 6.26205 22.9429 6.11419 22.6251 6.02828C22.3072 5.94236 21.9756 5.92007 21.6491 5.96268C21.3226 6.00529 21.0078 6.11197 20.7226 6.27659C20.4375 6.44122 20.1877 6.66054 19.9876 6.92196C19.7874 7.18339 19.6409 7.48177 19.5564 7.79998C19.4719 8.11819 19.4511 8.44996 19.4951 8.77624C19.5392 9.10252 19.6473 9.41688 19.8132 9.70127L28.4274 24.6212Z"
          fill="#A7A8B0"
        />
        <path
          d="M7.20123 24.1441L19.8244 31.4319L22.1202 32.7573C22.6816 33.0832 23.348 33.1774 23.9777 33.0198C24.6074 32.8623 25.1509 32.4655 25.4928 31.9136C25.8347 31.3618 25.948 30.6984 25.8086 30.0644C25.6692 29.4304 25.2882 28.8757 24.7464 28.5181C24.7027 28.4892 24.6661 28.4538 24.6202 28.4273L9.70123 19.8141C9.1272 19.4857 8.44647 19.3982 7.80806 19.5706C7.16965 19.7431 6.62556 20.1614 6.29491 20.7341C5.96426 21.3068 5.87399 21.9872 6.04385 22.6263C6.21371 23.2654 6.62987 23.8112 7.20123 24.1441V24.1441Z"
          fill="#A7A8B0"
        />
        <path
          d="M20.7295 42.9575C21.3925 42.9575 22.0284 42.6941 22.4973 42.2253C22.9661 41.7564 23.2295 41.1206 23.2295 40.4575C23.2295 39.7945 22.9661 39.1586 22.4973 38.6898C22.0284 38.2209 21.3925 37.9575 20.7295 37.9575H3.5C2.83696 37.9575 2.20107 38.2209 1.73223 38.6898C1.26339 39.1586 1 39.7945 1 40.4575C1 41.1206 1.26339 41.7564 1.73223 42.2253C2.20107 42.6941 2.83696 42.9575 3.5 42.9575H20.7295Z"
          fill="#A7A8B0"
        />
        <path
          d="M24.7458 52.3972C25.2876 52.0397 25.6687 51.485 25.8081 50.851C25.9476 50.217 25.8344 49.5537 25.4926 49.0018C25.1508 48.4499 24.6073 48.053 23.9776 47.8954C23.3478 47.7378 22.6815 47.8319 22.12 48.1577L19.8241 49.4834L7.20112 56.7715C6.9154 56.9349 6.66477 57.1532 6.46361 57.4137C6.26244 57.6742 6.11471 57.9719 6.02888 58.2897C5.94304 58.6075 5.92081 58.939 5.96344 59.2654C6.00608 59.5918 6.11275 59.9066 6.27733 60.1916C6.4419 60.4767 6.66115 60.7264 6.92249 60.9265C7.18383 61.1266 7.48211 61.2732 7.80022 61.3577C8.11833 61.4423 8.45001 61.4632 8.77621 61.4192C9.10242 61.3753 9.41673 61.2673 9.70112 61.1016L24.6201 52.4878C24.6658 52.4614 24.7023 52.426 24.7458 52.3972Z"
          fill="#A7A8B0"
        />
        <path
          d="M31.8425 55.3794C31.2683 55.0479 30.5859 54.958 29.9455 55.1296C29.305 55.3012 28.759 55.7202 28.4275 56.2944L19.8132 71.2143C19.6473 71.4987 19.5392 71.8131 19.4951 72.1394C19.4511 72.4656 19.4719 72.7974 19.5564 73.1156C19.6409 73.4338 19.7874 73.7322 19.9876 73.9936C20.1877 74.2551 20.4375 74.4744 20.7226 74.639C21.0078 74.8036 21.3226 74.9103 21.6491 74.9529C21.9756 74.9955 22.3072 74.9733 22.6251 74.8873C22.9429 74.8014 23.2406 74.6536 23.5012 74.4523C23.7617 74.251 23.9799 74.0002 24.1432 73.7143L32.7575 58.7944C33.089 58.2202 33.1787 57.5378 33.0072 56.8974C32.8356 56.257 32.4166 55.7109 31.8425 55.3794Z"
          fill="#A7A8B0"
        />
        <path
          d="M48.589 48.5894C50.4674 46.7113 51.6366 44.2401 51.8975 41.5966C52.1583 38.9532 51.4947 36.3011 50.0196 34.092C48.5446 31.883 46.3493 30.2536 43.8077 29.4815C41.2662 28.7093 38.5355 28.8422 36.0809 29.8573C33.6263 30.8725 31.5995 32.7073 30.3458 35.0491C29.0921 37.3909 28.6891 40.0949 29.2053 42.7005C29.7215 45.3061 31.125 47.6522 33.1768 49.3392C35.2287 51.0261 37.8019 51.9495 40.4581 51.9522C43.5068 51.9521 46.4309 50.7428 48.589 48.5894ZM45.0538 35.8614C46.1199 36.9249 46.7842 38.3253 46.9334 39.8238C47.0826 41.3222 46.7075 42.8261 45.872 44.0789C45.0365 45.3318 43.7924 46.2561 42.3517 46.6943C40.911 47.1325 39.3629 47.0575 37.9713 46.4821C36.5797 45.9066 35.4307 44.8664 34.7203 43.5386C34.0098 42.2109 33.7818 40.6778 34.0752 39.2008C34.3686 37.7237 35.1651 36.3942 36.329 35.4387C37.493 34.4832 38.9523 33.961 40.4581 33.961C42.1812 33.9612 43.8339 34.6446 45.0538 35.8614V35.8614Z"
          fill="#A7A8B0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1599_109596">
          <rect
            width="78.915"
            height="78.9155"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AhaMediumIcon;
