import React, { ReactNode } from "react";

export type CenterProps = {
  children: ReactNode;
  className?: string;
};

function Center({
  children,
  className = "",
  ...props
}: CenterProps & React.ComponentPropsWithRef<"div">) {
  return (
    <div className={`flex items-center justify-center ${className}`} {...props}>
      {children}
    </div>
  );
}

export default Center;
