import { User } from "graphql/generated";
import { t } from "i18n-js";

export const formatUsernameList = (
  invitees: Partial<User>[] = [],
  maxNames = 4,
) => {
  const inviteesSlicedList = invitees.slice(0, 4);

  const qtySlicedInvitees = inviteesSlicedList.length;
  const qtyInvitees = invitees.length;

  let names = "";

  for (const [inviteeIndex, invitee] of inviteesSlicedList.entries()) {
    const nextIndex = inviteeIndex + 1;
    const nextIsTheLast = nextIndex === qtySlicedInvitees - 1;

    const currentIsTheLast = inviteeIndex === qtySlicedInvitees - 1;
    const inviteesNotDisplayed = qtyInvitees - maxNames;

    names += invitee.firstName;

    if (nextIndex < qtySlicedInvitees && !nextIsTheLast) {
      names += ", ";
    }

    if (nextIsTheLast) {
      names += ` ${t("shared.and")} `;
    }

    if (currentIsTheLast && inviteesNotDisplayed > 0) {
      names += ` +${qtyInvitees - maxNames} ${
        inviteesNotDisplayed > 1 ? t("shared.others") : t("shared.other")
      }`;
    }
  }

  return names;
};
