import {
  DailyRoom,
  WorkshopParticipant,
  WorkshopParticipantRole,
} from "graphql/generated";
import { t } from "i18n-js";

export interface DisplayRoom {
  id: string;
  title: string;
  participants: WorkshopParticipant[];
}

// auto sort remaining unassigned participants to breakout rooms
export const autoSort = (
  people: WorkshopParticipant[],
  breakoutRooms: DisplayRoom[],
): { rooms: DisplayRoom[]; unassigned: WorkshopParticipant[] } => {
  const participants = people.filter(
    (p) => p.role === WorkshopParticipantRole.Participant,
  );
  const facilitators = people.filter(
    (p) => p.role !== WorkshopParticipantRole.Participant,
  );

  let roomIndex = 0;
  let roomSize = 0;

  const updatedRooms = breakoutRooms.map((room) => {
    // set the initial minimum room size in case a user has already been assigned users to each room
    if (roomSize === 0 || roomSize > room.participants.length) {
      roomSize = room.participants.length;
    }

    return room;
  });

  const totalRooms = breakoutRooms.length;

  for (const participant of participants) {
    // makes sure participants are always assigned to the room with the fewest participants first
    if (
      updatedRooms[roomIndex] &&
      updatedRooms[roomIndex].participants.length > roomSize
    ) {
      roomIndex++;

      if (roomIndex === totalRooms) {
        roomIndex = 0;
      }
    }

    updatedRooms[roomIndex].participants.push(participant);

    roomIndex++;

    if (roomIndex === totalRooms) {
      roomSize++;
      roomIndex = 0;
    }
  }

  return { rooms: updatedRooms, unassigned: facilitators };
};

export const getFilteredUnassigned = (
  unassigned: WorkshopParticipant[],
  rooms: DailyRoom[],
) => {
  // eslint-disable-next-line unicorn/no-array-reduce
  const participantsInRooms = rooms.reduce(
    (acc: string[], room) => [
      ...acc,
      ...(room.workshopParticipants || []).map((p) => p.user.id),
    ],
    [],
  );

  return unassigned.filter(
    (participant) => !participantsInRooms.includes(participant.user.id),
  );
};

export const mapRooms = (dailyRooms: DailyRoom[]) => {
  return dailyRooms.map((room, index) => ({
    open: false,
    id: room.id,
    title: `${t("breakoutActivityEditor.room")} ${index + 1}`,
    participants: room.workshopParticipants || [],
  }));
};
