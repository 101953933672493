import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
import clsx from "clsx";
import { Button } from "components/base";
import { Breakout, Resource } from "graphql/generated";
import { ArrowLeft, ArrowRight, CircleNotch, X } from "phosphor-react";
import React, { useEffect } from "react";

const borderClass = "rounded-lg border border-neutral-5 p-4";

/**
 * This component renders a visual preview of an embedded file/resource.
 */
export const PreviewResource = ({
  activityData,
  onDeleteLink,
}: {
  activityData: Resource | Breakout;
  onDeleteLink?: (linkId: string, type: "link" | "document") => void;
}) => {
  const [embedLink, setEmbedLink] = React.useState("");
  const [fileUrl, setFileUrl] = React.useState("");
  const [contentType, setContentType] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [numPages, setNumPages] = React.useState(0);

  useEffect(() => {
    const link = activityData?.link?.url;
    const file = activityData?.documents?.length
      ? activityData?.documents[0].url
      : undefined;
    const newContentType = activityData?.documents?.length
      ? activityData?.documents[0].contentType
      : undefined;

    if (link) {
      // Miro
      if (link.includes("miro.com/app/board/")) {
        const boardId = link
          .split("https://miro.com/app/board/")
          .pop()
          ?.split("/")[0];

        const newLink =
          boardId &&
          `https://miro.com/app/live-embed/${boardId}/?embedMode=view_only_without_ui&embedAutoplay=true`;
        if (newLink && newLink !== embedLink) {
          newLink !== embedLink && setEmbedLink(newLink);
          setFileUrl("");
        }
      } else if (link.includes("docs.google.com")) {
        let newLink = link;

        newLink = link.replace("/edit", "/pub");
        newLink = newLink.replace("/view", "/pub");

        if (!newLink.includes("?embedded=true")) {
          newLink += "?embedded=true";
        }

        newLink !== embedLink && setEmbedLink(newLink);
      } else {
        link !== embedLink && setEmbedLink(link);
      }
    } else if (file && file.split("?")[0] !== fileUrl.split("?")[0]) {
      setCurrentPage(1);
      setNumPages(0);

      file !== fileUrl && setFileUrl(file);
      newContentType !== contentType && setContentType(newContentType || "");
      setEmbedLink("");
    }
  }, [
    activityData?.link?.url,
    activityData?.documents,
    fileUrl,
    contentType,
    embedLink,
  ]);

  const handleDelete = () => {
    const linkId = activityData?.link?.id;
    const documentId = activityData?.documents?.[0]?.id;

    if (linkId) {
      onDeleteLink?.(linkId, "link");
    }

    if (documentId) {
      onDeleteLink?.(documentId, "document");
    }
  };

  const handleLoadSuccess = ({
    numPages: nextNumPages,
  }: {
    numPages: number;
  }) => {
    setNumPages(nextNumPages);
  };

  return (
    <div
      className={`flex-1 ${borderClass} relative flex h-108 items-center justify-center`}
    >
      {onDeleteLink && (
        <Button
          variant="link"
          RightIcon={X}
          className="absolute right-6 top-6"
          onClick={handleDelete}
        />
      )}
      {embedLink.length > 0 && (
        <>
          <CircleNotch
            size={40}
            color="#292D30"
            className="absolute -z-10 animate-spin"
          />
          <iframe src={embedLink} className="h-full w-full" />
        </>
      )}
      {fileUrl.length > 0 &&
        (contentType === "application/pdf" ? (
          <div className="flex w-full justify-center">
            {numPages > 1 && (
              <button
                disabled={currentPage === 1}
                className={clsx({ "opacity-30": currentPage === 1 }, "mr-auto")}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <ArrowLeft size={30} />
              </button>
            )}
            <Document file={fileUrl} onLoadSuccess={handleLoadSuccess}>
              <Page
                pageNumber={currentPage}
                height={380}
                className="child:hidden shadow-md"
              />
            </Document>

            {numPages > 1 && (
              <button
                disabled={currentPage === numPages}
                className={clsx(
                  { "opacity-30": currentPage === numPages },
                  "ml-auto",
                )}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <ArrowRight size={30} />
              </button>
            )}
          </div>
        ) : (
          <img src={fileUrl} className="h-full w-full object-contain" />
        ))}
    </div>
  );
};
