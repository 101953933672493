import {
  PulseCheckCsvResponseQuery,
  PulseCheckCsvResponseQueryVariables,
  usePulseCheckCsvResponseQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query PulseCheckCsvResponse(
    $id: ID!
    $pulseInputForExport: PulseInputForExport!
  ) {
    downloadPulseCheckCsv(
      projectId: $id
      pulseInputForExport: $pulseInputForExport
    )
  }
`;

function usePulseCheckCSVResponse(
  variables: PulseCheckCsvResponseQueryVariables,
): {
  csvUrl: PulseCheckCsvResponseQuery["downloadPulseCheckCsv"];
} & UseQueryResult<PulseCheckCsvResponseQuery, unknown> {
  const response = usePulseCheckCsvResponseQuery(variables, { suspense: true });

  return {
    csvUrl: response.data?.downloadPulseCheckCsv || "",
    ...response,
  };
}

export { usePulseCheckCSVResponse };
