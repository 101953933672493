import React from "react";

function DropIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#FFFFFF"
      {...props}
    >
      <path
        d="M7.35446 1.64554L7.00091 1.29199L6.64735 1.64554L2.40471 5.88818C-0.133696 8.42659 -0.133696 12.5422 2.40471 15.0806C4.94312 17.619 9.05869 17.619 11.5971 15.0806C14.1355 12.5422 14.1355 8.42659 11.5971 5.88818L7.35446 1.64554Z"
        fill="currentColor"
        stroke="#C8C2C4"
      />
    </svg>
  );
}

export default DropIcon;
