import {
  Base1,
  Base1Strong,
  Caption1,
  Heading3Strong,
  HStack,
  VStack,
  WorkshopDetailsModal,
} from "components";
import { Button, ReadOnlyRichTextEditor } from "components/base";
import { WorkshopResources } from "components/partial";
import { useGetEnvsContext } from "contexts";
import { format, parseISO } from "date-fns";
import { WorkshopParticipantRole, WorkshopState } from "graphql/generated";
import { useWorkshopParticipant } from "hooks";
import { t } from "i18n-js";
import { ArrowSquareOut, Presentation } from "phosphor-react";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { WeekFeedWorkshopType } from "./WeekFeed";

type WorkshopCardProps = {
  workshop: WeekFeedWorkshopType;
};

function formatWorkshopCreatedAt(date: string) {
  return format(parseISO(date), "MMM dd, y");
}

function formatStartEndTime(date: string) {
  return format(parseISO(date), "h:mm aaa");
}

function formatWorkshopDate(date: string) {
  return format(parseISO(date || ""), "EEEE MMM, dd");
}

export default function WorkshopCard({ workshop }: WorkshopCardProps) {
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const search = useLocation().search;
  const workshopCardId = new URLSearchParams(search).get("card");
  const [cardRefId, setCardRefId] = useState<string>("");
  const [retryCount, setRetryCount] = useState(4);

  const { currentUser } = useGetEnvsContext();
  const { role } = useWorkshopParticipant(workshop, currentUser);

  const {
    id = "",
    workshopTitle: title,
    workshopDescription: description,
    startTime,
    endTime,
    createdAt,
    state,
    links,
    documents,
    highlight,
  } = workshop;

  let totalDocuments, jsonDocuments;
  function isJsonFile(workshopById: string) {
    for (const key in workshop) {
      if (key == "id" && workshop[key] == workshopById) {
        // eslint-disable-next-line unicorn/consistent-destructuring
        totalDocuments = workshop.documents;
        jsonDocuments = totalDocuments?.filter((el, idx) => {
          return el.contentType == "application/json";
        });
        return totalDocuments?.length - jsonDocuments?.length;
      }
    }
  }

  const contextRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!!workshopCardId) setCardRefId(workshopCardId);
    if (cardRefId !== workshopCardId) {
      setRetryCount(4);
    }
  }, [workshopCardId]);

  useLayoutEffect(() => {
    let intervalId: NodeJS.Timer;

    if (
      retryCount > 0 &&
      cardRefId === `workshop-${id}` &&
      !!contextRef.current
    ) {
      intervalId = setInterval(() => {
        setRetryCount(0);
        if (!!contextRef.current && cardRefId === `workshop-${id}`) {
          contextRef?.current?.scrollIntoView({
            behavior: "smooth",
          });
        } else {
          clearInterval(intervalId);
          setRetryCount((retryCount) => retryCount - 1);
        }
      }, 1500);
    }
    return () => clearInterval(intervalId);
  }, [retryCount, cardRefId, contextRef, id]);

  return (
    <>
      <VStack space={4} id={`workshop-${id || ""}`} className="mb-4">
        <div
          ref={cardRefId === `workshop-${id}` ? contextRef : undefined}
          className="flex items-center gap-2"
        >
          {/* TODO: Add createdby when available on workshop */}
          {/* <Avatar user={createdBy} size="mini" textClassName="!text-xs" /> */}
          <Caption1 className="self-center text-neutral-70">
            {t(`component.workshopCard.createdDate`)}
          </Caption1>

          <Caption1 className="text-neutral-40">
            {createdAt && formatWorkshopCreatedAt(createdAt)}
          </Caption1>
        </div>
        <div className="flex w-[680px] flex-col gap-4 overflow-hidden rounded-2xl bg-white shadow-feedCard">
          {highlight && <HStack className="bg-tertiary-blue-50 p-3"></HStack>}
          <HStack className="justify-between p-6">
            <VStack space={4} align="start" className="ml-2 mt-2 w-[494px]">
              <Base1Strong className="text-tertiary-blue-50">
                {t("component.workshopCard.title")}
              </Base1Strong>
              <Heading3Strong>{title}</Heading3Strong>
              <HStack className="gap-6">
                <Base1>
                  {formatStartEndTime(startTime)} {`>`}{" "}
                  {formatStartEndTime(endTime)}
                </Base1>
                <Base1>{formatWorkshopDate(startTime)}</Base1>
              </HStack>
              <ReadOnlyRichTextEditor readOnlyContent={description} />
              <HStack className="gap-4">
                <div className="mt-10 flex gap-4">
                  <Button
                    variant="outline"
                    onClick={() => setShowDetailsModal(true)}
                  >
                    <span className="flex">
                      <span>{t("component.workshopCard.readMore")}</span>
                    </span>
                  </Button>
                  {state === WorkshopState.PostSession ? (
                    <Button
                      RightIcon={ArrowSquareOut}
                      className="min-w-min self-start px-8"
                      onClick={() =>
                        window.open(`/workshop/${id}/summary`, "_blank")
                      }
                    >
                      {t("component.workshopCard.summary")}
                    </Button>
                  ) : (
                    <Button
                      RightIcon={ArrowSquareOut}
                      className="min-w-min self-start px-8"
                      onClick={() => window.open(`/workshop/${id}`, "_blank")}
                    >
                      {t("component.workshopCard.join")}
                    </Button>
                  )}
                </div>
              </HStack>
            </VStack>
            <div className="flex h-18 w-18 items-center justify-center rounded-full bg-tertiary-blue-50">
              <Presentation size={40} weight="light" className="text-white" />
            </div>
          </HStack>
          {links?.length ||
          (documents?.length && isJsonFile(workshopCardId)) ? (
            <VStack className="gap-4 p-6">
              <hr />
              <Caption1 className="text-neutral-50">
                {t("component.workshopCard.links")}
              </Caption1>
              <WorkshopResources
                className="h-2/3"
                links={links}
                documents={documents}
                workshopId={id}
                hasTitle={false}
                hasBorder={false}
              />
            </VStack>
          ) : (
            <></>
          )}
        </div>
      </VStack>
      {showDetailsModal && (
        <WorkshopDetailsModal
          show={showDetailsModal}
          onClose={() => setShowDetailsModal(false)}
          workshop={workshop}
          isParticipant={role === WorkshopParticipantRole.Participant}
        />
      )}
    </>
  );
}
