import clsx from "clsx";
import { Base2, Center, HStack, Paragraph1 } from "components";
import { getAvatarSize } from "components/base/Avatar/Avatar";
import { isPast, parseISO } from "date-fns";
import { t } from "i18n-js";
import { CaretDown } from "phosphor-react";
import { useProject } from "queries";
import React from "react";
import { useParams } from "react-router-dom";

import ProjectviewAvtar from "../../../icons/ProjectViewIcon";

const size = "medium";

function ProjectSwitcher() {
  const { projectId = "" } = useParams();

  const { project } = useProject({ id: projectId });
  const isArchived = project?.status === "archived";

  const title = project
    ? project?.name
    : t("component.projectSwitcher.yourProjects");

  const endDate = parseISO(project?.endDate || "").setHours(23, 59, 59, 59);
  const isWrapped = isPast(endDate);

  return (
    <Center className="gap-4">
      <Center>
        {/* <ProjectviewAvtar /> */}
        {project?.organization?.organizationImageUrl ? (
          <HStack
            className={clsx(
              "h-10 w-10 rounded-full bg-neutral-40 text-white ",
              getAvatarSize(size),
            )}
            justify="center"
            align="center"
          >
            <img
              className="h-full w-full rounded-full"
              src={project?.organization?.organizationImageUrl}
              alt="OrganizationAvatar"
            />
          </HStack>
        ) : (
          <ProjectviewAvtar />
        )}
      </Center>
      <Paragraph1
        className="font-semibold text-neutral-90"
        data-testid="project-switcher-title"
      >
        {title}
      </Paragraph1>
      <Center className="rounded-full border border-neutral-60 p-[2px]">
        <CaretDown />
      </Center>
      {isWrapped && (
        <Base2 className="rounded-full bg-secondary-green-30 px-2 py-1 text-neutral-90">
          {isArchived
            ? t("component.projectSwitcher.projectArchived")
            : t("component.projectSwitcher.projectWrapped")}
        </Base2>
      )}
    </Center>
  );
}

export default ProjectSwitcher;
