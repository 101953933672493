export const FilteredDiscType = (discType: string) => {
  if (discType.toLowerCase() === "di") {
    return "Di";
  } else if (discType.toLowerCase() === "si") {
    return "Si";
  } else if (discType.toLowerCase() === "is") {
    return "Is";
  } else {
    return discType.toUpperCase();
  }
};
