/* eslint-disable unicorn/no-nested-ternary */
import clsx from "clsx";
import { Base2, Caption1, HStack, Overline, VStack } from "components";
import { Avatar, Button } from "components/base";
import { Modal } from "components/partial";
import { useToast } from "components/provider";
import { Caption2, Display3, Display4 } from "components/Typography";
import { useGetEnvsContext, useInviteUsers } from "contexts";
import { GraphQLError } from "graphql";
import {
  AccessLevel,
  Exact,
  ProjectAssignment,
  ProjectInvitation,
  ProjectInvitationsScope,
  Scalars,
  UpdatePendingInvitationMutation,
  UpdateProjectAssignmentAccessLevelMutation,
  useCreateProjectInvitationsMutation,
  useDeleteProjectAssignmentMutation,
  useDeleteProjectInvitationMutation,
  User,
  useUpdatePendingInvitationMutation,
  useUpdateProjectAssignmentAccessLevelMutation,
} from "graphql/generated";
import { useModal, useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { capitalize, noop } from "lodash";
import {
  CaretDown,
  EnvelopeSimple,
  User as CustomIcon,
  X,
} from "phosphor-react";
import {
  useOrganizationMembers,
  useProjectAssignments,
  useProjectInvitations,
} from "queries";
import { useUsersSearch } from "queries/use-users-search";
import React, { ReactNode, useMemo, useState } from "react";
import {
  QueryClient,
  UseMutateAsyncFunction,
  useQueryClient,
} from "react-query";
import { useParams } from "react-router-dom";
import { EMAIL_REGEX } from "utils/helpers/validations";

import { FilteredDiscType } from "../TeamView/components/DiscTypeUtil";
import { ACCESS_LEVEL_TEXT, AccessLevelDropdown } from ".";
import AvatarGroupPendingUser from "./AvatarGroupPendingInvitaion";
import { ROWS_TO_FETCH } from "./constants";
import UserDetailsDialog from "./UserDetailsDialog";

type HighlightsDialogProps = {
  titleHeader: string;
  isOpen: boolean;
  setIsOpen: (shouldShow: boolean) => void;
};
export function MembersList({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <VStack space={6} className="pt-6">
      <div className="flex justify-between">
        <Overline className="text-neutral-90 ">{title}</Overline>
      </div>
      <VStack space={2}>{children}</VStack>
    </VStack>
  );
}

export const colorVariants: { [key: string]: string } = {
  D: "bg-secondary-green-700 rounded-[8px_0px_0px_0px]",
  I: "bg-secondary-red-700 rounded-[0px_8px_0px_0px]",
  S: "bg-secondary-indigo-700 rounded-[0px_0px_8px_0px]",
  C: "bg-secondary-orange-700 rounded-[0px_0px_0px_8px]",
  DText: "text-secondary-green-700",
  IText: "text-secondary-red-700",
  SText: "text-secondary-indigo-700",
  CText: "text-secondary-orange-700",
};

const handleChangeAccessLevel = async (
  id: Scalars["ID"],
  accessLevel: AccessLevel,
  updateAccessLevel:
    | UseMutateAsyncFunction<
        UpdatePendingInvitationMutation,
        unknown,
        Exact<{
          id: string;
          accessLevel: AccessLevel;
        }>,
        unknown
      >
    | UseMutateAsyncFunction<
        UpdateProjectAssignmentAccessLevelMutation,
        unknown,
        Exact<{
          id: string;
          accessLevel: AccessLevel;
        }>,
        unknown
      >,
  queryClient: QueryClient,
) => {
  await updateAccessLevel({
    id,
    accessLevel,
  });
  void queryClient.refetchQueries(["ProjectInvitations"]);
  void queryClient.refetchQueries(["ProjectAssignments"]);
  void queryClient.refetchQueries(["GetPersonalityProfile"]);
};

function PendingInvitationsList() {
  const { projectId = "" } = useParams();
  const { invitations } = useProjectInvitations({
    projectId,
    scope: ProjectInvitationsScope.All,
  });

  const [visiblePendigRequest, setvisiblePendigRequest] = useState(false);
  const { mutateAsync: updateAccessLevel } =
    useUpdatePendingInvitationMutation();
  const queryClient = useQueryClient();
  const { mutateAsync: deleteProjectInvitation } =
    useDeleteProjectInvitationMutation();
  const [invitationToDelete, setInvitationToDelete] = useState<
    Partial<ProjectInvitation> | undefined
  >();

  const {
    isOpen,
    openModal,
    closeModal,
    Modal: DeleteAssignmentModal,
  } = useModal();

  const { hasAdminAccess } = useProjectAccessLevel({ projectId });

  if (invitations.length === 0) return <></>;

  const handleOpenModal = (invitation: Partial<ProjectInvitation>) => {
    openModal();
    setInvitationToDelete(invitation);
  };

  const handleCloseModal = () => {
    closeModal();
    setInvitationToDelete(undefined);
  };

  const handleDeleteProjectInvitation = async () => {
    if (!invitationToDelete?.id) return;
    await deleteProjectInvitation({ id: invitationToDelete.id });
    closeModal();
    await queryClient.refetchQueries("ProjectInvitations");
  };

  const PendingclickHandler = () => {
    setvisiblePendigRequest(true);
  };
  return (
    <>
      {visiblePendigRequest === false ? (
        <>
          <Overline className="text-neutral-90">
            {t("component.shareModal.pendingRequests")}
          </Overline>
          <div className="flex justify-between">
            <AvatarGroupPendingUser
              users={invitations
                .filter((invitation) => invitation.isActive)
                .map((user) => user.recipient)}
            />
            <Button
              className="h-9 px-5"
              variant="tertiary"
              onClick={PendingclickHandler}
            >
              <span className="text-slate-[#292D30]">
                {invitations.filter((invitation) => invitation.isActive).length}{" "}
                pending
              </span>
            </Button>
          </div>
        </>
      ) : (
        <>
          {visiblePendigRequest && (
            <ModalPendingRquest
              titleHeader={`${invitations.length} ${t(
                "component.shareModal.pendingRequests",
              )}`}
              isOpen={true}
              setIsOpen={setvisiblePendigRequest}
            />
          )}
        </>
      )}
    </>
  );
}
export const ModalPendingRquest = ({
  titleHeader,
  isOpen: open,
  setIsOpen,
}: HighlightsDialogProps) => {
  const { projectId = "" } = useParams();
  const { invitations } = useProjectInvitations({
    projectId,
    scope: ProjectInvitationsScope.All,
  });

  const { mutateAsync: updateAccessLevel } =
    useUpdatePendingInvitationMutation();
  const queryClient = useQueryClient();
  const { mutateAsync: deleteProjectInvitation } =
    useDeleteProjectInvitationMutation();
  const [invitationToDelete, setInvitationToDelete] = useState<
    Partial<ProjectInvitation> | undefined
  >();

  const {
    isOpen,
    openModal,
    closeModal,
    Modal: DeleteAssignmentModal,
  } = useModal();

  const { hasAdminAccess, hasViewAccess } = useProjectAccessLevel({
    projectId,
  });

  if (invitations.length === 0) return <></>;

  const handleOpenModal = (invitation: Partial<ProjectInvitation>) => {
    openModal();
    setInvitationToDelete(invitation);
  };

  const handleCloseModal = () => {
    closeModal();
    setInvitationToDelete(undefined);
  };

  const handleDeleteProjectInvitation = async () => {
    if (!invitationToDelete?.id) return;
    await deleteProjectInvitation({ id: invitationToDelete.id });
    closeModal();
    await queryClient.refetchQueries("ProjectInvitations");
  };

  return (
    <Modal
      className="flex h-[440px]  w-[568px] flex-col  items-start overflow-y-scroll rounded-xl p-6 "
      open={open}
      onClose={() => setIsOpen(false)}
      children={
        <>
          <HStack className="w-full justify-between px-5 py-4">
            <VStack className="w-[80%]">
              <Display4> {titleHeader}</Display4>
            </VStack>

            <button
              onClick={() => setIsOpen(false)}
              data-testid="close"
              className="isolate flex h-12 w-12 cursor-pointer items-center justify-center self-end rounded-full bg-tint-dark-300 "
            >
              <X className="text-neutral-90" size={24} />
            </button>
          </HStack>
          <VStack>
            {invitations
              .filter((invitation) => invitation.isActive)
              .map((invitation) => {
                const canEdit =
                  (invitation.accessLevel !== AccessLevel.AdminAccess ||
                    hasAdminAccess) &&
                  !hasViewAccess;

                const MemberItem = () => (
                  <MembersList.Member
                    key={invitation.id}
                    user={invitation.recipient || undefined}
                    email={
                      !invitation.recipient
                        ? invitation.recipientEmail
                        : undefined
                    }
                    role={invitation.recipient?.defaultProjectRole}
                    accessLevel={invitation.accessLevel}
                    isEditable={canEdit}
                    acceptedAt={invitation.acceptedAt || undefined}
                    memberNameCharLimit={20}
                    detailsCharLimit={30}
                    showDiscType={!hasViewAccess}
                  />
                );

                const handleOnDelete = hasAdminAccess
                  ? () => handleOpenModal(invitation)
                  : undefined;

                return canEdit ? (
                  <AccessLevelDropdown
                    onDelete={handleOnDelete}
                    currentLevel={invitation.accessLevel}
                    onSelect={(newLevel) =>
                      void handleChangeAccessLevel(
                        invitation.id,
                        newLevel,
                        updateAccessLevel,
                        queryClient,
                      )
                    }
                    key={invitation.id}
                    canAssignAdmin={hasAdminAccess}
                    buttonContent={<MemberItem />}
                  />
                ) : (
                  <MemberItem key={invitation.recipientEmail} />
                );
              })}
            <DeleteAssignmentModal
              isFullScreen
              open={isOpen}
              onClose={closeModal}
            >
              <DeleteAssignmentModalContent
                userName={invitationToDelete?.recipientEmail || ""}
                onClose={handleCloseModal}
                onDelete={handleDeleteProjectInvitation}
              />
            </DeleteAssignmentModal>
          </VStack>
        </>
      }
    />
  );
};

function ProjectMembersList() {
  const [userId, setUserId] = useState("");
  const [showUserDetailsDialog, setShowUserDetailsModal] = useState(false);
  const { projectId = "" } = useParams();
  const { projectAssignments } = useProjectAssignments({
    projectId,
  });
  const { mutateAsync: updateAccessLevel } =
    useUpdateProjectAssignmentAccessLevelMutation();
  const queryClient = useQueryClient();
  const { mutateAsync: deleteProjectAssignment } =
    useDeleteProjectAssignmentMutation();
  const [assignmentToDelete, setAssignmentToDelete] = useState<
    Partial<ProjectAssignment> | undefined
  >();

  const toggleUserDetailsDialog = (userId?: string) => {
    setShowUserDetailsModal(!showUserDetailsDialog);
    userId && setUserId(userId);
  };

  const {
    isOpen,
    openModal,
    closeModal,
    Modal: DeleteAssignmentModal,
  } = useModal();
  const { hasAdminAccess, hasViewAccess } = useProjectAccessLevel({
    projectId,
  });

  const hasMultipleAdmins = useMemo<boolean>(() => {
    return (
      projectAssignments.filter(
        ({ accessLevel }) => accessLevel === AccessLevel.AdminAccess,
      ).length > 1
    );
  }, [projectAssignments]);

  const handleCloseModal = () => {
    closeModal();
    setAssignmentToDelete(undefined);
  };

  const handleOpenModal = (assignment: Partial<ProjectAssignment>) => {
    openModal();
    setAssignmentToDelete(assignment);
  };

  const handleDeleteProjectAssignment = async () => {
    if (!assignmentToDelete?.id) return;
    await deleteProjectAssignment({ id: assignmentToDelete.id });
    closeModal();
    await queryClient.refetchQueries("ProjectAssignments");
  };

  return (
    <>
      <MembersList title={t("component.shareModal.myTeam")}>
        {projectAssignments.map((assignment) => {
          const canEdit =
            (assignment.accessLevel !== AccessLevel.AdminAccess ||
              hasAdminAccess) &&
            !hasViewAccess;

          const MemberItem = () => (
            <MembersList.Member
              user={assignment.user}
              role={assignment.role}
              accessLevel={assignment.accessLevel}
              isEditable={canEdit}
              acceptedAt={new Date().toString()}
              className="px-0"
              showDiscType={!hasViewAccess}
              discType={assignment.user.discType}
              visibleToTeam={assignment.user.visibleToTeam}
              toggleUserDetailsDialog={toggleUserDetailsDialog}
            />
          );

          const handleOnDelete = hasAdminAccess
            ? () => handleOpenModal(assignment)
            : undefined;

          return canEdit ? (
            <AccessLevelDropdown
              onDelete={handleOnDelete}
              currentLevel={assignment.accessLevel}
              onSelect={(newLevel) =>
                void handleChangeAccessLevel(
                  assignment.id,
                  newLevel,
                  updateAccessLevel,
                  queryClient,
                )
              }
              key={assignment.user.id}
              canAssignAdmin={hasAdminAccess}
              buttonContent={<MemberItem />}
              viewOnly={
                assignment.accessLevel === AccessLevel.AdminAccess &&
                !hasMultipleAdmins
              }
            />
          ) : (
            <MemberItem key={assignment.user.id} />
          );
        })}
        <DeleteAssignmentModal isFullScreen open={isOpen} onClose={closeModal}>
          <DeleteAssignmentModalContent
            userName={assignmentToDelete?.user?.fullName}
            onClose={handleCloseModal}
            onDelete={handleDeleteProjectAssignment}
          />
        </DeleteAssignmentModal>
      </MembersList>
      <UserDetailsDialog
        open={showUserDetailsDialog}
        onClose={toggleUserDetailsDialog}
        userId={userId}
      />
    </>
  );
}

type DeleteAssignmentModalContentProps = {
  onClose: () => void;
  onDelete: () => Promise<void>;
  userName?: string;
};

export function DeleteAssignmentModalContent({
  onClose,
  onDelete,
  userName,
}: DeleteAssignmentModalContentProps) {
  return (
    <div className="flex h-96 w-108 flex-col justify-around p-10">
      <VStack space={4}>
        <Display3 className="text-neutral-90">
          {t("component.shareModal.remove.title")}
        </Display3>
        <Base2 className="text-neutral-70">
          {t("component.shareModal.remove.description", { userName })}
        </Base2>
      </VStack>
      <VStack space={4}>
        <div tabIndex={0} />
        <Button onClick={onClose} className="w-full">
          {t("component.shareModal.remove.cancel")}
        </Button>
        <Button
          onClick={onDelete as () => void}
          className="w-full"
          variant="destructive"
        >
          {t("component.shareModal.remove.confirm")}
        </Button>
      </VStack>
    </div>
  );
}

function SuggestedInviteesList() {
  const { projectId = "" } = useParams();
  const { currentUser } = useGetEnvsContext();
  const { debouncedSearchTerm, selectedUsers, selectUser } = useInviteUsers();
  const { members } = useOrganizationMembers({
    projectId,
    input: {
      withoutProjectMembers: true,
      searchTerm: debouncedSearchTerm,
    },
  });

  const { usersList } = useUsersSearch({
    input: {
      projectId: projectId,
      query: debouncedSearchTerm,
      rows: ROWS_TO_FETCH,
    },
  });

  const selectedUserIds = useMemo(
    () => selectedUsers.map((user) => user.id),
    [selectedUsers],
  );

  return (
    <MembersList title={t("component.shareModal.selectPerson")}>
      {/* As per requirements in https://coeurajtech.atlassian.net/browse/REC-406, fetching users from searchUser query. */}
      {usersList?.map(
        (user: User) =>
          currentUser?.id !== user.id &&
          !selectedUserIds.includes(user.id) && (
            <MembersList.Member
              key={user.id}
              user={user}
              role={user.defaultProjectRole}
              onClick={selectUser}
              data-testid="organization-member"
              acceptedAt={undefined}
              className="rounded-lg hover:bg-neutral-0"
            />
          ),
      )}
      <MembersList.Email />
    </MembersList>
  );
}

function MemberItem({
  user,
  email,
  role,
  accessLevel,
  isEditable = false,
  onClick,
  acceptedAt,
  className,
  memberNameCharLimit,
  detailsCharLimit,
  showDiscType = false,
  discType = "",
  visibleToTeam = false,
  toggleUserDetailsDialog = noop,
  ...props
}: {
  user?: User;
  email?: string;
  role?: string;
  accessLevel?: AccessLevel;
  isEditable?: boolean;
  acceptedAt: string | undefined;
  onClick?: (user: User) => void;
  className?: string;
  memberNameCharLimit?: number;
  detailsCharLimit?: number;
  showDiscType?: boolean;
  discType?: string | null;
  visibleToTeam?: boolean | null;
  toggleUserDetailsDialog?: (userId?: string) => void;
}) {
  const { addToast } = useToast();
  const { projectId = "" } = useParams();
  const { mutateAsync: createProjectInvitationsMutateAsync } =
    useCreateProjectInvitationsMutation();
  const [errormsg, seterrormsg] = useState("");
  const resendProjectInvitation = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): Promise<void> => {
    event.stopPropagation();
    try {
      await createProjectInvitationsMutateAsync({
        projectId,
        input: {
          inviteeIds: !!user?.id ? [user?.id] : [],
          inviteeEmails: user?.id ? [] : [user?.email || email || ""],
          accessLevel: accessLevel || AccessLevel.MemberAccess,
        },
      });
      addToast({
        message: t("shared.inviteResent", {
          userNameOrEmail: user?.fullName || email,
        }),
        variant: "success",
        hasCloseOption: true,
      });
    } catch (error) {
      const graphQLError = error as GraphQLError;
      if (graphQLError.message) {
        seterrormsg(graphQLError.message);
      }
    }
  };

  const RenderAccessInfo = () => {
    return accessLevel ? (
      <HStack
        align="center"
        space={3}
        className={!acceptedAt ? "w-64 justify-around" : ""}
      >
        <HStack className="flex flex-col">
          {!acceptedAt && isEditable && (
            <Button
              variant="primaryTurquoise"
              data-testid={`resend-button-${user?.email || email || ""}`}
              className={clsx("h-9 w-24 !px-4 !py-2")}
              onClick={
                resendProjectInvitation as (
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                ) => void
              }
            >
              <Base2 className="!text-neutral-90">{t("shared.resend")}</Base2>
            </Button>
          )}
          {errormsg != "" && (
            <Caption1 className=" mt-4 text-left text-secondary-red-50">
              {errormsg}
            </Caption1>
          )}
        </HStack>

        <HStack className="items-center">
          <Base2 className="mr-2">{ACCESS_LEVEL_TEXT[accessLevel].label}</Base2>
          {isEditable ? <CaretDown /> : undefined}
        </HStack>
      </HStack>
    ) : (
      <Base2 className="rounded border border-tint-dark-15 py-1 px-2">
        {t("shared.member")}
      </Base2>
    );
  };
  const handleClick = () => {
    if (onClick && user) onClick(user);
  };

  let userFullName,
    userMailSanitized,
    userPronouns,
    userRole,
    userCompany = "";

  if (email != undefined) {
    userMailSanitized = email.length > 24 ? `${email.slice(0, 24)}...` : email;
  }

  if (user?.fullName) {
    userFullName =
      user?.fullName?.length > (memberNameCharLimit || 25)
        ? user?.fullName?.slice(0, memberNameCharLimit || 25) + "..."
        : user?.fullName;
  }

  if (user?.pronouns) {
    userPronouns =
      user.pronouns?.length > (detailsCharLimit || 35)
        ? user.pronouns?.slice(0, detailsCharLimit || 35) + "..."
        : user.pronouns;
  }
  if (role) {
    userRole =
      role?.length > (detailsCharLimit || 35)
        ? role?.slice(0, detailsCharLimit || 35) + "..."
        : role;
  }
  if (user?.company) {
    userCompany =
      user?.company?.length > (detailsCharLimit || 35)
        ? user?.company?.slice(0, detailsCharLimit || 35) + "..."
        : user?.company;
  }

  return React.createElement(
    onClick ? "button" : "div",
    { onClick: handleClick, ...props },
    <HStack
      justify="between"
      align="center"
      className={`w-full p-4 ${className ? className : ""}`}
    >
      <HStack space={2} justify="center" align="center">
        <div
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            toggleUserDetailsDialog(user?.id);
          }}
          onMouseOver={() => toggleUserDetailsDialog(user?.id)}
          className="cursor-pointer"
        >
          {!!user ? (
            <Avatar user={user} />
          ) : (
            <CustomIcon
              className=" h-10 w-10 rounded-full border border-white bg-[#F2F3F4]"
              size={32}
            />
          )}
        </div>
        <VStack className="group" align="start">
          <div
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              toggleUserDetailsDialog(user?.id);
            }}
            onMouseOver={() => toggleUserDetailsDialog(user?.id)}
            className="cursor-pointer"
          >
            <Base2 className="text-left">
              {userFullName || userMailSanitized}
              <span
                className={
                  user?.fullName
                    ? ""
                    : "absolute -top-[-15px] hidden w-fit -translate-y-full rounded-lg bg-primary-turquoise-50 px-2 py-1 text-center text-sm text-white after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700 after:content-[''] group-hover:flex"
                }
              >
                {email}
              </span>
            </Base2>
          </div>

          <div className="max-w-[185px]">
            {!!user?.pronouns && (
              <Caption2
                className="pt-1 text-left text-neutral-60"
                aria-label={t("team.people.pronouns")}
              >
                {userPronouns}
              </Caption2>
            )}
            <VStack>
              {user ? (
                <Caption2 className="pt-1 text-left text-neutral-60 ">
                  {capitalize(userRole) || user.email}
                </Caption2>
              ) : undefined}
              {userCompany && userRole ? (
                <Caption2 className="pt-1 text-left text-neutral-60 ">
                  {userCompany}
                </Caption2>
              ) : undefined}
            </VStack>
            {showDiscType && (
              <HStack align="baseline">
                {discType && visibleToTeam && (
                  <>
                    <div
                      className={`${
                        colorVariants[discType.charAt(0).toUpperCase()]
                      } h-4 w-4 text-center !text-[10px] text-white`}
                    >
                      {discType.charAt(0).toUpperCase()}
                    </div>
                    <HStack
                      className={`${
                        colorVariants[discType.charAt(0).toUpperCase() + "Text"]
                      } mt-2 !ml-1 text-xs`}
                    >
                      <Caption2 className="font-Steradian !text-[10px] font-normal tracking-[0.3px]">
                        DiSC Type {FilteredDiscType(discType)}
                      </Caption2>
                    </HStack>
                  </>
                )}
              </HStack>
            )}
          </div>
        </VStack>
      </HStack>
      <RenderAccessInfo />
    </HStack>,
  );
}

function EmailItem() {
  const { searchTerm, selectEmail, selectedEmails } = useInviteUsers();
  const email = searchTerm.trim().toLowerCase();

  if (!EMAIL_REGEX.test(email) || selectedEmails.includes(email)) return <></>;
  return (
    <button onClick={() => selectEmail(email)}>
      <HStack align="center" space={2} className="rounded-lg bg-neutral-0 p-4">
        <HStack
          justify="center"
          align="center"
          className="h-10 w-10 rounded-full bg-neutral-5"
        >
          <EnvelopeSimple size={24} />
        </HStack>

        <Base2 className="word-break-word">{email}</Base2>
      </HStack>
    </button>
  );
}

MembersList.PendingInvitations = PendingInvitationsList;
MembersList.ProjectMembers = ProjectMembersList;
MembersList.SuggestedInvitees = SuggestedInviteesList;
MembersList.Member = MemberItem;
MembersList.Email = EmailItem;

export default MembersList;
