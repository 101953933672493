import { Caption1 } from "components/Typography";
import { WorkshopActivity } from "graphql/generated";
import { t } from "i18n-js";
import {
  ChartBar,
  Files,
  IconProps,
  ListNumbers,
  SquaresFour,
} from "phosphor-react";
import React from "react";

/**
 * This tag is used in the Post-Session activities to give a little
 * indication of what "type" a workshop activity is.
 */
export const ActivityTypeTag = ({
  activityType,
}: {
  activityType: WorkshopActivity["activity"]["__typename"];
}) => {
  let Icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  > = ListNumbers;

  switch (activityType) {
    case "Resource":
      Icon = Files;
      break;

    case "Breakout":
      Icon = SquaresFour;
      break;

    case "Poll":
      Icon = ChartBar;
      break;

    case "Discussion":
    default:
      Icon = ListNumbers;
  }

  return (
    <div className="flex rounded bg-tint-dark-10 py-1 px-2 text-neutral-70">
      <Icon size={16} className="mr-1.5 text-neutral-70" />

      <Caption1>
        {t(
          `workshopEditor.types.${
            activityType?.toLocaleLowerCase() || "discussion"
          }`,
        )}
      </Caption1>
    </div>
  );
};
