import clsx from "clsx";
import { Highlights } from "graphql/generated";
import { HighlightsIcon } from "icons";
import { noop } from "lodash";
import { CalendarBlank, Megaphone, Presentation } from "phosphor-react";
import React, { useEffect, useState } from "react";

import { HStack } from "../../layout/Stack";
import { Caption1 } from "../../Typography";

const HIGHLIGHTTYPE = {
  ANNOUNCEMENT: "ANNOUNCEMENT",
  MEETING: "MEETING",
  WORKSHOP: "WORKSHOP",
};

type HighlightTypes = "ANNOUNCEMENT" | "MEETING" | "WORKSHOP";

type StandardHighlightType = {
  title: string;
  description?: string;
  createAt?: Date;
  type: HighlightTypes;
};

function HighlightsBar({
  highlights,
  fullwidth = true,
  handleClick = noop,
}: {
  fullwidth: boolean;
  highlights: Partial<Highlights>;
  handleClick: React.MouseEvent;
}): JSX.Element {
  const [sanitizedHighlights, setSanitizedHighlights] = useState<
    StandardHighlightType[]
  >([]);

  useEffect(() => {
    const sanitizedAnnouncements = highlights.announcements
      ? highlights.announcements.map((announcement) => {
          return {
            type: HIGHLIGHTTYPE.ANNOUNCEMENT,
            title: announcement?.announcementTitle,
            description: announcement?.description,
            createdAt: announcement?.scheduleTime || announcement?.createdAt,
          } as StandardHighlightType;
        })
      : [];

    const sanitizedMeetings = highlights.meetings
      ? highlights.meetings.map((meeting) => {
          return {
            type: HIGHLIGHTTYPE.MEETING,
            title: "Meeting",
            description: meeting?.description,
            createdAt: meeting?.startTime || meeting?.createdAt,
          } as StandardHighlightType;
        })
      : [];

    const sanitizedWorkshops = highlights.workshops
      ? highlights.workshops.map((workshop) => {
          return {
            type: HIGHLIGHTTYPE.WORKSHOP,
            title: "Workshop",
            description: workshop?.description as string,
            createdAt: workshop?.startTime || workshop?.createdAt,
          } as StandardHighlightType;
        })
      : [];

    setSanitizedHighlights([
      ...sanitizedAnnouncements,
      ...sanitizedMeetings,
      ...sanitizedWorkshops,
    ]);
  }, [highlights]);

  function getIcons(highlights: StandardHighlightType[], sliced = 0) {
    return (
      <HStack className="flex">
        {highlights &&
          highlights.map((highlight, index) => {
            switch (highlight.type) {
              case HIGHLIGHTTYPE.ANNOUNCEMENT:
                return (
                  <div
                    className={`z-[${
                      3 - index
                    }] !ml-[-8px] flex h-8 w-8 items-center justify-center rounded-full bg-secondary-yellow-50`}
                  >
                    <Megaphone
                      size={16}
                      mirrored
                      className={clsx("text-white")}
                    />
                  </div>
                );
              case HIGHLIGHTTYPE.MEETING:
                return (
                  <div
                    className={`z-[2] !ml-[-8px] flex h-8 w-8 items-center justify-center rounded-full bg-secondary-indigo-50`}
                  >
                    <CalendarBlank size={16} className="scale-x-1 text-white" />
                  </div>
                );
              case HIGHLIGHTTYPE.WORKSHOP:
                return (
                  <div
                    className={`z-[2] !ml-[-8px] flex h-8 w-8 items-center justify-center rounded-full bg-tertiary-blue-50`}
                  >
                    <Presentation size={16} className="scale-x-1 text-white" />
                  </div>
                );
              default:
                <></>;
            }
          })}
        {sliced ? (
          <div
            className={`z-[1] !ml-[-8px] flex h-8 w-8 items-center justify-center rounded-full bg-neutral-900`}
          >
            <Caption1 className="text-white">{`+${sliced}`}</Caption1>
          </div>
        ) : (
          <></>
        )}
      </HStack>
    );
  }

  return (
    <HStack
      className="flex items-center justify-between gap-2 rounded-2xl border-[1px] px-4 py-4"
      onClick={(event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        handleClick();
      }}
    >
      <HStack className="ml-2 flex gap-[17px]">
        {fullwidth && <HighlightsIcon />}
        <Caption1>{`${
          highlights?.totalHighlights as number
        } Highlights`}</Caption1>
      </HStack>

      {sanitizedHighlights.length > 3
        ? getIcons(
            sanitizedHighlights.slice(0, 2),
            sanitizedHighlights.length - 2,
          )
        : getIcons(sanitizedHighlights)}
    </HStack>
  );
}

export default HighlightsBar;
