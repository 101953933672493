import { useUpdatePollAnswerMutation } from "graphql/generated";
import { gql } from "graphql-request";

gql`
  mutation UpdatePollAnswer($inputs: UpdatePollAnswerInput!) {
    updatePollAnswer(inputs: $inputs) {
      id
    }
  }
`;

export function useUpdatePollAnswer() {
  return useUpdatePollAnswerMutation();
}
