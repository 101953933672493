import clsx from "clsx";
import { Base2 } from "components";
import { Button, DatePicker, Input, Link } from "components/base";
import { EmbeddedLinks } from "components/CreateInteractions/CreateAnnouncementForm";
import { formatISO, parseISO } from "date-fns";
import { useFetchMetadata } from "hooks";
import { t } from "i18n-js";
import noop from "lodash/noop";
import { ArrowRight, CircleNotch } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  VALIDATE_PARAGRAPH_REGEX,
  VALIDATE_STRING_REGEX,
  validateUrl,
  whetherLinkExists,
} from "utils/helpers/validations";

import { MAX_PROJECT_GOAL_CHARS, MAX_PROJECT_NAME_CHARS } from "./constants";
import {
  CreateProjectGeneralInformationFormInput,
  DefaultFieldsType,
  StepFormProps,
} from "./types";
import { getDefaultFields, mergeWithDefaultFields } from "./utils";
const PROJECT_GOAL_FIELD_OPTIONS = {
  required: t("errors.presence"),
  validate: {
    noLeadingWhiteSpace: (value: string) =>
      value.charAt(0) !== " " || t("errors.noLeadingSpaces"),
  },
  maxLength: {
    value: MAX_PROJECT_GOAL_CHARS,
    message: t("errors.maxLengthExceeded", {
      size: MAX_PROJECT_GOAL_CHARS,
    }),
  },
  pattern: {
    value: VALIDATE_PARAGRAPH_REGEX,
    message: t("errors.noSpaces"),
  },
};
const PROJECT_NAME_FIELD_OPTIONS = {
  required: t("errors.presence"),
  maxLength: {
    value: MAX_PROJECT_NAME_CHARS,
    message: t("errors.maxLengthExceeded", {
      size: MAX_PROJECT_NAME_CHARS,
    }),
  },
  pattern: {
    value: VALIDATE_STRING_REGEX,
    message: t("errors.noLeadingSpaces"),
  },
};
function GeneralInformationForm({
  className,
  fields = {},
  onSubmitSuccess = noop,
  testProps = [],
}: StepFormProps) {
  const defaultValues = useMemo(
    () =>
      mergeWithDefaultFields<CreateProjectGeneralInformationFormInput>(
        getDefaultFields<DefaultFieldsType>(),
        fields as CreateProjectGeneralInformationFormInput,
      ),
    [fields],
  );
  const [enteredLink, setEnteredLink] = useState("");
  const [embeddedLinks, setEmbeddedLinks] = useState<EmbeddedLinks[]>(
    testProps?.length > 0 ? testProps : [],
  );
  const { data, isLoading, error } = useFetchMetadata(enteredLink);
  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
    resetField,
    setError,
  } = useForm<CreateProjectGeneralInformationFormInput>({
    defaultValues,
    mode: "onTouched",
  });
  const handleEmbedLink = (event: React.FormEvent) => {
    event.preventDefault();
    const url = String(getValues("url"));
    if (validateUrl(url)) {
      setEnteredLink(url);
    } else {
      setError(
        "url",
        {
          type: "pattern",
          message: t("errors.invalidLink"),
        },
        { shouldFocus: true },
      );
    }
  };
  const removeEmbeddedLinks = (event: React.FormEvent, url: string) => {
    event.preventDefault();
    const newEmbeddedLinks = [...embeddedLinks];
    const linkIndex = embeddedLinks.findIndex((links) => links.url === url);
    if (linkIndex > -1) {
      newEmbeddedLinks.splice(linkIndex, 1);
      setEmbeddedLinks(newEmbeddedLinks);
      setEnteredLink("");
    }
  };
  const renderEmbeddedLinks = (list: EmbeddedLinks[]) => {
    return (
      <>
        <Base2 className="mb-2 text-neutral-90">
          {list.length} {list.length === 1 ? "Link" : "Links"}
        </Base2>
        <div className="align-center flex flex-wrap gap-4">
          {list.map((link) => (
            <Link
              metadata={link}
              key={link.url}
              isRemovable={true}
              onRemove={(event, url = link.url) =>
                removeEmbeddedLinks(event, url)
              }
            />
          ))}
        </div>
      </>
    );
  };
  useEffect(() => {
    reset(defaultValues);
    setValue("startDate", defaultValues.startDate);
    setValue("goal", defaultValues.goal);
  }, [reset, defaultValues, setValue]);
  useEffect(() => {
    setValue("links", embeddedLinks);
  }, [embeddedLinks]);
  useEffect(() => {
    testProps.length === 0 && fields?.links && setEmbeddedLinks(fields.links);
  }, [fields]);
  useEffect(() => {
    if (data.url && whetherLinkExists(embeddedLinks, data?.url) === -1) {
      setEmbeddedLinks((prev) => [
        ...prev,
        {
          url: data.url,
          title: data?.title || data?.domain,
          preview: data?.images[0] || "",
        },
      ]);
      resetField("url");
      setEnteredLink("");
    } else {
      data?.url &&
        setError(
          "url",
          {
            type: "custom",
            message: t("errors.linkAlreadyExists"),
          },
          { shouldFocus: true },
        );
      setEnteredLink("");
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      switch (error?.message) {
        case "Request failed with status code 404":
          setError(
            "url",
            {
              type: "pattern",
              message: t("errors.invalidLink"),
            },
            { shouldFocus: true },
          );
          setEnteredLink("");
          break;
        default:
          setError(
            "url",
            {
              type: "custom",
              message: t("errors.linkAlreadyExists"),
            },
            { shouldFocus: true },
          );
          setEnteredLink("");
      }
    }
  }, [error]);
  return (
    <form
      className={clsx(
        "flex h-full w-full flex-col justify-between px-14 py-8 pt-20 text-left",
        className,
      )}
      onSubmit={handleSubmit(onSubmitSuccess) as () => void}
    >
      <div className="my-16 flex flex-col justify-center p-1 pr-4">
        <Input
          contentClassName="mb-8"
          // inputContainerClassName="w-[608px]"
          error={errors.name?.message}
          label={t("component.createProjectForm.titleInputLabel")}
          placeholder={t("component.createProjectForm.titleInputPlaceholder")}
          {...register("name", { ...PROJECT_NAME_FIELD_OPTIONS })}
        />
        <DatePicker.Week
          contentClassName="mb-8"
          // datePickerContainerClassName="w-[608px]"
          placeholder={t(
            "component.createProjectForm.startWeekInputPlaceholder",
          )}
          error={errors.startDate?.message || ""}
          {...register("startDate", { required: t("errors.presence") })}
          defaultValue={
            getValues("startDate")
              ? parseISO(getValues("startDate"))
              : undefined
          }
          onChange={(selectedDate) => {
            if (selectedDate) {
              setValue("startDate", formatISO(selectedDate), {
                shouldValidate: true,
              });
            }
          }}
        />
        <Input
          multiline
          contentClassName="mb-8"
          // inputContainerClassName="w-[608px]"
          showCounter
          initialCount={defaultValues?.goal?.length}
          maxLength={MAX_PROJECT_GOAL_CHARS}
          error={errors.goal?.message}
          label={t("component.createProjectForm.projectGoalInputLabel")}
          placeholder={t(
            "component.createProjectForm.projectGoalInputPlaceholder",
          )}
          {...register("goal", { ...PROJECT_GOAL_FIELD_OPTIONS })}
          getValues={getValues}
        />
        <div className="flex w-full items-center space-y-2">
          <Input
            contentClassName="mb-4 !w-5/6"
            label={t("component.createProjectForm.projectGoalLinksLabel")}
            placeholder={t(
              "component.createProjectForm.projectGoalLinksPlaceholder",
            )}
            {...register("url")}
            error={errors.url?.message}
            inputContainerClassName="!w-full"
          />
          <Button
            onClick={handleEmbedLink}
            className="!ml-2 -mb-1 !w-1/4 rounded-3xl bg-tint-dark-300 text-xs"
          >
            {isLoading ? (
              <CircleNotch size={20} color="#FCFCFC" className="animate-spin" />
            ) : (
              <p className="w-[86px] text-base text-neutral-900 hover:!text-white">
                {t("shared.embedLink")}
              </p>
            )}
          </Button>
        </div>
        {embeddedLinks.length > 0 && renderEmbeddedLinks(embeddedLinks)}
      </div>
      <div className="fixed bottom-0 w-1/2 -translate-x-14 bg-white py-4 shadow-phasesGoals">
        <div className="flex w-full items-center justify-end px-14">
          <Button
            type="submit"
            variant="secondary"
            disabled={isSubmitting}
            RightIcon={ArrowRight}
          >
            {t("shared.next")}
          </Button>
        </div>
      </div>
    </form>
  );
}
export default GeneralInformationForm;
