import clsx from "clsx";
import { AddPhaseSummaryModal, VStack } from "components";
import {
  Avatar,
  Button,
  MilestoneIcon,
  MiniTimeline,
  WhiteCard,
} from "components/base";
import HighlightsBar from "components/base/Highlights/HighlightsBar";
import PulseCheckAverage from "components/base/PulseCheckAverage";
import EditPhaseSummaryModal from "components/EditPhaseSummaryModal";
import {
  Base2,
  Caption1,
  Caption2,
  Display3,
  Display4,
  Heading2Strong,
  Heading3Strong,
  Overline,
  Paragraph2,
  Paragraph3,
} from "components/Typography";
import {
  PhaseType,
  UpdatePhaseAndWeekProps,
  useProjectNavigator,
} from "contexts";
import {
  addWeeks,
  format,
  isAfter,
  isBefore,
  isPast,
  isSameWeek,
  parseISO,
} from "date-fns";
import {
  Highlights,
  MilestoneTypeEnum,
  Phase,
  ProjectByIdQuery,
  ProjectStatus,
} from "graphql/generated";
import { useProjectAccessLevel } from "hooks";
import { t } from "i18n-js";
import { Completed, DropIcon, PlusIcon } from "icons";
import { noop } from "lodash";
import useChangeProjectStatus from "mutations/use-archive-project";
import { ArrowRight } from "phosphor-react";
import { usePulseCheckPhaseSummary } from "queries/use-pulse-check-phase-summary";
import React, { useEffect, useState } from "react";
import { DeepPartial } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { formatWithoutTimezone, getToday } from "utils/constants/time_helpers";
import getWeeksFromProjectPerPhases, {
  WeeksFromProjectPerPhasesReturn,
} from "utils/helpers/get-weeks-from-project-per-phases";

import { NewSidebarModal } from "../../OrganizationMenuButton";
import { ArchiveWithoutSummaryDialog } from "./ArchiveDialog";
import { HighlightsModal } from "./HighlightsModal";

export function formatDate(date: string) {
  const formattedTimeString = format(
    formatWithoutTimezone(date),
    "MMMM d, yyyy",
  );
  return formattedTimeString;
}
export function PhaseCardContainer({
  phase,
  index,
  updatePhaseAndWeek,
  openAddPhaseSummaryModal,
  openHighlightsModal,
  weeksPerPhase,
  openEditPhaseSummaryModal,
  hasViewOnlyAccess,
  isProjectOver,
}: {
  phase: DeepPartial<Phase>;
  index: number;
  openEditPhaseSummaryModal: (event: React.SyntheticEvent) => void;
  openAddPhaseSummaryModal: (event: React.SyntheticEvent) => void;
  openHighlightsModal: (
    event: React.SyntheticEvent,
    highlights: Partial<Highlights>,
  ) => void;
  updatePhaseAndWeek: ({ phase, weekNumber }: UpdatePhaseAndWeekProps) => void;
  weeksPerPhase: WeeksFromProjectPerPhasesReturn[];
  hasViewOnlyAccess: boolean;
  isProjectOver: boolean;
}) {
  const navigate = useNavigate();
  const today = getToday();
  today.setHours(0, 0, 0, 0);

  const { pulseCheckPhaseSummary } = usePulseCheckPhaseSummary({
    phaseIds: phase?.id?.toString() || [],
  });

  const startDate = formatWithoutTimezone(phase.startDate as string);
  const endDate = formatWithoutTimezone(phase.endDate as string);
  let standardWidth = 368;
  if (phase.weeksLength && phase.weeksLength > 4)
    standardWidth = phase.weeksLength * 80 + phase.weeksLength * 4;
  const isCurrentPhase = today >= startDate && today <= endDate;
  return (
    <WhiteCard
      className={clsx(
        "relative flex h-[645px] cursor-pointer flex-col justify-between",
        isCurrentPhase && "!bg-white shadow-projectPhaseCard",
      )}
      style={{
        width: standardWidth,
      }}
      onClick={() => {
        if (phase) {
          updatePhaseAndWeek({ phase: phase as PhaseType, weekNumber: 0 });
          navigate({ search: "?level=phase" });
        }
      }}
    >
      <div className="flex flex-col px-6">
        <div className="flex justify-between">
          <div className="flex">
            <p
              className="-ml-6 h-5 -rotate-90 text-neutral-40 transition-colors group-hover:text-neutral-90"
              style={{ fontSize: "18px", marginTop: "34px" }}
            >
              Phase
            </p>
            <p
              className="h2 -ml-2 font-sans font-extralight text-neutral-40 transition-colors group-hover:text-neutral-90"
              style={{ fontSize: "71px", marginTop: "-10px" }}
            >
              {index + 1}
            </p>
          </div>
          <div>
            <Caption1 className="mt-7 text-neutral-90 transition-colors group-hover:text-neutral-90">
              {phase.weeksLength}{" "}
              {phase?.weeksLength && phase.weeksLength > 1 ? "weeks" : "Week"}
            </Caption1>
          </div>
        </div>
        <Heading2Strong
          className="mt-10 h-16 text-neutral-40 transition-colors line-clamp-2 group-hover:text-neutral-90 "
          style={{ maxWidth: "656px" }}
        >
          {phase?.name}
        </Heading2Strong>
        <Base2
          className="mb-2 text-neutral-70 transition-colors line-clamp-4 group-hover:text-gray-700 "
          style={{ minHeight: 80, maxHeight: 80 }}
        >
          {phase?.goal}
        </Base2>

        {phase?.phaseHighlights?.totalHighlights ? (
          <HighlightsBar
            handleClick={(event: React.SyntheticEvent) =>
              openHighlightsModal(
                event,
                phase?.phaseHighlights as Partial<Highlights>,
              )
            }
            fullwidth={true}
            highlights={phase?.phaseHighlights as Highlights}
          />
        ) : undefined}
      </div>
      <div className="mt-2 px-6">
        {phase.endDate &&
          !isBefore(today, new Date(phase?.endDate)) &&
          !phase?.phaseSummary && (
            <button
              onClick={
                hasViewOnlyAccess
                  ? // || isProjectOver
                    noop
                  : openAddPhaseSummaryModal
              }
              className="flex h-[140px] w-full flex-row items-center rounded-2xl border border-dashed border-primary-turquoise-10 p-4 text-center "
              style={{ maxWidth: "656px" }}
            >
              <PlusIcon width={40} height={40} className="mr-2 inline-flex" />
              <Paragraph2 className="text-xs">
                {t("pages.addPhaseSummary.addSummaryButton")}
              </Paragraph2>
            </button>
          )}

        <div className="flex flex-col gap-2">
          {phase?.phaseSummary && (
            <div
              className="flex h-[140px] w-full flex-row rounded-2xl border p-4"
              onClick={openEditPhaseSummaryModal}
            >
              <Avatar
                className="min-w-[32px]"
                size="small"
                style={{ marginTop: "2px" }}
                user={phase?.phaseSummary?.createdBy}
              />
              <div
                className="ml-4 w-full flex-col first-letter:flex"
                style={{ marginTop: "7px" }}
              >
                <div className="flex w-full justify-between">
                  <Base2 className="truncate">
                    {t("shared.phaseSummaryTitle", {
                      firstName: phase?.phaseSummary?.createdBy?.firstName,
                    })}
                  </Base2>

                  <div className="w-2/5">
                    {pulseCheckPhaseSummary?.length > 0 && (
                      <PulseCheckAverage
                        start={
                          +pulseCheckPhaseSummary[0]?.lowestAverage?.toFixed(2)
                        }
                        end={
                          +pulseCheckPhaseSummary[0]?.highestAverage?.toFixed(2)
                        }
                        average={
                          +pulseCheckPhaseSummary[0]?.average?.toFixed(2)
                        }
                        multiplier={10}
                      />
                    )}
                  </div>
                </div>
                <Paragraph3 className="mt-2 text-neutral-70 line-clamp-2">
                  {phase?.phaseSummary?.description}
                </Paragraph3>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mb-6 flex w-full items-center gap-2 p-2 px-4">
        {[...Array.from({ length: phase?.weeksLength || 0 }).keys()].map(
          (index) => {
            const milestone = phase?.milestones?.find(
              (milestone) => milestone?.weekNumber === index,
            );
            const date = addWeeks(startDate, index);
            const isCurrentWeek = isSameWeek(today, date);
            const weekText = weeksPerPhase?.filter(
              (week, weekIndex) => week?.weekNumber === weekIndex,
            );
            return (
              <>
                <VStack className="group-milestoneCard duration-900 transition-all ">
                  {weekText?.map((week, weekIndex) => {
                    return index === weekIndex ? (
                      <Caption1
                        className={`invisible z-50  text-neutral-60 ${
                          index === weekIndex
                            ? "transition-all duration-100 ease-in-out hover:bg-right-bottom group-milestoneCard-hover:visible"
                            : ""
                        }`}
                        style={{
                          fontSize: "9px",
                        }}
                      >
                        {week.weekText}
                      </Caption1>
                    ) : undefined;
                  })}
                  <span className="-z-1 absolute bottom-6 mt-2 h-0 w-[72px] rounded border-neutral-0 bg-neutral-0 transition-all ease-out group-hover:border-b-4 group-milestoneCard-hover:h-[95px] group-milestoneCard-hover:w-[72px] group-milestoneCard-hover:duration-500"></span>
                  <span className="align-center relative flex h-[96px] w-[72px] flex-col justify-center">
                    {milestone ? (
                      <>
                        <MilestoneIcon
                          className="h-[96px] w-[72px] p-4"
                          variant={
                            milestone.milestoneType || MilestoneTypeEnum.Aha
                          }
                          active
                        />
                        <Caption2 className="mb-2 text-center text-neutral-90">
                          {t(
                            `milestone.${
                              milestone.milestoneType || MilestoneTypeEnum.Aha
                            }`,
                          )}
                        </Caption2>
                      </>
                    ) : (
                      <ArrowRight
                        width={48}
                        height={48}
                        weight="thin"
                        className="align-center invisible mt-4 h-[96px] w-[72px] justify-center p-4 group-milestoneCard-hover:visible group-milestoneCard-hover:text-neutral-60"
                      />
                    )}
                    {isCurrentPhase && isCurrentWeek ? (
                      <div className="absolute -bottom-[4.5rem] left-1/2 w-20 -translate-x-3 translate-y-1">
                        <DropIcon className="h-8 w-6 stroke-0 text-primary-turquoise-30 drop-shadow-md" />
                        <Base2 className="mt-2 -translate-x-6 text-neutral-90">
                          {t("shared.thisWeek")}
                        </Base2>
                      </div>
                    ) : (
                      <></>
                    )}
                  </span>
                </VStack>
              </>
            );
          },
        )}
      </div>
    </WhiteCard>
  );
}
export function ProjectWrappedCard({
  date,
  hasAdminAccess,
  projectStatus,
  onArchiveProject,
}: {
  date: string;
  hasAdminAccess: boolean;
  projectStatus: string;
  onArchiveProject: () => void;
}) {
  const isArchived = projectStatus === "archived";
  return (
    <VStack
      data-testid="project-wrapped-card"
      className="flex-column ml-6 mr-16 h-[590px] w-[392px] min-w-[392px] rounded-lg bg-secondary-indigo-70 py-16 px-8"
      space={14}
    >
      <VStack space={6}>
        <Completed />
        <Display3 className="text-white">
          {isArchived
            ? t("shared.thisProjectArchived")
            : t("shared.thisProjectWrapped")}
          <br />
          {format(new Date(date), "MMM do, yyyy")}
        </Display3>
      </VStack>
      {hasAdminAccess && projectStatus === ProjectStatus.Active && (
        <Button
          onClick={onArchiveProject}
          variant="primaryDark"
          className="self-start"
        >
          {t("component.archiveProjectConfirmDialog.archiveProject")}
        </Button>
      )}
    </VStack>
  );
}
type ProjectViewBodyProps = {
  project: ProjectByIdQuery["projectById"];
  openProjectGoalModal: () => void;
};
export default function ProjectViewBody({
  project,
  openProjectGoalModal,
}: ProjectViewBodyProps) {
  const [addPhaseSummaryModalOpen, setAddPhaseSummaryModalOpen] =
    useState(false);
  const [editPhaseSummaryModalOpen, setEditPhaseSummaryModalOpen] =
    useState(false);
  const [selectedPhase, setSelectedPhase] = useState(0);
  const [fromEdit, setFromEdit] = useState(false);
  const [currentPhase, setCurrentPhase] = useState<DeepPartial<Phase>>();
  const [highlightsDialog, setHighlightsDialog] = useState<Partial<Highlights>>(
    {},
  );
  const [
    showArchiveWithoutPhaseSummaryDialog,
    setShowArchiveWithoutPhaseSummaryDialog,
  ] = useState(false);

  const { projectId = "" } = useParams();
  const { updatePhaseAndWeek } = useProjectNavigator();
  const { hasAdminAccess, hasViewAccess } = useProjectAccessLevel({
    projectId,
  });
  const [weeksPerPhase, setWeeksPerPhase] = useState(
    getWeeksFromProjectPerPhases(project),
  );
  const { mutateAsync } = useChangeProjectStatus();
  const [isProjectGoalModalOpen, setIsProjectGoalModalOpen] = useState(false);
  const handleOpenProjectGoalModal = () => setIsProjectGoalModalOpen(true);

  const isFinalPhaseSummaryAdded = () => {
    const phases = project?.phases || [];
    return phases[phases.length - 1]?.phaseSummary !== null;
  };

  const handleArchive = () =>
    isFinalPhaseSummaryAdded()
      ? void onProjectStatusChange()
      : setShowArchiveWithoutPhaseSummaryDialog(true);

  const onProjectStatusChange = async () => {
    try {
      await mutateAsync(
        {
          project: {
            id: projectId,
            status: 1,
          },
        },
        {
          onSuccess: () => {
            setShowArchiveWithoutPhaseSummaryDialog(false);
          },
        },
      );
    } catch {
      console.error(t("errors.somethingWentWrong"));
    }
  };

  const isProjectOver = isPast(parseISO(project?.endDate));

  const highlightsClickHandler = (
    phaseNumber: number,
    highlights: Partial<Highlights>,
  ) => {
    setSelectedPhase(phaseNumber);
    setHighlightsDialog(highlights);
  };

  useEffect(() => {
    setWeeksPerPhase(getWeeksFromProjectPerPhases(project));
  }, [project]);

  useEffect(() => {
    const tooltipEl = document.querySelector("#chartjs-tooltip");

    if (tooltipEl) {
      tooltipEl.remove();
    }
  }, []);

  return (
    <div className="flex flex-1 items-center overflow-x-auto">
      <div className="flex">
        <div className="ml-26 mr-4 flex h-[490px] w-[571px] flex-col justify-center pr-12">
          <Heading3Strong className="text-neutral-90">
            {t("shared.projectGoal")}
          </Heading3Strong>
          <Display4 className="mt-4 text-neutral-90 line-clamp-4">
            {project.goal}
          </Display4>
          <div className="mt-8">
            <Button
              onClick={handleOpenProjectGoalModal}
              variant="outline"
              className="text-neutral-90 focus:!outline-0 focus:!ring-0 focus:!ring-offset-0"
            >
              {t("shared.readMore")}
            </Button>
          </div>
          {isProjectGoalModalOpen && (
            <NewSidebarModal
              open={isProjectGoalModalOpen}
              onClose={() => setIsProjectGoalModalOpen(false)}
              setSidebarAsOpen={() => setIsProjectGoalModalOpen(true)}
            />
          )}
          <div className="mt-12 flex text-neutral-70">
            <div>
              <Overline className="py-2">
                {t("shared.projectStartDate")}
              </Overline>
              <Base2>{formatDate(project.startDate)}</Base2>
            </div>
            <div className="ml-24">
              <Overline className="py-2">{t("shared.targetEndDate")}</Overline>
              <Base2>{formatDate(project.endDate)}</Base2>
            </div>
          </div>
        </div>
        {project.phases.map((phase, index) => (
          <PhaseCardContainer
            key={phase.id}
            phase={phase as Phase}
            index={index}
            updatePhaseAndWeek={updatePhaseAndWeek}
            openEditPhaseSummaryModal={(event: React.SyntheticEvent) => {
              event.stopPropagation();
              setCurrentPhase(phase);
              setEditPhaseSummaryModalOpen(true);
            }}
            openHighlightsModal={(
              event: React.SyntheticEvent,
              hightlights: Partial<Highlights>,
            ) => {
              // event.stopPropagation();
              highlightsClickHandler(index + 1, hightlights);
            }}
            openAddPhaseSummaryModal={(event: React.SyntheticEvent) => {
              event.stopPropagation();
              setCurrentPhase(phase);
              setAddPhaseSummaryModalOpen(true);
            }}
            weeksPerPhase={weeksPerPhase?.[index]}
            hasViewOnlyAccess={hasViewAccess}
            isProjectOver={isProjectOver}
          />
        ))}
        {hasAdminAccess && isAfter(new Date(), parseISO(project?.endDate)) && (
          <ProjectWrappedCard
            date={project?.endDate}
            hasAdminAccess={hasAdminAccess}
            projectStatus={project?.status}
            onArchiveProject={handleArchive}
          />
        )}
      </div>
      <div className="absolute left-8 bottom-16">
        <MiniTimeline phases={project.phases} totalWidth={345} />
      </div>
      <ArchiveWithoutSummaryDialog
        open={showArchiveWithoutPhaseSummaryDialog}
        onCancel={() => setShowArchiveWithoutPhaseSummaryDialog(false)}
        onArchive={onProjectStatusChange as () => void}
      />
      <AddPhaseSummaryModal
        isOpen={addPhaseSummaryModalOpen}
        closeModal={() => setAddPhaseSummaryModalOpen(false)}
        phase={currentPhase}
        toggleAddModal={() => setAddPhaseSummaryModalOpen(true)}
        fromEdit={fromEdit}
        toggleEditPhaseSummaryModal={() => setEditPhaseSummaryModalOpen(false)}
      />
      <EditPhaseSummaryModal
        isOpen={editPhaseSummaryModalOpen}
        closeModal={() => setEditPhaseSummaryModalOpen(false)}
        phase={currentPhase}
        toggleEditModal={() => {
          setFromEdit(true);
          setAddPhaseSummaryModalOpen(true);
        }}
      />

      {!!highlightsDialog?.totalHighlights && (
        <HighlightsModal
          titleHeader={`Phase ${selectedPhase}`}
          onCancel={() => setHighlightsDialog({})}
          highlights={highlightsDialog}
          isOpen={!!highlightsDialog?.totalHighlights}
          setIsOpen={() => setHighlightsDialog({})}
        />
      )}
    </div>
  );
}
