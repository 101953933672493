import { ActivityProps } from "components/partial/DraggableList/DraggableItem/DraggableItem.types";
import { Base2, Caption1 } from "components/Typography";
import { format } from "date-fns";
import { t } from "i18n-js";
import React, { useMemo } from "react";

import { AgendaTimeMeterComponent } from "./AgendaTimeMeter.types";

const AgendaTimeMeter: AgendaTimeMeterComponent = ({
  startTime,
  endTime,
  agendaItems,
}) => {
  const { formattedStartTime, formattedEndTime, totalMin } = useMemo(() => {
    const formattedStartTime = format(startTime, "h:mmaaa");
    const formattedEndTime = format(endTime, "h:mmaaa");
    const totalMin = (endTime.getTime() - startTime.getTime()) / 1000 / 60;

    return { formattedStartTime, formattedEndTime, totalMin };
  }, [startTime, endTime]);

  const { formattedTimeRemaining, remainingPercent, isOver } = useMemo(() => {
    // eslint-disable-next-line unicorn/no-array-reduce
    const agendaItemsMin = Object.keys(agendaItems).reduce(
      (acc: number, key) => {
        const time = (agendaItems[key].data as ActivityProps).time || 0;
        return acc + time;
      },
      0,
    );

    const timeRemaining = totalMin - agendaItemsMin;
    const isOver = timeRemaining < 0;
    const remainingPercent = `${(agendaItemsMin / totalMin) * 100}%`;
    const hoursRemaining = Math.floor(Math.abs(timeRemaining) / 60);

    const formattedTimeRemaining = `${hoursRemaining || ""} ${
      hoursRemaining > 0 ? "h" : ""
    } ${Math.abs(timeRemaining) % 60} mins ${
      isOver ? t("shared.over") : t("shared.remaining")
    }`;

    return { agendaItemsMin, formattedTimeRemaining, remainingPercent, isOver };
  }, [agendaItems, totalMin]);

  return (
    <div className="mb-6">
      <div className="mb-2 flex items-end justify-between text-neutral-70">
        <Base2>{formattedStartTime}</Base2>
        <Caption1
          className={
            isOver ? "text-secondary-red-70" : "text-primary-turquoise-50"
          }
        >
          {formattedTimeRemaining}
        </Caption1>
        <Base2>{formattedEndTime}</Base2>
      </div>
      <div className="h-2 w-full overflow-hidden rounded-full bg-neutral-5">
        <div
          className={`h-full transition-all ${
            isOver ? "bg-secondary-red-50" : "bg-primary-turquoise-50"
          }`}
          style={{ width: remainingPercent }}
        />
      </div>
    </div>
  );
};

export default AgendaTimeMeter;
