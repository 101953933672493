import { JSONContent } from "@tiptap/core";
import { RichTextEditor } from "components/base";
import {
  MissingDataErrorDisplay,
  PageLoader,
  WorkshopResources,
} from "components/partial";
import AddResource, {
  AddResourceInput,
} from "components/partial/WorkshopEditorActivityContent/AddResource";
import { Caption1 } from "components/Typography";
import { t } from "i18n-js";
import _ from "lodash";
import { useUpdateWorkshop } from "mutations/workshop";
import { useWorkshop } from "queries";
import React, { useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";
import { URL_REGEX_EXTENDED } from "utils/helpers/validations";

import { WorkshopOverviewComponent } from "./WorkshopOverview.types";

const WorkshopOverview: WorkshopOverviewComponent = ({
  workshopId,
  workshopIsReady,
  onMutationStateChange,
}) => {
  const {
    mutate: updateWorkshop,
    isSuccess,
    isLoading,
    isError,
  } = useUpdateWorkshop();
  const {
    workshop,
    isLoading: isWorkshopLoading,
    isError: isWorkshopError,
  } = useWorkshop({
    id: workshopId,
  });

  const debouncedUpdateActivity = useCallback(
    _.debounce(updateWorkshop, 2000),
    [],
  );

  const queryClient = useQueryClient();
  const handleOverviewDescription = (value: JSONContent) => {
    const updateWorkshopActivity = {
      workshop: {
        id: workshopId,
        description: value,
      },
    };

    debouncedUpdateActivity(updateWorkshopActivity);
  };

  const handleAddResource = (input: AddResourceInput) => {
    if (input.link) {
      const update = { id: workshopId, links: [input.link] };
      updateWorkshop({ workshop: update });
    }

    if (input.file) {
      const update = { id: workshopId, documents: [input.file] };
      updateWorkshop({ workshop: update });
    }
  };

  useEffect(() => {
    onMutationStateChange({ isLoading });
  }, [isLoading, onMutationStateChange]);

  if (!workshop) {
    if (isWorkshopLoading) {
      return <PageLoader isShowing />;
    }
    if (isWorkshopError) {
      return <MissingDataErrorDisplay />;
    }
  }

  return (
    <div className="relative flex flex-1 p-12">
      <div className="flex w-1/2 flex-col">
        <div className="mb-4 w-11/12 max-w-3xl">
          <RichTextEditor
            label={t("workshopOverview.title")}
            isDisabled={!workshopIsReady}
            placeholder={t("workshopEditor.overviewPlaceholder")}
            minHeight="min-h-[calc(100vh-20rem)]"
            maxHeight="max-h-72"
            showFocusedRing={false}
            maxCharCount={1000}
            onChange={handleOverviewDescription}
            content={workshop ? (workshop.description as JSONContent) : {}}
            focusState="end"
          />
        </div>
        <Caption1>{t("workshopEditor.overviewCaption")}</Caption1>
      </div>
      <div className="flex w-1/2 flex-col gap-4">
        <WorkshopResources
          className="h-2/3"
          links={workshop?.links}
          documents={workshop?.documents}
          workshopId={workshopId}
        />
        <div className="rounded-md border border-neutral-5 p-6">
          <AddResource
            onAddResource={handleAddResource}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            isDisabled={!workshopIsReady}
            linkValidator={(value) =>
              URL_REGEX_EXTENDED.test(value) ? true : t("workshop.linkError")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default WorkshopOverview;
