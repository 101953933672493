import { useUpdateUserMutation } from "graphql/generated";
import { useQueryClient } from "react-query";

function useUpdateUser() {
  const queryClient = useQueryClient();

  return useUpdateUserMutation({
    onSuccess: () => queryClient.invalidateQueries(["CurrentUser"]),
  });
}

export default useUpdateUser;
