import {
  Notification,
  NotificationsQuery,
  NotificationsQueryVariables,
  useNotificationsQuery,
} from "graphql/generated";
import { gql } from "graphql-request";
import { UseQueryResult } from "react-query";

gql`
  query Notifications($count: Int!) {
    notifications(count: $count) {
      createdAt
      id
      senderId
      isRead
      isDeleted
      userId
      projectId
      startDate
      updatedAt
      author
      title
      notifiable {
        id
        type
      }
      heading
    }
  }
`;

function useNotifications(variables: NotificationsQueryVariables): {
  notifications: Notification[];
} & UseQueryResult<NotificationsQuery, unknown> {
  const response = useNotificationsQuery(variables);

  return {
    notifications: response?.data?.notifications || [],
    ...response,
  };
}

export { useNotifications };
