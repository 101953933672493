import { useDeletePollOptionMutation } from "graphql/generated";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

gql`
  mutation DeletePollOption($id: ID!) {
    deletePollOption(id: $id) {
      id
    }
  }
`;

export function useDeletePollOption() {
  const queryClient = useQueryClient();
  return useDeletePollOptionMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["WorkshopById"]);
      await queryClient.refetchQueries(["WorkshopById"]);
    },
  });
}
